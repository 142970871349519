import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OpenService } from 'src/swagger';
import { SwalHelper } from 'src/app/swal';

@Component({
  selector: 'app-set-new-password',
  templateUrl: './set-new-password.component.html',
  styleUrls: ['./set-new-password.component.scss']
})
export class SetNewPasswordComponent implements OnInit {

  public emailId: string;
  public newPassword: string;
  public confirmPassword: string;
  public uuid: string;
  public token: string;

  constructor(
    public activatedRoute: ActivatedRoute,
    private openApi: OpenService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      if (params.token) {
        this.token = params.token;
        this.uuid = params.token.substring(0, 36);
      }
    });
  }

  /* Fn to change the password */
  changePassword(form): boolean {
    if (form.valid) {
      this.openApi.verifySetpassword(
        this.token,
        this.confirmPassword
      ).subscribe(ok => {
        SwalHelper.successTimerSwal('You have set your password successfully!');
        this.router.navigate(['/login']);
      }, err => {
        SwalHelper.showErrorSwal(err);
      });
    } else {
      return false;
    }
  }
}
