import { Component, OnInit, Renderer2, HostListener, OnDestroy } from '@angular/core';
import { SalesOrder, OrganisationService, OrganisationContact, OrgOrdersService, StatusHistory, CustomExportObject, OrgShipmentsService } from 'src/swagger';
import { ActivatedRoute, Router } from '@angular/router';
import { SwalHelper } from 'src/app/swal';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { environment } from 'src/environments/environment';
import { OrganisationHelperService } from '../../organisation-helper.service';
import { CommonSupportService } from '../../../common-support.service';
import { GlobalProfileService } from 'src/app/global-profile.service';
import { Subscription, of, Subject } from 'rxjs';
import { ShipmentDocument } from '../../../../swagger/model/shipmentDocument';
import { debounceTime, tap, switchMap, catchError } from 'rxjs/operators';
import { Shipment } from '../../../../swagger/model/shipment';
declare var $: any;
declare var Swal: any;

@Component({
  selector: 'app-sales-order-details',
  templateUrl: './sales-order-details.component.html',
  styleUrls: ['./sales-order-details.component.scss']
})
export class SalesOrderDetailsComponent implements OnInit, OnDestroy {

  public salesOrderDetails: SalesOrder = {};
  public shouldShowLoader: boolean;

  public outputSO: any;
  public basePath: string;
  public rightSheet = false;
  public statusPercentage: string;

  public statusEnum = SalesOrder.StatusEnum;

  public emails = [];
  public contactUsers: OrganisationContact[] = [];
  public statusHistory: StatusHistory = {};
  public totalRemainingQnty = 0;
  public totalQuantity = 0;
  public pendingValue = 0;

  public shouldShareUser: boolean[] = [false];
  private userSubscription: Subscription;

  dtOptions: any = {};

  public shipmentDocumentLink: ShipmentDocument = {}; // For Linking Purpose

  public shipmentChange$: Subject<string> = new Subject();
  public listShipment$: Subject<Shipment[]> = new Subject();
  public alreadyLinkedShipIds = [];
  public shipmentLoading = false;
  private shipmentSubscription: Subscription;

  constructor(
    private activatedRoute: ActivatedRoute,
    private orgApi: OrganisationService,
    public http: HttpClient,
    private orgOrderApi: OrgOrdersService,
    private orgServiceHelper: OrganisationHelperService,
    public router: Router,
    private renderer: Renderer2,
    private commonService: CommonSupportService,
    public profileSync: GlobalProfileService,
    public orgShipmentApi: OrgShipmentsService
  ) {
    this.basePath = environment.BASE_PATH;
    commonService.renderer = renderer;
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent): void {
    this.rightSheet = false;
    this.renderer.removeClass(document.body, 'modal-open');
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(param => {
      if (param && param.soId) {
        this.fetchSalesOrderDetails(param.soId);
      }
    });
    this.userSubscription = this.profileSync.user$.subscribe(user => {
      if (user && user.OrganisationRole && user.OrganisationRole.permissions && user.OrganisationRole.permissions.OrganisationContact.read) {
        this.fetchContacts();
      }
    });

    this.dtOptions = {
      dom: 'Bfrtip',
      scrollX: true,
      // scrollY: '46vh',
      // scrollCollapse: true,
      buttons: [
        {
          text: 'Excel Download',
          className: 'btn btn-green',
          extend: 'excelHtml5',
          title: 'Order-report',
        }
      ],
      fixedColumns: {
        leftColumns: 3,
      },
      columnDefs: [
        { targets: 3, type: 'num-fmt' },
      ],
    };

    this.shipmentSubscription = this.shipmentChange$.pipe(
      debounceTime(200),
      tap(() => this.shipmentLoading = true),
      switchMap(query => this.orgOrderApi.searchOrderShipments(
        'SELL',
        query,
        this.alreadyLinkedShipIds.join(',')
      ).pipe(
        catchError(() => of([])), // empty list on error
        tap(() => {
          this.shipmentLoading = false;
        })
      ))
    ).subscribe(sos => {
      this.listShipment$.next(sos);
    });
    if (localStorage.getItem('IS_LINK_SHIPMENT') && this.profileSync.user && this.profileSync.user.OrganisationRole && this.profileSync.user.OrganisationRole.permissions && this.profileSync.user.OrganisationRole.permissions.Shipment.update) {
      this.openDocLinkModal();
      localStorage.removeItem('IS_LINK_SHIPMENT');
    }
  }

  ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
    if (this.shipmentSubscription) {
      this.shipmentSubscription.unsubscribe();
    }
  }


  rightSheetOpen(): void {
    this.rightSheet = !this.rightSheet;
    this.renderer.addClass(document.body, 'modal-open');
    this.commonService.addBackdrop();
  }

  rightSheetClose(): void {
    this.rightSheet = false;
    this.renderer.removeClass(document.body, 'modal-open');
    this.commonService.removeBackdrop();
  }

  orderRecon(): void {
    $('#orderReconModal').modal('show');
  }

  fetchSalesOrderDetails(soId): void {
    this.shouldShowLoader = true;
    this.orgOrderApi.salesOrderStatusHistory(soId).subscribe(so => {
      this.shouldShowLoader = false;
      if (so) {
        this.salesOrderDetails = so;
        this.calcPercentage();
        this.fetchTemplate();
        this.totalRemainingQnty = this.salesOrderDetails.SalesOrderItems.map(i => i.remainingQuantity).reduce((a, b) => a + b, 0);
        this.totalQuantity = this.salesOrderDetails.SalesOrderItems.map(i => i.itemQuantity).reduce((a, b) => a + b, 0);
        this.pendingValue = this.salesOrderDetails.SalesOrderItems.map(i => i.remainingQuantity * i.itemPrice).reduce((a, b) => a + b, 0);
        if (this.salesOrderDetails.ShipmentDocuments && this.salesOrderDetails.ShipmentDocuments.length > 0) {
          this.alreadyLinkedShipIds = this.salesOrderDetails.ShipmentDocuments.map(i => i.Shipment.shipmentId);
        }
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }
  calcPercentage(): void {
    if (this.salesOrderDetails.status === 'ACCEPTED') {
      this.statusPercentage = '25%';
    } else if (this.salesOrderDetails.status === 'IN_PROGRESS') {
      this.statusPercentage = '50%';
    } else if (this.salesOrderDetails.status === 'DELAYED') {
      this.statusPercentage = '75%';
    } else if (this.salesOrderDetails.status === 'COMPLETED') {
      this.statusPercentage = '100%';
    } else if (this.salesOrderDetails.status === 'CANCELLED') {
      this.statusPercentage = '100%';
    } else {
      this.statusPercentage = '0%';
    }
  }
  applyTemplate(): void {
    this.shouldShowLoader = true;
    this.orgOrderApi.updateSoTemplate(
      this.salesOrderDetails.salesOrderId,
      this.salesOrderDetails.templateName
    ).subscribe(order => {
      this.shouldShowLoader = false;
      this.fetchTemplate();
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }
  fetchContacts(): void {
    this.shouldShowLoader = true;
    this.orgApi.listOrgContact().subscribe(contacts => {
      this.shouldShowLoader = false;
      if (contacts) {
        this.contactUsers = contacts;
      } else {
        this.contactUsers = [];
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  calItemTotalQnty(item): void {
    return item.map(i => i.itemQuantity).reduce((a, b) => a + b, 0);
  }

  fetchTemplate(): void {
    const headers = new HttpHeaders().set('Authorization', this.orgApi.configuration.apiKeys.Authorization);

    if (this.salesOrderDetails.templateName) {
      this.shouldShowLoader = true;
      this.http.post(`${this.basePath}/organisation/templates/SalesOrder/${this.salesOrderDetails.templateName}`,
        { salesOrderId: this.salesOrderDetails.salesOrderId }, {
        responseType: 'text',
        headers,
        observe: 'body'
      }).subscribe(data => {
        this.shouldShowLoader = false;
        this.outputSO = data;
      }, err => {
        this.shouldShowLoader = false;
        SwalHelper.showErrorSwal(err);
      });
    }
  }

  convertToInv(): void {
    Swal({
      title: 'Convert to Invoice',
      text: 'Are you sure you want to convert Export order to Invoice',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Convert',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        this.shouldShowLoader = true;
        return new Promise((resolve, reject) => {
          this.orgOrderApi.convertSoToInvoice(
            this.salesOrderDetails.salesOrderId
          ).subscribe(inv => {
            this.shouldShowLoader = false;
            this.router.navigate([`/organisation/invoices/edit-invoice/${inv.invId}`]);
            resolve(inv);
          }, err => {
            reject(err);
          });
        });
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.value) {
        this.shouldShowLoader = false;
        SwalHelper.successTimerSwal('Invoice created successfully!');
      }
    }).catch(err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  exportSO(): void {
    this.shouldShowLoader = true;
    const cusObj: CustomExportObject = {};
    cusObj.salesOrderId = this.salesOrderDetails.salesOrderId;
    cusObj.type = 'SalesOrder';
    this.orgApi
      .exportDocument(cusObj).subscribe(res => {
        this.shouldShowLoader = false;
        saveAs(res, (`${this.salesOrderDetails.orgOrderNo}-ExportOrder.pdf`));
      }, err => {
        this.shouldShowLoader = false;
        SwalHelper.showErrorSwal(err);
      });
  }

  printSO(): void {
    const printContents = document.getElementById('printSO').innerHTML;
    const win = window.open('', 'Title', 'toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes');
    win.document.body.innerHTML = printContents;
    win.print();
  }

  updateStatus(status: SalesOrder.StatusEnum): void {
    let text = `Are you sure you want to update Export order to ${status === 'ACCEPTED' ? 'Final' : status.replace('_', ' ').toLocaleLowerCase()}`;
    if (status === 'ACCEPTED') {
      if (this.salesOrderDetails.SalesOrderItems && this.salesOrderDetails.SalesOrderItems.length === 0) {
        Swal({
          title: 'No Items Found!',
          text: 'Please Add items to Final the Order',
          type: 'warning',
        }).then((result) => {
          if (result.value) {
            this.router.navigate([`/organisation/sales-orders/update-sales-order/${this.salesOrderDetails.salesOrderId}`]);
          }
        });
        return;
      }
      text = 'You won’t be allowed to update Buyer & Item section once the status is updated from “Draft” to “Final”.';
    } else if (status === 'COMPLETED') {
      text = 'Once marked as “Completed” you won’t be able to update any fields.';
    }

    Swal({
      title: `Update Order to ${status === 'ACCEPTED' ? 'Final' : status.replace('_', ' ').toLocaleLowerCase()}`,
      text,
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Update',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        this.shouldShowLoader = true;
        return new Promise((resolve, reject) => {
          this.orgOrderApi.updateSoStatus(
            this.salesOrderDetails.salesOrderId,
            status
          ).subscribe(ok => {
            this.salesOrderDetails.status = status;
            this.calcPercentage();
            this.orgOrderApi.salesOrderStatusHistory(this.salesOrderDetails.salesOrderId).subscribe(so => {
              if (so) {
                this.salesOrderDetails = so;
              }
            }, err => {
              SwalHelper.showErrorSwal(err);
            });
            this.shouldShowLoader = false;
            resolve(ok);
          }, err => {
            reject(err);
          });
        });
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.value) {
        this.shouldShowLoader = false;
        SwalHelper.successTimerSwal(`Export Order marked as ${status === 'ACCEPTED' ? 'Final' : status.replace('_', ' ').toLocaleLowerCase()}`);
      }
    }).catch(err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  checkUser(event, user, ind): void {
    this.shouldShareUser[ind] = event.checked;
    if (event.checked) {
      this.emails.push({
        contactEmail: user.contactEmail,
        contactName: user.contactName
      });
    } else {
      const index = this.emails.findIndex(i => i.contactEmail === user.contactEmail);
      if (index >= 0) {
        this.emails.splice(index, 1);
      }
    }
  }
  shareSO(): void {
    this.shouldShowLoader = true;
    this.orgApi.emailSalesOrder(
      this.salesOrderDetails.salesOrderId,
      [JSON.stringify(this.emails)]
    ).subscribe(ok => {
      this.shouldShowLoader = false;
      this.emails = [];
      this.shouldShareUser = this.shouldShareUser.map(() => false);
      $('#assignUserModal').modal('hide');
      if (ok) {
        SwalHelper.successTimerSwal('Export Order sent successfully');
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }
  saveStatusComment(): void {
    this.shouldShowLoader = true;
    this.statusHistory.salesOrderId = this.salesOrderDetails.salesOrderId;
    if (this.statusHistory.statusHistoryId) {
      this.orgApi.updateStatusHistory(this.statusHistory).subscribe(history => {
        if (history) {
          this.shouldShowLoader = false;
          SwalHelper.showSavedToast('Comment Updated');
          const index = this.salesOrderDetails.StatusHistories.findIndex(i => i.statusHistoryId === this.statusHistory.statusHistoryId);
          this.salesOrderDetails.StatusHistories[index] = history;
          this.statusHistory = {};
        }
      }, err => {
        this.shouldShowLoader = false;
        SwalHelper.showErrorSwal(err);
      });
    } else {
      this.orgApi.createStatusHistory(this.statusHistory).subscribe(history => {
        if (history) {
          this.shouldShowLoader = false;
          this.salesOrderDetails.StatusHistories.unshift(history);
          SwalHelper.showSavedToast('Comment Added');
          this.statusHistory = {};
        }
      }, err => {
        this.shouldShowLoader = false;
        SwalHelper.showErrorSwal(err);
      });
    }
  }

  editComment(index: number): void {
    this.statusHistory = this.orgServiceHelper.copyObject(this.salesOrderDetails.StatusHistories[index]);
  }

  deleteStatusComment(index: number): void {
    Swal({
      title: 'Delete Status Comment',
      text: 'This cannot be undone',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        this.shouldShowLoader = true;
        return new Promise((resolve, reject) => {
          this.orgApi.deleteStatusHistory(this.salesOrderDetails.StatusHistories[index].statusHistoryId).subscribe(ok => {
            this.shouldShowLoader = false;
            this.salesOrderDetails.StatusHistories.splice(index, 1);
            resolve(ok);
          }, err => {
            reject(err);
          });
        });
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.value) {
        this.shouldShowLoader = false;
        SwalHelper.successTimerSwal('Comment deleted!');
      }
    }).catch(err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  public downloadFile(docAttId: number): void {
    this.orgApi.orgDocumentSignedUrl(docAttId)
      .subscribe(url => {
        window.open(url.url, '_blank');
      });
  }

  updatePaymentDetails(): void {
    this.shouldShowLoader = true;
    this.orgOrderApi.updateSoAdvanceDetails(this.salesOrderDetails).subscribe(ok => {
      this.shouldShowLoader = false;
      if (ok) {
        $('#recordPaymentModal').modal('hide');
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  openDocLinkModal(): void {
    this.shipmentDocumentLink.docType = 'SALES_ORDER';
    $('#fileAddModal').modal('show');
  }

  // Link the documents
  linkDocument(): void {
    this.shouldShowLoader = true;
    this.shipmentDocumentLink.salesOrderId = this.salesOrderDetails.salesOrderId;
    this.orgShipmentApi.linkShipmentDocument(
      this.shipmentDocumentLink
    ).subscribe(doc => {
      this.shouldShowLoader = false;
      SwalHelper.successTimerSwal('Document linked');
      if (doc) {
        if (doc.SalesOrder) {
          this.salesOrderDetails.ShipmentDocuments.push({
            shipmentDocumentId: doc.shipmentDocumentId,
            Shipment: doc.Shipment
          });
          this.alreadyLinkedShipIds.push(this.shipmentDocumentLink.shipmentId);
        }
        this.shipmentDocumentLink = {};
        this.shipmentDocumentLink.docType = 'SALES_ORDER';
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /**
   * Fn to Unlink the shipment from order
   * @param index Index of shipment document
   */
  unlinkDocument(index: number): void {
    Swal({
      title: `Unlink Shipment`,
      text: 'This cannot be undone',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Unlink',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        this.shouldShowLoader = true;
        return new Promise((resolve, reject) => {
          this.orgShipmentApi.unlinkShipmentDocument(
            this.salesOrderDetails.ShipmentDocuments[index].Shipment.shipmentId,
            this.salesOrderDetails.ShipmentDocuments[index].shipmentDocumentId
          ).subscribe(doc => {
            this.shouldShowLoader = false;
            this.salesOrderDetails.ShipmentDocuments.splice(index, 1);
            $('#fileViewModal').modal('hide');
            resolve(doc);
          }, err => {
            this.shouldShowLoader = false;
            reject(err);
          });
        });
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.value) {
        SwalHelper.successTimerSwal('Shipment Unlinked');
      }
    }).catch(err => {
      SwalHelper.showErrorSwal(err);
    });
  }

  /* Fn to set the buyer id for shipment & redirect to shipment create modal */
  openNewShipment(): void {
    localStorage.setItem('SHIPMENT_BUYER_ID', this.salesOrderDetails.buyerId.toString());
    this.router.navigate(['/organisation/list-shipments']);
  }
}
