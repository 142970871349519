import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { OrganisationService, ItemMaster } from 'src/swagger';
import { SwalHelper } from 'src/app/swal';
import { CommonSupportService } from 'src/app/common-support.service';
import { OrganisationHelperService } from '../../organisation-helper.service';
import { NgForm } from '@angular/forms';
import { ComponentCanDeactivate } from 'src/app/form-route-deactivate/component-can-deactivate';
declare var swal: any;

@Component({
  selector: 'app-organisation-item-masters',
  templateUrl: './organisation-item-masters.component.html',
  styleUrls: ['./organisation-item-masters.component.scss']
})
export class OrganisationItemMastersComponent extends ComponentCanDeactivate implements OnInit {

  @ViewChild('itemForm')
  public itemFormRef: NgForm;

  public itemMasterDetail: ItemMaster = {};
  public allItemMaster: ItemMaster[] = [];

  public shouldShowLoader: boolean;
  public rightSheet = false;

  constructor(
    private orgApi: OrganisationService,
    private commonService: CommonSupportService,
    private renderer: Renderer2,
    public orgHelper: OrganisationHelperService,
  ) {
    super();
    commonService.renderer = renderer;
  }

  ngOnInit(): void {
    this.fetchAllItemMasters();
  }

  canDeactivate(): boolean {
    return !this.itemFormRef.dirty;
  }

  /* Fn to initilize the new item & open the right sheet*/
  addNewItem(): void {
    this.itemMasterDetail = {};
    this.itemMasterDetail.itemCurrency = 'USD';
    this.rightSheetOpen();
  }

  /**
   * Fn to copy the item master details for edit & open right sheet
   * @param index Index of item master
   */
  editItem(index: number): void {
    this.itemMasterDetail = this.orgHelper.copyObject(this.allItemMaster[index]);
    this.rightSheetOpen();
  }

  /* Fn to open the right sheet for item form */
  rightSheetOpen(): void {
    this.rightSheet = !this.rightSheet;
    this.renderer.addClass(document.body, 'modal-open');
    this.commonService.addBackdrop();
  }

  /* Fn to close the right sheet */
  rightSheetClose(): void {
    this.rightSheet = false;
    this.renderer.removeClass(document.body, 'modal-open');
    this.commonService.removeBackdrop();
  }

  /* Fn to fetch all the item masters */
  fetchAllItemMasters(): void {
    this.shouldShowLoader = true;
    this.orgApi.listItemMaster().subscribe(items => {
      this.shouldShowLoader = false;
      if (items && items.length > 0) {
        this.allItemMaster = items;
      } else {
        this.allItemMaster = null;
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /**
   * Fn to get the item master details based on item id
   * @param itemId Primary key of item master
   */
  getItemMasterDetails(itemId: number): void {
    this.shouldShowLoader = true;
    this.orgApi.itemMasterDetail(itemId).subscribe(role => {
      this.shouldShowLoader = false;
      if (role) {
        this.itemMasterDetail = role;
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /**
   * Fn to save the details of item master
   * @param form Angular form
   */
  saveItemDetails(form): boolean {
    if (form.valid) {
      this.shouldShowLoader = true;
      if (this.itemMasterDetail.itemMasterId) {
        this.orgApi.updateItemMaster(this.itemMasterDetail
        ).subscribe(item => {
          this.shouldShowLoader = false;
          this.itemFormRef.form.markAsPristine();
          this.fetchAllItemMasters();
          SwalHelper.successTimerSwal('Item Updated!');
          this.rightSheetClose();
        }, err => {
          this.shouldShowLoader = false;
          SwalHelper.showErrorSwal(err);
        });
      } else {
        this.orgApi.addItemMaster(this.itemMasterDetail
        ).subscribe(item => {
          this.shouldShowLoader = false;
          this.itemFormRef.form.markAsPristine();
          this.fetchAllItemMasters();
          SwalHelper.successTimerSwal('Item Added!');
          this.rightSheetClose();
        }, err => {
          this.shouldShowLoader = false;
          SwalHelper.showErrorSwal(err);
        });
      }
    } else {
      return false;
    }
  }

  /**
   * Fn to delete the item master details
   * @param index Index of item master
   */
  deleteItemMaster(index: number): void {
    swal({
      title: 'Delete Item Master',
      text: 'This cannot be undone',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        this.shouldShowLoader = true;
        return new Promise((resolve, reject) => {
          this.orgApi.deleteItemMaster(this.allItemMaster[index].itemMasterId).subscribe(ok => {
            this.shouldShowLoader = false;
            this.allItemMaster.splice(index, 1);
            resolve(ok);
          }, err => {
            reject(err);
          });
        });
      },
      allowOutsideClick: () => !swal.isLoading()
    }).then((result) => {
      if (result.value) {
        this.shouldShowLoader = false;
        SwalHelper.successTimerSwal('Item deleted!');
      }
    }).catch(err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }
}
