import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { filter, timeout, take } from 'rxjs/operators';
import { GlobalProfileService } from './global-profile.service';
declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'nuphi-phase-two-frontend';

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private gtmService: GoogleTagManagerService,
    private profileSync: GlobalProfileService,
  ) {
    const currentPath = document.location.pathname;
    this.profileSync.user$.pipe(
      timeout(4000),
      take(1),
    ).subscribe((user) => {
      if (currentPath === '/') {
        if (user && user.orgId) {
          if (!user.Organisation.registeredAddress || !user.Organisation.gst) {
            this.router.navigate(['/welcome/profile']);
          } else {
            this.router.navigate(['/organisation']);
          }
        } else if (user && user.servProvId) {
          this.router.navigate(['/service-provider']);
        }
      }
    }, error => {
      if (currentPath === '/') {
        this.router.navigate(['/login']);
      }
    });
  }

  ngOnInit(): void {
    // tslint:disable-next-line: typedef
    $(function() {
      $('[data-toggle="tooltip"]').tooltip();
    });

    $(function() {
      $('[data-toggle="popover"]').popover({
        trigger: 'focus'
      });
    });

    this.gtmService.addGtmToDom();
    this.router.events.pipe(
      filter((e: RouterEvent) => {
        return e instanceof NavigationEnd;
      })
    ).subscribe((navigationEnd) => {
      $('[data-toggle="tooltip"]').tooltip('dispose');
    });
  }
}
