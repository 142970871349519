import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { CommonSupportService } from 'src/app/common-support.service';
import { SwalHelper } from 'src/app/swal';
import { OrganisationService, TagMaster, VendorAndSupplier } from 'src/swagger';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-organisation-vendor-update',
  templateUrl: './organisation-vendor-update.component.html',
  styleUrls: ['./organisation-vendor-update.component.scss']
})
export class OrganisationVendorUpdateComponent implements OnInit, OnChanges {

  @Output() closeVendorToggle = new EventEmitter<VendorAndSupplier>();
  @Input() venSupId: number;

  public allMasterTags: TagMaster[] = [];

  public vendorDetails: VendorAndSupplier = {};
  public shouldShowLoader: boolean;
  public vendorTag: string;

  public newTags = [];

  constructor(
    private orgApi: OrganisationService,
    public commonService: CommonSupportService
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    if (this.venSupId) {
      this.getVendorDetails(this.venSupId);
    }
    else {
      this.initVendor();
    }
    this.fetchTags('Vendor & Supplier');
  }

  /*  Fn to initilize new vendor */
  initVendor(): void {
    this.vendorDetails = {};
    this.vendorDetails.tags = [];
  }

  /* Fn to filter the new tag */
  checkTag(event): void {
    this.newTags = event.filter(i => !i.tagMasterId);
  }

  /* Fn to fetch the master tags */
  public fetchTags(tagType: any): void {
    this.orgApi.listTagMaster(tagType).subscribe(tags => {
      if (tags && tags.length > 0) {
        this.allMasterTags = tags;
      } else {
        this.allMasterTags = [];
      }
    }, err => {
      SwalHelper.showErrorSwal(err);
    });
  }

  /* Fn to add tag to tag master */
  public addTagToMaster(tagObject): void {
    this.orgApi.addTagMaster(
      tagObject
    ).subscribe(tag => {
      this.allMasterTags.push(tag);
    }, err => {
      SwalHelper.showErrorSwal(err);
    });
  }

  addVendorTag(): void {
    this.vendorDetails.tags.push(this.vendorTag);
    this.vendorTag = undefined;
  }

  /**
   * fn to get the vendor details
   *  @param venSupId Primary key of vendor
   */
  getVendorDetails(venSupId: number): void {
    this.shouldShowLoader = true;
    this.orgApi.vendorSupplierDetail(venSupId).subscribe(vendor => {
      this.shouldShowLoader = false;
      if (vendor) {
        this.vendorDetails = vendor;
        if (!this.vendorDetails.tags) {
          this.vendorDetails.tags = [];
        }
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /**
   * Fn to save vendor details
   * @param form Angular form
   */
  saveVendorDetails(form: NgForm): boolean {
    if (form.valid) {
      this.shouldShowLoader = true;
      this.createNewTags();
      if (this.vendorDetails.venSupId) {
        this.orgApi.updateVendorSupplier(this.vendorDetails).subscribe(vendor => {
          this.shouldShowLoader = false;
          this.closeVendorToggle.next(vendor);
          SwalHelper.successTimerSwal('Company Updated!');
          this.vendorDetails = {};
          this.vendorDetails.tags = [];
        }, err => {
          this.shouldShowLoader = false;
          SwalHelper.showErrorSwal(err);
        });
      } else {
        this.orgApi.addVendorSupplier(this.vendorDetails).subscribe(vendor => {
          this.shouldShowLoader = false;
          this.closeVendorToggle.next(vendor);
          SwalHelper.successTimerSwal('Company Added!');
          this.vendorDetails = {};
          this.vendorDetails.tags = [];
        }, err => {
          this.shouldShowLoader = false;
          SwalHelper.showErrorSwal(err);
        });
      }
    } else {
      return false;
    }
  }

  /* fn to create new tags */
  createNewTags(): void {
    if (this.newTags && this.newTags.length > 0) {
      this.newTags.forEach(i => {
        const newTag: TagMaster = {};
        newTag.tagName = i.tagName;
        newTag.tagDescription = 'Vendor & Supplier';
        this.addTagToMaster(newTag);
      });
    }
  }

}
