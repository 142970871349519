/**
 * Nuphi trade
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2.0.0
 * Contact: shraddha@blackcurrantapps.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { BillOfEntry } from '../model/billOfEntry';
import { BillOfLading } from '../model/billOfLading';
import { EmailReplyContext } from '../model/emailReplyContext';
import { ExpenseTracker } from '../model/expenseTracker';
import { ExpenseTrackerItem } from '../model/expenseTrackerItem';
import { Invoice } from '../model/invoice';
import { OrganisationContact } from '../model/organisationContact';
import { PurchaseOrder } from '../model/purchaseOrder';
import { SalesOrder } from '../model/salesOrder';
import { Shipment } from '../model/shipment';
import { ShipmentAccess } from '../model/shipmentAccess';
import { ShipmentChecklist } from '../model/shipmentChecklist';
import { ShipmentDialogue } from '../model/shipmentDialogue';
import { ShipmentDocument } from '../model/shipmentDocument';
import { ShipmentDocumentFolder } from '../model/shipmentDocumentFolder';
import { ShipmentTracking } from '../model/shipmentTracking';
import { ShippingBill } from '../model/shippingBill';
import { User } from '../model/user';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable({
  providedIn: 'root'
})
export class OrgShipmentsService {

    protected basePath = 'http://localhost:3000';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Archive the Shipment Manually
     * 
     * @param shipmentId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public archiveShipment(shipmentId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public archiveShipment(shipmentId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public archiveShipment(shipmentId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public archiveShipment(shipmentId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (shipmentId === null || shipmentId === undefined) {
            throw new Error('Required parameter shipmentId was null or undefined when calling archiveShipment.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (shipmentId !== undefined && shipmentId !== null) {
            queryParameters = queryParameters.set('shipmentId', <any>shipmentId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.put<any>(`${this.basePath}/organisation/shipments/archive_shipment`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * clone new shipment
     * Clone a new shipment from an existing shipment of an organisation
     * @param shipmentId 
     * @param Shipment 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public cloneShipment(shipmentId: number, Shipment: Shipment, observe?: 'body', reportProgress?: boolean): Observable<Shipment>;
    public cloneShipment(shipmentId: number, Shipment: Shipment, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Shipment>>;
    public cloneShipment(shipmentId: number, Shipment: Shipment, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Shipment>>;
    public cloneShipment(shipmentId: number, Shipment: Shipment, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (shipmentId === null || shipmentId === undefined) {
            throw new Error('Required parameter shipmentId was null or undefined when calling cloneShipment.');
        }

        if (Shipment === null || Shipment === undefined) {
            throw new Error('Required parameter Shipment was null or undefined when calling cloneShipment.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (shipmentId !== undefined && shipmentId !== null) {
            queryParameters = queryParameters.set('shipmentId', <any>shipmentId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Shipment>(`${this.basePath}/organisation/shipments/clone_shipment`,
            Shipment,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * add expense tracking item in the shipment
     * 
     * @param ExpenseTrackerItem 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createExpenseItemTracking(ExpenseTrackerItem: ExpenseTrackerItem, observe?: 'body', reportProgress?: boolean): Observable<ExpenseTrackerItem>;
    public createExpenseItemTracking(ExpenseTrackerItem: ExpenseTrackerItem, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ExpenseTrackerItem>>;
    public createExpenseItemTracking(ExpenseTrackerItem: ExpenseTrackerItem, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ExpenseTrackerItem>>;
    public createExpenseItemTracking(ExpenseTrackerItem: ExpenseTrackerItem, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (ExpenseTrackerItem === null || ExpenseTrackerItem === undefined) {
            throw new Error('Required parameter ExpenseTrackerItem was null or undefined when calling createExpenseItemTracking.');
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ExpenseTrackerItem>(`${this.basePath}/organisation/shipments/expense_item_tracking`,
            ExpenseTrackerItem,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * add expense tracking in the shipment
     * 
     * @param ExpenseTracker 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createExpenseTracking(ExpenseTracker: ExpenseTracker, observe?: 'body', reportProgress?: boolean): Observable<ExpenseTracker>;
    public createExpenseTracking(ExpenseTracker: ExpenseTracker, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ExpenseTracker>>;
    public createExpenseTracking(ExpenseTracker: ExpenseTracker, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ExpenseTracker>>;
    public createExpenseTracking(ExpenseTracker: ExpenseTracker, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (ExpenseTracker === null || ExpenseTracker === undefined) {
            throw new Error('Required parameter ExpenseTracker was null or undefined when calling createExpenseTracking.');
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ExpenseTracker>(`${this.basePath}/organisation/shipments/expense_tracking`,
            ExpenseTracker,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create the manual email reply context
     * 
     * @param shipmentId 
     * @param email 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createManualEmailReplyContext(shipmentId: number, email?: string, orgContactId?: number, observe?: 'body', reportProgress?: boolean): Observable<EmailReplyContext>;
    public createManualEmailReplyContext(shipmentId: number, email?: string, orgContactId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EmailReplyContext>>;
    public createManualEmailReplyContext(shipmentId: number, email?: string, orgContactId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EmailReplyContext>>;
    public createManualEmailReplyContext(shipmentId: number, email?: string, orgContactId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (shipmentId === null || shipmentId === undefined) {
            throw new Error('Required parameter shipmentId was null or undefined when calling createManualEmailReplyContext.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (shipmentId !== undefined && shipmentId !== null) {
            queryParameters = queryParameters.set('shipmentId', <any>shipmentId);
        }
        if (email !== undefined && email !== null) {
            queryParameters = queryParameters.set('email', <any>email);
        }
        if (orgContactId !== undefined && orgContactId !== null) {
            queryParameters = queryParameters.set('orgContactId', <any>orgContactId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<EmailReplyContext>(`${this.basePath}/organisation/shipments/create_manual_email_reply_context`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * create new shipment
     * Create a new shipment for an organisation
     * @param Shipment 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createShipment(Shipment: Shipment, observe?: 'body', reportProgress?: boolean): Observable<Shipment>;
    public createShipment(Shipment: Shipment, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Shipment>>;
    public createShipment(Shipment: Shipment, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Shipment>>;
    public createShipment(Shipment: Shipment, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (Shipment === null || Shipment === undefined) {
            throw new Error('Required parameter Shipment was null or undefined when calling createShipment.');
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Shipment>(`${this.basePath}/organisation/shipments/shipment`,
            Shipment,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * add checklist item in the shipment
     * 
     * @param ShipmentChecklist 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createShipmentChecklist(ShipmentChecklist: ShipmentChecklist, observe?: 'body', reportProgress?: boolean): Observable<ShipmentChecklist>;
    public createShipmentChecklist(ShipmentChecklist: ShipmentChecklist, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ShipmentChecklist>>;
    public createShipmentChecklist(ShipmentChecklist: ShipmentChecklist, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ShipmentChecklist>>;
    public createShipmentChecklist(ShipmentChecklist: ShipmentChecklist, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (ShipmentChecklist === null || ShipmentChecklist === undefined) {
            throw new Error('Required parameter ShipmentChecklist was null or undefined when calling createShipmentChecklist.');
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ShipmentChecklist>(`${this.basePath}/organisation/shipments/shipment_checklist`,
            ShipmentChecklist,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * add tracking in the shipment
     * 
     * @param ShipmentTracking 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createShipmentTracking(ShipmentTracking: ShipmentTracking, observe?: 'body', reportProgress?: boolean): Observable<ShipmentTracking>;
    public createShipmentTracking(ShipmentTracking: ShipmentTracking, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ShipmentTracking>>;
    public createShipmentTracking(ShipmentTracking: ShipmentTracking, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ShipmentTracking>>;
    public createShipmentTracking(ShipmentTracking: ShipmentTracking, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (ShipmentTracking === null || ShipmentTracking === undefined) {
            throw new Error('Required parameter ShipmentTracking was null or undefined when calling createShipmentTracking.');
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ShipmentTracking>(`${this.basePath}/organisation/shipments/shipment_tracking`,
            ShipmentTracking,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * delete a shipment expense tracking item
     * 
     * @param trackerItemId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteExpenseItemTracking(trackerItemId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteExpenseItemTracking(trackerItemId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteExpenseItemTracking(trackerItemId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteExpenseItemTracking(trackerItemId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (trackerItemId === null || trackerItemId === undefined) {
            throw new Error('Required parameter trackerItemId was null or undefined when calling deleteExpenseItemTracking.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (trackerItemId !== undefined && trackerItemId !== null) {
            queryParameters = queryParameters.set('trackerItemId', <any>trackerItemId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/organisation/shipments/expense_item_tracking`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * delete a shipment expense tracking
     * 
     * @param trackerId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteExpenseTracking(trackerId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteExpenseTracking(trackerId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteExpenseTracking(trackerId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteExpenseTracking(trackerId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (trackerId === null || trackerId === undefined) {
            throw new Error('Required parameter trackerId was null or undefined when calling deleteExpenseTracking.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (trackerId !== undefined && trackerId !== null) {
            queryParameters = queryParameters.set('trackerId', <any>trackerId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/organisation/shipments/expense_tracking`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * delete a folder
     * 
     * @param folderId 
     * @param shipmentId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteFolder(folderId: number, shipmentId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteFolder(folderId: number, shipmentId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteFolder(folderId: number, shipmentId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteFolder(folderId: number, shipmentId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (folderId === null || folderId === undefined) {
            throw new Error('Required parameter folderId was null or undefined when calling deleteFolder.');
        }

        if (shipmentId === null || shipmentId === undefined) {
            throw new Error('Required parameter shipmentId was null or undefined when calling deleteFolder.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (folderId !== undefined && folderId !== null) {
            queryParameters = queryParameters.set('folderId', <any>folderId);
        }
        if (shipmentId !== undefined && shipmentId !== null) {
            queryParameters = queryParameters.set('shipmentId', <any>shipmentId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/organisation/shipments/shipment_folder`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get shipment detail
     * Delete a requested shipment for an organisation
     * @param shipmentId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteShipment(shipmentId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteShipment(shipmentId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteShipment(shipmentId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteShipment(shipmentId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (shipmentId === null || shipmentId === undefined) {
            throw new Error('Required parameter shipmentId was null or undefined when calling deleteShipment.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (shipmentId !== undefined && shipmentId !== null) {
            queryParameters = queryParameters.set('shipmentId', <any>shipmentId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/organisation/shipments/shipment`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * delete a shipment checklist item
     * 
     * @param shipmentChecklistId 
     * @param shipmentId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteShipmentChecklist(shipmentChecklistId: number, shipmentId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteShipmentChecklist(shipmentChecklistId: number, shipmentId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteShipmentChecklist(shipmentChecklistId: number, shipmentId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteShipmentChecklist(shipmentChecklistId: number, shipmentId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (shipmentChecklistId === null || shipmentChecklistId === undefined) {
            throw new Error('Required parameter shipmentChecklistId was null or undefined when calling deleteShipmentChecklist.');
        }

        if (shipmentId === null || shipmentId === undefined) {
            throw new Error('Required parameter shipmentId was null or undefined when calling deleteShipmentChecklist.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (shipmentChecklistId !== undefined && shipmentChecklistId !== null) {
            queryParameters = queryParameters.set('shipmentChecklistId', <any>shipmentChecklistId);
        }
        if (shipmentId !== undefined && shipmentId !== null) {
            queryParameters = queryParameters.set('shipmentId', <any>shipmentId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/organisation/shipments/shipment_checklist`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * delete a shipment tracking
     * 
     * @param shipmentTrackingId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteShipmentTracking(shipmentTrackingId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteShipmentTracking(shipmentTrackingId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteShipmentTracking(shipmentTrackingId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteShipmentTracking(shipmentTrackingId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (shipmentTrackingId === null || shipmentTrackingId === undefined) {
            throw new Error('Required parameter shipmentTrackingId was null or undefined when calling deleteShipmentTracking.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (shipmentTrackingId !== undefined && shipmentTrackingId !== null) {
            queryParameters = queryParameters.set('shipmentTrackingId', <any>shipmentTrackingId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/organisation/shipments/shipment_tracking`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Check existing document linked with shipment
     * 
     * @param ShipmentDocument 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public documentAlreadyLinked(ShipmentDocument: ShipmentDocument, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public documentAlreadyLinked(ShipmentDocument: ShipmentDocument, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public documentAlreadyLinked(ShipmentDocument: ShipmentDocument, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public documentAlreadyLinked(ShipmentDocument: ShipmentDocument, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (ShipmentDocument === null || ShipmentDocument === undefined) {
            throw new Error('Required parameter ShipmentDocument was null or undefined when calling documentAlreadyLinked.');
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/organisation/shipments/document_already_linked`,
            ShipmentDocument,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get shipment total
     * Get details of the shipment tracking
     * @param shipmentId 
     * @param trackerId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getExpenseTracking(shipmentId: number, trackerId: number, observe?: 'body', reportProgress?: boolean): Observable<ExpenseTracker>;
    public getExpenseTracking(shipmentId: number, trackerId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ExpenseTracker>>;
    public getExpenseTracking(shipmentId: number, trackerId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ExpenseTracker>>;
    public getExpenseTracking(shipmentId: number, trackerId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (shipmentId === null || shipmentId === undefined) {
            throw new Error('Required parameter shipmentId was null or undefined when calling getExpenseTracking.');
        }

        if (trackerId === null || trackerId === undefined) {
            throw new Error('Required parameter trackerId was null or undefined when calling getExpenseTracking.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (shipmentId !== undefined && shipmentId !== null) {
            queryParameters = queryParameters.set('shipmentId', <any>shipmentId);
        }
        if (trackerId !== undefined && trackerId !== null) {
            queryParameters = queryParameters.set('trackerId', <any>trackerId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<ExpenseTracker>(`${this.basePath}/organisation/shipments/expense_tracking`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get shipment detail
     * Get all the Details of requested shipment id of an organisation
     * @param shipmentId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getShipment(shipmentId: number, observe?: 'body', reportProgress?: boolean): Observable<Shipment>;
    public getShipment(shipmentId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Shipment>>;
    public getShipment(shipmentId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Shipment>>;
    public getShipment(shipmentId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (shipmentId === null || shipmentId === undefined) {
            throw new Error('Required parameter shipmentId was null or undefined when calling getShipment.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (shipmentId !== undefined && shipmentId !== null) {
            queryParameters = queryParameters.set('shipmentId', <any>shipmentId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Shipment>(`${this.basePath}/organisation/shipments/shipment`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get shipment aceess details shared with external user
     * 
     * @param shipmentId 
     * @param shipmentAccessId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getShipmentAccess(shipmentId: number, shipmentAccessId: number, observe?: 'body', reportProgress?: boolean): Observable<ShipmentAccess>;
    public getShipmentAccess(shipmentId: number, shipmentAccessId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ShipmentAccess>>;
    public getShipmentAccess(shipmentId: number, shipmentAccessId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ShipmentAccess>>;
    public getShipmentAccess(shipmentId: number, shipmentAccessId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (shipmentId === null || shipmentId === undefined) {
            throw new Error('Required parameter shipmentId was null or undefined when calling getShipmentAccess.');
        }

        if (shipmentAccessId === null || shipmentAccessId === undefined) {
            throw new Error('Required parameter shipmentAccessId was null or undefined when calling getShipmentAccess.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (shipmentId !== undefined && shipmentId !== null) {
            queryParameters = queryParameters.set('shipmentId', <any>shipmentId);
        }
        if (shipmentAccessId !== undefined && shipmentAccessId !== null) {
            queryParameters = queryParameters.set('shipmentAccessId', <any>shipmentAccessId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<ShipmentAccess>(`${this.basePath}/organisation/shipments/get_shipment_access`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get shipment total
     * Get details of the shipment tracking
     * @param shipmentId 
     * @param shipmentTrackingId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getShipmentTracking(shipmentId: number, shipmentTrackingId: number, observe?: 'body', reportProgress?: boolean): Observable<ShipmentTracking>;
    public getShipmentTracking(shipmentId: number, shipmentTrackingId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ShipmentTracking>>;
    public getShipmentTracking(shipmentId: number, shipmentTrackingId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ShipmentTracking>>;
    public getShipmentTracking(shipmentId: number, shipmentTrackingId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (shipmentId === null || shipmentId === undefined) {
            throw new Error('Required parameter shipmentId was null or undefined when calling getShipmentTracking.');
        }

        if (shipmentTrackingId === null || shipmentTrackingId === undefined) {
            throw new Error('Required parameter shipmentTrackingId was null or undefined when calling getShipmentTracking.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (shipmentId !== undefined && shipmentId !== null) {
            queryParameters = queryParameters.set('shipmentId', <any>shipmentId);
        }
        if (shipmentTrackingId !== undefined && shipmentTrackingId !== null) {
            queryParameters = queryParameters.set('shipmentTrackingId', <any>shipmentTrackingId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<ShipmentTracking>(`${this.basePath}/organisation/shipments/shipment_tracking`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * list of internal users for checklist
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public internalUsersList(observe?: 'body', reportProgress?: boolean): Observable<Array<User>>;
    public internalUsersList(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<User>>>;
    public internalUsersList(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<User>>>;
    public internalUsersList(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<User>>(`${this.basePath}/organisation/shipments/internal_users_list`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Link existing documents with shipment
     * 
     * @param ShipmentDocument 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public linkShipmentDocument(ShipmentDocument: ShipmentDocument, observe?: 'body', reportProgress?: boolean): Observable<ShipmentDocument>;
    public linkShipmentDocument(ShipmentDocument: ShipmentDocument, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ShipmentDocument>>;
    public linkShipmentDocument(ShipmentDocument: ShipmentDocument, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ShipmentDocument>>;
    public linkShipmentDocument(ShipmentDocument: ShipmentDocument, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (ShipmentDocument === null || ShipmentDocument === undefined) {
            throw new Error('Required parameter ShipmentDocument was null or undefined when calling linkShipmentDocument.');
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ShipmentDocument>(`${this.basePath}/organisation/shipments/link_shipment_document`,
            ShipmentDocument,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * lists all shipments
     * gets list of all shipments for the organisation
     * @param shipmentType 
     * @param shipmentDate 
     * @param shipmentEndDate 
     * @param shipmentStatus 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listAllShipments(shipmentType: string, shipmentDate?: string, shipmentEndDate?: string, shipmentStatus?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Shipment>>;
    public listAllShipments(shipmentType: string, shipmentDate?: string, shipmentEndDate?: string, shipmentStatus?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Shipment>>>;
    public listAllShipments(shipmentType: string, shipmentDate?: string, shipmentEndDate?: string, shipmentStatus?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Shipment>>>;
    public listAllShipments(shipmentType: string, shipmentDate?: string, shipmentEndDate?: string, shipmentStatus?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (shipmentType === null || shipmentType === undefined) {
            throw new Error('Required parameter shipmentType was null or undefined when calling listAllShipments.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (shipmentType !== undefined && shipmentType !== null) {
            queryParameters = queryParameters.set('shipmentType', <any>shipmentType);
        }
        if (shipmentDate !== undefined && shipmentDate !== null) {
            queryParameters = queryParameters.set('shipmentDate', <any>shipmentDate);
        }
        if (shipmentEndDate !== undefined && shipmentEndDate !== null) {
            queryParameters = queryParameters.set('shipmentEndDate', <any>shipmentEndDate);
        }
        if (shipmentStatus !== undefined && shipmentStatus !== null) {
            queryParameters = queryParameters.set('shipmentStatus', <any>shipmentStatus);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<Shipment>>(`${this.basePath}/organisation/shipments/list_shipment`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get shipment total
     * Get list of all the shipment tracking expenses
     * @param shipmentStatus 
     * @param shipmentType 
     * @param shipmentCurrency 
     * @param vendorId 
     * @param fromDate 
     * @param toDate 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listExpenseTracking(shipmentStatus?: string, shipmentType?: string, shipmentCurrency?: string, vendorId?: number, fromDate?: string, toDate?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ExpenseTracker>>;
    public listExpenseTracking(shipmentStatus?: string, shipmentType?: string, shipmentCurrency?: string, vendorId?: number, fromDate?: string, toDate?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ExpenseTracker>>>;
    public listExpenseTracking(shipmentStatus?: string, shipmentType?: string, shipmentCurrency?: string, vendorId?: number, fromDate?: string, toDate?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ExpenseTracker>>>;
    public listExpenseTracking(shipmentStatus?: string, shipmentType?: string, shipmentCurrency?: string, vendorId?: number, fromDate?: string, toDate?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (shipmentStatus !== undefined && shipmentStatus !== null) {
            queryParameters = queryParameters.set('shipmentStatus', <any>shipmentStatus);
        }
        if (shipmentType !== undefined && shipmentType !== null) {
            queryParameters = queryParameters.set('shipmentType', <any>shipmentType);
        }
        if (shipmentCurrency !== undefined && shipmentCurrency !== null) {
            queryParameters = queryParameters.set('shipmentCurrency', <any>shipmentCurrency);
        }
        if (vendorId !== undefined && vendorId !== null) {
            queryParameters = queryParameters.set('vendorId', <any>vendorId);
        }
        if (fromDate !== undefined && fromDate !== null) {
            queryParameters = queryParameters.set('fromDate', <any>fromDate);
        }
        if (toDate !== undefined && toDate !== null) {
            queryParameters = queryParameters.set('toDate', <any>toDate);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<ExpenseTracker>>(`${this.basePath}/organisation/shipments/list_expense_tracking`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * List the manually created email reply tokens
     * 
     * @param shipmentId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listManualEmailReplyContext(shipmentId: number, observe?: 'body', reportProgress?: boolean): Observable<EmailReplyContext>;
    public listManualEmailReplyContext(shipmentId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EmailReplyContext>>;
    public listManualEmailReplyContext(shipmentId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EmailReplyContext>>;
    public listManualEmailReplyContext(shipmentId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (shipmentId === null || shipmentId === undefined) {
            throw new Error('Required parameter shipmentId was null or undefined when calling listManualEmailReplyContext.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (shipmentId !== undefined && shipmentId !== null) {
            queryParameters = queryParameters.set('shipmentId', <any>shipmentId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<EmailReplyContext>(`${this.basePath}/organisation/shipments/list_manual_email_reply_context`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * lists all shipments
     * gets condensed of all shipments for the organisation
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listShipmentNos(observe?: 'body', reportProgress?: boolean): Observable<Array<Shipment>>;
    public listShipmentNos(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Shipment>>>;
    public listShipmentNos(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Shipment>>>;
    public listShipmentNos(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<Shipment>>(`${this.basePath}/organisation/shipments/list_shipment_nos`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get shipment total
     * Get list of all the shipment tracking
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listShipmentTracking(observe?: 'body', reportProgress?: boolean): Observable<Array<ShipmentTracking>>;
    public listShipmentTracking(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ShipmentTracking>>>;
    public listShipmentTracking(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ShipmentTracking>>>;
    public listShipmentTracking(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<ShipmentTracking>>(`${this.basePath}/organisation/shipments/list_shipment_tracking`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get shipment detail
     * Get all the next shipment no of requested of an organisation
     * @param shipmentType 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public nextShipmentNo(shipmentType: string, observe?: 'body', reportProgress?: boolean): Observable<Shipment>;
    public nextShipmentNo(shipmentType: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Shipment>>;
    public nextShipmentNo(shipmentType: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Shipment>>;
    public nextShipmentNo(shipmentType: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (shipmentType === null || shipmentType === undefined) {
            throw new Error('Required parameter shipmentType was null or undefined when calling nextShipmentNo.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (shipmentType !== undefined && shipmentType !== null) {
            queryParameters = queryParameters.set('shipmentType', <any>shipmentType);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Shipment>(`${this.basePath}/organisation/shipments/next_shipment_no`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Revoke shipment Access Permission
     * 
     * @param shipmentAccessId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public revokeShipmentAccess(shipmentAccessId: number, observe?: 'body', reportProgress?: boolean): Observable<ShipmentDialogue>;
    public revokeShipmentAccess(shipmentAccessId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ShipmentDialogue>>;
    public revokeShipmentAccess(shipmentAccessId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ShipmentDialogue>>;
    public revokeShipmentAccess(shipmentAccessId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (shipmentAccessId === null || shipmentAccessId === undefined) {
            throw new Error('Required parameter shipmentAccessId was null or undefined when calling revokeShipmentAccess.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (shipmentAccessId !== undefined && shipmentAccessId !== null) {
            queryParameters = queryParameters.set('shipmentAccessId', <any>shipmentAccessId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<ShipmentDialogue>(`${this.basePath}/organisation/shipments/revoke_shipment_access`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Search Bill of Ladings
     * 
     * @param query 
     * @param limit 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchBls(query?: string, limit?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<BillOfLading>>;
    public searchBls(query?: string, limit?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<BillOfLading>>>;
    public searchBls(query?: string, limit?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<BillOfLading>>>;
    public searchBls(query?: string, limit?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (query !== undefined && query !== null) {
            queryParameters = queryParameters.set('query', <any>query);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<BillOfLading>>(`${this.basePath}/organisation/shipments/search_bls`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Search Bill of entry
     * 
     * @param query 
     * @param limit 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchBoes(query?: string, limit?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<BillOfEntry>>;
    public searchBoes(query?: string, limit?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<BillOfEntry>>>;
    public searchBoes(query?: string, limit?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<BillOfEntry>>>;
    public searchBoes(query?: string, limit?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (query !== undefined && query !== null) {
            queryParameters = queryParameters.set('query', <any>query);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<BillOfEntry>>(`${this.basePath}/organisation/shipments/search_boes`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Search invoices
     * 
     * @param type 
     * @param query 
     * @param exclude_ids comman seperated invoice nos
     * @param limit 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchInvoices(type?: string, query?: string, exclude_ids?: string, limit?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<Invoice>>;
    public searchInvoices(type?: string, query?: string, exclude_ids?: string, limit?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Invoice>>>;
    public searchInvoices(type?: string, query?: string, exclude_ids?: string, limit?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Invoice>>>;
    public searchInvoices(type?: string, query?: string, exclude_ids?: string, limit?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (type !== undefined && type !== null) {
            queryParameters = queryParameters.set('type', <any>type);
        }
        if (query !== undefined && query !== null) {
            queryParameters = queryParameters.set('query', <any>query);
        }
        if (exclude_ids !== undefined && exclude_ids !== null) {
            queryParameters = queryParameters.set('exclude_ids', <any>exclude_ids);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<Invoice>>(`${this.basePath}/organisation/shipments/search_invoices`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Search SOs
     * 
     * @param query 
     * @param exclude_ids comman seperated org contact nos
     * @param limit 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchOrgContacts(query?: string, exclude_ids?: string, limit?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<OrganisationContact>>;
    public searchOrgContacts(query?: string, exclude_ids?: string, limit?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<OrganisationContact>>>;
    public searchOrgContacts(query?: string, exclude_ids?: string, limit?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<OrganisationContact>>>;
    public searchOrgContacts(query?: string, exclude_ids?: string, limit?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (query !== undefined && query !== null) {
            queryParameters = queryParameters.set('query', <any>query);
        }
        if (exclude_ids !== undefined && exclude_ids !== null) {
            queryParameters = queryParameters.set('exclude_ids', <any>exclude_ids);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<OrganisationContact>>(`${this.basePath}/organisation/shipments/search_org_contacts`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Search POs
     * 
     * @param query 
     * @param exclude_ids comman seperated invoice nos
     * @param limit 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchPos(query?: string, exclude_ids?: string, limit?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<PurchaseOrder>>;
    public searchPos(query?: string, exclude_ids?: string, limit?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PurchaseOrder>>>;
    public searchPos(query?: string, exclude_ids?: string, limit?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PurchaseOrder>>>;
    public searchPos(query?: string, exclude_ids?: string, limit?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (query !== undefined && query !== null) {
            queryParameters = queryParameters.set('query', <any>query);
        }
        if (exclude_ids !== undefined && exclude_ids !== null) {
            queryParameters = queryParameters.set('exclude_ids', <any>exclude_ids);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<PurchaseOrder>>(`${this.basePath}/organisation/shipments/search_pos`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Search shipping bills
     * 
     * @param query 
     * @param limit 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchShippingBills(query?: string, limit?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<ShippingBill>>;
    public searchShippingBills(query?: string, limit?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ShippingBill>>>;
    public searchShippingBills(query?: string, limit?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ShippingBill>>>;
    public searchShippingBills(query?: string, limit?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (query !== undefined && query !== null) {
            queryParameters = queryParameters.set('query', <any>query);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<ShippingBill>>(`${this.basePath}/organisation/shipments/search_shippingBills`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Search SOs
     * 
     * @param query 
     * @param exclude_ids comman seperated invoice nos
     * @param limit 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchSos(query?: string, exclude_ids?: string, limit?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<SalesOrder>>;
    public searchSos(query?: string, exclude_ids?: string, limit?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SalesOrder>>>;
    public searchSos(query?: string, exclude_ids?: string, limit?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SalesOrder>>>;
    public searchSos(query?: string, exclude_ids?: string, limit?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (query !== undefined && query !== null) {
            queryParameters = queryParameters.set('query', <any>query);
        }
        if (exclude_ids !== undefined && exclude_ids !== null) {
            queryParameters = queryParameters.set('exclude_ids', <any>exclude_ids);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<SalesOrder>>(`${this.basePath}/organisation/shipments/search_sos`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * share shipment with external user
     * 
     * @param shipmentId 
     * @param ShipmentAccess 
     * @param docIds 
     * @param fileIds 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public shareShipment(shipmentId: number, ShipmentAccess: ShipmentAccess, docIds?: Array<string>, fileIds?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<ShipmentAccess>;
    public shareShipment(shipmentId: number, ShipmentAccess: ShipmentAccess, docIds?: Array<string>, fileIds?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ShipmentAccess>>;
    public shareShipment(shipmentId: number, ShipmentAccess: ShipmentAccess, docIds?: Array<string>, fileIds?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ShipmentAccess>>;
    public shareShipment(shipmentId: number, ShipmentAccess: ShipmentAccess, docIds?: Array<string>, fileIds?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (shipmentId === null || shipmentId === undefined) {
            throw new Error('Required parameter shipmentId was null or undefined when calling shareShipment.');
        }

        if (ShipmentAccess === null || ShipmentAccess === undefined) {
            throw new Error('Required parameter ShipmentAccess was null or undefined when calling shareShipment.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (shipmentId !== undefined && shipmentId !== null) {
            queryParameters = queryParameters.set('shipmentId', <any>shipmentId);
        }
        if (docIds) {
            queryParameters = queryParameters.set('docIds', docIds.join(COLLECTION_FORMATS['csv']));
        }
        if (fileIds) {
            queryParameters = queryParameters.set('fileIds', fileIds.join(COLLECTION_FORMATS['csv']));
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ShipmentAccess>(`${this.basePath}/organisation/shipments/share_shipment`,
            ShipmentAccess,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get shipment total
     * Get total amount group by currency
     * @param shipmentType 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public totalShipmentAmount(shipmentType: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Shipment>>;
    public totalShipmentAmount(shipmentType: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Shipment>>>;
    public totalShipmentAmount(shipmentType: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Shipment>>>;
    public totalShipmentAmount(shipmentType: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (shipmentType === null || shipmentType === undefined) {
            throw new Error('Required parameter shipmentType was null or undefined when calling totalShipmentAmount.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (shipmentType !== undefined && shipmentType !== null) {
            queryParameters = queryParameters.set('shipmentType', <any>shipmentType);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<Shipment>>(`${this.basePath}/organisation/shipments/total_shipment_amount`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Unlink existing documents from shipment
     * 
     * @param shipmentId 
     * @param shipmentDocumentId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public unlinkShipmentDocument(shipmentId: number, shipmentDocumentId: number, observe?: 'body', reportProgress?: boolean): Observable<ShipmentDialogue>;
    public unlinkShipmentDocument(shipmentId: number, shipmentDocumentId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ShipmentDialogue>>;
    public unlinkShipmentDocument(shipmentId: number, shipmentDocumentId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ShipmentDialogue>>;
    public unlinkShipmentDocument(shipmentId: number, shipmentDocumentId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (shipmentId === null || shipmentId === undefined) {
            throw new Error('Required parameter shipmentId was null or undefined when calling unlinkShipmentDocument.');
        }

        if (shipmentDocumentId === null || shipmentDocumentId === undefined) {
            throw new Error('Required parameter shipmentDocumentId was null or undefined when calling unlinkShipmentDocument.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (shipmentId !== undefined && shipmentId !== null) {
            queryParameters = queryParameters.set('shipmentId', <any>shipmentId);
        }
        if (shipmentDocumentId !== undefined && shipmentDocumentId !== null) {
            queryParameters = queryParameters.set('shipmentDocumentId', <any>shipmentDocumentId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.put<ShipmentDialogue>(`${this.basePath}/organisation/shipments/unlink_shipment_document`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Unlink a shipment tracking
     * 
     * @param shipmentTrackingId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public unlinkShipmentTracking(shipmentTrackingId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public unlinkShipmentTracking(shipmentTrackingId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public unlinkShipmentTracking(shipmentTrackingId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public unlinkShipmentTracking(shipmentTrackingId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (shipmentTrackingId === null || shipmentTrackingId === undefined) {
            throw new Error('Required parameter shipmentTrackingId was null or undefined when calling unlinkShipmentTracking.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (shipmentTrackingId !== undefined && shipmentTrackingId !== null) {
            queryParameters = queryParameters.set('shipmentTrackingId', <any>shipmentTrackingId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.put<any>(`${this.basePath}/organisation/shipments/unlink_shipment_tracking`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update expense tracking item of the shipment
     * 
     * @param ExpenseTrackerItem 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateExpenseItemTracking(ExpenseTrackerItem: ExpenseTrackerItem, observe?: 'body', reportProgress?: boolean): Observable<ExpenseTrackerItem>;
    public updateExpenseItemTracking(ExpenseTrackerItem: ExpenseTrackerItem, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ExpenseTrackerItem>>;
    public updateExpenseItemTracking(ExpenseTrackerItem: ExpenseTrackerItem, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ExpenseTrackerItem>>;
    public updateExpenseItemTracking(ExpenseTrackerItem: ExpenseTrackerItem, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (ExpenseTrackerItem === null || ExpenseTrackerItem === undefined) {
            throw new Error('Required parameter ExpenseTrackerItem was null or undefined when calling updateExpenseItemTracking.');
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ExpenseTrackerItem>(`${this.basePath}/organisation/shipments/expense_item_tracking`,
            ExpenseTrackerItem,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update expense tracking of the shipment
     * 
     * @param ExpenseTracker 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateExpenseTracking(ExpenseTracker: ExpenseTracker, observe?: 'body', reportProgress?: boolean): Observable<ExpenseTracker>;
    public updateExpenseTracking(ExpenseTracker: ExpenseTracker, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ExpenseTracker>>;
    public updateExpenseTracking(ExpenseTracker: ExpenseTracker, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ExpenseTracker>>;
    public updateExpenseTracking(ExpenseTracker: ExpenseTracker, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (ExpenseTracker === null || ExpenseTracker === undefined) {
            throw new Error('Required parameter ExpenseTracker was null or undefined when calling updateExpenseTracking.');
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ExpenseTracker>(`${this.basePath}/organisation/shipments/expense_tracking`,
            ExpenseTracker,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * update shipment
     * Update a requested shipment for an organisation
     * @param Shipment 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateShipment(Shipment: Shipment, observe?: 'body', reportProgress?: boolean): Observable<Shipment>;
    public updateShipment(Shipment: Shipment, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Shipment>>;
    public updateShipment(Shipment: Shipment, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Shipment>>;
    public updateShipment(Shipment: Shipment, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (Shipment === null || Shipment === undefined) {
            throw new Error('Required parameter Shipment was null or undefined when calling updateShipment.');
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Shipment>(`${this.basePath}/organisation/shipments/shipment`,
            Shipment,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update the access of shipment shared with external user
     * 
     * @param ShipmentAccess 
     * @param docIds 
     * @param fileIds 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateShipmentAccess(ShipmentAccess: ShipmentAccess, docIds?: Array<string>, fileIds?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<ShipmentAccess>;
    public updateShipmentAccess(ShipmentAccess: ShipmentAccess, docIds?: Array<string>, fileIds?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ShipmentAccess>>;
    public updateShipmentAccess(ShipmentAccess: ShipmentAccess, docIds?: Array<string>, fileIds?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ShipmentAccess>>;
    public updateShipmentAccess(ShipmentAccess: ShipmentAccess, docIds?: Array<string>, fileIds?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (ShipmentAccess === null || ShipmentAccess === undefined) {
            throw new Error('Required parameter ShipmentAccess was null or undefined when calling updateShipmentAccess.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (docIds) {
            queryParameters = queryParameters.set('docIds', docIds.join(COLLECTION_FORMATS['csv']));
        }
        if (fileIds) {
            queryParameters = queryParameters.set('fileIds', fileIds.join(COLLECTION_FORMATS['csv']));
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ShipmentAccess>(`${this.basePath}/organisation/shipments/update_shipment_access`,
            ShipmentAccess,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update the shipment checklist item
     * 
     * @param ShipmentChecklist 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateShipmentChecklist(ShipmentChecklist: ShipmentChecklist, observe?: 'body', reportProgress?: boolean): Observable<ShipmentChecklist>;
    public updateShipmentChecklist(ShipmentChecklist: ShipmentChecklist, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ShipmentChecklist>>;
    public updateShipmentChecklist(ShipmentChecklist: ShipmentChecklist, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ShipmentChecklist>>;
    public updateShipmentChecklist(ShipmentChecklist: ShipmentChecklist, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (ShipmentChecklist === null || ShipmentChecklist === undefined) {
            throw new Error('Required parameter ShipmentChecklist was null or undefined when calling updateShipmentChecklist.');
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ShipmentChecklist>(`${this.basePath}/organisation/shipments/shipment_checklist`,
            ShipmentChecklist,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update the staus of the Shipment Manually
     * 
     * @param shipmentId 
     * @param shipmentStatus 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateShipmentStatus(shipmentId: number, shipmentStatus: string, observe?: 'body', reportProgress?: boolean): Observable<ShipmentDialogue>;
    public updateShipmentStatus(shipmentId: number, shipmentStatus: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ShipmentDialogue>>;
    public updateShipmentStatus(shipmentId: number, shipmentStatus: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ShipmentDialogue>>;
    public updateShipmentStatus(shipmentId: number, shipmentStatus: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (shipmentId === null || shipmentId === undefined) {
            throw new Error('Required parameter shipmentId was null or undefined when calling updateShipmentStatus.');
        }

        if (shipmentStatus === null || shipmentStatus === undefined) {
            throw new Error('Required parameter shipmentStatus was null or undefined when calling updateShipmentStatus.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (shipmentId !== undefined && shipmentId !== null) {
            queryParameters = queryParameters.set('shipmentId', <any>shipmentId);
        }
        if (shipmentStatus !== undefined && shipmentStatus !== null) {
            queryParameters = queryParameters.set('shipmentStatus', <any>shipmentStatus);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.put<ShipmentDialogue>(`${this.basePath}/organisation/shipments/update_shipment_status`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update the shipment tracking
     * 
     * @param ShipmentTracking 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateShipmentTracking(ShipmentTracking: ShipmentTracking, observe?: 'body', reportProgress?: boolean): Observable<ShipmentTracking>;
    public updateShipmentTracking(ShipmentTracking: ShipmentTracking, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ShipmentTracking>>;
    public updateShipmentTracking(ShipmentTracking: ShipmentTracking, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ShipmentTracking>>;
    public updateShipmentTracking(ShipmentTracking: ShipmentTracking, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (ShipmentTracking === null || ShipmentTracking === undefined) {
            throw new Error('Required parameter ShipmentTracking was null or undefined when calling updateShipmentTracking.');
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ShipmentTracking>(`${this.basePath}/organisation/shipments/shipment_tracking`,
            ShipmentTracking,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * upload an attachment to the shipment
     * 
     * @param shipmentId 
     * @param folderId 
     * @param docType SHIPMENT_DOCUMENT_TYPE enum only
     * @param file1 
     * @param file2 
     * @param file3 
     * @param file4 
     * @param file5 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public uploadAttachment(shipmentId: number, folderId: number, docType: string, file1: Blob, file2?: Blob, file3?: Blob, file4?: Blob, file5?: Blob, observe?: 'body', reportProgress?: boolean): Observable<ShipmentDocumentFolder>;
    public uploadAttachment(shipmentId: number, folderId: number, docType: string, file1: Blob, file2?: Blob, file3?: Blob, file4?: Blob, file5?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ShipmentDocumentFolder>>;
    public uploadAttachment(shipmentId: number, folderId: number, docType: string, file1: Blob, file2?: Blob, file3?: Blob, file4?: Blob, file5?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ShipmentDocumentFolder>>;
    public uploadAttachment(shipmentId: number, folderId: number, docType: string, file1: Blob, file2?: Blob, file3?: Blob, file4?: Blob, file5?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (shipmentId === null || shipmentId === undefined) {
            throw new Error('Required parameter shipmentId was null or undefined when calling uploadAttachment.');
        }

        if (folderId === null || folderId === undefined) {
            throw new Error('Required parameter folderId was null or undefined when calling uploadAttachment.');
        }

        if (docType === null || docType === undefined) {
            throw new Error('Required parameter docType was null or undefined when calling uploadAttachment.');
        }

        if (file1 === null || file1 === undefined) {
            throw new Error('Required parameter file1 was null or undefined when calling uploadAttachment.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (shipmentId !== undefined && shipmentId !== null) {
            queryParameters = queryParameters.set('shipmentId', <any>shipmentId);
        }
        if (folderId !== undefined && folderId !== null) {
            queryParameters = queryParameters.set('folderId', <any>folderId);
        }
        if (docType !== undefined && docType !== null) {
            queryParameters = queryParameters.set('docType', <any>docType);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file1 !== undefined) {
            formParams.append('file1', <any>file1);
        }
        if (file2 !== undefined) {
            formParams.append('file2', <any>file2);
        }
        if (file3 !== undefined) {
            formParams.append('file3', <any>file3);
        }
        if (file4 !== undefined) {
            formParams.append('file4', <any>file4);
        }
        if (file5 !== undefined) {
            formParams.append('file5', <any>file5);
        }

        return this.httpClient.post<ShipmentDocumentFolder>(`${this.basePath}/organisation/shipments/upload_attachment`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
