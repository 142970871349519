import { Component, OnInit } from '@angular/core';
import { OpenService, User } from 'src/swagger';
import { SwalHelper } from 'src/app/swal';
import { GlobalProfileService } from 'src/app/global-profile.service';
import { Router } from '@angular/router';
import { CommonSupportService } from 'src/app/common-support.service';
// import { ReCaptchaV3Service } from 'ng-recaptcha';
declare var Swal: any;

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  public newUser: User = {};
  public shouldShowLoader: boolean;
  public companyName: string;
  public country: string;
  public confPassword: string;
  public userType: string;

  // public isCaptchaResolved = true;

  constructor(
    private openApi: OpenService,
    public profileSync: GlobalProfileService,
    public router: Router,
    public commonService: CommonSupportService,
    // private recaptchaV3Service: ReCaptchaV3Service
  ) { }

  ngOnInit(): void {
    this.userType = 'company';
    this.country = 'India';
  }

  /* Fn to create the new organisation & it's user */
  createNewOrg(form): boolean {
  //   this.recaptchaV3Service.execute('')
  //   .subscribe((token: string) => {
  //     this.newUser.recaptcha = token;
  //     this.isCaptchaResolved = true;
  //     if (form.valid && this.confPassword === this.newUser.password) {
  //       this.shouldShowLoader = true;
  //       this.openApi.signupNewOrganisation(
  //         this.newUser,
  //         this.companyName,
  //         this.country
  //       ).subscribe(user => {
  //         this.shouldShowLoader = false;
  //         Swal.fire({
  //           title: 'Congratulations!',
  //           type: 'success',
  //           text: `Successfully Signed Up! Please visit ${this.newUser.email} to verify your account.`,
  //           showConfirmButton: true,
  //         }).then((result) => {
  //           if (result.value) {
  //             form.reset();
  //             this.router.navigate(['/login']);
  //           }
  //         });
  //       }, err => {
  //         this.shouldShowLoader = false;
  //         SwalHelper.showErrorSwal(err);
  //       });
  //     } else {
  //       return false;
  //     }
  // })

  if (form.valid && this.confPassword === this.newUser.password) {
    this.shouldShowLoader = true;
    this.openApi.signupNewOrganisation(
      this.newUser,
      this.companyName,
      this.country
    ).subscribe(user => {
      this.shouldShowLoader = false;
      Swal.fire({
        title: 'Congratulations!',
        type: 'success',
        text: `Successfully Signed Up! Please visit ${this.newUser.email} to verify your account.`,
        showConfirmButton: true,
      }).then((result) => {
        if (result.value) {
          form.reset();
          this.router.navigate(['/login']);
        }
      });
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  } else {
    return false;
  }
  return false;
  }

  // resolved(captchaResponse: string): void {
  //   this.newUser.recaptcha = captchaResponse;
  //   this.isCaptchaResolved = true;
  // }

  // errored(): void {
  //   console.warn(`reCAPTCHA error encountered`);
  // }


}
