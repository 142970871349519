import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { ComponentCanDeactivate } from './component-can-deactivate';
declare var Swal: any;

@Injectable({
  providedIn: 'root'
})
export class CanDeactivateGuardGuard implements CanDeactivate<ComponentCanDeactivate> {
  canDeactivate(component: ComponentCanDeactivate): boolean {

    if (!component.canDeactivate()){
      if (confirm('Hey - You have not saved the changes. If you leave, your changes will be lost')) {
            return true;
        } else {
            return false;
        }
    }
    return true;
  }

}
