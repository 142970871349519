import { Component, OnDestroy, OnInit } from '@angular/core';
import { SwalHelper } from 'src/app/swal';
import { BillOfEntry, ShipmentDocsService } from 'src/swagger';
import { Router } from '@angular/router';
declare var $: any;
declare var swal: any;
import * as moment from 'moment';
import { GlobalProfileService } from 'src/app/global-profile.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-bill-of-entry',
  templateUrl: './bill-of-entry.component.html',
  styleUrls: ['./bill-of-entry.component.scss']
})
export class BillOfEntryComponent implements OnInit, OnDestroy {

  public rightSheet = false;
  public boe: BillOfEntry = {};
  public shouldShowLoader: boolean;
  public boeCreationType;
  public boeFile: File;
  public boeFileName: string;
  public allBoe: BillOfEntry[] = [];
  public startDate: any;
  public endDate: any;
  public status: string;

  dtOptions: DataTables.Settings = {};

  private userSubscription: Subscription;

  constructor(
    public router: Router,
    private shipmentDocApi: ShipmentDocsService,
    public profileSync: GlobalProfileService
  ) {  }

  ngOnInit(): void {
    this.shouldShowLoader = true;
    this.initBOE();
    this.dtOptions = {
      columnDefs: [
        { targets: 0, width: '40px' },
        { targets: -1, width: '80px' },
        { orderable: false, targets: -1 }
      ]
    };
    this.userSubscription = this.profileSync.user$.pipe(take(1)).subscribe(user => {
      if (user && user.OrganisationRole && user.OrganisationRole.permissions && user.OrganisationRole.permissions.BillOfEntry.read) {
        this.getBoeList();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  initBOE(): void {
    this.boeCreationType = 'new';
    this.boe.mode = 'Sea';
    this.boe.beDate = moment().format('YYYY-MM-DD');
  }

  getBoeList(): void {
    this.allBoe = [];
    this.shouldShowLoader = true;
    this.shipmentDocApi.listBillOfEntrys(
      this.startDate ? moment(this.startDate).format('YYYY-MM-DD') : null,
      this.endDate ? moment(this.endDate).format('YYYY-MM-DD') : null,
      this.status ? this.status : undefined,
    )
      .subscribe(billOfEntrys => {
        this.shouldShowLoader = false;
        if (billOfEntrys && billOfEntrys.length > 0) {
          this.allBoe = billOfEntrys;
        }
        else {
          this.allBoe = null;
        }
      }, err => {
        this.shouldShowLoader = false;
        SwalHelper.showErrorSwal(err);
      });
  }

  fileChange(event): void {
    this.boeFileName = event.target.files[0].name;
    this.boeFile = event.target.files[0];
  }

  newBoe(): void {
    $('#boeModal').modal('hide');
    this.shouldShowLoader = true;
    if (this.boeCreationType === 'new') {
      this.boe.beDate = moment(this.boe.beDate).format('YYYY-MM-DD');
      this.shipmentDocApi.createBillOfEntry(
        this.boe
      ).subscribe(boe => {
        if (boe) {
          this.shouldShowLoader = false;
          this.boe = boe;
          this.router.navigate([`organisation/shipment/shipment-documents/bill-of-entry/edit-bill-of-entry/${this.boe.billOfEntryId}`]);
        }
      }, err => {
        this.shouldShowLoader = false;
        SwalHelper.showErrorSwal(err);
      });
    } else {
      this.shipmentDocApi.importBoe(
        this.boeFile
      ).subscribe(boe => {
        if (boe) {
          this.shouldShowLoader = false;
          this.boe = boe;
          this.router.navigate([`organisation/shipment/shipment-documents/bill-of-entry/edit-bill-of-entry/${this.boe.billOfEntryId}`]);
        }
      }, err => {
        this.shouldShowLoader = false;
        SwalHelper.showErrorSwal(err);
      });
    }
  }

  deleteBoe(i): void {
    swal({
      title: 'Delete Bill of Entry',
      text: 'This cannot be undone',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        this.shouldShowLoader = true;
        return new Promise((resolve, reject) => {
          this.shipmentDocApi.deleteBillOfEntry(this.allBoe[i].billOfEntryId)
          .subscribe(ok => {
            this.shouldShowLoader = false;
            this.allBoe.splice(i, 1);
            resolve(ok);
          }, err => {
            reject(err);
          });
        });
      },
      allowOutsideClick: () => !swal.isLoading()
    }).then((result) => {
      if (result.value) {
        this.shouldShowLoader = false;
        SwalHelper.successTimerSwal('Bill of Entry deleted!');
      }
    }).catch(err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  resetFilter(): void {
    this.startDate = undefined;
    this.endDate = undefined;
    this.status = undefined;
    this.getBoeList();
  }

  /**
  * Fn to open the shipment link modal with bill of entry details
  * @param index Index of bill of entry to shipment link
  */
   openLinkShipment(index: number): void {
    localStorage.setItem('IS_LINK_SHIPMENT', 'true');
    this.router.navigate([`/organisation/shipment/shipment-documents/bill-of-entry/bill-of-entry-details/${this.allBoe[index].billOfEntryId}`]);
  }

}
