import { Component, OnInit , Output, EventEmitter} from '@angular/core';
import { take } from 'rxjs/operators';
import { AuthService, User } from 'src/swagger';
import { SwalHelper } from 'src/app/swal';
import { GlobalProfileService } from '../../../global-profile.service';
import { CommonSupportService } from 'src/app/common-support.service';

@Component({
  selector: 'app-user-profile-update',
  templateUrl: './user-profile-update.component.html',
  styleUrls: ['./user-profile-update.component.scss']
})
export class UserProfileUpdateComponent implements OnInit {

  public shouldShowLoader = false;
  public userProfile: User = {};
  @Output() servProvProfileSync = new EventEmitter<User>();

  constructor(
    private auth: AuthService,
    public profileSync: GlobalProfileService,
    public commonService: CommonSupportService
  ) { }

  ngOnInit(): void {
    this.shouldShowLoader = true;
    this.profileSync.user$
    .pipe(take(1))
    .subscribe(profile => {
      this.shouldShowLoader = false;
      this.userProfile = profile;
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

/* Fn to update the user profile */
  updateProfile(): void {
    this.shouldShowLoader = true;
    this.auth.updateProfile(this.userProfile)
    .subscribe(profile => {
      this.shouldShowLoader = false;
      this.profileSync.user = this.userProfile;
      this.servProvProfileSync.next(this.profileSync.user);
      SwalHelper.successTimerSwal('Details Updated');
    } , err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err.message);
    });
  }

}
