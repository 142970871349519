import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { SignupComponent } from './auth/signup/signup.component';
import { ExchangeAccessSecretComponent } from './auth/exchange-access-secret/exchange-access-secret.component';
import { ShipmentAuthenticatedGuard } from './shipment/shipment-authenticated.guard';
import { MagicLinkComponent } from './magic-link/magic-link.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { OrganisationGuardService } from './organisation/organisation-guard.service';
import { ChangePasswordComponent } from './auth/change-password/change-password.component';
import { SetNewPasswordComponent } from './auth/set-new-password/set-new-password.component';
import { OrganisationModule } from './organisation/organisation.module';
import { ShipmentModule } from './shipment/shipment.module';
import { ExternalUserFrameComponent } from './external-user-frame/external-user-frame.component';
import { VerifyUserComponent } from './auth/verify-user/verify-user.component';
import { ServiceProviderModule } from './service-provider/service-provider.module';
import { ServiceProviderGuard } from './service-provider/service-provider.guard';
import { NotFoundComponent } from './not-found/not-found.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'change-password/:uuid',
    component: ChangePasswordComponent
  },
  {
    path: 'setnew-password/:token',
    component: SetNewPasswordComponent
  },
  {
    path: 'signup',
    component: SignupComponent
  },
  {
    path: 'verify-user/:token',
    component: VerifyUserComponent
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  },
  {
    path: 'exchange_access_secret',
    component: ExchangeAccessSecretComponent
  },
  {
    path: 'organisation',
    canLoad: [OrganisationGuardService],
    canActivate: [OrganisationGuardService],
    canActivateChild: [OrganisationGuardService],
    loadChildren: () => import('./organisation/organisation.module')
      .then(m => m.OrganisationModule)
  },
  {
    path: 'welcome',
    canLoad: [OrganisationGuardService],
    canActivate: [OrganisationGuardService],
    canActivateChild: [OrganisationGuardService],
    loadChildren: () => import('./onboarding/onboarding.module')
      .then(m => m.OnboardingModule)
  },
  {
    path: 'shipment',
    component: ExternalUserFrameComponent,
    canActivate: [ShipmentAuthenticatedGuard],
    loadChildren: () => import('./shipment/shipment.module')
      .then(m => m.ShipmentModule)
  },
  {
    path: 'service-provider',
    canLoad: [ServiceProviderGuard],
    canActivate: [ServiceProviderGuard],
    canActivateChild: [ServiceProviderGuard],
    loadChildren: () => import('./service-provider/service-provider.module')
      .then(m => m.ServiceProviderModule)
  },
  {
    path: 'access-link',
    component: MagicLinkComponent
  },
  {
    path: '404',
    component: NotFoundComponent
  },
  {
    path: '**',
    redirectTo: '/404'
  }
];

@NgModule({
  imports: [
    OrganisationModule,
    ShipmentModule,
    ServiceProviderModule,
    RouterModule.forRoot(routes,{ useHash : true })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
