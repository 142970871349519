import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { Shipment } from '../../../swagger/model/shipment';

@Injectable({
  providedIn: 'root'
})
export class ShipmentDetailService {

  public shipmentBackdrop = false;
  public isFilesAvailable = false;
  public isFilesAvailable$ = new Subject<boolean>();

  public shipment: Shipment = null;
  public shipment$ = new BehaviorSubject<Shipment>(null);

  constructor() { }

  public updateFileAvaStatus(status: boolean): void {
    this.isFilesAvailable = status;
    this.isFilesAvailable$.next(status);
  }

  /* Update the shipment details */
  public updateShipmentDetails(shipment: Shipment): void {
    this.shipment = shipment;
    this.shipment$.next(shipment);
  }

}
