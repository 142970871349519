import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/swagger';
import { GlobalProfileService } from '../global-profile.service';
declare var $: any;

@Component({
  selector: 'app-service-provider',
  templateUrl: './service-provider.component.html',
  styleUrls: ['./service-provider.component.scss']
})
export class ServiceProviderComponent implements AfterViewInit, OnDestroy  {

  private loginSubscription: Subscription;
  public toggleSupport = false;
  public navToggler = true;

  constructor(
    public profileSync: GlobalProfileService,
    public authApi: AuthService,
    public router: Router,
  ) { }

  ngAfterViewInit(): void {
    console.log('Service provider Component initiated');
    this.loginSubscription = this.profileSync.isUserLoggedIn$.subscribe(isUserLoggedIn => {
      if (isUserLoggedIn) {
        this.authApi.getProfile().subscribe(user => {
          this.profileSync.updateUserProfile(user);
        }, err => {
          this.router.navigate(['/login']);
        });
      } else {
        this.router.navigate(['/login']);
      }
    });
  }

  ngOnDestroy(): void {
    if (this.loginSubscription) {
      this.loginSubscription.unsubscribe();
    }
  }

  logout(): void {
    this.profileSync.clearToken();
    this.router.navigate(['/login']);
  }

  updatedData(user): void{
    this.profileSync.updateUserProfile(user);
  }

  navToggle(): void {
    this.navToggler = !this.navToggler;
  }

  closeDropdown(): void {
    $('.nav-collapse').collapse('hide');
  }

}
