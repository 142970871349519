import { Component, OnInit } from '@angular/core';
import { SwalHelper } from '../../../swal';
import { GlobalProfileService } from '../../../global-profile.service';
import { CommonSupportService } from '../../../common-support.service';
import { OrganisationHelperService } from 'src/app/organisation/organisation-helper.service';
import { ExpenseTrackerItem, OrganisationService, ReportsService, VendorAndSupplier } from 'src/swagger';
import * as moment from 'moment';

@Component({
  selector: 'app-revenue-analysis',
  templateUrl: './revenue-analysis.component.html',
  styleUrls: ['./revenue-analysis.component.scss']
})
export class RevenueAnalysisComponent implements OnInit {

  public shouldShowLoader: boolean;
  public expenses: ExpenseTrackerItem[] = [];

  public fromDate: string;
  public toDate: string;
  public status: string;
  public invStatus: string;
  public currency: string;
  public shipmentType: string;
  public trackType: string;
  public revenueType: string;

  public allVendors: VendorAndSupplier[] = [];

  public vendorId: number;
  dtOptions: any = {};

  constructor(
    public profileSync: GlobalProfileService,
    private orgApi: OrganisationService,
    private orgReportApi: ReportsService,
    public orgHelper: OrganisationHelperService,
    public commonService: CommonSupportService
  ) {
  }

  ngOnInit(): void {
    this.fetchAllVendors();
    this.resetFilter();
    this.dtOptions = {
      dom: 'Bfrtip',
      lengthMenu: [
        [10, 25, 50, -1],
        ['10', '25', '50', 'All']
      ],
      buttons: [
        'pageLength',
        {
          text: 'Excel Download',
          className: 'btn btn-green',
          extend: 'excelHtml5',
          title: 'Expense-Report',
        }
      ],
      columnDefs: [
        { targets: [4, 5, 6, 7, 8], type: 'num-fmt' },
      ],
    };
  }

  /* Fn to fetch the all the expenses */
  fetchExpenses(): void {
    this.expenses = [];
    this.shouldShowLoader = true;
    this.orgReportApi.expenseAnalysis(
      'Revenue',
      this.revenueType === 'All' ? undefined : this.revenueType,
      this.shipmentType,
      this.fromDate ? moment(this.fromDate).format('YYYY-MM-DD') : null,
      this.toDate ? moment(this.toDate).format('YYYY-MM-DD') : null,
      this.status === 'All' ? undefined : this.status,
      this.currency === 'All' ? undefined : this.currency,
      this.invStatus === 'All' ? undefined : this.invStatus,
      this.vendorId  == 0 ? undefined : this.vendorId,
    ).subscribe(expenses => {
      this.shouldShowLoader = false;
      if (expenses && expenses.length > 0) {
        this.expenses = expenses;
      } else {
        this.expenses = [];
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /* Fn to fetch all the vendors in filter */
  fetchAllVendors(): void {
    this.shouldShowLoader = true;
    this.orgApi.searchVendors(
      undefined,
      undefined,
      100
    ).subscribe(vendors => {
      this.shouldShowLoader = false;
      if (vendors && vendors.length > 0) {
        this.allVendors = vendors;
      } else {
        this.allVendors = null;
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /* Fn to reset the filter */
  resetFilter(): void {
    this.toDate = undefined;
    this.fromDate = undefined;
    this.status = 'All';
    this.shipmentType = 'SELL';
    this.currency = 'All';
    this.vendorId = 0;
    this.invStatus = 'All';
    this.revenueType = 'All';
    this.fetchExpenses();

  }
}
