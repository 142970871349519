import { Component, OnInit, Renderer2, ChangeDetectorRef, OnDestroy, ViewChild } from '@angular/core';
import { SalesOrder, OrganisationService, ItemMaster, Page, SalesOrderItem, VendorAndSupplier, OrgOrdersService, OrganisationBankAccount } from 'src/swagger';
import { ActivatedRoute, Router } from '@angular/router';
import { SwalHelper } from 'src/app/swal';
import { Subject, Subscription, of, interval } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CommonSupportService } from 'src/app/common-support.service';
import { distinctUntilChanged, tap, switchMap, catchError, filter, skip } from 'rxjs/operators';
import { GlobalProfileService } from 'src/app/global-profile.service';
import { differenceWith } from 'lodash-es';
import { environment } from 'src/environments/environment';
import { ComponentCanDeactivate } from 'src/app/form-route-deactivate/component-can-deactivate';
import { NgForm } from '@angular/forms';
import { OrganisationHelperService } from '../../organisation-helper.service';
import * as moment from 'moment';
import { SelectorDiv } from 'src/app/enums';
import { NumberToWordsPipe } from 'src/app/pipes/number-to-words.pipe';
declare var $: any;
declare var Swal: any;

@Component({
  selector: 'app-sales-order-update',
  templateUrl: './sales-order-update.component.html',
  styleUrls: ['./sales-order-update.component.scss']
})
export class SalesOrderUpdateComponent extends ComponentCanDeactivate implements OnInit, OnDestroy {

  @ViewChild('SOForm')
  public SOFormRef: NgForm;

  private soOCRSubscription: Subscription;

  public salesOrderDetails: SalesOrder = {};
  public shouldShowLoader: boolean;
  public statusEnum = SalesOrder.StatusEnum;

  public vendorSuppliers: VendorAndSupplier[] = [];
  public ocrResults: Page[] = [];

  public newItem: SalesOrderItem = {};
  public buyerDetail: VendorAndSupplier = {};
  public consigneeDetail: VendorAndSupplier = {};
  public notifyPartyDetails: VendorAndSupplier = {};
  public notifyParty2Details: VendorAndSupplier = {};

  public itemMasters$: Subject<ItemMaster[]> = new Subject();
  public itemChange$: Subject<string> = new Subject();
  private itemChangeSubscription: Subscription;
  public itemLoading: boolean;

  public thisPage: any;
  public bgwidth;
  public bgheight;

  public copyText = 'Click to copy';
  public selectedImageURL: string;
  public totalPages = [];

  public selectors: SelectorDiv[] = [];
  public basePath: string;

  public rightSheet = false;

  public salesOrderFiles: File[] = [];
  public itemsCustomField = [];

  public selectedVendor: number;
  public newContactType: string;

  public itemMasterDetail: ItemMaster = {};
  public customItemIndex: number;
  public isRedirectToPreview: boolean;

  public orgBanks: OrganisationBankAccount[] = [];

  constructor(
    public http: HttpClient,
    private orgApi: OrganisationService,
    private activatedRoute: ActivatedRoute,
    public commonService: CommonSupportService,
    private renderer: Renderer2,
    private changeDetectorRef: ChangeDetectorRef,
    private orgOrderApi: OrgOrdersService,
    public profileSync: GlobalProfileService,
    private router: Router,
    public orgHelper: OrganisationHelperService
  ) {
    super();
    commonService.renderer = renderer;
    this.basePath = environment.BASE_PATH;
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(param => {
      if (param && param.soId) {
        this.fetchSalesOrderDetails(param.soId);
      }
    });
    this.fetchVendorSupplier();
    this.itemChangeSubscription = this.itemChange$.pipe(
      distinctUntilChanged(),
      tap(() => this.itemLoading = true),
      switchMap(query => this.orgApi.listItemMaster(query).pipe(
        catchError(() => of([])), // empty list on error
        tap(() => {
          this.itemLoading = false;
        })
      ))
    ).subscribe(items => {
      this.itemMasters$.next(items);
    });
  }

  ngOnDestroy(): void {
    if (this.soOCRSubscription) {
      this.soOCRSubscription.unsubscribe();
    }
    if (this.itemChangeSubscription) {
      this.itemChangeSubscription.unsubscribe();
    }
  }

  /* to clear the contact information */
  clearContactInfo(): void {
    this.salesOrderDetails.contactPerson = {};
  }

  canDeactivate(): boolean {
    return !this.SOFormRef.dirty;
  }

  rightSheetOpen(): void {
    this.rightSheet = true;
    this.renderer.addClass(document.body, 'modal-open');
    this.commonService.addBackdrop();
  }

  rightSheetClose(): void {
    this.salesOrderDetails.buyerId = this.salesOrderDetails.buyerId > 0 ? this.salesOrderDetails.buyerId : null;
    this.salesOrderDetails.consigneeId = this.salesOrderDetails.consigneeId > 0 ? this.salesOrderDetails.consigneeId : null;
    this.salesOrderDetails.notifyPartyId = this.salesOrderDetails.notifyPartyId > 0 ? this.salesOrderDetails.notifyPartyId : null;
    this.salesOrderDetails.notifyPartyId2 = this.salesOrderDetails.notifyPartyId2 > 0 ? this.salesOrderDetails.notifyPartyId2 : null;
    this.rightSheet = false;
    this.renderer.removeClass(document.body, 'modal-open');
    this.commonService.removeBackdrop();
  }

  calTotalQuantity(index: number): void {
    this.salesOrderDetails.SalesOrderItems[index].itemQuantity = parseFloat((this.salesOrderDetails.SalesOrderItems[index].noOfPackages * this.salesOrderDetails.SalesOrderItems[index].itemQuantityPerPack).toFixed(3));
    this.calAmount();
  }

  calAmount(): void {
    this.salesOrderDetails.soTotalAmount = this.salesOrderDetails.SalesOrderItems.map(i => i.itemPrice * i.itemQuantity).reduce((a, b) => a + b, 0);
    this.salesOrderDetails.soTotalQuantity = this.salesOrderDetails.SalesOrderItems.map(i => i.itemQuantity).reduce((a, b) => a + b, 0);
  }

  fetchBankDetails(): void {
    this.orgApi.getOrganisationBankAccount().subscribe(banks => {
      if (banks && banks.length > 0) {
        this.orgBanks = banks;
        let bankDetails;
        if (banks.filter(i => i.isPrimary).length > 0) {
          bankDetails = banks.filter(i => i.isPrimary)[0];
        } else {
          bankDetails = banks[0];
        }
        if (!this.salesOrderDetails.bankDetailsInfo || !this.salesOrderDetails.bankDetailsInfo.isCancelled) {
          this.selectBankDetails(bankDetails);
        }
      }
    }, err => {
      SwalHelper.showErrorSwal(err);
    });
  }

  selectBankDetails(bank: OrganisationBankAccount): void {
    if (bank) {
      this.salesOrderDetails.bankDetailsInfo.bankId = bank.bankId;
      this.salesOrderDetails.bankDetailsInfo.bankName = bank.bankName;
      this.salesOrderDetails.bankDetailsInfo.accountNo = bank.accountNumber;
      this.salesOrderDetails.bankDetailsInfo.swiftCode = bank.swiftCode;
      this.salesOrderDetails.bankDetailsInfo.title = bank.accountTitle;
      this.salesOrderDetails.bankDetailsInfo.branchAddress = bank.branchAddress;
      this.salesOrderDetails.bankDetailsInfo.intermediaryBankName = bank.intermediaryBankName;
      this.salesOrderDetails.bankDetailsInfo.intermediarySwiftCode = bank.intermediarySwiftCode;
      this.salesOrderDetails.bankDetailsInfo.ifsc = bank.ifscCode;
      this.salesOrderDetails.bankDetailsInfo.otherData = bank.otherData;
      this.salesOrderDetails.bankDetailsInfo.isCancelled = true;
    } else {
      this.salesOrderDetails.bankDetailsInfo = {};
      this.salesOrderDetails.bankDetailsInfo.isCancelled = true;
    }
  }

  checkItem(event, index: number): void {
    if (event) {
      if (event.itemMasterId) {
        const tempOtherData = this.salesOrderDetails.SalesOrderItems[index].otherData;
        this.salesOrderDetails.SalesOrderItems[index] = event;
        this.salesOrderDetails.SalesOrderItems[index].otherData = tempOtherData ? tempOtherData : this.orgHelper.copyObject(this.itemsCustomField);
      } else {
        $('#itemMasterModal').modal('show');
        this.customItemIndex = index;
        this.itemMasterDetail.itemCode = event.itemCode;
        this.itemMasterDetail.itemCurrency = this.salesOrderDetails.currency;
      }
    } else {
      this.salesOrderDetails.SalesOrderItems[index].itemCode = undefined;
    }
  }

  fetchVendorSupplier(): void {
    this.shouldShowLoader = true;
    this.orgApi.listVendorSupplier(
      undefined
    ).subscribe(vendors => {
      if (vendors && vendors.length > 0) {
        this.shouldShowLoader = false;
        this.vendorSuppliers = vendors;
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  addItem(): void {
    const item: SalesOrderItem = {};
    item.itemQuantity = 1;
    item.itemPrice = 1;
    item.otherData = this.orgHelper.copyObject(this.itemsCustomField);
    this.salesOrderDetails.SalesOrderItems.push(item);
    this.calAmount();
  }

  removeItem(index: number): void {
    Swal.fire({
      title: 'Remove Item?',
      text: 'Are you sure want to delete this Item?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete',
    }).then((result) => {
      if (result.value) {
        this.salesOrderDetails.SalesOrderItems.splice(index, 1);
        this.calAmount();
        SwalHelper.showToast('success', 'Item deleted!');
      }
    });
  }

  removeColumn(index: number): void {
    Swal.fire({
      title: 'Remove Column?',
      text: 'Are you sure want to delete this Item?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete',
    }).then((result) => {
      if (result.value) {
        const otherDataIndex = this.salesOrderDetails.SalesOrderItems[0].otherData.findIndex(i => i.key == this.itemsCustomField[index].key);
        if (otherDataIndex >= 0) {
          this.salesOrderDetails.SalesOrderItems.forEach(i => {
            i.otherData.splice(otherDataIndex, 1);
          });
        }
        this.itemsCustomField.splice(index, 1);
        SwalHelper.showToast('success', 'Item deleted!');
      }
    });
  }

  fetchItemCustomFields(): void {
    this.orgApi.getCustomField('Sales Order Items').subscribe(fields => {
      if (fields && fields.length > 0) {
        if (this.itemsCustomField.length > 0) {
          this.itemsCustomField = this.itemsCustomField.map(item => {
            const missingCustomFields = differenceWith(fields, item, (a, b) => {
              return a.fieldName === b.key;
            });
            missingCustomFields.map(mi => {
              const customField = {
                key: mi.fieldName,
                value: null,
                required: mi.isRequired,
                pattern: mi.fieldRegex,
                type: mi.fieldType
              };
              this.itemsCustomField.push(customField);
            });
            return item;
          });
        } else {
          this.itemsCustomField = fields.map(item => {
            return {
              key: item.fieldName,
              value: null,
              required: item.isRequired,
              pattern: item.fieldRegex,
              type: item.fieldType
            };
          });
        }
      }
      this.salesOrderDetails.SalesOrderItems = this.salesOrderDetails.SalesOrderItems.map(item => {
        if (!item.otherData) {
          item.otherData = [];
        }
        const missingItems = differenceWith(this.itemsCustomField, item.otherData, (a, b) => {
          return a.key === b.key;
        });
        missingItems.forEach(mi => {
          item.otherData.push(mi);
        });
        return item;
      });
      this.changeDetectorRef.detectChanges();
    }, err => {
      SwalHelper.showErrorSwal(err);
    });
  }

  fetchSalesOrderDetails(soId): void {
    this.shouldShowLoader = true;
    this.orgOrderApi.getSo(soId).subscribe(po => {
      this.shouldShowLoader = false;
      if (po) {
        this.salesOrderDetails = po;
        this.salesOrderDetails.SalesOrderItems = this.salesOrderDetails.SalesOrderItems.map(item => {
          const missingInvItems = differenceWith(item.otherData, this.itemsCustomField, (a, b) => {
            return a.key === b.key;
          });
          missingInvItems.map(mi => {
            const missingItem = this.orgHelper.copyObject(mi);
             // tslint:disable-next-line: no-string-literal
            missingItem['value'] = null;
            this.itemsCustomField.push(missingItem);
          });
          return item;
        });
        this.salesOrderDetails.bankDetailsInfo = this.salesOrderDetails.bankDetailsInfo ? this.salesOrderDetails.bankDetailsInfo : {};
        this.salesOrderDetails.bankDetailsInfo.isCancelled = this.salesOrderDetails.bankDetailsInfo.isCancelled ? this.salesOrderDetails.bankDetailsInfo.isCancelled : false;

        this.fetchItemCustomFields();
        this.fetchBankDetails();

        this.salesOrderDetails.currency = this.salesOrderDetails.currency ? this.salesOrderDetails.currency : 'USD';
        this.salesOrderDetails.SalesOrderItems = this.salesOrderDetails.SalesOrderItems ? this.salesOrderDetails.SalesOrderItems : [];
        this.salesOrderDetails.otherData = this.salesOrderDetails.otherData ? this.salesOrderDetails.otherData : {};
        this.buyerDetail = this.salesOrderDetails.VendorAndSupplier ? this.salesOrderDetails.VendorAndSupplier : {};
        this.consigneeDetail = this.salesOrderDetails.consigneeId ? this.salesOrderDetails.Consignee : {};
        this.notifyPartyDetails = this.salesOrderDetails.notifyPartyId ? this.salesOrderDetails.NotifyParty : {};
        this.notifyParty2Details = this.salesOrderDetails.notifyPartyId2 ? this.salesOrderDetails.NotifyParty2 : {};

        this.itemChange$.next(undefined);
        if (!this.salesOrderDetails.contactPerson) {
          this.initContactPerson();
        }
        if (this.salesOrderDetails.SalesOrderItems.length === 0) {
          this.addItem();
        }
        if (this.salesOrderDetails.ocrStatus !== 'FAILED' && this.salesOrderDetails.importedDocUrl) {
          this.soOCRSubscription = interval(3000).pipe(skip(3)).subscribe(tick => {
            this.orgApi.ocrResults('SalesOrder', this.salesOrderDetails.salesOrderId).pipe(
              catchError(err => of(null)),
              filter((result) => !!result),
            ).subscribe(results => {
              if (results && results.length > 0) {
                this.totalPages = results;
                this.soOCRSubscription.unsubscribe();
                this.ocrResults = results;
                this.loadData(0);
              }
            });
          });
        }
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  initContactPerson(): void {
    this.salesOrderDetails.contactPerson = {};
    this.salesOrderDetails.contactPerson = this.profileSync.user;
  }

  loadData(index: number): void {
    this.salesOrderDetails.ocrStatus = 'DONE';
    this.http.get(this.ocrResults[index].metadata).subscribe(data => {
      const dummyObj: any = data;
      this.selectedImageURL = this.ocrResults[index].background;
      this.thisPage = dummyObj.fullTextAnnotation.pages[0];
      this.bgwidth = this.thisPage.width;
      this.bgheight = this.thisPage.height;
      this.selectors = this.orgHelper.calBox(this.orgHelper);
      this.changeDetectorRef.detectChanges();
    }, err => {
      SwalHelper.showErrorSwal(err);
    });
  }

  selectText(str): void {
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    const selected =
      document.getSelection().rangeCount > 0
        ? document.getSelection().getRangeAt(0)
        : false;
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    if (selected) {
      document.getSelection().removeAllRanges();
      document.getSelection().addRange(selected);
      this.copyText = 'Copied';
    }
  }

  resetCopytext(): void {
    this.copyText = 'Click to copy';
  }

  selectBuyer(): void {
    if (this.salesOrderDetails.buyerId == -1) {
      this.rightSheetOpen();
      this.newContactType = 'Buyer';
      this.selectedVendor = undefined;
    } else {
      const index = this.vendorSuppliers.findIndex(i => i.venSupId == this.salesOrderDetails.buyerId);
      if (index >= 0) {
        this.buyerDetail = this.vendorSuppliers[index];
      }
    }
  }
  selectConsignee(): void {
    if (this.salesOrderDetails.consigneeId == -1) {
      this.rightSheetOpen();
      this.newContactType = 'Consignee';
      this.selectedVendor = undefined;
    } else {
      const index = this.vendorSuppliers.findIndex(i => i.venSupId == this.salesOrderDetails.consigneeId);
      if (index >= 0) {
        this.consigneeDetail = this.vendorSuppliers[index];
      }
    }
  }
  selectNotifyParty(): void {
    if (this.salesOrderDetails.notifyPartyId == -1) {
      this.rightSheetOpen();
      this.newContactType = 'Notify Party';
      this.selectedVendor = undefined;
    } else {
      const index = this.vendorSuppliers.findIndex(i => i.venSupId == this.salesOrderDetails.notifyPartyId);
      if (index >= 0) {
        this.notifyPartyDetails = this.vendorSuppliers[index];
      }
    }
  }
  selectNotifyParty2(): void {
    if (this.salesOrderDetails.notifyPartyId2 == -1) {
      this.rightSheetOpen();
      this.newContactType = 'Notify Party';
      this.selectedVendor = undefined;
    } else {
      const index = this.vendorSuppliers.findIndex(i => i.venSupId == this.salesOrderDetails.notifyPartyId2);
      if (index >= 0) {
        this.notifyParty2Details = this.vendorSuppliers[index];
      }
    }
  }

  editVendor(id, type): void {
    this.selectedVendor = id;
    this.newContactType = type;
    this.rightSheetOpen();
  }

  newVendor(vendor): void {
    if (!this.vendorSuppliers) {
      this.vendorSuppliers = [];
    }
    const index = this.vendorSuppliers.findIndex(i => i.venSupId == vendor.venSupId);
    if (index >= 0) {
      this.vendorSuppliers[index] = vendor;
    } else {
      this.vendorSuppliers.push(vendor);
    }
    this.salesOrderDetails.consigneeId = this.salesOrderDetails.consigneeId == -1 ? parseInt(vendor.venSupId, 10) : this.salesOrderDetails.consigneeId;
    this.salesOrderDetails.notifyPartyId = this.salesOrderDetails.notifyPartyId == -1 ? parseInt(vendor.venSupId, 10) : this.salesOrderDetails.notifyPartyId;
    this.salesOrderDetails.notifyPartyId2 = this.salesOrderDetails.notifyPartyId2 == -1 ? parseInt(vendor.venSupId, 10) : this.salesOrderDetails.notifyPartyId2;
    this.salesOrderDetails.buyerId = this.salesOrderDetails.buyerId == -1 ? parseInt(vendor.venSupId, 10) : this.salesOrderDetails.buyerId;
    this.selectConsignee();
    this.selectNotifyParty();
    this.selectNotifyParty2();
    this.selectBuyer();
    this.rightSheetClose();
    this.selectedVendor = undefined;
  }

  saveSalesOrder(form): boolean {
    if (form.valid) {
      if (this.salesOrderDetails.salesOrderId) {
        this.shouldShowLoader = true;
        // tslint:disable-next-line: max-line-length
        this.salesOrderDetails.buyerOrderDate = this.salesOrderDetails.buyerOrderDate ? moment(this.salesOrderDetails.buyerOrderDate).format('YYYY-MM-DD') : null;
        // tslint:disable-next-line: max-line-length
        this.salesOrderDetails.orgOrderDate = this.salesOrderDetails.orgOrderDate ? moment(this.salesOrderDetails.orgOrderDate).format('YYYY-MM-DD') : null;
        // tslint:disable-next-line: max-line-length
        this.salesOrderDetails.soDeliveryDate = this.salesOrderDetails.soDeliveryDate ? moment(this.salesOrderDetails.soDeliveryDate).format('YYYY-MM-DD') : null;
        // tslint:disable-next-line: max-line-length
        this.salesOrderDetails.soDispatchDate = this.salesOrderDetails.soDispatchDate ? moment(this.salesOrderDetails.soDispatchDate).format('YYYY-MM-DD') : null;
        this.salesOrderDetails.buyerId = this.salesOrderDetails.buyerId == -1 ? null : this.salesOrderDetails.buyerId;
        this.salesOrderDetails.notifyPartyId = this.salesOrderDetails.notifyPartyId == -1 ? null : this.salesOrderDetails.notifyPartyId;
        this.salesOrderDetails.notifyPartyId2 = this.salesOrderDetails.notifyPartyId2 == -1 ? null : this.salesOrderDetails.notifyPartyId2;

        this.orgOrderApi.updateSo(this.salesOrderDetails).subscribe(so => {
          if (so) {
            this.salesOrderDetails = so;
            this.shouldShowLoader = false;
            this.SOFormRef.form.markAsPristine();
            if (this.isRedirectToPreview) {
              this.router.navigate([`/organisation/sales-orders/sales-order-detail/${this.salesOrderDetails.salesOrderId}`]);
            } else {
              SwalHelper.successTimerSwal('Export Order updated successfully');
            }
          }
        }, err => {
          this.shouldShowLoader = false;
          SwalHelper.showErrorSwal(err);
        });
      }
    } else {
      return false;
    }
  }

  captureSalesOrderFile(event): void {
    if (event.target.files.length + this.salesOrderFiles.length > 5) {
      SwalHelper.showErrorSwal('Maximum 5 files allowed');
      return;
    }
    if (event.target.files && event.target.files.length > 0) {
      Object.keys(event.target.files).forEach((key) => {
        this.salesOrderFiles.push(event.target.files[key]);
      });
    }
  }

  uplaodNewDocument(): void {
    this.shouldShowLoader = true;
    this.orgOrderApi.uploadOrderAttachment(
      this.salesOrderFiles[0],
      undefined,
      this.salesOrderDetails.salesOrderId,
      this.salesOrderFiles[1],
      this.salesOrderFiles[2],
      this.salesOrderFiles[3],
      this.salesOrderFiles[4]
    ).subscribe(docs => {
      if (docs) {
        this.shouldShowLoader = false;
        SwalHelper.successTimerSwal('Document uploaded');
        $('#fileUploadModal').modal('hide');
        if (!this.salesOrderDetails.DocumentAttachments) {
          this.salesOrderDetails.DocumentAttachments = [];
        }
        this.salesOrderDetails.DocumentAttachments = docs;
        this.salesOrderFiles = []; // Clean the document file array
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  removeAttchment(index: number): void {
    Swal({
      title: 'Delete Attcahment',
      text: 'This cannot be undone',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        this.shouldShowLoader = true;
        return new Promise((resolve, reject) => {
          this.orgOrderApi.deleteOrderAttachment(
            this.salesOrderDetails.DocumentAttachments[index].docAttId,
            undefined,
            this.salesOrderDetails.salesOrderId
          ).subscribe(ok => {
            this.shouldShowLoader = false;
            this.salesOrderDetails.DocumentAttachments.splice(index, 1);
            resolve(ok);
          }, err => {
            reject(err);
          });
        });
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.value) {
        this.shouldShowLoader = false;
        SwalHelper.successTimerSwal('Attachment deleted!');
      }
    }).catch(err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /**
   * Fn to download & save document
   * @param docAttId Document Id to download signed URL
   */
  public downloadFile(docAttId: number): void {
    this.orgApi.orgDocumentSignedUrl(docAttId)
      .subscribe(url => {
        window.open(url.url, '_blank');
      });
  }

  /**
   * Fn to open the Item master details modal & save the index
   * @param index Index of Order Item
   */
  addNewItemMaster(index: number): void {
    $('#itemMasterModal').modal('show');
    this.customItemIndex = index;
  }

  /**
   * Fn to save the Item master details into DB
   * @param form Angular Form
   * @returns
   */
  saveItemDetails(form): boolean {
    if (form.valid) {
      this.shouldShowLoader = true;
      this.orgApi.addItemMaster(this.itemMasterDetail
      ).subscribe(item => {
        this.shouldShowLoader = false;
        this.salesOrderDetails.SalesOrderItems[this.customItemIndex].itemMasterId = item.itemMasterId;
        this.salesOrderDetails.SalesOrderItems[this.customItemIndex].itemName = item.itemName;
        this.salesOrderDetails.SalesOrderItems[this.customItemIndex].itemDescription = item.itemDescription;
        this.salesOrderDetails.SalesOrderItems[this.customItemIndex].itemSubCode = item.itemSubCode;
        this.salesOrderDetails.SalesOrderItems[this.customItemIndex].itemHSNCode = item.itemHSNCode;
        this.salesOrderDetails.SalesOrderItems[this.customItemIndex].itemCode = item.itemCode;
        this.salesOrderDetails.SalesOrderItems[this.customItemIndex].itemUnit = item.itemUnit;
        this.salesOrderDetails.SalesOrderItems[this.customItemIndex].itemPrice = item.itemPrice;
        this.itemMasterDetail = {};
        this.customItemIndex = undefined;
        $('#itemMasterModal').modal('hide');
        SwalHelper.successTimerSwal('Item Added!');
        this.rightSheetClose();
      }, err => {
        this.shouldShowLoader = false;
        SwalHelper.showErrorSwal(err);
      });
    } else {
      return false;
    }
  }

  /* Fn to close the item master details modal */
  closeItemMasterModal(): void {
    this.itemMasterDetail = {};
    $('#itemMasterModal').modal('hide');
    this.salesOrderDetails.SalesOrderItems[this.customItemIndex].itemCode = undefined;
    this.customItemIndex = undefined;
  }

  trackByVendId(index: number, vendor: any): string {
    return vendor.venSupId;
  }

  /* Fn to redirect to preview page */
  redirectToPreview(): void {
    this.isRedirectToPreview = true;
  }

  /**
   * Fn to copy the amount in words
   * @param amount Amount to be copied
   */
   copyNumberToWord(amount): void {
    const pipe = new NumberToWordsPipe();
    const fiteredArr = pipe.transform(amount, 'US');

    const el = document.createElement('textarea');
    el.value = fiteredArr;
    el.setAttribute('readonly', '');
    document.body.appendChild(el);
    const selected =
      document.getSelection().rangeCount > 0
        ? document.getSelection().getRangeAt(0)
        : false;
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    if (selected) {
      document.getSelection().removeAllRanges();
      document.getSelection().addRange(selected);
      SwalHelper.showCenteredSavedToast('Copied');
    }
  }
}
