import { Component, OnInit, HostListener, Renderer2 } from '@angular/core';
import { OrgShipmentsService } from '../../../swagger/api/orgShipments.service';
import { SwalHelper } from '../../swal';
import { ExpenseTracker } from '../../../swagger/model/expenseTracker';
import { GlobalProfileService } from '../../global-profile.service';
import { CommonSupportService } from '../../common-support.service';
import { OrganisationHelperService } from 'src/app/organisation/organisation-helper.service';
import { OrganisationService, VendorAndSupplier } from 'src/swagger';
import * as moment from 'moment';
@Component({
  selector: 'app-shipment-expenses',
  templateUrl: './shipment-expenses.component.html',
  styleUrls: ['./shipment-expenses.component.scss']
})
export class ShipmentExpensesComponent implements OnInit {

  public shouldShowLoader: boolean;
  public expenses: ExpenseTracker[] = [];
  public expenseDetails: ExpenseTracker = {};

  public bottomSheet = false;

  public fromDate: string;
  public toDate: string;
  public status: string;
  public currency: string;
  public shipmentType: string;

  public allVendors: VendorAndSupplier[] = [];

  public vendorId: number;
  dtOptions: any = {};

  constructor(
    private orgShipmentApi: OrgShipmentsService,
    public profileSync: GlobalProfileService,
    private renderer: Renderer2,
    private orgApi: OrganisationService,
    public orgHelper: OrganisationHelperService,
    public commonService: CommonSupportService
  ) {
    commonService.renderer = renderer;
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent): void {
    this.bottomSheet = false;
    this.renderer.removeClass(document.body, 'modal-open');
  }

  ngOnInit(): void {
    this.fetchAllVendors();
    this.resetFilter();
    this.dtOptions = {
      dom: 'Bfrtip',
      lengthMenu: [
        [10, 25, 50, -1],
        ['10', '25', '50', 'All']
      ],
      buttons: [
        'pageLength',
        {
          text: 'Excel Download',
          className: 'btn btn-green',
          extend: 'excelHtml5',
          title: 'Expense-Report',
        }
      ],
      columnDefs: [
        { targets: [4, 5, 6, 7, 8], type: 'num-fmt' },
      ],
    };
  }

  /* Fn to fetch the all the expenses */
  fetchExpenses(): void {
    this.expenses = [];
    this.shouldShowLoader = true;
    this.orgShipmentApi.listExpenseTracking(
      this.status === 'All' ? undefined : this.status,
      this.shipmentType === 'ALL' ? undefined : this.shipmentType,
      this.currency === 'All' ? undefined : this.currency,
      this.vendorId  == 0 ? undefined : this.vendorId,
      this.fromDate ? moment(this.fromDate).format('YYYY-MM-DD') : null,
      this.toDate ? moment(this.toDate).format('YYYY-MM-DD') : null,
    ).subscribe(expenses => {
      this.shouldShowLoader = false;
      if (expenses && expenses.length > 0) {
        this.expenses = expenses;
      } else {
        this.expenses = [];
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /* Fn to fetch all the vendors in filter */
  fetchAllVendors(): void {
    this.shouldShowLoader = true;
    this.orgApi.searchVendors(
      undefined,
      undefined,
      100
    ).subscribe(vendors => {
      this.shouldShowLoader = false;
      if (vendors && vendors.length > 0) {
        this.allVendors = vendors;
      } else {
        this.allVendors = null;
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /* Fn to open the details bottom sheet */
  bottomSheetOpen(): void {
    this.bottomSheet = true;
    this.renderer.addClass(document.body, 'modal-open');
    this.commonService.addBackdrop();
  }

  /* Fn to close the details bottom sheet */
  bottomSheetClose(): void {
    this.expenseDetails = {};
    this.bottomSheet = false;
    this.renderer.removeClass(document.body, 'modal-open');
    this.commonService.removeBackdrop();
  }

  /**
   * Fn to open the expense details in bottom sheet
   * @param index Index of Expense to view the details
   */
  openExpenseTracker(index: number): void {
    this.expenseDetails = this.expenses[index];
    this.bottomSheetOpen();
  }

  /* Fn to reset the filter */
  resetFilter(): void {
    this.toDate = undefined;
    this.fromDate = undefined;
    this.status = 'All';
    this.currency = 'All';
    this.vendorId = 0;
    this.shipmentType = 'ALL';
    this.fetchExpenses();
  }
}
