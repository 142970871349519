import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { GlobalProfileService } from 'src/app/global-profile.service';
import { SwalHelper } from 'src/app/swal';
import { Invoice, InvoicePayment, OrgInvoiceService, PaymentSchedule } from 'src/swagger';
import { OrganisationHelperService } from '../../organisation-helper.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
declare var swal: any;

@Component({
  selector: 'app-invoice-payment',
  templateUrl: './invoice-payment.component.html',
  styleUrls: ['./invoice-payment.component.scss']
})
export class InvoicePaymentComponent implements OnInit, OnChanges, OnDestroy {

  public allPayments: InvoicePayment[] = [];
  public allSchedules: PaymentSchedule[] = [];
  public newInvoicePayment: InvoicePayment = {};

  public shouldShowLoader: boolean;
  @Input() invDetails: Invoice;
  @Output() invRemBalance = new EventEmitter<number>();

  public remainingAmount = 0;
  public paymentFile: File;
  public today = moment().format('YYYY-MM-DD');
  private userSubscription: Subscription;

  constructor(
    private orgInvApi: OrgInvoiceService,
    private orgHelper: OrganisationHelperService,
    public profileSync: GlobalProfileService
  ) { }

  ngOnInit(): void {
    this.orgHelper.paymentSchedule$.subscribe(schedule => {
      if (schedule) {
        const index = this.allSchedules.findIndex(i => i.paymentScheduleId === schedule.paymentScheduleId);
        if (index >= 0) {
          this.allSchedules[index] = schedule;
        } else {
          this.allSchedules.push(schedule);
        }
      }
    });
  }

  ngOnChanges(): void {
    if (this.invDetails.invId) {
      this.userSubscription =  this.profileSync.user$.pipe(take(1)).subscribe(user => {
        if (user && user.isInternal) {
          this.fetchAllSchedules();
        }
      });
    }
  }
  ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }
  // Fetch All the Payments for requested Invoice Id
  fetchAllPayments(): void {
    this.shouldShowLoader = true;
    this.orgInvApi.listInvoicePayment(
      this.invDetails.invId
    ).subscribe(schedules => {
      this.shouldShowLoader = false;
      if (schedules && schedules.length > 0) {
        this.allPayments = schedules;
      } else {
        this.allPayments = [];
      }
      this.initInvPayment();
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }
  // Fetch All the Scjedules for requested Invoice Id
  fetchAllSchedules(): void {
    this.shouldShowLoader = true;
    this.orgInvApi.listPaymentSchedule(
      this.invDetails.invId
    ).subscribe(schedules => {
      this.shouldShowLoader = false;
      if (schedules && schedules.length > 0) {
        this.allSchedules = schedules;
      } else {
        this.allSchedules = [];
      }
      this.fetchAllPayments();
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }
  // Fn to calculate payment is Ontime or not
  calOntimeStatus(paymentDate): void {
    let statusValue;
    const totalPaymentAmount = this.allPayments.filter(i => i.dateOfPayment <= paymentDate).map(i => i.amount).reduce((a, b) => a + b, 0);
    const onDateSchedules = this.allSchedules.filter(i => i.expDateOfPayment <= paymentDate);
    const totalScheduleAmount = onDateSchedules.map(i => i.expAmount).reduce((a, b) => a + b, 0);
    if (totalPaymentAmount >= totalScheduleAmount) {
      statusValue = true;
    }
    return statusValue;
  }
  // Fn to calculate payment is delayed or not
  calDelayedStatus(paymentDate): void {
    let statusValue;
    const totalPaymentAmount = this.allPayments.filter(i => i.dateOfPayment <= paymentDate).map(i => i.amount).reduce((a, b) => a + b, 0);
    const onDateSchedules = this.allSchedules.filter(i => i.expDateOfPayment <= paymentDate).reverse();
    const totalScheduleAmount = onDateSchedules.map(i => i.expAmount).reduce((a, b) => a + b, 0);
    if (totalPaymentAmount < totalScheduleAmount || (onDateSchedules && onDateSchedules.length > 0 && (paymentDate > onDateSchedules[0].expDateOfPayment))) {
      statusValue = true;
    }
    return statusValue;
  }
  // Delete the Payment
  deletePayment(index: number): void {
    swal({
      title: 'Delete Payment',
      text: 'This cannot be undone',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        this.shouldShowLoader = true;
        return new Promise((resolve, reject) => {
          this.orgInvApi.deleteInvoicePayment(this.allPayments[index].invoicePaymentId).subscribe(ok => {
            this.shouldShowLoader = false;
            this.allPayments.splice(index, 1);
            this.initInvPayment();
            this.invRemBalance.next(this.remainingAmount);
            resolve(ok);
          }, err => {
            reject(err);
          });
        });
      },
      allowOutsideClick: () => !swal.isLoading()
    }).then((result) => {
      if (result.value) {
        this.shouldShowLoader = false;
        SwalHelper.successTimerSwal('Invoice Payment deleted!');
      }
    }).catch(err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }
  // Create new Payment
  savePayment(): void {
    this.shouldShowLoader = true;
    this.newInvoicePayment.invId = this.invDetails.invId;
    this.newInvoicePayment.currency = this.invDetails.currency;
    this.newInvoicePayment.dateOfPayment = this.newInvoicePayment.dateOfPayment ? moment(this.newInvoicePayment.dateOfPayment).format('YYYY-MM-DD') : null;
    this.orgInvApi.createInvoicePayment(
      this.newInvoicePayment
    ).subscribe(newPayment => {
      if (this.paymentFile) {
        this.uploadFile(newPayment.invoicePaymentId);
      }
      this.shouldShowLoader = false;
      this.allPayments.unshift(newPayment);
      SwalHelper.successTimerSwal('Payment Created');
      this.initInvPayment();
      this.invRemBalance.next(this.remainingAmount);
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }
  // Fn to initialize new invoice payment
  initInvPayment(): void {
    this.newInvoicePayment = {};
    this.remainingAmount = parseFloat((this.invDetails.totalAmount - this.allPayments.map(i => i.amount).reduce((a, b) => a + b, 0)).toFixed(2));
    this.newInvoicePayment.amount = parseFloat(this.remainingAmount.toFixed(2));
  }
  captureInvoiceFile(event): void {
    this.paymentFile = event.target.files[0];
  }
  // Fn to get the signed URL of requested Attachment & Download It
  downloadFile(index: number): void {
    this.orgInvApi.paymentAttSignedUrl(this.allPayments[index].invoicePaymentId)
      .subscribe(url => {
        window.open(url.url, '_blank');
      });
  }
  // Fn to upload the payment Attachment file
  uploadFile(paymentId: number): void {
    this.orgInvApi.uploadPaymentFile(paymentId, this.paymentFile)
      .subscribe(url => {
        this.shouldShowLoader = false;
        this.paymentFile = undefined;
        const index = this.allPayments.findIndex(i => i.invoicePaymentId === paymentId);
        this.allPayments[index].attachmentURL = url.url;
      }, err => {
        this.shouldShowLoader = false;
        SwalHelper.showErrorSwal('Attachment not Uploaded , Please try again');
      });
  }
}
