export const CURRENCIES = [
  'INR',
    'USD',
    'AUD',
    'AED',
    'SGD',
    'JPY',
    'GBP',
    'EUR',
    'CNY',
    'KHD',
    'CAD',
    'CHF',
];

export const DEFAULT_SHIPMENT_STATUS = [
  'PRE_ORDER',
  'ORDER',
  'WAREHOUSE',
  'INVOICE',
  'IN_TRANSIT',
  'DELIVERED',
  'PAID',
];
export const SHIPMENT_TYPE = [
  'BUY',
  'SELL',
];
export const CERTIFICATE_TYPE = [
  'COA',
  'THIRD_PARTY',
];
export const SHIPMENT_DIALOUGE = [
  'SYSTEM_TRIGGERED_TRANSACTIONAL',
  'SYSTEM_TRIGGERED_USERDRIVEN',
  'PO_EVENT',
  'INVOICE_EVENT',
  'OTHER_DOCUMENT_EVENT',
  'EXTERNAL_EVENT',
  'API_EVENT',
  'CRON_EVENT',
  'BULK_EVENT',
  'EXTERNAL_USER_EVENT',
  'USER_COMMENT',
  'USER_EMAIL_COMMENT',
  'FINANCING_EVENT',
];
export const SHIPMENT_DOCUMENT_TYPE = [
  'PURCHASE_ORDER',
  'SALES_ORDER',
  'INVOICE',
  'SHIPPING_BILL',
  'BILL_OF_LADING',
  'BILL_OF_ENTRY',
  'OTHER',
  'INSURANCE',
  'PACKING_LIST',
  'CERTIFICATES',
  'EBRC',
  'COO',
  'SCRIP',
];
export const SHIPMENT_DOCUMENT_ORIGIN = [
  'GENERATED',
  'IMPORTED',
];
export const CONTACT_TYPE = [
  'CHA',
  'FF',
  'BUYER',
  'CONSULTANT',
  'SELLER',
  'BANKER',
  'LENDER',
  'OTHERS',
  'DUAL(BUYER+SELLER)',
];
export const INVOICE_PAYMENT_TYPE = [
  'FINANCE',
  'BUYER_PAYMENT',
];
export const OCR_STAGES = [
  'NOT_STARTED',
  'IN_PROGRESS',
  'DONE',
  'FAILED',
];
export const INVOICE_TYPE = [
  'SALES',
  'PURCHASE',
];

export const ORDER_STATUS = [
  'DRAFT',
  'ACCEPTED',
  'IN_PROGRESS',
  'DELAYED',
  'COMPLETED',
  'CANCELLED',
];

export const BL_STATUS = [
  'DRAFT',
  'SHIPPED_ON_BOARD',
  'TELEX_RELEASED',
  'IN_TRANSIT',
  'COURIER_RELEASED',
  'DELIVERED',
  'DISPUTED',
];
export class SelectorDiv {
  public left: number;
  public top: number;
  public height: number;
  public width: number;

  public innerText: string;
}

export enum SurveyState {
  'UNSAVED_CHANGES' = 'UNSAVED_CHANGES',
  'SAVING' = 'SAVING',
  'SAVED' = 'SAVED'
}
