import { Component, OnInit } from '@angular/core';
import { GlobalProfileService } from '../../../global-profile.service';

@Component({
  selector: 'app-custom-fields',
  templateUrl: './custom-fields.component.html',
  styleUrls: ['./custom-fields.component.scss']
})
export class CustomFieldsComponent implements OnInit {

  constructor(
    public profileSync: GlobalProfileService
  ) { }

  ngOnInit(): void {
  }

}
