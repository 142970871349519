import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from '../../swagger/api/auth.service';
import { GlobalProfileService } from '../global-profile.service';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss']
})
export class OnboardingComponent implements OnInit, OnDestroy, AfterViewInit {

  private loginSubscription: Subscription;

  constructor(
    public profileSync: GlobalProfileService,
    public authApi: AuthService,
    public router: Router,
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.loginSubscription = this.profileSync.isUserLoggedIn$.subscribe(isUserLoggedIn => {
      if (isUserLoggedIn) {
        this.authApi.getProfile().subscribe(user => {
          this.profileSync.updateUserProfile(user);
        }, err => {
          this.router.navigate(['/login']);
        });
      } else {
        this.router.navigate(['/login']);
      }
    });
  }

  ngOnDestroy(): void {
    if (this.loginSubscription) {
      this.loginSubscription.unsubscribe();
    }
  }

  updatedData(user): void {
    this.profileSync.updateUserProfile(user);
  }

  logout(): void {
    this.profileSync.clearToken();
    this.router.navigate(['/login']);
  }
}
