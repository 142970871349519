import { catchError, debounceTime, switchMap, tap } from 'rxjs/operators';
import { Shipment } from './../../../../swagger/model/shipment';
import { of, Subject, Subscription } from 'rxjs';
import { Component, OnInit, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { ExpenseTracker } from '../../../../swagger/model/expenseTracker';
import { ExpenseTrackerItem } from '../../../../swagger/model/expenseTrackerItem';
import { OrgShipmentsService } from '../../../../swagger/api/orgShipments.service';
import { ActivatedRoute } from '@angular/router';
import { SwalHelper } from '../../../swal';
import { GlobalProfileService } from '../../../global-profile.service';
import { OrganisationHelperService } from '../../../organisation/organisation-helper.service';
import { CommonSupportService } from '../../../common-support.service';
import { ShipmentDetailService } from '../../shipment-details/shipment-detail.service';
import { ShipmentDocsService } from 'src/swagger';
declare var $: any;
declare var Swal: any;

@Component({
  selector: 'app-shipment-expense-details',
  templateUrl: './shipment-expense-details.component.html',
  styleUrls: ['./shipment-expense-details.component.scss']
})
export class ShipmentExpenseDetailsComponent implements OnInit, OnDestroy {

  public expenseDetails: ExpenseTracker = {};
  public expenseItemDetail: ExpenseTrackerItem = {};

  public shouldShowLoader: boolean;
  public totalCalculations: TotalCalculation = {};
  dtOptions1: any = {};
  dtOptions2: any = {};
  public isReadOnly: boolean[] = [];

  public shipmentChange$: Subject<string> = new Subject();
  public listShipment$: Subject<Shipment[]> = new Subject();
  public shipmentLoading = false;
  private shipmentSubscription: Subscription;
  public existingShipmentId: number;

  @Output() closeExpenseToggle = new EventEmitter<string>();
  @Input() shipmentId: number;
  @Input() tracking: ExpenseTracker;

  constructor(
    private orgShipmentApi: OrgShipmentsService,
    private activatedRoute: ActivatedRoute,
    public profileSync: GlobalProfileService,
    public orgHelper: OrganisationHelperService,
    private shipmentDetailService: ShipmentDetailService,
    public commonService: CommonSupportService,
    private orgShipmentDocsApi: ShipmentDocsService
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(param => {
      if (param && param.trackingId) {
        this.getExpenseDetail(param.shipmentId, param.trackingId);
      }
    });
    this.shipmentDetailService.shipment$.subscribe(shipment => {
      if (shipment && shipment.shipmentId && shipment.ExpenseTracker && shipment.ExpenseTracker.trackerId) {
        this.getExpenseDetail(shipment.shipmentId, shipment.ExpenseTracker.trackerId);
      }
    });

    this.shipmentSubscription = this.shipmentChange$.pipe(
      debounceTime(200),
      tap(() => this.shipmentLoading = true),
      switchMap(query => this.orgShipmentDocsApi.searchShipmentDocsShipments(
        '',
        query,
      ).pipe(
        catchError(() => of([])), // empty list on error
        tap(() => {
          this.shipmentLoading = false;
        })
      ))
    ).subscribe(sos => {
      this.listShipment$.next(sos);
    });

    this.dtOptions1 = {
      dom: 'Bfrtip',
      buttons: [
        {
          text: '<i class="ri-file-excel-2-fill"></i>',
          className: 'btn btn-green-o',
          extend: 'excelHtml5',
          title: 'Expense-report',
          footer: true,
        },
        {
          text: '<i class="ri-printer-fill"></i>',
          className: 'btn btn-gray-o',
          extend: 'print',
          title: 'Expense-report',
          footer: true,
        },
      ],
      columnDefs: [
        { targets: [0, 1], width: '150px' },
        { targets: [5, 10], width: '60px' },
        { targets: [3, 7, 8, 12], width: '50px' }
      ],
      searching: false,
      ordering: false,
      paging: false,
      bInfo: false,
    };
    this.dtOptions2 = {
      dom: 'Bfrtip',
      columnDefs: [
        { targets: [0, 1], width: '150px' },
      ],
      paging: false,
      searching: false,
      ordering: false,
      bInfo: false,
      buttons: [
        {
          text: '<i class="ri-file-excel-2-fill"></i>',
          className: 'btn btn-green-o',
          extend: 'excelHtml5',
          title: 'Revenue-report',
          footer: true,
        },
        {
          text: '<i class="ri-printer-fill"></i>',
          className: 'btn btn-gray-o',
          extend: 'print',
          title: 'Revenue-report',
          footer: true
        },
      ],
    };
    if (this.profileSync.user && this.profileSync.user.isInternal) {
      if (this.shipmentId && this.tracking && this.tracking.trackerId) {
        this.getExpenseDetail(this.shipmentId, this.tracking.trackerId);
      }
    }
  }

  ngOnDestroy(): void {
    if (this.shipmentSubscription) {
      this.shipmentSubscription.unsubscribe();
    }
  }


  /* Fn to initiate the expense tracking item */
  initItem(trackType: string): void {
    const filteredItems = this.expenseDetails.ExpenseTrackerItems.filter(item => item.trackType === trackType);
    this.expenseItemDetail = {};
    this.expenseItemDetail.trackType = trackType;
    this.expenseItemDetail.budgetCurrency = this.expenseDetails.invoiceCurrency;
    this.expenseItemDetail.actualCurrency = this.expenseDetails.invoiceCurrency;
    this.expenseItemDetail.budgetECHRate = filteredItems.length > 0 ? filteredItems[filteredItems.length - 1].budgetECHRate : 1;
    this.expenseItemDetail.actualECHRate = filteredItems.length > 0 ? filteredItems[filteredItems.length - 1].actualECHRate : 1;
    this.expenseDetails.ExpenseTrackerItems.push(this.expenseItemDetail);
  }

  /* Fn to get the details of expense tracking with items of expenses */
  getExpenseDetail(shipmentId: number, trackerId: number): void {
    this.shouldShowLoader = true;
    this.orgShipmentApi.getExpenseTracking(shipmentId, trackerId).subscribe(license => {
      this.shouldShowLoader = false;
      if (license) {
        this.expenseDetails = license;
        this.expenseDetails.ExpenseTrackerItems = this.expenseDetails.ExpenseTrackerItems && this.expenseDetails.ExpenseTrackerItems.length > 0 ? this.expenseDetails.ExpenseTrackerItems : [];
        this.isReadOnly = this.expenseDetails.ExpenseTrackerItems.map(() => true);
        this.calculateTotalAmount();
        if (this.expenseDetails.ExpenseTrackerItems.length === 0) {
          this.expenseDetails.ExpenseTrackerItems = [];
        }
        if (this.expenseDetails.Shipment && this.expenseDetails.Shipment.ShipmentDocuments && this.expenseDetails.Shipment.ShipmentDocuments.length > 0 && this.expenseDetails.Shipment.ShipmentDocuments[0].Invoice && this.expenseDetails.Shipment.ShipmentDocuments[0].Invoice.totalAmount && (this.expenseDetails.Shipment.ShipmentDocuments[0].Invoice.totalAmount !== this.expenseDetails.invoiceAmount || this.expenseDetails.Shipment.ShipmentDocuments[0].Invoice.currency !== this.expenseDetails.invoiceCurrency)) {
          this.expenseDetails.invoiceAmount = parseFloat(this.expenseDetails.Shipment.ShipmentDocuments[0].Invoice.totalAmount.toFixed(2));
          this.expenseDetails.invoiceCurrency = this.expenseDetails.Shipment.ShipmentDocuments[0].Invoice.currency;
          this.updateExpense();
        }
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /* Fn to calculate the budgeted amount based on one of the amount */
  calculateBudgetedAmount(index: number): void {
    if (this.expenseDetails.ExpenseTrackerItems[index].budgetCurrency === this.expenseDetails.invoiceCurrency) {
      this.expenseDetails.ExpenseTrackerItems[index].budgetLocalAmount = parseFloat((this.expenseDetails.ExpenseTrackerItems[index].budgetFCYAmount * this.expenseDetails.ExpenseTrackerItems[index].budgetECHRate).toFixed(3));
    } else {
      this.expenseDetails.ExpenseTrackerItems[index].budgetFCYAmount = parseFloat((this.expenseDetails.ExpenseTrackerItems[index].budgetLocalAmount / this.expenseDetails.ExpenseTrackerItems[index].budgetECHRate).toFixed(3));
    }
  }

  /* Fn to calculate the actual amount based on one of the amount */
  calculateActualAmount(index: number): void {
    if (this.expenseDetails.ExpenseTrackerItems[index].actualCurrency === this.expenseDetails.invoiceCurrency) {
      this.expenseDetails.ExpenseTrackerItems[index].actualLocalAmount = this.expenseDetails.ExpenseTrackerItems[index].actualFCYAmount * this.expenseDetails.ExpenseTrackerItems[index].actualECHRate;
    } else {
      this.expenseDetails.ExpenseTrackerItems[index].actualFCYAmount = this.expenseDetails.ExpenseTrackerItems[index].actualLocalAmount / this.expenseDetails.ExpenseTrackerItems[index].actualECHRate;
    }
  }

  /* Fn to calculate the total amount */
  calculateTotalAmount(): void {
    this.totalCalculations.totalActualFCY = this.expenseDetails.ExpenseTrackerItems.filter(item => item.trackType === 'Expense').map(item => item.actualFCYAmount).reduce((a, b) => a + b, 0);

    this.totalCalculations.totalActualLocal = this.expenseDetails.ExpenseTrackerItems.filter(item => item.trackType === 'Expense').map(item => item.actualLocalAmount).reduce((a, b) => a + b, 0);

    this.totalCalculations.totalBudgetedFCY = this.expenseDetails.ExpenseTrackerItems.filter(item => item.trackType === 'Expense').map(item => item.budgetFCYAmount).reduce((a, b) => a + b, 0);

    this.totalCalculations.totalBudgetedLocal = this.expenseDetails.ExpenseTrackerItems.filter(item => item.trackType === 'Expense').map(item => item.budgetLocalAmount).reduce((a, b) => a + b, 0);

    this.totalCalculations.totalRevenueFCY = this.expenseDetails.ExpenseTrackerItems.filter(item => item.trackType === 'Revenue').map(item => item.actualFCYAmount).reduce((a, b) => a + b, 0) + this.expenseDetails.invoiceAmount;

    this.totalCalculations.totalActualPer = this.expenseDetails.ExpenseTrackerItems.filter(item => item.trackType === 'Expense').map(item => item.actualFCYAmount * 100).reduce((a, b) => a + b, 0) / this.expenseDetails.invoiceAmount;

    this.totalCalculations.totalBudgetedPer = this.expenseDetails.ExpenseTrackerItems.filter(item => item.trackType === 'Expense').map(item => item.budgetFCYAmount * 100).reduce((a, b) => a + b, 0) / this.expenseDetails.invoiceAmount;

  }

  /**
   * FN to Add/Update the expense tracker item
   * @param index Index of expense tracker item
   */
  saveItemDetails(index: number): void {

    if (!this.expenseDetails.ExpenseTrackerItems[index].category || !this.expenseDetails.ExpenseTrackerItems[index].description) {
      return;
    }

    this.shouldShowLoader = true;
    this.expenseDetails.ExpenseTrackerItems[index].trackerId = this.expenseDetails.trackerId;

    if (this.expenseDetails.ExpenseTrackerItems[index].trackerItemId) {
      this.orgShipmentApi.updateExpenseItemTracking(this.expenseDetails.ExpenseTrackerItems[index]
      ).subscribe(item => {
        this.shouldShowLoader = false;
        this.expenseDetails.ExpenseTrackerItems[index] = item;
        SwalHelper.successTimerSwal(`${this.expenseDetails.ExpenseTrackerItems[index].trackType} Updated!`);
        this.isReadOnly[index] = true;
        this.calculateTotalAmount();
      }, err => {
        this.shouldShowLoader = false;
        SwalHelper.showErrorSwal(err);
      });
    } else {
      this.orgShipmentApi.createExpenseItemTracking(this.expenseDetails.ExpenseTrackerItems[index]
      ).subscribe(item => {
        this.shouldShowLoader = false;
        this.expenseDetails.ExpenseTrackerItems[index] = item;
        SwalHelper.successTimerSwal(`${this.expenseDetails.ExpenseTrackerItems[index].trackType} Added!`);
        this.isReadOnly[index] = true;
        this.calculateTotalAmount();

      }, err => {
        this.shouldShowLoader = false;
        SwalHelper.showErrorSwal(err);
      });
    }
  }

  /**
   * Function to delete the expense
   * @param index Index of the expense item
   */
  deleteExportItem(index: number): void {
    Swal({
      title: `Delete this ${this.expenseDetails.ExpenseTrackerItems[index].trackType}?`,
      text: 'This is permanent deletion',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        this.shouldShowLoader = true;
        return new Promise((resolve, reject) => {
          this.orgShipmentApi.deleteExpenseItemTracking(this.expenseDetails.ExpenseTrackerItems[index].trackerItemId).subscribe(ok => {
            this.shouldShowLoader = false;
            resolve(ok);
          }, err => {
            reject(err);
          });
        });
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.value) {
        this.shouldShowLoader = false;
        SwalHelper.successTimerSwal(`${this.expenseDetails.ExpenseTrackerItems[index].trackType} deleted!`);
        this.expenseDetails.ExpenseTrackerItems.splice(index, 1);
        this.calculateTotalAmount();
      }
    }).catch(err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /**
   * Function to delete the expense
   * @param index Index of the expense item
   */
  deleteNewExportItem(index: number): void {
    Swal.fire({
      title: `Delete this ${this.expenseDetails.ExpenseTrackerItems[index].trackType}?`,
      text: 'This is permanent deletion',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete',
    }).then((result) => {
      if (result.value) {
        SwalHelper.showToast('success', `${this.expenseDetails.ExpenseTrackerItems[index].trackType} deleted!`);
        this.expenseDetails.ExpenseTrackerItems.splice(index, 1);
        this.calculateTotalAmount();
      }
    });
  }

  /**
   * Function to edit the expense & open the modal
   * @param index Index of the expense item
   */
  editDetails(index: number): void {
    this.isReadOnly[index] = false;
    this.expenseDetails.ExpenseTrackerItems[index].budgetCurrency = this.expenseDetails.invoiceCurrency;
    this.expenseDetails.ExpenseTrackerItems[index].actualCurrency = this.expenseDetails.invoiceCurrency;
  }

  /* Function to update the expense details */
  updateExpense(): void {
    if (this.profileSync.user && this.profileSync.user.orgId === this.expenseDetails.orgId) {
      this.shouldShowLoader = true;
      this.orgShipmentApi.updateExpenseTracking(this.expenseDetails).subscribe(ok => {
        this.shouldShowLoader = false;
        SwalHelper.showSavedToast('Updated');
        this.calculateTotalAmount();
      }, err => {
        this.shouldShowLoader = false;
        SwalHelper.showErrorSwal(err);
      });
    }
  }

  /* Fn to close the bottom sheet */
  bottomSheetClose(): void {
    this.closeExpenseToggle.next('Close shipment');
  }

  /* Fn to print the expense table */
  printExpense(): void {
    const printContents = document.getElementById('printInv').innerHTML;
    const win = window.open('', 'Title', 'toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes');
    win.document.body.innerHTML = printContents;
    win.print();
  }

  /* Fn to ask for confirmation before copying data */
  askCopyConfirmation(event): void {
    Swal({
      title: 'Copy details',
      text: `Are you sure, that you want to copy expense details from ${event.shipmentNoPre}${event.shipmentNoSuf}`,
      type: 'warning',
      showCancelButton: true,
      cancelButtonText: 'No',
      confirmButtonText: 'Yes! Copy',
    }).then((result) => {
      if (result.value) {
        this.fetchExistingShipmentExpense(event);
      } else {
        this.existingShipmentId = null;
      }
    });
  }

  /* Fn to fetch the shipment expense tracker for existing shipment & copy the data */
  fetchExistingShipmentExpense(event): void {
    if (event.ExpenseTracker) {
      this.shouldShowLoader = true;
      this.orgShipmentApi.getExpenseTracking(event.shipmentId, event.ExpenseTracker.trackerId).subscribe(expenseDetail => {
        this.shouldShowLoader = false;
        if (expenseDetail && expenseDetail.ExpenseTrackerItems && expenseDetail.ExpenseTrackerItems.length > 0 ) {
          this.expenseDetails.ExpenseTrackerItems = expenseDetail.ExpenseTrackerItems.map(i => {
            return {
              trackType: i.trackType,
              description: i.description,
              remark: i.remark,
              category: i.category,
              trackerDate: i.trackerDate,
              budgetECHRate: i.budgetECHRate,
              actualECHRate: i.actualECHRate,
            };
          });
          this.isReadOnly = expenseDetail.ExpenseTrackerItems.map(() => false);
        }
      }, err => {
        this.shouldShowLoader = false;
        SwalHelper.showErrorSwal(err);
      });
    }
  }
}

export class TotalCalculation {
  public totalBudgetedFCY?: number;
  public totalBudgetedLocal?: number;
  public totalBudgetedPer?: number;
  public totalActualFCY?: number;
  public totalActualLocal?: number;
  public totalActualPer?: number;
  public totalRevenueFCY?: number;
  public totalRevenueLocal?: number;
}
