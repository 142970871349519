import { Component, OnInit, Renderer2, ChangeDetectorRef, OnDestroy, ViewChild } from '@angular/core';
import { PurchaseOrder, OrganisationService, ItemMaster, Page, PurchaseOrderItem, VendorAndSupplier, OrgOrdersService, OrganisationBankAccount } from 'src/swagger';
import { ActivatedRoute, Router } from '@angular/router';
import { SwalHelper } from 'src/app/swal';
import { interval, of, Subject, Subscription } from 'rxjs';
import { skip, catchError, filter, switchMap, tap, distinctUntilChanged } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { CommonSupportService } from 'src/app/common-support.service';
import { environment } from 'src/environments/environment';
import { GlobalProfileService } from 'src/app/global-profile.service';
import { differenceWith } from 'lodash-es';
import { ComponentCanDeactivate } from 'src/app/form-route-deactivate/component-can-deactivate';
import { NgForm } from '@angular/forms';
import { OrganisationHelperService } from '../../organisation-helper.service';
import * as moment from 'moment';
import { SelectorDiv } from '../../../enums';
import { NumberToWordsPipe } from 'src/app/pipes/number-to-words.pipe';
declare var $: any;
declare var Swal: any;

@Component({
  selector: 'app-purchase-order-update',
  templateUrl: './purchase-order-update.component.html',
  styleUrls: ['./purchase-order-update.component.scss']
})
export class PurchaseOrderUpdateComponent extends ComponentCanDeactivate implements OnInit, OnDestroy {

  @ViewChild('POForm')
  public POFormRef: NgForm;

  private poOCRSubscription: Subscription;

  public purchaseOrderDetails: PurchaseOrder = {};
  public shouldShowLoader: boolean;
  public statusEnum = PurchaseOrder.StatusEnum;

  public vendorSuppliers: VendorAndSupplier[] = [];
  public ocrResults: Page[] = [];

  public newItem: PurchaseOrderItem = {};
  public buyerDetail: VendorAndSupplier = {};
  public consigneeDetail: VendorAndSupplier = {};
  public notifyPartyDetails: VendorAndSupplier = {};
  public notifyParty2Details: VendorAndSupplier = {};

  public itemMasters$: Subject<ItemMaster[]> = new Subject();
  public itemChange$: Subject<string> = new Subject();
  private itemChangeSubscription: Subscription;
  public itemLoading: boolean;

  public thisPage: any;
  public bgwidth;
  public bgheight;

  public copyText = 'Click to copy';
  public selectedImageURL: string;
  public totalPages = [];

  public selectors: SelectorDiv[] = [];
  public basePath: string;
  public isSaveAndPrint: boolean;

  public rightSheet = false;
  public purchaseOrderFiles: File[] = [];
  public itemsCustomField = [];

  public selectedVendor: number;
  public newContactType: string;
  public buttonDisabled = true;

  public itemMasterDetail: ItemMaster = {};
  public customItemIndex: number;
  public isRedirectToPreview: boolean;

  public orgBanks: OrganisationBankAccount[] = [];

  constructor(
    public http: HttpClient,
    private orgApi: OrganisationService,
    private activatedRoute: ActivatedRoute,
    public commonService: CommonSupportService,
    private renderer: Renderer2,
    private changeDetectorRef: ChangeDetectorRef,
    private orgOrderApi: OrgOrdersService,
    public profileSync: GlobalProfileService,
    private router: Router,
    public orgHelper: OrganisationHelperService,
  ) {
    super();
    commonService.renderer = renderer;
    this.basePath = environment.BASE_PATH;
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(param => {
      if (param && param.poId) {
        this.fetchPurchaseOrderDetails(param.poId);
      }
    });
    this.fetchVendorSupplier();
    this.itemChangeSubscription = this.itemChange$.pipe(
      distinctUntilChanged(),
      tap(() => this.itemLoading = true),
      switchMap(query => this.orgApi.listItemMaster(query).pipe(
        catchError(() => of([])), // empty list on error
        tap(() => {
          this.itemLoading = false;
        })
      ))
    ).subscribe(items => {
      this.itemMasters$.next(items);
    });
  }

  ngOnDestroy(): void {
    if (this.poOCRSubscription) {
      this.poOCRSubscription.unsubscribe();
    }
    if (this.itemChangeSubscription) {
      this.itemChangeSubscription.unsubscribe();
    }
  }

  canDeactivate(): boolean {
    return !this.POFormRef.dirty;
  }

  calTotalQuantity(index: number): void {
    this.purchaseOrderDetails.PurchaseOrderItems[index].itemQuantity = parseFloat((this.purchaseOrderDetails.PurchaseOrderItems[index].noOfPackages * this.purchaseOrderDetails.PurchaseOrderItems[index].itemQuantityPerPack).toFixed(3));
    this.calAmount();
  }

  calAmount(): void {
    this.purchaseOrderDetails.poTotalAmount = this.purchaseOrderDetails.PurchaseOrderItems.map(i => i.itemPrice * i.itemQuantity).reduce((a, b) => a + b, 0);
    this.purchaseOrderDetails.poTotalQuantity = this.purchaseOrderDetails.PurchaseOrderItems.map(i => i.itemQuantity).reduce((a, b) => a + b, 0);
  }

  checkItem(event, index: number): void {
    if (event) {
      if (event.itemMasterId) {
        const tempOtherData = this.purchaseOrderDetails.PurchaseOrderItems[index].otherData;
        this.purchaseOrderDetails.PurchaseOrderItems[index] = event;
        this.purchaseOrderDetails.PurchaseOrderItems[index].otherData = tempOtherData ? tempOtherData : this.orgHelper.copyObject(this.itemsCustomField);
      } else {
        $('#itemMasterModal').modal('show');
        this.customItemIndex = index;
        this.itemMasterDetail.itemCode = event.itemCode;
        this.itemMasterDetail.itemCurrency = this.purchaseOrderDetails.currency;
      }
    } else {
      this.purchaseOrderDetails.PurchaseOrderItems[index].itemCode = undefined;
    }

  }

  fetchVendorSupplier(): void {
    this.shouldShowLoader = true;
    this.orgApi.listVendorSupplier(
    ).subscribe(vendors => {
      if (vendors && vendors.length > 0) {
        this.shouldShowLoader = false;
        this.vendorSuppliers = vendors;
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  addItem(): void {
    const item: PurchaseOrderItem = {};
    item.itemQuantity = 1;
    item.itemPrice = 1;
    item.otherData = this.orgHelper.copyObject(this.itemsCustomField);
    this.purchaseOrderDetails.PurchaseOrderItems.push(item);
    this.calAmount();
  }

  removeItem(index: number): void {
    Swal.fire({
      title: 'Remove Item?',
      text: 'Are you sure want to delete this Item?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete',
    }).then((result) => {
      if (result.value) {
        this.purchaseOrderDetails.PurchaseOrderItems.splice(index, 1);
        this.calAmount();
        SwalHelper.showToast('success', 'Item deleted!');
      }
    });
  }

  removeColumn(index: number): void {
    Swal.fire({
      title: 'Remove Column?',
      text: 'Are you sure want to delete this Item?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete',
    }).then((result) => {
      if (result.value) {
        const otherDataIndex = this.purchaseOrderDetails.PurchaseOrderItems[0].otherData.findIndex(i => i.key == this.itemsCustomField[index].key);
        if (otherDataIndex >= 0) {
          this.purchaseOrderDetails.PurchaseOrderItems.forEach(i => {
            i.otherData.splice(otherDataIndex, 1);
          });
        }
        this.itemsCustomField.splice(index, 1);
        SwalHelper.showToast('success', 'Item deleted!');
      }
    });
  }

  fetchItemCustomFields(): void {
    this.orgApi.getCustomField('Purchase Order Items').subscribe(fields => {
      if (fields && fields.length > 0) {
        if (this.itemsCustomField.length > 0) {
          this.itemsCustomField = this.itemsCustomField.map(item => {
            const missingCustomFields = differenceWith(fields, item, (a, b) => {
              return a.fieldName === b.key;
            });
            missingCustomFields.map(mi => {
              const customField = {
                key: mi.fieldName,
                value: null,
                required: mi.isRequired,
                pattern: mi.fieldRegex,
                type: mi.fieldType
              };
              this.itemsCustomField.push(customField);
            });
            return item;
          });
        } else {
          this.itemsCustomField = fields.map(item => {
            return {
              key: item.fieldName,
              value: null,
              required: item.isRequired,
              pattern: item.fieldRegex,
              type: item.fieldType
            };
          });
        }
      }
      this.purchaseOrderDetails.PurchaseOrderItems = this.purchaseOrderDetails.PurchaseOrderItems.map(item => {
        item.otherData = item.otherData ? item.otherData : [];
        const missingItems = differenceWith(this.itemsCustomField, item.otherData, (a, b) => {
          return a.key === b.key;
        });
        missingItems.forEach(mi => {
          item.otherData.push(mi);
        });
        return item;
      });
      this.changeDetectorRef.detectChanges();
    }, err => {
      SwalHelper.showErrorSwal(err);
    });
  }

  fetchPurchaseOrderDetails(poId): void {
    this.shouldShowLoader = true;
    this.orgOrderApi.getPo(poId).subscribe(po => {
      this.shouldShowLoader = false;
      if (po) {
        this.purchaseOrderDetails = po;
        this.purchaseOrderDetails.PurchaseOrderItems = this.purchaseOrderDetails.PurchaseOrderItems.map(item => {
          const missingInvItems = differenceWith(item.otherData, this.itemsCustomField, (a, b) => {
            return a.key === b.key;
          });
          missingInvItems.map(mi => {
            const missingItem = this.orgHelper.copyObject(mi);
            // tslint:disable-next-line: no-string-literal
            missingItem['value'] = null;
            this.itemsCustomField.push(missingItem);
          });
          return item;
        });
        this.fetchItemCustomFields();
        this.purchaseOrderDetails.bankDetailsInfo = this.purchaseOrderDetails.bankDetailsInfo ? this.purchaseOrderDetails.bankDetailsInfo : {};
        this.purchaseOrderDetails.currency = this.purchaseOrderDetails.currency ? this.purchaseOrderDetails.currency : 'USD';
        this.purchaseOrderDetails.PurchaseOrderItems = this.purchaseOrderDetails.PurchaseOrderItems ? this.purchaseOrderDetails.PurchaseOrderItems : [];
        this.purchaseOrderDetails.otherData = this.purchaseOrderDetails.otherData ? this.purchaseOrderDetails.otherData : {};
        this.buyerDetail = this.purchaseOrderDetails.VendorAndSupplier ? this.purchaseOrderDetails.VendorAndSupplier : {};
        this.consigneeDetail = this.purchaseOrderDetails.consigneeId ? this.purchaseOrderDetails.Consignee : {};
        this.notifyPartyDetails = this.purchaseOrderDetails.notifyPartyId ? this.purchaseOrderDetails.NotifyParty : {};
        this.notifyParty2Details = this.purchaseOrderDetails.notifyPartyId2 ? this.purchaseOrderDetails.NotifyParty2 : {};
        this.purchaseOrderDetails.incoTerms = this.purchaseOrderDetails.incoTerms ? this.purchaseOrderDetails.incoTerms : 'defaultIncoTerms';

        if (!this.purchaseOrderDetails.contactPerson) {
          this.purchaseOrderDetails.contactPerson = {};
        }
        if (this.purchaseOrderDetails.PurchaseOrderItems.length === 0) {
          this.addItem();
        }
        this.itemChange$.next(undefined);
        if (this.purchaseOrderDetails.ocrStatus !== 'FAILED' && this.purchaseOrderDetails.importedDocUrl) {
          this.poOCRSubscription = interval(3000).pipe(skip(3)).subscribe(tick => {
            this.orgApi.ocrResults('PurchaseOrder', this.purchaseOrderDetails.purchaseOrderId).pipe(
              catchError(err => of(null)),
              filter((result) => !!result),
            ).subscribe(results => {
              if (results && results.length > 0) {
                this.totalPages = results;
                this.poOCRSubscription.unsubscribe();
                this.ocrResults = results;
                this.loadData(0);
              }
            });
          });
        }
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  loadData(index: number): void {
    this.purchaseOrderDetails.ocrStatus = 'DONE';
    this.http.get(this.ocrResults[index].metadata).subscribe(data => {
      const dummyObj: any = data;
      this.selectedImageURL = this.ocrResults[index].background;
      this.thisPage = dummyObj.fullTextAnnotation.pages[0];
      this.bgwidth = this.thisPage.width;
      this.bgheight = this.thisPage.height;
      this.selectors = this.orgHelper.calBox(this.thisPage);
      this.changeDetectorRef.detectChanges();
    }, err => {
      SwalHelper.showErrorSwal(err);
    });
  }

  selectText(str): void {
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    const selected =
      document.getSelection().rangeCount > 0
        ? document.getSelection().getRangeAt(0)
        : false;
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    if (selected) {
      document.getSelection().removeAllRanges();
      document.getSelection().addRange(selected);
      this.copyText = 'Copied';
    }
  }

  resetCopytext(): void {
    this.copyText = 'Click to copy';
  }

  selectBuyer(): void {
    if (this.purchaseOrderDetails.sellerId == -1) {
      this.rightSheetOpen();
      this.newContactType = 'Seller';
      this.purchaseOrderDetails.VendorAndSupplier = {};
      this.selectedVendor = undefined;
    } else {
      const index = this.vendorSuppliers.findIndex(i => i.venSupId == this.purchaseOrderDetails.sellerId);
      if (index >= 0) {
        this.buyerDetail = this.vendorSuppliers[index];
      }
    }
  }

  selectConsignee(): void {
    if (this.purchaseOrderDetails.consigneeId == -1) {
      this.rightSheetOpen();
      this.newContactType = 'Consignee';
      this.purchaseOrderDetails.Consignee = {};
      this.selectedVendor = undefined;
    } else {
      const index = this.vendorSuppliers.findIndex(i => i.venSupId == this.purchaseOrderDetails.consigneeId);
      if (index >= 0) {
        this.consigneeDetail = this.vendorSuppliers[index];
      }
    }
  }
  selectNotifyParty(): void {
    if (this.purchaseOrderDetails.notifyPartyId == -1) {
      this.rightSheetOpen();
      this.newContactType = 'Notify Party';
      this.purchaseOrderDetails.NotifyParty = {};
      this.selectedVendor = undefined;
    } else {
      const index = this.vendorSuppliers.findIndex(i => i.venSupId == this.purchaseOrderDetails.notifyPartyId);
      if (index >= 0) {
        this.notifyPartyDetails = this.vendorSuppliers[index];
      }
    }
  }
  selectNotifyParty2(): void {
    if (this.purchaseOrderDetails.notifyPartyId2 == -1) {
      this.rightSheetOpen();
      this.newContactType = 'Notify Party';
      this.purchaseOrderDetails.NotifyParty2 = {};
      this.selectedVendor = undefined;
    } else {
      const index = this.vendorSuppliers.findIndex(i => i.venSupId == this.purchaseOrderDetails.notifyPartyId2);
      if (index >= 0) {
        this.notifyParty2Details = this.vendorSuppliers[index];
      }
    }
  }

  editVendor(id, type): void {
    this.selectedVendor = id;
    this.newContactType = type;
    this.rightSheetOpen();
  }

  rightSheetOpen(): void {
    this.rightSheet = !this.rightSheet;
    this.renderer.addClass(document.body, 'modal-open');
    this.commonService.addBackdrop();
  }

  rightSheetClose(): void {
    this.purchaseOrderDetails.sellerId = this.purchaseOrderDetails.sellerId > 0 ? this.purchaseOrderDetails.sellerId : null;
    this.purchaseOrderDetails.consigneeId = this.purchaseOrderDetails.consigneeId > 0 ? this.purchaseOrderDetails.consigneeId : null;
    this.purchaseOrderDetails.notifyPartyId = this.purchaseOrderDetails.notifyPartyId > 0 ? this.purchaseOrderDetails.notifyPartyId : null;
    this.purchaseOrderDetails.notifyPartyId2 = this.purchaseOrderDetails.notifyPartyId2 > 0 ? this.purchaseOrderDetails.notifyPartyId2 : null;
    this.rightSheet = false;
    this.renderer.removeClass(document.body, 'modal-open');
    this.commonService.removeBackdrop();
  }

  newVendor(vendor): void {
    if (!this.vendorSuppliers) {
      this.vendorSuppliers = [];
    }
    const index = this.vendorSuppliers.findIndex(i => i.venSupId == vendor.venSupId);
    if (index >= 0) {
      this.vendorSuppliers[index] = vendor;
    } else {
      this.vendorSuppliers.push(vendor);
    }
    this.purchaseOrderDetails.consigneeId = this.purchaseOrderDetails.consigneeId == -1 ? parseInt(vendor.venSupId, 10) : this.purchaseOrderDetails.consigneeId;
    this.purchaseOrderDetails.notifyPartyId = this.purchaseOrderDetails.notifyPartyId == -1 ? parseInt(vendor.venSupId, 10) : this.purchaseOrderDetails.notifyPartyId;
    this.purchaseOrderDetails.notifyPartyId2 = this.purchaseOrderDetails.notifyPartyId2 == -1 ? parseInt(vendor.venSupId, 10) : this.purchaseOrderDetails.notifyPartyId2;
    this.purchaseOrderDetails.sellerId = this.purchaseOrderDetails.sellerId == -1 ? parseInt(vendor.venSupId, 10) : this.purchaseOrderDetails.sellerId;
    this.selectConsignee();
    this.selectNotifyParty();
    this.selectNotifyParty2();
    this.selectBuyer();
    this.rightSheetClose();
    this.selectedVendor = undefined;
  }

  savePurchaseOrder(form): boolean {
    if (form.valid) {
      if (this.purchaseOrderDetails.purchaseOrderId) {
        // tslint:disable-next-line: max-line-length
        this.purchaseOrderDetails.sellerOrderDate = this.purchaseOrderDetails.sellerOrderDate ? moment(this.purchaseOrderDetails.sellerOrderDate).format('YYYY-MM-DD') : null;
        // tslint:disable-next-line: max-line-length
        this.purchaseOrderDetails.orgOrderDate = this.purchaseOrderDetails.orgOrderDate ? moment(this.purchaseOrderDetails.orgOrderDate).format('YYYY-MM-DD') : null;
        // tslint:disable-next-line: max-line-length
        this.purchaseOrderDetails.poDeliveryDate = this.purchaseOrderDetails.poDeliveryDate ? moment(this.purchaseOrderDetails.poDeliveryDate).format('YYYY-MM-DD') : null;
        // tslint:disable-next-line: max-line-length
        this.purchaseOrderDetails.poDispatchDate = this.purchaseOrderDetails.poDispatchDate ? moment(this.purchaseOrderDetails.poDispatchDate).format('YYYY-MM-DD') : null;
        this.purchaseOrderDetails.sellerId = this.purchaseOrderDetails.sellerId == -1 ? null : this.purchaseOrderDetails.sellerId;
        this.purchaseOrderDetails.notifyPartyId = this.purchaseOrderDetails.notifyPartyId == -1 ? null : this.purchaseOrderDetails.notifyPartyId;
        this.purchaseOrderDetails.notifyPartyId2 = this.purchaseOrderDetails.notifyPartyId2 == -1 ? null : this.purchaseOrderDetails.notifyPartyId2;
        this.purchaseOrderDetails.incoTerms = this.purchaseOrderDetails.incoTerms === 'defaultIncoTerms' ? null : this.purchaseOrderDetails.incoTerms;

        this.orgOrderApi.updatePo(this.purchaseOrderDetails).subscribe(po => {
          if (po) {
            this.shouldShowLoader = false;
            this.purchaseOrderDetails = po;
            this.POFormRef.form.markAsPristine();
            this.purchaseOrderDetails.incoTerms = this.purchaseOrderDetails.incoTerms ? this.purchaseOrderDetails.incoTerms : 'defaultIncoTerms';
            if (this.isRedirectToPreview) {
              this.router.navigate([`/organisation/purchase-orders/purchase-order-detail/${this.purchaseOrderDetails.purchaseOrderId}`]);
            } else {
              SwalHelper.successTimerSwal('Import Order updated successfully');
            }
          }
        }, err => {
          this.shouldShowLoader = false;
          SwalHelper.showErrorSwal(err);
        });
      }
    } else {
      return false;
    }
  }
  captureSalesOrderFile(event): void {
    if (event.target.files.length + this.purchaseOrderFiles.length > 5) {
      SwalHelper.showErrorSwal('Maximum 5 files allowed');
      return;
    }
    if (event.target.files && event.target.files.length > 0) {
      this.buttonDisabled = false;
      Object.keys(event.target.files).forEach((key) => {
        this.purchaseOrderFiles.push(event.target.files[key]);
      });
    }
  }

  uplaodNewDocument(): void {
    this.shouldShowLoader = true;
    this.orgOrderApi.uploadOrderAttachment(
      this.purchaseOrderFiles[0],
      this.purchaseOrderDetails.purchaseOrderId,
      undefined,
      this.purchaseOrderFiles[1],
      this.purchaseOrderFiles[2],
      this.purchaseOrderFiles[3],
      this.purchaseOrderFiles[4]
    ).subscribe(docs => {
      if (docs) {
        this.shouldShowLoader = false;
        SwalHelper.successTimerSwal('Document uploaded');
        $('#fileUploadModal').modal('hide');
        if (!this.purchaseOrderDetails.DocumentAttachments) {
          this.purchaseOrderDetails.DocumentAttachments = [];
        }
        this.purchaseOrderDetails.DocumentAttachments = docs;
        this.purchaseOrderFiles = []; // Clean the document file array
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }
  removeAttchment(index: number): void {
    Swal({
      title: 'Delete Attachment',
      text: 'This cannot be undone',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        this.shouldShowLoader = true;
        return new Promise((resolve, reject) => {
          this.orgOrderApi.deleteOrderAttachment(
            this.purchaseOrderDetails.DocumentAttachments[index].docAttId,
            this.purchaseOrderDetails.purchaseOrderId,
          ).subscribe(ok => {
            this.shouldShowLoader = false;
            this.purchaseOrderDetails.DocumentAttachments.splice(index, 1);
            resolve(ok);
          }, err => {
            reject(err);
          });
        });
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.value) {
        this.shouldShowLoader = false;
        SwalHelper.successTimerSwal('Attachment deleted!');
      }
    }).catch(err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  public downloadFile(docAttId: number): void {
    this.orgApi.orgDocumentSignedUrl(docAttId)
      .subscribe(url => {
        window.open(url.url, '_blank');
      });
  }

  addNewItemMaster(index: number): void {
    $('#itemMasterModal').modal('show');
    this.customItemIndex = index;
  }

  saveItemDetails(form): boolean {
    if (form.valid) {
      this.shouldShowLoader = true;
      this.orgApi.addItemMaster(this.itemMasterDetail
      ).subscribe(item => {
        this.shouldShowLoader = false;
        this.purchaseOrderDetails.PurchaseOrderItems[this.customItemIndex].itemMasterId = item.itemMasterId;
        this.purchaseOrderDetails.PurchaseOrderItems[this.customItemIndex].itemName = item.itemName;
        this.purchaseOrderDetails.PurchaseOrderItems[this.customItemIndex].itemDescription = item.itemDescription;
        this.purchaseOrderDetails.PurchaseOrderItems[this.customItemIndex].itemSubCode = item.itemSubCode;
        this.purchaseOrderDetails.PurchaseOrderItems[this.customItemIndex].itemHSNCode = item.itemHSNCode;
        this.purchaseOrderDetails.PurchaseOrderItems[this.customItemIndex].itemCode = item.itemCode;
        this.purchaseOrderDetails.PurchaseOrderItems[this.customItemIndex].itemUnit = item.itemUnit;
        this.purchaseOrderDetails.PurchaseOrderItems[this.customItemIndex].itemPrice = item.itemPrice;
        this.itemMasterDetail = {};
        this.customItemIndex = undefined;
        $('#itemMasterModal').modal('hide');
        SwalHelper.successTimerSwal('Item Added!');
        this.rightSheetClose();
      }, err => {
        this.shouldShowLoader = false;
        SwalHelper.showErrorSwal(err);
      });
    } else {
      return false;
    }
  }
  closeItemMasterModal(): void {
    this.itemMasterDetail = {};
    $('#itemMasterModal').modal('hide');
    this.purchaseOrderDetails.PurchaseOrderItems[this.customItemIndex].itemCode = undefined;
    this.customItemIndex = undefined;
  }
  trackByVendId(index: number, vendor: any): string {
    return vendor.venSupId;
  }

  redirectToPreview(): void {
    this.isRedirectToPreview = true;
  }

  /**
   * Fn to copy the amount in words
   * @param amount Amount to be copied
   */
  copyNumberToWord(amount): void {
    const pipe = new NumberToWordsPipe();
    const fiteredArr = pipe.transform(amount, 'US');

    const el = document.createElement('textarea');
    el.value = fiteredArr;
    el.setAttribute('readonly', '');
    document.body.appendChild(el);
    const selected =
      document.getSelection().rangeCount > 0
        ? document.getSelection().getRangeAt(0)
        : false;
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    if (selected) {
      document.getSelection().removeAllRanges();
      document.getSelection().addRange(selected);
      SwalHelper.showCenteredSavedToast('Copied');
    }
  }
}
