/**
 * Nuphi trade
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2.0.0
 * Contact: shraddha@blackcurrantapps.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface SendgridDomain { 
    id?: number;
    domain?: string;
    subdomain?: string;
    custom_spf?: boolean;
    _default?: boolean;
    legacy?: boolean;
    automatic_security?: boolean;
    valid?: boolean;
    dns?: any;
}
