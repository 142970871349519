import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ServiceProviderProfileComponent } from './service-provider-profile/service-provider-profile.component';
import { ServiceProviderProfileUpdateComponent } from './service-provider-profile/service-provider-profile-update/service-provider-profile-update.component';
import { ServiceProviderRoutingModule } from './service-provider-routing.module';
import { UserProfileUpdateComponent } from './service-provider-profile/user-profile-update/user-profile-update.component';
import { FormsModule } from '@angular/forms';
import { BASE_PATH } from 'src/swagger';
import { environment } from 'src/environments/environment';
import { ShipmentListComponent } from './shipment-list/shipment-list.component';
import { SpDashboardComponent } from './sp-dashboard/sp-dashboard.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { HelperModuleModule } from '../helper-module/helper-module.module';
import { ServiceProviderComponent } from './service-provider.component';

@NgModule({
  declarations: [
    ServiceProviderComponent,
    ServiceProviderProfileComponent,
    ServiceProviderProfileUpdateComponent,
    UserProfileUpdateComponent,
    ShipmentListComponent,
    SpDashboardComponent
  ],
  imports: [
    CommonModule,
    ServiceProviderRoutingModule,
    FormsModule,
    NgSelectModule,
    MatDatepickerModule,
    HelperModuleModule
  ],
  providers: [
    { provide: BASE_PATH, useValue: environment.BASE_PATH },
  ],
})
export class ServiceProviderModule { }
