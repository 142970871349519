import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { SwalHelper } from 'src/app/swal';
import { Invoice, OrganisationService, PurchaseOrder, Reminder, ReminderEmailList, SalesOrder, Shipment } from 'src/swagger';
import { GlobalProfileService } from '../../global-profile.service';
declare var $: any;
import * as moment from 'moment';

@Component({
  selector: 'app-reminder',
  templateUrl: './reminder.component.html',
  styleUrls: ['./reminder.component.scss']
})
export class ReminderComponent implements OnInit, OnChanges {

  public reminderDetail: Reminder = {};

  public emailList: ReminderEmailList[] = [];
  public shouldShowLoader = false;
  public otherReminderDate;

  public reminderAttachment;
  public imageSrc: string | ArrayBuffer = '';

  @Input() salesOrder: SalesOrder;
  @Input() purchaseOrder: PurchaseOrder;
  @Input() invoice: Invoice;
  @Input() shipment: Shipment;
  @Input() reminderInfo: Reminder;

  public nextDay = moment().add(1, 'day');

  constructor(
    private orgApi: OrganisationService,
    private profileSync: GlobalProfileService
  ) { }

  ngOnInit(): void {
    this.getEmails();
    if (this.reminderInfo) {
      this.reminderDetail = this.reminderInfo;
    }
  }

  ngOnChanges(): void {
    console.log('Change detected');
    if (this.reminderInfo) {
      this.reminderDetail = this.reminderInfo;
    } else {
      this.otherReminderDate = moment().add(1, 'day');
      this.reminderDetail.toEmail = [this.profileSync.user.email];
    }
    if (this.invoice && !this.reminderDetail.reminderId && this.invoice.invoiceType === 'PURCHASE') {
      this.reminderDetail.eventName = `Vendor Bill Reminder: ${this.invoice.invNo}`;
      this.reminderDetail.description = `Hi,

This is a reminder for ${this.invoice.invNo} dated ${moment(this.invoice.dateOfInv).format('DD MMM yyyy')} towards the upcoming key dates and events. The Invoice Amount is ${this.invoice.currency}${this.invoice.totalAmount} and the Balance Due Amount is ${this.invoice.currency}${this.invoice.balanceAmount ? this.invoice.balanceAmount : 0}. Request your necessary action.

- Vendor Bill Due Date: ${this.invoice.dueDate ? moment(this.invoice.dueDate).format('DD MMM yyyy') : ''}
- Delivery Date: ${this.invoice.expDeliveryDate ? moment(this.invoice.expDeliveryDate).format('DD MMM yyyy') : ''}
- Other Important Date:

Thanks,
${this.profileSync.user.firstName} ${this.profileSync.user.lastName}
${this.profileSync.user.Organisation.companyName}`;
    }

    if (this.invoice && !this.reminderDetail.reminderId && this.invoice.invoiceType === 'SALES') {
      this.reminderDetail.eventName = `Invoice Reminder: ${this.invoice.invNo}`;
      this.reminderDetail.description = `Hi,

This is a reminder for ${this.invoice.invNo} dated ${moment(this.invoice.dateOfInv).format('DD MMM yyyy')} towards the upcoming key dates and events. The Invoice Amount is ${this.invoice.currency}${this.invoice.totalAmount} and the Balance Due Amount is ${this.invoice.currency}${this.invoice.balanceAmount ? this.invoice.balanceAmount : 0}. Request your necessary action.

- Invoice Due Date: ${this.invoice.dueDate ? moment(this.invoice.dueDate).format('DD MMM yyyy') : ''}
- Delivery Date: ${this.invoice.expDeliveryDate ? moment(this.invoice.expDeliveryDate).format('DD MMM yyyy') : ''}
- Other Important Date:

Thanks,
${this.profileSync.user.firstName} ${this.profileSync.user.lastName}
${this.profileSync.user.Organisation.companyName}`;
    }

    if (this.salesOrder && !this.reminderDetail.reminderId) {
      this.reminderDetail.eventName = `Order Reminder: ${this.salesOrder.orgOrderNo}`;
      this.reminderDetail.description = `Hi,

This is a reminder for ${this.salesOrder.orgOrderNo} dated ${moment(this.salesOrder.orgOrderDate).format('DD MMM yyyy')} towards the upcoming key dates and events. The Order Amount is ${this.salesOrder.currency}${this.salesOrder.soTotalAmount}. Request your necessary action.

- Dispatch Due Date: ${this.salesOrder.soDispatchDate ? moment(this.salesOrder.soDispatchDate).format('DD MMM yyyy') : ''}
- Delivery Date: ${this.salesOrder.soDeliveryDate ? moment(this.salesOrder.soDeliveryDate).format('DD MMM yyyy') : ''}
- Other Important Date:

Thanks,
${this.profileSync.user.firstName} ${this.profileSync.user.lastName}
${this.profileSync.user.Organisation.companyName}`;
    }

    if (this.purchaseOrder && !this.reminderDetail.reminderId) {
      this.reminderDetail.eventName = `Order Reminder: ${this.purchaseOrder.orgOrderNo}`;
      this.reminderDetail.description = `Hi,

This is a reminder for ${this.purchaseOrder.orgOrderNo} dated ${moment(this.purchaseOrder.orgOrderDate).format('DD MMM yyyy')} towards the upcoming key dates and events. The Order Amount is ${this.purchaseOrder.currency}${this.purchaseOrder.poTotalAmount}. Request your necessary action.

- Dispatch Due Date: ${this.purchaseOrder.poDispatchDate ? moment(this.purchaseOrder.poDispatchDate).format('DD MMM yyyy') : ''}
- Delivery Date: ${this.purchaseOrder.poDeliveryDate ? moment(this.purchaseOrder.poDeliveryDate).format('DD MMM yyyy') : ''}
- Other Important Date:

Thanks,
${this.profileSync.user.firstName} ${this.profileSync.user.lastName}
${this.profileSync.user.Organisation.companyName}`;
    }

    if (this.shipment && !this.reminderDetail.reminderId) {
      this.reminderDetail.eventName = `Shipment Reminder: ${this.shipment.shipmentNoPre}${this.shipment.shipmentNoSuf}`;
      this.reminderDetail.description = `Hi,

This is a reminder for ${this.shipment.shipmentNoPre}${this.shipment.shipmentNoSuf} towards the upcoming key dates and events. Request your necessary action.

- ETA Date: ${this.shipment.shipmentETA ? moment(this.shipment.shipmentETA).format('DD MMM yyyy') : ''}
- ETD Date: ${this.shipment.shipmentETD ? moment(this.shipment.shipmentETD).format('DD MMM yyyy') : ''}
- Other Important Date:

Thanks,
${this.profileSync.user.firstName} ${this.profileSync.user.lastName}
${this.profileSync.user.Organisation.companyName}`;
    }

  }

  /* Fn to get the list of Emails */
  getEmails(): void {
    this.orgApi.reminderEmailList().subscribe(emails => {
      if (emails && emails.length > 0) {
        this.emailList = emails;
      }
    }, err => {
      SwalHelper.showErrorSwal(err);
    });
  }

  /**
   * Fn to save the reminder detail
   * @param form angular form
   */
  saveReminderDetails(form: NgForm): void {
    this.reminderDetail.targetDate = this.otherReminderDate ? this.otherReminderDate : this.reminderDetail.targetDate;
    this.shouldShowLoader = true;
    if (this.salesOrder) {
      this.reminderDetail.salesOrderId = this.salesOrder.salesOrderId;
    } else if (this.purchaseOrder) {
      this.reminderDetail.purchaseOrderId = this.purchaseOrder.purchaseOrderId;
    } else if (this.invoice) {
      this.reminderDetail.invId = this.invoice.invId;
    } else if (this.shipment) {
      this.reminderDetail.shipmentId = this.shipment.shipmentId;
    }
    this.reminderDetail.targetDate = moment(this.reminderDetail.targetDate).format('YYYY-MM-DD');
    if (this.reminderDetail.reminderId) {
      this.orgApi.updateReminder(
        this.reminderDetail
      ).subscribe(reminder => {
        this.reminderDetail = reminder;
        if (this.reminderAttachment) {
          this.uploadReminderAttachment();
        }
        this.shouldShowLoader = false;
        form.reset();
        $('#reminderMainModal').modal('hide');
        SwalHelper.successTimerSwal('Reminder updated successfully!');
      }, err => {
        this.shouldShowLoader = false;
        SwalHelper.showErrorSwal(err);
      });
    } else {
      this.orgApi.createReminder(
        this.reminderDetail
      ).subscribe(reminder => {
        this.reminderDetail = reminder;
        if (this.reminderAttachment) {
          this.uploadReminderAttachment();
        }
        this.shouldShowLoader = false;
        form.reset();
        SwalHelper.successTimerSwal('Reminder created successfully!');
        $('#reminderModal').modal('hide');
      }, err => {
        this.shouldShowLoader = false;
        SwalHelper.showErrorSwal(err);
      });
    }
  }

  otherDateChange(): void {
    this.reminderDetail.targetDate = null;
  }

  changeTriggerDate(): void {
    this.otherReminderDate = null;
  }

  /* fn to set the remidner attachment */
  setReminderAttachment(event): void {
    this.reminderAttachment = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(this.reminderAttachment);
    reader.onload = ev => {
      this.imageSrc = reader.result;
    };
  }

  /* Fn to upload Reminder Attachment */
  uploadReminderAttachment(): void {
    this.orgApi.uploadReminderAttachment(
      this.reminderDetail.reminderId,
      this.reminderAttachment)
      .subscribe(url => {
        this.reminderAttachment = undefined;
        this.reminderDetail.attachmentUrl = url.url;
      }, err => {
        SwalHelper.showErrorSwal('Logo not updated , Please try again');
      });
  }

  /* Fn to remove the attachment */
  removeAttchment(): void {
    this.reminderDetail.attachmentUrl = undefined;
    this.reminderDetail.attachmentData = {};
  }

}
