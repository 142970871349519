import { Component, OnInit } from '@angular/core';
import { GlobalProfileService } from 'src/app/global-profile.service';
import { SwalHelper } from 'src/app/swal';
import { OrganisationService } from 'src/swagger';
import { SendgridDomain } from '../../../swagger/model/sendgridDomain';
declare var swal: any;

@Component({
  selector: 'app-user-preference',
  templateUrl: './user-preference.component.html',
  styleUrls: ['./user-preference.component.scss']
})
export class UserPreferenceComponent implements OnInit {

  public shouldShowLoader = false;
  public domainName: string;
  public subDomainName: string;
  public domainRecord: SendgridDomain;

  constructor(
    public profileSync: GlobalProfileService,
    private orgApi: OrganisationService
  ) { }

  ngOnInit(): void {
    this.fetchDomainRecords();
  }

  /* API to update the user preferences for notification email */
  updatePreference(): void {
    this.shouldShowLoader = true;
    this.orgApi.userPreference(
      this.profileSync.user
    ).subscribe(user => {
      this.shouldShowLoader = false;
      if (user) {
        this.profileSync.user.preferences = user.preferences;
        SwalHelper.successTimerSwal('Preferences Updated');
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  fetchDomainRecords(): void {
    if (this.profileSync.user && this.profileSync.user.Organisation && this.profileSync.user.Organisation.domainName) {
      this.shouldShowLoader = true;
      this.orgApi.checkDomain().subscribe(data => {
        this.shouldShowLoader = false;
        if (data) {
          this.domainRecord = data;

        }
      }, err => {
        this.shouldShowLoader = false;
        SwalHelper.showErrorSwal(err);
      });
    }
  }

  validateDomainRecords(): void {
    if (this.profileSync.user && this.profileSync.user.Organisation && this.profileSync.user.Organisation.domainName) {
      this.shouldShowLoader = true;
      this.orgApi.validateDomain().subscribe(data => {
        this.shouldShowLoader = false;
        if (data) {
          this.domainRecord = data;
          this.profileSync.user.Organisation.isDomainVerified = true;
        }
        SwalHelper.successTimerSwal('Domain authenticated');
      }, err => {
        this.shouldShowLoader = false;
        SwalHelper.showErrorSwal(err);
      });
    }
  }

  createDomain(): void {
    swal({
      title: 'Create Domain',
      text: 'This cannot be undone',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Create',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        this.shouldShowLoader = true;
        return new Promise((resolve, reject) => {
          this.orgApi.createDomain(this.domainName, this.subDomainName).subscribe(ok => {
            this.profileSync.user.Organisation.domainName = this.domainName;
            this.domainRecord = ok;
            this.shouldShowLoader = false;
            resolve(ok);
          }, err => {
            reject(err);
          });
        });
      },
      allowOutsideClick: () => !swal.isLoading()
    }).then((result) => {
      if (result.value) {
        this.shouldShowLoader = false;
        SwalHelper.successTimerSwal('Domain created!');
      }
    }).catch(err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  copyDNSValue(str): void {
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    el.focus();
    document.execCommand('copy');
    document.body.removeChild(el);
    SwalHelper.showToast('success', 'Copied');
  }

}
