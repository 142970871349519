import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ServiceProviderProfileComponent } from './service-provider-profile/service-provider-profile.component';
import { ServiceProviderProfileUpdateComponent } from './service-provider-profile/service-provider-profile-update/service-provider-profile-update.component';
import { UserProfileUpdateComponent } from './service-provider-profile/user-profile-update/user-profile-update.component';
import { ServiceProviderComponent } from './service-provider.component';
import { ShipmentListComponent } from './shipment-list/shipment-list.component';
import { SpDashboardComponent } from './sp-dashboard/sp-dashboard.component';
import { ComingSoonComponent } from '../coming-soon/coming-soon.component';
import { ChangePasswordSettingComponent } from '../auth/change-password-setting/change-password-setting.component';

const routes: Routes = [
  {
    path: 'service-provider',
    component: ServiceProviderComponent,
    children: [
      {
        path: '',
        redirectTo: 'sp-dashboard',
        pathMatch: 'full'
      },
      {
        path: 'sp-dashboard',
        component: SpDashboardComponent
      },
      {
        path: 'coming-soon',
        component: ComingSoonComponent
      },
      {
        path: 'change-password-setting',
        component: ChangePasswordSettingComponent
      },
      {
        path: 'service-provider-profile',
        component: ServiceProviderProfileComponent,
      },
      {
        path: 'service-provider-profile-update',
        component: ServiceProviderProfileUpdateComponent,
      },
      {
        path: 'user-profile-update',
        component: UserProfileUpdateComponent,
      },
      {
        path: 'shipment-list',
        component: ShipmentListComponent
      },
      {
        path: 'shipment',
        loadChildren: () => import('../shipment/shipment.module')
          .then(m => m.ShipmentModule)
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ServiceProviderRoutingModule { }
