import {
  Pipe,
  PipeTransform
} from '@angular/core';
import { ToWords } from 'to-words';

@Pipe({
  name: 'numberToWords'
})
export class NumberToWordsPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value) {
      const toWords = new ToWords({
        localeCode: `en-${args}`,
        converterOptions: {
          currency: true,
          ignoreDecimal: false,
          ignoreZeroCurrency: false,
        }
      });

      if (!toWords.isValidNumber(value)) {
        return 'Not valid number';
      }

      value = parseFloat(value.toFixed(2));
      return toWords.convert(value);
    } else {
      return '';
    }
  }

}
