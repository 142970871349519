import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencyHelper'
})
export class CurrencyHelperPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    if (value === 'USD') {
      return '$';
    } else if (value === 'INR') {
      return '₹';
    } else if (value === 'AUD') {
      return 'A$';
    } else if (value === 'AED') {
      return 'د.إ';
    } else if (value === 'SGD') {
      return 'S$';
    } else if (value === 'JPY') {
      return '¥';
    } else if (value === 'GBP') {
      return '£';
    } else if (value === 'EUR') {
      return '€';
    } else if (value === 'HKD') {
      return 'HK$';
    } else if (value === 'CAD') {
      return 'C$';
    } else if (value === 'CHF') {
      return 'CHf';
    } else if (value === 'CNY') {
      return '¥';
    }
  }

}
