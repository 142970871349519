export * from './auth.service';
import { AuthService } from './auth.service';
export * from './licenses.service';
import { LicensesService } from './licenses.service';
export * from './open.service';
import { OpenService } from './open.service';
export * from './orgContact.service';
import { OrgContactService } from './orgContact.service';
export * from './orgInvoice.service';
import { OrgInvoiceService } from './orgInvoice.service';
export * from './orgOrders.service';
import { OrgOrdersService } from './orgOrders.service';
export * from './orgShipments.service';
import { OrgShipmentsService } from './orgShipments.service';
export * from './organisation.service';
import { OrganisationService } from './organisation.service';
export * from './reports.service';
import { ReportsService } from './reports.service';
export * from './serviceProvider.service';
import { ServiceProviderService } from './serviceProvider.service';
export * from './shipmentDocs.service';
import { ShipmentDocsService } from './shipmentDocs.service';
export const APIS = [AuthService, LicensesService, OpenService, OrgContactService, OrgInvoiceService, OrgOrdersService, OrgShipmentsService, OrganisationService, ReportsService, ServiceProviderService, ShipmentDocsService];
