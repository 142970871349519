import { ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { CommonSupportService } from 'src/app/common-support.service';
import { GlobalProfileService } from 'src/app/global-profile.service';
import { SwalHelper } from 'src/app/swal';
import { AuthService, OrgContactService, Shipment, OrgShipmentsService, ShipmentTracking } from 'src/swagger';
import { ExpenseTracker } from '../../../swagger/model/expenseTracker';
declare var $: any;
declare var Swal: any;

@Component({
  selector: 'app-shipment-details',
  templateUrl: './shipment-details.component.html',
  styleUrls: ['./shipment-details.component.scss']
})
export class ShipmentDetailsComponent implements OnInit {
  // quick switch
  public shipments: Shipment[] = [];
  public selectedShipmentUid: string = undefined;

  @ViewChild('slider', { read: ElementRef }) slider: ElementRef;
  @ViewChild('scrollChatWindow') scrollChatWindow: ElementRef;


  control = new FormControl();
  files = new FormControl();

  public rightSheet = false;
  public bottomSheet = false;
  public shipmentUserAccess = false;

  public shipmentDetails: Shipment = {};
  public shouldShowLoader: boolean;

  public availableUsers = [];
  public availableChatUsers = new BehaviorSubject<any[]>([]);

  public eta: string;
  public etd: string;
  public getFunded = false;

  public statusPercentage: string;
  public shipPercentage: string;
  public isShowDemo = false;

  public reminderShipments: Shipment = {};

  constructor(
    private orgContactApi: OrgContactService,
    private orgShipmentApi: OrgShipmentsService,
    private activatedRoute: ActivatedRoute,
    private renderer: Renderer2,
    private commonService: CommonSupportService,
    public profileSync: GlobalProfileService,
    private authApi: AuthService,
    public router: Router,
    private changeDetector: ChangeDetectorRef,
  ) {
    commonService.renderer = renderer;
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent): void {
    this.rightSheet = false;
    this.bottomSheet = false;
    this.renderer.removeClass(document.body, 'modal-open');
  }

  private autoSelectCurrentShipment(): void {

    this.activatedRoute.paramMap.pipe(
      filter(params => params.has('uid')),
      take(1)
    ).subscribe(params => {
      if (this.shipments) {
        this.selectedShipmentUid = this.shipments.find(s => s.shipmentUid === params.get('uid')).shipmentUid;
      }
      this.changeDetector.detectChanges();
    });
  }

  public onSelect(): void {
    this.router.navigate(['../', this.selectedShipmentUid], { relativeTo: this.activatedRoute });
  }

  ngOnInit(): void {
    // quick switch
    // this.profileSync.user$.pipe(take(1)).subscribe(user => {
    //   if (user.isInternal) {
    //     this.orgShipmentApi.listShipmentNos().subscribe(shipments => {
    //       this.shipments = shipments;
    //       this.autoSelectCurrentShipment();
    //     });
    //   } else {
    //     this.authApi.extShipmentsSharedWithMe().subscribe(shipments => {
    //       this.shipments = shipments;
    //       this.autoSelectCurrentShipment();
    //     });
    //   }
    // });


    this.activatedRoute.params.subscribe(param => {
      if (param && param.uid) {
        this.shipmentDetails = {};
        this.shipmentDetails.shipmentUid = param.uid;
        this.fetchShipmentDetails();
      }
    });
    if (localStorage.getItem('ShowshipmentDemo') === 'Yes') {
      this.isShowDemo = true;
    } else {
      this.isShowDemo = false;
    }
  }

  receivedDemoEvent(resp: boolean): void {
    this.isShowDemo = !resp;
    if (!this.isShowDemo) {
      localStorage.removeItem('ShowshipmentDemo');
    }
  }

  closeShipmentTracking(track: ShipmentTracking): void {
    this.shipmentDetails.ShipmentTracking = track;
  }

  fetchShipmentDetails(): void {
    this.shouldShowLoader = true;
    this.orgContactApi.getOrgShipment(
      undefined,
      this.shipmentDetails.shipmentUid
    ).subscribe(shipment => {
      this.shouldShowLoader = false;
      if (shipment) {
        this.shipmentDetails = shipment;
        if (this.profileSync.user && this.profileSync.user.orgId ===  this.shipmentDetails.orgId) {
          this.orgShipmentApi.listShipmentNos().subscribe(shipments => {
            this.shipments = shipments;
            if (this.shipmentDetails.shipmentStatus === 'ARCHIVED') {
              this.shipments.push({
                shipmentUid: this.shipmentDetails.shipmentUid,
                shipmentId: this.shipmentDetails.shipmentId,
                shipmentType: this.shipmentDetails.shipmentType,
                shipmentNo: `${this.shipmentDetails.shipmentNoPre}${this.shipmentDetails.shipmentNoSuf}`
              });
            }
            this.autoSelectCurrentShipment();
          });
        } else {
          this.authApi.extShipmentsSharedWithMe().subscribe(shipments => {
            this.shipments = shipments;
            this.autoSelectCurrentShipment();
          });
        }


        this.calcPercentage();
        this.availableUsers = [];
        if (this.shipmentDetails.ShipmentAccesses && this.shipmentDetails.ShipmentAccesses.length > 0) {
          this.availableUsers = this.shipmentDetails.ShipmentAccesses.map(i => {
            return {
              name: i.OrganisationContact.contactName,
              email: i.OrganisationContact.contactEmail,
              orgContactId: i.OrganisationContact.orgContactId,
            };
          });
        }
        this.availableChatUsers.next(this.availableUsers);
        this.changeDetector.detectChanges();
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  calcPercentage(): void {
    if (this.shipmentDetails.shipmentStatus === 'ORDER') {
      this.statusPercentage = '20%';
      this.shipPercentage = '18%';
    } else if (this.shipmentDetails.shipmentStatus === 'INVOICE') {
      this.statusPercentage = '40%';
      this.shipPercentage = '36%';
    } else if (this.shipmentDetails.shipmentStatus === 'IN_TRANSIT') {
      this.statusPercentage = '60%';
      this.shipPercentage = '54%';
    } else if (this.shipmentDetails.shipmentStatus === 'DELIVERED') {
      this.statusPercentage = '80%';
      this.shipPercentage = '72%';
    } else if (this.shipmentDetails.shipmentStatus === 'RECONCILED' || this.shipmentDetails.shipmentStatus === 'CLOSED' || this.shipmentDetails.shipmentStatus === 'DISPUTED') {
      this.statusPercentage = '100%';
      this.shipPercentage = '90%';
    } else {
      this.statusPercentage = '0%';
      this.shipPercentage = '0%';
    }
  }

  changeETD(): void {
    this.orgContactApi.shipmentEtdates(
      this.shipmentDetails.shipmentId,
      this.shipmentDetails.shipmentETA ? moment(this.shipmentDetails.shipmentETA).format('YYYY-MM-DD') : null,
      this.shipmentDetails.shipmentETD ? moment(this.shipmentDetails.shipmentETD).format('YYYY-MM-DD') : null
      ).subscribe(ok => {
      SwalHelper.showSavedToast('Saved');
    }, err => {
      SwalHelper.showErrorSwal(err);
    });
  }

  rightSheetOpen(): void {
    this.rightSheet = !this.rightSheet;
    this.renderer.addClass(document.body, 'modal-open');
    this.commonService.addBackdrop();
  }

  rightSheetClose(): void {
    this.rightSheet = false;
    this.renderer.removeClass(document.body, 'modal-open');
    this.commonService.removeBackdrop();
  }

  bottomSheetOpen(): void {
    this.bottomSheet = true;
    this.renderer.addClass(document.body, 'modal-open');
    this.commonService.addBackdrop();
  }

  bottomSheetClose(): void {
    this.bottomSheet = false;
    this.renderer.removeClass(document.body, 'modal-open');
    this.commonService.removeBackdrop();
  }

  receivedUpdatedUsers(users): void {
    this.availableUsers = users;
    this.availableChatUsers.next(this.availableUsers);
  }

  updatedShipmentDetails(shipment: Shipment): void {
    this.shipmentDetails.shipmentDate = shipment.shipmentDate;
    this.shipmentDetails.shipmentValue = shipment.shipmentValue;
    this.shipmentDetails.shipmentTags = shipment.shipmentTags;
    this.shipmentDetails.shipmentStatus = shipment.shipmentStatus;
    const index = this.shipments.findIndex(i => i.shipmentUid == shipment.shipmentUid);
    if (index >= 0) {
      this.shipments[index].shipmentNo = `${shipment.shipmentNoPre}${shipment.shipmentNoSuf}`;
      this.shipments = [...this.shipments];
    }
    this.rightSheetClose();
  }

  logout(): void {
    this.profileSync.clearToken();
    this.router.navigate(['/login']);
  }

  updatedAmount(remainingAmount: number): void {
    this.shipmentDetails.Invoice.Invoice.balanceAmount = remainingAmount;
  }

  updatedVBAmount(remainingAmount: number): void {
    this.shipmentDetails.VendorBill.Invoice.balanceAmount = remainingAmount;
  }

  openExpenseTracker(): void {
    if (this.shipmentDetails.ExpenseTracker) {
      this.bottomSheetOpen();
    } else {
      const expenseTracker: ExpenseTracker = {};
      expenseTracker.shipmentId = this.shipmentDetails.shipmentId;
      expenseTracker.orgCurrency = this.profileSync.user.Organisation.currency;
      this.orgShipmentApi.createExpenseTracking(
        expenseTracker
      ).subscribe(expense => {
        this.shipmentDetails.ExpenseTracker = expense;
        this.bottomSheetOpen();
      }, err => {
        SwalHelper.showErrorSwal(err);
      });
    }
  }

  /**
   * Fn to Open the reminder modal
   * @param index Index of Invoice for reminder
   */
  openReminder(index: number): void {
    $('#reminderModal').modal('show');
    this.reminderShipments = this.shipmentDetails;
  }

  updateStatus(status: Shipment.ShipmentStatusEnum): void {

    const text = `This cannot be reversed Later`;
    Swal({
      title: `Update Shipment status to ${status.replace('_', ' ').toUpperCase()}`,
      text,
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Update',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        this.shouldShowLoader = true;
        return new Promise((resolve, reject) => {
          this.orgShipmentApi.updateShipmentStatus(
            this.shipmentDetails.shipmentId,
            status
          ).subscribe(ok => {
            this.shipmentDetails.shipmentStatus = status;
            this.shipmentDetails.ShipmentDialogues.push(ok);
            this.calcPercentage();
            this.shouldShowLoader = false;
            resolve(ok);
          }, err => {
            reject(err);
          });
        });
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.value) {
        this.shouldShowLoader = false;
        SwalHelper.successTimerSwal(`Shipment marked as ${status.replace('_', ' ').toLocaleLowerCase()}`);
      }
    }).catch(err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

}
