import { Component, OnInit } from '@angular/core';
import { Invoice, OrganisationService, ReportsService, VendorAndSupplier } from 'src/swagger';
import { SwalHelper } from 'src/app/swal';
import * as moment from 'moment';
import { OrganisationHelperService } from '../../organisation-helper.service';
import { GlobalProfileService } from 'src/app/global-profile.service';
import { CommonSupportService } from '../../../common-support.service';

@Component({
  selector: 'app-invoice-report',
  templateUrl: './invoice-report.component.html',
  styleUrls: ['./invoice-report.component.scss']
})
export class InvoiceReportComponent implements OnInit {

  public allInvoices: Invoice[] = [];
  public shouldShowLoader: boolean;

  public fromDate: string;
  public toDate: string;

  public fromDueDate: string;
  public toDueDate: string;

  public status: string;
  public currency: string;

  public allVendors: VendorAndSupplier[] = [];

  public vendorId: number;

  dtOptions: any = {};

  public allInvAmount: Invoice[] = [];

  constructor(
    private reportApi: ReportsService,
    public orgHelper: OrganisationHelperService,
    public profileSync: GlobalProfileService,
    private orgApi: OrganisationService,
    public commonService: CommonSupportService
  ) { }

  ngOnInit(): void {
    this.resetFilter();
    this.fetchAllVendors();
    this.fetchInvTotal();
    this.dtOptions = {
      dom: 'Bfrtip',
      lengthMenu: [
        [10, 25, 50, -1],
        ['10', '25', '50', 'All']
      ],
      buttons: [
        'pageLength',
        {
          text: 'Excel Download',
          className: 'btn btn-green',
          extend: 'excelHtml5',
          title: 'invoice-report',
        }
      ],
      columnDefs: [
        { targets: 3, type: 'num-fmt' },
      ],
    };
  }

  /* Fn to fetch the invoice report */
  fetchAllInvoices(): void {
    this.allInvoices = [];
    this.shouldShowLoader = true;
    this.reportApi.invoiceReport(
      this.toDate ? moment(this.toDate).format('YYYY-MM-DD') : null,
      this.fromDate ? moment(this.fromDate).format('YYYY-MM-DD') : null,
      this.toDueDate ? moment(this.toDueDate).format('YYYY-MM-DD') : null,
      this.fromDueDate ? moment(this.fromDueDate).format('YYYY-MM-DD') : null,
      this.status === 'All' ? undefined : this.status,
      this.currency === 'All' ? undefined : this.currency,
      this.vendorId  == 0 ? undefined : this.vendorId,
    ).subscribe(invoices => {
      this.shouldShowLoader = false;
      if (invoices && invoices.length > 0) {
        this.allInvoices = invoices;
      } else {
        this.allInvoices = null;
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /* Fn to fetch all the vendors in filter */
  fetchAllVendors(): void {
    this.shouldShowLoader = true;
    this.orgApi.searchVendors(
      undefined,
      undefined,
      100
    ).subscribe(vendors => {
      this.shouldShowLoader = false;
      if (vendors && vendors.length > 0) {
        this.allVendors = vendors;
      } else {
        this.allVendors = null;
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /* Fn to reset the filter */
  resetFilter(): void {
    this.toDate = undefined;
    this.fromDate = undefined;
    this.toDueDate = undefined;
    this.fromDueDate = undefined;
    this.status = 'All';
    this.currency = 'All';
    this.vendorId = 0;
    this.fetchAllInvoices();
  }

  /* Function to fetch total amount in band*/
  fetchInvTotal(): void {
    this.shouldShowLoader = true;
    this.reportApi.totalInvAmountReport(
      'SALES',
      this.status === 'All' ? undefined : this.status,
    ).subscribe(pos => {
      this.shouldShowLoader = false;
      if (pos && pos.length > 0) {
        this.allInvAmount = pos;
      } else {
        this.allInvAmount = null;
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

}
