import { Component, HostListener, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { of, Subject, Subscription } from 'rxjs';
import { switchMap, catchError, tap, distinctUntilChanged } from 'rxjs/operators';
import { GlobalProfileService } from 'src/app/global-profile.service';
import { OrganisationHelperService } from 'src/app/organisation/organisation-helper.service';
import { SwalHelper } from 'src/app/swal';
import { ItemMaster, License, LicenseItem, LicensesService, OrganisationService } from 'src/swagger';
import { CommonSupportService } from '../../../../common-support.service';
declare var $: any;
declare var Swal: any;

@Component({
  selector: 'app-license-details',
  templateUrl: './license-details.component.html',
  styleUrls: ['./license-details.component.scss']
})
export class LicenseDetailsComponent implements OnInit, OnDestroy {

  @ViewChild('licenseItemForm')
  public licenseItemFormRef: NgForm;

  public showImport = false;
  public showExport = false;
  public itemSheet = false;

  public licenseDetail: License = {};
  public allLicenseNos: License[] = [];
  public licenseItemDetail: LicenseItem = {};
  public shouldShowLoader: boolean;
  public importItemUtilization = '0';
  public exportItemUtilization = '0';

  public itemChange$: Subject<string> = new Subject();
  private itemChangeSubscription: Subscription;
  public itemLoading: boolean;
  public itemMasters$: Subject<ItemMaster[]> = new Subject();

  public itemMasterDetail: ItemMaster = {};
  public customItemIndex: number;
  public itemType = 'Import';

  dtOptions: DataTables.Settings[] = [];
  public licenseItems: LicenseItem[] = [];

  public importItemUSDTotal: number;
  public importItemINRTotal: number;
  public importItemBalanceINRTotal: number;

  public exportItemUSDTotal: number;
  public exportItemINRTotal: number;

  public licenseImItemUSDTotal: number;
  public licenseImItemINRTotal: number;

  public licenseExItemUSDTotal: number;
  public licenseExItemINRTotal: number;

  public importItemQtyTotal: number;
  public exportItemQtyTotal: number;

  constructor(
    private activatedRoute: ActivatedRoute,
    public licenseApi: LicensesService,
    public orgHelper: OrganisationHelperService,
    public profileSync: GlobalProfileService,
    private orgApi: OrganisationService,
    private renderer: Renderer2,
    private commonService: CommonSupportService,
  ) {
    commonService.renderer = renderer;
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent): void {
    this.itemSheet = false;
    this.commonService.removeBackdrop();
    this.renderer.removeClass(document.body, 'modal-open');
  }

  ngOnInit(): void {
    this.showImport = true;
    this.activatedRoute.params.subscribe(param => {
      if (param && param.licenseId) {
        this.getlicenseDetail(param.licenseId);
      }
    });
    this.fetchAllLicenses();
    this.dtOptions = [{
      // For Import item table
      scrollX: true,
      paging: false,
      info: false,
      scrollY: '30vh',
      scrollCollapse: true,
    },
    {
      // For Export Item table
      scrollX: true,
      paging: false,
      info: false,
      scrollY: '30vh',
      scrollCollapse: true,
    },
    // License Item table
    {
      scrollX: true,
      paging: false,
      info: false,
      scrollY: '30vh',
      scrollCollapse: true,
      searching: false
    }
    ];

    this.itemChangeSubscription = this.itemChange$.pipe(
      distinctUntilChanged(),
      tap(() => this.itemLoading = true),
      switchMap(query => this.orgApi.listItemMaster(query).pipe(
        catchError(() => of([])), // empty list on error
        tap(() => {
          this.itemLoading = false;
        })
      ))
    ).subscribe(items => {
      this.itemMasters$.next(items);
    });
  }

  ngOnDestroy(): void {
    if (this.itemChangeSubscription) { this.itemChangeSubscription.unsubscribe(); }
  }

  /* Activated when user close the dirty form */
  canDeactivate(): boolean {
    if (!this.licenseItemFormRef.dirty) {
      this.rightSheetClose();
    } else {
      Swal.fire({
        title: 'License Item not saved!',
        text: 'Are you sure you want to close the form?',
        type: 'warning',
        showConfirmButton: true,
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.rightSheetClose();
        }
      });
    }
    return !this.licenseItemFormRef.dirty;
  }

  /* Fn to fetch all the license nos for selector */
  fetchAllLicenses(): void {
    this.shouldShowLoader = true;
    this.licenseApi.listLicensesNo().subscribe(nos => {
      this.shouldShowLoader = false;
      if (nos && nos.length > 0) {
        this.allLicenseNos = nos;
      } else {
        this.allLicenseNos = [];
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /* Fn to open the license item right sheet */
  rightSheetOpen(): void {
    this.itemSheet = !this.itemSheet;
    this.initItem();
    this.renderer.addClass(document.body, 'modal-open');
    this.commonService.addBackdrop();
  }

  /* Fn to close the license item right sheet */
  rightSheetClose(): void {
    this.itemSheet = false;
    this.renderer.removeClass(document.body, 'modal-open');
    this.commonService.removeBackdrop();
  }

  /* Fn to initilize the new license item */
  initItem(): void {
    this.licenseItemDetail = {};
    this.licenseItemDetail.itemCurrency = 'USD';
    this.licenseItemDetail.itemType = this.itemType;
  }

  /**
   * Fn to get the details of license
   * @param licenseId License id to get details
   */
  getlicenseDetail(licenseId): void {
    this.shouldShowLoader = true;
    this.licenseApi.getLicense(licenseId).subscribe(license => {
      this.shouldShowLoader = false;
      if (license) {
        this.licenseDetail = license;
        this.licenseDetail.LicenseItems = this.licenseDetail.LicenseItems && this.licenseDetail.LicenseItems.length > 0 ? this.licenseDetail.LicenseItems : [];

        this.importItemUtilization = this.licenseDetail.LicenseItems.filter(i => i.itemType === 'Import').length > 0 ? (this.licenseDetail.LicenseItems.map(item => (item.itemImpQtyUtilization * 100 / item.itemQuantity)).reduce((a, b) => a + b, 0) / this.licenseDetail.LicenseItems.filter(i => i.itemType === 'Import').length).toFixed(2) : '0';

        this.exportItemUtilization = this.licenseDetail.LicenseItems.filter(i => i.itemType === 'Export').length > 0 ? (this.licenseDetail.LicenseItems.map(item => (item.itemExpQtyUtilization * 100 / item.itemQuantity)).reduce((a, b) => a + b, 0) / this.licenseDetail.LicenseItems.filter(i => i.itemType === 'Export').length).toFixed(2) : '0';

        this.applyChange();
        this.calculateTotalAmount();
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /* Fn to calculate the total amount */
  calculateTotalAmount(): void {
    this.licenseExItemINRTotal = this.licenseDetail.LicenseItems.filter(item => item.itemType === 'Export').map(item => (item.itemQuantity * item.itemPrice * item.exchangeRateINR)).reduce((a, b) => a + b, 0);

    this.licenseExItemUSDTotal = this.licenseDetail.LicenseItems.filter(item => item.itemType === 'Export').map(item => (item.itemQuantity * item.itemPrice * item.exchangeRateUSD)).reduce((a, b) => a + b, 0);

    this.licenseImItemINRTotal = this.licenseDetail.LicenseItems.filter(item => item.itemType === 'Import').map(item => (item.itemQuantity * item.itemPrice * item.exchangeRateINR)).reduce((a, b) => a + b, 0);

    this.licenseImItemUSDTotal = this.licenseDetail.LicenseItems.filter(item => item.itemType === 'Import').map(item => (item.itemQuantity * item.itemPrice * item.exchangeRateUSD)).reduce((a, b) => a + b, 0);

    this.importItemBalanceINRTotal = this.licenseDetail.LicenseItems.filter(item => item.itemType === 'Import').map(item => (item.itemQuantity * item.itemPrice * item.exchangeRateINR) - item.itemImpPriceUtilization).reduce((a, b) => a + b, 0);

    this.importItemINRTotal = this.licenseDetail.LicenseImportItems.map(item => (item.itemPriceINR)).reduce((a, b) => a + b, 0);

    this.importItemUSDTotal = this.licenseDetail.LicenseImportItems.map(item => (item.itemPriceUSD)).reduce((a, b) => a + b, 0);

    this.importItemINRTotal = this.licenseDetail.LicenseImportItems.map(item => (item.itemPriceINR)).reduce((a, b) => a + b, 0);

    this.exportItemINRTotal = this.licenseDetail.LicenseExportItems.map(item => (item.itemPriceINR)).reduce((a, b) => a + b, 0);

    this.exportItemUSDTotal = this.licenseDetail.LicenseExportItems.map(item => (item.itemPriceUSD)).reduce((a, b) => a + b, 0);

    this.importItemQtyTotal = this.licenseDetail.LicenseImportItems.map(item => (item.itemQuantity)).reduce((a, b) => a + b, 0);

    this.exportItemQtyTotal = this.licenseDetail.LicenseExportItems.map(item => (item.itemQuantity)).reduce((a, b) => a + b, 0);

  }

  /* Fn to filter the licenses based on tab change */
  applyChange(): void {
    this.licenseItems = this.licenseDetail.LicenseItems.filter(i => i.itemType === this.itemType);
  }


  showImortFunction(): void {
    this.showImport = true;
    this.showExport = false;
  }

  showExportFunction(): void {
    this.showImport = false;
    this.showExport = true;
  }

  /**
   * Fn to open the right sheet for edit
   * @param index index of license item
   */
  editItem(index: number): void {
    this.licenseItemDetail = this.orgHelper.copyObject(this.licenseItems[index]);
    this.itemSheet = true;
  }

  /**
   * Fn to save the details of license items
   * @param form Angular form
   */
  saveItemDetails(form): void {
    if (form.valid) {
      this.shouldShowLoader = true;
      this.licenseItemDetail.licenseId = this.licenseDetail.licenseId;
      if (this.licenseItemDetail.licenseItemId) {
        this.licenseApi.updateLicenseItem(this.licenseItemDetail
        ).subscribe(item => {
          this.shouldShowLoader = false;
          const index = this.licenseDetail.LicenseItems.findIndex(i => i.licenseItemId === item.licenseItemId);
          this.licenseDetail.LicenseItems[index] = item;
          this.applyChange();
          SwalHelper.successTimerSwal('Item Updated!');
          $('#itemcollapse').collapse('show');
          this.itemSheet = false;
          this.licenseItemDetail = {};
          form.reset();
          this.calculateTotalAmount();
          this.licenseItemFormRef.form.markAsPristine();
        }, err => {
          this.shouldShowLoader = false;
          SwalHelper.showErrorSwal(err);
        });
      } else {
        this.licenseApi.createLicenseItem(this.licenseItemDetail
        ).subscribe(item => {
          this.shouldShowLoader = false;
          this.licenseDetail.LicenseItems.unshift(item);
          this.applyChange();
          SwalHelper.successTimerSwal('Item Added!');
          $('#itemcollapse').collapse('show');
          this.itemSheet = false;
          this.licenseItemDetail = {};
          form.reset();
          this.calculateTotalAmount();
          this.licenseItemFormRef.form.markAsPristine();

        }, err => {
          this.shouldShowLoader = false;
          SwalHelper.showErrorSwal(err);
        });
      }
    } else {
      return;
    }
  }

  /**
   * Fn to fetch the license details based on id in selector
   * @param licenseId License Id to get the details
   */
  changeLicenseDetail(licenseId: number): void {
    this.getlicenseDetail(licenseId);
  }

  /* Fn to close the license */
  closeLicense(): void {
    this.shouldShowLoader = true;
    this.licenseApi.closeLicense(
      this.licenseDetail.licenseId,
    ).subscribe(inv => {
      this.shouldShowLoader = false;
      this.licenseDetail.licenseStatus = 'CLOSED';
      SwalHelper.successTimerSwal('License closed!');
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /* Fn to check the license obligation to close the license*/
  checkObligation(): void {
    Swal({
      title: 'Close License',
      text: 'You want to able to revert this',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Close',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        this.shouldShowLoader = true;
        return new Promise((resolve, reject) => {
          this.licenseApi.checkLicenseObligation(
            this.licenseDetail.licenseId,
          ).subscribe(inv => {
            this.shouldShowLoader = false;
            resolve(inv);
          }, err => {
            reject(err);
          });
        });
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.value) {
        this.shouldShowLoader = false;
        this.closeLicense();
      }
    }).catch(err => {
      this.shouldShowLoader = false;
      Swal({
        title: 'Obligation not met',
        text: err.error ? err.error.message : err,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Continue & Close',
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          this.closeLicense();
        }
      });
    });
  }

  /**
   * Fn to save the SION Ratio of license items
   * @param form Angular form
   */
  saveSIONDetails(form): void {
    if (form.valid) {
      this.shouldShowLoader = true;
      this.licenseApi.updateSionRatio(this.licenseDetail
      ).subscribe(item => {
        this.shouldShowLoader = false;
        SwalHelper.successTimerSwal('SION Ratio Updated!');
        $('#sionModal').modal('hide');
        this.itemSheet = false;
      }, err => {
        this.shouldShowLoader = false;
        SwalHelper.showErrorSwal(err);
      });
    } else {
      return;
    }
  }

  /**
   * Fn to delete the license import item
   * @param index Index of an license import item
   */
  deleteImportItem(index: number): void {
    Swal({
      title: 'Delete Ledger',
      text: 'This cannot be undone',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        this.shouldShowLoader = true;
        return new Promise((resolve, reject) => {
          this.licenseApi.licenseImportItem(this.licenseDetail.LicenseImportItems[index].invId).subscribe(ok => {
            this.shouldShowLoader = false;
            this.licenseDetail.LicenseImportItems.splice(index, 1);
            resolve(ok);
          }, err => {
            reject(err);
          });
        });
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.value) {
        this.shouldShowLoader = false;
        SwalHelper.successTimerSwal('Ledger deleted!');
      }
    }).catch(err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /**
   * Fn to delete the license export item
   * @param index Index of an license export item
   */
  deleteExportItem(index: number): void {
    Swal({
      title: 'Delete Ledger',
      text: 'This cannot be undone',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        this.shouldShowLoader = true;
        return new Promise((resolve, reject) => {
          this.licenseApi.licenseExportItem(this.licenseDetail.LicenseExportItems[index].invId).subscribe(ok => {
            this.shouldShowLoader = false;
            this.licenseDetail.LicenseExportItems.splice(index, 1);
            resolve(ok);
          }, err => {
            reject(err);
          });
        });
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.value) {
        this.shouldShowLoader = false;
        SwalHelper.successTimerSwal('Ledger deleted!');
      }
    }).catch(err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }
}
