import { Component, OnDestroy, OnInit } from '@angular/core';
import { PurchaseOrder, OrganisationService, OrgOrdersService } from 'src/swagger';
import { SwalHelper } from 'src/app/swal';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { GlobalProfileService } from 'src/app/global-profile.service';
import { OrganisationHelperService } from '../organisation-helper.service';
import { of, Subject, Subscription } from 'rxjs';
import { catchError, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
declare var swal: any;
declare var $: any;

@Component({
  selector: 'app-purchase-order',
  templateUrl: './purchase-order.component.html',
  styleUrls: ['./purchase-order.component.scss']
})
export class PurchaseOrderComponent implements OnInit, OnDestroy {

  public allPurchaseOrders: PurchaseOrder[] = [];
  public allPOAmount: PurchaseOrder[] = [];
  public shouldShowLoader: boolean;

  public poEndDate: any;
  public poStartDate: any;
  public poStatus: string;

  public poCreationtype: string;
  public poFileName;

  public poFile: File;
  public purchaseOrderDetails: PurchaseOrder = {};

  dtOptions: DataTables.Settings = {};
  private userSubscription: Subscription;

  public cloneOrderId: number;
  public clonedOrderNo: string;

  public orderChange$: Subject<string> = new Subject();
  public listOrder$: Subject<PurchaseOrder[]> = new Subject();
  private orderChangeSubscription: Subscription;
  public orderLoading = false;

  public reminderOrder: PurchaseOrder = {};

  constructor(
    private orgOrderApi: OrgOrdersService,
    public router: Router,
    public orgApi: OrganisationService,
    public profileSync: GlobalProfileService,
    public orgHelper: OrganisationHelperService
  ) { }

  ngOnInit(): void {
    this.initPO();
    this.poStatus = 'All';

    this.dtOptions = {
      columnDefs: [
        { targets: [4, 5], width: '100px', type: 'date' },
        { targets: 7, type: 'num-fmt' },
        { orderable: false, targets: -1 },
        { width: '70px', targets: [-1, 0] }
      ]
    };
    this.userSubscription = this.profileSync.user$.subscribe(user => {
      if (user && user.OrganisationRole && user.OrganisationRole.permissions && user.OrganisationRole.permissions.PO.read) {
        this.fetchAllPurchaseOrder();
        this.fetchPOTotals();
      }
    });
    this.orderChangeSubscription = this.orderChange$.pipe(
      distinctUntilChanged(),
      tap(() => this.orderLoading = true),
      switchMap(query => this.orgApi.searchPosForClone(
        query,
      ).pipe(
        catchError(() => of([])), // empty list on error
        tap(() => {
          this.orderLoading = false;
        })
      ))
    ).subscribe(items => {
      this.listOrder$.next(items);
    });
  }

  ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
    if (this.orderChangeSubscription) {
      this.orderChangeSubscription.unsubscribe();
    }
  }

  /* Fn to init the purchase Order*/
  initPO(): void {
    this.poCreationtype = 'clonePurchaseOrder';
    this.purchaseOrderDetails.currency = this.purchaseOrderDetails.currency ? this.purchaseOrderDetails.currency : 'USD';
    this.purchaseOrderDetails.templateName = 'po1';
  }

  /* Fn to fetch all the purchase orders */
  fetchAllPurchaseOrder(): void {
    this.allPurchaseOrders = [];
    this.shouldShowLoader = true;
    this.orgOrderApi.listPo(
      this.poStartDate ? moment(this.poStartDate).format('YYYY-MM-DD') : null,
      this.poEndDate ? moment(this.poEndDate).format('YYYY-MM-DD') : null,
      this.poStatus === 'All' ? undefined : this.poStatus
    ).subscribe(pos => {
      this.shouldShowLoader = false;
      if (pos && pos.length > 0) {
        this.allPurchaseOrders = pos;
      } else {
        this.allPurchaseOrders = null;
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /* Fn to fetch the total order amount for total band*/
  fetchPOTotals(): void {
    this.shouldShowLoader = true;
    this.orgOrderApi.totalPoAmount(
    ).subscribe(pos => {
      this.shouldShowLoader = false;
      if (pos && pos.length > 0) {
        this.allPOAmount = pos;
      } else {
        this.allPOAmount = null;
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /* Fn to reset the filter */
  resetFilter(): void {
    this.poEndDate = undefined;
    this.poStartDate = undefined;
    this.poStatus = 'All';
    this.fetchAllPurchaseOrder();
  }

  /**
   * Fn to delete the order
   * @param index Index of the order to be deleted
   */
  deletePO(index: number): void {
    swal({
      title: 'Delete Purchase order',
      text: 'This cannot be undone',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        this.shouldShowLoader = true;
        return new Promise((resolve, reject) => {
          this.orgOrderApi.deletePoDetail(this.allPurchaseOrders[index].purchaseOrderId).subscribe(ok => {
            this.shouldShowLoader = false;
            this.allPurchaseOrders.splice(index, 1);
            resolve(ok);
          }, err => {
            reject(err);
          });
        });
      },
      allowOutsideClick: () => !swal.isLoading()
    }).then((result) => {
      if (result.value) {
        this.shouldShowLoader = false;
        SwalHelper.successTimerSwal('Order deleted!');
      }
    }).catch(err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  fileChange(event): void {
    this.poFileName = event.target.files[0].name;
    this.poFile = event.target.files[0];
  }

  /* Fn to  Add the new Order*/
  newOrder(): void {
    this.shouldShowLoader = true;
    if (this.poCreationtype === 'new') {
      this.purchaseOrderDetails.templateName = 'po1';
      this.purchaseOrderDetails.orgOrderDate = moment(this.purchaseOrderDetails.orgOrderDate).format('YYYY-MM-DD');
      this.orgOrderApi.createPo(
        this.purchaseOrderDetails
      ).subscribe(po => {
        if (po) {
          $('#purchaseOrderModal').modal('hide');
          this.shouldShowLoader = false;
          this.purchaseOrderDetails = po;
          this.router.navigate([`/organisation/purchase-orders/update-purchase-order/${this.purchaseOrderDetails.purchaseOrderId}`]);
        }
      }, err => {
        this.shouldShowLoader = false;
        SwalHelper.showErrorSwal(err);
      });
    } else if (this.poCreationtype === 'existing') {
      this.orgOrderApi.importPo(
        this.poFile,
        this.purchaseOrderDetails.currency
      ).subscribe(po => {
        if (po) {
          $('#purchaseOrderModal').modal('hide');
          this.shouldShowLoader = false;
          this.purchaseOrderDetails = po;
          this.router.navigate([`/organisation/purchase-orders/update-purchase-order/${this.purchaseOrderDetails.purchaseOrderId}`]);
        }
      }, err => {
        this.shouldShowLoader = false;
        SwalHelper.showErrorSwal(err);
      });
    } else {
      $('#purchaseOrderModal').modal('hide');
      this.shouldShowLoader = false;
      this.cloneOrder();
    }
  }

  /**
   * Fn to archive the order
   * @param index Index of the order to be archived
   */
  archiveOrder(index: number): void {
    swal({
      title: 'Archive Order',
      text: 'Unlink all the Linked documents first.',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Archive',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        this.shouldShowLoader = true;
        return new Promise((resolve, reject) => {
          this.orgOrderApi.archivePo(
            this.allPurchaseOrders[index].purchaseOrderId,
          ).subscribe(inv => {
            this.shouldShowLoader = false;
            this.allPurchaseOrders[index].status = 'ARCHIVED';
            this.allPurchaseOrders[index].orgOrderNo = this.allPurchaseOrders[index].orgOrderNo.concat(' (ARCHIVED)');
            resolve(inv);
          }, err => {
            reject(err);
          });
        });
      },
      allowOutsideClick: () => !swal.isLoading()
    }).then((result) => {
      if (result.value) {
        this.shouldShowLoader = false;
        SwalHelper.successTimerSwal('Order Archived!');
      }
    }).catch(err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /**
   * Fn to get the details of order to be cloned
   * @param index Index of the order to be cloned
   */
  clonePO(index: number): void {
    $('#cloneOrderModal').modal('show');
    this.cloneOrderId = this.allPurchaseOrders[index].purchaseOrderId;
  }

  /* Fn to clone the order*/
  cloneOrder(): void {
    swal({
      title: 'Clone Import Order',
      text: 'This cannot be undone',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Clone',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        this.shouldShowLoader = true;
        return new Promise((resolve, reject) => {
          this.orgOrderApi.clonePo(
            this.cloneOrderId,
            this.clonedOrderNo,
          ).subscribe(po => {
            this.shouldShowLoader = false;
            this.allPurchaseOrders.push(po);
            $('#cloneOrderModal').modal('hide');
            this.cloneOrderId = null;
            this.clonedOrderNo = null;
            this.router.navigate([`/organisation/purchase-orders/update-purchase-order/${po.purchaseOrderId}`]);
            resolve(po);
          }, err => {
            reject(err);
          });
        });
      },
      allowOutsideClick: () => !swal.isLoading()
    }).then((result) => {
      if (result.value) {
        this.shouldShowLoader = false;
        SwalHelper.successTimerSwal('Order Cloned!');
      }
    }).catch(err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /**
   * Fn to open the reminder modal with order details
   * @param index Index of order to create reminder
   */
  openReminder(index: number): void {
    $('#reminderModal').modal('show');
    this.reminderOrder = this.allPurchaseOrders[index];
  }
  /**
   * Fn to open the shipment link modal with order details
   * @param index Index of order to shipment link
   */
  openLinkShipment(index: number): void {
    localStorage.setItem('IS_LINK_SHIPMENT', 'true');
    this.router.navigate([`/organisation/purchase-orders/purchase-order-detail/${this.allPurchaseOrders[index].purchaseOrderId}`]);
  }
}
