import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencyConverter'
})
export class CurrencyConverterPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value > 0) {
      let currency = value;
      if (typeof value === 'string') {
        currency = Number(value);
      }
      currency = currency.toFixed(2);
      const res = value.toString().split('.');
      if (res.length === 1 || res[1].length < 3) {
        currency = parseFloat(currency).toFixed(2);
      }

      const val = currency.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return val;
    } else if (value === 0) {
      return value;
    } else {
      return '';
    }
  }
}
