import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { OrganisationRole, OrganisationService } from '../../../swagger';
import { SwalHelper } from 'src/app/swal';
import { CommonSupportService } from 'src/app/common-support.service';
import { GlobalProfileService } from 'src/app/global-profile.service';
import { Subscription } from 'rxjs';
declare var swal: any;

@Component({
  selector: 'app-organisation-roles',
  templateUrl: './organisation-roles.component.html',
  styleUrls: ['./organisation-roles.component.scss']
})
export class OrganisationRolesComponent implements OnInit, OnDestroy {

  public allRoles: OrganisationRole[] = [];
  public shouldShowLoader: boolean;

  public selectedRole: number;
  public rightSheet = false;
  dtOptions: DataTables.Settings = {};

  private userSubscription: Subscription;

  constructor(
    private orgApi: OrganisationService,
    public commonService: CommonSupportService,
    private renderer: Renderer2,
    public profileSync: GlobalProfileService
  ) {
    commonService.renderer = renderer;
  }

  ngOnInit(): void {
    this.userSubscription = this.profileSync.user$.subscribe(user => {
      if (user && user.OrganisationRole && user.OrganisationRole.permissions && user.OrganisationRole.permissions.OrganisationRole.read) {
        this.fetchAllRoles();
      }
    });
    this.dtOptions = {
      columnDefs: [
        { orderable: false, targets: -1, width: '70px' }
      ]
    };
  }

  ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  /* Fn to fetch all the organisation roles */
  fetchAllRoles(): void {
    this.shouldShowLoader = true;
    this.orgApi.allOrgRoles().subscribe(roles => {
      this.shouldShowLoader = false;
      if (roles && roles.length > 0) {
        this.allRoles = roles;
      } else {
        this.allRoles = null;
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /**
   * Fn to delete the role details
   * @param index Index of role to be deleted
   */
  deleteRole(index: number): void {
    swal({
      title: 'Delete Role',
      text: 'This cannot be undone',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        this.shouldShowLoader = true;
        return new Promise((resolve, reject) => {
          this.orgApi.deleteItemMaster(this.allRoles[index].orgRoleId).subscribe(ok => {
            this.shouldShowLoader = false;
            this.allRoles.splice(index, 1);
            resolve(ok);
          }, err => {
            reject(err);
          });
        });
      },
      allowOutsideClick: () => !swal.isLoading()
    }).then((result) => {
      if (result.value) {
        this.shouldShowLoader = false;
        SwalHelper.successTimerSwal('Role deleted!');
      }
    }).catch(err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /**
   * fn to copy the role to be updated & open right sheet
   * @param index Index of role to be update
   */
  editDetails(index: number): void {
    this.selectedRole = this.allRoles[index].orgRoleId;
    this.rightSheetOpen();
  }

  /* Fn to open the right sheet to add new role data */
  addNewRole(): void {
    this.selectedRole = undefined;
    this.rightSheetOpen();
  }

  /* Fn to open the right sheet for role details form */
  rightSheetOpen(): void {
    this.rightSheet = !this.rightSheet;
    this.renderer.addClass(document.body, 'modal-open');
    this.commonService.addBackdrop();
  }

  /* Fn to open the right sheet for role details form */
  rightSheetClose(): void {
    this.rightSheet = false;
    this.renderer.removeClass(document.body, 'modal-open');
    this.commonService.removeBackdrop();
    this.selectedRole = undefined;
  }

  /**
   * Fn to add the updated role details into array
   * @param role Updated role detail
   */
  receivedData(role: any): void {
    if (!this.allRoles) {
      this.allRoles = [];
    }
    const index = this.allRoles.findIndex(i => i.orgRoleId == role.orgRoleId);
    if (index >= 0) {
      this.allRoles[index] = role;
    } else {
      this.allRoles.push(role);
    }
    this.rightSheetClose();
  }

  /**
   * Fn to apply the regex to document name
   * @param docName Document name
   */
  docNameRegex(docName: any): void {
    let documentName = docName.replace(/([A-Z])/g, ' $1').trim();
    if (documentName == 'P O') {
      documentName = 'Import Order';
    } else if (documentName == 'S O') {
      documentName = 'Export Order';
    } else if (documentName == 'Invoice') {
      documentName = 'Invoice/Vendor Bill';
    } else if (documentName == 'Organisation Contact') {
      documentName = 'Companies & Business Contacts';
    }
    return documentName;
  }

  /**
   * Fn to check if document exist or not
   * @param docName document name to be checked
   * @param object role object
   */
  docsExist(docName: string, object: object): boolean {
    const ignoreDocs = ['COO', 'eBRC', 'PackingList', 'QAC', 'Reports', 'VoyageInsurance', 'OtherDocument'];
    return ignoreDocs.includes(docName) ? false : (Object.keys(object).every((k) => object[k] == true) ? true : false);
  }

}
