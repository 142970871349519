declare var Swal: any;

export class SwalHelper {
  public static showSavedToast(message: string): void {
    Swal.fire({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      type: 'success',
      title: message,
    });
  }
  public static showCenteredSavedToast(message: string): void {
    Swal.fire({
      toast: true,
      position: 'center',
      showConfirmButton: false,
      timer: 3000,
      type: 'success',
      title: message,
    });
  }

  public static successTimerSwal(title): void {
    Swal.fire({
      title,
      type: 'success',
      timer: 2000,
      showConfirmButton: false,
    });
  }

  public static showToast(type: string = 'success', message: string): void {
    Swal.fire({
      toast: true,
      position: 'center',
      showConfirmButton: false,
      timer: 3000,
      type,
      title: message,
    });
  }

  public static showErrorSwal(err): void {
    Swal.fire({
      title: 'Whoops',
      text: err.error ? err.error.message : err,
      type: 'error',
    });
  }

  public static showErrorToast(err): void {
    Swal.fire({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      type: 'error',
      title: 'Whoops, something went wrong',
    });
  }

  public static showWarningSwal(err): void {
    Swal.fire({
      title: 'Whoops',
      text: err.error ? err.error.message : err,
      type: 'warning',
    });
  }

  public static dismiss(): void {
    Swal.close();
  }

}
