import { Component, OnInit } from '@angular/core';
import { OrganisationRole, OrganisationService } from 'src/swagger';
import { SwalHelper } from 'src/app/swal';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-organisation-roles-details',
  templateUrl: './organisation-roles-details.component.html',
  styleUrls: ['./organisation-roles-details.component.scss']
})
export class OrganisationRolesDetailsComponent implements OnInit {

  public roleDetails: OrganisationRole = {};
  public shouldShowLoader: boolean;
  constructor(
    private orgApi: OrganisationService,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(param => {
      if (param && param.roleId) {
        this.getRoleDetails(param.roleId);
      }
    });
  }

  /**
   * Fn to get the details of role based on role id
   * @param roleId pk of Role object
   */
  getRoleDetails(roleId: number): void {
    this.shouldShowLoader = true;
    this.orgApi.getRole(roleId).subscribe(role => {
      this.shouldShowLoader = false;
      if (role) {
        this.roleDetails = role;
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }
}
