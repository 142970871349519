import { ShipmentDocumentsComponent } from './shipment-documents/shipment-documents.component';
import { BillOfEntryComponent } from './shipment-documents/bill-of-entry/bill-of-entry.component';
import { ShipmentExpiredComponent } from './shipment-expired/shipment-expired.component';
import { ShipmentDetailsComponent } from './shipment-details/shipment-details.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BillOfLadingComponent } from './shipment-documents/bill-of-lading/bill-of-lading.component';
import { ShippingBillComponent } from './shipment-documents/shipping-bill/shipping-bill.component';
import { ShippingBillUpdateComponent } from './shipment-documents/shipping-bill/shipping-bill-update/shipping-bill-update.component';
import { BillOfEntryUpdateComponent } from './shipment-documents/bill-of-entry/bill-of-entry-update/bill-of-entry-update.component';
import { BillOfLadingUpdateComponent} from './shipment-documents/bill-of-lading/bill-of-lading-update/bill-of-lading-update.component';
import { BillOfLadingDetailsComponent } from './shipment-documents/bill-of-lading/bill-of-lading-details/bill-of-lading-details.component';
import { ShippingBillDetailsComponent } from './shipment-documents/shipping-bill/shipping-bill-details/shipping-bill-details.component';
import { BillOfEntryDetailsComponent } from './shipment-documents/bill-of-entry/bill-of-entry-details/bill-of-entry-details.component';
import { ShipmentExpenseDetailsComponent } from './shipment-expenses/shipment-expense-details/shipment-expense-details.component';
// routes start with /shipment
const routes: Routes = [
  {
    path: 'uid/:uid',
    // canActivate: [ShipmentAccessGuard],
    component: ShipmentDetailsComponent
  },
  {
    path: 'access-expired',
    component: ShipmentExpiredComponent
  },
  {
    path: 'expenses-details/:shipmentId/:trackingId',
    component: ShipmentExpenseDetailsComponent
  },
  {
    path: 'shipment-documents',
    component: ShipmentDocumentsComponent,
    children: [
      {
        path: '',
        redirectTo: 'bill-of-lading',
        pathMatch: 'full',
      },
      {
        path: 'bill-of-lading',
        component: BillOfLadingComponent
      },
      {
        path: 'bill-of-entry',
        component: BillOfEntryComponent
      },
      {
        path: 'shipping-bill',
        component: ShippingBillComponent
      },
    ]
  },
  {
    path: 'shipment-documents/bill-of-lading/edit-bill-of-lading/:billOfLadingId',
    component: BillOfLadingUpdateComponent
  },
  {
    path: 'shipment-documents/bill-of-lading/bill-of-lading-details/:billOfLadingId',
    component: BillOfLadingDetailsComponent
  },
  {
    path: 'shipment-documents/shipping-bill/edit-shipping-bill/:shippingBillId',
    component: ShippingBillUpdateComponent
  },
  {
    path: 'shipment-documents/shipping-bill/shipping-bill-details/:shippingBillId',
    component: ShippingBillDetailsComponent
  },
  {
    path: 'shipment-documents/bill-of-entry/edit-bill-of-entry/:billOfEntryId',
    component: BillOfEntryUpdateComponent
  },
  {
    path: 'shipment-documents/bill-of-entry/bill-of-entry-details/:billOfEntryId',
    component: BillOfEntryDetailsComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ShipmentRoutingModule { }
