import { Component, AfterViewInit, OnDestroy, Renderer2 } from '@angular/core';
import { Subscription } from 'rxjs';
import { GlobalProfileService } from '../global-profile.service';
import { AuthService, Reminder } from 'src/swagger';
import { Router } from '@angular/router';
import { OrgNotification } from '../../swagger/model/orgNotification';
import { OrganisationService } from '../../swagger/api/organisation.service';
import { SwalHelper } from '../swal';
import * as moment from 'moment';
declare var $: any;
declare var swal: any;

@Component({
  selector: 'app-organisation',
  templateUrl: './organisation.component.html',
  styleUrls: ['./organisation.component.scss']
})
export class OrganisationComponent implements AfterViewInit, OnDestroy {
  private loginSubscription: Subscription;
  public toggleSupport = false;
  public navToggler = true;

  public notificationToggle = false;
  public reminderToggle = false;

  public isnotificationUnseen = true;
  public isNewReminder = true;

  public reminders: Reminder[] = [];
  public pastReminders: Reminder[] = [];
  public todayReminders: Reminder[] = [];
  public upcomingReminders: Reminder[] = [];
  public today = moment().format('YYYY-MM-DD').toString();

  public reminderInfo: Reminder = {};
  public shouldShowLoader = false;

  public notifications: OrgNotification[] = [];


  constructor(
    public profileSync: GlobalProfileService,
    public authApi: AuthService,
    public router: Router,
    public orgApi: OrganisationService,
    public renderer: Renderer2,
  ) { }

  ngAfterViewInit(): void {
    console.log('Organisation Component initiated');
    this.loginSubscription = this.profileSync.isUserLoggedIn$.subscribe(isUserLoggedIn => {
      if (isUserLoggedIn) {
        this.authApi.getProfile().subscribe(user => {
          if (user.OrgNotifications && user.OrgNotifications.length > 0) {
            this.isnotificationUnseen = true;
          } else {
            this.isnotificationUnseen = false;
          }
          this.profileSync.updateUserProfile(user);
        }, err => {
          this.router.navigate(['/login']);
        });
      } else {
        this.router.navigate(['/login']);
      }
    });
  }

  ngOnDestroy(): void {
    if (this.loginSubscription) {
      this.loginSubscription.unsubscribe();
    }
  }

  /* Fn to toggle the notification dropdown */
  toggleNotification(): void {
    this.notificationToggle = !this.notificationToggle;
    if (this.notificationToggle) {
      this.showNotification();
    }
  }

  addBackdrop(): void {
    const backdrop = this.renderer.createElement('div');
    const body = document.querySelector('.main');
    this.renderer.addClass(backdrop, 'no-backdrop');
    this.renderer.appendChild(body, backdrop);
    this.renderer.listen(backdrop, 'click', event => {
      this.hideNotification();
      this.hideReminder();
    });
  }

  removeBackdrop(): void{
    const body = document.querySelector('.main');
    const backdrop = document.querySelector('.no-backdrop');
    this.renderer.removeChild(body, backdrop);
  }

  /* Fn to open the notification & fetch all notifications */
  showNotification(): void {
    this.notificationToggle = true;
    this.reminderToggle = false;
    this.addBackdrop();
    this.orgApi.listNotifications().subscribe(nots => {
      if (nots && nots.length > 0) {
        this.notifications = nots;
      } else {
        this.notifications = [];
      }
    }, err => {
      SwalHelper.showErrorSwal(err);
    });
  }

  /* Fn to hide the notification */
  hideNotification(): void {
    this.notificationToggle = false;
    this.removeBackdrop();
  }

  /* Fn to toggle the reminder dropdown */
  toggleReminder(): void {
    this.reminderToggle = !this.reminderToggle;
    if (this.reminderToggle){
      this.showReminder();
    }
  }
  /* Fn to open the reminder & fetch all reminders */
  showReminder(): void {
    this.reminderToggle = true;
    this.addBackdrop();
    this.notificationToggle = false;
    this.orgApi.listReminders().subscribe(reminders => {
      if (reminders && reminders.length > 0) {
        this.reminders = reminders;
        this.todayReminders = reminders.filter(i => i.targetDate === this.today);
        this.pastReminders = reminders.filter(i => i.targetDate < this.today);
        this.upcomingReminders = reminders.filter(i => i.targetDate > this.today);
      } else {
        this.reminders = [];
        this.todayReminders = [];
        this.pastReminders = [];
        this.upcomingReminders = [];
      }
    }, err => {
      SwalHelper.showErrorSwal(err);
    });
  }

  /* Fn to hide the reminder */
  hideReminder(): void {
    this.reminderToggle = false;
    this.removeBackdrop();
  }

  logout(): void {
    this.profileSync.clearToken();
    this.router.navigate(['/login']);
  }

  // updatedData(user): void{
  //   this.profileSync.updateUserProfile(user);
  // }

  closeDropdown(): void {
    $('.nav-collapse').collapse('hide');
  }

  navToggle(): void {
    this.navToggler = !this.navToggler;
  }

  /* Fn to check if document has permission or not */
  hasDocPermission(object): boolean {
    return Object.keys(object).every((k) => object[k] == true) ? true : false;
  }

  /**
   * Fn to delete the notification
   * @param index Index of notification
   */
  deleteNotification(index: number): void {
    this.orgApi.cleanNotification(this.notifications[index].notificationId).subscribe(ok => {
      if (ok) {
        this.notifications.splice(index, 1);
      }
    }, err => {
      SwalHelper.showErrorSwal(err);
    });
  }

  /**
   * Fn to mark the notification as complete
   * @param index Index of notification
   */
  completeNotification(index: number): void {
    if (!this.notifications[index].isSeen) {
      this.orgApi.completeNotification(this.notifications[index].notificationId).subscribe(ok => {
        if (ok) {
          this.notifications[index].isSeen = true;
          if (this.notifications[index].invId) {
            this.router.navigate([`/organisation/invoices/invoice-details/${this.notifications[index].invId}`]);
          } else if (this.notifications[index].salesOrderId) {
            this.router.navigate([`/organisation/sales-orders/sales-order-detail/${this.notifications[index].salesOrderId}`]);
          } else if (this.notifications[index].purchaseOrderId) {
            this.router.navigate([`/organisation/purchase-orders/purchase-order-detail/${this.notifications[index].purchaseOrderId}`]);
          }
        }
      }, err => {
        SwalHelper.showErrorSwal(err);
      });
    } else {
      if (this.notifications[index].invId) {
        this.router.navigate([`/organisation/invoices/invoice-details/${this.notifications[index].invId}`]);
      } else if (this.notifications[index].salesOrderId) {
        this.router.navigate([`/organisation/sales-orders/sales-order-detail/${this.notifications[index].salesOrderId}`]);
      } else if (this.notifications[index].purchaseOrderId) {
        this.router.navigate([`/organisation/purchase-orders/purchase-order-detail/${this.notifications[index].purchaseOrderId}`]);
      }

    }
  }

  /* Fn to delete all the notifications */
  deleteNotifications(): void {
    this.orgApi.cleanAllNotifications().subscribe(ok => {
      if (ok) {
        this.notifications = [];
      }
    }, err => {
      SwalHelper.showErrorSwal(err);
    });
  }

  /**
   * Fn to delete the reminder
   * @param index Index of reminder
   */
  deleteReminder(index: number): void {
    swal({
      title: 'Delete Reminder',
      text: 'Once deleted you will not be able to see!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        this.shouldShowLoader = true;
        return new Promise((resolve, reject) => {
          this.orgApi.deleteReminder(
            this.reminders[index].reminderId,
          ).subscribe(inv => {
            this.shouldShowLoader = false;
            this.reminders.splice(index, 1);
            resolve(inv);
          }, err => {
            reject(err);
          });
        });
      },
      allowOutsideClick: () => !swal.isLoading()
    }).then((result) => {
      if (result.value) {
        this.shouldShowLoader = false;
        SwalHelper.successTimerSwal('Reminder Deleted');
      }
    }).catch(err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /**
   * Fn to mark the reminder as complete
   * @param index Index of reminder
   */
  completeReminder(index: number): void {
    swal({
      title: 'Complete reminder',
      text: '',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Complete',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        this.shouldShowLoader = true;
        return new Promise((resolve, reject) => {
          this.orgApi.completeReminder(
            this.reminders[index].reminderId,
          ).subscribe(inv => {
            this.shouldShowLoader = false;
            this.reminders[index].isSeen = true;
            resolve(inv);
          }, err => {
            reject(err);
          });
        });
      },
      allowOutsideClick: () => !swal.isLoading()
    }).then((result) => {
      if (result.value) {
        this.shouldShowLoader = false;
        SwalHelper.successTimerSwal('Reminder Marked completed!');
      }
    }).catch(err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /**
   * Fn to open reminder modal for update
   * @param index index of reminder
   */
  updateReminder(index: number): void {
    $('#reminderMainModal').modal('show');
    this.reminderInfo = {};
    this.reminderInfo = this.reminders[index];
  }
}

