import { Component, OnInit } from '@angular/core';
import { OpenService } from 'src/swagger';
import { SwalHelper } from 'src/app/swal';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  public email: string;
  public shouldShowLoader: boolean;

  constructor(
    private openApi: OpenService,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  /* Fn to send the reset password link */
  requestForgetPwdLink(): void {
    this.shouldShowLoader = true;
    this.openApi.forgotPassword(this.email).subscribe(ok => {
      this.shouldShowLoader = false;
      if (ok) {
        SwalHelper.successTimerSwal('Password reset Link shared to your email id');
        this.router.navigate(['../login']);
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

}
