/**
 * Nuphi trade
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2.0.0
 * Contact: shraddha@blackcurrantapps.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { DocumentAttachment } from '../model/documentAttachment';
import { ExcelUploadErrors } from '../model/excelUploadErrors';
import { ExcelUploadResult } from '../model/excelUploadResult';
import { Invoice } from '../model/invoice';
import { OrderInvoiceMapping } from '../model/orderInvoiceMapping';
import { PurchaseOrder } from '../model/purchaseOrder';
import { SalesOrder } from '../model/salesOrder';
import { Shipment } from '../model/shipment';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable({
  providedIn: 'root'
})
export class OrgOrdersService {

    protected basePath = 'http://localhost:3000';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Archive the PUrchase Order Manually
     * 
     * @param purchaseOrderId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public archivePo(purchaseOrderId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public archivePo(purchaseOrderId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public archivePo(purchaseOrderId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public archivePo(purchaseOrderId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (purchaseOrderId === null || purchaseOrderId === undefined) {
            throw new Error('Required parameter purchaseOrderId was null or undefined when calling archivePo.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (purchaseOrderId !== undefined && purchaseOrderId !== null) {
            queryParameters = queryParameters.set('purchaseOrderId', <any>purchaseOrderId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.put<any>(`${this.basePath}/organisation/orders/archive_po`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Archive the Sales Order Manually
     * 
     * @param salesOrderId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public archiveSo(salesOrderId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public archiveSo(salesOrderId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public archiveSo(salesOrderId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public archiveSo(salesOrderId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (salesOrderId === null || salesOrderId === undefined) {
            throw new Error('Required parameter salesOrderId was null or undefined when calling archiveSo.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (salesOrderId !== undefined && salesOrderId !== null) {
            queryParameters = queryParameters.set('salesOrderId', <any>salesOrderId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.put<any>(`${this.basePath}/organisation/orders/archive_so`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Clone PO
     * Clone the existing Po
     * @param purchaseOrderId 
     * @param orgOrderNo 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clonePo(purchaseOrderId: number, orgOrderNo: string, observe?: 'body', reportProgress?: boolean): Observable<PurchaseOrder>;
    public clonePo(purchaseOrderId: number, orgOrderNo: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PurchaseOrder>>;
    public clonePo(purchaseOrderId: number, orgOrderNo: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PurchaseOrder>>;
    public clonePo(purchaseOrderId: number, orgOrderNo: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (purchaseOrderId === null || purchaseOrderId === undefined) {
            throw new Error('Required parameter purchaseOrderId was null or undefined when calling clonePo.');
        }

        if (orgOrderNo === null || orgOrderNo === undefined) {
            throw new Error('Required parameter orgOrderNo was null or undefined when calling clonePo.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (purchaseOrderId !== undefined && purchaseOrderId !== null) {
            queryParameters = queryParameters.set('purchaseOrderId', <any>purchaseOrderId);
        }
        if (orgOrderNo !== undefined && orgOrderNo !== null) {
            queryParameters = queryParameters.set('orgOrderNo', <any>orgOrderNo);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<PurchaseOrder>(`${this.basePath}/organisation/orders/clone_po`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Clone So
     * Clone the existing So
     * @param salesOrderId 
     * @param orgOrderNo 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public cloneSo(salesOrderId: number, orgOrderNo: string, observe?: 'body', reportProgress?: boolean): Observable<SalesOrder>;
    public cloneSo(salesOrderId: number, orgOrderNo: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SalesOrder>>;
    public cloneSo(salesOrderId: number, orgOrderNo: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SalesOrder>>;
    public cloneSo(salesOrderId: number, orgOrderNo: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (salesOrderId === null || salesOrderId === undefined) {
            throw new Error('Required parameter salesOrderId was null or undefined when calling cloneSo.');
        }

        if (orgOrderNo === null || orgOrderNo === undefined) {
            throw new Error('Required parameter orgOrderNo was null or undefined when calling cloneSo.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (salesOrderId !== undefined && salesOrderId !== null) {
            queryParameters = queryParameters.set('salesOrderId', <any>salesOrderId);
        }
        if (orgOrderNo !== undefined && orgOrderNo !== null) {
            queryParameters = queryParameters.set('orgOrderNo', <any>orgOrderNo);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<SalesOrder>(`${this.basePath}/organisation/orders/clone_so`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * create po to invoice
     * Convert Purchase order to organisation invoice
     * @param purchaseOrderId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public convertPoToInvoice(purchaseOrderId: number, observe?: 'body', reportProgress?: boolean): Observable<Invoice>;
    public convertPoToInvoice(purchaseOrderId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Invoice>>;
    public convertPoToInvoice(purchaseOrderId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Invoice>>;
    public convertPoToInvoice(purchaseOrderId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (purchaseOrderId === null || purchaseOrderId === undefined) {
            throw new Error('Required parameter purchaseOrderId was null or undefined when calling convertPoToInvoice.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (purchaseOrderId !== undefined && purchaseOrderId !== null) {
            queryParameters = queryParameters.set('purchaseOrderId', <any>purchaseOrderId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<Invoice>(`${this.basePath}/organisation/orders/convert_po_to_invoice`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * create po to so
     * Convert purchase order to organisation sales order
     * @param purchaseOrderId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public convertPoToSo(purchaseOrderId: number, observe?: 'body', reportProgress?: boolean): Observable<SalesOrder>;
    public convertPoToSo(purchaseOrderId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SalesOrder>>;
    public convertPoToSo(purchaseOrderId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SalesOrder>>;
    public convertPoToSo(purchaseOrderId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (purchaseOrderId === null || purchaseOrderId === undefined) {
            throw new Error('Required parameter purchaseOrderId was null or undefined when calling convertPoToSo.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (purchaseOrderId !== undefined && purchaseOrderId !== null) {
            queryParameters = queryParameters.set('purchaseOrderId', <any>purchaseOrderId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<SalesOrder>(`${this.basePath}/organisation/orders/convert_po_to_so`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * create so to invoice
     * Convert sales order to organisation invoice
     * @param salesOrderId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public convertSoToInvoice(salesOrderId: number, observe?: 'body', reportProgress?: boolean): Observable<Invoice>;
    public convertSoToInvoice(salesOrderId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Invoice>>;
    public convertSoToInvoice(salesOrderId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Invoice>>;
    public convertSoToInvoice(salesOrderId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (salesOrderId === null || salesOrderId === undefined) {
            throw new Error('Required parameter salesOrderId was null or undefined when calling convertSoToInvoice.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (salesOrderId !== undefined && salesOrderId !== null) {
            queryParameters = queryParameters.set('salesOrderId', <any>salesOrderId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<Invoice>(`${this.basePath}/organisation/orders/convert_so_to_invoice`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update invoice specific details
     * Create the details of organisation purchase order
     * @param PurchaseOrder 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createPo(PurchaseOrder: PurchaseOrder, observe?: 'body', reportProgress?: boolean): Observable<PurchaseOrder>;
    public createPo(PurchaseOrder: PurchaseOrder, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PurchaseOrder>>;
    public createPo(PurchaseOrder: PurchaseOrder, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PurchaseOrder>>;
    public createPo(PurchaseOrder: PurchaseOrder, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (PurchaseOrder === null || PurchaseOrder === undefined) {
            throw new Error('Required parameter PurchaseOrder was null or undefined when calling createPo.');
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<PurchaseOrder>(`${this.basePath}/organisation/orders/purchase_order`,
            PurchaseOrder,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * create so specific details
     * Create the details of organisation sales order
     * @param SalesOrder 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createSo(SalesOrder: SalesOrder, observe?: 'body', reportProgress?: boolean): Observable<SalesOrder>;
    public createSo(SalesOrder: SalesOrder, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SalesOrder>>;
    public createSo(SalesOrder: SalesOrder, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SalesOrder>>;
    public createSo(SalesOrder: SalesOrder, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (SalesOrder === null || SalesOrder === undefined) {
            throw new Error('Required parameter SalesOrder was null or undefined when calling createSo.');
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<SalesOrder>(`${this.basePath}/organisation/orders/sales_order`,
            SalesOrder,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete an attachment from the order either purchase or sales
     * 
     * @param docAttId 
     * @param purchaseOrderId 
     * @param salesOrderId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteOrderAttachment(docAttId: number, purchaseOrderId?: number, salesOrderId?: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteOrderAttachment(docAttId: number, purchaseOrderId?: number, salesOrderId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteOrderAttachment(docAttId: number, purchaseOrderId?: number, salesOrderId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteOrderAttachment(docAttId: number, purchaseOrderId?: number, salesOrderId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (docAttId === null || docAttId === undefined) {
            throw new Error('Required parameter docAttId was null or undefined when calling deleteOrderAttachment.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (docAttId !== undefined && docAttId !== null) {
            queryParameters = queryParameters.set('docAttId', <any>docAttId);
        }
        if (purchaseOrderId !== undefined && purchaseOrderId !== null) {
            queryParameters = queryParameters.set('purchaseOrderId', <any>purchaseOrderId);
        }
        if (salesOrderId !== undefined && salesOrderId !== null) {
            queryParameters = queryParameters.set('salesOrderId', <any>salesOrderId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/organisation/orders/delete_order_attachment`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete PO specific details
     * Delete the details of organisation purchase order.This will be the soft delete
     * @param purchaseOrderId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deletePoDetail(purchaseOrderId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deletePoDetail(purchaseOrderId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deletePoDetail(purchaseOrderId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deletePoDetail(purchaseOrderId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (purchaseOrderId === null || purchaseOrderId === undefined) {
            throw new Error('Required parameter purchaseOrderId was null or undefined when calling deletePoDetail.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (purchaseOrderId !== undefined && purchaseOrderId !== null) {
            queryParameters = queryParameters.set('purchaseOrderId', <any>purchaseOrderId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/organisation/orders/purchase_order`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete SO specific details
     * Delete the details of organisation sales order.This will be the soft delete
     * @param salesOrderId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteSoDetail(salesOrderId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteSoDetail(salesOrderId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteSoDetail(salesOrderId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteSoDetail(salesOrderId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (salesOrderId === null || salesOrderId === undefined) {
            throw new Error('Required parameter salesOrderId was null or undefined when calling deleteSoDetail.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (salesOrderId !== undefined && salesOrderId !== null) {
            queryParameters = queryParameters.set('salesOrderId', <any>salesOrderId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/organisation/orders/sales_order`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update invoice specific details
     * get the details of organisation purchase order
     * @param purchaseOrderId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPo(purchaseOrderId: number, observe?: 'body', reportProgress?: boolean): Observable<PurchaseOrder>;
    public getPo(purchaseOrderId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PurchaseOrder>>;
    public getPo(purchaseOrderId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PurchaseOrder>>;
    public getPo(purchaseOrderId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (purchaseOrderId === null || purchaseOrderId === undefined) {
            throw new Error('Required parameter purchaseOrderId was null or undefined when calling getPo.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (purchaseOrderId !== undefined && purchaseOrderId !== null) {
            queryParameters = queryParameters.set('purchaseOrderId', <any>purchaseOrderId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<PurchaseOrder>(`${this.basePath}/organisation/orders/purchase_order`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get so specific details
     * get the details of organisation sales order
     * @param salesOrderId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSo(salesOrderId: number, observe?: 'body', reportProgress?: boolean): Observable<SalesOrder>;
    public getSo(salesOrderId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SalesOrder>>;
    public getSo(salesOrderId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SalesOrder>>;
    public getSo(salesOrderId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (salesOrderId === null || salesOrderId === undefined) {
            throw new Error('Required parameter salesOrderId was null or undefined when calling getSo.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (salesOrderId !== undefined && salesOrderId !== null) {
            queryParameters = queryParameters.set('salesOrderId', <any>salesOrderId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<SalesOrder>(`${this.basePath}/organisation/orders/sales_order`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Import Purchase order for OCR
     * 
     * @param attachment1 
     * @param currency 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public importPo(attachment1: Blob, currency: string, observe?: 'body', reportProgress?: boolean): Observable<PurchaseOrder>;
    public importPo(attachment1: Blob, currency: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PurchaseOrder>>;
    public importPo(attachment1: Blob, currency: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PurchaseOrder>>;
    public importPo(attachment1: Blob, currency: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (attachment1 === null || attachment1 === undefined) {
            throw new Error('Required parameter attachment1 was null or undefined when calling importPo.');
        }

        if (currency === null || currency === undefined) {
            throw new Error('Required parameter currency was null or undefined when calling importPo.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (currency !== undefined && currency !== null) {
            queryParameters = queryParameters.set('currency', <any>currency);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (attachment1 !== undefined) {
            formParams.append('attachment1', <any>attachment1);
        }

        return this.httpClient.post<PurchaseOrder>(`${this.basePath}/organisation/orders/import_po`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Import sales order for OCR
     * 
     * @param attachment1 
     * @param currency 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public importSo(attachment1: Blob, currency: string, observe?: 'body', reportProgress?: boolean): Observable<SalesOrder>;
    public importSo(attachment1: Blob, currency: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SalesOrder>>;
    public importSo(attachment1: Blob, currency: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SalesOrder>>;
    public importSo(attachment1: Blob, currency: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (attachment1 === null || attachment1 === undefined) {
            throw new Error('Required parameter attachment1 was null or undefined when calling importSo.');
        }

        if (currency === null || currency === undefined) {
            throw new Error('Required parameter currency was null or undefined when calling importSo.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (currency !== undefined && currency !== null) {
            queryParameters = queryParameters.set('currency', <any>currency);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (attachment1 !== undefined) {
            formParams.append('attachment1', <any>attachment1);
        }

        return this.httpClient.post<SalesOrder>(`${this.basePath}/organisation/orders/import_so`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Link po to invoice
     * 
     * @param invId 
     * @param purchaseOrderId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public linkPoInvoice(invId: number, purchaseOrderId: number, observe?: 'body', reportProgress?: boolean): Observable<OrderInvoiceMapping>;
    public linkPoInvoice(invId: number, purchaseOrderId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrderInvoiceMapping>>;
    public linkPoInvoice(invId: number, purchaseOrderId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrderInvoiceMapping>>;
    public linkPoInvoice(invId: number, purchaseOrderId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (invId === null || invId === undefined) {
            throw new Error('Required parameter invId was null or undefined when calling linkPoInvoice.');
        }

        if (purchaseOrderId === null || purchaseOrderId === undefined) {
            throw new Error('Required parameter purchaseOrderId was null or undefined when calling linkPoInvoice.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (invId !== undefined && invId !== null) {
            queryParameters = queryParameters.set('invId', <any>invId);
        }
        if (purchaseOrderId !== undefined && purchaseOrderId !== null) {
            queryParameters = queryParameters.set('purchaseOrderId', <any>purchaseOrderId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<OrderInvoiceMapping>(`${this.basePath}/organisation/orders/link_po_invoice`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Link so to invoice
     * 
     * @param invId 
     * @param salesOrderId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public linkSoInvoice(invId: number, salesOrderId: number, observe?: 'body', reportProgress?: boolean): Observable<OrderInvoiceMapping>;
    public linkSoInvoice(invId: number, salesOrderId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrderInvoiceMapping>>;
    public linkSoInvoice(invId: number, salesOrderId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrderInvoiceMapping>>;
    public linkSoInvoice(invId: number, salesOrderId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (invId === null || invId === undefined) {
            throw new Error('Required parameter invId was null or undefined when calling linkSoInvoice.');
        }

        if (salesOrderId === null || salesOrderId === undefined) {
            throw new Error('Required parameter salesOrderId was null or undefined when calling linkSoInvoice.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (invId !== undefined && invId !== null) {
            queryParameters = queryParameters.set('invId', <any>invId);
        }
        if (salesOrderId !== undefined && salesOrderId !== null) {
            queryParameters = queryParameters.set('salesOrderId', <any>salesOrderId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<OrderInvoiceMapping>(`${this.basePath}/organisation/orders/link_so_invoice`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all purchase order
     * Get all the Purchase order of an organisation/ Contact
     * @param poStartDate 
     * @param poEndDate 
     * @param status 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listPo(poStartDate?: string, poEndDate?: string, status?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<PurchaseOrder>>;
    public listPo(poStartDate?: string, poEndDate?: string, status?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PurchaseOrder>>>;
    public listPo(poStartDate?: string, poEndDate?: string, status?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PurchaseOrder>>>;
    public listPo(poStartDate?: string, poEndDate?: string, status?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (poStartDate !== undefined && poStartDate !== null) {
            queryParameters = queryParameters.set('poStartDate', <any>poStartDate);
        }
        if (poEndDate !== undefined && poEndDate !== null) {
            queryParameters = queryParameters.set('poEndDate', <any>poEndDate);
        }
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', <any>status);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<PurchaseOrder>>(`${this.basePath}/organisation/orders/list_po`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all sales order
     * Get all the Sales order of an organisation/ Contact
     * @param soStartDate 
     * @param soEndDate 
     * @param status 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listSo(soStartDate?: string, soEndDate?: string, status?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<SalesOrder>>;
    public listSo(soStartDate?: string, soEndDate?: string, status?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SalesOrder>>>;
    public listSo(soStartDate?: string, soEndDate?: string, status?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SalesOrder>>>;
    public listSo(soStartDate?: string, soEndDate?: string, status?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (soStartDate !== undefined && soStartDate !== null) {
            queryParameters = queryParameters.set('soStartDate', <any>soStartDate);
        }
        if (soEndDate !== undefined && soEndDate !== null) {
            queryParameters = queryParameters.set('soEndDate', <any>soEndDate);
        }
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', <any>status);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<SalesOrder>>(`${this.basePath}/organisation/orders/list_so`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get po specific details with status history
     * get the details of organisation purchase order along with status history
     * @param purchaseOrderId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public purchaseOrderStatusHistory(purchaseOrderId: number, observe?: 'body', reportProgress?: boolean): Observable<PurchaseOrder>;
    public purchaseOrderStatusHistory(purchaseOrderId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PurchaseOrder>>;
    public purchaseOrderStatusHistory(purchaseOrderId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PurchaseOrder>>;
    public purchaseOrderStatusHistory(purchaseOrderId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (purchaseOrderId === null || purchaseOrderId === undefined) {
            throw new Error('Required parameter purchaseOrderId was null or undefined when calling purchaseOrderStatusHistory.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (purchaseOrderId !== undefined && purchaseOrderId !== null) {
            queryParameters = queryParameters.set('purchaseOrderId', <any>purchaseOrderId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<PurchaseOrder>(`${this.basePath}/organisation/orders/purchase_order_status_history`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get so specific details with status history
     * get the details of organisation sales order with the status history
     * @param salesOrderId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public salesOrderStatusHistory(salesOrderId: number, observe?: 'body', reportProgress?: boolean): Observable<SalesOrder>;
    public salesOrderStatusHistory(salesOrderId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SalesOrder>>;
    public salesOrderStatusHistory(salesOrderId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SalesOrder>>;
    public salesOrderStatusHistory(salesOrderId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (salesOrderId === null || salesOrderId === undefined) {
            throw new Error('Required parameter salesOrderId was null or undefined when calling salesOrderStatusHistory.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (salesOrderId !== undefined && salesOrderId !== null) {
            queryParameters = queryParameters.set('salesOrderId', <any>salesOrderId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<SalesOrder>(`${this.basePath}/organisation/orders/sales_order_status_history`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Search vendors
     * 
     * @param type 
     * @param query 
     * @param exclude_ids comman seperated invoice nos
     * @param limit 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchOrderShipments(type: string, query?: string, exclude_ids?: string, limit?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<Shipment>>;
    public searchOrderShipments(type: string, query?: string, exclude_ids?: string, limit?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Shipment>>>;
    public searchOrderShipments(type: string, query?: string, exclude_ids?: string, limit?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Shipment>>>;
    public searchOrderShipments(type: string, query?: string, exclude_ids?: string, limit?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling searchOrderShipments.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (query !== undefined && query !== null) {
            queryParameters = queryParameters.set('query', <any>query);
        }
        if (type !== undefined && type !== null) {
            queryParameters = queryParameters.set('type', <any>type);
        }
        if (exclude_ids !== undefined && exclude_ids !== null) {
            queryParameters = queryParameters.set('exclude_ids', <any>exclude_ids);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<Shipment>>(`${this.basePath}/organisation/orders/search_shipments`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get po specific details with status history
     * Get total amount group by currency
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public totalPoAmount(observe?: 'body', reportProgress?: boolean): Observable<Array<PurchaseOrder>>;
    public totalPoAmount(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PurchaseOrder>>>;
    public totalPoAmount(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PurchaseOrder>>>;
    public totalPoAmount(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<PurchaseOrder>>(`${this.basePath}/organisation/orders/total_po_amount`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get so total
     * Get total amount group by currency
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public totalSoAmount(observe?: 'body', reportProgress?: boolean): Observable<Array<SalesOrder>>;
    public totalSoAmount(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SalesOrder>>>;
    public totalSoAmount(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SalesOrder>>>;
    public totalSoAmount(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<SalesOrder>>(`${this.basePath}/organisation/orders/total_so_amount`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * UnLink so to invoice
     * 
     * @param ordInvMapId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public unlinkSoInvoice(ordInvMapId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public unlinkSoInvoice(ordInvMapId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public unlinkSoInvoice(ordInvMapId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public unlinkSoInvoice(ordInvMapId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (ordInvMapId === null || ordInvMapId === undefined) {
            throw new Error('Required parameter ordInvMapId was null or undefined when calling unlinkSoInvoice.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (ordInvMapId !== undefined && ordInvMapId !== null) {
            queryParameters = queryParameters.set('ordInvMapId', <any>ordInvMapId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/organisation/orders/unlink_so_invoice`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update invoice specific details
     * Update the details of organisation purchase order
     * @param PurchaseOrder 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updatePo(PurchaseOrder: PurchaseOrder, observe?: 'body', reportProgress?: boolean): Observable<PurchaseOrder>;
    public updatePo(PurchaseOrder: PurchaseOrder, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PurchaseOrder>>;
    public updatePo(PurchaseOrder: PurchaseOrder, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PurchaseOrder>>;
    public updatePo(PurchaseOrder: PurchaseOrder, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (PurchaseOrder === null || PurchaseOrder === undefined) {
            throw new Error('Required parameter PurchaseOrder was null or undefined when calling updatePo.');
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<PurchaseOrder>(`${this.basePath}/organisation/orders/purchase_order`,
            PurchaseOrder,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update the details of the PO advance payment
     * 
     * @param PurchaseOrder 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updatePoAdvanceDetails(PurchaseOrder: PurchaseOrder, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updatePoAdvanceDetails(PurchaseOrder: PurchaseOrder, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updatePoAdvanceDetails(PurchaseOrder: PurchaseOrder, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updatePoAdvanceDetails(PurchaseOrder: PurchaseOrder, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (PurchaseOrder === null || PurchaseOrder === undefined) {
            throw new Error('Required parameter PurchaseOrder was null or undefined when calling updatePoAdvanceDetails.');
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/organisation/orders/update_po_advance_details`,
            PurchaseOrder,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update the staus of the PO Manually
     * 
     * @param purchaseOrderId 
     * @param status 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updatePoStatus(purchaseOrderId: number, status: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updatePoStatus(purchaseOrderId: number, status: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updatePoStatus(purchaseOrderId: number, status: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updatePoStatus(purchaseOrderId: number, status: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (purchaseOrderId === null || purchaseOrderId === undefined) {
            throw new Error('Required parameter purchaseOrderId was null or undefined when calling updatePoStatus.');
        }

        if (status === null || status === undefined) {
            throw new Error('Required parameter status was null or undefined when calling updatePoStatus.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (purchaseOrderId !== undefined && purchaseOrderId !== null) {
            queryParameters = queryParameters.set('purchaseOrderId', <any>purchaseOrderId);
        }
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', <any>status);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.put<any>(`${this.basePath}/organisation/orders/update_po_status`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get so total
     * Change the po template
     * @param purchaseOrderId 
     * @param templateName 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updatePoTemplate(purchaseOrderId: number, templateName: string, observe?: 'body', reportProgress?: boolean): Observable<PurchaseOrder>;
    public updatePoTemplate(purchaseOrderId: number, templateName: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PurchaseOrder>>;
    public updatePoTemplate(purchaseOrderId: number, templateName: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PurchaseOrder>>;
    public updatePoTemplate(purchaseOrderId: number, templateName: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (purchaseOrderId === null || purchaseOrderId === undefined) {
            throw new Error('Required parameter purchaseOrderId was null or undefined when calling updatePoTemplate.');
        }

        if (templateName === null || templateName === undefined) {
            throw new Error('Required parameter templateName was null or undefined when calling updatePoTemplate.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (purchaseOrderId !== undefined && purchaseOrderId !== null) {
            queryParameters = queryParameters.set('purchaseOrderId', <any>purchaseOrderId);
        }
        if (templateName !== undefined && templateName !== null) {
            queryParameters = queryParameters.set('templateName', <any>templateName);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<PurchaseOrder>(`${this.basePath}/organisation/orders/update_po_template`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update so specific details
     * Update the details of organisation sales order
     * @param SalesOrder 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateSo(SalesOrder: SalesOrder, observe?: 'body', reportProgress?: boolean): Observable<SalesOrder>;
    public updateSo(SalesOrder: SalesOrder, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SalesOrder>>;
    public updateSo(SalesOrder: SalesOrder, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SalesOrder>>;
    public updateSo(SalesOrder: SalesOrder, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (SalesOrder === null || SalesOrder === undefined) {
            throw new Error('Required parameter SalesOrder was null or undefined when calling updateSo.');
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<SalesOrder>(`${this.basePath}/organisation/orders/sales_order`,
            SalesOrder,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update the details of the SO advance payment
     * 
     * @param SalesOrder 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateSoAdvanceDetails(SalesOrder: SalesOrder, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateSoAdvanceDetails(SalesOrder: SalesOrder, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateSoAdvanceDetails(SalesOrder: SalesOrder, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateSoAdvanceDetails(SalesOrder: SalesOrder, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (SalesOrder === null || SalesOrder === undefined) {
            throw new Error('Required parameter SalesOrder was null or undefined when calling updateSoAdvanceDetails.');
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/organisation/orders/update_so_advance_details`,
            SalesOrder,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update the so status manually
     * 
     * @param salesOrderId 
     * @param status 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateSoStatus(salesOrderId: number, status: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateSoStatus(salesOrderId: number, status: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateSoStatus(salesOrderId: number, status: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateSoStatus(salesOrderId: number, status: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (salesOrderId === null || salesOrderId === undefined) {
            throw new Error('Required parameter salesOrderId was null or undefined when calling updateSoStatus.');
        }

        if (status === null || status === undefined) {
            throw new Error('Required parameter status was null or undefined when calling updateSoStatus.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (salesOrderId !== undefined && salesOrderId !== null) {
            queryParameters = queryParameters.set('salesOrderId', <any>salesOrderId);
        }
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', <any>status);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.put<any>(`${this.basePath}/organisation/orders/update_so_status`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get so total
     * Change the so template
     * @param salesOrderId 
     * @param templateName 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateSoTemplate(salesOrderId: number, templateName: string, observe?: 'body', reportProgress?: boolean): Observable<SalesOrder>;
    public updateSoTemplate(salesOrderId: number, templateName: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SalesOrder>>;
    public updateSoTemplate(salesOrderId: number, templateName: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SalesOrder>>;
    public updateSoTemplate(salesOrderId: number, templateName: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (salesOrderId === null || salesOrderId === undefined) {
            throw new Error('Required parameter salesOrderId was null or undefined when calling updateSoTemplate.');
        }

        if (templateName === null || templateName === undefined) {
            throw new Error('Required parameter templateName was null or undefined when calling updateSoTemplate.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (salesOrderId !== undefined && salesOrderId !== null) {
            queryParameters = queryParameters.set('salesOrderId', <any>salesOrderId);
        }
        if (templateName !== undefined && templateName !== null) {
            queryParameters = queryParameters.set('templateName', <any>templateName);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<SalesOrder>(`${this.basePath}/organisation/orders/update_so_template`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * upload an attachment to the order either purchase or sales
     * 
     * @param file1 
     * @param purchaseOrderId 
     * @param salesOrderId 
     * @param file2 
     * @param file3 
     * @param file4 
     * @param file5 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public uploadOrderAttachment(file1: Blob, purchaseOrderId?: number, salesOrderId?: number, file2?: Blob, file3?: Blob, file4?: Blob, file5?: Blob, observe?: 'body', reportProgress?: boolean): Observable<Array<DocumentAttachment>>;
    public uploadOrderAttachment(file1: Blob, purchaseOrderId?: number, salesOrderId?: number, file2?: Blob, file3?: Blob, file4?: Blob, file5?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DocumentAttachment>>>;
    public uploadOrderAttachment(file1: Blob, purchaseOrderId?: number, salesOrderId?: number, file2?: Blob, file3?: Blob, file4?: Blob, file5?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DocumentAttachment>>>;
    public uploadOrderAttachment(file1: Blob, purchaseOrderId?: number, salesOrderId?: number, file2?: Blob, file3?: Blob, file4?: Blob, file5?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (file1 === null || file1 === undefined) {
            throw new Error('Required parameter file1 was null or undefined when calling uploadOrderAttachment.');
        }







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (purchaseOrderId !== undefined && purchaseOrderId !== null) {
            queryParameters = queryParameters.set('purchaseOrderId', <any>purchaseOrderId);
        }
        if (salesOrderId !== undefined && salesOrderId !== null) {
            queryParameters = queryParameters.set('salesOrderId', <any>salesOrderId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file1 !== undefined) {
            formParams.append('file1', <any>file1);
        }
        if (file2 !== undefined) {
            formParams.append('file2', <any>file2);
        }
        if (file3 !== undefined) {
            formParams.append('file3', <any>file3);
        }
        if (file4 !== undefined) {
            formParams.append('file4', <any>file4);
        }
        if (file5 !== undefined) {
            formParams.append('file5', <any>file5);
        }

        return this.httpClient.post<Array<DocumentAttachment>>(`${this.basePath}/organisation/orders/upload_order_attachment`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Uplod the excel file of Purchase orders
     * 
     * @param file 
     * @param includeFirstRow 
     * @param dryRun Dry run the database transactions to see the results
     * @param columnMappings 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public uploadPoExcel(file: Blob, includeFirstRow: boolean, dryRun: boolean, columnMappings: string, observe?: 'body', reportProgress?: boolean): Observable<ExcelUploadResult>;
    public uploadPoExcel(file: Blob, includeFirstRow: boolean, dryRun: boolean, columnMappings: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ExcelUploadResult>>;
    public uploadPoExcel(file: Blob, includeFirstRow: boolean, dryRun: boolean, columnMappings: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ExcelUploadResult>>;
    public uploadPoExcel(file: Blob, includeFirstRow: boolean, dryRun: boolean, columnMappings: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (file === null || file === undefined) {
            throw new Error('Required parameter file was null or undefined when calling uploadPoExcel.');
        }

        if (includeFirstRow === null || includeFirstRow === undefined) {
            throw new Error('Required parameter includeFirstRow was null or undefined when calling uploadPoExcel.');
        }

        if (dryRun === null || dryRun === undefined) {
            throw new Error('Required parameter dryRun was null or undefined when calling uploadPoExcel.');
        }

        if (columnMappings === null || columnMappings === undefined) {
            throw new Error('Required parameter columnMappings was null or undefined when calling uploadPoExcel.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (includeFirstRow !== undefined && includeFirstRow !== null) {
            queryParameters = queryParameters.set('includeFirstRow', <any>includeFirstRow);
        }
        if (dryRun !== undefined && dryRun !== null) {
            queryParameters = queryParameters.set('dryRun', <any>dryRun);
        }
        if (columnMappings !== undefined && columnMappings !== null) {
            queryParameters = queryParameters.set('columnMappings', <any>columnMappings);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams.append('file', <any>file);
        }

        return this.httpClient.put<ExcelUploadResult>(`${this.basePath}/organisation/orders/upload_po_excel`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Uplod the excel file of Sales orders
     * 
     * @param file 
     * @param includeFirstRow 
     * @param dryRun Dry run the database transactions to see the results
     * @param columnMappings 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public uploadSoExcel(file: Blob, includeFirstRow: boolean, dryRun: boolean, columnMappings: string, observe?: 'body', reportProgress?: boolean): Observable<ExcelUploadResult>;
    public uploadSoExcel(file: Blob, includeFirstRow: boolean, dryRun: boolean, columnMappings: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ExcelUploadResult>>;
    public uploadSoExcel(file: Blob, includeFirstRow: boolean, dryRun: boolean, columnMappings: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ExcelUploadResult>>;
    public uploadSoExcel(file: Blob, includeFirstRow: boolean, dryRun: boolean, columnMappings: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (file === null || file === undefined) {
            throw new Error('Required parameter file was null or undefined when calling uploadSoExcel.');
        }

        if (includeFirstRow === null || includeFirstRow === undefined) {
            throw new Error('Required parameter includeFirstRow was null or undefined when calling uploadSoExcel.');
        }

        if (dryRun === null || dryRun === undefined) {
            throw new Error('Required parameter dryRun was null or undefined when calling uploadSoExcel.');
        }

        if (columnMappings === null || columnMappings === undefined) {
            throw new Error('Required parameter columnMappings was null or undefined when calling uploadSoExcel.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (includeFirstRow !== undefined && includeFirstRow !== null) {
            queryParameters = queryParameters.set('includeFirstRow', <any>includeFirstRow);
        }
        if (dryRun !== undefined && dryRun !== null) {
            queryParameters = queryParameters.set('dryRun', <any>dryRun);
        }
        if (columnMappings !== undefined && columnMappings !== null) {
            queryParameters = queryParameters.set('columnMappings', <any>columnMappings);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams.append('file', <any>file);
        }

        return this.httpClient.put<ExcelUploadResult>(`${this.basePath}/organisation/orders/upload_so_excel`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
