/**
 * Nuphi trade
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2.0.0
 * Contact: shraddha@blackcurrantapps.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Organisation } from './organisation';
import { Shipment } from './shipment';
import { ShipmentDialogue } from './shipmentDialogue';


export interface ShipmentDialogueAttachment { 
    shipmentDialogueAttachmentId?: number;
    shipmentDialogueId?: number;
    shipmentId?: number;
    orgId?: number;
    url?: string;
    filename?: string;
    source?: ShipmentDialogueAttachment.SourceEnum;
    meta?: any;
    Organisation?: Organisation;
    Shipment?: Shipment;
    ShipmentDialogue?: ShipmentDialogue;
}
export namespace ShipmentDialogueAttachment {
    export type SourceEnum = 'EMAIL' | 'UPLOAD';
    export const SourceEnum = {
        EMAIL: 'EMAIL' as SourceEnum,
        UPLOAD: 'UPLOAD' as SourceEnum
    };
}
