/**
 * Nuphi trade
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2.0.0
 * Contact: shraddha@blackcurrantapps.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Invoice } from './invoice';
import { Organisation } from './organisation';
import { OrganisationContact } from './organisationContact';


export interface InvoicePayment { 
    invoicePaymentId?: number;
    invId?: number;
    orgId?: number;
    paymentMadeBy?: number;
    dateOfPayment?: string;
    bankAccount?: any;
    currency?: string;
    amount?: number;
    remarks?: string;
    paymentRefNo?: string;
    typeOfPayment?: InvoicePayment.TypeOfPaymentEnum;
    financingCharges?: number;
    bankCharges?: number;
    conversionRate?: number;
    localCurAmount?: number;
    attachmentURL?: string;
    Invoice?: Invoice;
    OrganisationContact?: OrganisationContact;
    Organisation?: Organisation;
}
export namespace InvoicePayment {
    export type TypeOfPaymentEnum = 'FINANCE' | 'BUYER_PAYMENT';
    export const TypeOfPaymentEnum = {
        FINANCE: 'FINANCE' as TypeOfPaymentEnum,
        BUYERPAYMENT: 'BUYER_PAYMENT' as TypeOfPaymentEnum
    };
}
