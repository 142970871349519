import { Component, OnInit } from '@angular/core';
import { ServiceProviderService } from 'src/swagger';

@Component({
  selector: 'app-sp-dashboard',
  templateUrl: './sp-dashboard.component.html',
  styleUrls: ['./sp-dashboard.component.scss']
})
export class SpDashboardComponent implements OnInit {

  public stats: Stats = {};
  constructor(
    private sp: ServiceProviderService
  ) { }

  ngOnInit(): void {
    this.getStats();
  }

  getStats(): void {
    this.sp.getDashboardStatsSp()
    .subscribe(stats => {
      this.stats = stats;
    });
  }
}

export class Stats {
  totalShipments?: number;
  shipmentValue?: any;

}
