import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OnboardingRoutingModule } from './onboarding-routing.module';
import { CreateProfileComponent } from './create-profile/create-profile.component';
import { HelperModuleModule } from '../helper-module/helper-module.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BASE_PATH } from '../../swagger/variables';
import { environment } from '../../environments/environment.development';
import { OnboardingComponent } from './onboarding.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { CreateShipmentComponent } from './create-shipment/create-shipment.component';
import { OrganisationModule } from '../organisation/organisation.module';
import { ShipmentDemoComponent } from './shipment-demo/shipment-demo.component';
import { ShipmentOverviewComponent } from './shipment-overview/shipment-overview.component';


@NgModule({
  declarations: [
    OnboardingComponent,
    CreateProfileComponent,
    CreateShipmentComponent,
    ShipmentDemoComponent,
    ShipmentOverviewComponent,
  ],
  imports: [
    CommonModule,
    HelperModuleModule,
    FormsModule,
    ReactiveFormsModule,
    OnboardingRoutingModule,
    NgSelectModule,
    OrganisationModule
  ],
  exports: [
    ShipmentDemoComponent
  ],
  providers: [
    { provide: BASE_PATH, useValue: environment.BASE_PATH },
  ],
})
export class OnboardingModule { }
