import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { SwalHelper } from 'src/app/swal';
import { OrgShipmentsService, Shipment } from 'src/swagger';
declare var $: any;

@Component({
  selector: 'app-create-shipment',
  templateUrl: './create-shipment.component.html',
  styleUrls: ['./create-shipment.component.scss']
})
export class CreateShipmentComponent implements OnInit {

  public nextStep = false;
  public newShipment: Shipment = {};
  public shouldShowLoader: boolean;

  constructor(
    private orgShipmentApi: OrgShipmentsService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.initNewShipment();
  }

  initNewShipment(): void {
    this.newShipment = {};
    this.newShipment.shipmentType = 'SELL';
    this.newShipment.shipmentTags = [];
    this.newShipment.buyerId = 0;
    this.newShipment.sellerId = 0;
    this.newShipment.shipmentCurrency = 'USD';
    this.newShipment.mode = 'Sea';
    this.newShipment.shipmentNoSuf = '0001';
    this.newShipment.shipmentNoPre = 'Inv-';
  }

  public showNextStep(): void {
    this.nextStep = true;
  }

  public showPrevStep(): void {
    this.nextStep = false;
  }

  newVendor(vendor): void {
    this.newShipment.buyerId = this.newShipment.shipmentType === 'SELL' ? vendor.venSupId : undefined;
    this.newShipment.sellerId = this.newShipment.shipmentType === 'BUY' ? vendor.venSupId : undefined;
    this.newShipment.companyName = vendor.companyName;
    $('#addBuyer').modal('hide');
    this.saveShipment();
  }


  // Fn to Add/Update the shipment details
  saveShipment(): void {
    this.newShipment.buyerId = this.newShipment.buyerId === 0 ? undefined : this.newShipment.buyerId;
    this.newShipment.sellerId = this.newShipment.sellerId === 0 ? undefined : this.newShipment.sellerId;
    this.newShipment.shipmentDate = moment(this.newShipment.shipmentDate).format('YYYY-MM-DD');
    if (this.newShipment.buyerId || this.newShipment.sellerId) {
      this.shouldShowLoader = true;
      this.orgShipmentApi.createShipment(
        this.newShipment
      ).subscribe(shipment => {
        this.shouldShowLoader = false;
        SwalHelper.successTimerSwal('Shipment created');
        this.router.navigate([`/organisation/shipment/uid/${shipment.shipmentUid}`]);
        localStorage.setItem('ShowshipmentDemo', 'Yes');
      }, err => {
        this.shouldShowLoader = false;
        SwalHelper.showErrorSwal(err);
      });
    } else {
      SwalHelper.showErrorSwal(`Select ${this.newShipment.shipmentType === 'SELL' ? 'Buyer' : 'Seller'}`);
    }
  }

}
