import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { GlobalProfileService } from 'src/app/global-profile.service';
import { SwalHelper } from 'src/app/swal';
import { OrganisationService, PurchaseOrder, ReportsService, VendorAndSupplier } from 'src/swagger';
import { OrganisationHelperService } from '../../organisation-helper.service';
import { CommonSupportService } from '../../../common-support.service';

@Component({
  selector: 'app-purchase-order-report',
  templateUrl: './purchase-order-report.component.html',
  styleUrls: ['./purchase-order-report.component.scss']
})
export class PurchaseOrderReportComponent implements OnInit {

  public allOrders: PurchaseOrder[] = [];
  public shouldShowLoader: boolean;

  public fromDate: string;
  public toDate: string;
  public status: string;
  public currency: string;

  public allVendors: VendorAndSupplier[] = [];

  public vendorId: number;

  dtOptions: any = {};

  public allPOAmount: PurchaseOrder[] = [];

  constructor(
    private reportApi: ReportsService,
    public orgHelper: OrganisationHelperService,
    public profileSync: GlobalProfileService,
    public commonService: CommonSupportService,
    private orgApi: OrganisationService,
  ) { }

  ngOnInit(): void {
    this.resetFilter();
    this.fetchAllVendors();
    this.fetchPOTotal();
    this.dtOptions = {
      dom: 'Bfrtip',
      lengthMenu: [
        [10, 25, 50, -1],
        ['10', '25', '50', 'All']
      ],
      buttons: [
        'pageLength',
        {
          text: 'Excel Download',
          className: 'btn btn-green',
          extend: 'excelHtml5',
          title: 'import-order-report',
        }
      ],
      columnDefs: [
        { targets: 3, type: 'num-fmt' },
      ],
    };
  }

  /* Fn to fetch the Purchase order report */
  fetchReport(): void {
    this.allOrders = [];
    this.shouldShowLoader = true;
    this.reportApi.poReport(
      this.toDate ? moment(this.toDate).format('YYYY-MM-DD') : null,
      this.fromDate ? moment(this.fromDate).format('YYYY-MM-DD') : null,
      this.status === 'All' ? undefined : this.status,
      this.currency === 'All' ? undefined : this.currency,
      this.vendorId  == 0 ? undefined : this.vendorId,
    ).subscribe(orders => {
      this.shouldShowLoader = false;
      if (orders && orders.length > 0) {
        this.allOrders = orders;
      } else {
        this.allOrders = null;
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /* Fn to reset the filter */
  resetFilter(): void {
    this.toDate = undefined;
    this.fromDate = undefined;
    this.status = 'All';
    this.currency = 'All';
    this.vendorId = 0;
    this.fetchReport();
  }

  /* Fn to fetch all the vendors in filter */
  fetchAllVendors(): void {
    this.shouldShowLoader = true;
    this.orgApi.searchVendors(
      undefined,
      undefined,
      100
    ).subscribe(vendors => {
      this.shouldShowLoader = false;
      if (vendors && vendors.length > 0) {
        this.allVendors = vendors;
      } else {
        this.allVendors = null;
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /* Function to fetch total amount in band*/
  fetchPOTotal(): void {
    this.shouldShowLoader = true;
    this.reportApi.totalPoAmountReport(
      this.status === 'All' ? undefined : this.status,
    ).subscribe(pos => {
      this.shouldShowLoader = false;
      if (pos && pos.length > 0) {
        this.allPOAmount = pos;
      } else {
        this.allPOAmount = null;
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

}
