import { Component, OnInit } from '@angular/core';
import { GlobalProfileService } from 'src/app/global-profile.service';

@Component({
  selector: 'app-shipment-documents',
  templateUrl: './shipment-documents.component.html',
  styleUrls: ['./shipment-documents.component.scss']
})
export class ShipmentDocumentsComponent implements OnInit {

  constructor(
    public profileSync: GlobalProfileService
  ) { }

  ngOnInit(): void {
  }

}
