import { Component, OnInit, Renderer2, HostListener, ViewChild, ElementRef, Input, ChangeDetectorRef, OnChanges, OnDestroy, EventEmitter, Output } from '@angular/core';
import {
  OrgContactService, OrgShipmentsService, OrganisationContact, PurchaseOrder,
  SalesOrder, Invoice, ShipmentDocument, ShipmentAccess, ShipmentDocumentFolder, Shipment,
  OrganisationService, ShippingBill, BillOfEntry, BillOfLading, CustomExportObject, DocumentAttachment, OrgInvoiceService
} from 'src/swagger';
import { Router } from '@angular/router';
import { CommonSupportService } from 'src/app/common-support.service';
import { GlobalProfileService } from 'src/app/global-profile.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject, of, Subscription } from 'rxjs';
import { catchError, tap, switchMap, take, debounceTime } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SwalHelper } from 'src/app/swal';
import { ShipmentDetailService } from '../shipment-detail.service';
import * as moment from 'moment';
import { saveAs } from 'file-saver';

declare var $: any;
declare var swal: any;
@Component({
  selector: 'app-shipment-share-document',
  templateUrl: './shipment-share-document.component.html',
  styleUrls: ['./shipment-share-document.component.scss']
})
export class ShipmentShareDocumentComponent implements OnInit, OnChanges, OnDestroy {

  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('FolderFileInput') folderFileInput: ElementRef;

  @Input() shipmentDetails: Shipment = {};
  @Input() availableUsers = [];

  @Output() updatedUsers = new EventEmitter<any>();

  public daysSliderConfig: any = {
    behaviour: 'drag',
    connect: 'lower',
    step: 1,
    pageSteps: 10,
    tooltips: true,
    range: {
      min: 1,
      max: 120
    },
    pips: {
      mode: 'values',
      values: [1, 9, 30, 60, 90, 120],
      density: 2,
    },
    format: {
      // 'to' the formatted value. Receives a number.
      to(value): number {
        return Number(value.toFixed(0));
      },
      from(value): number {
        return Number(value);
      }
    }
  };

  public contactRightSheet = false;
  public basePath: string;
  public shouldShowLoader: boolean;

  public perpetualDays;
  public shipmentUserAccess = false;
  public shipmentAccessDays = 60;

  public orgContactChange$: Subject<string> = new Subject();
  public listOrgContact$: Subject<OrganisationContact[]> = new Subject();
  public alreadyLinkedOrgContact = [];
  public contactLoading = false;
  private orgContactSubscription: Subscription;

  public poChange$: Subject<string> = new Subject();
  public listPO$: Subject<PurchaseOrder[]> = new Subject();
  public alreadyLinkedPOIds = [];
  public poLoading = false;
  private poSubscription: Subscription;

  public soChange$: Subject<string> = new Subject();
  public listSo$: Subject<SalesOrder[]> = new Subject();
  public alreadyLinkedSOIds = [];
  public soLoading = false;
  private soSubscription: Subscription;

  public invChange$: Subject<string> = new Subject();
  public listInvoice$: Subject<Invoice[]> = new Subject();
  public alreadyLinkedInvIds = [];
  public invoiceLoading = false;
  private invSubscription: Subscription;

  public shippingBillChange$: Subject<string> = new Subject();
  public listShippingBill$: Subject<ShippingBill[]> = new Subject();
  public shippingBillLoading = false;
  private shippingBillSubscription: Subscription;

  public boeChange$: Subject<string> = new Subject();
  public listBOE$: Subject<BillOfEntry[]> = new Subject();
  public boeLoading = false;
  private boeSubscription: Subscription;

  public bolChange$: Subject<string> = new Subject();
  public listBOL$: Subject<BillOfLading[]> = new Subject();
  public bolLoading = false;
  private bolSubscription: Subscription;

  public alreadySharedContacts: ShipmentAccess[] = [];
  public shipmentDocumentLink: ShipmentDocument = {}; // For Linking Purpose
  public shipmentAccess: ShipmentAccess = {};
  public newShipmentFolder: ShipmentDocumentFolder = {};
  public shipmentDocumentFiles: File[] = [];
  public newShipmentDocument: ShipmentDocument = {}; // For adding new Documents in folder
  public shipmentDocAccess = [];
  public shipmentFileAccess = [];

  public documentAttachments: DocumentAttachment[] = [];

  public selectContactNameToShare: string;
  public outputInvoice: any;

  /* To show current selected smart link document for view modal */
  public currentOpenDocument = {
    documentId: 0,
    docType: '',
    pkName: '',
    pkId: 0,
    documentName: '',
    status: ''
  };

  public isShowUserSelectError = false;

  /* Boolean variables to check if smart link document is sharable */
  public shouldShareInvoice = false;
  public shouldShareVendorBill = false;
  public shouldSharePO = false;
  public shouldShareSO = false;
  public shouldShareBOE = false;
  public shouldShareBL = false;
  public shouldShareShippingBill = false;

  public isEnabledInvoice = false;
  public isEnabledVendorBill = false;
  public isEnabledPO = false;
  public isEnabledSO = false;
  public isEnabledBOE = false;
  public isEnabledBL = false;
  public isEnabledShippingBill = false;

  public shouldShareFiles: boolean[][] = [];

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent): void {
    this.contactRightSheet = false;
    this.renderer.removeClass(document.body, 'modal-open');
  }

  constructor(
    private orgContactApi: OrgContactService,
    private orgShipmentApi: OrgShipmentsService,
    private renderer: Renderer2,
    private commonService: CommonSupportService,
    public profileSync: GlobalProfileService,
    public http: HttpClient,
    public router: Router,
    private changeDetector: ChangeDetectorRef,
    private shipmetDetailService: ShipmentDetailService,
    private orgApi: OrganisationService,
    private orgInvApi: OrgInvoiceService,
  ) {
    commonService.renderer = renderer;
    this.basePath = environment.BASE_PATH;
  }

  ngOnInit(): void {

    this.shipmentAccess = {};

    this.poSubscription = this.poChange$.pipe(
      debounceTime(200),
      tap(() => this.poLoading = true),
      switchMap(query => this.orgShipmentApi.searchPos(
        query,
        this.alreadyLinkedPOIds.join(',')
      ).pipe(
        catchError(() => of([])), // empty list on error
        tap(() => {
          this.poLoading = false;
          this.shipmentDocumentLink.invId = undefined;
          this.shipmentDocumentLink.salesOrderId = undefined;
        })
      ))
    ).subscribe(pos => {
      this.listPO$.next(pos);
    });

    this.soSubscription = this.soChange$.pipe(
      debounceTime(200),
      tap(() => this.soLoading = true),
      switchMap(query => this.orgShipmentApi.searchSos(
        query,
        this.alreadyLinkedSOIds.join(',')
      ).pipe(
        catchError(() => of([])), // empty list on error
        tap(() => {
          this.soLoading = false;
          this.shipmentDocumentLink.invId = undefined;
          this.shipmentDocumentLink.purchaseOrderId = undefined;
        })
      ))
    ).subscribe(sos => {
      this.listSo$.next(sos);
    });

    this.invSubscription = this.invChange$.pipe(
      debounceTime(200),
      tap(() => this.invoiceLoading = true),
      switchMap(query => this.orgShipmentApi.searchInvoices(
        this.shipmentDetails.shipmentType === 'SELL' ? 'SALES' : 'PURCHASE',
        query,
        this.alreadyLinkedInvIds.toString()
      ).pipe(
        catchError(() => of([])), // empty list on error
        tap(() => {
          this.invoiceLoading = false;
          this.shipmentDocumentLink.purchaseOrderId = undefined;
          this.shipmentDocumentLink.salesOrderId = undefined;
        })
      ))
    ).subscribe(invs => {
      this.listInvoice$.next(invs);
    });

    this.shippingBillSubscription = this.shippingBillChange$.pipe(
      debounceTime(200),
      tap(() => this.shippingBillLoading = true),
      switchMap(query => this.orgShipmentApi.searchShippingBills(
        query,
      ).pipe(
        catchError(() => of([])), // empty list on error
        tap(() => {
          this.shippingBillLoading = false;
          this.shipmentDocumentLink.purchaseOrderId = undefined;
          this.shipmentDocumentLink.salesOrderId = undefined;
        })
      ))
    ).subscribe(sbs => {
      this.listShippingBill$.next(sbs);
    });

    this.bolSubscription = this.bolChange$.pipe(
      debounceTime(200),
      tap(() => this.bolLoading = true),
      switchMap(query => this.orgShipmentApi.searchBls(
        query,
      ).pipe(
        catchError(() => of([])), // empty list on error
        tap(() => {
          this.bolLoading = false;
          this.shipmentDocumentLink.purchaseOrderId = undefined;
          this.shipmentDocumentLink.salesOrderId = undefined;
        })
      ))
    ).subscribe(bols => {
      this.listBOL$.next(bols);
    });

    this.boeSubscription = this.boeChange$.pipe(
      debounceTime(200),
      tap(() => this.boeLoading = true),
      switchMap(query => this.orgShipmentApi.searchBoes(
        query,
      ).pipe(
        catchError(() => of([])), // empty list on error
        tap(() => {
          this.boeLoading = false;
          this.shipmentDocumentLink.purchaseOrderId = undefined;
          this.shipmentDocumentLink.salesOrderId = undefined;
        })
      ))
    ).subscribe(boes => {
      this.listBOE$.next(boes);
    });

    this.orgContactSubscription = this.orgContactChange$.pipe(
      debounceTime(200),
      tap(() => this.contactLoading = true),
      switchMap(query => this.orgShipmentApi.searchOrgContacts(
        query,
        this.alreadyLinkedOrgContact.toString(),
      ).pipe(
        catchError(() => of([])), // empty list on error
        tap(() => {
          this.contactLoading = false;
        })
      ))
    ).subscribe(cons => {
      this.listOrgContact$.next(cons);
    });
  }

  ngOnChanges(): void {
    if (this.shipmentDetails && this.shipmentDetails.shipmentId) {
      if (this.shipmentDetails.PurchaseOrders && this.shipmentDetails.PurchaseOrders.length > 0) {
        this.alreadyLinkedPOIds = this.shipmentDetails.PurchaseOrders.map(i => i.PurchaseOrder.purchaseOrderId);
      }
      if (this.shipmentDetails.SalesOrders && this.shipmentDetails.SalesOrders.length > 0) {
        this.alreadyLinkedSOIds = this.shipmentDetails.SalesOrders.map(i => i.SalesOrder.salesOrderId);
      }
      if (this.shipmentDetails.Invoice) {
        this.alreadyLinkedInvIds = this.shipmentDetails.Invoice.Invoice.invId;
      }
      if (this.shipmentDetails.ShipmentAccesses && this.shipmentDetails.ShipmentAccesses.length > 0) {
        this.alreadySharedContacts = this.shipmentDetails.ShipmentAccesses;
        this.alreadyLinkedOrgContact = this.shipmentDetails.ShipmentAccesses.map(i => i.sharedWithContact);
      } else {
        this.alreadySharedContacts = [];
      }
      this.fetchDocumentFolders();
    }
    if (this.profileSync.user && this.profileSync.user.orgId == this.shipmentDetails.orgId) {
      this.invChange$.next('');
      this.poChange$.next('');
      this.soChange$.next('');
      this.boeChange$.next('');
      this.bolChange$.next('');
      this.shippingBillChange$.next('');
    }
  }

  /* Fn to initialize new folder & show folder modal */
  public initNewFolder(): void {
    this.newShipmentFolder = {};
    $('#groupModal').modal('show');
  }

  /* Fn to open smart link document modal */
  public openSmartLinksDialog(): void {
    $('#fileAddModal').modal('show');
    this.shipmentDocumentLink.docType = 'default';
  }

  toggleDocPermission(type, event): void {
    if (event.checked) {
      this.shipmentDocAccess.push(type);
    } else {
      this.shipmentAccess.allDocumentAccess = false;
      const index = this.shipmentDocAccess.findIndex(i => i == type);
      if (index >= 0) {
        this.shipmentDocAccess.splice(index, 1);
      }
    }
  }

  toggeleFilesAccess(docId, event): void {
    if (event.checked) {
      this.shipmentFileAccess.push(docId);
    } else {
      this.shipmentAccess.allDocumentAccess = false;
      const index = this.shipmentFileAccess.findIndex(i => i == docId);
      if (index >= 0) {
        this.shipmentFileAccess.splice(index, 1);
      }
    }
  }

  /* Fn to Open right sheet to add new contact */
  contactRightSheetOpen(): void {
    this.contactRightSheet = !this.contactRightSheet;
    this.renderer.addClass(document.body, 'modal-open');
    this.commonService.addBackdrop();
  }

  /* Fn to close right sheet to add new contact */
  contactRightSheetClose(): void {
    this.contactRightSheet = false;
    this.renderer.removeClass(document.body, 'modal-open');
    this.commonService.removeBackdrop();
  }

  /**
   * Fn to capture Shipment Document File
   * @param event Event contains file buffer
   */
  captureShipmentDocumentFile(event): void {
    if (event.target.files.length + this.shipmentDocumentFiles.length > 5) {
      SwalHelper.showErrorSwal('Maximum 5 files allowed');
      return;
    }
    if (event.target.files && event.target.files.length > 0) {
      Object.keys(event.target.files).forEach((key) => {
        this.shipmentDocumentFiles.push(event.target.files[key]);
      });
    }
  }

  /**
   * Fn to remove the shipment Document File from array
   * @param index Index of file to be removed
   */
  removeShipmentDocumentFile(index: number): void {
    this.shipmentDocumentFiles.splice(index, 1);
    this.fileInput.nativeElement.value = '';
  }

  /**
   * Fn to show the name of selected Contact on sharing modal
   * @param orgContact Organisation contact selected for shipment sharing
   */
  setContactName(orgContact: OrganisationContact): void {
    this.selectContactNameToShare = orgContact.contactName;
  }

  /* Fn to fetch all the folders inside the shipment */
  fetchDocumentFolders(): void {
    this.shouldShowLoader = true;
    this.orgContactApi.listShipmentFolders(
      this.shipmentDetails.shipmentId,
    ).subscribe(folders => {
      this.shouldShowLoader = false;
      if (folders && folders.length > 0) {
        if (!this.shipmentDetails.ShipmentDocumentFolders) {
          this.shipmentDetails.ShipmentDocumentFolders = [];
        }
        this.shipmentDetails.ShipmentDocumentFolders = folders;
        const files = this.shipmentDetails.ShipmentDocumentFolders.filter(i => i.ShipmentDocuments.length > 0);
        if (files && files.length > 0) {
          this.shipmetDetailService.updateFileAvaStatus(true);
        }
      } else {
        this.shipmentDetails.ShipmentDocumentFolders = [];
      }

      this.shouldShareFiles = new Array(this.shipmentDetails.ShipmentDocumentFolders.length);
      this.shipmentDetails.ShipmentDocumentFolders.forEach((folder, i) => {
        this.shouldShareFiles[i] = new Array(folder.ShipmentDocuments.length);
        folder.ShipmentDocuments.forEach((file, j) => {
          this.shouldShareFiles[i][j] = true;
        });
      });

      this.changeDetector.detectChanges();
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /* Update & show shipment sharing modal once new contact is added */
  newContact(contact): void {
    this.contactRightSheetClose();
    this.shipmentAccess.sharedWithContact = contact.orgContactId;
    this.selectContactNameToShare = contact.contactName;
    this.shipmentAccess.allDocumentAccess = true;
    this.shipmentAccessDays = 60;
    this.perpetualDays = false;
    this.toggleAllAccess();
    this.shipmentUserAccess = true;
    this.orgContactChange$.next(undefined);
    $('.file-collapse').collapse('show');
  }

  /* Fn to open the shipment share modal & fetch first 5 contact */
  addUser(): void {
    if (this.profileSync.user && this.profileSync.user.orgId === this.shipmentDetails.orgId) {
      this.shipmentUserAccess = true;
      this.initDefaultAccess();
      this.orgContactChange$.next(undefined);
      $('.file-collapse').collapse('show');
    }
  }

  /* Fn to initialize default access for user in shipment share */
  initDefaultAccess(): void {
    this.selectContactNameToShare = undefined;
    this.shipmentAccess = {};
    this.shipmentAccess.allDocumentAccess = true;
    this.shipmentAccessDays = 60;
    this.perpetualDays = false;
    this.toggleAllAccess();
  }

  toggleAllAccess(): void {
    if (this.shipmentAccess.allDocumentAccess) {
      this.checkAll();
    } else {
      this.uncheckAll();
    }
  }

  /* Fn to check all the smart link documents & files for shipment sharing modal */
  private checkAll(): void {
    this.shipmentAccess.allDocumentAccess = true;
    this.shouldShareInvoice = false;
    this.shouldShareVendorBill = false;
    this.shouldSharePO = false;
    this.shouldShareSO = false;
    this.shouldShareBOE = false;
    this.shouldShareBL = false;
    this.shouldShareShippingBill = false;

    if (this.shipmentDetails.Invoice && this.shipmentDetails.Invoice.Invoice) {
      this.shipmentDocAccess.push('INVOICE');
      this.isEnabledInvoice = true;
      this.shouldShareInvoice = true;
    }
    if (this.shipmentDetails.VendorBill && this.shipmentDetails.VendorBill.Invoice) {
      this.shipmentDocAccess.push('INVOICE');
      this.isEnabledVendorBill = true;
      this.shouldShareVendorBill = true;
    }
    if (this.shipmentDetails.ShippingBill && this.shipmentDetails.ShippingBill.ShippingBill) {
      this.shipmentDocAccess.push('SHIPPING_BILL');
      this.isEnabledShippingBill = true;
      this.shouldShareShippingBill = true;
    }
    if (this.shipmentDetails.BillOfLading && this.shipmentDetails.BillOfLading.BillOfLading) {
      this.shipmentDocAccess.push('BILL_OF_LADING');
      this.isEnabledBL = true;
      this.shouldShareBL = true;
    }
    if (this.shipmentDetails.PurchaseOrders && this.shipmentDetails.PurchaseOrders.length > 0) {
      this.shipmentDocAccess.push('PURCHASE_ORDER');
      this.isEnabledPO = true;
      this.shouldSharePO = true;
    }
    if (this.shipmentDetails.SalesOrders && this.shipmentDetails.SalesOrders.length > 0) {
      this.shipmentDocAccess.push('SALES_ORDER');
      this.isEnabledSO = true;
      this.shouldShareSO = true;
    }
    if (this.shipmentDetails.BillOfEntry && this.shipmentDetails.BillOfEntry.BillOfEntry) {
      this.shipmentDocAccess.push('BILL_OF_ENTRY');
      this.isEnabledBOE = true;
      this.shouldShareBOE = true;
    }

    this.shouldShareFiles = this.shouldShareFiles.map(folder => folder.map(() => true));

    const ids = this.shipmentDetails.Others.map(i => i.shipmentDocumentId);
    this.shipmentFileAccess = [];
    ids.forEach(i => {
      this.shipmentFileAccess.push(i);
    });
    this.changeDetector.detectChanges();
  }

  /* Fn to uncheck all the smart link documents & files for shipment sharing modal */
  private uncheckAll(): void {
    this.shipmentAccess.allDocumentAccess = false;
    this.shouldShareInvoice = false;
    this.shouldShareVendorBill = false;
    this.shouldSharePO = false;
    this.shouldShareSO = false;
    this.shouldShareBOE = false;
    this.shouldShareBL = false;
    this.shouldShareShippingBill = false;
    this.shouldShareFiles = this.shouldShareFiles.map(folder => folder.map(() => false));

    this.shipmentDocAccess = [];
    this.shipmentFileAccess = [];
    this.changeDetector.detectChanges();
  }

  cancelUser(): void {
    this.shipmentUserAccess = false;
    $('.file-collapse').collapse('hide');
  }


  /**
   * Fn to get the shipment access details for requested external user
   * @param index index of external contact to fetch access details 
   */
  updateUser(index: number): void {
    $('#usersModal').modal('hide');
    // fetch all the shipment Access details & bind it to shipment access model
    this.shipmentUserAccess = true;
    this.shouldShowLoader = true;
    this.orgShipmentApi.getShipmentAccess(
      this.shipmentDetails.shipmentId,
      this.alreadySharedContacts[index].shipmentAccessId
    ).subscribe(access => {
      this.shouldShowLoader = false;
      if (access) {
        this.shipmentAccess = access;
        this.checkAccess(undefined, 'SalesOrder');
        this.reCalculateAccess();
        this.selectContactNameToShare = access.OrganisationContact.contactName;
        if (this.shipmentAccess.expDate === '3100-01-31') {
          this.perpetualDays = true;
        } else {
          const a = moment(this.shipmentAccess.expDate);
          const b = moment().format('YYYY-MM-DD');
          this.shipmentAccessDays = a.diff(b, 'days'); // 1
        }
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /* Fn to re Calculate Shipment Access */
  reCalculateAccess(): void {
    this.shouldShareInvoice = false;
    this.shouldShareVendorBill = false;
    this.shouldSharePO = false;
    this.shouldShareSO = false;
    this.shouldShareBOE = false;
    this.shouldShareBL = false;
    this.shouldShareShippingBill = false;
    this.shouldShareFiles = this.shouldShareFiles.map(folder => folder.map(() => false));
    this.shipmentDocAccess = [];
    this.shipmentFileAccess = [];
    if (this.shipmentAccess.ShipmentDocumentAccesses.filter(i => i.ShipmentDocument.docType === 'INVOICE').length > 0) {
      this.shipmentDocAccess.push('INVOICE');
      this.shouldShareInvoice = true;
    }
    if (this.shipmentAccess.ShipmentDocumentAccesses.filter(i => i.ShipmentDocument.docType === 'VENDOR_BILL').length > 0) {
      this.shipmentDocAccess.push('INVOICE');
      this.shouldShareVendorBill = true;
    }
    if (this.shipmentAccess.ShipmentDocumentAccesses.filter(i => i.ShipmentDocument.docType === 'SHIPPING_BILL').length > 0) {
      this.shipmentDocAccess.push('SHIPPING_BILL');
      this.shouldShareShippingBill = true;
    }
    if (this.shipmentAccess.ShipmentDocumentAccesses.filter(i => i.ShipmentDocument.docType === 'BILL_OF_LADING').length > 0) {
      this.shipmentDocAccess.push('BILL_OF_LADING');
      this.shouldShareBL = true;
    }
    if (this.shipmentAccess.ShipmentDocumentAccesses.filter(i => i.ShipmentDocument.docType === 'PURCHASE_ORDER').length > 0) {
      this.shipmentDocAccess.push('PURCHASE_ORDER');
      this.shouldSharePO = true;
    }
    if (this.shipmentAccess.ShipmentDocumentAccesses.filter(i => i.ShipmentDocument.docType === 'SALES_ORDER').length > 0) {
      this.shipmentDocAccess.push('SALES_ORDER');
      this.shouldShareSO = true;
    }
    if (this.shipmentAccess.ShipmentDocumentAccesses.filter(i => i.ShipmentDocument.docType === 'BILL_OF_ENTRY').length > 0) {
      this.shipmentDocAccess.push('BILL_OF_ENTRY');
      this.shouldShareBOE = true;
    }
    if (this.shipmentAccess.ShipmentDocumentAccesses.filter(i => i.ShipmentDocument.docType === 'OTHER').length > 0) {
      const shipmentDocAccIds = this.shipmentAccess.ShipmentDocumentAccesses.filter(i => i.ShipmentDocument.docType === 'OTHER');
      const ids = shipmentDocAccIds.map(i => {
        this.shipmentFileAccess.push(i.shipmentDocumentId);
        return i.shipmentDocumentId;
      });
      this.shouldShareFiles = new Array(this.shipmentDetails.ShipmentDocumentFolders.length);
      this.shipmentDetails.ShipmentDocumentFolders.forEach((folder, i) => {
        this.shouldShareFiles[i] = new Array(folder.ShipmentDocuments.length);
        folder.ShipmentDocuments.forEach((file, j) => {
          this.shouldShareFiles[i][j] = ids.includes(file.shipmentDocumentId);
        });
      });
    }
    if (this.shipmentAccess.allDocumentAccess) {
      this.shouldShareInvoice = true;
      this.shouldShareVendorBill = true;
      this.shouldSharePO = true;
      this.shouldShareSO = true;
      this.shouldShareBOE = true;
      this.shouldShareBL = true;
      this.shouldShareShippingBill = true;
      this.shouldShareFiles = this.shouldShareFiles.map(folder => folder.map(() => true));
      this.shipmentDocAccess.push('INVOICE', 'SHIPPING_BILL', 'BILL_OF_LADING', 'PURCHASE_ORDER', 'SALES_ORDER', 'BILL_OF_ENTRY');

      const ids = this.shipmentDetails.Others.map(i => i.shipmentDocumentId);
      this.shipmentFileAccess = [];
      ids.forEach(i => {
        this.shipmentFileAccess.push(i);
      });
    }
    if (this.shipmentDetails.Invoice && this.shipmentDetails.Invoice.Invoice) {
      this.isEnabledInvoice = true;
    }
    if (this.shipmentDetails.VendorBill && this.shipmentDetails.VendorBill.Invoice) {
      this.isEnabledVendorBill = true;
    }
    if (this.shipmentDetails.ShippingBill && this.shipmentDetails.ShippingBill.ShippingBill) {
      this.isEnabledShippingBill = true;
    }
    if (this.shipmentDetails.BillOfLading && this.shipmentDetails.BillOfLading.BillOfLading) {
      this.isEnabledBL = true;
    }
    if (this.shipmentDetails.PurchaseOrders && this.shipmentDetails.PurchaseOrders.length > 0) {
      this.isEnabledPO = true;
    }
    if (this.shipmentDetails.SalesOrders && this.shipmentDetails.SalesOrders.length > 0) {
      this.isEnabledSO = true;
    }
    if (this.shipmentDetails.BillOfEntry && this.shipmentDetails.BillOfEntry.BillOfEntry) {
      this.isEnabledBOE = true;
    }
  }

  /* Fn to share/Update the Shipment access with external user */
  shareWithContact(): void {
    if (this.shipmentAccess.sharedWithContact) {
      this.isShowUserSelectError = false;
      this.shipmentUserAccess = false;
      this.shouldShowLoader = true;

      if (this.perpetualDays) {
        this.shipmentAccess.expDate = '-1';
      } else {
        this.shipmentAccess.expDate = moment().add(this.shipmentAccessDays, 'days').toDate().getTime().toString();
      }
      if (this.shipmentAccess.allDocumentAccess) {
        this.shipmentDocAccess = [];
        this.shipmentFileAccess = [];
      }

      this.shipmentAccess.shipmentId = this.shipmentDetails.shipmentId;
      this.shipmentAccess.permissions = {};

      if (this.shipmentAccess.shipmentAccessId) {
        this.orgShipmentApi.updateShipmentAccess(
          this.shipmentAccess,
          [JSON.stringify(this.shipmentDocAccess)],
          [JSON.stringify(this.shipmentFileAccess)]
        ).subscribe(shipmentAcc => {
          this.shouldShowLoader = false;
          SwalHelper.successTimerSwal(`Shipment Access updated for ${this.selectContactNameToShare}`);
          this.cancelUser();
          this.initDefaultAccess();
        }, err => {
          this.shouldShowLoader = false;
          SwalHelper.showErrorSwal(err);
        });
      } else {
        this.orgShipmentApi.shareShipment(
          this.shipmentDetails.shipmentId,
          this.shipmentAccess,
          [JSON.stringify(this.shipmentDocAccess)],
          [JSON.stringify(this.shipmentFileAccess)]
        ).subscribe(shipmentAcc => {
          this.shouldShowLoader = false;
          SwalHelper.successTimerSwal(`Shipment shared with ${this.selectContactNameToShare}`);
          this.cancelUser();
          this.initDefaultAccess();
          this.alreadySharedContacts.push(shipmentAcc);  // Add the contact to shared list
          this.availableUsers.push({
            name: shipmentAcc.OrganisationContact.contactName,
            email: shipmentAcc.OrganisationContact.contactEmail,
            orgContactId: shipmentAcc.OrganisationContact.orgContactId,
          });
          this.updatedUsers.next(this.availableUsers);
          this.shipmentDetails.ShipmentDialogues.push(shipmentAcc.Shipment.ShipmentDialogues[0]);
        }, err => {
          this.shouldShowLoader = false;
          SwalHelper.showErrorSwal(err);
        });
      }
    } else {
      this.isShowUserSelectError = true;
    }

  }

  /**
   * Fn to revoke Access Permission from external user
   * @param index Index of Selected External User
   */
  revokeAccessPermission(index: number): void {
    swal({
      title: `Revoke permission from ${this.alreadySharedContacts[index].OrganisationContact.contactName}`,
      text: 'This cannot be undone & revokes all the permission from user & will not be able to see shipment',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        this.shouldShowLoader = true;
        return new Promise((resolve, reject) => {
          this.orgShipmentApi.revokeShipmentAccess(
            this.alreadySharedContacts[index].shipmentAccessId,
          ).subscribe(comment => {
            if (comment) {
              $('#usersModal').modal('hide');
              const i = this.availableUsers.findIndex((user) => user.email == this.alreadySharedContacts[index].OrganisationContact.contactEmail);
              this.availableUsers.splice(i, 1);
              this.updatedUsers.next(this.availableUsers);
              this.alreadySharedContacts.splice(index, 1);
              this.shipmentDetails.ShipmentDialogues.push(comment);
            }
            resolve(comment);
          }, err => {
            reject(err);
          });
        });
      },
      allowOutsideClick: () => !swal.isLoading()
    }).then((result) => {
      if (result.value) {
        this.shouldShowLoader = false;
        SwalHelper.successTimerSwal('Shipment Access revoked');
      }
    }).catch(err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /**
   * Fn to open the shipment smart link modal based on type of document
   * @param docType String document type
   */
  openDocLinkModal(docType: string): void {
    this.shipmentDocumentLink.docType = docType;
    $('#fileAddModal').modal('show');
  }

  /* Fn to check if selected smart link document already linked with other shipment */
  docAlreadyLinked(): void {
    if (this.shipmentDocumentLink.docType !== 'PURCHASE_ORDER' && this.shipmentDocumentLink.docType !== 'SALES_ORDER') {
      this.orgShipmentApi.documentAlreadyLinked(
        this.shipmentDocumentLink
      ).subscribe(ok => {
        if (ok) {
          swal({
            title: 'Alredy Linked',
            text: ok.message,
            type: 'warning',
            showCancelButton: true,
            cancelButtonText: 'No',
            confirmButtonText: 'Yes! Link',
          }).then((result) => {
            if (result.value) {
              if (this.shipmentDocumentLink.docType === 'INVOICE' || this.shipmentDocumentLink.docType === 'VENDOR_BILL') {
                this.getInvoiceLinkedDocuments();
              } else {
                this.linkDocument(true);
              }
            } else {
              $('#fileAddModal').modal('hide');
              this.shipmentDocumentLink = {};
              this.shipmentDocumentLink.docType = 'default';
            }
          });
        } else {
          if (this.shipmentDocumentLink.docType === 'INVOICE' || this.shipmentDocumentLink.docType === 'VENDOR_BILL') {
            this.getInvoiceLinkedDocuments();
          } else {
            this.linkDocument(true);
          }
        }
      }, err => {
        SwalHelper.showErrorSwal(err);
      });
    } else {
      this.linkDocument(true);
    }
  }

  /* Fn to check linked documents to Invoice */
  getInvoiceLinkedDocuments(): void {
    this.orgInvApi.getLinkedInvDocs(
      this.shipmentDocumentLink.invId
    ).subscribe(inv => {
      const linkedInvDocs = [];
      let message;
      if (inv.ShippingBill && (!this.shipmentDetails.ShippingBill || !this.shipmentDetails.ShippingBill.ShippingBill)) {
        linkedInvDocs.push({
          shippingBillId: inv.ShippingBill.shippingBillId,
          docType: 'SHIPPING_BILL',
        });
        message = `Linked With Shipping Bill${inv.ShippingBill.shippingBillNumber}`;
      }
      if (inv.BillOfEntry && (!this.shipmentDetails.BillOfEntry || !this.shipmentDetails.BillOfEntry.BillOfEntry)) {
        linkedInvDocs.push({
          billOfEntryId: inv.BillOfEntry.billOfEntryId,
          docType: 'BILL_OF_ENTRY',
        });
        message = message.concat(`, Linked With Bill Of Entry ${inv.BillOfEntry.beNumber}`);
      }
      if (inv.BillOfLading && (!this.shipmentDetails.BillOfLading || !this.shipmentDetails.BillOfLading.BillOfLading)) {
        linkedInvDocs.push({
          billOfLadingId: inv.BillOfLading.billOfLadingId,
          docType: 'BILL_OF_LADING',
        });
        message = message.concat(`, Linked With Bill of Lading ${inv.BillOfLading.blNumber}`);
      }
      if (linkedInvDocs.length > 0) {
        swal({
          title: `Documents Linked to ${this.shipmentDocumentLink.docType.toLowerCase()}`,
          text: message,
          type: 'warning',
          showCancelButton: true,
          cancelButtonText: 'No',
          confirmButtonText: 'Yes! Link',
        }).then((result) => {
          if (result.value) {
            this.linkDocument(true);
            linkedInvDocs.forEach(doc => {
              this.shipmentDocumentLink = {};
              this.shipmentDocumentLink.docType = doc.docType;
              this.shipmentDocumentLink.billOfLadingId = doc.billOfLadingId ? doc.billOfLadingId : undefined;
              this.shipmentDocumentLink.billOfEntryId = doc.billOfEntryId ? doc.billOfEntryId : undefined;
              this.shipmentDocumentLink.shippingBillId = doc.shippingBillId ? doc.shippingBillId : undefined;
              this.linkDocument(false);
            });
          } else {
            this.linkDocument(true);
          }
        });
      } else {
        this.linkDocument(true);
      }
    });
  }

  /**
   * Fn to link the smart link document
   * @param showSwal Boolean value to show swal or not
   */
  linkDocument(showSwal: boolean): void {
    if (this.shipmentDocumentLink.docType === 'default') {
      return;
    }
    this.shouldShowLoader = true;
    this.shipmentDocumentLink.shipmentId = this.shipmentDetails.shipmentId;
    this.orgShipmentApi.linkShipmentDocument(
      this.shipmentDocumentLink
    ).subscribe(doc => {
      this.shouldShowLoader = false;
      if (showSwal) {
        SwalHelper.successTimerSwal('Document linked');
      }
      if (doc) {
        $('#fileAddModal').modal('hide');
        if (doc.PurchaseOrder) {
          this.shipmentDetails.PurchaseOrders.push(doc);
          this.alreadyLinkedPOIds.push(doc.PurchaseOrder.orgOrderNo);
          this.shouldSharePO = true;
        }
        if (doc.SalesOrder) {
          this.shipmentDetails.SalesOrders.push(doc);
          this.alreadyLinkedSOIds.push(doc.SalesOrder.orgOrderNo);
          this.shouldShareSO = true;
        }
        if (doc.Invoice) {
          this.shipmentDetails.Invoice = doc.docType === 'INVOICE' ? doc : null;
          this.shipmentDetails.VendorBill = doc.docType === 'VENDOR_BILL' ? doc : null;
          this.shipmentDetails.shipmentCurrency = doc.Invoice.invoiceStatus !== 'DRAFT' ? doc.Invoice.currency : null;
          this.shipmentDetails.shipmentValue = doc.Invoice.invoiceStatus !== 'DRAFT' ? doc.Invoice.totalAmount : 0;
          this.shipmetDetailService.updateShipmentDetails(this.shipmentDetails);
          this.shouldShareInvoice = true;
          this.shouldShareVendorBill = true;
          this.alreadyLinkedInvIds.push(doc.Invoice.invNo);
        }
        if (doc.ShippingBill) {
          this.shipmentDetails.ShippingBill = doc;
          this.shouldShareShippingBill = true;
        }
        if (doc.BillOfLading) {
          this.shipmentDetails.BillOfLading = doc;
          this.shouldShareBL = true;
        }
        if (doc.BillOfEntry) {
          this.shipmentDetails.BillOfEntry = doc;
          this.shouldShareBOE = true;
        }
        this.shipmentDocumentLink = {};
        this.shipmentDocumentLink.docType = 'default';
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /**
   * Fn to unlink the smart link document or delete the files uploaded inside folders
   * @param docId document Id to be unlinked
   * @param docType document type to be unlinked
   * @param folderIndex Index of folder
   * @param fileIndex index of file to be deleted from selected folder
   */
  unlinkDocument(docId, docType, folderIndex, fileIndex): void {
    swal({
      title: folderIndex !== null && fileIndex !== null ? `Delete ${docType}` : `Unlink ${docType}`,
      text: 'This cannot be undone',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: folderIndex !== null && fileIndex !== null ? 'Delete' : 'Unlink',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        this.shouldShowLoader = true;
        return new Promise((resolve, reject) => {
          this.orgShipmentApi.unlinkShipmentDocument(
            this.shipmentDetails.shipmentId,
            docId
          ).subscribe(doc => {
            this.shouldShowLoader = false;
            if (folderIndex !== null && fileIndex !== null) {
              this.shipmentDetails.ShipmentDocumentFolders[folderIndex].ShipmentDocuments.splice(fileIndex, 1);
              this.shouldShareFiles[folderIndex][fileIndex] = false;
            } else {
              if (docType === 'PurchaseOrder') {
                const index = this.shipmentDetails.PurchaseOrders.findIndex(i => i.shipmentDocumentId == docId);
                this.shipmentDetails.PurchaseOrders.splice(index, 1);
                this.shouldSharePO = this.shipmentDetails.PurchaseOrders.length > 0 ? true : false;
              }
              if (docType === 'SalesOrder') {
                const index = this.shipmentDetails.SalesOrders.findIndex(i => i.shipmentDocumentId == docId);
                this.shipmentDetails.SalesOrders.splice(index, 1);
                this.shouldShareSO = this.shipmentDetails.SalesOrders.length > 0 ? true : false;
              }
              if (docType === 'Invoice') {
                this.shipmentDetails.Invoice = null;
                this.shouldShareInvoice = false;
              }
              if (docType === 'VendorBill') {
                this.shipmentDetails.VendorBill = null;
                this.shouldShareVendorBill = false;
              }
              if (docType === 'ShippingBill') {
                this.shipmentDetails.ShippingBill = null;
                this.shouldShareShippingBill = false;
              }
              if (docType === 'BillOfLading') {
                this.shipmentDetails.BillOfLading = null;
                this.shouldShareBL = false;
              }
              if (docType === 'BillOfEntry') {
                this.shipmentDetails.BillOfEntry = null;
                this.shouldShareBOE = false;
              }
            }
            if (doc) {
              $('#fileAddModal').modal('hide');
              this.shipmentDetails.ShipmentDialogues.push(doc);
            }
            $('#fileViewModal').modal('hide');
            const files = this.shipmentDetails.ShipmentDocumentFolders.filter(i => i.ShipmentDocuments.length > 0);
            if (files && files.length === 0) {
              this.shipmetDetailService.updateFileAvaStatus(false);
            }
            resolve(doc);
          }, err => {
            this.shouldShowLoader = false;
            reject(err);
          });
        });
      },
      allowOutsideClick: () => !swal.isLoading()
    }).then((result) => {
      if (result.value) {
        SwalHelper.successTimerSwal('Document Unlinked');
      }
    }).catch(err => {
      SwalHelper.showErrorSwal(err);
    });
  }

  /**
   * Fn to get the the rendered template of the requested document and attachments linked to it
   * @param docType string type of document
   * @param templateName string name of the template
   * @param pkName string name of the primary key like invId
   * @param pkId number value of primary key
   * @param documentId number primary key of document id
   * @param documentName string name of the document like Bill Of Lading, Invoice etc.
   * @param status string status of the document
   */
  linkedDocumentDetails(docType: string, templateName: string, pkName: string, pkId: number, documentId: number, documentName: string, status: string): void {
    templateName = templateName.match(/(\d+)/)[0];
    const headers = new HttpHeaders().set('Authorization', this.orgContactApi.configuration.apiKeys.Authorization);
    this.shouldShowLoader = true;
    const body = {};
    body[`${pkName}`] = pkId;
    this.currentOpenDocument.documentId = documentId;
    this.currentOpenDocument.docType = docType;
    this.currentOpenDocument.pkId = pkId;
    this.currentOpenDocument.pkName = pkName;
    this.currentOpenDocument.documentName = documentName;
    this.currentOpenDocument.status = status;
    const url = this.profileSync.user && this.profileSync.user.orgId === this.shipmentDetails.orgId ? `${this.basePath}/organisation/templates/${docType}/${templateName}` : `${this.basePath}/orgContact/templates/${docType}/${templateName}?shipmentId=${this.shipmentDetails.shipmentId}`;
    this.http.post(url, body, {
      responseType: 'text',
      headers,
      observe: 'body'
    }).subscribe(data => {
      this.shouldShowLoader = false;
      this.outputInvoice = data;
      this.http.post<Array<DocumentAttachment>>(`${this.basePath}/orgContact/list_document_attachment?shipmentId=${this.shipmentDetails.shipmentId}`, body, {
        headers,
        observe: 'body',
      }).subscribe(attchmentData => {
        this.documentAttachments = attchmentData;
        $('#fileViewModal').modal('show');
      }, err => {
        this.shouldShowLoader = false;
        SwalHelper.showErrorSwal(err);
      });
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /**
   * Fn to get the rendered invoice template based on the user selection
   * @param event type of tab selected like customs invoice, customs packingList
   */
  invoiceTabChange(event): void {
    this.linkedDocumentDetails(event.target.value, '1', this.currentOpenDocument.pkName, this.currentOpenDocument.pkId, this.currentOpenDocument.documentId, this.currentOpenDocument.documentName, this.currentOpenDocument.status);
  }

  public downloadDocumentFile(docAttId: number): void {
    this.orgContactApi.getDocumentSignedUrl(
      this.shipmentDetails.shipmentUid,
      docAttId)
      .subscribe(url => {
        window.open(url.url, '_blank');
      });
  }

  /* Fn to add the new Shipment folder */
  addNewShipmentFolder(): void {
    this.shouldShowLoader = true;
    if (this.newShipmentFolder.folderId) {
      this.orgContactApi.renameFolder(
        this.newShipmentFolder.folderId,
        this.shipmentDetails.shipmentId,
        this.newShipmentFolder.folderName
      ).subscribe(folder => {
        this.shouldShowLoader = false;
        const index = this.shipmentDetails.ShipmentDocumentFolders.findIndex(i => i.folderId === this.newShipmentFolder.folderId);
        this.shipmentDetails.ShipmentDocumentFolders[index].folderName = this.newShipmentFolder.folderName;
        $('#groupModal').modal('hide');
        SwalHelper.successTimerSwal('Folder Updated');
        this.newShipmentFolder = {};
      }, err => {
        this.shouldShowLoader = false;
        SwalHelper.showErrorSwal(err);
      });
    } else {
      this.orgContactApi.addFolder(
        this.shipmentDetails.shipmentId,
        this.newShipmentFolder.folderName
      ).subscribe(folder => {
        this.shouldShowLoader = false;
        this.newShipmentFolder = {};
        this.shipmentDetails.ShipmentDocumentFolders.push(folder);
        SwalHelper.successTimerSwal('Folder created');
        $('#groupModal').modal('hide');
      }, err => {
        this.shouldShowLoader = false;
        SwalHelper.showErrorSwal(err);
      });
    }
  }

  /**
   * Fn to open the folder modal
   * @param index Index of folder to be updated
   */
  editFolderName(index: number): void {
    this.newShipmentFolder = JSON.parse(JSON.stringify(this.shipmentDetails.ShipmentDocumentFolders[index]));
    $('#groupModal').modal('show');
  }

  /**
   * Fn to Add the files in selected folder
   * @param index Index of folder where files to be upload
   */
  AddFolderSpecificFile(index: number): void {
    this.newShipmentDocument.folderId = this.shipmentDetails.ShipmentDocumentFolders[index].folderId;
    this.newShipmentDocument.docType = this.shipmentDetails.ShipmentDocumentFolders[index].folderType;
    this.shipmentDocumentFiles = [];
    this.folderFileInput.nativeElement.value = '';
  }

  /* Fn to upload attachment files to selected folder */
  uplaodNewDocument(): void {
    this.shouldShowLoader = true;
    this.orgContactApi.uploadFolderAttachment(
      this.shipmentDetails.shipmentId,
      this.newShipmentDocument.folderId,
      this.newShipmentDocument.docType ? this.newShipmentDocument.docType : 'OTHER',
      this.shipmentDocumentFiles[0],
      this.shipmentDocumentFiles[1],
      this.shipmentDocumentFiles[2],
      this.shipmentDocumentFiles[3],
      this.shipmentDocumentFiles[4]
    ).subscribe(docs => {
      if (docs) {
        this.shouldShowLoader = false;
        SwalHelper.successTimerSwal('Document uploaded');
        $('#fileUploadModal').modal('hide');
        $('fileDragUploadModal').modal('hide');
        const index = this.shipmentDetails.ShipmentDocumentFolders.findIndex(i => i.folderId == this.newShipmentDocument.folderId);
        this.shipmentDetails.ShipmentDocumentFolders[index] = docs;
        this.shouldShareFiles[index] = [true];
        this.newShipmentDocument = {};
        this.shipmentDocumentFiles = []; // Clean the document file array
        this.shipmetDetailService.updateFileAvaStatus(true);
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /* Fn to get the signed URL & download the selected folder file */
  public downloadFile(shipmentDocumentId: number): void {
    this.orgContactApi.getSignedUrl(this.shipmentDetails.shipmentId, shipmentDocumentId)
      .pipe(take(1))
      .subscribe(url => {
        window.open(url.url, '_blank');
      });
  }

  /* Fn to export the selected document in pdf format */
  public exportDocument(): void {
    this.shouldShowLoader = true;
    const cusObj: CustomExportObject = {};
    cusObj[this.currentOpenDocument.pkName] = this.currentOpenDocument.pkId;
    cusObj.type = this.currentOpenDocument.docType;
    if (this.profileSync.user && this.profileSync.user.orgId === this.shipmentDetails.orgId) {
      this.orgApi
        .exportDocument(cusObj).subscribe(res => {
          this.shouldShowLoader = false;
          saveAs(res, (`${this.currentOpenDocument.docType}.pdf`));
        }, err => {
          this.shouldShowLoader = false;
          SwalHelper.showErrorSwal(err);
        });
    } else {
      this.orgContactApi
        .exportOrgContactDocument(cusObj).subscribe(res => {
          this.shouldShowLoader = false;
          saveAs(res, (`${this.currentOpenDocument.docType}.pdf`));
        }, err => {
          this.shouldShowLoader = false;
          SwalHelper.showErrorSwal(err);
        });
    }
  }

  /* Fn to redirect to edit page of selected document */
  public redirectToEdit(): void {
    $('#fileViewModal').modal('hide');
    if (this.currentOpenDocument.docType === 'PurchaseOrder') {
      this.router.navigate([`/organisation/purchase-orders/update-purchase-order/${this.currentOpenDocument.pkId}`]);
    } else if (this.currentOpenDocument.docType === 'SalesOrder') {
      this.router.navigate([`/organisation/sales-orders/update-sales-order/${this.currentOpenDocument.pkId}`]);
    } else if (this.currentOpenDocument.docType === 'Invoice') {
      this.router.navigate([`/organisation/invoices/edit-invoice/${this.currentOpenDocument.pkId}`]);
    } else if (this.currentOpenDocument.docType === 'VendorBill') {
      this.router.navigate([`/organisation/vendor-bills/edit-vendor-bill/${this.currentOpenDocument.pkId}`]);
    } else if (this.currentOpenDocument.docType === 'ShippingBill') {
      this.router.navigate([`/organisation/shipment/shipment-documents/shipping-bill/edit-shipping-bill/${this.currentOpenDocument.pkId}`]);
    } else if (this.currentOpenDocument.docType === 'BillOfLading') {
      // tslint:disable-next-line: max-line-length
      this.router.navigate([`/organisation/shipment/shipment-documents/bill-of-lading/edit-bill-of-lading/${this.currentOpenDocument.pkId}`]);
    } else if (this.currentOpenDocument.docType === 'BillOfEntry') {
      this.router.navigate([`/organisation/shipment/shipment-documents/bill-of-entry/edit-bill-of-entry/${this.currentOpenDocument.pkId}`]);
    }
  }

  ngOnDestroy(): void {
    if (this.orgContactSubscription) { this.orgContactSubscription.unsubscribe(); }
    if (this.poSubscription) { this.poSubscription.unsubscribe(); }
    if (this.soSubscription) { this.soSubscription.unsubscribe(); }
    if (this.invSubscription) { this.invSubscription.unsubscribe(); }
    if (this.shippingBillSubscription) { this.shippingBillSubscription.unsubscribe(); }
    if (this.boeSubscription) { this.boeSubscription.unsubscribe(); }
    if (this.bolSubscription) { this.bolSubscription.unsubscribe(); }
  }

  /**
   * checks if this access is given to the user or not
   */
  checkAccess(documentId: number, docType: string): boolean {
    let returnValue = false;
    if (this.shipmentAccess.allDocumentAccess) {
      return true;
    } else if (this.shipmentAccess && this.shipmentAccess.OrganisationContact) {
      if (docType === 'PurchaseOrder') {
        const docIds = this.shipmentDetails.PurchaseOrders.map(i => i.shipmentDocumentId);
        if (this.shipmentAccess.ShipmentDocumentAccesses.filter(i => docIds.includes(i.shipmentDocumentId)).length > 0) {
          returnValue = true;
        }
      } else if (docType === 'SalesOrder') {
        const docIds = this.shipmentDetails.SalesOrders.map(i => i.shipmentDocumentId);
        if (this.shipmentAccess.ShipmentDocumentAccesses.filter(i => docIds.includes(i.shipmentDocumentId)).length > 0) {
          returnValue = true;
        }
      } else {
        returnValue = this.shipmentAccess.ShipmentDocumentAccesses.filter(i => i.shipmentDocumentId === documentId).length > 0;
      }
      return returnValue;
    } else {
      return true;
    }
  }
}
