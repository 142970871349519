import { BulkUploadInvoiceComponent } from './bulk-upload/bulk-upload-invoice/bulk-upload-invoice.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { OrganisationComponent } from './organisation.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { OrganisationRolesComponent } from './organisation-roles/organisation-roles.component';
import { OrganisationContactsComponent } from './organisation-contacts/organisation-contacts.component';
import { OrganisationProfileComponent } from './organisation-profile/organisation-profile.component';
import { OrganisationUsersComponent } from './organisation-users/organisation-users.component';
import { PurchaseOrderDetailsComponent } from './purchase-order/purchase-order-details/purchase-order-details.component';
import { PurchaseOrderComponent } from './purchase-order/purchase-order.component';
import { PurchaseOrderUpdateComponent } from './purchase-order/purchase-order-update/purchase-order-update.component';
import { SalesOrderComponent } from './sales-order/sales-order.component';
import { SalesOrderDetailsComponent } from './sales-order/sales-order-details/sales-order-details.component';
import { SalesOrderUpdateComponent } from './sales-order/sales-order-update/sales-order-update.component';
import { InvoiceComponent } from './invoice/invoice.component';
import { ListShipmentsComponent } from './list-shipments/list-shipments.component';
import { InvoiceUpdatesComponent } from './invoice/invoice-updates/invoice-updates.component';
import { InvoiceDetailsComponent } from './invoice/invoice-details/invoice-details.component';
import { LicenseDetailsComponent } from './dgft/licenses/license-details/license-details.component';
import { LicensesComponent } from './dgft/licenses/licenses.component';
import { MasterComponent } from './master/master.component';
import { OrganisationItemMastersComponent } from './master/organisation-item-masters/organisation-item-masters.component';
import { CustomFieldsComponent } from './master/custom-fields/custom-fields.component';
import { InvoiceCustomFieldsComponent } from './master/custom-fields/invoice-custom-fields/invoice-custom-fields.component';
import { OrderCustomFieldsComponent } from './master/custom-fields/order-custom-fields/order-custom-fields.component';
import { OrderTemplatesComponent } from './master/templates/order-templates/order-templates.component';
import { EditTemplateComponent } from './master/templates/edit-template/edit-template.component';
import { ServiceProvidersComponent } from './service-providers/service-providers.component';
import { ComingSoonComponent } from '../coming-soon/coming-soon.component';
import { CanDeactivateGuardGuard } from '../form-route-deactivate/can-deactivate-guard.guard';
import { ChangePasswordSettingComponent } from '../auth/change-password-setting/change-password-setting.component';
import { VendorBillUpdatesComponent } from './vendor-bill/vendor-bill-updates/vendor-bill-updates.component';
import { VendorBillDetailsComponent } from './vendor-bill/vendor-bill-details/vendor-bill-details.component';
import { VendorBillComponent } from './vendor-bill/vendor-bill.component';
import { VendorBillCustomFieldsComponent } from './master/custom-fields/vendor-bill-custom-fields/vendor-bill-custom-fields.component';
import { ReportsComponent } from './reports/reports.component';
import { ShipmentReportComponent } from './reports/shipment-report/shipment-report.component';
import { PurchaseOrderReportComponent } from './reports/purchase-order-report/purchase-order-report.component';
import { SalesOrderReportComponent } from './reports/sales-order-report/sales-order-report.component';
import { VendorBillReportComponent } from './reports/vendor-bill-report/vendor-bill-report.component';
import { InvoiceReportComponent } from './reports/invoice-report/invoice-report.component';
import { PoCustomFieldsComponent } from './master/custom-fields/po-custom-fields/po-custom-fields.component';
import { ShipmentTrackingComponent } from './shipment-tracking/shipment-tracking.component';
import { OrderReconReportComponent } from './reports/order-recon-report/order-recon-report.component';
import { UserPreferenceComponent } from './user-preference/user-preference.component';
import { MyWorkspaceComponent } from './dashboard/my-workspace/my-workspace.component';
import { ShipmentExpensesComponent } from '../shipment/shipment-expenses/shipment-expenses.component';
import { ExpenseAnalysisComponent } from './reports/expense-analysis/expense-analysis.component';
import { RevenueAnalysisComponent } from './reports/revenue-analysis/revenue-analysis.component';
import { MandatoryFieldsComponent } from './master/mandatory-fields/mandatory-fields.component';

const routes: Routes = [
  {
    path: 'organisation',
    component: OrganisationComponent,
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
      {
        path: 'workspace',
        component: MyWorkspaceComponent
      },
      {
        path: 'bulk-excel-upload',
        component: BulkUploadInvoiceComponent
      },
      {
        path: 'coming-soon',
        component: ComingSoonComponent
      },
      {
        path: 'change-password-setting',
        component: ChangePasswordSettingComponent
      },
      {
        path: 'dashboard',
        component: DashboardComponent
      },
      {
        path: 'invoices',
        component: InvoiceComponent,
      },
      {
        path: 'invoices/add-invoice',
        component: InvoiceUpdatesComponent
      },
      {
        path: 'invoices/edit-invoice/:invId',
        component: InvoiceUpdatesComponent,
        canDeactivate: [CanDeactivateGuardGuard]
      },
      {
        path: 'invoices/invoice-details/:invId',
        component: InvoiceDetailsComponent
      },
      {
        path: 'vendor-bills',
        component: VendorBillComponent,
      },
      {
        path: 'vendor-bills/add-vendor-bill',
        component: VendorBillUpdatesComponent
      },
      {
        path: 'vendor-bills/edit-vendor-bill/:invId',
        component: VendorBillUpdatesComponent,
        canDeactivate: [CanDeactivateGuardGuard]
      },
      {
        path: 'vendor-bills/vendor-bill-details/:invId',
        component: VendorBillDetailsComponent
      },
      {
        path: 'shipment-tracking',
        component: ShipmentTrackingComponent,
      },
      {
        path: 'contacts',
        component: OrganisationContactsComponent
      },
      {
        path: 'profile',
        component: OrganisationProfileComponent,
      },
      {
        path: 'roles-permissions',
        children: [{
          path: '',
          redirectTo: 'roles',
          pathMatch: 'full'
        },
        {
          path: 'users',
          component: OrganisationUsersComponent
        },
        {
          path: 'roles',
          component: OrganisationRolesComponent
        },
        ]
      },
      {
        path: 'purchase-orders',
        children: [{
          path: '',
          component: PurchaseOrderComponent,
        }, {
          path: 'purchase-order-detail/:poId',
          component: PurchaseOrderDetailsComponent
        },
        {
          path: 'add-purchase-order',
          component: PurchaseOrderUpdateComponent
        },
        {
          path: 'update-purchase-order/:poId',
          component: PurchaseOrderUpdateComponent
        }]
      },
      {
        path: 'sales-orders',
        children: [{
          path: '',
          component: SalesOrderComponent,
        }, {
          path: 'sales-order-detail/:soId',
          component: SalesOrderDetailsComponent
        },
        {
          path: 'add-sales-order',
          component: SalesOrderUpdateComponent
        },
        {
          path: 'update-sales-order/:soId',
          component: SalesOrderUpdateComponent
        }]
      },
      {
        path: 'list-shipments',
        component: ListShipmentsComponent,
      },
      {
        path: 'shipment',
        loadChildren: () => import('../shipment/shipment.module')
          .then(m => m.ShipmentModule)
      },
      {
        path: 'dgft',
        children: [
          {
            path: '',
            component: LicensesComponent,
          },
          {
            path: 'license-details/:licenseId',
            component: LicenseDetailsComponent,
          },
        ]
      },
      {
        path: 'master',
        component: MasterComponent,
        children: [
          {
            path: '',
            redirectTo: 'item-masters',
            pathMatch: 'full'
          },
          {
            path: 'item-masters',
            component: OrganisationItemMastersComponent
          },
          {
            path: 'preferences',
            component: UserPreferenceComponent
          },
          {
            path: 'template-master/edit-template/:type/:id',
            component: EditTemplateComponent
          },
          {
            path: 'template-master',
            component: OrderTemplatesComponent,
            /* children: [
              {
                path: '',
                redirectTo: 'order-templates',
                pathMatch: 'full',
              },
              {
                path: 'invoice-templates',
                component: InvoiceTemplatesComponent
              },
              {
                path: 'order-templates',
                component: OrderTemplatesComponent
              },
            ] */
          },
          {
            path: 'mandatory-fields',
            component: MandatoryFieldsComponent,
          },
          {
            path: 'custom-fields',
            component: CustomFieldsComponent,
            children: [
              {
                path: '',
                redirectTo: 'invoice-fields',
                pathMatch: 'full'
              },
              {
                path: 'invoice-fields',
                component: InvoiceCustomFieldsComponent
              },
              {
                path: 'vendor-bill-fields',
                component: VendorBillCustomFieldsComponent
              },
              {
                path: 'so-order-fields',
                component: OrderCustomFieldsComponent
              },
              {
                path: 'po-order-fields',
                component: PoCustomFieldsComponent
              }
            ]
          },
          // {
          //   path: 'items-column',
          //   component: ItemsCustomFieldsComponent,
          //   children: [
          //     {
          //       path: '',
          //       redirectTo: 'invoice-item-column',
          //       pathMatch: 'full'
          //     },
          //     {
          //       path: 'invoice-item-column',
          //       component: InvoiceItemsComponent
          //     },
          //     {
          //       path: 'order-item-column',
          //       component: OrderItemsComponent
          //     }
          //   ]
          // },
        ]
      },
      {
        path: 'service-providers',
        component: ServiceProvidersComponent
      },
      {
        path: 'reports',
        component: ReportsComponent,
      },
      {
        path: 'reports/shipment',
        component: ShipmentReportComponent,
      },
      {
        path: 'reports/invoice',
        component: InvoiceReportComponent,
      },
      {
        path: 'reports/vendor-bill',
        component: VendorBillReportComponent,
      },
      {
        path: 'reports/sales-order',
        component: SalesOrderReportComponent,
      },
      {
        path: 'reports/purchase-order',
        component: PurchaseOrderReportComponent,
      },
      {
        path: 'reports/order-recon',
        component: OrderReconReportComponent,
      },
      {
        path: 'reports/expense-tracker',
        component: ShipmentExpensesComponent
      },
      {
        path: 'reports/expense-analysis',
        component: ExpenseAnalysisComponent
      },
      {
        path: 'reports/revenue-analysis',
        component: RevenueAnalysisComponent
      },
    ]
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OrganisationRoutingModule { }
