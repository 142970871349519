/**
 * Nuphi trade
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2.0.0
 * Contact: shraddha@blackcurrantapps.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ExpenseTracker } from './expenseTracker';
import { Organisation } from './organisation';
import { OrganisationContact } from './organisationContact';
import { ShipmentAccess } from './shipmentAccess';
import { ShipmentChecklist } from './shipmentChecklist';
import { ShipmentDialogue } from './shipmentDialogue';
import { ShipmentDocument } from './shipmentDocument';
import { ShipmentDocumentFolder } from './shipmentDocumentFolder';
import { ShipmentTracking } from './shipmentTracking';
import { User } from './user';


export interface Shipment { 
    shipmentId?: number;
    orgId?: number;
    shipmentUid?: string;
    shipmentNo?: string;
    shipmentNoSuf?: string;
    shipmentNoPre?: string;
    mode?: string;
    createdBy?: string;
    shipmentType?: Shipment.ShipmentTypeEnum;
    isNuPhiFinance?: boolean;
    expiryInDays?: number;
    sellerId?: number;
    buyerId?: number;
    companyName?: string;
    shipmentStatus?: Shipment.ShipmentStatusEnum;
    shipmentDate?: string;
    shipmentETD?: string;
    shipmentETA?: string;
    shipmentValue?: number;
    shipmentCurrency?: string;
    shipmentTags?: any;
    PurchaseOrders?: Array<any>;
    SalesOrders?: Array<any>;
    Invoice?: any;
    VendorBill?: any;
    ShippingBill?: any;
    BillOfLading?: any;
    BillOfEntry?: any;
    Others?: Array<any>;
    Certificates?: Array<any>;
    COO?: any;
    EBRC?: any;
    Insurance?: any;
    Organisation?: Organisation;
    User?: User;
    Seller?: OrganisationContact;
    Buyer?: OrganisationContact;
    ShipmentTracking?: ShipmentTracking;
    ExpenseTracker?: ExpenseTracker;
    ShipmentDialogues?: Array<ShipmentDialogue>;
    ShipmentAccesses?: Array<ShipmentAccess>;
    ShipmentDocumentFolders?: Array<ShipmentDocumentFolder>;
    ShipmentChecklists?: Array<ShipmentChecklist>;
    ShipmentDocuments?: Array<ShipmentDocument>;
}
export namespace Shipment {
    export type ShipmentTypeEnum = 'BUY' | 'SELL';
    export const ShipmentTypeEnum = {
        BUY: 'BUY' as ShipmentTypeEnum,
        SELL: 'SELL' as ShipmentTypeEnum
    };
    export type ShipmentStatusEnum = 'DRAFT' | 'ORDER' | 'INVOICE' | 'IN_TRANSIT' | 'DELIVERED' | 'RECONCILED' | 'CLOSED' | 'DISPUTED' | 'ARCHIVED';
    export const ShipmentStatusEnum = {
        DRAFT: 'DRAFT' as ShipmentStatusEnum,
        ORDER: 'ORDER' as ShipmentStatusEnum,
        INVOICE: 'INVOICE' as ShipmentStatusEnum,
        INTRANSIT: 'IN_TRANSIT' as ShipmentStatusEnum,
        DELIVERED: 'DELIVERED' as ShipmentStatusEnum,
        RECONCILED: 'RECONCILED' as ShipmentStatusEnum,
        CLOSED: 'CLOSED' as ShipmentStatusEnum,
        DISPUTED: 'DISPUTED' as ShipmentStatusEnum,
        ARCHIVED: 'ARCHIVED' as ShipmentStatusEnum
    };
}
