/**
 * Nuphi trade
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2.0.0
 * Contact: shraddha@blackcurrantapps.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Organisation } from './organisation';
import { Shipment } from './shipment';


export interface ShipmentTracking { 
    shipmentTrackingId?: number;
    shipmentId?: number;
    orgId?: number;
    shipmentTrackingUid?: string;
    containerNo?: string;
    provider?: ShipmentTracking.ProviderEnum;
    isTrackingEnabled?: boolean;
    shipmentTrackingETA?: string;
    shipmentTrackingLocation?: string;
    screenShotUrl?: string;
    shipmentETA?: string;
    shipmentTracking?: any;
    Organisation?: Organisation;
    Shipment?: Shipment;
}
export namespace ShipmentTracking {
    export type ProviderEnum = 'MSC' | 'MAERSK' | 'OTHER';
    export const ProviderEnum = {
        MSC: 'MSC' as ProviderEnum,
        MAERSK: 'MAERSK' as ProviderEnum,
        OTHER: 'OTHER' as ProviderEnum
    };
}
