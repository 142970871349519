/**
 * Nuphi trade
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2.0.0
 * Contact: shraddha@blackcurrantapps.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { Shipment } from '../model/shipment';
import { User } from '../model/user';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

    protected basePath = 'http://localhost:3000';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * checks for match with current password and sets a new provided password
     * change users password from settings
     * @param oldPassword 
     * @param newPassword 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public changePasswordSetting(oldPassword: string, newPassword: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public changePasswordSetting(oldPassword: string, newPassword: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public changePasswordSetting(oldPassword: string, newPassword: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public changePasswordSetting(oldPassword: string, newPassword: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (oldPassword === null || oldPassword === undefined) {
            throw new Error('Required parameter oldPassword was null or undefined when calling changePasswordSetting.');
        }

        if (newPassword === null || newPassword === undefined) {
            throw new Error('Required parameter newPassword was null or undefined when calling changePasswordSetting.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (oldPassword !== undefined && oldPassword !== null) {
            queryParameters = queryParameters.set('oldPassword', <any>oldPassword);
        }
        if (newPassword !== undefined && newPassword !== null) {
            queryParameters = queryParameters.set('newPassword', <any>newPassword);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<any>(`${this.basePath}/auth/change_password`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Only non-expired shares
     * Get shipments shared with me
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public extShipmentsSharedWithMe(observe?: 'body', reportProgress?: boolean): Observable<Array<Shipment>>;
    public extShipmentsSharedWithMe(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Shipment>>>;
    public extShipmentsSharedWithMe(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Shipment>>>;
    public extShipmentsSharedWithMe(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<Shipment>>(`${this.basePath}/auth/ext_shipments_shared_with_me`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get user&#39;s profile
     * Get profile of requested user
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProfile(observe?: 'body', reportProgress?: boolean): Observable<User>;
    public getProfile(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<User>>;
    public getProfile(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<User>>;
    public getProfile(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<User>(`${this.basePath}/auth/get_profile`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get user&#39;s profile
     * Get HTMl rendered file of the requested Invoice
     * @param invUid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public invoiceDetails(invUid: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public invoiceDetails(invUid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public invoiceDetails(invUid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public invoiceDetails(invUid: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (invUid === null || invUid === undefined) {
            throw new Error('Required parameter invUid was null or undefined when calling invoiceDetails.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (invUid !== undefined && invUid !== null) {
            queryParameters = queryParameters.set('invUid', <any>invUid);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/html',
            'text/plain',
            'application/xhtml+xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<any>(`${this.basePath}/auth/invoice_details`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Only non-expired shares
     * Get shipments shared with me
     * @param startDate 
     * @param endDate 
     * @param status 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public serviceProviderSharedShipments(startDate?: string, endDate?: string, status?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Shipment>>;
    public serviceProviderSharedShipments(startDate?: string, endDate?: string, status?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Shipment>>>;
    public serviceProviderSharedShipments(startDate?: string, endDate?: string, status?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Shipment>>>;
    public serviceProviderSharedShipments(startDate?: string, endDate?: string, status?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (startDate !== undefined && startDate !== null) {
            queryParameters = queryParameters.set('startDate', <any>startDate);
        }
        if (endDate !== undefined && endDate !== null) {
            queryParameters = queryParameters.set('endDate', <any>endDate);
        }
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', <any>status);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<Shipment>>(`${this.basePath}/auth/service_provider_shared_shipments`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * update the profile of a user
     * update profile of a user
     * @param User 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProfile(User: User, observe?: 'body', reportProgress?: boolean): Observable<User>;
    public updateProfile(User: User, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<User>>;
    public updateProfile(User: User, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<User>>;
    public updateProfile(User: User, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (User === null || User === undefined) {
            throw new Error('Required parameter User was null or undefined when calling updateProfile.');
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<User>(`${this.basePath}/auth/get_profile`,
            User,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
