import { BillOfEntryComponent } from './shipment-documents/bill-of-entry/bill-of-entry.component';
import { ShippingBillUpdateComponent } from './shipment-documents/shipping-bill/shipping-bill-update/shipping-bill-update.component';
import { ShippingBillComponent } from './shipment-documents/shipping-bill/shipping-bill.component';
import { BillOfLadingUpdateComponent } from './shipment-documents/bill-of-lading/bill-of-lading-update/bill-of-lading-update.component';
import { BillOfLadingComponent } from './shipment-documents/bill-of-lading/bill-of-lading.component';
import { OrganisationModule } from './../organisation/organisation.module';
import { ShipmentExpiredComponent } from './shipment-expired/shipment-expired.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { ShipmentRoutingModule } from './shipment-routing.module';
import { ShipmentDetailsComponent } from './shipment-details/shipment-details.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatSelectModule} from '@angular/material/select';
import { NouisliderModule } from 'ng2-nouislider';
import { HelperModuleModule } from '../helper-module/helper-module.module';
import { ShipmentShareDocumentComponent } from './shipment-details/shipment-share-document/shipment-share-document.component';
import { ShipmentChatComponent } from './shipment-details/shipment-chat/shipment-chat.component';
import { ShipmentDocumentsComponent } from './shipment-documents/shipment-documents.component';
import { DataTablesModule } from 'angular-datatables';
import { BillOfEntryUpdateComponent } from './shipment-documents/bill-of-entry/bill-of-entry-update/bill-of-entry-update.component';
import { BillOfLadingDetailsComponent } from './shipment-documents/bill-of-lading/bill-of-lading-details/bill-of-lading-details.component';
import { BillOfEntryDetailsComponent } from './shipment-documents/bill-of-entry/bill-of-entry-details/bill-of-entry-details.component';
import { ShippingBillDetailsComponent } from './shipment-documents/shipping-bill/shipping-bill-details/shipping-bill-details.component';
import { OnboardingModule } from '../onboarding/onboarding.module';
import { ShipmentTrackingComponent } from './shipment-details/shipment-tracking/shipment-tracking.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ShipmentExpensesComponent } from './shipment-expenses/shipment-expenses.component';
import { ShipmentExpenseDetailsComponent } from './shipment-expenses/shipment-expense-details/shipment-expense-details.component';
import { EditorModule } from '@tinymce/tinymce-angular';

@NgModule({
  declarations: [
    BillOfLadingComponent,
    BillOfLadingUpdateComponent,
    ShippingBillComponent,
    ShippingBillUpdateComponent,
    BillOfEntryComponent,
    ShipmentDetailsComponent,
    ShipmentChatComponent,
    ShipmentExpiredComponent,
    ShipmentShareDocumentComponent,
    ShipmentDocumentsComponent,
    BillOfEntryUpdateComponent,
    BillOfLadingDetailsComponent,
    BillOfEntryDetailsComponent,
    ShippingBillDetailsComponent,
    ShipmentTrackingComponent,
    ShipmentExpensesComponent,
    ShipmentExpenseDetailsComponent,
    ],
  imports: [
    CommonModule,
    ShipmentRoutingModule,
    MatAutocompleteModule,
    FormsModule,
    ReactiveFormsModule,
    HelperModuleModule,
    MatSelectModule,
    NouisliderModule,
    OrganisationModule,
    NgSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    InfiniteScrollModule,
    DataTablesModule,
    OnboardingModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    EditorModule
  ]
})
export class ShipmentModule { }
