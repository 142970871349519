import { Component, OnInit, Renderer2, HostListener, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PurchaseOrder, OrganisationService, OrganisationContact, OrgOrdersService, StatusHistory, CustomExportObject } from 'src/swagger';
import { SwalHelper } from 'src/app/swal';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { environment } from 'src/environments/environment';
import { OrganisationHelperService } from '../../organisation-helper.service';
import { GlobalProfileService } from 'src/app/global-profile.service';
import { CommonSupportService } from '../../../common-support.service';
import { Subscription, Subject, of } from 'rxjs';
import { OrgShipmentsService } from '../../../../swagger/api/orgShipments.service';
import { Shipment } from '../../../../swagger/model/shipment';
import { ShipmentDocument } from '../../../../swagger/model/shipmentDocument';
import { debounceTime, switchMap, tap, catchError } from 'rxjs/operators';
declare var $: any;
declare var Swal: any;

@Component({
  selector: 'app-purchase-order-details',
  templateUrl: './purchase-order-details.component.html',
  styleUrls: ['./purchase-order-details.component.scss']
})
export class PurchaseOrderDetailsComponent implements OnInit, OnDestroy {

  public purchaseOrderDetails: PurchaseOrder = {};
  public shouldShowLoader: boolean;

  public outputInvoice: any;
  public basePath: string;
  public rightSheet = false;
  public statusPercentage: string;

  public emails = [];
  public contactUsers: OrganisationContact[] = [];
  public statusHistory: StatusHistory = {};
  public statusEnum = PurchaseOrder.StatusEnum;
  public totalRemainingQnty = 0;
  public totalQuantity = 0;
  public pendingValue = 0;

  public shouldShareUser: boolean[] = [false];
  private userSubscription: Subscription;

  public shipmentDocumentLink: ShipmentDocument = {}; // For Linking Purpose

  public shipmentChange$: Subject<string> = new Subject();
  public listShipment$: Subject<Shipment[]> = new Subject();
  public alreadyLinkedShipIds = [];
  public shipmentLoading = false;
  private shipmentSubscription: Subscription;

  constructor(
    private activatedRoute: ActivatedRoute,
    private orgOrderApi: OrgOrdersService,
    private orgApi: OrganisationService,
    public http: HttpClient,
    public profileSync: GlobalProfileService,
    private orgServiceHelper: OrganisationHelperService,
    private router: Router,
    private renderer: Renderer2,
    private commonService: CommonSupportService,
    public orgShipmentApi: OrgShipmentsService
  ) {
    this.basePath = environment.BASE_PATH;
    commonService.renderer = renderer;
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent): void {
    this.rightSheet = false;
    this.renderer.removeClass(document.body, 'modal-open');
  }
  ngOnInit(): void {
    this.activatedRoute.params.subscribe(param => {
      if (param && param.poId) {
        this.fetchPurcaseOrderDetails(param.poId);
      }
    });
    this.userSubscription = this.profileSync.user$.subscribe(user => {
      if (user && user.OrganisationRole && user.OrganisationRole.permissions && user.OrganisationRole.permissions.OrganisationContact.read) {
        this.fetchContacts();
      }
    });

    this.shipmentSubscription = this.shipmentChange$.pipe(
      debounceTime(200),
      tap(() => this.shipmentLoading = true),
      switchMap(query => this.orgOrderApi.searchOrderShipments(
        'BUY',
        query,
        this.alreadyLinkedShipIds.join(',')
      ).pipe(
        catchError(() => of([])), // empty list on error
        tap(() => {
          this.shipmentLoading = false;
        })
      ))
    ).subscribe(sos => {
      this.listShipment$.next(sos);
    });
    if (localStorage.getItem('IS_LINK_SHIPMENT') && this.profileSync.user && this.profileSync.user.OrganisationRole && this.profileSync.user.OrganisationRole.permissions && this.profileSync.user.OrganisationRole.permissions.Shipment.update) {
      this.openDocLinkModal();
      localStorage.removeItem('IS_LINK_SHIPMENT');
    }
  }

  ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }

    if (this.shipmentSubscription) {
      this.shipmentSubscription.unsubscribe();
    }
  }


  rightSheetOpen(): void {
    this.rightSheet = !this.rightSheet;
    this.renderer.addClass(document.body, 'modal-open');
    this.commonService.addBackdrop();
  }

  rightSheetClose(): void {
    this.rightSheet = false;
    this.renderer.removeClass(document.body, 'modal-open');
    this.commonService.removeBackdrop();
  }

  orderRecon(): void {
    $('#orderReconModal').modal('show');
  }

  fetchContacts(): void {
    this.shouldShowLoader = true;
    this.orgApi.listOrgContact().subscribe(contacts => {
      this.shouldShowLoader = false;
      if (contacts) {
        this.contactUsers = contacts;
      } else {
        this.contactUsers = [];
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  applyTemplate(): void {
    this.shouldShowLoader = true;
    this.orgOrderApi.updatePoTemplate(
      this.purchaseOrderDetails.purchaseOrderId,
      this.purchaseOrderDetails.templateName
    ).subscribe(order => {
      this.shouldShowLoader = false;
      this.fetchTemplate();
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  fetchPurcaseOrderDetails(poId): void {
    this.shouldShowLoader = true;
    this.orgOrderApi.purchaseOrderStatusHistory(poId).subscribe(po => {
      this.shouldShowLoader = false;
      if (po) {
        this.purchaseOrderDetails = po;
        this.totalRemainingQnty = this.purchaseOrderDetails.PurchaseOrderItems.map(i => i.remainingQuantity).reduce((a, b) => a + b, 0);
        this.totalQuantity = this.purchaseOrderDetails.PurchaseOrderItems.map(i => i.itemQuantity).reduce((a, b) => a + b, 0);
        this.pendingValue = this.purchaseOrderDetails.PurchaseOrderItems.map(i => i.remainingQuantity * i.itemPrice).reduce((a, b) => a + b, 0);
        this.calcPercentage();
        this.fetchTemplate();
        if (this.purchaseOrderDetails.ShipmentDocuments && this.purchaseOrderDetails.ShipmentDocuments.length > 0) {
          this.alreadyLinkedShipIds = this.purchaseOrderDetails.ShipmentDocuments.map(i => i.Shipment.shipmentId);
        }
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  calcPercentage(): void {
    if (this.purchaseOrderDetails.status === 'ACCEPTED') {
      this.statusPercentage = '25%';
    } else if (this.purchaseOrderDetails.status === 'IN_PROGRESS') {
      this.statusPercentage = '50%';
    } else if (this.purchaseOrderDetails.status === 'DELAYED') {
      this.statusPercentage = '75%';
    } else if (this.purchaseOrderDetails.status === 'COMPLETED') {
      this.statusPercentage = '100%';
    } else if (this.purchaseOrderDetails.status === 'CANCELLED') {
      this.statusPercentage = '100%';
    } else {
      this.statusPercentage = '0%';
    }
  }


  fetchTemplate(): void {
    const headers = new HttpHeaders().set('Authorization', this.orgApi.configuration.apiKeys.Authorization);
    if (this.purchaseOrderDetails.templateName) {
      this.shouldShowLoader = true;
      this.http.post(`${this.basePath}/organisation/templates/PurchaseOrder/${this.purchaseOrderDetails.templateName}`,
        { purchaseOrderId: this.purchaseOrderDetails.purchaseOrderId }, {
        responseType: 'text',
        headers,
        observe: 'body'
      }).subscribe(data => {
        this.shouldShowLoader = false;
        this.outputInvoice = data;
      }, err => {
        this.shouldShowLoader = false;
        SwalHelper.showErrorSwal(err);
      });
    }
  }

  updateStatus(status: PurchaseOrder.StatusEnum): void {

    let text = `Are you sure you want to update Import order to ${status === 'ACCEPTED' ? 'Final' : status.replace('_', ' ').toLocaleLowerCase()}`;
    if (status === 'ACCEPTED') {

      if (this.purchaseOrderDetails.PurchaseOrderItems && this.purchaseOrderDetails.PurchaseOrderItems.length === 0) {
        Swal({
          title: 'No Items Found!',
          text: 'Please Add items to Final the Order',
          type: 'warning',
        }).then((result) => {
          if (result.value) {
            this.router.navigate([`/organisation/purchase-orders/update-purchase-order/${this.purchaseOrderDetails.purchaseOrderId}`]);
          }
        });
        return;
      }

      text = 'You won’t be allowed to update Seller & Item section once the status is updated from “Draft” to “Final”.';
    } else if (status === 'COMPLETED') {
      text = 'Once marked as “Completed” you won’t be able to update any fields.';
    }
    Swal({
      title: `Update Order to ${status === 'ACCEPTED' ? 'Final' :  status.replace('_', ' ').toLocaleLowerCase()}`,
      text,
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Update',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        this.shouldShowLoader = true;
        return new Promise((resolve, reject) => {
          this.orgOrderApi.updatePoStatus(
            this.purchaseOrderDetails.purchaseOrderId,
            status
          ).subscribe(ok => {
            this.purchaseOrderDetails.status = status;
            this.calcPercentage();
            this.orgOrderApi.purchaseOrderStatusHistory(this.purchaseOrderDetails.purchaseOrderId).subscribe(po => {
              if (po) {
                this.purchaseOrderDetails = po;
              }
            }, err => {
              SwalHelper.showErrorSwal(err);
            });
            this.shouldShowLoader = false;
            resolve(ok);
          }, err => {
            reject(err);
          });
        });
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.value) {
        this.shouldShowLoader = false;
        SwalHelper.successTimerSwal(`Import Order marked as ${status === 'ACCEPTED' ? 'Final' : status.replace('_', ' ').toLocaleLowerCase()}`);
      }
    }).catch(err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  exportPO(): void {
    this.shouldShowLoader = true;
    const cusObj: CustomExportObject = {};
    cusObj.purchaseOrderId = this.purchaseOrderDetails.purchaseOrderId;
    cusObj.type = 'PurchaseOrder';
    this.orgApi
      .exportDocument(cusObj).subscribe(res => {
        this.shouldShowLoader = false;
        saveAs(res, (`${this.purchaseOrderDetails.orgOrderNo}-ImportOrder.pdf`));
      }, err => {
        this.shouldShowLoader = false;
        SwalHelper.showErrorSwal(err);
      });
  }

  printPO(): void {
    const printContents = document.getElementById('printPO').innerHTML;
    const win = window.open('', 'Title', 'toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes');
    win.document.body.innerHTML = printContents;
    win.print();
  }

  checkUser(event, user, ind): void {
    this.shouldShareUser[ind] = event.checked;
    if (event.checked) {
      this.emails.push({
        contactEmail: user.contactEmail,
        contactName: user.contactName
      });
    } else {
      const index = this.emails.findIndex(i => i.contactEmail === user.contactEmail);
      if (index >= 0) {
        this.emails.splice(index, 1);
      }
    }
  }

  sharePO(): void {
    this.shouldShowLoader = true;
    this.orgApi.emailPurchaseOrder(
      this.purchaseOrderDetails.purchaseOrderId,
      [JSON.stringify(this.emails)]
    ).subscribe(ok => {
      this.shouldShowLoader = false;
      this.emails = [];
      this.shouldShareUser = this.shouldShareUser.map(() => false);
      $('#assignUserModal').modal('hide');
      if (ok) {
        SwalHelper.successTimerSwal('Import Order sent successfully');
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  saveStatusComment(): void {
    this.shouldShowLoader = true;
    this.statusHistory.purchaseOrderId = this.purchaseOrderDetails.purchaseOrderId;
    if (this.statusHistory.statusHistoryId) {
      this.orgApi.updateStatusHistory(this.statusHistory).subscribe(history => {
        if (history) {
          this.shouldShowLoader = false;
          SwalHelper.showSavedToast('Comment Updated');
          const index = this.purchaseOrderDetails.StatusHistories.findIndex(i => i.statusHistoryId === this.statusHistory.statusHistoryId);
          this.purchaseOrderDetails.StatusHistories[index] = history;
          this.statusHistory = {};
        }
      }, err => {
        this.shouldShowLoader = false;
        SwalHelper.showErrorSwal(err);
      });
    } else {
      this.orgApi.createStatusHistory(this.statusHistory).subscribe(history => {
        if (history) {
          this.shouldShowLoader = false;
          this.purchaseOrderDetails.StatusHistories.unshift(history);
          SwalHelper.showSavedToast('Comment Added');
          this.statusHistory = {};
        }
      }, err => {
        this.shouldShowLoader = false;
        SwalHelper.showErrorSwal(err);
      });
    }
  }

  editComment(index: number): void {
    this.statusHistory = this.orgServiceHelper.copyObject(this.purchaseOrderDetails.StatusHistories[index]);
  }

  deleteStatusComment(index: number): void {
    Swal({
      title: 'Delete Status Comment',
      text: 'This cannot be undone',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        this.shouldShowLoader = true;
        return new Promise((resolve, reject) => {
          this.orgApi.deleteStatusHistory(this.purchaseOrderDetails.StatusHistories[index].statusHistoryId).subscribe(ok => {
            this.shouldShowLoader = false;
            this.purchaseOrderDetails.StatusHistories.splice(index, 1);
            resolve(ok);
          }, err => {
            reject(err);
          });
        });
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.value) {
        this.shouldShowLoader = false;
        SwalHelper.successTimerSwal('Comment deleted!');
      }
    }).catch(err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }
  convertToInv(): void {
    Swal({
      title: 'Convert to Invoice',
      text: 'Are you sure you want to convert Import order to Vendor Bill',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Convert',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        this.shouldShowLoader = true;
        return new Promise((resolve, reject) => {
          this.orgOrderApi.convertPoToInvoice(
            this.purchaseOrderDetails.purchaseOrderId
          ).subscribe(inv => {
            this.shouldShowLoader = false;
            this.router.navigate([`/organisation/vendor-bills/edit-vendor-bill/${inv.invId}`]);
            resolve(inv);
          }, err => {
            reject(err);
          });
        });
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.value) {
        this.shouldShowLoader = false;
        SwalHelper.successTimerSwal('Vendor Bill created successfully!');
      }
    }).catch(err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  public downloadFile(docAttId: number): void {
    this.orgApi.orgDocumentSignedUrl(docAttId)
      .subscribe(url => {
        window.open(url.url, '_blank');
      });
  }

  updatePaymentDetails(): void {
    this.shouldShowLoader = true;
    this.orgOrderApi.updatePoAdvanceDetails(this.purchaseOrderDetails).subscribe(ok => {
      this.shouldShowLoader = false;
      if (ok) {
        $('#recordPaymentModal').modal('hide');
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  openDocLinkModal(): void {
    this.shipmentDocumentLink.docType = 'PURCHASE_ORDER';
    $('#fileAddModal').modal('show');
  }

  // Link the documents
  linkDocument(): void {
    this.shouldShowLoader = true;
    this.shipmentDocumentLink.purchaseOrderId = this.purchaseOrderDetails.purchaseOrderId;
    this.orgShipmentApi.linkShipmentDocument(
      this.shipmentDocumentLink
    ).subscribe(doc => {
      this.shouldShowLoader = false;
      SwalHelper.successTimerSwal('Shipment linked');
      if (doc) {
        if (doc.PurchaseOrder) {
          this.purchaseOrderDetails.ShipmentDocuments.push({
            shipmentDocumentId: doc.shipmentDocumentId,
            Shipment: doc.Shipment
          });
          this.alreadyLinkedShipIds.push(this.shipmentDocumentLink.shipmentId);
        }
        this.shipmentDocumentLink = {};
        this.shipmentDocumentLink.docType = 'PURCHASE_ORDER';
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /**
   * Fn to Unlink the shipment from order
   * @param index Index of shipment document
   */
  unlinkDocument(index: number): void {
    Swal({
      title: `Unlink Shipment`,
      text: 'This cannot be undone',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Unlink',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        this.shouldShowLoader = true;
        return new Promise((resolve, reject) => {
          this.orgShipmentApi.unlinkShipmentDocument(
            this.purchaseOrderDetails.ShipmentDocuments[index].Shipment.shipmentId,
            this.purchaseOrderDetails.ShipmentDocuments[index].shipmentDocumentId
          ).subscribe(doc => {
            this.shouldShowLoader = false;
            this.purchaseOrderDetails.ShipmentDocuments.splice(index, 1);
            $('#fileViewModal').modal('hide');
            resolve(doc);
          }, err => {
            this.shouldShowLoader = false;
            reject(err);
          });
        });
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.value) {
        SwalHelper.successTimerSwal('Shipment Unlinked');
      }
    }).catch(err => {
      SwalHelper.showErrorSwal(err);
    });
  }

  /* Fn to set the seller id for shipment & redirect to shipment create modal */
  openNewShipment(): void {
    localStorage.setItem('SHIPMENT_SELLER_ID', this.purchaseOrderDetails.sellerId.toString());
    this.router.navigate(['/organisation/list-shipments']);
  }
}
