import { EmailReplyContext } from './../../../../swagger/model/emailReplyContext';
import { Component, OnInit, ViewChild, ElementRef, Renderer2, Input, AfterViewInit, OnChanges, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { OrgContactService, ShipmentDialogue, ShipmentDialogueAttachment, Shipment, OrgShipmentsService, ShipmentChecklist, User } from 'src/swagger';
import { GlobalProfileService } from 'src/app/global-profile.service';
import { SwalHelper } from 'src/app/swal';
import { ShipmentDetailService } from '../shipment-detail.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, take } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';

declare var $: any;
declare var Swal: any;
@Component({
  selector: 'app-shipment-chat',
  templateUrl: './shipment-chat.component.html',
  styleUrls: ['./shipment-chat.component.scss']
})
export class ShipmentChatComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {

  @ViewChild('scrollChatWindow') scrollChatWindow: ElementRef;
  @ViewChild('allSelected') private allSelected: MatOption;
  @ViewChild('chatUserSelector')
  private chatUserSelector: MatSelect;

  @Input() shipmentDetails: Shipment = {};
  @Input() incomingUsers: Observable<[]>;
  public availableUsers = [];
  public customEmailTokensInternalUsers: EmailReplyContext = {};

  public shouldShowLoader: boolean;
  public checkListToggle = false;
  public pinMessage = false;
  public secretToken = false;
  public bigChatBox = false;
  public showFilesPopup = false;
  public chatUserFocused = false;
  public chatWindow = false;

  public newShipmentDialogue: ShipmentDialogue = {}; // adding new dialogue
  public newChecklistItem: ShipmentChecklist = {}; // adding new dialogue
  public editChecklistItem: ShipmentChecklist = {};
  public isSendEmail = true;

  public isFilesAvailable = false;
  public pinnedDialogue: ShipmentDialogue = {};

  private searchQuery$: Subject<string> = new Subject();
  public dialogueUsers = new FormControl();
  public checkListUsers = [];
  public shipmentAccessUsers: User[] = [];

  private userSubscription: Subscription;
  public copyText = 'Click to copy';

  constructor(
    private orgContactApi: OrgContactService,
    private renderer: Renderer2,
    public profileSync: GlobalProfileService,
    private changeDetector: ChangeDetectorRef,
    private shipmetDetailService: ShipmentDetailService,
    private orgShipmentApi: OrgShipmentsService
  ) { }

  ngOnInit(): void {
    this.shipmetDetailService.isFilesAvailable$.subscribe(file => {
      if (file) {
        this.isFilesAvailable = true;
      } else {
        this.isFilesAvailable = false;
      }
    });
    this.searchQuery$.pipe(
      distinctUntilChanged(),
    ).subscribe((query) => {
      this.fetchInitialComments(query);
    });
    this.userSubscription = this.profileSync.user$.pipe(take(1)).subscribe(user => {
      if (user && user.isInternal) {
        this.fetchInternalUsers();
      }
    });
    this.incomingUsers.subscribe(users => {
      this.availableUsers = users;
      this.dialogueUsers
        .patchValue(['everyone', ...this.availableUsers, ...this.shipmentAccessUsers]);
    });
  }

  searchChange(event): void {
    this.searchQuery$.next(event);
  }

  togglePerOne(): void {
    if (this.allSelected.selected) {
      this.allSelected.deselect();
    }
    if (this.dialogueUsers.value.length == (this.availableUsers.length + this.shipmentAccessUsers.length)) {
      this.allSelected.select();
    }
  }

  /* Fn to toggle the All selection inside chat window */
  toggleAllSelection(): void {
    if (this.allSelected.selected) {
      this.dialogueUsers
        .patchValue(['everyone', ...this.availableUsers, ...this.shipmentAccessUsers]);
    } else {
      this.dialogueUsers.patchValue([]);
    }
  }

  ngOnChanges(): void {
    if (this.shipmentDetails && this.shipmentDetails.shipmentId) {
      this.secretToken = false;
      this.pinMessage = false;
      this.checkListToggle = false;
      this.fetchInitialComments(undefined);
      this.fetchCheckList();
      this.fetchCustomEmailTokens();
      if (this.profileSync.user && this.profileSync.user.orgId !== this.shipmentDetails.orgId) {
        this.setDefaultUser();
      }
    }
  }

  ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  /* Fn to set the default user based if external user is commenting */
  setDefaultUser(): void {
    this.newShipmentDialogue.commentOnlyFor = this.shipmentDetails.createdBy;
    if (this.shipmentDetails.User) {
      this.newShipmentDialogue.commentOnlyForName = `${this.shipmentDetails.User.firstName} ${this.shipmentDetails.User.lastName}`;
      this.dialogueUsers.setValue([{
        name: this.newShipmentDialogue.commentOnlyForName,
        email: this.newShipmentDialogue.commentOnlyFor
      }]);
    }
  }

  chatUserFocus(): void {
    this.chatUserFocused = true;
  }

  /* Fn to open the chat window */
  openChatWidnow(): void {
    this.chatWindow = true;
  }

  /* Fn to close the chat Window  */
  closeChatWidnow(): void {
    this.chatWindow = false;
  }

  /* TODO: Shraddha Ask shubham & delete this if not required */
  ngAfterViewInit(): void {
    if (this.chatUserFocused === true) {
      this.bigChatBox = true;
    }
  }

  /* FN to open the chat box */
  public openChatBox(): void {
    this.bigChatBox = true;
  }

  /* Fn to toggle the checklist modal */
  public toggleChecklist(): void {
    this.checkListToggle = !this.checkListToggle;
    this.secretToken = false;
    this.pinMessage = false;
  }

  /* Fn to toggle the Pinned message modal */
  public togglePinMessage(): void {
    this.pinMessage = !this.pinMessage;
    this.secretToken = false;
    this.checkListToggle = false;
  }

  /* Fn to toggle the Secret Token modal */
  public toggleSecretToken(): void {
    this.secretToken = !this.secretToken;
    this.pinMessage = false;
    this.checkListToggle = false;
  }

  public filePopUpToggle(): void {
    this.showFilesPopup = !this.showFilesPopup;
  }

  public scrollToBottom(): void {
    this.scrollChatWindow.nativeElement.scrollTop = this.scrollChatWindow.nativeElement.scrollHeight;
  }

  /**
   * Fn to add the links of selected attahment for dialogue
   * @param event Angular event to check checkbox is ticked
   * @param folderIndex Index of selected Folder
   * @param fileIndex Index of selected file
   */
  public addDialogueAttachment(event: any, folderIndex: number, fileIndex: number): void {
    if (event.target.checked) {
      if (!this.newShipmentDialogue.ShipmentDialogueAttachments) {
        this.newShipmentDialogue.ShipmentDialogueAttachments = [];
      }
      const newShipmentDialogueAttachment: ShipmentDialogueAttachment = {};
      newShipmentDialogueAttachment.filename = this.shipmentDetails.ShipmentDocumentFolders[folderIndex].ShipmentDocuments[fileIndex].docMeta.fileName;
      newShipmentDialogueAttachment.url = this.shipmentDetails.ShipmentDocumentFolders[folderIndex].ShipmentDocuments[fileIndex].docUrl;
      this.newShipmentDialogue.ShipmentDialogueAttachments.push(newShipmentDialogueAttachment);
    } else {
      const index = this.newShipmentDialogue.ShipmentDialogueAttachments.findIndex(i => i.url === this.shipmentDetails.ShipmentDocumentFolders[folderIndex].ShipmentDocuments[fileIndex].docUrl);
      if (index >= 0) {
        this.newShipmentDialogue.ShipmentDialogueAttachments.splice(index, 1);
      }
    }
  }

  /**
   * Fn to remove the dialogue attachment
   * @param index index of dialogue attachment
   */
  public removeDialogueAttachment(index: number): void {
    this.newShipmentDialogue.ShipmentDialogueAttachments.splice(index, 1);
  }

  /**
   * Fn to add the dialogue & send email notification
   */
  public addDialogue(): void {

    if (this.dialogueUsers.value.length === 0) {
      this.chatUserSelector.open();
      return;
    }

    this.shouldShowLoader = true;

    this.newShipmentDialogue.isPublic = this.dialogueUsers.value[0] == 'everyone' ? true : false;
    this.orgContactApi.addDialog(
      this.shipmentDetails.shipmentId,
      this.isSendEmail,
      this.newShipmentDialogue,
      this.dialogueUsers.value[0] == 'everyone' ? undefined : [JSON.stringify(this.dialogueUsers.value)],
    ).subscribe(dialogues => {
      this.shouldShowLoader = false;
      this.closeChatWidnow();
      if (dialogues) {
        if (!this.shipmentDetails.ShipmentDialogues) {
          this.shipmentDetails.ShipmentDialogues = [];
        }
        dialogues.forEach(i => {
          this.shipmentDetails.ShipmentDialogues.push(i);
        });
        this.newShipmentDialogue = {};
        this.profileSync.user.orgId === this.shipmentDetails.orgId ? this.dialogueUsers
          .patchValue(['everyone', ...this.availableUsers, ...this.shipmentAccessUsers]) : this.setDefaultUser();
        SwalHelper.showCenteredSavedToast(`${this.isSendEmail ? 'Message' : 'Email'} Sent`);
        this.showFilesPopup = false;
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /**
   * Fn to delete the dialogue
   * @param index Index of dialogue
   */
  deleteDiagoue(index: number): void {
    Swal({
      title: 'Delete Message',
      text: 'This cannot be undone',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        this.shouldShowLoader = true;
        return new Promise((resolve, reject) => {
          this.orgContactApi.deleteDialog(
            this.shipmentDetails.shipmentId,
            this.shipmentDetails.ShipmentDialogues[index].shipmentDialogueId
          ).subscribe(ok => {
            this.shouldShowLoader = false;
            this.shipmentDetails.ShipmentDialogues[index].isDeleted = true;
            resolve(ok);
          }, err => {
            reject(err);
          });
        });
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.value) {
        this.shouldShowLoader = false;
        SwalHelper.successTimerSwal('Dialogue deleted');
      }
    }).catch(err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /* API to fetch the internal users for checklist & shipment chat window*/
  fetchInternalUsers(): void {
    this.checkListUsers = [];
    this.shipmentAccessUsers = [];
    this.shouldShowLoader = true;
    this.orgShipmentApi.internalUsersList().subscribe(users => {
      this.shouldShowLoader = false;
      if (users && users.length > 0) {
        this.checkListUsers = this.checkListUsers.concat(users);
        this.shipmentAccessUsers = users.filter(u => u.OrganisationRole.permissions.Shipment.read == true).map(i => {
          return {
            email: i.email,
            name: i.name,
            isInternal: true
          };
        });
        this.dialogueUsers
          .patchValue(['everyone', ...this.availableUsers, ...this.shipmentAccessUsers]);
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /**
   * Fn to fetch the dialogue of shipment based on search query
   * @param query search query
   */
  fetchInitialComments(query: any): void {
    this.shouldShowLoader = true;
    this.orgContactApi.listDialogues(
      this.shipmentDetails.shipmentId,
      query
    ).subscribe(dialogues => {
      this.shouldShowLoader = false;
      if (dialogues && dialogues.length > 0) {
        if (!this.shipmentDetails.ShipmentDialogues) {
          this.shipmentDetails.ShipmentDialogues = [];
        }
        this.shipmentDetails.ShipmentDialogues = dialogues;
        if (this.profileSync.user && this.profileSync.user.orgId != this.shipmentDetails.orgId) {
          // tslint:disable-next-line: max-line-length
          this.shipmentDetails.ShipmentDialogues = this.shipmentDetails.ShipmentDialogues.filter(i => i.isPublic || i.commentOnlyFor === this.profileSync.user.email || i.dialogueByEmail === this.profileSync.user.email);
        }
        this.pinnedDialogue = this.shipmentDetails.ShipmentDialogues.filter(i => i.isPinned)[0];
      } else {
        this.shipmentDetails.ShipmentDialogues = null;
      }
      this.changeDetector.detectChanges();
      this.scrollToBottom();
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /* FN to fecth the shipment checklsit */
  fetchCheckList(): void {
    this.shouldShowLoader = true;
    this.orgContactApi.listShipmentChecklist(
      this.shipmentDetails.shipmentId,
    ).subscribe(lists => {
      this.shouldShowLoader = false;
      if (lists && lists.length > 0) {
        if (!this.shipmentDetails.ShipmentChecklists) {
          this.shipmentDetails.ShipmentChecklists = [];
        }
        this.shipmentDetails.ShipmentChecklists = lists;
      } else {
        this.shipmentDetails.ShipmentChecklists = [];
      }
      this.changeDetector.detectChanges();
      this.scrollToBottom();
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /**
   * Fn to Pin the existing dialoge
   * Unpin already pinned dialogue
   * @param index index of dialogue to be pinned
   */
  pinDialogue(index: number): void {
    this.shouldShowLoader = true;
    const pinnedIndex = this.shipmentDetails.ShipmentDialogues.findIndex(i => i.isPinned === true);
    this.orgContactApi.pinDialogue(
      this.shipmentDetails.shipmentId,
      this.shipmentDetails.ShipmentDialogues[index].shipmentDialogueId
    ).subscribe(ok => {
      this.pinnedDialogue = this.shipmentDetails.ShipmentDialogues[index];
      this.shipmentDetails.ShipmentDialogues[index].isPinned = true;
      if (pinnedIndex >= 0) {
        this.shipmentDetails.ShipmentDialogues[pinnedIndex].isPinned = false;
      }
      this.shouldShowLoader = false;
      SwalHelper.showToast('Success', 'Pinned dialogue');
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /* Fn to unpin the dialogue */
  unpinDialogue(): void {
    const index = this.shipmentDetails.ShipmentDialogues.findIndex(i => i.shipmentDialogueId == this.pinnedDialogue.shipmentDialogueId);
    this.shouldShowLoader = true;
    this.orgContactApi.unpinDialogue(
      this.shipmentDetails.shipmentId,
      this.shipmentDetails.ShipmentDialogues[index].shipmentDialogueId
    ).subscribe(ok => {
      this.pinnedDialogue = {};
      this.shouldShowLoader = false;
      this.shipmentDetails.ShipmentDialogues[index].isPinned = false;
      SwalHelper.showToast('Success', 'Unpinned dialogue');
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /**
   * Fn to select the checklist item to update the details
   * @param index Index of checklist item
   */
  selectCheckList(index: number): void {
    this.editChecklistItem = this.shipmentDetails.ShipmentChecklists[index];
  }

  /**
   * Fn to unassign user from checklist item & update the checklist item
   * @param index Index of the checklist item
   */
  unassignCheckList(index: number): void {
    this.editChecklistItem = this.shipmentDetails.ShipmentChecklists[index];
    this.editChecklistItem.assignToEmail = null;
    this.editChecklistItem.assignToName = null;
    this.updateChecklistItem();
  }

  /**
   * Fn to update checklist item toggle status
   * @param event Checklist checkbox event
   * @param index Index of checklist item
   */
  isChecklistUpdated(event, index: number): void {
    this.editChecklistItem = this.shipmentDetails.ShipmentChecklists[index];
    if (event.target.checked) {
      this.editChecklistItem.checked = true;
    } else {
      this.editChecklistItem.checked = false;
    }
    this.orgContactApi.shipmentChecklistToggle(
      this.shipmentDetails.shipmentId,
      this.editChecklistItem.shipmentChecklistId,
      this.editChecklistItem.checked
    ).subscribe(checklist => {
      this.shipmentDetails.ShipmentChecklists[index].checked = this.editChecklistItem.checked;
      this.shipmentDetails.ShipmentChecklists[index].completedBy = checklist.completedBy;
    }, err => {
      SwalHelper.showErrorSwal(err);
    });
  }

  /* Fn to create new checklist item */
  addChecklistItem(): void {
    this.shouldShowLoader = true;
    this.newChecklistItem.shipmentId = this.shipmentDetails.shipmentId;
    this.orgShipmentApi.createShipmentChecklist(
      this.newChecklistItem
    ).subscribe(item => {
      this.shouldShowLoader = false;
      // tslint:disable-next-line: max-line-length
      this.shipmentDetails.ShipmentChecklists = this.shipmentDetails.ShipmentChecklists && this.shipmentDetails.ShipmentChecklists.length > 0 ? this.shipmentDetails.ShipmentChecklists : [];
      this.shipmentDetails.ShipmentChecklists.push(item);
      SwalHelper.showSavedToast('Item Added');
      this.newChecklistItem = {};
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /* Fn to update the checklist item */
  updateChecklistItem(): void {
    this.shouldShowLoader = true;
    if (this.editChecklistItem.assignToEmail) {
      const intUserIndex = this.checkListUsers.findIndex(i => i.email === this.editChecklistItem.assignToEmail);
      if (intUserIndex >= 0) {
        this.editChecklistItem.assignToName = this.checkListUsers[intUserIndex].name;
      }
      const extUserIndex = this.availableUsers.findIndex(i => i.email === this.editChecklistItem.assignToEmail);
      if (extUserIndex >= 0) {
        this.editChecklistItem.assignToName = this.availableUsers[extUserIndex].name;
      }
    }
    this.orgShipmentApi.updateShipmentChecklist(
      this.editChecklistItem
    ).subscribe(item => {
      this.shouldShowLoader = false;
      $('#assignUserModal').modal('hide');
      this.editChecklistItem = {};
      const index = this.shipmentDetails.ShipmentChecklists.findIndex(i => i.shipmentChecklistId == item.shipmentChecklistId);
      this.shipmentDetails.ShipmentChecklists[index] = item;
      SwalHelper.showSavedToast('Item updated');
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /**
   * Fn to delete the checklist item
   * @param index Index of checklist item
   */
  deleteChecklistItem(index: number): void {
    Swal({
      title: 'Delete Checklist',
      text: 'This cannot be undone',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        this.shouldShowLoader = true;
        return new Promise((resolve, reject) => {
          this.orgShipmentApi.deleteShipmentChecklist(
            this.shipmentDetails.ShipmentChecklists[index].shipmentChecklistId,
            this.shipmentDetails.shipmentId,
          ).subscribe(ok => {
            this.shouldShowLoader = false;
            this.shipmentDetails.ShipmentChecklists.splice(index, 1);
            resolve(ok);
          }, err => {
            reject(err);
          });
        });
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.value) {
        this.shouldShowLoader = false;
        SwalHelper.successTimerSwal('Checklist item deleted');
      }
    }).catch(err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /**
   * Fn to download the document
   * @param shipmentDocumentId Document id to be downloaded
   */
  public downloadFile(shipmentDocumentId: number): void {
    this.orgContactApi.commentAttSignedUrl(this.shipmentDetails.shipmentId, shipmentDocumentId)
      .pipe(take(1))
      .subscribe(url => {
        window.open(url.url, '_blank');
      });
  }

  /* Fn to forward the selected message*/
  forwardMessage(index: number): void {
    this.newShipmentDialogue = this.shipmentDetails.ShipmentDialogues[index];
    this.openChatWidnow();
  }

  /* Function to copy custom email reply token */
  selectText(str): void {
    str = str.concat('@ses.nuphi.trade');
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    const selected =
      document.getSelection().rangeCount > 0
        ? document.getSelection().getRangeAt(0)
        : false;
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    if (selected) {
      document.getSelection().removeAllRanges();
      document.getSelection().addRange(selected);
      SwalHelper.showCenteredSavedToast('Copied');
    }
  }

  /* function to create custom email reply token */
  createCustomEmailTokens(email: string, orgContactId: number): void {
    this.shouldShowLoader = true;
    this.orgShipmentApi.createManualEmailReplyContext(
      this.shipmentDetails.shipmentId,
      email,
      orgContactId
    ).subscribe(customToken => {
      this.shouldShowLoader = false;
      if (customToken) {
        this.customEmailTokensInternalUsers = customToken;
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }
  /* function to fetch all custom email reply token*/
  fetchCustomEmailTokens(): void {
    this.shouldShowLoader = true;
    this.customEmailTokensInternalUsers = {};
    this.orgShipmentApi.listManualEmailReplyContext(this.shipmentDetails.shipmentId).subscribe(token => {
      this.shouldShowLoader = false;
      if (token) {
        this.customEmailTokensInternalUsers = token;
      } else {
        this.customEmailTokensInternalUsers = {
          shipmentId: this.shipmentDetails.shipmentId,
          email: this.profileSync.user.email,
          User: {
            name: this.profileSync.user.firstName,
          }
        };
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }
}
