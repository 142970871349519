// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  GTM_ID: 'GTM-PFTPN9K',
  BASE_PATH: 'https://trade.credlixlab.com',
  RECAPTCHA_SITE_KEY: "6LcNZgIeAAAAAJLLV2khyYrzxBZRvV2y2gp5_ggp"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
