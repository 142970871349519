import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import {DragDropModule} from '@angular/cdk/drag-drop';

import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { environment } from 'src/environments/environment';
import { BASE_PATH } from 'src/swagger';
import { HelperModuleModule } from '../helper-module/helper-module.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { InvoiceDetailsComponent } from './invoice/invoice-details/invoice-details.component';
import { InvoiceUpdatesComponent } from './invoice/invoice-updates/invoice-updates.component';
import { InvoiceComponent } from './invoice/invoice.component';
import { ListShipmentsComponent } from './list-shipments/list-shipments.component';
import { OrganisationContactsDetailComponent } from './organisation-contacts/organisation-contacts-detail/organisation-contacts-detail.component';
import { OrganisationContactsComponent } from './organisation-contacts/organisation-contacts.component';
import { OrganisationProfileUpdateComponent } from './organisation-profile/organisation-profile-update/organisation-profile-update.component';
import { OrganisationProfileComponent } from './organisation-profile/organisation-profile.component';
import { OrganisationAddEditRolesComponent } from './organisation-roles/organisation-add-edit-roles/organisation-add-edit-roles.component';
import { OrganisationRolesDetailsComponent } from './organisation-roles/organisation-roles-details/organisation-roles-details.component';
import { OrganisationRolesComponent } from './organisation-roles/organisation-roles.component';
import { OrganisationRoutingModule } from './organisation-routing.module';
import { OrganisationUsersDetailComponent } from './organisation-users/organisation-users-detail/organisation-users-detail.component';
import { OrganisationUsersComponent } from './organisation-users/organisation-users.component';
import { PurchaseOrderDetailsComponent } from './purchase-order/purchase-order-details/purchase-order-details.component';
import { PurchaseOrderUpdateComponent } from './purchase-order/purchase-order-update/purchase-order-update.component';
import { PurchaseOrderComponent } from './purchase-order/purchase-order.component';
import { SalesOrderDetailsComponent } from './sales-order/sales-order-details/sales-order-details.component';
import { SalesOrderUpdateComponent } from './sales-order/sales-order-update/sales-order-update.component';
import { SalesOrderComponent } from './sales-order/sales-order.component';
import { DataTablesModule } from 'angular-datatables';
import { ShipmentUpdatesComponent } from './shipment-updates/shipment-updates.component';
import { DgftComponent } from './dgft/dgft.component';
import { LicensesComponent } from './dgft/licenses/licenses.component';
import { LicenseDetailsComponent } from './dgft/licenses/license-details/license-details.component';
import { LicenseUpdateComponent } from './dgft/licenses/license-update/license-update.component';
import { MasterComponent } from './master/master.component';
import { OrganisationItemMastersComponent } from './master/organisation-item-masters/organisation-item-masters.component';
import { TemplatesComponent } from './master/templates/templates.component';
import { InvoiceTemplatesComponent } from './master/templates/invoice-templates/invoice-templates.component';
import { CustomFieldsComponent } from './master/custom-fields/custom-fields.component';
import { InvoiceCustomFieldsComponent } from './master/custom-fields/invoice-custom-fields/invoice-custom-fields.component';
import { OrderCustomFieldsComponent } from './master/custom-fields/order-custom-fields/order-custom-fields.component';
import { OrderTemplatesComponent } from './master/templates/order-templates/order-templates.component';
import { EditTemplateComponent } from './master/templates/edit-template/edit-template.component';
import { ServiceProvidersComponent } from './service-providers/service-providers.component';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { BulkUploadInvoiceComponent } from './bulk-upload/bulk-upload-invoice/bulk-upload-invoice.component';
import { PaymentScheduleComponent } from './invoice/payment-schedule/payment-schedule.component';
import { InvoicePaymentComponent } from './invoice/invoice-payment/invoice-payment.component';
import { OrganisationVendorUpdateComponent } from './organisation-contacts/organisation-vendor-update/organisation-vendor-update.component';
import { VendorBillComponent } from './vendor-bill/vendor-bill.component';
import { VendorBillDetailsComponent } from './vendor-bill/vendor-bill-details/vendor-bill-details.component';
import { VendorBillUpdatesComponent } from './vendor-bill/vendor-bill-updates/vendor-bill-updates.component';
import { VendorBillCustomFieldsComponent } from './master/custom-fields/vendor-bill-custom-fields/vendor-bill-custom-fields.component';
import { ReportsComponent } from './reports/reports.component';
import { InvoiceReportComponent } from './reports/invoice-report/invoice-report.component';
import { VendorBillReportComponent } from './reports/vendor-bill-report/vendor-bill-report.component';
import { SalesOrderReportComponent } from './reports/sales-order-report/sales-order-report.component';
import { PurchaseOrderReportComponent } from './reports/purchase-order-report/purchase-order-report.component';
import { ShipmentReportComponent } from './reports/shipment-report/shipment-report.component';
import { PoCustomFieldsComponent } from './master/custom-fields/po-custom-fields/po-custom-fields.component';
import { ShipmentTrackingComponent } from './shipment-tracking/shipment-tracking.component';
import { OrderReconReportComponent } from './reports/order-recon-report/order-recon-report.component';
import { ChartsModule } from 'ng2-charts';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UserPreferenceComponent } from './user-preference/user-preference.component';
import { ReminderComponent } from './reminder/reminder.component';
import { OrganisationComponent } from './organisation.component';
import { MyWorkspaceComponent } from './dashboard/my-workspace/my-workspace.component';
import { ItemBulkUploadComponent } from './invoice/item-bulk-upload/item-bulk-upload.component';
import { ExpenseAnalysisComponent } from './reports/expense-analysis/expense-analysis.component';
import { RevenueAnalysisComponent } from './reports/revenue-analysis/revenue-analysis.component';
import { MandatoryFieldsComponent } from './master/mandatory-fields/mandatory-fields.component';

@NgModule({
  declarations: [
    OrganisationComponent,
    DashboardComponent,
    OrganisationUsersComponent,
    OrganisationUsersDetailComponent,
    OrganisationRolesComponent,
    OrganisationRolesDetailsComponent,
    OrganisationAddEditRolesComponent,
    OrganisationContactsComponent,
    OrganisationContactsDetailComponent,
    OrganisationItemMastersComponent,
    OrganisationProfileComponent,
    OrganisationProfileUpdateComponent,
    PurchaseOrderComponent,
    PurchaseOrderDetailsComponent,
    PurchaseOrderUpdateComponent,
    SalesOrderComponent,
    SalesOrderDetailsComponent,
    SalesOrderUpdateComponent,
    InvoiceComponent,
    InvoiceDetailsComponent,
    InvoiceUpdatesComponent,
    ListShipmentsComponent,
    ShipmentUpdatesComponent,
    DgftComponent,
    LicensesComponent,
    LicenseDetailsComponent,
    LicenseUpdateComponent,
    MasterComponent,
    TemplatesComponent,
    InvoiceTemplatesComponent,
    CustomFieldsComponent,
    InvoiceCustomFieldsComponent,
    OrderCustomFieldsComponent,
    OrderTemplatesComponent,
    EditTemplateComponent,
    ServiceProvidersComponent,
    BulkUploadInvoiceComponent,
    PaymentScheduleComponent,
    InvoicePaymentComponent,
    OrganisationVendorUpdateComponent,
    VendorBillComponent,
    VendorBillDetailsComponent,
    VendorBillUpdatesComponent,
    VendorBillCustomFieldsComponent,
    ReportsComponent,
    InvoiceReportComponent,
    VendorBillReportComponent,
    SalesOrderReportComponent,
    PurchaseOrderReportComponent,
    ShipmentReportComponent,
    PoCustomFieldsComponent,
    ShipmentTrackingComponent,
    OrderReconReportComponent,
    ReminderComponent,
    UserPreferenceComponent,
    MyWorkspaceComponent,
    ItemBulkUploadComponent,
    ExpenseAnalysisComponent,
    RevenueAnalysisComponent,
    MandatoryFieldsComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HelperModuleModule,
    OrganisationRoutingModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    NgSelectModule,
    DataTablesModule,
    MatProgressSpinnerModule,
    MatSliderModule,
    ChartsModule,
    MatTooltipModule,
    MatBottomSheetModule,
    DragDropModule
  ],
  exports: [
    OrganisationContactsDetailComponent,
    OrganisationVendorUpdateComponent,
    ShipmentUpdatesComponent,
    InvoicePaymentComponent,
    PaymentScheduleComponent,
    ReminderComponent
  ],
  entryComponents: [
    ItemBulkUploadComponent
  ],
  providers: [
    { provide: BASE_PATH, useValue: environment.BASE_PATH },
  ],
})
export class OrganisationModule { }
