import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { GlobalProfileService } from './../global-profile.service';

@Injectable({
  providedIn: 'root'
})
export class ServiceProviderGuard implements CanActivate, CanActivateChild, CanLoad {

  constructor(
    private profileSync: GlobalProfileService,
    private router: Router
  ){}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.allow();
  }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.allow();
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return this.allow();
  }

  allow(): boolean {
    if (this.profileSync.isUserLoggedIn){
     this.profileSync.user$
    .pipe(take(1))
    .subscribe( user => {
      if (user && user.servProvId) {
        return true;
      }
      else {
        return false;
      }
    }, err => {
      this.router.navigate(['/login']);
      return false;
    } );
  } else{
    return false;
  }
  }
}
