import { Component, OnInit, OnDestroy, ChangeDetectorRef, Renderer2, ViewChild } from '@angular/core';
import { ShippingBill, OrganisationService, VendorAndSupplier, Page, Invoice, Organisation, ShipmentDocsService } from 'src/swagger';
import { SwalHelper } from 'src/app/swal';
import { ActivatedRoute, Router } from '@angular/router';
import { interval, of, Subject, Subscription } from 'rxjs';
import { skip, catchError, filter, distinctUntilChanged, switchMap, tap, take } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { CommonSupportService } from 'src/app/common-support.service';
import { GlobalProfileService } from 'src/app/global-profile.service';
import { ComponentCanDeactivate } from 'src/app/form-route-deactivate/component-can-deactivate';
import { NgForm } from '@angular/forms';
import { OrganisationHelperService } from 'src/app/organisation/organisation-helper.service';
import * as moment from 'moment';
declare var $: any;
declare var Swal: any;
@Component({
  selector: 'app-shipping-bill-update',
  templateUrl: './shipping-bill-update.component.html',
  styleUrls: ['./shipping-bill-update.component.scss']
})
export class ShippingBillUpdateComponent extends ComponentCanDeactivate implements OnInit, OnDestroy {

  @ViewChild('shippingBillForm')
  public shippingBillFormRef: NgForm;

  public shouldShowLoader: boolean;
  public sbDetails: ShippingBill = {};
  public sbOCRSubscription: Subscription;
  public totalPages = [];

  public ocrResults: Page[] = [];
  public selectors: SelectorDiv[] = [];

  public orgContacts: VendorAndSupplier[] = [];
  public buyer: VendorAndSupplier = {};
  public consignee: VendorAndSupplier = {};

  public thisPage: any;
  public bgwidth: any;
  public bgheight: any;

  public copyText = 'Click to copy';
  public selectedImageURL: string;

  public rightSheet = false;
  public newContactType: string;

  public invChange$: Subject<string> = new Subject();
  public listInvoice$: Subject<Invoice[]> = new Subject();
  private invChangeSubscription: Subscription;
  public invLoading = false;

  public sbFiles: File[] = [];
  public companyProfile: Organisation = {};
  public selectedInvoice: Invoice = {};
  public isAir: boolean;

  public isRedirectToPreview: boolean;

  constructor(
    private orgApi: OrganisationService,
    private activatedRoute: ActivatedRoute,
    public http: HttpClient,
    private changeDetectorRef: ChangeDetectorRef,
    public commonService: CommonSupportService,
    private renderer: Renderer2,
    private profileSync: GlobalProfileService,
    public orgHelper: OrganisationHelperService,
    public shipmentDocApi: ShipmentDocsService,
    private router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    this.sbDetails.consigneeId = 0;
    this.sbDetails.buyerId = 0;
    this.getVendors();
    this.profileSync.user$.pipe(take(1)).subscribe(user => {
      if (user.Organisation) {
        this.companyProfile = user.Organisation;
      }
    });

    this.invChangeSubscription = this.invChange$.pipe(
      distinctUntilChanged(),
      tap(() => this.invLoading = true),
      switchMap(query => this.shipmentDocApi.searchInvoicesShipmentDocs(
        'SALES',
        query,
      ).pipe(
        catchError(() => of([])), // empty list on error
        tap(() => {
          this.invLoading = false;
        })
      ))
    ).subscribe(items => {
      this.listInvoice$.next(items);
    });

    this.activatedRoute.params.subscribe(param => {
      if (param && param.shippingBillId) {
        this.getShippingBill(param.shippingBillId);
      }
    });
  }

  ngOnDestroy(): void {
    if (this.sbOCRSubscription) {
      this.sbOCRSubscription.unsubscribe();
    }
    if (this.invChangeSubscription) {
      this.invChangeSubscription.unsubscribe();
    }
  }

  canDeactivate(): boolean {
    return !this.shippingBillFormRef.dirty;
  }

  /* Fn to get the list of vendors */
  getVendors(): void {
    this.shouldShowLoader = true;
    this.orgApi.listVendorSupplier()
      .subscribe(contacts => {
        this.shouldShowLoader = false;
        if (contacts && contacts.length > 0) {
          this.orgContacts = contacts;
        } else {
          this.orgContacts = null;
        }
      }, err => {
        this.shouldShowLoader = false;
        SwalHelper.showErrorSwal(err);
      });
  }

  /**
   * Fn to get the shipping bill details
   * @param shippingBillId Shipping Bill Primary key
   */
  getShippingBill(shippingBillId): void {
    this.shouldShowLoader = true;
    this.shipmentDocApi.getShippingBill(shippingBillId)
      .subscribe(sb => {
        this.shouldShowLoader = false;
        this.sbDetails = sb;
        if (this.sbDetails.mode === 'Air') {
          this.isAir = true;
        }
        else {
          this.isAir = false;
        }
        this.invChange$.next('');
        if (this.sbDetails.Invoice) {
          this.selectedInvoice = this.sbDetails.Invoice;
          this.selectedInvoice.currency = this.selectedInvoice.currency || 'USD';
        }

        if (this.sbDetails.Consignee) {
          this.consignee.companyBillingAddress = this.sbDetails.Consignee.companyBillingAddress;
        }

        if (this.sbDetails.Buyer) {
          this.buyer.companyBillingAddress = this.sbDetails.Buyer.companyBillingAddress;
        }

        this.sbDetails.exporterIEC = this.sbDetails.exporterIEC || this.companyProfile.companyName;
        this.sbDetails.exporterAddress = this.sbDetails.exporterAddress || this.companyProfile.registeredAddress;
        this.sbDetails.exporterName = this.sbDetails.exporterName || this.companyProfile.companyName;
        this.sbDetails.GSTIN = this.sbDetails.GSTIN || this.companyProfile.gst;

        if (this.sbDetails.ocrStatus !== 'FAILED' && this.sbDetails.importedDocUrl) {
          this.sbOCRSubscription = interval(3000).pipe(skip(3)).subscribe(tick => {
            this.orgApi.ocrResults('ShippingBill', this.sbDetails.shippingBillId).pipe(
              catchError(err => of(null)),
              filter((result) => !!result),
            ).subscribe(results => {
              if (results && results.length > 0) {
                this.totalPages = results;
                this.sbOCRSubscription.unsubscribe();
                this.ocrResults = results;
                this.loadData(0);
              }
            });
          });
        }
      }, err => {
        this.shouldShowLoader = false;
        SwalHelper.showErrorSwal(err);
      });
  }

  /**
   * Fn to Save the shipping bill details
   * @param form Ng Form
   */
  saveSBDetails(form): boolean {
    if (form.valid) {
      this.sbDetails.assessedDate = this.sbDetails.assessedDate ? moment(this.sbDetails.assessedDate).format('YYYY-MM-DD') : null;
      this.sbDetails.brcRealisationDate = this.sbDetails.brcRealisationDate ? moment(this.sbDetails.brcRealisationDate).format('YYYY-MM-DD') : null;
      this.sbDetails.leoDate = this.sbDetails.leoDate ? moment(this.sbDetails.leoDate).format('YYYY-MM-DD') : null;
      this.sbDetails.shippingBillDate = this.sbDetails.shippingBillDate ? moment(this.sbDetails.shippingBillDate).format('YYYY-MM-DD') : null;

      this.shouldShowLoader = true;
      if (this.sbDetails.shippingBillId) {
        this.shipmentDocApi.updateShippingBill(this.sbDetails).subscribe(shippingBill => {
          this.sbDetails = shippingBill;
          this.shippingBillFormRef.form.markAsPristine();
          this.shouldShowLoader = false;
          if (this.isRedirectToPreview) {
            this.router.navigate([`/organisation/shipment/shipment-documents/shipping-bill/shipping-bill-details/${this.sbDetails.shippingBillId}`]);
          } else {
            SwalHelper.successTimerSwal('Shipping Bill Updated!');
          }
        }, err => {
          this.shouldShowLoader = false;
          SwalHelper.showErrorSwal(err);
        });
      }
    } else {
      return false;
    }
  }

  /* Fn to redirect to preview page */
  redirectToPreview(): void {
    this.isRedirectToPreview = true;
  }

  loadData(index: number): void {
    this.shouldShowLoader = true;
    this.sbDetails.ocrStatus = 'DONE';
    this.http.get(this.ocrResults[index].metadata).subscribe(data => {
      const dummyObj: any = data;
      this.shouldShowLoader = false;
      this.selectedImageURL = this.ocrResults[index].background;
      this.thisPage = dummyObj.fullTextAnnotation.pages[0];
      this.bgwidth = this.thisPage.width;
      this.bgheight = this.thisPage.height;
      this.calBox();
      this.changeDetectorRef.detectChanges();
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  calBox(): void {
    this.selectors = [];
    this.thisPage.blocks.forEach(block => {
      const selectorDiv: SelectorDiv = new SelectorDiv();

      let minboxX = 9999;
      let minboxY = 9999;
      let maxboxX = 0;
      let maxboxY = 0;

      block.boundingBox.vertices.forEach(v => {
        if (v.x < minboxX) {
          minboxX = v.x;
        }
        if (v.y < minboxY) {
          minboxY = v.y;
        }
        if (v.x > maxboxX) {
          maxboxX = v.x;
        }
        if (v.y > maxboxY) {
          maxboxY = v.y;
        }
      });

      const padding = 5;

      selectorDiv.top = minboxY - padding;
      selectorDiv.left = minboxX - padding;
      selectorDiv.height = maxboxY - selectorDiv.top + padding;
      selectorDiv.width = maxboxX - selectorDiv.left + padding;

      selectorDiv.innerText = '';

      block.paragraphs.forEach(p => {
        p.words.forEach(w => {
          w.symbols.forEach(s => {
            selectorDiv.innerText = selectorDiv.innerText + s.text;
          });
          selectorDiv.innerText = selectorDiv.innerText + ' ';
        });
        selectorDiv.innerText = selectorDiv.innerText + '\n';
      });

      selectorDiv.innerText = selectorDiv.innerText.slice(0, -1);
      this.selectors.push(selectorDiv);
    });
  }

  selectText(str): void {
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    const selected =
      document.getSelection().rangeCount > 0
        ? document.getSelection().getRangeAt(0)
        : false;
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    if (selected) {
      document.getSelection().removeAllRanges();
      document.getSelection().addRange(selected);
      this.copyText = 'Copied';
    }
  }

  resetCopytext(): void {
    this.copyText = 'Click to copy';
  }

  linkInv(inv): void {
    if (inv) {
      this.sbDetails.invId = inv.invId;
      this.selectedInvoice = inv;
      this.orgApi.fetchItems(inv.invId)
        .subscribe(items => this.sbDetails.items = items);
    } else {
      this.selectedInvoice = {};
      this.sbDetails.items = [];
    }
  }

  selectConsignee(): void {
    if (this.sbDetails.consigneeId == -1) {
      this.rightSheetOpen();
      this.newContactType = 'Consignee';
    } else {
      const index = this.orgContacts.findIndex(i => i.venSupId == this.sbDetails.consigneeId);
      if (index >= 0) {
        this.consignee = this.orgContacts[index];
        this.sbDetails.Consignee = this.orgContacts[index];
      }
    }
  }

  selectBuyer(): void {
    if (this.sbDetails.buyerId == -1) {
      this.rightSheetOpen();
      this.newContactType = 'Buyer';
    } else {
      const index = this.orgContacts.findIndex(i => i.venSupId == this.sbDetails.buyerId);
      if (index >= 0) {
        this.buyer = this.orgContacts[index];
        this.sbDetails.Buyer = this.orgContacts[index];
      }
    }
  }

  rightSheetOpen(): void {
    this.rightSheet = !this.rightSheet;
    this.renderer.addClass(document.body, 'modal-open');
    this.commonService.addBackdrop();
  }

  rightSheetClose(): void {
    this.rightSheet = false;
    this.renderer.removeClass(document.body, 'modal-open');
    this.commonService.removeBackdrop();
  }

  newVendor(vendor): void {
    this.rightSheetClose();
    this.orgContacts.push(vendor);
    this.sbDetails.consigneeId = this.sbDetails.consigneeId === -1 ? vendor.venSupId : this.sbDetails.consigneeId;
    this.sbDetails.buyerId = this.sbDetails.buyerId === -1 ? vendor.venSupId : this.sbDetails.buyerId;
    this.selectConsignee();
  }

  captureSBFile(event): void {
    if (event.target.files.length + this.sbFiles.length > 5) {
      SwalHelper.showErrorSwal('Maximum 5 files allowed');
      return;
    }
    if (event.target.files && event.target.files.length > 0) {
      Object.keys(event.target.files).forEach((key) => {
        this.sbFiles.push(event.target.files[key]);
      });
    }
  }

  uplaodNewDocument(): void {
    this.shouldShowLoader = true;
    this.orgApi.uploadDocumentsAttachment(
      this.sbFiles[0],
      undefined,
      this.sbDetails.shippingBillId,
      undefined,
      undefined,
      this.sbFiles[1],
      this.sbFiles[2],
      this.sbFiles[3],
      this.sbFiles[4]
    ).subscribe(docs => {
      if (docs) {
        this.shouldShowLoader = false;
        SwalHelper.successTimerSwal('Document uploaded');
        $('#fileUploadModal').modal('hide');
        if (!this.sbDetails.DocumentAttachments) {
          this.sbDetails.DocumentAttachments = [];
        }
        this.sbDetails.DocumentAttachments = docs;
        this.sbFiles = []; // Clean the document file array
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  removeAttchment(index: number): void {
    Swal({
      title: 'Delete Attachment',
      text: 'This cannot be undone',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        this.shouldShowLoader = true;
        return new Promise((resolve, reject) => {
          this.orgApi.deleteDocumentsAttachment(
            this.sbDetails.DocumentAttachments[index].docAttId,
            undefined,
            this.sbDetails.shippingBillId,
          ).subscribe(ok => {
            this.shouldShowLoader = false;
            this.sbDetails.DocumentAttachments.splice(index, 1);
            resolve(ok);
          }, err => {
            reject(err);
          });
        });
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.value) {
        this.shouldShowLoader = false;
        SwalHelper.successTimerSwal('Attachment deleted!');
      }
    }).catch(err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  modeChange(): void {
    if (this.sbDetails.mode === 'Air') {
      this.isAir = true;
    }
    else {
      this.isAir = false;
    }
    this.changeDetectorRef.detectChanges();
  }

  public downloadFile(docAttId: number): void {
    this.orgApi.orgDocumentSignedUrl(docAttId)
      .subscribe(url => {
        window.open(url.url, '_blank');
      });
  }
}

export class SelectorDiv {
  public left: number;
  public top: number;
  public height: number;
  public width: number;
  public innerText: string;
}
