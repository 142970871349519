import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { OrganisationRole, OrganisationService } from 'src/swagger';
import { SwalHelper } from 'src/app/swal';
import { roleTemplate } from 'src/app/roleTemplate';

@Component({
  selector: 'app-organisation-add-edit-roles',
  templateUrl: './organisation-add-edit-roles.component.html',
  styleUrls: ['./organisation-add-edit-roles.component.scss']
})
export class OrganisationAddEditRolesComponent implements OnInit, OnChanges {

  public roleDetails: OrganisationRole = {};
  public shouldShowLoader: boolean;

  @Input() roleId: number;
  @Output() closeOrgRoleToggle = new EventEmitter<OrganisationRole>();

  public isEnabledInvoice = false;
  public isEnabledPO = false;
  public isEnabledSO = false;
  public isEnabledBOE = false;
  public isEnabledBL = false;
  public isEnabledShippingBill = false;
  public isEnabledShipment = false;
  public isEnabledRole = false;
  public isEnabledUser = false;
  public isEnabledContact = false;

  constructor(
    private orgApi: OrganisationService,
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    if (this.roleId) {
      this.getRoleDetails(this.roleId);
    } else {
      this.initRole();
    }
  }

  /* Fn to init new role details */
  initRole(): void {
    this.roleDetails = {};
    this.roleDetails.permissions = roleTemplate;
    this.isEnabledInvoice = false;
    this.isEnabledPO = false;
    this.isEnabledSO = false;
    this.isEnabledBOE = false;
    this.isEnabledBL = false;
    this.isEnabledShippingBill = false;
    this.isEnabledShipment = false;
    this.isEnabledRole = false;
    this.isEnabledUser = false;
    this.isEnabledContact = false;
    this.roleDetails.permissions.ItemMaster = {
      create: true,
      read: true,
      update: false,
      delete: false,
      share: false,
    };
  }

  /**
   * Fn to get the details of role based on role id
   * @param roleId pk of Role object
   */
  getRoleDetails(roleId: number): void {
    this.shouldShowLoader = true;
    this.orgApi.getRole(roleId).subscribe(role => {
      this.shouldShowLoader = false;
      if (role) {
        this.roleDetails = role;
        this.checkValue(this.roleDetails.permissions);
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /**
   * Fn to save the role details
   * @param form Angular form
   */
  saveRoleDetails(form): void {
    if (form.valid) {
      this.shouldShowLoader = true;
      if (this.roleDetails.orgRoleId) {
        this.orgApi.updateRole(this.roleDetails).subscribe(role => {
          this.shouldShowLoader = false;
          this.closeOrgRoleToggle.next(role);
          SwalHelper.successTimerSwal('Role Updated!');
          form.reset();
          this.initRole();
        }, err => {
          this.shouldShowLoader = false;
          SwalHelper.showErrorSwal(err);
        });
      } else {
        this.orgApi.addRole(this.roleDetails).subscribe(role => {
          this.shouldShowLoader = false;
          this.closeOrgRoleToggle.next(role);
          form.reset();
          this.initRole();
          SwalHelper.successTimerSwal('Role Added!');
        }, err => {
          this.shouldShowLoader = false;
          SwalHelper.showErrorSwal(err);
        });
      }
    } else {
      return;
    }
  }

  /* Fn to check is document access is true or false */
  checkValue(object): void {
    this.isEnabledBL = Object.keys(object.BillOfLading).every((k) => object.BillOfLading[k] == true) ? true : false;
    this.isEnabledBOE = Object.keys(object.BillOfEntry).every((k) => object.BillOfEntry[k] == true) ? true : false;
    this.isEnabledShipment = Object.keys(object.Shipment).every((k) => object.Shipment[k] == true) ? true : false;
    this.isEnabledShippingBill = Object.keys(object.ShippingBill).every((k) => object.ShippingBill[k] == true) ? true : false;
    this.isEnabledSO = Object.keys(object.SO).every((k) => object.SO[k] == true) ? true : false;
    this.isEnabledPO = Object.keys(object.PO).every((k) => object.PO[k] == true) ? true : false;
    this.isEnabledInvoice = Object.keys(object.Invoice).every((k) => object.Invoice[k] == true) ? true : false;
    this.isEnabledRole = Object.keys(object.OrganisationRole).every((k) => object.OrganisationRole[k] == true) ? true : false;
    this.isEnabledUser = Object.keys(object.OrganisationUser).every((k) => object.OrganisationUser[k] == true) ? true : false;
    this.isEnabledContact = Object.keys(object.OrganisationContact).every((k) => object.OrganisationContact[k] == true) ? true : false;
  }

  /* Fn to toggle the Purchase order role boolean */
  togglePOAccess(checked): void {
    if (checked) {
      this.roleDetails.permissions.PO = {
        create: true,
        read: true,
        update: true,
        delete: true,
        share: true,
      };
    } else {
      this.roleDetails.permissions.PO = {
        create: false,
        read: false,
        update: false,
        delete: false,
        share: false,
      };
    }
  }

  /* Fn to toggle the sales order role boolean */
  toggleSOAccess(checked): void {
    if (checked) {
      this.roleDetails.permissions.SO = {
        create: true,
        read: true,
        update: true,
        delete: true,
        share: true,
      };
    } else {
      this.roleDetails.permissions.SO = {
        create: false,
        read: false,
        update: false,
        delete: false,
        share: false,
      };
    }
  }

  /* Fn to toggle the invoice/vendor bill role boolean */
  toggleInvoiceAccess(checked): void {
    if (checked) {
      this.roleDetails.permissions.Invoice = {
        create: true,
        read: true,
        update: true,
        delete: true,
        share: true,
      };
    } else {
      this.roleDetails.permissions.Invoice = {
        create: false,
        read: false,
        update: false,
        delete: false,
        share: false,
      };
    }
  }

  /* Fn to toggle the Bill of lading role boolean */
  toggleBOLAccess(checked): void {
    if (checked) {
      this.roleDetails.permissions.BillOfLading = {
        create: true,
        read: true,
        update: true,
        delete: true,
        share: true,
      };
    } else {
      this.roleDetails.permissions.BillOfLading = {
        create: false,
        read: false,
        update: false,
        delete: false,
        share: false,
      };
    }
  }

  /* Fn to toggle the Shipping Bill role boolean */
  toggleSBAccess(checked): void {
    if (checked) {
      this.roleDetails.permissions.ShippingBill = {
        create: true,
        read: true,
        update: true,
        delete: true,
        share: true,
      };
    } else {
      this.roleDetails.permissions.ShippingBill = {
        create: false,
        read: false,
        update: false,
        delete: false,
        share: false,
      };
    }
  }

  /* Fn to toggle the bill of entry role boolean */
  toggleBOEAccess(checked): void {
    if (checked) {
      this.roleDetails.permissions.BillOfEntry = {
        create: true,
        read: true,
        update: true,
        delete: true,
        share: true,
      };
    } else {
      this.roleDetails.permissions.BillOfEntry = {
        create: false,
        read: false,
        update: false,
        delete: false,
        share: false,
      };
    }
  }

  /* Fn to toggle the shipment role boolean */
  toggleShipmentAccess(checked): void {
    if (checked) {
      this.roleDetails.permissions.Shipment = {
        create: true,
        read: true,
        update: true,
        delete: true,
        share: true,
      };
    } else {
      this.roleDetails.permissions.Shipment = {
        create: false,
        read: false,
        update: false,
        delete: false,
        share: false,
      };
    }
  }

  /* Fn to toggle the organisation role boolean */
  toggleRoleAccess(checked): void {
    if (checked) {
      this.roleDetails.permissions.OrganisationRole = {
        create: true,
        read: true,
        update: true,
        delete: true,
        share: true,
      };
    } else {
      this.roleDetails.permissions.OrganisationRole = {
        create: false,
        read: true,
        update: false,
        delete: false,
        share: false,
      };
    }
  }

  /* Fn to toggle the organisation user role boolean */
  toggleUserAccess(checked): void {
    if (checked) {
      this.roleDetails.permissions.OrganisationUser = {
        create: true,
        read: true,
        update: true,
        delete: true,
        share: true,
      };
    } else {
      this.roleDetails.permissions.OrganisationUser = {
        create: false,
        read: false,
        update: false,
        delete: false,
        share: false,
      };
    }
  }

  /* Fn to toggle the organisation contact role boolean */
  toggleContactAccess(checked): void {
    if (checked) {
      this.roleDetails.permissions.OrganisationContact = {
        create: true,
        read: true,
        update: true,
        delete: true,
        share: true,
      };
    } else {
      this.roleDetails.permissions.OrganisationContact = {
        create: false,
        read: true,
        update: false,
        delete: false,
        share: false,
      };
    }
  }
}
