import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { OrganisationService, Organisation, User } from 'src/swagger';
import { GlobalProfileService } from '../../../global-profile.service';
import { SwalHelper } from 'src/app/swal';
import { OrganisationHelperService } from '../../organisation-helper.service';
import { CommonSupportService } from 'src/app/common-support.service';
declare var swal: any;

@Component({
  selector: 'app-organisation-profile-update',
  templateUrl: './organisation-profile-update.component.html',
  styleUrls: ['./organisation-profile-update.component.scss']
})
export class OrganisationProfileUpdateComponent implements OnInit {

  public orgProfile: Organisation = null;
  public orgLogo: File;
  public orgStamp: File;
  public imageSrc: string | ArrayBuffer = '';
  public stampSrc: string | ArrayBuffer = '';
  public shouldShowLoader: boolean;

  @Output() updateProfileSync = new EventEmitter<User>();

  constructor(
    private orgApi: OrganisationService,
    public profileSync: GlobalProfileService,
    public orgHelper: OrganisationHelperService,
    public commonService: CommonSupportService
  ) { }

  ngOnInit(): void {
    this.profileSync.user$.subscribe(user => {
      if (user && user.Organisation) {
        this.orgProfile = this.orgHelper.copyObject(user.Organisation);
        // if (!this.orgProfile.billingAddress) {
        //   this.orgProfile.billingAddress = 'Factory Address: \n\nGST:';
        // }
        this.imageSrc = this.orgProfile.companyLogo;
        this.stampSrc = this.orgProfile.stampUrl;
        this.orgProfile.currency = this.orgProfile.currency ? this.orgProfile.currency : 'USD';
      }
    });
  }

  /* Fn to update the organisation profile*/
  updateOrgProfile(): void {
    this.shouldShowLoader = true;
    this.orgApi.updateOrganisationProfile(this.orgProfile)
      .subscribe(orgProfile => {
        if (this.orgLogo) {
          this.uploadLogo();
        }
        if (this.orgStamp) {
          this.uploadStamp();
        }
        this.shouldShowLoader = false;
        this.profileSync.user.Organisation = orgProfile;
        this.updateProfileSync.next(this.profileSync.user);
        (document.getElementById('logo') as HTMLInputElement).value = '';
        SwalHelper.successTimerSwal('Details Updated');
      }, err => {
        this.shouldShowLoader = false;
        SwalHelper.showErrorSwal(err);
      });
  }

  /**
   * Fn to set the file of logo
   * @param event Event contains file buffer
   */
  setLogo(event): void {
    this.orgLogo = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(this.orgLogo);
    reader.onload = ev => {
      this.imageSrc = reader.result;
    };
  }

  /**
   * Fn to set the file of stamp
   * @param event Event contains file buffer
   */
  setStamp(event): void {
    this.orgStamp = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(this.orgStamp);
    reader.onload = ev => {
      this.stampSrc = reader.result;
    };
  }

  /* Fn to upload the logo */
  uploadLogo(): void {
    this.orgApi.uploadLogo(this.orgLogo)
      .subscribe(url => {
        this.shouldShowLoader = false;
        this.orgLogo = undefined;
        this.profileSync.user.Organisation.companyLogo = url.url;
        this.updateProfileSync.next(this.profileSync.user);
      }, err => {
        this.shouldShowLoader = false;
        SwalHelper.showErrorSwal('Logo not updated , Please try again');
      });
  }

  /* Fn to upload the stamp */
  uploadStamp(): void {
    this.orgApi.uploadStamp(this.orgStamp)
      .subscribe(url => {
        this.shouldShowLoader = false;
        this.orgStamp = undefined;
        this.orgProfile.stampUrl = url.url;
        this.profileSync.user.Organisation.stampUrl = url.url;
        this.updateProfileSync.next(this.profileSync.user);
      }, err => {
        this.shouldShowLoader = false;
        SwalHelper.showErrorSwal('Signature not uploaded , Please try again');
      });
  }

  /* Fn to remove the stamp of organisation profile */
  removestamp(): void {
    swal({
      title: 'Remove Signature',
      text: 'Signature will be removed from all the documents',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Remove',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        this.shouldShowLoader = true;
        return new Promise((resolve, reject) => {
          this.orgApi.deleteStamp(
          ).subscribe(inv => {
            this.shouldShowLoader = false;
            this.orgProfile.stampUrl = null;
            this.stampSrc = undefined;
            this.profileSync.user.Organisation.stampUrl = null;
            this.updateProfileSync.next(this.profileSync.user);
            resolve(inv);
          }, err => {
            reject(err);
          });
        });
      },
      allowOutsideClick: () => !swal.isLoading()
    }).then((result) => {
      if (result.value) {
        this.shouldShowLoader = false;
        SwalHelper.successTimerSwal('Signature removed!');
      }
    }).catch(err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

}
