import { MagicLinkComponent } from './magic-link/magic-link.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from 'src/environments/environment';
import { ApiModule, BASE_PATH } from 'src/swagger';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ChangePasswordComponent } from './auth/change-password/change-password.component';
import { LoginComponent } from './auth/login/login.component';
import { SetNewPasswordComponent } from './auth/set-new-password/set-new-password.component';
import { SignupComponent } from './auth/signup/signup.component';
import { ExternalUserFrameComponent } from './external-user-frame/external-user-frame.component';
import { HelperModuleModule } from './helper-module/helper-module.module';
import { ExchangeAccessSecretComponent } from './auth/exchange-access-secret/exchange-access-secret.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { VerifyUserComponent } from './auth/verify-user/verify-user.component';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, } from '@angular/material/core';
import { SignupServiceProviderComponent } from './auth/signup-service-provider/signup-service-provider.component';
import { ComingSoonComponent } from './coming-soon/coming-soon.component';
import { CanDeactivateGuardGuard } from './form-route-deactivate/can-deactivate-guard.guard';
import { NotFoundComponent } from './not-found/not-found.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ChangePasswordSettingComponent } from './auth/change-password-setting/change-password-setting.component';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RecaptchaModule, ReCaptchaV3Service, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'll',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SignupComponent,
    ChangePasswordComponent,
    SetNewPasswordComponent,
    ExternalUserFrameComponent,
    MagicLinkComponent,
    ExchangeAccessSecretComponent,
    ForgotPasswordComponent,
    VerifyUserComponent,
    SignupServiceProviderComponent,
    ComingSoonComponent,
    NotFoundComponent,
    ChangePasswordSettingComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HelperModuleModule,
    AppRoutingModule,
    HttpClientModule,
    ApiModule,
    BrowserAnimationsModule,
    NgSelectModule,
    MatTooltipModule,
    RecaptchaModule,
    GoogleTagManagerModule.forRoot({
      id: environment.GTM_ID,
    })
  ],
  providers: [
    ReCaptchaV3Service,
    { provide: BASE_PATH, useValue: environment.BASE_PATH },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.RECAPTCHA_SITE_KEY,
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    CanDeactivateGuardGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
