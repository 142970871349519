import { Component, OnInit, Renderer2, Output, Input, EventEmitter, OnChanges, OnDestroy } from '@angular/core';
import { SwalHelper } from 'src/app/swal';
import { Shipment, OrganisationService, OrgShipmentsService, VendorAndSupplier, TagMaster } from 'src/swagger';
import { CommonSupportService } from 'src/app/common-support.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { OrganisationHelperService } from '../organisation-helper.service';
import { GlobalProfileService } from 'src/app/global-profile.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
declare var swal: any;
declare var $: any;
@Component({
  selector: 'app-shipment-updates',
  templateUrl: './shipment-updates.component.html',
  styleUrls: ['./shipment-updates.component.scss']
})
export class ShipmentUpdatesComponent implements OnInit, OnChanges, OnDestroy {

  public newShipment: Shipment = {};
  public shouldShowLoader: boolean;
  public tag: string;

  public vendorSuppliers: VendorAndSupplier[] = [];
  public allMasterTags: TagMaster[] = [];
  public newTags = [];

  public contactRightSheet = false;

  @Output() closeShipmentToggle = new EventEmitter<Shipment>();

  @Input() shipmentId: number;
  @Input() shipment: Shipment;
  @Input() cloneShipmentId: number;
  @Input() newVendorSupplier: VendorAndSupplier;

  private userSubscription: Subscription;

  constructor(
    private orgApi: OrganisationService,
    private orgShipmentApi: OrgShipmentsService,
    private commonService: CommonSupportService,
    private renderer: Renderer2,
    private orgHelperService: OrganisationHelperService,
    private router: Router,
    public profileSync: GlobalProfileService
  ) {
    commonService.renderer = renderer;
  }

  ngOnInit(): void {
    this.userSubscription = this.profileSync.user$.pipe(take(1)).subscribe(user => {
      if (user && user.isInternal) {
        this.fetchvendorSuppliers();
        this.fetchTags('Shipment');
        if (this.shipment) {
          this.newShipment = this.shipment;
          this.fetchNextShipmentNo();
        }
      }
    });
  }

  ngOnChanges(): void {
    if (this.profileSync.user && this.profileSync.user.isInternal) {
      if (this.shipmentId) {
        this.getShipmentDetails(this.shipmentId);
      } else {
        this.initNewShipment();
      }
      if (this.newVendorSupplier && this.newVendorSupplier.venSupId) {
        this.newVendor(this.newVendorSupplier);
      }
    }
  }

  ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }
  // Close the add vendor modal
  modalClose(): void {
    this.newShipment.buyerId = undefined;
    this.newShipment.sellerId = undefined;
  }
  // Fn to Add/Update the shipment details
  saveShipment(): void {
    this.newShipment.buyerId = this.newShipment.buyerId === 0 ? undefined : this.newShipment.buyerId;
    this.newShipment.sellerId = this.newShipment.sellerId === 0 ? undefined : this.newShipment.sellerId;
    this.newShipment.shipmentDate = moment(this.newShipment.shipmentDate).format('YYYY-MM-DD');
    this.createNewTags();
    if (!this.newShipment.companyName) {
      this.getCompanyName();
    }
    if (this.newShipment.buyerId || this.newShipment.sellerId) {
      this.shouldShowLoader = true;
      if (this.cloneShipmentId) {
        this.cloneShipment();
      } else if (this.newShipment.shipmentId) {
        this.orgShipmentApi.updateShipment(
          this.newShipment
        ).subscribe(shipment => {
          this.shouldShowLoader = false;
          this.closeShipmentToggle.next(shipment);
          SwalHelper.successTimerSwal('Shipment Updated');
        }, err => {
          this.shouldShowLoader = false;
          SwalHelper.showErrorSwal(err);
        });
      } else {
        this.orgShipmentApi.createShipment(
          this.newShipment
        ).subscribe(shipment => {
          this.shouldShowLoader = false;
          this.closeShipmentToggle.next(shipment);
          SwalHelper.successTimerSwal('Shipment created');
          this.router.navigate([`/organisation/shipment/uid/${shipment.shipmentUid}`]);
        }, err => {
          this.shouldShowLoader = false;
          SwalHelper.showErrorSwal(err);
        });
      }
    } else {
      SwalHelper.showErrorSwal(`Select ${this.newShipment.shipmentType === 'SELL' ? 'Buyer' : 'Seller'}`);
    }
  }
  // Fn to fetch the shipment tags
  public fetchTags(tagType): void {
    this.orgApi.listTagMaster(tagType).subscribe(tags => {
      if (tags && tags.length > 0) {
        this.allMasterTags = tags;
      } else {
        this.allMasterTags = [];
      }
    }, err => {
      SwalHelper.showErrorSwal(err);
    });
  }
  // Fn to get new tags not present in tagMaster
  checkTag(event): void {
    this.newTags = event.filter(i => !i.tagMasterId);
  }
  // Fn to create new tags
  createNewTags(): void {
    if (this.newTags && this.newTags.length > 0) {
      this.newTags.forEach(i => {
        const newTag: TagMaster = {};
        newTag.tagName = i.tagName;
        newTag.tagDescription = 'Shipment';
        this.orgHelperService.addTag(newTag);
      });
    }
  }
  // Fn to initialize new shipment
  initNewShipment(): void {
    this.newShipment = {};
    this.newShipment.shipmentType = 'SELL';
    this.newShipment.shipmentTags = [];
    this.newShipment.buyerId = 0;
    this.newShipment.sellerId = 0;
    this.newShipment.shipmentCurrency = 'USD';
    this.newShipment.mode = 'Sea';
    this.fetchNextShipmentNo();
  }
  // Fn to fetch next shipment no
  fetchNextShipmentNo(): void {
    this.orgShipmentApi.nextShipmentNo(
      this.newShipment.shipmentType ? this.newShipment.shipmentType : 'SELL'
    ).subscribe(shipment => {
      if (shipment) {
        this.newShipment.shipmentNoPre = shipment.shipmentNoPre;
        this.newShipment.shipmentNoSuf = (parseInt(shipment.shipmentNoSuf, 10) + 1).toString();
      } else {
        this.newShipment.shipmentNoPre = 'INV';
        this.newShipment.shipmentNoSuf = '0001';
      }
    }, err => {
      SwalHelper.showErrorSwal(err);
    });
  }
  // Fn to fetch the company name from array of vendors
  getCompanyName(): void {
    if (this.newShipment.sellerId == -1 || this.newShipment.buyerId == -1) {
      $('#contactAdd').modal({
        show: true,
        backdrop: false
      });
    } else {
      if (this.newShipment.sellerId) {
        const index = this.vendorSuppliers.findIndex(i => i.venSupId == this.newShipment.sellerId);
        this.newShipment.companyName = this.vendorSuppliers[index].companyName;
      }
      if (this.newShipment.buyerId) {
        const index = this.vendorSuppliers.findIndex(i => i.venSupId == this.newShipment.buyerId);
        this.newShipment.companyName = this.vendorSuppliers[index].companyName;
      }
    }
  }
  // Fn to fetch All Vendors
  fetchvendorSuppliers(): void {
    this.newShipment.buyerId = undefined;
    this.newShipment.sellerId = undefined;
    this.shouldShowLoader = true;
    this.orgApi.listVendorSupplier(
      this.newShipment.shipmentType === 'BUY' ? 'SELLER' : 'BUYER'
    ).subscribe(vendors => {
      this.shouldShowLoader = false;
      if (vendors && vendors.length > 0) {
        this.vendorSuppliers = vendors;
      } else {
        this.vendorSuppliers = null;
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }
  // Fn to get the new vendor details & update the shipment data
  newVendor(vendor): void {
    this.contactRightSheetClose();
    this.vendorSuppliers.push(vendor);
    this.newShipment.buyerId = this.newShipment.buyerId == -1 ? vendor.venSupId : undefined;
    this.newShipment.sellerId = this.newShipment.sellerId == -1 ? vendor.venSupId : undefined;
    this.getCompanyName();
    $('#contactAdd').modal('hide');
  }

  /* Fn to open contact right sheet */
  contactRightSheetOpen(): void {
    this.contactRightSheet = !this.contactRightSheet;
    this.renderer.addClass(document.body, 'modal-open');
    this.commonService.addBackdrop();
  }

  /* Fn to Close the contact right sheet*/
  contactRightSheetClose(): void {
    this.contactRightSheet = false;
    this.renderer.removeClass(document.body, 'modal-open');
    this.commonService.removeBackdrop();
  }

  /* Fn to fetch the shipment details */
  getShipmentDetails(shipmentId): void {
    this.shouldShowLoader = true;
    this.orgShipmentApi.getShipment(shipmentId).subscribe(shipment => {
      this.shouldShowLoader = false;
      this.newShipment = shipment;
      this.newShipment.shipmentCurrency = this.newShipment.shipmentCurrency ? this.newShipment.shipmentCurrency : 'USD';
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /* Fn to clone the shipment details */
  cloneShipment(): void {
    swal({
      title: 'Clone/Copy Shipment',
      text: 'This cannot be undone',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Clone',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        this.shouldShowLoader = true;
        return new Promise((resolve, reject) => {
          this.orgShipmentApi.cloneShipment(
            this.cloneShipmentId,
            this.newShipment
          ).subscribe(ship => {
            this.shouldShowLoader = false;
            this.router.navigate([`/organisation/shipment/uid/${ship.shipmentUid}`]);
            $('#cloneShipmentModal').modal('hide');
            resolve(ship);
          }, err => {
            reject(err);
          });
        });
      },
      allowOutsideClick: () => !swal.isLoading()
    }).then((result) => {
      if (result.value) {
        this.shouldShowLoader = false;
        SwalHelper.successTimerSwal('Shipment Cloned!');
      }
    }).catch(err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }
}
