/**
 * Nuphi trade
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2.0.0
 * Contact: shraddha@blackcurrantapps.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CustomExportObject } from '../model/customExportObject';
import { Shipment } from '../model/shipment';
import { ShipmentChecklist } from '../model/shipmentChecklist';
import { ShipmentDialogue } from '../model/shipmentDialogue';
import { ShipmentDocumentFolder } from '../model/shipmentDocumentFolder';
import { SignedUrl } from '../model/signedUrl';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable({
  providedIn: 'root'
})
export class OrgContactService {

    protected basePath = 'http://localhost:3000';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * add dialog to shipment
     * 
     * @param shipmentId 
     * @param isSendEmail 
     * @param ShipmentDialogue 
     * @param emailArray 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addDialog(shipmentId: number, isSendEmail: boolean, ShipmentDialogue: ShipmentDialogue, emailArray?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<Array<ShipmentDialogue>>;
    public addDialog(shipmentId: number, isSendEmail: boolean, ShipmentDialogue: ShipmentDialogue, emailArray?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ShipmentDialogue>>>;
    public addDialog(shipmentId: number, isSendEmail: boolean, ShipmentDialogue: ShipmentDialogue, emailArray?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ShipmentDialogue>>>;
    public addDialog(shipmentId: number, isSendEmail: boolean, ShipmentDialogue: ShipmentDialogue, emailArray?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (shipmentId === null || shipmentId === undefined) {
            throw new Error('Required parameter shipmentId was null or undefined when calling addDialog.');
        }

        if (isSendEmail === null || isSendEmail === undefined) {
            throw new Error('Required parameter isSendEmail was null or undefined when calling addDialog.');
        }

        if (ShipmentDialogue === null || ShipmentDialogue === undefined) {
            throw new Error('Required parameter ShipmentDialogue was null or undefined when calling addDialog.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (shipmentId !== undefined && shipmentId !== null) {
            queryParameters = queryParameters.set('shipmentId', <any>shipmentId);
        }
        if (isSendEmail !== undefined && isSendEmail !== null) {
            queryParameters = queryParameters.set('isSendEmail', <any>isSendEmail);
        }
        if (emailArray) {
            queryParameters = queryParameters.set('emailArray', emailArray.join(COLLECTION_FORMATS['csv']));
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Array<ShipmentDialogue>>(`${this.basePath}/orgContact/add_dialog`,
            ShipmentDialogue,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add a folder
     * 
     * @param shipmentId 
     * @param folderName 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addFolder(shipmentId: number, folderName: string, observe?: 'body', reportProgress?: boolean): Observable<ShipmentDocumentFolder>;
    public addFolder(shipmentId: number, folderName: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ShipmentDocumentFolder>>;
    public addFolder(shipmentId: number, folderName: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ShipmentDocumentFolder>>;
    public addFolder(shipmentId: number, folderName: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (shipmentId === null || shipmentId === undefined) {
            throw new Error('Required parameter shipmentId was null or undefined when calling addFolder.');
        }

        if (folderName === null || folderName === undefined) {
            throw new Error('Required parameter folderName was null or undefined when calling addFolder.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (shipmentId !== undefined && shipmentId !== null) {
            queryParameters = queryParameters.set('shipmentId', <any>shipmentId);
        }
        if (folderName !== undefined && folderName !== null) {
            queryParameters = queryParameters.set('folderName', <any>folderName);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        return this.httpClient.post<ShipmentDocumentFolder>(`${this.basePath}/orgContact/shipment_folder`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Check shipment access
     * Check if you have access to a shipment
     * @param shipmentId 
     * @param shipmentUid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public checkShipmentAccess(shipmentId?: number, shipmentUid?: string, observe?: 'body', reportProgress?: boolean): Observable<Shipment>;
    public checkShipmentAccess(shipmentId?: number, shipmentUid?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Shipment>>;
    public checkShipmentAccess(shipmentId?: number, shipmentUid?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Shipment>>;
    public checkShipmentAccess(shipmentId?: number, shipmentUid?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (shipmentId !== undefined && shipmentId !== null) {
            queryParameters = queryParameters.set('shipmentId', <any>shipmentId);
        }
        if (shipmentUid !== undefined && shipmentUid !== null) {
            queryParameters = queryParameters.set('shipmentUid', <any>shipmentUid);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Shipment>(`${this.basePath}/orgContact/check_shipment_access`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get a signed url to download dialogue attachment file
     * 
     * @param shipmentId 
     * @param shipmentDialogueAttachmentId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public commentAttSignedUrl(shipmentId: number, shipmentDialogueAttachmentId: number, observe?: 'body', reportProgress?: boolean): Observable<SignedUrl>;
    public commentAttSignedUrl(shipmentId: number, shipmentDialogueAttachmentId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SignedUrl>>;
    public commentAttSignedUrl(shipmentId: number, shipmentDialogueAttachmentId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SignedUrl>>;
    public commentAttSignedUrl(shipmentId: number, shipmentDialogueAttachmentId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (shipmentId === null || shipmentId === undefined) {
            throw new Error('Required parameter shipmentId was null or undefined when calling commentAttSignedUrl.');
        }

        if (shipmentDialogueAttachmentId === null || shipmentDialogueAttachmentId === undefined) {
            throw new Error('Required parameter shipmentDialogueAttachmentId was null or undefined when calling commentAttSignedUrl.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (shipmentId !== undefined && shipmentId !== null) {
            queryParameters = queryParameters.set('shipmentId', <any>shipmentId);
        }
        if (shipmentDialogueAttachmentId !== undefined && shipmentDialogueAttachmentId !== null) {
            queryParameters = queryParameters.set('shipmentDialogueAttachmentId', <any>shipmentDialogueAttachmentId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<SignedUrl>(`${this.basePath}/orgContact/comment_att_signed_url`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * delete dialog to shipment
     * 
     * @param shipmentId 
     * @param shipmentDialogueId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteDialog(shipmentId: number, shipmentDialogueId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteDialog(shipmentId: number, shipmentDialogueId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteDialog(shipmentId: number, shipmentDialogueId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteDialog(shipmentId: number, shipmentDialogueId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (shipmentId === null || shipmentId === undefined) {
            throw new Error('Required parameter shipmentId was null or undefined when calling deleteDialog.');
        }

        if (shipmentDialogueId === null || shipmentDialogueId === undefined) {
            throw new Error('Required parameter shipmentDialogueId was null or undefined when calling deleteDialog.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (shipmentId !== undefined && shipmentId !== null) {
            queryParameters = queryParameters.set('shipmentId', <any>shipmentId);
        }
        if (shipmentDialogueId !== undefined && shipmentDialogueId !== null) {
            queryParameters = queryParameters.set('shipmentDialogueId', <any>shipmentDialogueId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/orgContact/delete_dialog`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Export document as a file
     * 
     * @param CustomExportObject 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exportOrgContactDocument(CustomExportObject: CustomExportObject, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public exportOrgContactDocument(CustomExportObject: CustomExportObject, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public exportOrgContactDocument(CustomExportObject: CustomExportObject, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public exportOrgContactDocument(CustomExportObject: CustomExportObject, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (CustomExportObject === null || CustomExportObject === undefined) {
            throw new Error('Required parameter CustomExportObject was null or undefined when calling exportOrgContactDocument.');
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/pdf'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post(`${this.basePath}/orgContact/export_document`,
            CustomExportObject,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get a signed url to download attachment file
     * 
     * @param shipmentUid 
     * @param docAttId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDocumentSignedUrl(shipmentUid: string, docAttId: number, observe?: 'body', reportProgress?: boolean): Observable<SignedUrl>;
    public getDocumentSignedUrl(shipmentUid: string, docAttId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SignedUrl>>;
    public getDocumentSignedUrl(shipmentUid: string, docAttId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SignedUrl>>;
    public getDocumentSignedUrl(shipmentUid: string, docAttId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (shipmentUid === null || shipmentUid === undefined) {
            throw new Error('Required parameter shipmentUid was null or undefined when calling getDocumentSignedUrl.');
        }

        if (docAttId === null || docAttId === undefined) {
            throw new Error('Required parameter docAttId was null or undefined when calling getDocumentSignedUrl.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (shipmentUid !== undefined && shipmentUid !== null) {
            queryParameters = queryParameters.set('shipmentUid', <any>shipmentUid);
        }
        if (docAttId !== undefined && docAttId !== null) {
            queryParameters = queryParameters.set('docAttId', <any>docAttId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<SignedUrl>(`${this.basePath}/orgContact/get_document_signed_url`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get shipment detail
     * Get all the Details of requested shipment id
     * @param shipmentId 
     * @param shipmentUid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrgShipment(shipmentId?: number, shipmentUid?: string, observe?: 'body', reportProgress?: boolean): Observable<Shipment>;
    public getOrgShipment(shipmentId?: number, shipmentUid?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Shipment>>;
    public getOrgShipment(shipmentId?: number, shipmentUid?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Shipment>>;
    public getOrgShipment(shipmentId?: number, shipmentUid?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (shipmentId !== undefined && shipmentId !== null) {
            queryParameters = queryParameters.set('shipmentId', <any>shipmentId);
        }
        if (shipmentUid !== undefined && shipmentUid !== null) {
            queryParameters = queryParameters.set('shipmentUid', <any>shipmentUid);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Shipment>(`${this.basePath}/orgContact/shipment`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get a signed url to download attachment file
     * 
     * @param shipmentId 
     * @param shipmentDocumentId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSignedUrl(shipmentId: number, shipmentDocumentId: number, observe?: 'body', reportProgress?: boolean): Observable<SignedUrl>;
    public getSignedUrl(shipmentId: number, shipmentDocumentId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SignedUrl>>;
    public getSignedUrl(shipmentId: number, shipmentDocumentId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SignedUrl>>;
    public getSignedUrl(shipmentId: number, shipmentDocumentId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (shipmentId === null || shipmentId === undefined) {
            throw new Error('Required parameter shipmentId was null or undefined when calling getSignedUrl.');
        }

        if (shipmentDocumentId === null || shipmentDocumentId === undefined) {
            throw new Error('Required parameter shipmentDocumentId was null or undefined when calling getSignedUrl.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (shipmentId !== undefined && shipmentId !== null) {
            queryParameters = queryParameters.set('shipmentId', <any>shipmentId);
        }
        if (shipmentDocumentId !== undefined && shipmentDocumentId !== null) {
            queryParameters = queryParameters.set('shipmentDocumentId', <any>shipmentDocumentId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<SignedUrl>(`${this.basePath}/orgContact/get_signed_url`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * List the dialogues in desc order based on limit
     * 
     * @param shipmentId 
     * @param search 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listDialogues(shipmentId: number, search?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<ShipmentDialogue>>;
    public listDialogues(shipmentId: number, search?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ShipmentDialogue>>>;
    public listDialogues(shipmentId: number, search?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ShipmentDialogue>>>;
    public listDialogues(shipmentId: number, search?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (shipmentId === null || shipmentId === undefined) {
            throw new Error('Required parameter shipmentId was null or undefined when calling listDialogues.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (shipmentId !== undefined && shipmentId !== null) {
            queryParameters = queryParameters.set('shipmentId', <any>shipmentId);
        }
        if (search !== undefined && search !== null) {
            queryParameters = queryParameters.set('search', <any>search);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<ShipmentDialogue>>(`${this.basePath}/orgContact/list_dialogues`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * List the checklists of the shipment
     * 
     * @param shipmentId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listShipmentChecklist(shipmentId?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<ShipmentChecklist>>;
    public listShipmentChecklist(shipmentId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ShipmentChecklist>>>;
    public listShipmentChecklist(shipmentId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ShipmentChecklist>>>;
    public listShipmentChecklist(shipmentId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (shipmentId !== undefined && shipmentId !== null) {
            queryParameters = queryParameters.set('shipmentId', <any>shipmentId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<ShipmentChecklist>>(`${this.basePath}/orgContact/list_shipment_checklist`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * List the document folders of the shipment
     * 
     * @param shipmentId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listShipmentFolders(shipmentId?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<ShipmentDocumentFolder>>;
    public listShipmentFolders(shipmentId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ShipmentDocumentFolder>>>;
    public listShipmentFolders(shipmentId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ShipmentDocumentFolder>>>;
    public listShipmentFolders(shipmentId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (shipmentId !== undefined && shipmentId !== null) {
            queryParameters = queryParameters.set('shipmentId', <any>shipmentId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<ShipmentDocumentFolder>>(`${this.basePath}/orgContact/list_shipment_folders`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Pin the shipment dialogue
     * 
     * @param shipmentId 
     * @param shipmentDialogueId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pinDialogue(shipmentId: number, shipmentDialogueId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public pinDialogue(shipmentId: number, shipmentDialogueId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public pinDialogue(shipmentId: number, shipmentDialogueId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public pinDialogue(shipmentId: number, shipmentDialogueId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (shipmentId === null || shipmentId === undefined) {
            throw new Error('Required parameter shipmentId was null or undefined when calling pinDialogue.');
        }

        if (shipmentDialogueId === null || shipmentDialogueId === undefined) {
            throw new Error('Required parameter shipmentDialogueId was null or undefined when calling pinDialogue.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (shipmentId !== undefined && shipmentId !== null) {
            queryParameters = queryParameters.set('shipmentId', <any>shipmentId);
        }
        if (shipmentDialogueId !== undefined && shipmentDialogueId !== null) {
            queryParameters = queryParameters.set('shipmentDialogueId', <any>shipmentDialogueId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<any>(`${this.basePath}/orgContact/pin_dialogue`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Rename a folder
     * 
     * @param folderId 
     * @param shipmentId 
     * @param folderName 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public renameFolder(folderId: number, shipmentId: number, folderName: string, observe?: 'body', reportProgress?: boolean): Observable<ShipmentDocumentFolder>;
    public renameFolder(folderId: number, shipmentId: number, folderName: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ShipmentDocumentFolder>>;
    public renameFolder(folderId: number, shipmentId: number, folderName: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ShipmentDocumentFolder>>;
    public renameFolder(folderId: number, shipmentId: number, folderName: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (folderId === null || folderId === undefined) {
            throw new Error('Required parameter folderId was null or undefined when calling renameFolder.');
        }

        if (shipmentId === null || shipmentId === undefined) {
            throw new Error('Required parameter shipmentId was null or undefined when calling renameFolder.');
        }

        if (folderName === null || folderName === undefined) {
            throw new Error('Required parameter folderName was null or undefined when calling renameFolder.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (folderId !== undefined && folderId !== null) {
            queryParameters = queryParameters.set('folderId', <any>folderId);
        }
        if (shipmentId !== undefined && shipmentId !== null) {
            queryParameters = queryParameters.set('shipmentId', <any>shipmentId);
        }
        if (folderName !== undefined && folderName !== null) {
            queryParameters = queryParameters.set('folderName', <any>folderName);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        return this.httpClient.put<ShipmentDocumentFolder>(`${this.basePath}/orgContact/shipment_folder`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update the shipment checklist item
     * 
     * @param shipmentId 
     * @param shipmentChecklistId 
     * @param checked 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public shipmentChecklistToggle(shipmentId: number, shipmentChecklistId: number, checked?: boolean, observe?: 'body', reportProgress?: boolean): Observable<ShipmentChecklist>;
    public shipmentChecklistToggle(shipmentId: number, shipmentChecklistId: number, checked?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ShipmentChecklist>>;
    public shipmentChecklistToggle(shipmentId: number, shipmentChecklistId: number, checked?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ShipmentChecklist>>;
    public shipmentChecklistToggle(shipmentId: number, shipmentChecklistId: number, checked?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (shipmentId === null || shipmentId === undefined) {
            throw new Error('Required parameter shipmentId was null or undefined when calling shipmentChecklistToggle.');
        }

        if (shipmentChecklistId === null || shipmentChecklistId === undefined) {
            throw new Error('Required parameter shipmentChecklistId was null or undefined when calling shipmentChecklistToggle.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (shipmentId !== undefined && shipmentId !== null) {
            queryParameters = queryParameters.set('shipmentId', <any>shipmentId);
        }
        if (shipmentChecklistId !== undefined && shipmentChecklistId !== null) {
            queryParameters = queryParameters.set('shipmentChecklistId', <any>shipmentChecklistId);
        }
        if (checked !== undefined && checked !== null) {
            queryParameters = queryParameters.set('checked', <any>checked);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.put<ShipmentChecklist>(`${this.basePath}/orgContact/shipment_checklist_toggle`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * update shipment
     * Update a requested shipment for an organisation
     * @param shipmentId 
     * @param shipmentETA 
     * @param shipmentETD 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public shipmentEtdates(shipmentId: number, shipmentETA?: string, shipmentETD?: string, observe?: 'body', reportProgress?: boolean): Observable<Shipment>;
    public shipmentEtdates(shipmentId: number, shipmentETA?: string, shipmentETD?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Shipment>>;
    public shipmentEtdates(shipmentId: number, shipmentETA?: string, shipmentETD?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Shipment>>;
    public shipmentEtdates(shipmentId: number, shipmentETA?: string, shipmentETD?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (shipmentId === null || shipmentId === undefined) {
            throw new Error('Required parameter shipmentId was null or undefined when calling shipmentEtdates.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (shipmentId !== undefined && shipmentId !== null) {
            queryParameters = queryParameters.set('shipmentId', <any>shipmentId);
        }
        if (shipmentETA !== undefined && shipmentETA !== null) {
            queryParameters = queryParameters.set('shipmentETA', <any>shipmentETA);
        }
        if (shipmentETD !== undefined && shipmentETD !== null) {
            queryParameters = queryParameters.set('shipmentETD', <any>shipmentETD);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.put<Shipment>(`${this.basePath}/orgContact/shipment_etdates`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Unpin the existing pinned shipment dialogue
     * 
     * @param shipmentId 
     * @param shipmentDialogueId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public unpinDialogue(shipmentId: number, shipmentDialogueId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public unpinDialogue(shipmentId: number, shipmentDialogueId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public unpinDialogue(shipmentId: number, shipmentDialogueId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public unpinDialogue(shipmentId: number, shipmentDialogueId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (shipmentId === null || shipmentId === undefined) {
            throw new Error('Required parameter shipmentId was null or undefined when calling unpinDialogue.');
        }

        if (shipmentDialogueId === null || shipmentDialogueId === undefined) {
            throw new Error('Required parameter shipmentDialogueId was null or undefined when calling unpinDialogue.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (shipmentId !== undefined && shipmentId !== null) {
            queryParameters = queryParameters.set('shipmentId', <any>shipmentId);
        }
        if (shipmentDialogueId !== undefined && shipmentDialogueId !== null) {
            queryParameters = queryParameters.set('shipmentDialogueId', <any>shipmentDialogueId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/orgContact/unpin_dialogue`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * upload an attachment to the shipment
     * 
     * @param shipmentId 
     * @param folderId 
     * @param docType SHIPMENT_DOCUMENT_TYPE enum only
     * @param file1 
     * @param file2 
     * @param file3 
     * @param file4 
     * @param file5 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public uploadFolderAttachment(shipmentId: number, folderId: number, docType: string, file1: Blob, file2?: Blob, file3?: Blob, file4?: Blob, file5?: Blob, observe?: 'body', reportProgress?: boolean): Observable<ShipmentDocumentFolder>;
    public uploadFolderAttachment(shipmentId: number, folderId: number, docType: string, file1: Blob, file2?: Blob, file3?: Blob, file4?: Blob, file5?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ShipmentDocumentFolder>>;
    public uploadFolderAttachment(shipmentId: number, folderId: number, docType: string, file1: Blob, file2?: Blob, file3?: Blob, file4?: Blob, file5?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ShipmentDocumentFolder>>;
    public uploadFolderAttachment(shipmentId: number, folderId: number, docType: string, file1: Blob, file2?: Blob, file3?: Blob, file4?: Blob, file5?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (shipmentId === null || shipmentId === undefined) {
            throw new Error('Required parameter shipmentId was null or undefined when calling uploadFolderAttachment.');
        }

        if (folderId === null || folderId === undefined) {
            throw new Error('Required parameter folderId was null or undefined when calling uploadFolderAttachment.');
        }

        if (docType === null || docType === undefined) {
            throw new Error('Required parameter docType was null or undefined when calling uploadFolderAttachment.');
        }

        if (file1 === null || file1 === undefined) {
            throw new Error('Required parameter file1 was null or undefined when calling uploadFolderAttachment.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (shipmentId !== undefined && shipmentId !== null) {
            queryParameters = queryParameters.set('shipmentId', <any>shipmentId);
        }
        if (folderId !== undefined && folderId !== null) {
            queryParameters = queryParameters.set('folderId', <any>folderId);
        }
        if (docType !== undefined && docType !== null) {
            queryParameters = queryParameters.set('docType', <any>docType);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file1 !== undefined) {
            formParams.append('file1', <any>file1);
        }
        if (file2 !== undefined) {
            formParams.append('file2', <any>file2);
        }
        if (file3 !== undefined) {
            formParams.append('file3', <any>file3);
        }
        if (file4 !== undefined) {
            formParams.append('file4', <any>file4);
        }
        if (file5 !== undefined) {
            formParams.append('file5', <any>file5);
        }

        return this.httpClient.post<ShipmentDocumentFolder>(`${this.basePath}/orgContact/upload_attachment`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
