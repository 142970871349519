import { Component, OnInit } from '@angular/core';
import { LoginRequest, OpenService } from '../../../swagger';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalProfileService } from '../../global-profile.service';
import { SwalHelper } from 'src/app/swal';
declare var swal: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public loginRequest: LoginRequest = {};
  public isTwoFactor: boolean;

  constructor(
    public activatedRoute: ActivatedRoute,
    public profileSync: GlobalProfileService,
    public router: Router,
    public openApi: OpenService,
  ) { }

  ngOnInit(): void {}

  /* Fn to first check if 2 factor authentication is enabled */
  public twoFactorAuth(): void {
    if (this.isTwoFactor && this.loginRequest.otp) {
      this.Login();
    } else {
      this.openApi.isTwofactorEnabled(this.loginRequest.email).subscribe(ok => {
        if (ok.isEnabled) {
          // show otp field and then login
          this.isTwoFactor = true;
        } else {
          this.isTwoFactor = false;
          this.Login();
        }
      }, err => {
        SwalHelper.showErrorSwal(err);
      });
    }
  }

  /* Fn to login & redirect the user to appropriate module */
  public Login(): void {

    if (this.loginRequest) {
      const that = this;
      swal({
        title: 'Logging you in',
        html: '<i class="fa fa-circle-check fa-spin"></i>',
        type: 'info',
        showConfirmButton: true,
        showCancelButton: false,
      });

      this.profileSync.updateUserProfile(null);

      that.openApi.login(this.loginRequest).subscribe(user => {
        this.profileSync.setToken(user.token);
        swal.close();
        this.profileSync.updateUserProfile(user);
        if (user && user.orgId) {
          if (!user.Organisation.registeredAddress || !user.Organisation.gst) {
            that.router.navigate(['/welcome/profile']);
          } else {
            that.router.navigate(['/organisation']);
          }
        } else if (user && user.servProvId) {
          that.router.navigate(['/service-provider']);
        } else {
          console.log('Failed to get user profile');
        }
      }, err => {
        swal.close();
        SwalHelper.showErrorSwal(err);
      });
    }
  }

}
