/**
 * Nuphi trade
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2.0.0
 * Contact: shraddha@blackcurrantapps.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { DocumentAttachment } from './documentAttachment';
import { Organisation } from './organisation';
import { PurchaseOrderItem } from './purchaseOrderItem';
import { ShipmentDocument } from './shipmentDocument';
import { StatusHistory } from './statusHistory';
import { VendorAndSupplier } from './vendorAndSupplier';


export interface PurchaseOrder { 
    purchaseOrderId?: number;
    poUid?: string;
    sellerId?: number;
    consigneeId?: number;
    notifyPartyId?: number;
    notifyPartyId2?: number;
    orgId?: number;
    poHeading?: string;
    poTotalAmount?: number;
    poTotalQuantity?: number;
    currency?: string;
    poDeliveryDate?: string;
    vendorCode?: string;
    shipmentDelTerms?: string;
    poDispatchDate?: string;
    deliveryInstructions?: string;
    amountInWords?: string;
    deliveryAddress?: string;
    destinationPort?: string;
    destinationETA?: string;
    destinationCountry?: string;
    originPort?: string;
    originETA?: string;
    originCountry?: string;
    documents?: string;
    conditions?: string;
    dutyRate?: number;
    incoTerms?: string;
    incoTermsPlace?: string;
    paymentTerms?: string;
    orgOrderDate?: string;
    sellerOrderDate?: string;
    orgOrderNo?: string;
    sellerOrderNo?: string;
    sellerSONo?: string;
    orderRevisionNo?: string;
    specialInstruction?: string;
    tarrifCode?: string;
    createdBy?: string;
    status?: PurchaseOrder.StatusEnum;
    importedDocUrl?: string;
    ocrS3FolderUrl?: string;
    numPages?: number;
    ocrStatus?: string;
    templateName?: string;
    otherData?: any;
    contactPerson?: any;
    bankDetailsInfo?: any;
    portOfLoading?: string;
    noOfPackagesDes?: string;
    quantityDescription?: string;
    quantityPerPackDes?: string;
    unitPriceDes?: string;
    deliveryLabel?: string;
    isNoOfPackagesVisible?: boolean;
    isQtyPerPackageVisible?: boolean;
    advanceAmount?: number;
    advanceAmountDate?: string;
    VendorAndSupplier?: VendorAndSupplier;
    Consignee?: VendorAndSupplier;
    NotifyParty?: VendorAndSupplier;
    NotifyParty2?: VendorAndSupplier;
    Organisation?: Organisation;
    PurchaseOrderItems?: Array<PurchaseOrderItem>;
    DocumentAttachments?: Array<DocumentAttachment>;
    StatusHistories?: Array<StatusHistory>;
    ShipmentDocuments?: Array<ShipmentDocument>;
}
export namespace PurchaseOrder {
    export type StatusEnum = 'DRAFT' | 'ACCEPTED' | 'IN_PROGRESS' | 'DELAYED' | 'COMPLETED' | 'CANCELLED' | 'ARCHIVED';
    export const StatusEnum = {
        DRAFT: 'DRAFT' as StatusEnum,
        ACCEPTED: 'ACCEPTED' as StatusEnum,
        INPROGRESS: 'IN_PROGRESS' as StatusEnum,
        DELAYED: 'DELAYED' as StatusEnum,
        COMPLETED: 'COMPLETED' as StatusEnum,
        CANCELLED: 'CANCELLED' as StatusEnum,
        ARCHIVED: 'ARCHIVED' as StatusEnum
    };
}
