import { Component, OnInit, OnDestroy, ChangeDetectorRef, Renderer2, ViewChild } from '@angular/core';
import { OrganisationService, BillOfLading, VendorAndSupplier, Invoice, Shipment, Page, ShipmentDocsService } from 'src/swagger';
import { SwalHelper } from 'src/app/swal';
import { ActivatedRoute, Router } from '@angular/router';
import { interval, of, Subject, Subscription } from 'rxjs';
import { skip, catchError, filter, distinctUntilChanged, tap, switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { CommonSupportService } from 'src/app/common-support.service';
import { GlobalProfileService } from 'src/app/global-profile.service';
import { ComponentCanDeactivate } from 'src/app/form-route-deactivate/component-can-deactivate';
import { NgForm } from '@angular/forms';
import * as moment from 'moment';
declare var Swal: any;
declare var $: any;

@Component({
  selector: 'app-bill-of-lading-update',
  templateUrl: './bill-of-lading-update.component.html',
  styleUrls: ['./bill-of-lading-update.component.scss']
})
export class BillOfLadingUpdateComponent extends ComponentCanDeactivate implements OnInit, OnDestroy {

  @ViewChild('BOLForm')
  public BOLFormRef: NgForm;

  public shouldShowLoader: boolean;
  public billOfLading: BillOfLading = {};
  public shipments: Shipment[] = [];
  public bolOCRSubscription: Subscription;
  public totalPages = [];
  public ocrResults: Page[] = [];
  public selectors: SelectorDiv[] = [];
  public orgContacts: VendorAndSupplier[] = [];
  public notifyParty: VendorAndSupplier = {};
  public consignee: VendorAndSupplier = {};

  public thisPage: any;
  public bgwidth;
  public bgheight;

  public copyText = 'Click to copy';
  public selectedImageURL: string;

  public invId: any;

  public rightSheet = false;
  public newContactType: string;

  public invChange$: Subject<string> = new Subject();
  public listInvoice$: Subject<Invoice[]> = new Subject();
  private invChangeSubscription: Subscription;
  public invLoading = false;
  public selectedInvoice: Invoice = {};

  public bolFiles: File[] = [];
  public isAir: boolean;

  public isRedirectToPreview: boolean;

  constructor(
    private orgApi: OrganisationService,
    private activatedRoute: ActivatedRoute,
    public http: HttpClient,
    private changeDetectorRef: ChangeDetectorRef,
    private renderer: Renderer2,
    public commonService: CommonSupportService,
    private profileSync: GlobalProfileService,
    private shipmentDocApi: ShipmentDocsService,
    private router: Router
  ) {
    super();
    commonService.renderer = renderer;
  }

  ngOnInit(): void {

    this.billOfLading.consigneeId = 0;
    this.billOfLading.notifyPartyId = 0;
    this.fetchVendors();

    this.activatedRoute.params.subscribe(param => {
      if (param && param.billOfLadingId) {
        this.fetchBOL(param.billOfLadingId);
      }
    });

    this.invChangeSubscription = this.invChange$.pipe(
      distinctUntilChanged(),
      tap(() => this.invLoading = true),
      switchMap(query => this.shipmentDocApi.searchInvoicesShipmentDocs(
        undefined,
        query,
      ).pipe(
        catchError(() => of([])), // empty list on error
        tap(() => {
          this.invLoading = false;
        })
      ))
    ).subscribe(items => {
      this.listInvoice$.next(items);
    });
  }

  ngOnDestroy(): void {
    if (this.bolOCRSubscription) {
      this.bolOCRSubscription.unsubscribe();
    }
    if (this.invChangeSubscription) {
      this.invChangeSubscription.unsubscribe();
    }
  }

  canDeactivate(): boolean {
    return !this.BOLFormRef.dirty;
  }

  fetchVendors(): void {
    this.shouldShowLoader = true;
    this.orgApi.listVendorSupplier()
      .subscribe(vendors => {
        this.shouldShowLoader = false;
        if (vendors && vendors.length > 0) {
          this.orgContacts = vendors;
        } else {
          this.orgContacts = null;
        }
      }, err => {
        this.shouldShowLoader = false;
        SwalHelper.showErrorSwal(err);
      });
  }

  fetchBOL(billOfLadingId): void {
    this.shipmentDocApi.getBillOfLading(billOfLadingId)
      .subscribe(bol => {
        this.shouldShowLoader = false;
        this.billOfLading = bol;

        if (this.billOfLading.mode === 'Air') {
          this.isAir = true;
        }
        else {
          this.isAir = false;
        }
        this.invChange$.next('');
        if (this.billOfLading.Consignee) {
          this.consignee.companyBillingAddress = this.billOfLading.Consignee.companyBillingAddress;
        }
        this.selectedInvoice = this.billOfLading.Invoice ? this.billOfLading.Invoice : {};
        if (this.billOfLading.NotifyParty) {
          this.notifyParty.companyBillingAddress = this.billOfLading.NotifyParty.companyBillingAddress;
        }
        this.billOfLading.containerSize = this.billOfLading.containerSize || [];
        this.billOfLading.shipperName = this.billOfLading.shipperName || this.profileSync.user.Organisation.companyName;
        this.billOfLading.shipperAddress = this.billOfLading.shipperAddress || this.profileSync.user.Organisation.registeredAddress;

        if (this.billOfLading.ocrStatus !== 'FAILED' && this.billOfLading.importedDocUrl) {
          this.bolOCRSubscription = interval(3000).pipe(skip(3)).subscribe(tick => {
            this.orgApi.ocrResults('BillOfLading', this.billOfLading.billOfLadingId).pipe(
              catchError(err => of(null)),
              filter((result) => !!result),
            ).subscribe(results => {
              if (results && results.length > 0) {
                this.totalPages = results;
                this.bolOCRSubscription.unsubscribe();
                this.ocrResults = results;
                this.loadData(0);
              }
            });
          });
        }
      }, err => {
        this.shouldShowLoader = false;
        SwalHelper.showErrorSwal(err);
      });
  }

  loadData(index: number): void {
    this.shouldShowLoader = true;
    this.billOfLading.ocrStatus = 'DONE';
    this.http.get(this.ocrResults[index].metadata).subscribe(data => {
      const dummyObj: any = data;
      this.shouldShowLoader = false;
      this.selectedImageURL = this.ocrResults[index].background;
      this.thisPage = dummyObj.fullTextAnnotation.pages[0];
      this.bgwidth = this.thisPage.width;
      this.bgheight = this.thisPage.height;
      this.calBox();
      this.changeDetectorRef.detectChanges();
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  calBox(): void {
    this.selectors = [];
    this.thisPage.blocks.forEach(block => {
      const selectorDiv: SelectorDiv = new SelectorDiv();

      let minboxX = 9999;
      let minboxY = 9999;
      let maxboxX = 0;
      let maxboxY = 0;

      block.boundingBox.vertices.forEach(v => {
        if (v.x < minboxX) {
          minboxX = v.x;
        }
        if (v.y < minboxY) {
          minboxY = v.y;
        }
        if (v.x > maxboxX) {
          maxboxX = v.x;
        }
        if (v.y > maxboxY) {
          maxboxY = v.y;
        }
      });

      const padding = 5;

      selectorDiv.top = minboxY - padding;
      selectorDiv.left = minboxX - padding;
      selectorDiv.height = maxboxY - selectorDiv.top + padding;
      selectorDiv.width = maxboxX - selectorDiv.left + padding;

      selectorDiv.innerText = '';

      block.paragraphs.forEach(p => {
        p.words.forEach(w => {
          w.symbols.forEach(s => {
            selectorDiv.innerText = selectorDiv.innerText + s.text;
          });
          selectorDiv.innerText = selectorDiv.innerText + ' ';
        });
        selectorDiv.innerText = selectorDiv.innerText + '\n';
      });

      selectorDiv.innerText = selectorDiv.innerText.slice(0, -1);
      this.selectors.push(selectorDiv);
    });
  }

  selectText(str): void {
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    const selected =
      document.getSelection().rangeCount > 0
        ? document.getSelection().getRangeAt(0)
        : false;
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    if (selected) {
      document.getSelection().removeAllRanges();
      document.getSelection().addRange(selected);
      this.copyText = 'Copied';
    }
  }

  resetCopytext(): void {
    this.copyText = 'Click to copy';
  }

  linkInv(inv): void {
    if (inv) {
      this.billOfLading.invId = inv.invId;
      this.selectedInvoice.invNo = inv.invNo;
      this.selectedInvoice.dateOfInv = inv.dateOfInv;
      this.billOfLading.portOfLoading = inv.portOfLoading;
      this.billOfLading.portOfDischarge = inv.destinationPort;
      this.billOfLading.vesselName = inv.vesselName;
      this.billOfLading.consigneeId = inv.customsConsigneeId;
      this.billOfLading.mode = inv.modeOfShipment;
      this.billOfLading.containerNo = inv.containerNo;
      this.billOfLading.totalWeight = inv.totalNetWeight;
    } else {
      this.selectedInvoice = {};
    }
  }

  selectConsignee(): void {
    if (this.billOfLading.consigneeId == -1) {
      this.rightSheetOpen();
      this.newContactType = 'Consignee';
      this.consignee = {};
    } else {
      const index = this.orgContacts.findIndex(i => i.venSupId == this.billOfLading.consigneeId);
      if (index >= 0) {
        this.consignee = this.orgContacts[index];
      }
    }
  }

  addField(): void {
    const field: CustomField = {
      fieldName: '',
      unit: '',
      value: 0,
    };
    this.billOfLading.containerSize.push(field);
  }

  removeField(i): void {
    Swal.fire({
      title: 'Remove Container Dimension ?',
      text: 'Are you sure want to delete this row?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete',
    }).then((result) => {
      if (result.value) {
        this.billOfLading.containerSize.splice(i, 1);
        SwalHelper.showToast('success', 'Row deleted!');
      }
    });
  }

  selectNotify(): void {
    if (this.billOfLading.notifyPartyId == -1) {
      this.rightSheetOpen();
      this.newContactType = 'Notify Party';
    } else {
      const index = this.orgContacts.findIndex(i => i.venSupId == this.billOfLading.notifyPartyId);
      if (index >= 0) {
        this.notifyParty = this.orgContacts[index];
        this.billOfLading.NotifyParty = this.orgContacts[index];
      }
    }
  }

  rightSheetOpen(): void {
    this.rightSheet = !this.rightSheet;
    this.renderer.addClass(document.body, 'modal-open');
    this.commonService.addBackdrop();
  }

  rightSheetClose(): void {
    this.rightSheet = false;
    this.renderer.removeClass(document.body, 'modal-open');
    this.commonService.removeBackdrop();
  }

  resetRightsheet(): void {
    this.billOfLading.consigneeId = this.billOfLading.consigneeId > 0 ? this.billOfLading.consigneeId : 0;
    this.rightSheetClose();
  }

  newVendor(vendor): void {
    this.orgContacts.push(vendor);
    this.billOfLading.consigneeId = this.billOfLading.consigneeId == -1 ? vendor.venSupId : this.billOfLading.consigneeId;
    this.billOfLading.notifyPartyId = this.billOfLading.notifyPartyId == -1 ? vendor.venSupId : this.billOfLading.notifyPartyId;
    this.selectNotify();
    this.selectConsignee();
    this.rightSheetClose();
  }

  saveBol(): void {
    if (this.billOfLading.consigneeId <= 0) {
      SwalHelper.showWarningSwal('Please select consignee');
      return;
    }
    this.shouldShowLoader = true;
    this.billOfLading.blDate = this.billOfLading.blDate ? moment(this.billOfLading.blDate).format('YYYY-MM-DD') : null;
    this.billOfLading.receivedForShipmentDate = this.billOfLading.receivedForShipmentDate ? moment(this.billOfLading.receivedForShipmentDate).format('YYYY-MM-DD') : null;
    this.billOfLading.shippedOnBoardDate = this.billOfLading.shippedOnBoardDate ? moment(this.billOfLading.shippedOnBoardDate).format('YYYY-MM-DD') : null;
    this.billOfLading.vesselSailingDate = this.billOfLading.vesselSailingDate ? moment(this.billOfLading.vesselSailingDate).format('YYYY-MM-DD') : null;

    this.billOfLading.consigneeId = this.billOfLading.consigneeId > 0 ? this.billOfLading.consigneeId : null;
    this.billOfLading.notifyPartyId = this.billOfLading.notifyPartyId > 0 ? this.billOfLading.notifyPartyId : null;

    if (this.billOfLading.billOfLadingId) {
      this.shipmentDocApi.updateBillOfLading(this.billOfLading)
        .subscribe(bol => {
          this.billOfLading = bol;
          this.BOLFormRef.form.markAsPristine();
          this.shouldShowLoader = false;
          if (this.isRedirectToPreview) {
            this.router.navigate([`/organisation/shipment/shipment-documents/bill-of-lading/bill-of-lading-details/${this.billOfLading.billOfLadingId}`]);
          } else {
            SwalHelper.successTimerSwal('Bill of Lading updated!');
          }
        }, err => {
          this.shouldShowLoader = false;
          SwalHelper.showErrorSwal(err);
        });
    }
  }
  captureBOEFile(event): void {
    if (event.target.files.length + this.bolFiles.length > 5) {
      SwalHelper.showErrorSwal('Maximum 5 files allowed');
      return;
    }
    if (event.target.files && event.target.files.length > 0) {
      Object.keys(event.target.files).forEach((key) => {
        this.bolFiles.push(event.target.files[key]);
      });
    }
  }

  /* Fn to redirect to preview page */
  redirectToPreview(): void {
    this.isRedirectToPreview = true;
  }


  uplaodNewDocument(): void {
    this.shouldShowLoader = true;
    this.orgApi.uploadDocumentsAttachment(
      this.bolFiles[0],
      undefined,
      undefined,
      undefined,
      this.billOfLading.billOfLadingId,
      this.bolFiles[1],
      this.bolFiles[2],
      this.bolFiles[3],
      this.bolFiles[4]
    ).subscribe(docs => {
      if (docs) {
        this.shouldShowLoader = false;
        SwalHelper.successTimerSwal('Document uploaded');
        $('#fileUploadModal').modal('hide');
        if (!this.billOfLading.DocumentAttachments) {
          this.billOfLading.DocumentAttachments = [];
        }
        this.billOfLading.DocumentAttachments = docs;
        this.bolFiles = []; // Clean the document file array
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }
  removeAttchment(index: number): void {
    Swal({
      title: 'Delete Attachment',
      text: 'This cannot be undone',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        this.shouldShowLoader = true;
        return new Promise((resolve, reject) => {
          this.orgApi.deleteDocumentsAttachment(
            this.billOfLading.DocumentAttachments[index].docAttId,
            undefined,
            this.billOfLading.billOfLadingId,
          ).subscribe(ok => {
            this.shouldShowLoader = false;
            this.billOfLading.DocumentAttachments.splice(index, 1);
            resolve(ok);
          }, err => {
            reject(err);
          });
        });
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.value) {
        this.shouldShowLoader = false;
        SwalHelper.successTimerSwal('Attachment deleted!');
      }
    }).catch(err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  modeChange(): void {
    if (this.billOfLading.mode === 'Air') {
      this.isAir = true;
    }
    else {
      this.isAir = false;
    }
    this.changeDetectorRef.detectChanges();
  }

  public downloadFile(docAttId: number): void {
    this.orgApi.orgDocumentSignedUrl(docAttId)
      .subscribe(url => {
        window.open(url.url, '_blank');
      });
  }
}

export class SelectorDiv {
  public left: number;
  public top: number;
  public height: number;
  public width: number;
  public innerText: string;
}

export class CustomField {
  public fieldName?: string;
  public unit?: string;
  public value?: number;
}
