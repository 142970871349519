import { Component, OnInit } from '@angular/core';
import { ReportsService } from '../../../../swagger/api/reports.service';
import { OrganisationHelperService } from '../../organisation-helper.service';
import { GlobalProfileService } from '../../../global-profile.service';
import * as moment from 'moment';
import { SwalHelper } from 'src/app/swal';
import { CommonSupportService } from '../../../common-support.service';

@Component({
  selector: 'app-order-recon-report',
  templateUrl: './order-recon-report.component.html',
  styleUrls: ['./order-recon-report.component.scss']
})
export class OrderReconReportComponent implements OnInit {

  public allSalesOrders = [];
  public allPurchaseOrders = [];
  public shouldShowLoader: boolean;

  public fromDate: string;
  public toDate: string;
  public status: string;
  public currency: string;
  public orderType = 'Export';
  public maxLengthArray = Array(5).fill(0);

  dtOptions: any = {};

  constructor(
    private reportApi: ReportsService,
    public orgHelper: OrganisationHelperService,
    public profileSync: GlobalProfileService,
    public commonService: CommonSupportService
  ) { }

  ngOnInit(): void {
    this.status = 'All';
    this.resetFilter();
    this.dtOptions = {
      dom: 'Bfrtip',
      lengthMenu: [
        [10, 25, 50, -1],
        ['10', '25', '50', 'All']
      ],
      buttons: [
        'pageLength',
        {
          text: 'Excel Download',
          className: 'btn btn-green',
          extend: 'excelHtml5',
          title: 'export-order-report',
        }
      ],
      // columnDefs: [
      //   { targets: 0, width: '100px' },
      // ],
    };
  }

  /* Fn to fetch the order recon report */
  fetchReport(): void {
    this.allSalesOrders = [];
    this.allPurchaseOrders = [];
    this.shouldShowLoader = true;
    this.reportApi.orderReconReport(
      this.orderType,
      this.toDate ? moment(this.toDate).format('YYYY-MM-DD') : null,
      this.fromDate ? moment(this.fromDate).format('YYYY-MM-DD') : null,
      this.status === 'All' ? undefined : this.status,
      this.currency === 'All' ? undefined : this.currency,
    ).subscribe(orders => {
      this.shouldShowLoader = false;
      if (orders && orders.length > 0) {
        if (this.orderType === 'Export') {
          this.allSalesOrders = orders;
        } else {
          this.allPurchaseOrders = orders;
        }
      } else {
        this.allSalesOrders = [];
        this.allPurchaseOrders = [];
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /* Fn to reset the filter */
  resetFilter(): void {
    this.toDate = undefined;
    this.fromDate = undefined;
    this.status = 'All';
    this.currency = 'All';
    this.fetchReport();
  }
}
