import { Component, OnInit, OnDestroy, ChangeDetectorRef, Renderer2, ViewChild } from '@angular/core';
import { BillOfEntry, OrganisationService, VendorAndSupplier, Page, Invoice, ItemMaster, Organisation, ShipmentDocsService } from 'src/swagger';
import { SwalHelper } from 'src/app/swal';
import { ActivatedRoute, Router } from '@angular/router';
import { concat, interval, Observable, of, Subject, Subscription } from 'rxjs';
import { skip, catchError, filter, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { CommonSupportService } from 'src/app/common-support.service';
import { GlobalProfileService } from 'src/app/global-profile.service';
import { ComponentCanDeactivate } from 'src/app/form-route-deactivate/component-can-deactivate';
import { NgForm } from '@angular/forms';
import { OrganisationHelperService } from 'src/app/organisation/organisation-helper.service';
import * as moment from 'moment';
declare var Swal: any;
declare var $: any;
@Component({
  selector: 'app-bill-of-entry-update',
  templateUrl: './bill-of-entry-update.component.html',
  styleUrls: ['./bill-of-entry-update.component.scss']
})
export class BillOfEntryUpdateComponent extends ComponentCanDeactivate implements OnInit, OnDestroy {

  @ViewChild('billOfEntryForm')
  public billOfEntryFormRef: NgForm;

  public shouldShowLoader: boolean;
  public billOfEntry: BillOfEntry = {};
  public boeOCRSubscription: Subscription;
  public totalPages = [];

  public ocrResults: Page[] = [];
  public selectors: SelectorDiv[] = [];

  public orgContacts: VendorAndSupplier[] = [];
  public supplier: VendorAndSupplier = {};

  public thisPage: any;
  public bgwidth: any;
  public bgheight: any;

  public copyText = 'Click to copy';
  public selectedImageURL: string;

  public rightSheet = false;
  public newContactType: string;

  public invChange$: Subject<string> = new Subject();
  public listInvoice$: Subject<Invoice[]> = new Subject();
  private invChangeSubscription: Subscription;
  public invLoading = false;
  public selectedInvoice: Invoice = {};

  private itemChange$: Subject<string> = new Subject();
  public itemMasters$: Observable<ItemMaster[]>;
  public itemLoading: boolean;
  public item: ItemMaster = {};
  public companyDetail: Organisation;

  public boeFiles: File[] = [];
  public isAir: boolean;

  public isRedirectToPreview: boolean;

  constructor(
    private orgApi: OrganisationService,
    private activatedRoute: ActivatedRoute,
    public http: HttpClient,
    private changeDetectorRef: ChangeDetectorRef,
    public commonService: CommonSupportService,
    private renderer: Renderer2,
    private profileSync: GlobalProfileService,
    public orgHelper: OrganisationHelperService,
    private router: Router,
    public shipmentDocApi: ShipmentDocsService
  ) {
    super();
  }

  ngOnInit(): void {

    this.billOfEntry.supplierId = 0;
    this.billOfEntry.items = [];
    this.getVendors();
    this.companyDetail = this.profileSync.user.Organisation;
    this.itemMasters$ = concat(
      of([]), // default items
      this.itemChange$.pipe(
        distinctUntilChanged(),
        tap(() => this.itemLoading = true),
        switchMap(query => this.orgApi.listItemMaster(query).pipe(
          catchError(() => of([])), // empty list on error
          tap(() => {
            this.itemLoading = false;
          })
        ))
      )
    );

    this.invChangeSubscription = this.invChange$.pipe(
      distinctUntilChanged(),
      tap(() => this.invLoading = true),
      switchMap(query => this.shipmentDocApi.searchInvoicesShipmentDocs(
        'PURCHASE',
        query,
      ).pipe(
        catchError(() => of([])), // empty list on error
        tap(() => {
          this.invLoading = false;
        })
      ))
    ).subscribe(items => {
      this.listInvoice$.next(items);
    });

    this.activatedRoute.params.subscribe(param => {
      if (param && param.billOfEntryId) {
        this.getBillOfEntry(param.billOfEntryId);
      }
    });
  }

  ngOnDestroy(): void {
    if (this.boeOCRSubscription) {
      this.boeOCRSubscription.unsubscribe();
    }
    if (this.invChangeSubscription) {
      this.invChangeSubscription.unsubscribe();
    }
  }

  canDeactivate(): boolean {
    return !this.billOfEntryFormRef.dirty;
  }

  getVendors(): void {
    this.shouldShowLoader = true;
    this.orgApi.listVendorSupplier()
      .subscribe(contacts => {
        this.shouldShowLoader = false;
        if (contacts && contacts.length > 0) {
          this.orgContacts = contacts;
        } else {
          this.orgContacts = null;
        }
      }, err => {
        this.shouldShowLoader = false;
        SwalHelper.showErrorSwal(err);
      });
  }

  getBillOfEntry(billOfEntryId): void {
    this.shouldShowLoader = true;
    this.shipmentDocApi.getBillOfEntry(billOfEntryId)
      .subscribe(boe => {
        this.shouldShowLoader = false;
        this.billOfEntry = boe;

        if (this.billOfEntry.mode === 'Air') {
          this.isAir = true;
        }
        else {
          this.isAir = false;
        }

        this.invChange$.next('');
        if (this.billOfEntry.Supplier) {
          this.supplier.companyBillingAddress = this.billOfEntry.Supplier.companyBillingAddress;
        }
        if (this.billOfEntry.invId) {
          this.selectedInvoice = this.billOfEntry.Invoice;
          this.selectedInvoice.currency = this.selectedInvoice.currency || 'USD';
        }
        this.billOfEntry.importerName = this.billOfEntry.importerName || this.companyDetail.companyName;
        this.billOfEntry.importerAddress = this.billOfEntry.importerAddress || this.companyDetail.registeredAddress;
        this.billOfEntry.IEC = this.billOfEntry.IEC || this.companyDetail.IECcode;
        this.billOfEntry.GSTIN = this.billOfEntry.GSTIN || this.companyDetail.gst;
        this.billOfEntry.items = this.billOfEntry.items || [];

        if (this.billOfEntry.ocrStatus !== 'FAILED' && this.billOfEntry.importedDocUrl) {
          this.boeOCRSubscription = interval(3000).pipe(skip(3)).subscribe(tick => {
            this.orgApi.ocrResults('BillOfEntry', this.billOfEntry.billOfEntryId).pipe(
              catchError(err => of(null)),
              filter((result) => !!result),
            ).subscribe(results => {
              if (results && results.length > 0) {
                this.totalPages = results;
                this.boeOCRSubscription.unsubscribe();
                this.ocrResults = results;
                this.loadData(0);
              }
            });
          });
        }
      }, err => {
        this.shouldShowLoader = false;
        SwalHelper.showErrorSwal(err);
      });
  }

  loadData(index: number): void {
    this.shouldShowLoader = true;
    this.billOfEntry.ocrStatus = 'DONE';
    this.http.get(this.ocrResults[index].metadata).subscribe(data => {
      const dummyObj: any = data;
      this.shouldShowLoader = false;
      this.selectedImageURL = this.ocrResults[index].background;
      this.thisPage = dummyObj.fullTextAnnotation.pages[0];
      this.bgwidth = this.thisPage.width;
      this.bgheight = this.thisPage.height;
      this.calBox();
      this.changeDetectorRef.detectChanges();
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  calBox(): void {
    this.selectors = [];
    this.thisPage.blocks.forEach(block => {
      const selectorDiv: SelectorDiv = new SelectorDiv();

      let minboxX = 9999;
      let minboxY = 9999;
      let maxboxX = 0;
      let maxboxY = 0;

      block.boundingBox.vertices.forEach(v => {
        if (v.x < minboxX) {
          minboxX = v.x;
        }
        if (v.y < minboxY) {
          minboxY = v.y;
        }
        if (v.x > maxboxX) {
          maxboxX = v.x;
        }
        if (v.y > maxboxY) {
          maxboxY = v.y;
        }
      });

      const padding = 5;

      selectorDiv.top = minboxY - padding;
      selectorDiv.left = minboxX - padding;
      selectorDiv.height = maxboxY - selectorDiv.top + padding;
      selectorDiv.width = maxboxX - selectorDiv.left + padding;

      selectorDiv.innerText = '';

      block.paragraphs.forEach(p => {
        p.words.forEach(w => {
          w.symbols.forEach(s => {
            selectorDiv.innerText = selectorDiv.innerText + s.text;
          });
          selectorDiv.innerText = selectorDiv.innerText + ' ';
        });
        selectorDiv.innerText = selectorDiv.innerText + '\n';
      });

      selectorDiv.innerText = selectorDiv.innerText.slice(0, -1);
      this.selectors.push(selectorDiv);
    });
  }

  resetCopytext(): void {
    this.copyText = 'Click to copy';
  }

  linkInv(inv): void {
    if (inv) {
      this.billOfEntry.invId = inv.invId;
      this.selectedInvoice = inv;
      this.orgApi.fetchItems(inv.invId)
        .subscribe(items => this.billOfEntry.items = items, err => {
        });
    } else {
      this.selectedInvoice = {};
      this.billOfEntry.items = [];
    }
  }

  selectSupplier(): void {
    if (this.billOfEntry.supplierId == -1) {
      this.newContactType = 'Importer';
      this.rightSheetOpen();
    } else {
      const index = this.orgContacts.findIndex(i => i.venSupId == this.billOfEntry.supplierId);
      if (index >= 0) {
        this.supplier = this.orgContacts[index];
        this.billOfEntry.Supplier = this.orgContacts[index];
      }
    }
  }

  rightSheetOpen(): void {
    this.rightSheet = !this.rightSheet;
    this.renderer.addClass(document.body, 'modal-open');
    this.commonService.addBackdrop();
  }

  rightSheetClose(): void {
    this.rightSheet = false;
    this.renderer.removeClass(document.body, 'modal-open');
    this.commonService.removeBackdrop();
  }

  newVendor(vendor): void {
    this.rightSheetClose();
    this.orgContacts.push(vendor);
    this.billOfEntry.supplierId = this.billOfEntry.supplierId === -1 ? vendor.venSupId : this.billOfEntry.supplierId;
    this.selectSupplier();
  }

  saveBoe(): void {
    this.shouldShowLoader = true;
    this.billOfEntry.beDate = this.billOfEntry.beDate ? moment(this.billOfEntry.beDate).format('YYYY-MM-DD') : null;
    this.billOfEntry.lcDate = this.billOfEntry.lcDate ? moment(this.billOfEntry.lcDate).format('YYYY-MM-DD') : null;
    this.billOfEntry.poDate = this.billOfEntry.poDate ? moment(this.billOfEntry.poDate).format('YYYY-MM-DD') : null;
    this.billOfEntry.igmDate = this.billOfEntry.igmDate ? moment(this.billOfEntry.igmDate).format('YYYY-MM-DD') : null;
    this.billOfEntry.inwDate = this.billOfEntry.inwDate ? moment(this.billOfEntry.inwDate).format('YYYY-MM-DD') : null;
    this.billOfEntry.oocDate = this.billOfEntry.oocDate ? moment(this.billOfEntry.oocDate).format('YYYY-MM-DD') : null;

    if (this.billOfEntry.billOfEntryId) {
      this.shipmentDocApi.updateBillOfEntry(this.billOfEntry)
        .subscribe(bol => {
          this.billOfEntry = bol;
          this.billOfEntryFormRef.form.markAsPristine();
          this.shouldShowLoader = false;
          if (this.isRedirectToPreview) {
            this.router.navigate([`/organisation/shipment/shipment-documents/bill-of-entry/bill-of-entry-details/${this.billOfEntry.billOfEntryId}`]);
          } else {
            SwalHelper.successTimerSwal('Bill of Entry updated!');
          }
        }, err => {
          this.shouldShowLoader = false;
          SwalHelper.showErrorSwal(err);
        });
    }
  }

  /* Fn to redirect to preview page */
  redirectToPreview(): void {
    this.isRedirectToPreview = true;
  }

  itemCodeChange(event): void {
    this.itemChange$.next(event);
  }

  checkItem(event, i): void {

    this.billOfEntry.items[i].itemCode = event.itemCode;
    this.billOfEntry.items[i].itemDesc = event.itemDescription;
    this.billOfEntry.items[i].qty = 0;
    this.billOfEntry.items[i].assessmentValue = 0;
    this.billOfEntry.items[i].duty = 0;
    this.billOfEntry.items[i].cth = '';
    this.billOfEntry.items[i].ceth = '';
    this.billOfEntry.items[i].uqc = '';
  }

  addItem(): void {
    const item: BoeItems = {};

    item.itemCode = '';
    item.itemDesc = '';
    item.qty = 0;
    item.assessmentValue = 0;
    item.duty = 0;
    item.cth = '';
    item.ceth = '';
    item.uqc = '';

    this.billOfEntry.items.push(item);
  }

  createItem(): void {
    $('#addItemModal').modal('show');
  }

  newItem(): void {
    this.shouldShowLoader = true;
    this.orgApi.addItemMaster(this.item)
      .subscribe(item => {
        this.shouldShowLoader = false;
        if (item) {
          $('#addItemModal').modal('hide');
          this.item = {};
        }
      }, err => {
        this.shouldShowLoader = false;
        SwalHelper.showErrorSwal(err);
      });
  }

  removeItem(index: number): void {
    Swal.fire({
      title: 'Remove Item?',
      text: 'Are you sure want to delete this Item?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete',
    }).then((result) => {
      if (result.value) {
        this.billOfEntry.items.splice(index, 1);
        SwalHelper.showToast('success', 'Item deleted!');
      }
    });
  }

  captureBOEFile(event): void {
    if (event.target.files.length + this.boeFiles.length > 5) {
      SwalHelper.showErrorSwal('Maximum 5 files allowed');
      return;
    }
    if (event.target.files && event.target.files.length > 0) {
      Object.keys(event.target.files).forEach((key) => {
        this.boeFiles.push(event.target.files[key]);
      });
    }
  }

  uplaodNewDocument(): void {
    this.shouldShowLoader = true;
    this.orgApi.uploadDocumentsAttachment(
      this.boeFiles[0],
      undefined,
      undefined,
      this.billOfEntry.billOfEntryId,
      undefined,
      this.boeFiles[1],
      this.boeFiles[2],
      this.boeFiles[3],
      this.boeFiles[4]
    ).subscribe(docs => {
      if (docs) {
        this.shouldShowLoader = false;
        SwalHelper.successTimerSwal('Document uploaded');
        $('#fileUploadModal').modal('hide');
        if (!this.billOfEntry.DocumentAttachments) {
          this.billOfEntry.DocumentAttachments = [];
        }
        this.billOfEntry.DocumentAttachments = docs;
        this.boeFiles = []; // Clean the document file array
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }
  removeAttchment(index: number): void {
    Swal({
      title: 'Delete Attachment',
      text: 'This cannot be undone',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        this.shouldShowLoader = true;
        return new Promise((resolve, reject) => {
          this.orgApi.deleteDocumentsAttachment(
            this.billOfEntry.DocumentAttachments[index].docAttId,
            undefined,
            this.billOfEntry.billOfEntryId,
          ).subscribe(ok => {
            this.shouldShowLoader = false;
            this.billOfEntry.DocumentAttachments.splice(index, 1);
            resolve(ok);
          }, err => {
            reject(err);
          });
        });
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.value) {
        this.shouldShowLoader = false;
        SwalHelper.successTimerSwal('Attachment deleted!');
      }
    }).catch(err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  modeChange(): void {
    if (this.billOfEntry.mode === 'Air') {
      this.isAir = true;
    }
    else {
      this.isAir = false;
    }
    this.changeDetectorRef.detectChanges();
  }

  public downloadFile(docAttId: number): void {
    this.orgApi.orgDocumentSignedUrl(docAttId)
      .subscribe(url => {
        window.open(url.url, '_blank');
      });
  }

}
export class SelectorDiv {
  public left: number;
  public top: number;
  public height: number;
  public width: number;
  public innerText: string;
}

export class BoeItems {
  public itemCode?: string;
  public itemDesc?: string;
  public qty?: number;
  public assessmentValue?: number;
  public duty?: number;
  public cth?: string;
  public ceth?: string;
  public uqc?: string;
}
