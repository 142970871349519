import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Organisation } from '../../../swagger/model/organisation';
import { OrganisationService } from '../../../swagger/api/organisation.service';
import { CommonSupportService } from '../../common-support.service';
import { GlobalProfileService } from '../../global-profile.service';
import { OrganisationHelperService } from '../../organisation/organisation-helper.service';
import { SwalHelper } from 'src/app/swal';
import { User } from 'src/swagger';
import { Router } from '@angular/router';
declare var Swal: any;

@Component({
  selector: 'app-create-profile',
  templateUrl: './create-profile.component.html',
  styleUrls: ['./create-profile.component.scss']
})
export class CreateProfileComponent implements OnInit {

  public orgProfile: Organisation = null;
  public orgLogo: File;
  public imageSrc: string | ArrayBuffer = '';
  public shouldShowLoader: boolean;
  @Output() updateProfileSync = new EventEmitter<User>();

  constructor(
    public orgApi: OrganisationService,
    public commonService: CommonSupportService,
    public profileSync: GlobalProfileService,
    public orgHelper: OrganisationHelperService,
    public router: Router
  ) { }

  ngOnInit(): void {
    console.log('create-profile-init');
    this.profileSync.user$.subscribe(user => {
      if (user && user.Organisation) {
        this.orgProfile = user.Organisation;
        this.imageSrc = this.orgProfile.companyLogo;
        this.orgProfile.currency = this.orgProfile.currency ? this.orgProfile.currency : 'USD';
        Swal({
          title: 'Congrats, <b>' + this.profileSync.user.firstName + '</b>!<br>NuPhi Account Successfully Created.',
          html: `<div class="d-flex justify-content-center"><div class="text-left">Now, it will just take 1 minute to create : <br><br> 1 : <b>Company Profile of ` + this.orgProfile.companyName + `</b> <br> 2 : <b>Your first shipment</b><div></div>`,
          // type: 'success',
          showConfirmButton: true,
          allowOutsideClick: false,
          imageUrl: '/assets/confetti.gif',
          imageWidth: 180,
          imageHeight: 180,
          confirmButtonText: `Let's Begin<i class="ri-arrow-right-line ml-2"></i> `,
        });
      }
    });
  }

  /* Fn to update the company profile*/
  updateOrgProfile(): void {
    this.shouldShowLoader = true;
    this.orgApi.updateOrganisationProfile(this.orgProfile)
      .subscribe(orgProfile => {
        if (this.orgLogo) {
          this.uploadLogo();
        }
        this.shouldShowLoader = false;
        this.profileSync.user.Organisation = orgProfile;
        this.updateProfileSync.next(this.profileSync.user);
        (document.getElementById('logo') as HTMLInputElement).value = '';
        Swal.fire({
          title: 'Company profile created!',
          text: 'You can always update it later',
          // type: 'success',
          showConfirmButton: true,
          allowOutsideClick: false,
          imageUrl: '/assets/confetti.gif',
          imageWidth: 180,
          imageHeight: 180,
          confirmButtonText: `Now Let's Create Your First Shipment<i class="ri-arrow-right-line ml-2"></i> `,
        }).then(result => {
          if (result.value) {
            this.router.navigate(['/welcome/shipment-overview']);
          }
        });
      }, err => {
        this.shouldShowLoader = false;
        SwalHelper.showErrorSwal(err);
      });
  }

  /* Fn to set the company logo */
  setLogo(event): void {
    this.orgLogo = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(this.orgLogo);
    reader.onload = ev => {
      this.imageSrc = reader.result;
    };
  }

  /* Fn to upload the company logo */
  uploadLogo(): void {
    this.orgApi.uploadLogo(this.orgLogo)
      .subscribe(url => {
        this.shouldShowLoader = false;
        this.orgLogo = undefined;
        this.profileSync.user.Organisation.companyLogo = url.url;
        this.updateProfileSync.next(this.profileSync.user);
      }, err => {
        this.shouldShowLoader = false;
        SwalHelper.showErrorSwal('Logo not updated , Please try again');
      });
  }

}
