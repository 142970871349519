/**
 * Nuphi trade
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2.0.0
 * Contact: shraddha@blackcurrantapps.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface ExcelUploadResult { 
    warnings?: Array<string>;
    rowsInserted?: Array<string>;
    rowsSkipped?: Array<string>;
    skippedBillOfEntries?: Array<any>;
    skippedBillOfLadings?: Array<any>;
    newBillOfEntries?: Array<any>;
    newBillOfLadings?: Array<any>;
    skippedShippingBills?: Array<any>;
    newShippingBills?: Array<any>;
    skippedInvoices?: Array<any>;
    newInvoices?: Array<any>;
    skippedBuyers?: Array<any>;
    newBuyers?: Array<any>;
    skippedItemMasters?: Array<any>;
    newItemMasters?: Array<any>;
    filterWarnigs?: Array<string>;
    mappingWarnings?: Array<string>;
}
