import { Component, OnInit, Output, Input, EventEmitter, OnChanges, OnDestroy } from '@angular/core';
import { User, OrganisationService, OrganisationRole } from 'src/swagger';
import { SwalHelper } from 'src/app/swal';
import { CommonSupportService } from 'src/app/common-support.service';
import { GlobalProfileService } from 'src/app/global-profile.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-organisation-users-detail',
  templateUrl: './organisation-users-detail.component.html',
  styleUrls: ['./organisation-users-detail.component.scss']
})
export class OrganisationUsersDetailComponent implements OnInit, OnChanges, OnDestroy {

  public userDetails: User = {};
  public allRoles: OrganisationRole[] = [];

  public shouldShowLoader: boolean;

  @Output() closeOrgUserToggle = new EventEmitter<User>();

  @Input() emailId: string;
  private userSubscription: Subscription;


  constructor(
    private orgApi: OrganisationService,
    public commonService: CommonSupportService,
    private profileSync: GlobalProfileService
  ) { }

  ngOnInit(): void {
    this.userSubscription = this.profileSync.user$.subscribe(user => {
      if (user && user.OrganisationRole && user.OrganisationRole.permissions && user.OrganisationRole.permissions.OrganisationRole.read) {
        this.fetchAllRoles();
      }
    });
  }

  ngOnChanges(): void {
    if (this.emailId) {
      this.getUserDetails(this.emailId);
    } else {
      this.inItUser();
    }
  }

  ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  /* Fn to init the new user details */
  inItUser(): void {
    this.userDetails = {};
    this.userDetails.countryCode = '+91';
  }

  /* Fn to fetch all the roles details */
  fetchAllRoles(): void {
    this.shouldShowLoader = true;
    this.orgApi.allOrgRoles().subscribe(roles => {
      this.shouldShowLoader = false;
      if (roles && roles.length > 0) {
        this.allRoles = roles;
        this.userDetails.orgRoleId = this.userDetails.orgRoleId ? this.userDetails.orgRoleId : this.allRoles[0].orgRoleId;
      } else {
        this.allRoles = null;
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /**
   * Fn to fet the details of requested email id
   * @param email Email id of user
   */
  getUserDetails(email: string): void {
    this.shouldShowLoader = true;
    this.orgApi.getUserProfile(email).subscribe(user => {
      this.shouldShowLoader = false;
      if (user) {
        this.userDetails = user;
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /**
   * Fn to save the user details
   * @param form Angular form
   */
  saveuserDetails(form): boolean {
    if (form.valid) {
      this.shouldShowLoader = true;
      if (this.emailId) {
        this.orgApi.updateUserProfile(this.userDetails).subscribe(user => {
          this.shouldShowLoader = false;
          this.closeOrgUserToggle.next(user);
          SwalHelper.successTimerSwal('User details Updated!');
          this.inItUser();
        }, err => {
          this.shouldShowLoader = false;
          SwalHelper.showErrorSwal(err);
        });
      } else {
        this.orgApi.addUserProfile(this.userDetails).subscribe(user => {
          this.shouldShowLoader = false;
          this.closeOrgUserToggle.next(user);
          SwalHelper.successTimerSwal('User Added!');
          this.inItUser();
        }, err => {
          this.shouldShowLoader = false;
          SwalHelper.showErrorSwal(err);
        });
      }
    } else {
      return false;
    }
  }
}
