import { Component, OnInit, HostListener, Renderer2, OnDestroy } from '@angular/core';
import { Invoice, OrganisationService, OrganisationContact, OrgInvoiceService, StatusHistory, CustomExportObject, PaymentSchedule } from 'src/swagger';
import { ActivatedRoute, Router } from '@angular/router';
import { SwalHelper } from 'src/app/swal';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { saveAs } from 'file-saver';
import { GlobalProfileService } from 'src/app/global-profile.service';
import { OrganisationHelperService } from '../../organisation-helper.service';
import { CommonSupportService } from '../../../common-support.service';
import { OrgShipmentsService } from '../../../../swagger/api/orgShipments.service';
import { Subscription, of, Subject } from 'rxjs';
import { debounceTime, tap, switchMap, catchError } from 'rxjs/operators';
import { ShipmentDocument } from '../../../../swagger/model/shipmentDocument';
import { Shipment } from '../../../../swagger/model/shipment';

declare var $: any;
declare var swal: any;

@Component({
  selector: 'app-invoice-details',
  templateUrl: './invoice-details.component.html',
  styleUrls: ['./invoice-details.component.scss']
})
export class InvoiceDetailsComponent implements OnInit, OnDestroy {
  public invoice: Invoice = {};
  public shouldShowLoader = false;
  public rightSheet = false;

  public outputInvoice: any;
  public basePath: string;
  public nextState: string;
  public prevState: string;

  public emails = [];
  public contactUsers: OrganisationContact[] = [];

  public statusHistory: StatusHistory = {};
  public statusEnum = Invoice.InvoiceStatusEnum;

  public schedule: PaymentSchedule = {};
  public invoiceVersion = 'Commercial';

  public shouldShareUser: boolean[] = [false];
  private userSubscription: Subscription;

  public shipmentDocumentLink: ShipmentDocument = {}; // For Linking Purpose

  public shipmentChange$: Subject<string> = new Subject();
  public listShipment$: Subject<Shipment[]> = new Subject();
  public alreadyLinkedShipIds = [];
  public shipmentLoading = false;
  private shipmentSubscription: Subscription;

  constructor(
    public http: HttpClient,
    private orgApi: OrganisationService,
    private activatedRoute: ActivatedRoute,
    private orgInvApi: OrgInvoiceService,
    public profileSync: GlobalProfileService,
    private orgServiceHelper: OrganisationHelperService,
    private renderer: Renderer2,
    public commonService: CommonSupportService,
    private router: Router,
    public orgShipmentApi: OrgShipmentsService
  ) {
    this.basePath = environment.BASE_PATH;
    commonService.renderer = renderer;
  }
  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent): void {
    this.rightSheet = false;
    this.renderer.removeClass(document.body, 'modal-open');
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(param => {
      if (param && param.type) {
        this.invoiceVersion = param.type;
      }
    });
    this.activatedRoute.params.subscribe(param => {
      if (param && param.invId) {
        this.fetchInvoice(param.invId);
      }
    });
    this.userSubscription = this.profileSync.user$.subscribe(user => {
      if (user && user.OrganisationRole && user.OrganisationRole.permissions && user.OrganisationRole.permissions.OrganisationContact.read) {
        this.fetchContacts();
      }
    });
    this.shipmentSubscription = this.shipmentChange$.pipe(
      debounceTime(200),
      tap(() => this.shipmentLoading = true),
      switchMap(query => this.orgInvApi.searchInvoiceShipments(
        'SELL',
        'INVOICE',
        query,
        this.alreadyLinkedShipIds.join(',')
      ).pipe(
        catchError(() => of([])), // empty list on error
        tap(() => {
          this.shipmentLoading = false;
        })
      ))
    ).subscribe(sos => {
      this.listShipment$.next(sos);
    });
    if (localStorage.getItem('IS_LINK_SHIPMENT') && this.profileSync.user && this.profileSync.user.OrganisationRole && this.profileSync.user.OrganisationRole.permissions && this.profileSync.user.OrganisationRole.permissions.Shipment.update) {
      this.openDocLinkModal();
      localStorage.removeItem('IS_LINK_SHIPMENT');
    }
  }

  ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
    if (this.shipmentSubscription) {
      this.shipmentSubscription.unsubscribe();
    }
  }

  rightSheetOpen(): void {
    this.rightSheet = !this.rightSheet;
    this.renderer.addClass(document.body, 'modal-open');
    this.commonService.addBackdrop();
  }

  rightSheetClose(): void {
    this.rightSheet = false;
    this.renderer.removeClass(document.body, 'modal-open');
    this.commonService.removeBackdrop();
  }

  fetchInvoice(invId): void {
    this.shouldShowLoader = true;
    this.orgInvApi.invoiceStatusHistory(invId).subscribe(inv => {
      if (inv) {
        this.shouldShowLoader = false;
        this.invoice = inv;
        this.calcStatus();
        this.fetchTemplate();
        if (this.invoice.ShipmentDocuments && this.invoice.ShipmentDocuments.length > 0) {
          this.alreadyLinkedShipIds = this.invoice.ShipmentDocuments.map(i => i.Shipment.shipmentId);
        }
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  applyTemplate(): void {
    this.shouldShowLoader = true;
    this.orgInvApi.updateInvTemplate(
      this.invoice.invId,
      this.invoice.templateName
    ).subscribe(order => {
      this.shouldShowLoader = false;
      this.fetchTemplate();
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  calcStatus(): void {
    const historyStatus = this.invoice.StatusHistories.filter(i => i.oldState != null);
    if (this.invoice.invoiceStatus === 'DRAFT') {
      this.nextState = 'FINAL';
    } else if (this.invoice.invoiceStatus === 'ACCEPTED') {
      this.prevState = 'DRAFT';
      this.nextState = 'PARTIALLY_PAID';
    } else if (this.invoice.invoiceStatus === 'PARTIALLY_PAID' || this.invoice.invoiceStatus === 'OVERDUE') {
      this.prevState = historyStatus[0].oldState;
      this.nextState = 'FULLY_PAID';
    } else if (this.invoice.invoiceStatus === 'FULLY_PAID') {
      this.prevState = historyStatus[0].oldState;
      this.prevState = 'FINAL';
    } else if (this.invoice.invoiceStatus === 'DISPUTED') {
      this.prevState = historyStatus[0].oldState;
    } else {
      this.prevState = historyStatus[0].oldState;
    }
  }

  // For sharing the invoice as email with external user
  fetchContacts(): void {
    this.shouldShowLoader = true;
    this.orgApi.listOrgContact().subscribe(contacts => {
      this.shouldShowLoader = false;
      if (contacts) {
        this.contactUsers = contacts;
      } else {
        this.contactUsers = [];
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }
  // Checkbox feature to add/remove from array of emails
  checkUser(event, user, ind): void {
    this.shouldShareUser[ind] = event.checked;
    if (event.checked) {
      this.emails.push({
        contactEmail: user.contactEmail,
        contactName: user.contactName
      });
    } else {
      const index = this.emails.findIndex(i => i.contactEmail === user.contactEmail);
      if (index >= 0) {
        this.emails.splice(index, 1);
      }
    }
  }
  // Fetch server side rendered invoice template
  fetchTemplate(): void {
    const headers = new HttpHeaders().set('Authorization', this.orgApi.configuration.apiKeys.Authorization);
    if (this.invoice.templateName) {
      this.shouldShowLoader = true;
      this.http.post(`${this.basePath}/organisation/templates/${this.invoiceVersion === 'Commercial' ? 'Invoice' : this.invoiceVersion}/${this.invoice.templateName}`, { invId: this.invoice.invId }, {
        responseType: 'text',
        headers,
        observe: 'body'
      }).subscribe(data => {
        this.shouldShowLoader = false;
        this.outputInvoice = data;
      }, err => {
        this.shouldShowLoader = false;
        SwalHelper.showErrorSwal(err);
      });
    }
  }
  // Export the invoice in pdf form
  exportInvoice(): void {
    this.shouldShowLoader = true;
    const cusObj: CustomExportObject = {};
    cusObj.invId = this.invoice.invId;
    cusObj.type = `${this.invoiceVersion === 'Commercial' ? 'Invoice' : this.invoiceVersion}`;
    this.orgApi
      .exportDocument(cusObj).subscribe(res => {
        this.shouldShowLoader = false;
        saveAs(res, (`${this.invoiceVersion}-${this.invoice.invNo}.pdf`));
      }, err => {
        this.shouldShowLoader = false;
        SwalHelper.showErrorSwal(err);
      });
  }

  prinInv(): void {
    const printContents = document.getElementById('printInv').innerHTML;
    const win = window.open('', 'Title', 'toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes');
    win.document.body.innerHTML = printContents;
    win.print();
  }
  // Share the invoice with selected email id
  shareInvoice(): void {
    this.shouldShowLoader = true;
    this.orgApi.emailInvoice(
      this.invoice.invId,
      [JSON.stringify(this.emails)],
      this.invoiceVersion === 'Commercial' ? 'Invoice' : this.invoiceVersion
    ).subscribe(ok => {
      this.shouldShowLoader = false;
      this.emails = [];
      this.shouldShareUser = this.shouldShareUser.map(() => false);
      $('#assignUserModal').modal('hide');
      if (ok) {
        SwalHelper.successTimerSwal('Email sent successfully');
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  saveStatusComment(): void {
    this.shouldShowLoader = true;
    this.statusHistory.invId = this.invoice.invId;
    if (this.statusHistory.statusHistoryId) {
      this.orgApi.updateStatusHistory(this.statusHistory).subscribe(history => {
        if (history) {
          this.shouldShowLoader = false;
          SwalHelper.showSavedToast('Comment Updated');
          const index = this.invoice.StatusHistories.findIndex(i => i.statusHistoryId === this.statusHistory.statusHistoryId);
          this.invoice.StatusHistories[index] = history;
          this.statusHistory = {};
        }
      }, err => {
        this.shouldShowLoader = false;
        SwalHelper.showErrorSwal(err);
      });
    } else {
      this.orgApi.createStatusHistory(this.statusHistory).subscribe(history => {
        if (history) {
          this.shouldShowLoader = false;
          this.invoice.StatusHistories.unshift(history);
          SwalHelper.showSavedToast('Comment Added');
          this.statusHistory = {};
        }
      }, err => {
        this.shouldShowLoader = false;
        SwalHelper.showErrorSwal(err);
      });
    }
  }

  editComment(index: number): void {
    this.statusHistory = this.orgServiceHelper.copyObject(this.invoice.StatusHistories[index]);
  }

  deleteStatusComment(index: number): void {
    swal({
      title: 'Delete Status Comment',
      text: 'This cannot be undone',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        this.shouldShowLoader = true;
        return new Promise((resolve, reject) => {
          this.orgApi.deleteStatusHistory(this.invoice.StatusHistories[index].statusHistoryId).subscribe(ok => {
            this.shouldShowLoader = false;
            this.invoice.StatusHistories.splice(index, 1);
            resolve(ok);
          }, err => {
            reject(err);
          });
        });
      },
      allowOutsideClick: () => !swal.isLoading()
    }).then((result) => {
      if (result.value) {
        this.shouldShowLoader = false;
        SwalHelper.successTimerSwal('Comment deleted!');
      }
    }).catch(err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  updateAndSave(status: Invoice.InvoiceStatusEnum): void {
    if (this.invoice.InvoiceItems && this.invoice.InvoiceItems.length > 0) {
      swal({
        title: 'Mark as Final',
        text: 'You won’t be allowed to update "Buyer Name" & "Item Table" afterwards.',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Confirm',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return new Promise((resolve, reject) => {
            this.orgInvApi.saveInvoice(this.invoice.invId).subscribe(ok => {
              this.invoice.invoiceStatus = 'ACCEPTED';
              this.shouldShowLoader = false;
              this.orgInvApi.invoiceStatusHistory(this.invoice.invId).subscribe(inv => {
                if (inv) {
                  this.invoice = inv;
                  this.calcStatus();
                }
              }, err => {
                SwalHelper.showErrorSwal(err);
              });
              resolve(ok);
            }, err => {
              reject(err);
            });
          });
        },
        allowOutsideClick: () => !swal.isLoading()
      }).then((result) => {
        if (result.value) {
          SwalHelper.successTimerSwal('Invoice Finalized!');
        }
      }).catch(err => {
        SwalHelper.showErrorSwal(err);
      });
    } else {
      swal({
        title: 'No Items Found!',
        text: 'Please Add items to accept the invoice',
        type: 'warning',
      }).then((result) => {
        if (result.value) {
          this.router.navigate([`organisation/invoices/edit-invoice/${this.invoice.invId}`]);
        }
      });
    }
  }

  updateStatus(status: Invoice.InvoiceStatusEnum): void {
    swal({
      title: `Update Invoice to ${status.replace('_', ' ').toLocaleLowerCase()}`,
      text: `Are you sure you want to update Invoice to ${status.replace('_', ' ').toLocaleLowerCase()}`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Update',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        this.shouldShowLoader = true;
        return new Promise((resolve, reject) => {
          this.orgInvApi.updateInvStatus(
            this.invoice.invId,
            status
          ).subscribe(ok => {
            this.invoice.invoiceStatus = status;
            this.shouldShowLoader = false;
            this.orgInvApi.invoiceStatusHistory(this.invoice.invId).subscribe(inv => {
              if (inv) {
                this.invoice = inv;
                this.calcStatus();
              }
            }, err => {
              SwalHelper.showErrorSwal(err);
            });
            resolve(ok);
          }, err => {
            reject(err);
          });
        });
      },
      allowOutsideClick: () => !swal.isLoading()
    }).then((result) => {
      if (result.value) {
        this.shouldShowLoader = false;
        SwalHelper.successTimerSwal(`Invoice marked as ${status.replace('_', ' ').toLocaleLowerCase()}`);
      }
    }).catch(err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  updatedAmount(remainingAmount: number): void {
    this.invoice.balanceAmount = remainingAmount;
  }

  generateExcel(): void {
    this.orgInvApi
      .invoicePaymentExcel(
        this.invoice.invId
      ).subscribe(res => {
        saveAs(res, ('Invoice Payments.xlsx'));
      });
  }

  public downloadFile(docAttId: number): void {
    this.orgApi.orgDocumentSignedUrl(docAttId)
      .subscribe(url => {
        window.open(url.url, '_blank');
      });
  }

  openDocLinkModal(): void {
    this.shipmentDocumentLink.docType = 'INVOICE';
    $('#fileAddModal').modal('show');
  }

  docAlreadyLinked(): void {
    this.shipmentDocumentLink.invId = this.invoice.invId;
    this.orgShipmentApi.documentAlreadyLinked(
      this.shipmentDocumentLink
    ).subscribe(ok => {
      if (ok) {
        swal({
          title: 'Alredy Linked',
          text: ok.message,
          type: 'warning',
          showCancelButton: true,
          cancelButtonText: 'No',
          confirmButtonText: 'Yes! Link',
        }).then((result) => {
          if (result.value) {
            this.linkDocument();
          } else {
            $('#fileAddModal').modal('hide');
            this.shipmentDocumentLink = {};
            this.shipmentDocumentLink.docType = 'INVOICE';
          }
        });
      } else {
        this.linkDocument();
      }
    }, err => {
      SwalHelper.showErrorSwal(err);
    });
  }

  // Link the documents
  linkDocument(): void {
    this.shouldShowLoader = true;
    this.shipmentDocumentLink.invId = this.invoice.invId;
    this.orgShipmentApi.linkShipmentDocument(
      this.shipmentDocumentLink
    ).subscribe(doc => {
      this.shouldShowLoader = false;
      SwalHelper.successTimerSwal('Shipment linked');
      if (doc) {
        if (doc.Invoice) {
          this.invoice.ShipmentDocuments.push({
            shipmentDocumentId: doc.shipmentDocumentId,
            Shipment: doc.Shipment
          });
          this.alreadyLinkedShipIds.push(this.shipmentDocumentLink.shipmentId);
        }
        this.shipmentDocumentLink = {};
        this.shipmentDocumentLink.docType = 'INVOICE';
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /**
   * Fn to Unlink the shipment from order
   * @param index Index of shipment document
   */
  unlinkDocument(index: number): void {
    swal({
      title: `Unlink Shipment`,
      text: 'This cannot be undone',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Unlink',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        this.shouldShowLoader = true;
        return new Promise((resolve, reject) => {
          this.orgShipmentApi.unlinkShipmentDocument(
            this.invoice.ShipmentDocuments[index].Shipment.shipmentId,
            this.invoice.ShipmentDocuments[index].shipmentDocumentId
          ).subscribe(doc => {
            this.shouldShowLoader = false;
            this.invoice.ShipmentDocuments.splice(index, 1);
            $('#fileViewModal').modal('hide');
            resolve(doc);
          }, err => {
            this.shouldShowLoader = false;
            reject(err);
          });
        });
      },
      allowOutsideClick: () => !swal.isLoading()
    }).then((result) => {
      if (result.value) {
        SwalHelper.successTimerSwal('Shipment Unlinked');
      }
    }).catch(err => {
      SwalHelper.showErrorSwal(err);
    });
  }

  /* Fn to set the buyer id for shipment & redirect to shipment create modal */
  openNewShipment(): void {
    localStorage.setItem('SHIPMENT_BUYER_ID', this.invoice.buyerId.toString());
    this.router.navigate(['/organisation/list-shipments']);
  }
}
