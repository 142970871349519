/**
 * Nuphi trade
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2.0.0
 * Contact: shraddha@blackcurrantapps.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Invoice } from './invoice';
import { Organisation } from './organisation';
import { OrganisationContact } from './organisationContact';


export interface PaymentSchedule { 
    paymentScheduleId?: number;
    invId?: number;
    orgId?: number;
    paymentMadeBy?: number;
    remainingAmount?: number;
    expAmount?: number;
    percentOfInvoice?: number;
    expDateOfPayment?: string;
    status?: PaymentSchedule.StatusEnum;
    currency?: string;
    Invoice?: Invoice;
    OrganisationContact?: OrganisationContact;
    Organisation?: Organisation;
}
export namespace PaymentSchedule {
    export type StatusEnum = 'OPEN';
    export const StatusEnum = {
        OPEN: 'OPEN' as StatusEnum
    };
}
