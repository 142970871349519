import { Injectable } from '@angular/core';
import { OrganisationService, TagMaster, PaymentSchedule } from 'src/swagger';
import { BehaviorSubject } from 'rxjs';
import { SwalHelper } from '../swal';
import { SelectorDiv } from '../enums';

@Injectable({
  providedIn: 'root'
})
export class OrganisationHelperService {

  public allMasterTags: TagMaster[] = [];
  public paymentSchedule: PaymentSchedule = null;
  public paymentSchedule$ = new BehaviorSubject<PaymentSchedule>(null);
  public currencyMaster = [{
    name: '$ USD', value: 'USD'
  },
  {
    name: '€ EUR', value: 'EUR'
  },
  {
    name: '£ GBP', value: 'GBP'
  },
  {
    name: 'د.إ AED', value: 'AED'
  },
  {
    name: 'S$ SGD', value: 'SGD'
  },
  {
    name: 'A$ AUD', value: 'AUD'
  },
  {
    name: '¥ JPY', value: 'JPY'
  },
  {
    name: '¥ CNY', value: 'CNY'
  },
  {
    name: 'HK$ HKD', value: 'HKD'
  },
  {
    name: 'C$ CAD', value: 'CAD'
  }, {
    name: 'CHF', value: 'CHF'
  },
  {
    name: '₹ INR', value: 'INR'
  }, ];

  constructor(
    private orgApi: OrganisationService
  ) { }

  public copyObject(source): object {
    return JSON.parse(JSON.stringify(source));
  }

  public fetchTags(tagType): void {
    this.orgApi.listTagMaster(tagType).subscribe(tags => {
      if (tags && tags.length > 0) {
        this.allMasterTags = tags;
      } else {
        this.allMasterTags = [];
      }
    }, err => {
      SwalHelper.showErrorSwal(err);
    });
  }

  /* Add the tag to master */
  public addTag(tagObject): void {
    this.orgApi.addTagMaster(
      tagObject
    ).subscribe(tag => { }, err => {
      SwalHelper.showErrorSwal(err);
    });
  }

  public updateSchedule(schedule: PaymentSchedule): void {
    this.paymentSchedule = schedule;
    this.paymentSchedule$.next(schedule);
  }

  calBox(thisPage): any {
    const selectors = [];
    thisPage.blocks.forEach(block => {
      const selectorDiv: SelectorDiv = new SelectorDiv();

      let minboxX = 9999;
      let minboxY = 9999;
      let maxboxX = 0;
      let maxboxY = 0;

      block.boundingBox.vertices.forEach(v => {
        if (v.x < minboxX) {
          minboxX = v.x;
        }
        if (v.y < minboxY) {
          minboxY = v.y;
        }
        if (v.x > maxboxX) {
          maxboxX = v.x;
        }
        if (v.y > maxboxY) {
          maxboxY = v.y;
        }
      });

      const padding = 5;

      selectorDiv.top = minboxY - padding;
      selectorDiv.left = minboxX - padding;
      selectorDiv.height = maxboxY - selectorDiv.top + padding;
      selectorDiv.width = maxboxX - selectorDiv.left + padding;

      selectorDiv.innerText = '';

      block.paragraphs.forEach(p => {
        p.words.forEach(w => {
          w.symbols.forEach(s => {
            selectorDiv.innerText = selectorDiv.innerText + s.text;
          });
          selectorDiv.innerText = selectorDiv.innerText + ' ';
        });
        selectorDiv.innerText = selectorDiv.innerText + '\n';
      });

      selectorDiv.innerText = selectorDiv.innerText.slice(0, -1);
      selectors.push(selectorDiv);
    });
    return selectors;
  }
}
