import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { InvoiceItem } from 'src/swagger';

@Injectable({
  providedIn: 'root'
})
export class BulkItemSyncService {

  public invoiceItems$: Subject<InvoiceItem[]> = new Subject();
  public customFields$: Subject<string[]> = new Subject();

  constructor() { }

}
