import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { SwalHelper } from 'src/app/swal';
import { ExcelUploadResult, OrgInvoiceService, OrgOrdersService, ShipmentDocsService } from 'src/swagger';
import * as stringSimilarity from 'string-similarity';
import * as XLSX from 'xlsx';
import { CommonSupportService } from '../../../common-support.service';
declare var Swal: any;
declare var $: any;

@Component({
  selector: 'app-bulk-upload-invoice',
  templateUrl: './bulk-upload-invoice.component.html',
  styleUrls: ['./bulk-upload-invoice.component.scss']
})
export class BulkUploadInvoiceComponent implements OnInit {

  public excelInvHeaders = [
    'invNo',
    'type',
    'invoiceStatus',
    'dateOfInv',
    'dueDate',
    'orderDate',
    'orderNumber',
    'currency',
    'blNo',
    'blDate',
    'shippingBillNumber',
    'shippingBillDate',
    'totalGrossWeight',
    'totalNetWeight',
    'containerNo',
    'incoTerms',
    'incoTermsPlace',
    'modeOfShipment',
    'finalDestination',
    'creditPeriod',
    'originCountry',
    'portOfLoading',
    'destinationPort',
    'destinationCountry',
    'exchangeRate',
    'itemCode',
    'itemName',
    'itemHSNCode',
    'itemDescription',
    'itemPrice',
    'itemUnit',
    'itemQuantity',
    'gst',
    'netWeight',
    'grossWeight',
    'companyName',
    'IGNORE'
  ];

  public excelVBHeaders = [
    'invNo',
    'type',
    'invoiceStatus',
    'dateOfInv',
    'dueDate',
    'orderDate',
    'orderNumber',
    'currency',
    'blNo',
    'blDate',
    'beNumber',
    'billOfEntryDate',
    'totalGrossWeight',
    'totalNetWeight',
    'containerNo',
    'incoTerms',
    'incoTermsPlace',
    'modeOfShipment',
    'finalDestination',
    'creditPeriod',
    'originCountry',
    'portOfLoading',
    'destinationPort',
    'destinationCountry',
    'exchangeRate',
    'itemCode',
    'itemName',
    'itemHSNCode',
    'itemDescription',
    'itemPrice',
    'itemUnit',
    'itemQuantity',
    'netWeight',
    'grossWeight',
    'companyName',
    'IGNORE'
  ];

  public excelPOHeaders = [
    'poHeading',
    'poTotalAmount',
    'poTotalQuantity',
    'currency',
    'poDeliveryDate',
    'vendorCode',
    'shipmentDelTerms',
    'poDispatchDate',
    'deliveryInstructions',
    'amountInWords',
    'deliveryAddress',
    'destinationPort',
    'destinationETA',
    'destinationCountry',
    'originPort',
    'originETA',
    'originCountry',
    'documents',
    'conditions',
    'dutyRate',
    'incoTerms',
    'incoTermsPlace',
    'paymentTerms',
    'orgOrderDate',
    'orgOrderNo',
    'sellerSONo',
    'orderRevisionNo',
    'specialInstruction',
    'tarrifCode',
    'portOfLoading',
    'advanceAmount',
    'advanceAmountDate',
    'termsandconditions',
    'status',
    'itemCode',
    'itemHSNCode',
    'itemSubCode',
    'itemName',
    'itemDescription',
    'itemPrice',
    'itemCurrency',
    'itemQuantity',
    'remainingQuantity',
    'itemUnit',
    'companyName',
    'companyEmail',
    'companyPhoneNo',
    'companyCountryCode',
    'companyFaxNo',
    'companyTaxNo',
    'companyBillingAddress',
    'companyShippingAddress',
    'IGNORE'
  ];

  public excelSOHeaders = [
    'soHeading',
    'soTotalAmount',
    'amountInWords',
    'soTotalQuantity',
    'currency',
    'soDeliveryDate',
    'soDispatchDate',
    'deliveryInstructions',
    'deliveryAddress',
    'destinationPort',
    'destinationETA',
    'destinationCountry',
    'originPort',
    'originETA',
    'originCountry',
    'documents',
    'conditions',
    'dutyRate',
    'incoTerms',
    'incoTermsPlace',
    'paymentTerms',
    'orgOrderDate',
    'buyerOrderDate',
    'orgOrderNo',
    'buyerPONo',
    'orderRevisionNo',
    'specialInstruction',
    'termsandconditions',
    'tarrifCode',
    'vendorCode',
    'status',
    'itemCode',
    'itemHSNCode',
    'itemSubCode',
    'itemName',
    'itemDescription',
    'itemPrice',
    'itemCurrency',
    'itemQuantity',
    'remainingQuantity',
    'itemUnit',
    'portOfLoading',
    'advanceAmount',
    'advanceAmountDate',
    'companyName',
    'companyEmail',
    'companyPhoneNo',
    'companyCountryCode',
    'companyFaxNo',
    'companyTaxNo',
    'companyBillingAddress',
    'companyShippingAddress',
    'IGNORE'
  ];

  public excelSBHeaders = [
    'shippingBillDate',
    'shippingBillNumber',
    'noOfPackages',
    'totalWeight',
    'uom',
    'invNo',
    'mode',
    'portCode',
    'portOfLoading',
    'stateOfOrigin',
    'dischargePort',
    'dischargeCountry',
    'finalDestinationCountry',
    'finalDestinationPort',
    'buyerId',
    'consigneeId',
    'adBankCode',
    'adIfscCode',
    'fobValue',
    'exchangeRate',
    'qrCode',
    'districtOfOrigin',
    'freight',
    'insurance',
    'discount',
    'commission',
    'exporterName',
    'exporterAddress',
    'GSTIN',
    'dbkClaim',
    'igst',
    'cess',
    'awbNo',
    'sealType',
    'containerNumber',
    'marksAndNumbers',
    'leoNo',
    'leoDate',
    'thirdPartyName',
    'thirdPartyAddress',
    'items',
    'LUT',
    'schemeDesc',
    'advAuthLicNo',
    'sionNo',
    'descOfImportItem',
    'importItemQty',
    'importUqc',
    'importItemValue',
    'chaCode',
    'chaName',
    'meisClause',
    'drawbackBankAccNo',
    'drawbackClaimAmount',
    'drawbackRate',
    'drawbackSerialNo',
    'forexBankAccNo',
    'assessedDate',
    'assessedTime',
    'brcRealisationDate',
    'incoTerm',
    'hsCode',
    'shippingBillBarcode',
    'isAssessed',
    'isExamined',
    'isJobbing',
    'isMEIS',
    'isROSL',
    'isLUT',
    'isDEEC',
    'isDFRC',
    'cargoNature',
    'itemCode',
    'itemHSNCode',
    'itemSubCode',
    'itemName',
    'itemDescription',
    'itemPrice',
    'itemCurrency',
    'itemQuantity',
    'remainingQuantity',
    'itemUnit',
    'companyName',
    'IGNORE'
  ];

  public excelBOEHeaders = [
    'invNo',
    'beDate',
    'beNumber',
    'beType',
    'IEC',
    'GSTIN',
    'cbCode',
    'qrCode',
    'ucr',
    'beStatus',
    'mode',
    'defBe',
    'reimp',
    'advBe',
    'hss',
    'countryOfOrigin',
    'countryOfConsignment',
    'portOfLoading',
    'portOfShipment',
    'importerName',
    'importerAddress',
    'cbName',
    'adCode',
    'bcd',
    'acd',
    'sws',
    'nccd',
    'add',
    'containerNo',
    'cvd',
    'igst',
    'cess',
    'totalAssessmentValue',
    'totalDuty',
    'interest',
    'penalty',
    'fine',
    'totalAmount',
    'igmNo',
    'igmDate',
    'inwDate',
    'mawbNo',
    'pkg',
    'gw',
    'bondNo',
    'bgAmt',
    'challanNo',
    'paidOn',
    'amount',
    'currency',
    'exchangeRate',
    'noOfContainers',
    'lcf',
    'sealNo',
    'oocNo',
    'oocDate',
    'supplierId',
    'thirdPartyName',
    'thirdPartyAddress',
    'deliveryTerms',
    'valuationMethod',
    'lcNo',
    'lcDate',
    'poNo',
    'poDate',
    'itemCode',
    'itemHSNCode',
    'itemSubCode',
    'itemName',
    'itemDescription',
    'itemPrice',
    'itemCurrency',
    'itemQuantity',
    'remainingQuantity',
    'itemUnit',
    'companyName',
    'companyEmail',
    'companyPhoneNo',
    'companyCountryCode',
    'companyFaxNo',
    'companyTaxNo',
    'companyBillingAddress',
    'companyShippingAddress',
    'IGNORE'
  ];

  public excelBOLHeaders = [
    'blDate',
    'blNumber',
    'mode',
    'trackingNo',
    'placeOfIssue',
    'consigneeId',
    'vesselName',
    'portOfLoading',
    'portOfDischarge',
    'placeOfDelivery',
    'totalWeight',
    'uomWt',
    'noOfPackages',
    'desc',
    'vesselSailingDate',
    'bookingNo',
    'receivedForShipmentDate',
    'totalPckgRcvdByCarrier',
    'shipperName',
    'shipperAddress',
    'notifyPartyId',
    'voyageDetails',
    'placeOfReceipt',
    'containerNo',
    'invNo',
    'measurement',
    'uomVol',
    'containerSize',
    'freightCharges',
    'negociableInstrumentHeldBy',
    'negotiableCopy',
    'status',
    'carrierName',
    'noOfOriginals',
    'shipmentRefNo',
    'shippedOnBoardDate',
    'itemCode',
    'itemHSNCode',
    'itemSubCode',
    'itemName',
    'itemDescription',
    'itemPrice',
    'itemCurrency',
    'itemQuantity',
    'remainingQuantity',
    'itemUnit',
    'companyName',
    'IGNORE'
  ];

  public SELECTED_COLUMNS = [];

  private fileSubject = new Subject<File>();
  public file: File;
  public file$ = this.fileSubject.asObservable();
  public fileName = '';

  public sheetData = [];
  public isLoadingSheetData = false;

  public type: string;
  public shouldCreateShipment = true;

  public previewResult: ExcelUploadResult = {};
  public uploadResult: any;

  public shouldShowLoader = false;

  constructor(
    private orgInvApi: OrgInvoiceService,
    private orgOrderApi: OrgOrdersService,
    private shipmentDocsApi: ShipmentDocsService,
    private activatedRoute: ActivatedRoute,
    public commonService: CommonSupportService
  ) {
    this.file$.subscribe((file) => {
      this.file = file;
      this.fileName = file.name;
      const reader: FileReader = new FileReader();

      reader.onload = (e: any) => {
        this.isLoadingSheetData = false;
        /* read workbook */
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

        /* save data */
        this.sheetData = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], {
          raw: false,
          header: 1,
          blankrows: false,
          dateNF: 'yyyy-mm-dd'
        });

        this.findBestMatchColumns();
      };

      this.isLoadingSheetData = true;
      reader.readAsBinaryString(file);
    });
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(param => {
      if (param && param.type) {
        this.type = param.type;
      }
    });
  }

  public setFile(event: any): void {
    this.fileSubject.next(event.srcElement.files[0]);
  }

  /* Fn to find the best matching column */
  private findBestMatchColumns(): void {
    this.SELECTED_COLUMNS = this.sheetData[0].map((colName: any) => {
      if (this.type === 'SALES_ORDER') {
        if (stringSimilarity.findBestMatch(colName, this.excelSOHeaders).bestMatch.rating >= 0.4) {
          return stringSimilarity.findBestMatch(colName, this.excelSOHeaders).bestMatch.target;
        } else {
          return 'IGNORE';
        }
      } else if (this.type === 'PURCHASE_ORDER') {
        if (stringSimilarity.findBestMatch(colName, this.excelPOHeaders).bestMatch.rating >= 0.4) {
          return stringSimilarity.findBestMatch(colName, this.excelPOHeaders).bestMatch.target;
        } else {
          return 'IGNORE';
        }
      } else if (this.type === 'SALES') {
        if (stringSimilarity.findBestMatch(colName, this.excelInvHeaders).bestMatch.rating >= 0.4) {
          return stringSimilarity.findBestMatch(colName, this.excelInvHeaders).bestMatch.target;
        } else {
          return 'IGNORE';
        }
      } else if (this.type === 'SHIPPING_BILL') {
        if (stringSimilarity.findBestMatch(colName, this.excelSBHeaders).bestMatch.rating >= 0.4) {
          return stringSimilarity.findBestMatch(colName, this.excelSBHeaders).bestMatch.target;
        } else {
          return 'IGNORE';
        }
      } else if (this.type === 'BILL_OF_ENTRY') {
        if (stringSimilarity.findBestMatch(colName, this.excelBOEHeaders).bestMatch.rating >= 0.4) {
          return stringSimilarity.findBestMatch(colName, this.excelBOEHeaders).bestMatch.target;
        } else {
          return 'IGNORE';
        }
      } else if (this.type === 'BILL_OF_LADING') {
        if (stringSimilarity.findBestMatch(colName, this.excelBOLHeaders).bestMatch.rating >= 0.4) {
          return stringSimilarity.findBestMatch(colName, this.excelBOLHeaders).bestMatch.target;
        } else {
          return 'IGNORE';
        }
      } else {
        if (stringSimilarity.findBestMatch(colName, this.excelVBHeaders).bestMatch.rating >= 0.4) {
          return stringSimilarity.findBestMatch(colName, this.excelVBHeaders).bestMatch.target;
        } else {
          return 'IGNORE';
        }
      }
    });
  }

  /* Fn to call the appripriate preview function */
  previewChanges(): void {
    $('#errorCollapse').collapse('show');
    this.shouldShowLoader = true;
    if (this.type === 'SALES_ORDER') {
      this.previewSOChanges();
    } else if (this.type === 'PURCHASE_ORDER') {
      this.previewPOChanges();
    } else if (this.type === 'SHIPPING_BILL') {
      this.previewSBChanges();
    } else if (this.type === 'BILL_OF_ENTRY') {
      this.previewBOEChanges();
    } else if (this.type === 'BILL_OF_LADING') {
      this.previewBOLChanges();
    } else {
      this.previewInvoiceChanges();
    }
  }

  /* Fn to call the appropriate upload function */
  uploadExcel(): void {
    if (this.previewResult.filterWarnigs && this.previewResult.filterWarnigs.length > 0) {
      SwalHelper.showErrorSwal('Please match Mandatory Fields before proceeding');
      return;
    }
    this.shouldShowLoader = true;
    if (this.type === 'SALES_ORDER') {
      this.uploadSOExcel();
    } else if (this.type === 'PURCHASE_ORDER') {
      this.uploadPOExcel();
    } else if (this.type === 'SHIPPING_BILL') {
      this.uploadSBExcel();
    } else if (this.type === 'BILL_OF_ENTRY') {
      this.uploadBOEExcel();
    } else if (this.type === 'BILL_OF_LADING') {
      this.uploadBOLExcel();
    } else {
      this.uploadInvoice();
    }
  }

  /* Fn to go to previous step */
  prevStep(): void {
    const currentTab = $('#stepTab').find('a.active').closest('li').prev('li').find('a').attr('id');
    $('#' + currentTab).tab('show');
  }

  /* Preview of the invoice excel */
  public previewInvoiceChanges(): void {
    this.orgInvApi.uploadInvoiceExcel(
      this.file,
      this.type,
      this.shouldCreateShipment,
      false,
      true,
      JSON.stringify(this.SELECTED_COLUMNS))
      .subscribe((result) => {
        this.shouldShowLoader = false;
        $('#nav-step2-tab').tab('show');
        this.previewResult = result;
      }, (err) => {
        Swal.fire({
          title: 'Whoops',
          html: err.error ? err.error.reduce((msg, er) => msg + '<br' + er) : err.message,
          type: 'error',
        });
      });
  }

  /* Upload the invoice excel */
  uploadInvoice(): void {
    this.orgInvApi.uploadInvoiceExcel(
      this.file,
      this.type,
      this.shouldCreateShipment,
      false,
      false,
      JSON.stringify(this.SELECTED_COLUMNS))
      .subscribe((result) => {
        this.shouldShowLoader = false;
        this.uploadResult = result;
        $('#nav-step3-tab').tab('show');
        SwalHelper.successTimerSwal('Uploaded excel file');
      }, (err) => {
        Swal.fire({
          title: 'Whoops',
          html: err.error ? err.error.reduce((msg, er) => msg + '<br' + er) : err.message,
          type: 'error',
        });
      });
  }

  /* Fn to preview the Purchase Order Excel */
  public previewPOChanges(): void {
    this.orgOrderApi.uploadPoExcel(
      this.file,
      false,
      true,
      JSON.stringify(this.SELECTED_COLUMNS))
      .subscribe((result) => {
        this.shouldShowLoader = false;
        this.previewResult = result;
        $('#nav-step2-tab').tab('show');
      }, (err) => {
        Swal.fire({
          title: 'Whoops',
          html: err.error ? err.error.reduce((msg, er) => msg + '<br' + er) : err.message,
          type: 'error',
        });
      });
  }

  /* Fn to Upload the Purchase Order Excel */
  uploadPOExcel(): void {
    this.orgOrderApi.uploadPoExcel(
      this.file,
      false,
      false,
      JSON.stringify(this.SELECTED_COLUMNS))
      .subscribe((result) => {
        this.shouldShowLoader = false;
        this.uploadResult = result;
        $('#nav-step3-tab').tab('show');
        SwalHelper.successTimerSwal('Uploaded excel file');
      }, (err) => {
        Swal.fire({
          title: 'Whoops',
          html: err.error ? err.error.reduce((msg, er) => msg + '<br' + er) : err.message,
          type: 'error',
        });
      });
  }

  /* Fn to preview the Sales Order Excel */
  public previewSOChanges(): void {
    this.orgOrderApi.uploadSoExcel(
      this.file,
      false,
      true,
      JSON.stringify(this.SELECTED_COLUMNS))
      .subscribe((result) => {
        this.shouldShowLoader = false;
        this.previewResult = result;
        $('#nav-step2-tab').tab('show');
      }, (err) => {
        Swal.fire({
          title: 'Whoops',
          html: err.error ? err.error.reduce((msg, er) => msg + '<br' + er) : err.message,
          type: 'error',
        });
      });
  }

  /* Fn to upload the Sales Order Excel */
  uploadSOExcel(): void {
    this.orgOrderApi.uploadSoExcel(
      this.file,
      false,
      false,
      JSON.stringify(this.SELECTED_COLUMNS))
      .subscribe((result) => {
        this.shouldShowLoader = false;
        this.uploadResult = result;
        $('#nav-step3-tab').tab('show');
        SwalHelper.successTimerSwal('Uploaded excel file');
      }, (err) => {
        Swal.fire({
          title: 'Whoops',
          html: err.error ? err.error.reduce((msg, er) => msg + '<br' + er) : err.message,
          type: 'error',
        });
      });
  }

  /* Fn to preview the Shipping Bill Excel */
  public previewSBChanges(): void {
    this.shipmentDocsApi.uploadShippingBillExcel(
      this.file,
      false,
      true,
      JSON.stringify(this.SELECTED_COLUMNS))
      .subscribe((result) => {
        this.shouldShowLoader = false;
        this.previewResult = result;
        $('#nav-step2-tab').tab('show');
      }, (err) => {
        Swal.fire({
          title: 'Whoops',
          html: err.error ? err.error.reduce((msg, er) => msg + '<br' + er) : err.message,
          type: 'error',
        });
      });
  }

  /* Fn to upload the Shipping Bill Excel */
  uploadSBExcel(): void {
    this.shipmentDocsApi.uploadShippingBillExcel(
      this.file,
      false,
      false,
      JSON.stringify(this.SELECTED_COLUMNS))
      .subscribe((result) => {
        this.shouldShowLoader = false;
        this.uploadResult = result;
        $('#nav-step3-tab').tab('show');
        SwalHelper.successTimerSwal('Uploaded excel file');
      }, (err) => {
        Swal.fire({
          title: 'Whoops',
          html: err.error ? err.error.reduce((msg, er) => msg + '<br' + er) : err.message,
          type: 'error',
        });
      });
  }

  /* Fn to preview the bill of entry excel */
  public previewBOEChanges(): void {
    this.shipmentDocsApi.uploadBoeExcel(
      this.file,
      false,
      true,
      JSON.stringify(this.SELECTED_COLUMNS))
      .subscribe((result) => {
        this.previewResult = result;
        this.shouldShowLoader = false;
        $('#nav-step2-tab').tab('show');
      }, (err) => {
        Swal.fire({
          title: 'Whoops',
          html: err.error ? err.error.reduce((msg, er) => msg + '<br' + er) : err.message,
          type: 'error',
        });
      });
  }

  /* Fn to upload the bill of entry excel */
  uploadBOEExcel(): void {
    this.shipmentDocsApi.uploadBoeExcel(
      this.file,
      false,
      false,
      JSON.stringify(this.SELECTED_COLUMNS))
      .subscribe((result) => {
        this.uploadResult = result;
        this.shouldShowLoader = false;
        $('#nav-step3-tab').tab('show');
        SwalHelper.successTimerSwal('Uploaded excel file');
      }, (err) => {
        Swal.fire({
          title: 'Whoops',
          html: err.error ? err.error.reduce((msg, er) => msg + '<br' + er) : err.message,
          type: 'error',
        });
      });
  }

  /* Fn to preview the bill of lading details */
  public previewBOLChanges(): void {
    this.shipmentDocsApi.uploadBlExcel(
      this.file,
      false,
      true,
      JSON.stringify(this.SELECTED_COLUMNS))
      .subscribe((result) => {
        this.previewResult = result;
        this.shouldShowLoader = false;
        $('#nav-step2-tab').tab('show');
      }, (err) => {
        Swal.fire({
          title: 'Whoops',
          html: err.error ? err.error.reduce((msg, er) => msg + '<br' + er) : err.message,
          type: 'error',
        });
      });
  }

  /* Fn to upload the Bill of lading excel */
  uploadBOLExcel(): void {
    this.shipmentDocsApi.uploadBlExcel(
      this.file,
      false,
      false,
      JSON.stringify(this.SELECTED_COLUMNS))
      .subscribe((result) => {
        this.uploadResult = result;
        this.shouldShowLoader = false;
        $('#nav-step3-tab').tab('show');
        SwalHelper.successTimerSwal('Uploaded excel file');
      }, (err) => {
        Swal.fire({
          title: 'Whoops',
          html: err.error ? err.error.reduce((msg, er) => msg + '<br' + er) : err.message,
          type: 'error',
        });
      });
  }

}
