import { Component, OnInit, Renderer2, HostListener, OnDestroy } from '@angular/core';
import { Shipment, OrgShipmentsService, VendorAndSupplier, AuthService } from 'src/swagger';
import { SwalHelper } from 'src/app/swal';
import { CommonSupportService } from 'src/app/common-support.service';
import * as moment from 'moment';
import { GlobalProfileService } from '../../global-profile.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
declare var $: any;
declare var swal: any;
@Component({
  selector: 'app-list-shipments',
  templateUrl: './list-shipments.component.html',
  styleUrls: ['./list-shipments.component.scss']
})
export class ListShipmentsComponent implements OnInit, OnDestroy {

  public shipmentType: string;
  dtOptions: any = [];

  public allShipments: Shipment[] = [];
  public sharedShipments: Shipment[] = [];
  public reminderShipments: Shipment = {};

  public shipmentDate: Date;
  public shipmentEndDate: Date;
  public selectedShipment: number;
  public shipmentStatus: string;
  public shouldShowLoader: boolean;

  public rightSheet = false;
  public newVendorSupplier: VendorAndSupplier = {};

  public getFunded = false;
  public isSharedWithMeEnable = false;

  private userSubscription: Subscription;

  public cloneShipmentId: number;
  public clonedShipmentPre: string;
  public clonedShipmentSuf: string;

  public shipmentDetails: Shipment = {}; // To send shipment details

  constructor(
    private orgShipmentApi: OrgShipmentsService,
    private commonService: CommonSupportService,
    private renderer: Renderer2,
    public profileSync: GlobalProfileService,
    private auth: AuthService,
  ) {
    commonService.renderer = renderer;
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent): void {
    this.rightSheet = false;
    this.renderer.removeClass(document.body, 'modal-open');
  }

  ngOnInit(): void {
    this.dtOptions[0] = {
      columnDefs: [
        { orderable: false, targets: -1 },
        { targets: 3, type: 'num-fmt' },
        { targets: 6, type: 'date' },
      ],
    };
    this.dtOptions[1] = {
      columnDefs: [
        { orderable: false, targets: -1 },
        { targets: 3, type: 'num-fmt' },
        { targets: 6, type: 'date' },
      ],
    };
    this.shipmentStatus = 'All';
    this.userSubscription = this.profileSync.user$.pipe(take(1)).subscribe(user => {
      if (user && user.OrganisationRole && user.OrganisationRole.permissions && user.OrganisationRole.permissions.Shipment.read) {
        this.fetchAllShipments('SELL');
      }
    });
    this.checkLocalStorage();
  }

  ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  /* Fn to check if user has come to this page via redirection & has predefined seller/buyer Id */
  checkLocalStorage(): void {
    if (localStorage.getItem('SHIPMENT_SELLER_ID')) {
      this.shipmentDetails = {
        sellerId: parseInt(localStorage.getItem('SHIPMENT_SELLER_ID'), 10),
        shipmentType: 'BUY',
        shipmentTags: [],
        buyerId: 0,
        shipmentCurrency: 'USD',
        mode: 'Sea',
      };
    } else if (localStorage.getItem('SHIPMENT_BUYER_ID')) {
      this.shipmentDetails = {
        buyerId: parseInt(localStorage.getItem('SHIPMENT_BUYER_ID'), 10),
        shipmentType: 'SELL',
        shipmentTags: [],
        sellerId: 0,
        shipmentCurrency: 'USD',
        mode: 'Sea',
      };
    } else {
      return;
    }
    this.addShipment();
    localStorage.removeItem('SHIPMENT_SELLER_ID');
    localStorage.removeItem('SHIPMENT_BUYER_ID');
  }

  /* Fn to get all the external shipments shared with user */
  getExternalShipmentShared(event: any): void {
    this.isSharedWithMeEnable = event;
    if (event) {
      this.allShipments = [];
      this.auth.serviceProviderSharedShipments(
        this.shipmentDate ? moment(this.shipmentDate).format('YYYY-MM-DD') : null,
        this.shipmentEndDate ? moment(this.shipmentEndDate).format('YYYY-MM-DD') : null,
        this.shipmentStatus === 'All' ? undefined : this.shipmentStatus
      )
        .subscribe(ships => {
          this.shouldShowLoader = false;
          if (ships && ships.length > 0) {
            this.sharedShipments = ships.filter(i => i.shipmentType == this.shipmentType);
          } else {
            this.sharedShipments = [];
          }
        }, err => {
          this.shouldShowLoader = false;
          SwalHelper.showErrorSwal(err);
        });
    } else {
      this.sharedShipments = [];
      this.fetchAllShipments(this.shipmentType);
    }
  }

  redirectToNuPhi(): void {
    $('#getFundedModal').modal('show');
  }

  newVendor(vendor): void {
    this.newVendorSupplier = vendor;
  }

  getFundedVal(): void {
    this.getFunded = !this.getFunded;
  }

  getFund(): void {
    $('#getFundedModal').modal('hide');
    window.open('https://nuphi.credit/', '_blank');
    SwalHelper.successTimerSwal('Details shared with NuPhi Credit!');
  }

  /* Fn to fetch all the shipment based on shipment type */
  fetchAllShipments(shipmentType: string): void {
    this.allShipments = [];
    this.shipmentType = shipmentType;
    this.shipmentType === 'BUY' ? $('#purchase-tab').tab('show') : $('#sales-tab').tab('show');
    this.shouldShowLoader = true;
    this.orgShipmentApi.listAllShipments(
      this.shipmentType,
      this.shipmentDate ? moment(this.shipmentDate).format('YYYY-MM-DD') : null,
      this.shipmentEndDate ? moment(this.shipmentEndDate).format('YYYY-MM-DD') : null,
      this.shipmentStatus === 'All' ? undefined : this.shipmentStatus
    ).subscribe(shipmets => {
      this.shouldShowLoader = false;
      if (shipmets && shipmets.length > 0) {
        this.allShipments = shipmets;
      } else {
        this.allShipments = null;
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /* Fn to reset the filter */
  resetFilter(): void {
    this.shipmentStatus = 'All';
    this.shipmentDate = undefined;
  }

  /* Fn to open the shipment form right sheet */
  addShipment(): void {
    this.selectedShipment = undefined;
    this.rightSheetOpen();
  }

  /* Fn to slect the shiment for edit details */
  editDetails(index: number): void {
    this.selectedShipment = this.allShipments[index].shipmentId;
    this.rightSheetOpen();
  }

  /* Fn to open the right sheet for shipment form*/
  rightSheetOpen(): void {
    this.rightSheet = !this.rightSheet;
    this.renderer.addClass(document.body, 'modal-open');
    this.commonService.addBackdrop();
  }

  /* Fn to close the right sheet for shipment form */
  rightSheetClose(): void {
    this.rightSheet = false;
    this.renderer.removeClass(document.body, 'modal-open');
    this.commonService.removeBackdrop();
  }

  /* Fn to receive shipment data from shipment update component */
  receivedData(shipment): void {
    const index = this.allShipments.findIndex(i => i.shipmentId === shipment.shipmentId);
    if (index >= 0) {
      this.allShipments[index] = shipment;
    } else {
      this.allShipments.push(shipment);
    }
    this.selectedShipment = undefined;
    this.rightSheetClose();
  }

  /**
   * Fn to delete the shipment only in draft state
   * @param index Index of shipment
   */
  deleteDetails(index: number): void {
    swal({
      title: 'Delete Shipment?',
      text: 'This cannot be undone',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        this.shouldShowLoader = true;
        return new Promise((resolve, reject) => {
          this.orgShipmentApi.deleteShipment(this.allShipments[index].shipmentId).subscribe(ok => {
            this.shouldShowLoader = false;
            this.allShipments.splice(index, 1);
            resolve(ok);
          }, err => {
            reject(err);
          });
        });
      },
      allowOutsideClick: () => !swal.isLoading()
    }).then((result) => {
      if (result.value) {
        this.shouldShowLoader = false;
        SwalHelper.successTimerSwal('Shipment deleted!');
      }
    }).catch(err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /**
   * Fn to archive the shipment after unlinking all the documents
   * @param index index of shipment
   */
  archiveShipment(index: number): void {
    swal({
      title: 'Archive Shipment',
      text: 'Unlink all the Linked documents first.',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Archive',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        this.shouldShowLoader = true;
        return new Promise((resolve, reject) => {
          this.orgShipmentApi.archiveShipment(
            this.allShipments[index].shipmentId,
          ).subscribe(inv => {
            this.shouldShowLoader = false;
            this.allShipments[index].shipmentStatus = 'ARCHIVED';
            resolve(inv);
          }, err => {
            reject(err);
          });
        });
      },
      allowOutsideClick: () => !swal.isLoading()
    }).then((result) => {
      if (result.value) {
        this.shouldShowLoader = false;
        SwalHelper.successTimerSwal('Shipment Archived!');
      }
    }).catch(err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /* Fn to check if requested document has access permission */
  hasDocPermission(object: any): boolean {
    return Object.keys(object).every((k) => object[k] == true) ? true : false;
  }

  /**
   * Fn to open modal for clone shipment
   * @param index Index of shipment to clone
   */
  cloneShipmentModal(index: number): void {
    this.cloneShipmentId = this.allShipments[index].shipmentId;
    this.addShipment();
  }

  /**
   * Fn to open modal for reminder creation
   * @param index Index of shipment to create reminder
   */
  openReminder(index: number): void {
    $('#reminderModal').modal('show');
    this.reminderShipments = this.allShipments[index];
  }

}
