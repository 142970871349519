/**
 * Nuphi trade
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2.0.0
 * Contact: shraddha@blackcurrantapps.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { DocumentAttachment } from './documentAttachment';
import { Invoice } from './invoice';
import { Organisation } from './organisation';
import { Shipment } from './shipment';
import { ShipmentDocument } from './shipmentDocument';
import { StatusHistory } from './statusHistory';
import { VendorAndSupplier } from './vendorAndSupplier';


export interface BillOfLading { 
    billOfLadingId?: number;
    orgId?: number;
    shipmentId?: number;
    blDate?: string;
    blNumber?: string;
    mode?: string;
    trackingNo?: string;
    placeOfIssue?: string;
    consigneeId?: number;
    vesselName?: string;
    portOfLoading?: string;
    portOfDischarge?: string;
    placeOfDelivery?: string;
    totalWeight?: number;
    uomWt?: string;
    noOfPackages?: number;
    desc?: string;
    vesselSailingDate?: string;
    bookingNo?: string;
    receivedForShipmentDate?: string;
    totalPckgRcvdByCarrier?: number;
    shipperName?: string;
    shipperAddress?: string;
    notifyPartyId?: number;
    voyageDetails?: any;
    placeOfReceipt?: string;
    containerNo?: string;
    invId?: number;
    measurement?: any;
    uomVol?: string;
    containerSize?: any;
    freightCharges?: number;
    negociableInstrumentHeldBy?: string;
    negotiableCopy?: string;
    status?: BillOfLading.StatusEnum;
    carrierName?: string;
    noOfOriginals?: number;
    shipmentRefNo?: string;
    shippedOnBoardDate?: string;
    importedDocUrl?: string;
    ocrS3FolderUrl?: string;
    numPages?: string;
    ocrStatus?: string;
    Organisation?: Organisation;
    Shipment?: Shipment;
    Consignee?: VendorAndSupplier;
    NotifyParty?: VendorAndSupplier;
    Invoice?: Invoice;
    DocumentAttachments?: Array<DocumentAttachment>;
    StatusHistories?: Array<StatusHistory>;
    ShipmentDocuments?: Array<ShipmentDocument>;
}
export namespace BillOfLading {
    export type StatusEnum = 'DRAFT' | 'SHIPPED_ON_BOARD' | 'TELEX_RELEASED' | 'IN_TRANSIT' | 'COURIER_RELEASED' | 'DELIVERED' | 'DISPUTED' | 'ARCHIVED';
    export const StatusEnum = {
        DRAFT: 'DRAFT' as StatusEnum,
        SHIPPEDONBOARD: 'SHIPPED_ON_BOARD' as StatusEnum,
        TELEXRELEASED: 'TELEX_RELEASED' as StatusEnum,
        INTRANSIT: 'IN_TRANSIT' as StatusEnum,
        COURIERRELEASED: 'COURIER_RELEASED' as StatusEnum,
        DELIVERED: 'DELIVERED' as StatusEnum,
        DISPUTED: 'DISPUTED' as StatusEnum,
        ARCHIVED: 'ARCHIVED' as StatusEnum
    };
}
