import { Component, OnInit } from '@angular/core';
import { OrganisationService } from '../../../../swagger/api/organisation.service';
import { Shipment } from '../../../../swagger/model/shipment';
import { SwalHelper } from '../../../swal';
import { GlobalProfileService } from '../../../global-profile.service';

@Component({
  selector: 'app-my-workspace',
  templateUrl: './my-workspace.component.html',
  styleUrls: ['./my-workspace.component.scss']
})
export class MyWorkspaceComponent implements OnInit {

  public shipmentStatus: string;
  public myWorkSpace: Shipment[] = [];

  public shouldShowLoader = false;
  dtOptions: DataTables.Settings = {};

  constructor(
    private orgApi: OrganisationService,
    public profileSync: GlobalProfileService
  ) { }

  ngOnInit(): void {
    this.shipmentStatus = 'INVOICE';
    this.fetchShipments('INVOICE');
  }

  /* Fn to fetch the details of my workspace */
  fetchShipments(status: string): void {
    this.shipmentStatus = status;
    this.myWorkSpace = [];
    this.shouldShowLoader = true;
    this.orgApi.myWorkspace(this.shipmentStatus).subscribe(ships => {
      this.shouldShowLoader = false;
      if (ships && ships.length > 0) {
        this.myWorkSpace = ships;
        if (this.shipmentStatus === 'ORDER') {
          this.dtOptions = {
            columnDefs: [
              { targets: [4], type: 'date' },
            ],
          };
        } else if (this.shipmentStatus === 'INVOICE') {
          this.dtOptions = {
            columnDefs: [
              { targets: [6], type: 'date' },
            ],
          };
        } else if (this.shipmentStatus === 'IN_TRANSIT' || this.shipmentStatus === 'DELIVERED') {
          this.dtOptions = {
            columnDefs: [
              { targets: [6, 8], type: 'date' },
            ],
          };
        } else {
          this.dtOptions = {
            columnDefs: [
              { targets: [4, 6], type: 'date' },
            ],
          };
        }
      } else {
        this.myWorkSpace = [];
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

}
