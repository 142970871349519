import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OpenService, LoginRequest } from 'src/swagger';
import { SwalHelper } from '../../swal';
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  public loginRequest: LoginRequest = {};
  public confirmPassword: string;

  constructor(
    public activatedRoute: ActivatedRoute,
    private openApi: OpenService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      if (params.uuid) {
        this.loginRequest.uuid = params.uuid;
      }
    });
  }
  // Function to call reset password API
  changePassword(form): boolean {
    if (form.valid) {
      this.openApi.resetPassword(
        this.loginRequest,
      ).subscribe(ok => {
        SwalHelper.successTimerSwal('You have changed your password successfully!');
        this.router.navigate(['/login']);
      }, err => {
        SwalHelper.showErrorSwal(err);
      });
    } else {
      return false;
    }
  }

}
