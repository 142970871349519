import { Component, OnInit } from '@angular/core';
import { SwalHelper } from 'src/app/swal';
import { ServiceProvider, OrganisationService } from 'src/swagger';
@Component({
  selector: 'app-service-providers',
  templateUrl: './service-providers.component.html',
  styleUrls: ['./service-providers.component.scss']
})
export class ServiceProvidersComponent implements OnInit {

  public servProv: ServiceProvider[] = [];
  public rating: number;

  public searchCategory: string;
  public location: string;
  public companyName: string;

  public isCertified: boolean;
  public shouldShowLoader: boolean;

  constructor(
    private orgApi: OrganisationService,
  ) { }

  ngOnInit(): void {
    this.fetchProviders();
  }

  Ratings(n: number): Array<number> {
    return Array(n);
  }
  // Fn to fetch all the providers
  fetchProviders(): void {
    this.servProv = [];
    this.shouldShowLoader = true;
    this.orgApi.listServiceProvider(
      this.companyName,
      this.rating,
      this.searchCategory,
      this.isCertified,
      this.location)
      .subscribe(servProviders => {
        this.shouldShowLoader = false;
        this.servProv = servProviders;
      }, err => {
        this.shouldShowLoader = false;
        SwalHelper.showErrorSwal(err);
      });
  }
  // Fn to reset the filter & fetch all providers
  filterReset(): void {
    this.rating = undefined;
    this.searchCategory = undefined;
    this.isCertified = undefined;
    this.location = undefined;
    this.companyName = undefined;
    this.fetchProviders();
  }

}

