/**
 * Nuphi trade
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2.0.0
 * Contact: shraddha@blackcurrantapps.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ExpenseTrackerItem } from '../model/expenseTrackerItem';
import { Invoice } from '../model/invoice';
import { PurchaseOrder } from '../model/purchaseOrder';
import { SalesOrder } from '../model/salesOrder';
import { Shipment } from '../model/shipment';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable({
  providedIn: 'root'
})
export class ReportsService {

    protected basePath = 'http://localhost:3000';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * get expense_analysis report
     * Get the report of expense_analysis in an organisation
     * @param trackType 
     * @param expenseType 
     * @param shipmentType 
     * @param dateOfInvTo 
     * @param dateOfInvFrom 
     * @param shipmentStatus 
     * @param shipmentCurrency 
     * @param invoiceStatus 
     * @param vendorId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public expenseAnalysis(trackType: string, expenseType?: string, shipmentType?: string, dateOfInvTo?: string, dateOfInvFrom?: string, shipmentStatus?: string, shipmentCurrency?: string, invoiceStatus?: string, vendorId?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<ExpenseTrackerItem>>;
    public expenseAnalysis(trackType: string, expenseType?: string, shipmentType?: string, dateOfInvTo?: string, dateOfInvFrom?: string, shipmentStatus?: string, shipmentCurrency?: string, invoiceStatus?: string, vendorId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ExpenseTrackerItem>>>;
    public expenseAnalysis(trackType: string, expenseType?: string, shipmentType?: string, dateOfInvTo?: string, dateOfInvFrom?: string, shipmentStatus?: string, shipmentCurrency?: string, invoiceStatus?: string, vendorId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ExpenseTrackerItem>>>;
    public expenseAnalysis(trackType: string, expenseType?: string, shipmentType?: string, dateOfInvTo?: string, dateOfInvFrom?: string, shipmentStatus?: string, shipmentCurrency?: string, invoiceStatus?: string, vendorId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (trackType === null || trackType === undefined) {
            throw new Error('Required parameter trackType was null or undefined when calling expenseAnalysis.');
        }









        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (trackType !== undefined && trackType !== null) {
            queryParameters = queryParameters.set('trackType', <any>trackType);
        }
        if (expenseType !== undefined && expenseType !== null) {
            queryParameters = queryParameters.set('expenseType', <any>expenseType);
        }
        if (shipmentType !== undefined && shipmentType !== null) {
            queryParameters = queryParameters.set('shipmentType', <any>shipmentType);
        }
        if (dateOfInvTo !== undefined && dateOfInvTo !== null) {
            queryParameters = queryParameters.set('dateOfInvTo', <any>dateOfInvTo);
        }
        if (dateOfInvFrom !== undefined && dateOfInvFrom !== null) {
            queryParameters = queryParameters.set('dateOfInvFrom', <any>dateOfInvFrom);
        }
        if (shipmentStatus !== undefined && shipmentStatus !== null) {
            queryParameters = queryParameters.set('shipmentStatus', <any>shipmentStatus);
        }
        if (shipmentCurrency !== undefined && shipmentCurrency !== null) {
            queryParameters = queryParameters.set('shipmentCurrency', <any>shipmentCurrency);
        }
        if (invoiceStatus !== undefined && invoiceStatus !== null) {
            queryParameters = queryParameters.set('invoiceStatus', <any>invoiceStatus);
        }
        if (vendorId !== undefined && vendorId !== null) {
            queryParameters = queryParameters.set('vendorId', <any>vendorId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<ExpenseTrackerItem>>(`${this.basePath}/organisation/reports/expense_analysis`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get Invoice report
     * Get the report of invoice in an organisation
     * @param dateOfInvTo 
     * @param dateOfInvFrom 
     * @param dueDateTo 
     * @param dueDateFrom 
     * @param invoiceStatus 
     * @param currency 
     * @param vendorId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public invoiceReport(dateOfInvTo?: string, dateOfInvFrom?: string, dueDateTo?: string, dueDateFrom?: string, invoiceStatus?: string, currency?: string, vendorId?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<Invoice>>;
    public invoiceReport(dateOfInvTo?: string, dateOfInvFrom?: string, dueDateTo?: string, dueDateFrom?: string, invoiceStatus?: string, currency?: string, vendorId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Invoice>>>;
    public invoiceReport(dateOfInvTo?: string, dateOfInvFrom?: string, dueDateTo?: string, dueDateFrom?: string, invoiceStatus?: string, currency?: string, vendorId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Invoice>>>;
    public invoiceReport(dateOfInvTo?: string, dateOfInvFrom?: string, dueDateTo?: string, dueDateFrom?: string, invoiceStatus?: string, currency?: string, vendorId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {








        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (dateOfInvTo !== undefined && dateOfInvTo !== null) {
            queryParameters = queryParameters.set('dateOfInvTo', <any>dateOfInvTo);
        }
        if (dateOfInvFrom !== undefined && dateOfInvFrom !== null) {
            queryParameters = queryParameters.set('dateOfInvFrom', <any>dateOfInvFrom);
        }
        if (dueDateTo !== undefined && dueDateTo !== null) {
            queryParameters = queryParameters.set('dueDateTo', <any>dueDateTo);
        }
        if (dueDateFrom !== undefined && dueDateFrom !== null) {
            queryParameters = queryParameters.set('dueDateFrom', <any>dueDateFrom);
        }
        if (invoiceStatus !== undefined && invoiceStatus !== null) {
            queryParameters = queryParameters.set('invoiceStatus', <any>invoiceStatus);
        }
        if (currency !== undefined && currency !== null) {
            queryParameters = queryParameters.set('currency', <any>currency);
        }
        if (vendorId !== undefined && vendorId !== null) {
            queryParameters = queryParameters.set('vendorId', <any>vendorId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<Invoice>>(`${this.basePath}/organisation/reports/invoice_report`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get shipment report
     * Get the report of Order recon in an organisation
     * @param orderType 
     * @param orgOrderDateTo 
     * @param orgOrderDateFrom 
     * @param status 
     * @param currency 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public orderReconReport(orderType?: string, orgOrderDateTo?: string, orgOrderDateFrom?: string, status?: string, currency?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<any>>;
    public orderReconReport(orderType?: string, orgOrderDateTo?: string, orgOrderDateFrom?: string, status?: string, currency?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<any>>>;
    public orderReconReport(orderType?: string, orgOrderDateTo?: string, orgOrderDateFrom?: string, status?: string, currency?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<any>>>;
    public orderReconReport(orderType?: string, orgOrderDateTo?: string, orgOrderDateFrom?: string, status?: string, currency?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (orderType !== undefined && orderType !== null) {
            queryParameters = queryParameters.set('orderType', <any>orderType);
        }
        if (orgOrderDateTo !== undefined && orgOrderDateTo !== null) {
            queryParameters = queryParameters.set('orgOrderDateTo', <any>orgOrderDateTo);
        }
        if (orgOrderDateFrom !== undefined && orgOrderDateFrom !== null) {
            queryParameters = queryParameters.set('orgOrderDateFrom', <any>orgOrderDateFrom);
        }
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', <any>status);
        }
        if (currency !== undefined && currency !== null) {
            queryParameters = queryParameters.set('currency', <any>currency);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<any>>(`${this.basePath}/organisation/reports/order_recon_report`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get Purchase Order report
     * Get the report of Purchase order in an organisation
     * @param orgOrderDateTo 
     * @param orgOrderDateFrom 
     * @param status 
     * @param currency 
     * @param vendorId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public poReport(orgOrderDateTo?: string, orgOrderDateFrom?: string, status?: string, currency?: string, vendorId?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<PurchaseOrder>>;
    public poReport(orgOrderDateTo?: string, orgOrderDateFrom?: string, status?: string, currency?: string, vendorId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PurchaseOrder>>>;
    public poReport(orgOrderDateTo?: string, orgOrderDateFrom?: string, status?: string, currency?: string, vendorId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PurchaseOrder>>>;
    public poReport(orgOrderDateTo?: string, orgOrderDateFrom?: string, status?: string, currency?: string, vendorId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (orgOrderDateTo !== undefined && orgOrderDateTo !== null) {
            queryParameters = queryParameters.set('orgOrderDateTo', <any>orgOrderDateTo);
        }
        if (orgOrderDateFrom !== undefined && orgOrderDateFrom !== null) {
            queryParameters = queryParameters.set('orgOrderDateFrom', <any>orgOrderDateFrom);
        }
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', <any>status);
        }
        if (currency !== undefined && currency !== null) {
            queryParameters = queryParameters.set('currency', <any>currency);
        }
        if (vendorId !== undefined && vendorId !== null) {
            queryParameters = queryParameters.set('vendorId', <any>vendorId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<PurchaseOrder>>(`${this.basePath}/organisation/reports/po_report`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get shipment report
     * Get the report of shipment in an organisation
     * @param shipmentType 
     * @param dateOfInvTo 
     * @param dateOfInvFrom 
     * @param shipmentStatus 
     * @param shipmentCurrency 
     * @param invoiceStatus 
     * @param vendorId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public shipmentReport(shipmentType?: string, dateOfInvTo?: string, dateOfInvFrom?: string, shipmentStatus?: string, shipmentCurrency?: string, invoiceStatus?: string, vendorId?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<Shipment>>;
    public shipmentReport(shipmentType?: string, dateOfInvTo?: string, dateOfInvFrom?: string, shipmentStatus?: string, shipmentCurrency?: string, invoiceStatus?: string, vendorId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Shipment>>>;
    public shipmentReport(shipmentType?: string, dateOfInvTo?: string, dateOfInvFrom?: string, shipmentStatus?: string, shipmentCurrency?: string, invoiceStatus?: string, vendorId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Shipment>>>;
    public shipmentReport(shipmentType?: string, dateOfInvTo?: string, dateOfInvFrom?: string, shipmentStatus?: string, shipmentCurrency?: string, invoiceStatus?: string, vendorId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {








        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (shipmentType !== undefined && shipmentType !== null) {
            queryParameters = queryParameters.set('shipmentType', <any>shipmentType);
        }
        if (dateOfInvTo !== undefined && dateOfInvTo !== null) {
            queryParameters = queryParameters.set('dateOfInvTo', <any>dateOfInvTo);
        }
        if (dateOfInvFrom !== undefined && dateOfInvFrom !== null) {
            queryParameters = queryParameters.set('dateOfInvFrom', <any>dateOfInvFrom);
        }
        if (shipmentStatus !== undefined && shipmentStatus !== null) {
            queryParameters = queryParameters.set('shipmentStatus', <any>shipmentStatus);
        }
        if (shipmentCurrency !== undefined && shipmentCurrency !== null) {
            queryParameters = queryParameters.set('shipmentCurrency', <any>shipmentCurrency);
        }
        if (invoiceStatus !== undefined && invoiceStatus !== null) {
            queryParameters = queryParameters.set('invoiceStatus', <any>invoiceStatus);
        }
        if (vendorId !== undefined && vendorId !== null) {
            queryParameters = queryParameters.set('vendorId', <any>vendorId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<Shipment>>(`${this.basePath}/organisation/reports/shipment_report`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get Sales Order report
     * Get the report of Sales Order in an organisation
     * @param orgOrderDateTo 
     * @param orgOrderDateFrom 
     * @param status 
     * @param currency 
     * @param vendorId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public soReport(orgOrderDateTo?: string, orgOrderDateFrom?: string, status?: string, currency?: string, vendorId?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<SalesOrder>>;
    public soReport(orgOrderDateTo?: string, orgOrderDateFrom?: string, status?: string, currency?: string, vendorId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SalesOrder>>>;
    public soReport(orgOrderDateTo?: string, orgOrderDateFrom?: string, status?: string, currency?: string, vendorId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SalesOrder>>>;
    public soReport(orgOrderDateTo?: string, orgOrderDateFrom?: string, status?: string, currency?: string, vendorId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (orgOrderDateTo !== undefined && orgOrderDateTo !== null) {
            queryParameters = queryParameters.set('orgOrderDateTo', <any>orgOrderDateTo);
        }
        if (orgOrderDateFrom !== undefined && orgOrderDateFrom !== null) {
            queryParameters = queryParameters.set('orgOrderDateFrom', <any>orgOrderDateFrom);
        }
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', <any>status);
        }
        if (currency !== undefined && currency !== null) {
            queryParameters = queryParameters.set('currency', <any>currency);
        }
        if (vendorId !== undefined && vendorId !== null) {
            queryParameters = queryParameters.set('vendorId', <any>vendorId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<SalesOrder>>(`${this.basePath}/organisation/reports/so_report`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get so total
     * Get total amount group by currency
     * @param invoiceType 
     * @param status 
     * @param shipmentType 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public totalInvAmountReport(invoiceType: string, status?: string, shipmentType?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Invoice>>;
    public totalInvAmountReport(invoiceType: string, status?: string, shipmentType?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Invoice>>>;
    public totalInvAmountReport(invoiceType: string, status?: string, shipmentType?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Invoice>>>;
    public totalInvAmountReport(invoiceType: string, status?: string, shipmentType?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (invoiceType === null || invoiceType === undefined) {
            throw new Error('Required parameter invoiceType was null or undefined when calling totalInvAmountReport.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (invoiceType !== undefined && invoiceType !== null) {
            queryParameters = queryParameters.set('invoiceType', <any>invoiceType);
        }
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', <any>status);
        }
        if (shipmentType !== undefined && shipmentType !== null) {
            queryParameters = queryParameters.set('shipmentType', <any>shipmentType);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<Invoice>>(`${this.basePath}/organisation/reports/total_inv_amount_report`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get so total
     * Get total amount group by currency
     * @param status 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public totalPoAmountReport(status?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<PurchaseOrder>>;
    public totalPoAmountReport(status?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PurchaseOrder>>>;
    public totalPoAmountReport(status?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PurchaseOrder>>>;
    public totalPoAmountReport(status?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', <any>status);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<PurchaseOrder>>(`${this.basePath}/organisation/reports/total_po_amount_report`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get so total
     * Get total amount group by currency
     * @param status 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public totalSoAmountReport(status?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<SalesOrder>>;
    public totalSoAmountReport(status?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SalesOrder>>>;
    public totalSoAmountReport(status?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SalesOrder>>>;
    public totalSoAmountReport(status?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', <any>status);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<SalesOrder>>(`${this.basePath}/organisation/reports/total_so_amount_report`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get VB report
     * Get the report of VB in an organisation
     * @param dateOfInvTo 
     * @param dateOfInvFrom 
     * @param dueDateTo 
     * @param dueDateFrom 
     * @param invoiceStatus 
     * @param currency 
     * @param vendorId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public vendorBillReport(dateOfInvTo?: string, dateOfInvFrom?: string, dueDateTo?: string, dueDateFrom?: string, invoiceStatus?: string, currency?: string, vendorId?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<Invoice>>;
    public vendorBillReport(dateOfInvTo?: string, dateOfInvFrom?: string, dueDateTo?: string, dueDateFrom?: string, invoiceStatus?: string, currency?: string, vendorId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Invoice>>>;
    public vendorBillReport(dateOfInvTo?: string, dateOfInvFrom?: string, dueDateTo?: string, dueDateFrom?: string, invoiceStatus?: string, currency?: string, vendorId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Invoice>>>;
    public vendorBillReport(dateOfInvTo?: string, dateOfInvFrom?: string, dueDateTo?: string, dueDateFrom?: string, invoiceStatus?: string, currency?: string, vendorId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {








        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (dateOfInvTo !== undefined && dateOfInvTo !== null) {
            queryParameters = queryParameters.set('dateOfInvTo', <any>dateOfInvTo);
        }
        if (dateOfInvFrom !== undefined && dateOfInvFrom !== null) {
            queryParameters = queryParameters.set('dateOfInvFrom', <any>dateOfInvFrom);
        }
        if (dueDateTo !== undefined && dueDateTo !== null) {
            queryParameters = queryParameters.set('dueDateTo', <any>dueDateTo);
        }
        if (dueDateFrom !== undefined && dueDateFrom !== null) {
            queryParameters = queryParameters.set('dueDateFrom', <any>dueDateFrom);
        }
        if (invoiceStatus !== undefined && invoiceStatus !== null) {
            queryParameters = queryParameters.set('invoiceStatus', <any>invoiceStatus);
        }
        if (currency !== undefined && currency !== null) {
            queryParameters = queryParameters.set('currency', <any>currency);
        }
        if (vendorId !== undefined && vendorId !== null) {
            queryParameters = queryParameters.set('vendorId', <any>vendorId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<Invoice>>(`${this.basePath}/organisation/reports/vendor_bill_report`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
