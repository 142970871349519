/**
 * Nuphi trade
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2.0.0
 * Contact: shraddha@blackcurrantapps.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { Enabled } from '../model/enabled';
import { LoginRequest } from '../model/loginRequest';
import { SignupObjServiceProvider } from '../model/signupObjServiceProvider';
import { User } from '../model/user';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable({
  providedIn: 'root'
})
export class OpenService {

    protected basePath = 'http://localhost:3000';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * exchange magic link received in email with token if valid
     * @param accessSecret 
     * @param shipmentUid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exchangeAccessSecret(accessSecret: string, shipmentUid: string, observe?: 'body', reportProgress?: boolean): Observable<User>;
    public exchangeAccessSecret(accessSecret: string, shipmentUid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<User>>;
    public exchangeAccessSecret(accessSecret: string, shipmentUid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<User>>;
    public exchangeAccessSecret(accessSecret: string, shipmentUid: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (accessSecret === null || accessSecret === undefined) {
            throw new Error('Required parameter accessSecret was null or undefined when calling exchangeAccessSecret.');
        }

        if (shipmentUid === null || shipmentUid === undefined) {
            throw new Error('Required parameter shipmentUid was null or undefined when calling exchangeAccessSecret.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (accessSecret !== undefined && accessSecret !== null) {
            queryParameters = queryParameters.set('accessSecret', <any>accessSecret);
        }
        if (shipmentUid !== undefined && shipmentUid !== null) {
            queryParameters = queryParameters.set('shipmentUid', <any>shipmentUid);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<User>(`${this.basePath}/open/exchange_access_secret`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * change user&#39;s password link
     * Send forgot password link to the user.
     * @param email 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public forgotPassword(email: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public forgotPassword(email: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public forgotPassword(email: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public forgotPassword(email: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (email === null || email === undefined) {
            throw new Error('Required parameter email was null or undefined when calling forgotPassword.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (email !== undefined && email !== null) {
            queryParameters = queryParameters.set('email', <any>email);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<any>(`${this.basePath}/open/forgot_password`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * change user&#39;s password
     * Check if two factor auth is enabled and send a fresh otp to the user
     * @param email 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public isTwofactorEnabled(email: string, observe?: 'body', reportProgress?: boolean): Observable<Enabled>;
    public isTwofactorEnabled(email: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Enabled>>;
    public isTwofactorEnabled(email: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Enabled>>;
    public isTwofactorEnabled(email: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (email === null || email === undefined) {
            throw new Error('Required parameter email was null or undefined when calling isTwofactorEnabled.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (email !== undefined && email !== null) {
            queryParameters = queryParameters.set('email', <any>email);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<Enabled>(`${this.basePath}/open/is_twofactor_enabled`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * change user&#39;s password link
     * Fetch the user email address using uuid
     * @param LoginRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public login(LoginRequest: LoginRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public login(LoginRequest: LoginRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public login(LoginRequest: LoginRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public login(LoginRequest: LoginRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (LoginRequest === null || LoginRequest === undefined) {
            throw new Error('Required parameter LoginRequest was null or undefined when calling login.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/open/login`,
            LoginRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * change user&#39;s password
     * Reset password after clicking on link
     * @param LoginRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public resetPassword(LoginRequest: LoginRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public resetPassword(LoginRequest: LoginRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public resetPassword(LoginRequest: LoginRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public resetPassword(LoginRequest: LoginRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (LoginRequest === null || LoginRequest === undefined) {
            throw new Error('Required parameter LoginRequest was null or undefined when calling resetPassword.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/open/reset_password`,
            LoginRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * send a one time use secret access link to users email inbox
     * @param email 
     * @param shipmentUid optional shipmentUid to redirect to after auth
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sendAccessSecret(email: string, shipmentUid?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public sendAccessSecret(email: string, shipmentUid?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public sendAccessSecret(email: string, shipmentUid?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public sendAccessSecret(email: string, shipmentUid?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (email === null || email === undefined) {
            throw new Error('Required parameter email was null or undefined when calling sendAccessSecret.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (email !== undefined && email !== null) {
            queryParameters = queryParameters.set('email', <any>email);
        }
        if (shipmentUid !== undefined && shipmentUid !== null) {
            queryParameters = queryParameters.set('shipmentUid', <any>shipmentUid);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<any>(`${this.basePath}/open/send_access_secret`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * sends verification link to verify email id before login
     * @param email 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sendVerificationLink(email: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public sendVerificationLink(email: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public sendVerificationLink(email: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public sendVerificationLink(email: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (email === null || email === undefined) {
            throw new Error('Required parameter email was null or undefined when calling sendVerificationLink.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (email !== undefined && email !== null) {
            queryParameters = queryParameters.set('email', <any>email);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<any>(`${this.basePath}/open/send_verification_link`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Signup a organisation
     * Add new organisation to nuphi platform.
     * @param User 
     * @param companyName 
     * @param country 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public signupNewOrganisation(User: User, companyName: string, country: string, observe?: 'body', reportProgress?: boolean): Observable<User>;
    public signupNewOrganisation(User: User, companyName: string, country: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<User>>;
    public signupNewOrganisation(User: User, companyName: string, country: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<User>>;
    public signupNewOrganisation(User: User, companyName: string, country: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (User === null || User === undefined) {
            throw new Error('Required parameter User was null or undefined when calling signupNewOrganisation.');
        }

        if (companyName === null || companyName === undefined) {
            throw new Error('Required parameter companyName was null or undefined when calling signupNewOrganisation.');
        }

        if (country === null || country === undefined) {
            throw new Error('Required parameter country was null or undefined when calling signupNewOrganisation.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (companyName !== undefined && companyName !== null) {
            queryParameters = queryParameters.set('companyName', <any>companyName);
        }
        if (country !== undefined && country !== null) {
            queryParameters = queryParameters.set('country', <any>country);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<User>(`${this.basePath}/open/signup_new_organisation`,
            User,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * signup new service provider
     * create new service provider profile
     * @param companyName 
     * @param SignupObjServiceProvider 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public signupServiceProvider(companyName: string, SignupObjServiceProvider: SignupObjServiceProvider, observe?: 'body', reportProgress?: boolean): Observable<User>;
    public signupServiceProvider(companyName: string, SignupObjServiceProvider: SignupObjServiceProvider, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<User>>;
    public signupServiceProvider(companyName: string, SignupObjServiceProvider: SignupObjServiceProvider, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<User>>;
    public signupServiceProvider(companyName: string, SignupObjServiceProvider: SignupObjServiceProvider, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (companyName === null || companyName === undefined) {
            throw new Error('Required parameter companyName was null or undefined when calling signupServiceProvider.');
        }

        if (SignupObjServiceProvider === null || SignupObjServiceProvider === undefined) {
            throw new Error('Required parameter SignupObjServiceProvider was null or undefined when calling signupServiceProvider.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (companyName !== undefined && companyName !== null) {
            queryParameters = queryParameters.set('companyName', <any>companyName);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<User>(`${this.basePath}/open/signup_serviceProvider`,
            SignupObjServiceProvider,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Verify email id of new oranization user and set password for him
     * @param token 
     * @param newPassword 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public verifySetpassword(token: string, newPassword: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public verifySetpassword(token: string, newPassword: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public verifySetpassword(token: string, newPassword: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public verifySetpassword(token: string, newPassword: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (token === null || token === undefined) {
            throw new Error('Required parameter token was null or undefined when calling verifySetpassword.');
        }

        if (newPassword === null || newPassword === undefined) {
            throw new Error('Required parameter newPassword was null or undefined when calling verifySetpassword.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (token !== undefined && token !== null) {
            queryParameters = queryParameters.set('token', <any>token);
        }
        if (newPassword !== undefined && newPassword !== null) {
            queryParameters = queryParameters.set('newPassword', <any>newPassword);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<any>(`${this.basePath}/open/verify_setpassword`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Verifies email of a user using a secret sent in email
     * @param token 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public verifyUser(token: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public verifyUser(token: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public verifyUser(token: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public verifyUser(token: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (token === null || token === undefined) {
            throw new Error('Required parameter token was null or undefined when calling verifyUser.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (token !== undefined && token !== null) {
            queryParameters = queryParameters.set('token', <any>token);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<any>(`${this.basePath}/open/verify_email`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
