import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { take } from 'rxjs/operators';
import { ServiceProvider, ServiceProviderService, User } from 'src/swagger';
import { GlobalProfileService } from '../../../global-profile.service';
import { SwalHelper } from 'src/app/swal';
import { CommonSupportService } from 'src/app/common-support.service';

@Component({
  selector: 'app-service-provider-profile-update',
  templateUrl: './service-provider-profile-update.component.html',
  styleUrls: ['./service-provider-profile-update.component.scss']
})
export class ServiceProviderProfileUpdateComponent implements OnInit {

  public servProv: ServiceProvider = {};
  public logo: File;
  public imageSrc: string | ArrayBuffer = '';
  public shouldShowLoader: boolean;
  public categoryList: Array<string>;
  public cityList: Array<string>;

  @Output() servProvProfileSync = new EventEmitter<User>();

  constructor(
    private servProvApi: ServiceProviderService,
    public profileSync: GlobalProfileService,
    public commonService: CommonSupportService
  ) { }

  ngOnInit(): void {
    this.categoryList = [
      'CHA',
      'Freight Forwarder',
      'DGFT Consultant',
      'Export Consultant',
      'International Agent to find Buyers',
      'FX Advisor',
      'Loan Syndication',
      'Chartered Accountant',
      'Quality Ceritification',
      'Laboratory',
      'General Insurance',
      'Credit Insurance',
      'Insurance Claim Advisor'
    ];

    this.cityList = ['Mumbai', 'New Delhi', 'Pune', 'Kolkata', 'Bangalore', 'Hyderabad'];
    this.shouldShowLoader = true;
    this.profileSync.user$
      .pipe(take(1))
      .subscribe(profile => {
        this.shouldShowLoader = false;
        this.servProv = profile.ServiceProvider;
        this.imageSrc = this.servProv.logoUrl;
      }, err => {
        this.shouldShowLoader = false;
        SwalHelper.showErrorSwal(err);
      });
  }

  updateServProvProfile(): void {
    this.shouldShowLoader = true;
    this.servProvApi.updateServiceProviderProfile(this.servProv)
      .subscribe(profile => {
        if (this.logo) {
          this.uploadLogo();
        }
        this.shouldShowLoader = false;
        this.profileSync.user.ServiceProvider = profile;
        this.servProvProfileSync.next(this.profileSync.user);
        (document.getElementById('logo') as HTMLInputElement).value = '';
        SwalHelper.successTimerSwal('Details Updated');
      }, err => {
        this.shouldShowLoader = false;
        SwalHelper.showErrorSwal(err.message);
      });
  }

  /* Fn to Update the logo of an organisation*/
  uploadLogo(): void {
    this.servProvApi.uploadLogoServiceProvider(this.logo)
      .subscribe(url => {
        this.shouldShowLoader = false;
        this.logo = undefined;
        this.profileSync.user.ServiceProvider.logoUrl = url.url;
        this.servProvProfileSync.next(this.profileSync.user);
      }, err => {
        this.shouldShowLoader = false;
        SwalHelper.showErrorSwal('Logo not updated , Please try again');
      });
  }

  /* Fn to read the logo */
  setLogo(event): void {
    this.logo = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(this.logo);
    reader.onload = ev => {
      this.imageSrc = reader.result;
    };
  }

}
