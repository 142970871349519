/**
 * Nuphi trade
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2.0.0
 * Contact: shraddha@blackcurrantapps.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ExcelUploadErrors } from '../model/excelUploadErrors';
import { ExcelUploadResult } from '../model/excelUploadResult';
import { Invoice } from '../model/invoice';
import { InvoicePayment } from '../model/invoicePayment';
import { PaymentSchedule } from '../model/paymentSchedule';
import { PurchaseOrder } from '../model/purchaseOrder';
import { SalesOrder } from '../model/salesOrder';
import { Shipment } from '../model/shipment';
import { SignedUrl } from '../model/signedUrl';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable({
  providedIn: 'root'
})
export class OrgInvoiceService {

    protected basePath = 'http://localhost:3000';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Archive the Invoice Manually
     * 
     * @param invId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public archiveInvoice(invId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public archiveInvoice(invId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public archiveInvoice(invId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public archiveInvoice(invId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (invId === null || invId === undefined) {
            throw new Error('Required parameter invId was null or undefined when calling archiveInvoice.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (invId !== undefined && invId !== null) {
            queryParameters = queryParameters.set('invId', <any>invId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.put<any>(`${this.basePath}/organisation/invoices/archive_invoice`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Clone Invoice
     * Clone the existing invoice
     * @param invId 
     * @param invNo 
     * @param invoiceType 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public cloneInvoice(invId: number, invNo: string, invoiceType: string, observe?: 'body', reportProgress?: boolean): Observable<Invoice>;
    public cloneInvoice(invId: number, invNo: string, invoiceType: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Invoice>>;
    public cloneInvoice(invId: number, invNo: string, invoiceType: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Invoice>>;
    public cloneInvoice(invId: number, invNo: string, invoiceType: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (invId === null || invId === undefined) {
            throw new Error('Required parameter invId was null or undefined when calling cloneInvoice.');
        }

        if (invNo === null || invNo === undefined) {
            throw new Error('Required parameter invNo was null or undefined when calling cloneInvoice.');
        }

        if (invoiceType === null || invoiceType === undefined) {
            throw new Error('Required parameter invoiceType was null or undefined when calling cloneInvoice.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (invId !== undefined && invId !== null) {
            queryParameters = queryParameters.set('invId', <any>invId);
        }
        if (invNo !== undefined && invNo !== null) {
            queryParameters = queryParameters.set('invNo', <any>invNo);
        }
        if (invoiceType !== undefined && invoiceType !== null) {
            queryParameters = queryParameters.set('invoiceType', <any>invoiceType);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<Invoice>(`${this.basePath}/organisation/invoices/clone_invoice`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * add invoice specific details
     * Add the details of organisation invoice
     * @param Invoice 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createInvoice(Invoice: Invoice, observe?: 'body', reportProgress?: boolean): Observable<Invoice>;
    public createInvoice(Invoice: Invoice, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Invoice>>;
    public createInvoice(Invoice: Invoice, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Invoice>>;
    public createInvoice(Invoice: Invoice, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (Invoice === null || Invoice === undefined) {
            throw new Error('Required parameter Invoice was null or undefined when calling createInvoice.');
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Invoice>(`${this.basePath}/organisation/invoices/invoice`,
            Invoice,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * add invoice payment specific details
     * Add the details of organisation invoice payment
     * @param InvoicePayment 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createInvoicePayment(InvoicePayment: InvoicePayment, observe?: 'body', reportProgress?: boolean): Observable<InvoicePayment>;
    public createInvoicePayment(InvoicePayment: InvoicePayment, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InvoicePayment>>;
    public createInvoicePayment(InvoicePayment: InvoicePayment, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InvoicePayment>>;
    public createInvoicePayment(InvoicePayment: InvoicePayment, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (InvoicePayment === null || InvoicePayment === undefined) {
            throw new Error('Required parameter InvoicePayment was null or undefined when calling createInvoicePayment.');
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<InvoicePayment>(`${this.basePath}/organisation/invoices/invoice_payment`,
            InvoicePayment,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * add invoice payment schedule specific details
     * Add the details of organisation invoice payment schedule
     * @param PaymentSchedule 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createPaymentSchedule(PaymentSchedule: PaymentSchedule, observe?: 'body', reportProgress?: boolean): Observable<PaymentSchedule>;
    public createPaymentSchedule(PaymentSchedule: PaymentSchedule, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PaymentSchedule>>;
    public createPaymentSchedule(PaymentSchedule: PaymentSchedule, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PaymentSchedule>>;
    public createPaymentSchedule(PaymentSchedule: PaymentSchedule, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (PaymentSchedule === null || PaymentSchedule === undefined) {
            throw new Error('Required parameter PaymentSchedule was null or undefined when calling createPaymentSchedule.');
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<PaymentSchedule>(`${this.basePath}/organisation/invoices/payment_schedule`,
            PaymentSchedule,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete invoice specific details
     * Delete the details of organisation invoice.This will be the soft delete
     * @param invId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteInvoice(invId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteInvoice(invId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteInvoice(invId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteInvoice(invId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (invId === null || invId === undefined) {
            throw new Error('Required parameter invId was null or undefined when calling deleteInvoice.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (invId !== undefined && invId !== null) {
            queryParameters = queryParameters.set('invId', <any>invId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/organisation/invoices/invoice`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete invoice payment
     * Delete the details of organisation invoice payment
     * @param invoicePaymentId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteInvoicePayment(invoicePaymentId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteInvoicePayment(invoicePaymentId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteInvoicePayment(invoicePaymentId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteInvoicePayment(invoicePaymentId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (invoicePaymentId === null || invoicePaymentId === undefined) {
            throw new Error('Required parameter invoicePaymentId was null or undefined when calling deleteInvoicePayment.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (invoicePaymentId !== undefined && invoicePaymentId !== null) {
            queryParameters = queryParameters.set('invoicePaymentId', <any>invoicePaymentId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/organisation/invoices/invoice_payment`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete open payment schedule
     * Delete the details of organisation invoice payment schedule
     * @param paymentScheduleId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deletePaymentSchedule(paymentScheduleId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deletePaymentSchedule(paymentScheduleId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deletePaymentSchedule(paymentScheduleId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deletePaymentSchedule(paymentScheduleId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (paymentScheduleId === null || paymentScheduleId === undefined) {
            throw new Error('Required parameter paymentScheduleId was null or undefined when calling deletePaymentSchedule.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (paymentScheduleId !== undefined && paymentScheduleId !== null) {
            queryParameters = queryParameters.set('paymentScheduleId', <any>paymentScheduleId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/organisation/invoices/payment_schedule`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * add invoice specific details
     * get the details of organisation invoice
     * @param invId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getInvoice(invId: number, observe?: 'body', reportProgress?: boolean): Observable<Invoice>;
    public getInvoice(invId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Invoice>>;
    public getInvoice(invId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Invoice>>;
    public getInvoice(invId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (invId === null || invId === undefined) {
            throw new Error('Required parameter invId was null or undefined when calling getInvoice.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (invId !== undefined && invId !== null) {
            queryParameters = queryParameters.set('invId', <any>invId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Invoice>(`${this.basePath}/organisation/invoices/invoice`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get payment specific details
     * get the details of organisation invoice payment
     * @param invoicePaymentId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getInvoicePayment(invoicePaymentId: number, observe?: 'body', reportProgress?: boolean): Observable<InvoicePayment>;
    public getInvoicePayment(invoicePaymentId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InvoicePayment>>;
    public getInvoicePayment(invoicePaymentId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InvoicePayment>>;
    public getInvoicePayment(invoicePaymentId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (invoicePaymentId === null || invoicePaymentId === undefined) {
            throw new Error('Required parameter invoicePaymentId was null or undefined when calling getInvoicePayment.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (invoicePaymentId !== undefined && invoicePaymentId !== null) {
            queryParameters = queryParameters.set('invoicePaymentId', <any>invoicePaymentId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<InvoicePayment>(`${this.basePath}/organisation/invoices/invoice_payment`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get get_linked_inv_docs specific details
     * get the details of organisation invoice
     * @param invId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getLinkedInvDocs(invId: number, observe?: 'body', reportProgress?: boolean): Observable<Invoice>;
    public getLinkedInvDocs(invId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Invoice>>;
    public getLinkedInvDocs(invId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Invoice>>;
    public getLinkedInvDocs(invId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (invId === null || invId === undefined) {
            throw new Error('Required parameter invId was null or undefined when calling getLinkedInvDocs.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (invId !== undefined && invId !== null) {
            queryParameters = queryParameters.set('invId', <any>invId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Invoice>(`${this.basePath}/organisation/invoices/get_linked_inv_docs`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get payment schedule specific details
     * get the details of organisation invoice payment schedule
     * @param paymentScheduleId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPaymentSchedule(paymentScheduleId: number, observe?: 'body', reportProgress?: boolean): Observable<Invoice>;
    public getPaymentSchedule(paymentScheduleId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Invoice>>;
    public getPaymentSchedule(paymentScheduleId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Invoice>>;
    public getPaymentSchedule(paymentScheduleId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (paymentScheduleId === null || paymentScheduleId === undefined) {
            throw new Error('Required parameter paymentScheduleId was null or undefined when calling getPaymentSchedule.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (paymentScheduleId !== undefined && paymentScheduleId !== null) {
            queryParameters = queryParameters.set('paymentScheduleId', <any>paymentScheduleId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Invoice>(`${this.basePath}/organisation/invoices/payment_schedule`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Import Invoice for OCR
     * 
     * @param attachment1 
     * @param modeOfShipment 
     * @param currency 
     * @param invoiceType 
     * @param invNo 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public importInvoice(attachment1: Blob, modeOfShipment: string, currency: string, invoiceType: string, invNo: string, observe?: 'body', reportProgress?: boolean): Observable<Invoice>;
    public importInvoice(attachment1: Blob, modeOfShipment: string, currency: string, invoiceType: string, invNo: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Invoice>>;
    public importInvoice(attachment1: Blob, modeOfShipment: string, currency: string, invoiceType: string, invNo: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Invoice>>;
    public importInvoice(attachment1: Blob, modeOfShipment: string, currency: string, invoiceType: string, invNo: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (attachment1 === null || attachment1 === undefined) {
            throw new Error('Required parameter attachment1 was null or undefined when calling importInvoice.');
        }

        if (modeOfShipment === null || modeOfShipment === undefined) {
            throw new Error('Required parameter modeOfShipment was null or undefined when calling importInvoice.');
        }

        if (currency === null || currency === undefined) {
            throw new Error('Required parameter currency was null or undefined when calling importInvoice.');
        }

        if (invoiceType === null || invoiceType === undefined) {
            throw new Error('Required parameter invoiceType was null or undefined when calling importInvoice.');
        }

        if (invNo === null || invNo === undefined) {
            throw new Error('Required parameter invNo was null or undefined when calling importInvoice.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (modeOfShipment !== undefined && modeOfShipment !== null) {
            queryParameters = queryParameters.set('modeOfShipment', <any>modeOfShipment);
        }
        if (currency !== undefined && currency !== null) {
            queryParameters = queryParameters.set('currency', <any>currency);
        }
        if (invoiceType !== undefined && invoiceType !== null) {
            queryParameters = queryParameters.set('invoiceType', <any>invoiceType);
        }
        if (invNo !== undefined && invNo !== null) {
            queryParameters = queryParameters.set('invNo', <any>invNo);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (attachment1 !== undefined) {
            formParams.append('attachment1', <any>attachment1);
        }

        return this.httpClient.post<Invoice>(`${this.basePath}/organisation/invoices/import_invoice`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Generate invoice payments report
     * Generate Invoice payments excel report for admin
     * @param invId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public invoicePaymentExcel(invId: number, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public invoicePaymentExcel(invId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public invoicePaymentExcel(invId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public invoicePaymentExcel(invId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (invId === null || invId === undefined) {
            throw new Error('Required parameter invId was null or undefined when calling invoicePaymentExcel.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (invId !== undefined && invId !== null) {
            queryParameters = queryParameters.set('invId', <any>invId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get(`${this.basePath}/organisation/invoices/invoice_payment_excel`,
            {
                params: queryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get inv specific details with status history
     * get the details of organisation invoice with the status history
     * @param invId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public invoiceStatusHistory(invId: number, observe?: 'body', reportProgress?: boolean): Observable<Invoice>;
    public invoiceStatusHistory(invId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Invoice>>;
    public invoiceStatusHistory(invId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Invoice>>;
    public invoiceStatusHistory(invId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (invId === null || invId === undefined) {
            throw new Error('Required parameter invId was null or undefined when calling invoiceStatusHistory.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (invId !== undefined && invId !== null) {
            queryParameters = queryParameters.set('invId', <any>invId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Invoice>(`${this.basePath}/organisation/invoices/invoice_status_history`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all invoice
     * Get all the Invoice of an organisation
     * @param invoiceType 
     * @param dateOfInvTo 
     * @param dateOfInvFrom 
     * @param invoiceStatus 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listInvoice(invoiceType: string, dateOfInvTo?: string, dateOfInvFrom?: string, invoiceStatus?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Invoice>>;
    public listInvoice(invoiceType: string, dateOfInvTo?: string, dateOfInvFrom?: string, invoiceStatus?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Invoice>>>;
    public listInvoice(invoiceType: string, dateOfInvTo?: string, dateOfInvFrom?: string, invoiceStatus?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Invoice>>>;
    public listInvoice(invoiceType: string, dateOfInvTo?: string, dateOfInvFrom?: string, invoiceStatus?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (invoiceType === null || invoiceType === undefined) {
            throw new Error('Required parameter invoiceType was null or undefined when calling listInvoice.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (invoiceType !== undefined && invoiceType !== null) {
            queryParameters = queryParameters.set('invoiceType', <any>invoiceType);
        }
        if (dateOfInvTo !== undefined && dateOfInvTo !== null) {
            queryParameters = queryParameters.set('dateOfInvTo', <any>dateOfInvTo);
        }
        if (dateOfInvFrom !== undefined && dateOfInvFrom !== null) {
            queryParameters = queryParameters.set('dateOfInvFrom', <any>dateOfInvFrom);
        }
        if (invoiceStatus !== undefined && invoiceStatus !== null) {
            queryParameters = queryParameters.set('invoiceStatus', <any>invoiceStatus);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<Invoice>>(`${this.basePath}/organisation/invoices/list_invoice`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all invoice payments
     * Get all the payment capture of Invoice in an organisation
     * @param invId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listInvoicePayment(invId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<InvoicePayment>>;
    public listInvoicePayment(invId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<InvoicePayment>>>;
    public listInvoicePayment(invId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<InvoicePayment>>>;
    public listInvoicePayment(invId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (invId === null || invId === undefined) {
            throw new Error('Required parameter invId was null or undefined when calling listInvoicePayment.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (invId !== undefined && invId !== null) {
            queryParameters = queryParameters.set('invId', <any>invId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<InvoicePayment>>(`${this.basePath}/organisation/invoices/list_invoice_payment`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all invoice
     * Get all the payment schedule of Invoice in an organisation
     * @param invId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listPaymentSchedule(invId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<PaymentSchedule>>;
    public listPaymentSchedule(invId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PaymentSchedule>>>;
    public listPaymentSchedule(invId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PaymentSchedule>>>;
    public listPaymentSchedule(invId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (invId === null || invId === undefined) {
            throw new Error('Required parameter invId was null or undefined when calling listPaymentSchedule.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (invId !== undefined && invId !== null) {
            queryParameters = queryParameters.set('invId', <any>invId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<PaymentSchedule>>(`${this.basePath}/organisation/invoices/list_payment_schedule`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get a signed url to download payment attachment file
     * 
     * @param invoicePaymentId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public paymentAttSignedUrl(invoicePaymentId: number, observe?: 'body', reportProgress?: boolean): Observable<SignedUrl>;
    public paymentAttSignedUrl(invoicePaymentId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SignedUrl>>;
    public paymentAttSignedUrl(invoicePaymentId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SignedUrl>>;
    public paymentAttSignedUrl(invoicePaymentId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (invoicePaymentId === null || invoicePaymentId === undefined) {
            throw new Error('Required parameter invoicePaymentId was null or undefined when calling paymentAttSignedUrl.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (invoicePaymentId !== undefined && invoicePaymentId !== null) {
            queryParameters = queryParameters.set('invoicePaymentId', <any>invoicePaymentId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<SignedUrl>(`${this.basePath}/organisation/invoices/payment_att_signed_url`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Save &amp; recon the invoice order
     * Save the invoice and perform REcon
     * @param invId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public saveInvoice(invId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public saveInvoice(invId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public saveInvoice(invId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public saveInvoice(invId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (invId === null || invId === undefined) {
            throw new Error('Required parameter invId was null or undefined when calling saveInvoice.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (invId !== undefined && invId !== null) {
            queryParameters = queryParameters.set('invId', <any>invId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.put<any>(`${this.basePath}/organisation/invoices/save_invoice`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Search vendors
     * 
     * @param type 
     * @param docType 
     * @param query 
     * @param exclude_ids comman seperated invoice nos
     * @param limit 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchInvoiceShipments(type: string, docType: string, query?: string, exclude_ids?: string, limit?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<Shipment>>;
    public searchInvoiceShipments(type: string, docType: string, query?: string, exclude_ids?: string, limit?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Shipment>>>;
    public searchInvoiceShipments(type: string, docType: string, query?: string, exclude_ids?: string, limit?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Shipment>>>;
    public searchInvoiceShipments(type: string, docType: string, query?: string, exclude_ids?: string, limit?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling searchInvoiceShipments.');
        }

        if (docType === null || docType === undefined) {
            throw new Error('Required parameter docType was null or undefined when calling searchInvoiceShipments.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (query !== undefined && query !== null) {
            queryParameters = queryParameters.set('query', <any>query);
        }
        if (type !== undefined && type !== null) {
            queryParameters = queryParameters.set('type', <any>type);
        }
        if (docType !== undefined && docType !== null) {
            queryParameters = queryParameters.set('docType', <any>docType);
        }
        if (exclude_ids !== undefined && exclude_ids !== null) {
            queryParameters = queryParameters.set('exclude_ids', <any>exclude_ids);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<Shipment>>(`${this.basePath}/organisation/invoices/search_shipments`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Search POs for invoice along with it&#39;s subitems
     * 
     * @param query 
     * @param currency 
     * @param exclude_ids comman seperated invoice nos
     * @param limit 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchPosForInvoice(query?: string, currency?: string, exclude_ids?: string, limit?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<PurchaseOrder>>;
    public searchPosForInvoice(query?: string, currency?: string, exclude_ids?: string, limit?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PurchaseOrder>>>;
    public searchPosForInvoice(query?: string, currency?: string, exclude_ids?: string, limit?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PurchaseOrder>>>;
    public searchPosForInvoice(query?: string, currency?: string, exclude_ids?: string, limit?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (query !== undefined && query !== null) {
            queryParameters = queryParameters.set('query', <any>query);
        }
        if (currency !== undefined && currency !== null) {
            queryParameters = queryParameters.set('currency', <any>currency);
        }
        if (exclude_ids !== undefined && exclude_ids !== null) {
            queryParameters = queryParameters.set('exclude_ids', <any>exclude_ids);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<PurchaseOrder>>(`${this.basePath}/organisation/invoices/search_pos_for_invoice`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Search SOs for invoice along with it&#39;s subitems
     * 
     * @param query 
     * @param currency 
     * @param exclude_ids comman seperated invoice nos
     * @param limit 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchSosForInvoice(query?: string, currency?: string, exclude_ids?: string, limit?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<SalesOrder>>;
    public searchSosForInvoice(query?: string, currency?: string, exclude_ids?: string, limit?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SalesOrder>>>;
    public searchSosForInvoice(query?: string, currency?: string, exclude_ids?: string, limit?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SalesOrder>>>;
    public searchSosForInvoice(query?: string, currency?: string, exclude_ids?: string, limit?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (query !== undefined && query !== null) {
            queryParameters = queryParameters.set('query', <any>query);
        }
        if (currency !== undefined && currency !== null) {
            queryParameters = queryParameters.set('currency', <any>currency);
        }
        if (exclude_ids !== undefined && exclude_ids !== null) {
            queryParameters = queryParameters.set('exclude_ids', <any>exclude_ids);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<SalesOrder>>(`${this.basePath}/organisation/invoices/search_sos_for_invoice`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get so total
     * Get total amount group by currency
     * @param invoiceType 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public totalInvAmount(invoiceType: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Invoice>>;
    public totalInvAmount(invoiceType: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Invoice>>>;
    public totalInvAmount(invoiceType: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Invoice>>>;
    public totalInvAmount(invoiceType: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (invoiceType === null || invoiceType === undefined) {
            throw new Error('Required parameter invoiceType was null or undefined when calling totalInvAmount.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (invoiceType !== undefined && invoiceType !== null) {
            queryParameters = queryParameters.set('invoiceType', <any>invoiceType);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<Invoice>>(`${this.basePath}/organisation/invoices/total_inv_amount`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update the staus of the Invoice Manually
     * 
     * @param invId 
     * @param status 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateInvStatus(invId: number, status: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateInvStatus(invId: number, status: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateInvStatus(invId: number, status: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateInvStatus(invId: number, status: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (invId === null || invId === undefined) {
            throw new Error('Required parameter invId was null or undefined when calling updateInvStatus.');
        }

        if (status === null || status === undefined) {
            throw new Error('Required parameter status was null or undefined when calling updateInvStatus.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (invId !== undefined && invId !== null) {
            queryParameters = queryParameters.set('invId', <any>invId);
        }
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', <any>status);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.put<any>(`${this.basePath}/organisation/invoices/update_inv_status`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get so total
     * Change the inv template
     * @param invId 
     * @param templateName 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateInvTemplate(invId: number, templateName: string, observe?: 'body', reportProgress?: boolean): Observable<Invoice>;
    public updateInvTemplate(invId: number, templateName: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Invoice>>;
    public updateInvTemplate(invId: number, templateName: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Invoice>>;
    public updateInvTemplate(invId: number, templateName: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (invId === null || invId === undefined) {
            throw new Error('Required parameter invId was null or undefined when calling updateInvTemplate.');
        }

        if (templateName === null || templateName === undefined) {
            throw new Error('Required parameter templateName was null or undefined when calling updateInvTemplate.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (invId !== undefined && invId !== null) {
            queryParameters = queryParameters.set('invId', <any>invId);
        }
        if (templateName !== undefined && templateName !== null) {
            queryParameters = queryParameters.set('templateName', <any>templateName);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<Invoice>(`${this.basePath}/organisation/invoices/update_inv_template`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update invoice specific details
     * Update the details of organisation invoice
     * @param Invoice 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateInvoice(Invoice: Invoice, observe?: 'body', reportProgress?: boolean): Observable<Invoice>;
    public updateInvoice(Invoice: Invoice, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Invoice>>;
    public updateInvoice(Invoice: Invoice, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Invoice>>;
    public updateInvoice(Invoice: Invoice, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (Invoice === null || Invoice === undefined) {
            throw new Error('Required parameter Invoice was null or undefined when calling updateInvoice.');
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Invoice>(`${this.basePath}/organisation/invoices/invoice`,
            Invoice,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update invoice payment schdule specific details
     * Update the details of organisation invoice payment schedule
     * @param PaymentSchedule 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updatePaymentSchedule(PaymentSchedule: PaymentSchedule, observe?: 'body', reportProgress?: boolean): Observable<PaymentSchedule>;
    public updatePaymentSchedule(PaymentSchedule: PaymentSchedule, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PaymentSchedule>>;
    public updatePaymentSchedule(PaymentSchedule: PaymentSchedule, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PaymentSchedule>>;
    public updatePaymentSchedule(PaymentSchedule: PaymentSchedule, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (PaymentSchedule === null || PaymentSchedule === undefined) {
            throw new Error('Required parameter PaymentSchedule was null or undefined when calling updatePaymentSchedule.');
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<PaymentSchedule>(`${this.basePath}/organisation/invoices/payment_schedule`,
            PaymentSchedule,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Uplod the excel file of invoices
     * 
     * @param file 
     * @param invoiceType 
     * @param shouldCreateShipment 
     * @param includeFirstRow 
     * @param dryRun Dry run the database transactions to see the results
     * @param columnMappings 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public uploadInvoiceExcel(file: Blob, invoiceType: string, shouldCreateShipment: boolean, includeFirstRow: boolean, dryRun: boolean, columnMappings: string, observe?: 'body', reportProgress?: boolean): Observable<ExcelUploadResult>;
    public uploadInvoiceExcel(file: Blob, invoiceType: string, shouldCreateShipment: boolean, includeFirstRow: boolean, dryRun: boolean, columnMappings: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ExcelUploadResult>>;
    public uploadInvoiceExcel(file: Blob, invoiceType: string, shouldCreateShipment: boolean, includeFirstRow: boolean, dryRun: boolean, columnMappings: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ExcelUploadResult>>;
    public uploadInvoiceExcel(file: Blob, invoiceType: string, shouldCreateShipment: boolean, includeFirstRow: boolean, dryRun: boolean, columnMappings: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (file === null || file === undefined) {
            throw new Error('Required parameter file was null or undefined when calling uploadInvoiceExcel.');
        }

        if (invoiceType === null || invoiceType === undefined) {
            throw new Error('Required parameter invoiceType was null or undefined when calling uploadInvoiceExcel.');
        }

        if (shouldCreateShipment === null || shouldCreateShipment === undefined) {
            throw new Error('Required parameter shouldCreateShipment was null or undefined when calling uploadInvoiceExcel.');
        }

        if (includeFirstRow === null || includeFirstRow === undefined) {
            throw new Error('Required parameter includeFirstRow was null or undefined when calling uploadInvoiceExcel.');
        }

        if (dryRun === null || dryRun === undefined) {
            throw new Error('Required parameter dryRun was null or undefined when calling uploadInvoiceExcel.');
        }

        if (columnMappings === null || columnMappings === undefined) {
            throw new Error('Required parameter columnMappings was null or undefined when calling uploadInvoiceExcel.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (invoiceType !== undefined && invoiceType !== null) {
            queryParameters = queryParameters.set('invoiceType', <any>invoiceType);
        }
        if (shouldCreateShipment !== undefined && shouldCreateShipment !== null) {
            queryParameters = queryParameters.set('shouldCreateShipment', <any>shouldCreateShipment);
        }
        if (includeFirstRow !== undefined && includeFirstRow !== null) {
            queryParameters = queryParameters.set('includeFirstRow', <any>includeFirstRow);
        }
        if (dryRun !== undefined && dryRun !== null) {
            queryParameters = queryParameters.set('dryRun', <any>dryRun);
        }
        if (columnMappings !== undefined && columnMappings !== null) {
            queryParameters = queryParameters.set('columnMappings', <any>columnMappings);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams.append('file', <any>file);
        }

        return this.httpClient.put<ExcelUploadResult>(`${this.basePath}/organisation/invoices/upload_invoice_excel`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * upload payment proof for invoice
     * logo attachment for payment proof
     * @param invoicePaymentId 
     * @param Logo 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public uploadPaymentFile(invoicePaymentId: number, Logo: Blob, observe?: 'body', reportProgress?: boolean): Observable<SignedUrl>;
    public uploadPaymentFile(invoicePaymentId: number, Logo: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SignedUrl>>;
    public uploadPaymentFile(invoicePaymentId: number, Logo: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SignedUrl>>;
    public uploadPaymentFile(invoicePaymentId: number, Logo: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (invoicePaymentId === null || invoicePaymentId === undefined) {
            throw new Error('Required parameter invoicePaymentId was null or undefined when calling uploadPaymentFile.');
        }

        if (Logo === null || Logo === undefined) {
            throw new Error('Required parameter Logo was null or undefined when calling uploadPaymentFile.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (invoicePaymentId !== undefined && invoicePaymentId !== null) {
            queryParameters = queryParameters.set('invoicePaymentId', <any>invoicePaymentId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (Logo !== undefined) {
            formParams.append('Logo', <any>Logo);
        }

        return this.httpClient.post<SignedUrl>(`${this.basePath}/organisation/invoices/upload_payment_file`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
