import { Location } from '@angular/common';
import { Injectable, Renderer2 } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CommonSupportService {
  public renderer: Renderer2;
  public countries = [
    { id: 1, country: 'Afghanistan', avatar: 'flag-icon flag-icon-af', name: '+93' },
    { id: 2, country: 'Aland Islands', avatar: 'flag-icon flag-icon-ax', name: '+358' },
    { id: 3, country: 'Albania', avatar: 'flag-icon flag-icon-al', name: '+355' },
    { id: 4, country: 'Algeria', avatar: 'flag-icon flag-icon-dz', name: '+213' },
    { id: 5, country: 'American Samoa', avatar: 'flag-icon flag-icon-as', name: '+1-684' },
    { id: 6, country: 'Andorra', avatar: 'flag-icon flag-icon-ad', name: '+376' },
    { id: 7, country: 'Angola', avatar: 'flag-icon flag-icon-ao', name: '+244' },
    { id: 8, country: 'Anguilla', avatar: 'flag-icon flag-icon-ai', name: '+1-264' },
    { id: 9, country: 'Antarctica', avatar: 'flag-icon flag-icon-aq', name: '+672' },
    { id: 10, country: 'Antigua and Barbuda', avatar: 'flag-icon flag-icon-ag', name: '+1-268' },
    { id: 11, country: 'Argentina', avatar: 'flag-icon flag-icon-ar', name: '+54' },
    { id: 12, country: 'Armenia', avatar: 'flag-icon flag-icon-am', name: '+374' },
    { id: 13, country: 'Aruba', avatar: 'flag-icon flag-icon-aw', name: '+297' },
    { id: 14, country: 'Australia', avatar: 'flag-icon flag-icon-au', name: '+61' },
    { id: 15, country: 'Austria', avatar: 'flag-icon flag-icon-at', name: '+43' },
    { id: 16, country: 'Azerbaijan', avatar: 'flag-icon flag-icon-az', name: '+994' },
    { id: 17, country: 'Bahamas', avatar: 'flag-icon flag-icon-bs', name: '+1-242' },
    { id: 18, country: 'Bahrain', avatar: 'flag-icon flag-icon-bh', name: '+973' },
    { id: 19, country: 'Bangladesh', avatar: 'flag-icon flag-icon-bd', name: '+880' },
    { id: 20, country: 'Barbados', avatar: 'flag-icon flag-icon-bb', name: '+1-246' },
    { id: 21, country: 'Belarus', avatar: 'flag-icon flag-icon-by', name: '+375' },
    { id: 22, country: 'Belgium', avatar: 'flag-icon flag-icon-be', name: '+32' },
    { id: 23, country: 'Belize', avatar: 'flag-icon flag-icon-bz', name: '+501' },
    { id: 24, country: 'Benin', avatar: 'flag-icon flag-icon-bj', name: '+229' },
    { id: 25, country: 'Bermuda', avatar: 'flag-icon flag-icon-bm', name: '+1-441' },
    { id: 26, country: 'Bhutan', avatar: 'flag-icon flag-icon-bt', name: '+975' },
    { id: 27, country: 'Bolivia', avatar: 'flag-icon flag-icon-bo', name: '+591' },
    { id: 28, country: 'Bosnia and Herzegovina', avatar: 'flag-icon flag-icon-ba', name: '+387' },
    { id: 29, country: 'Botswana', avatar: 'flag-icon flag-icon-bw', name: '+267' },
    { id: 30, country: 'Bouvet Island', avatar: 'flag-icon flag-icon-bv', name: '+47' },
    { id: 31, country: 'Brazil', avatar: 'flag-icon flag-icon-br', name: '+55' },
    { id: 32, country: 'British Virgin Islands', avatar: 'flag-icon flag-icon-vg', name: '+1-284' },
    { id: 33, country: 'British Indian Ocean Territory', avatar: 'flag-icon flag-icon-io', name: '+246' },
    { id: 34, country: 'Brunei Darussalam', avatar: 'flag-icon flag-icon-bn', name: '+673' },
    { id: 35, country: 'Bulgaria', avatar: 'flag-icon flag-icon-bg', name: '+359' },
    { id: 36, country: 'Burkina Faso', avatar: 'flag-icon flag-icon-bf', name: '+226' },
    { id: 37, country: 'Burundi', avatar: 'flag-icon flag-icon-bi', name: '+257' },
    { id: 38, country: 'Cambodia', avatar: 'flag-icon flag-icon-kh', name: '+855' },
    { id: 39, country: 'Cameroon', avatar: 'flag-icon flag-icon-cm', name: '+237' },
    { id: 40, country: 'Canada', avatar: 'flag-icon flag-icon-ca', name: '+1' },
    { id: 41, country: 'Cape Verde', avatar: 'flag-icon flag-icon-cv', name: '+238' },
    { id: 42, country: 'Cayman Islands', avatar: 'flag-icon flag-icon-ky', name: '+1-345' },
    { id: 43, country: 'Central African Republic', avatar: 'flag-icon flag-icon-cf', name: '+236' },
    { id: 44, country: 'Chad', avatar: 'flag-icon flag-icon-td', name: '+235' },
    { id: 45, country: 'Chile', avatar: 'flag-icon flag-icon-cl', name: '+56' },
    { id: 46, country: 'China', avatar: 'flag-icon flag-icon-cn', name: '+86' },
    { id: 47, country: 'Hong Kong, SAR China', avatar: 'flag-icon flag-icon-hk', name: '+852' },
    { id: 48, country: 'Macao, SAR China', avatar: 'flag-icon flag-icon-mo', name: '+853' },
    { id: 49, country: 'Christmas Island', avatar: 'flag-icon flag-icon-cx', name: '+61' },
    { id: 50, country: 'Cocos (Keeling) Islands', avatar: 'flag-icon flag-icon-cc', name: '+61' },
    { id: 51, country: 'Colombia', avatar: 'flag-icon flag-icon-co', name: '+57' },
    { id: 52, country: 'Comoros', avatar: 'flag-icon flag-icon-km', name: '+269' },
    { id: 53, country: 'Congo (Brazzaville)', avatar: 'flag-icon flag-icon-cg', name: '+242' },
    { id: 54, country: 'Congo, (Kinshasa)', avatar: 'flag-icon flag-icon-cd', name: '+243' },
    { id: 55, country: 'Cook Islands', avatar: 'flag-icon flag-icon-ck', name: '+682' },
    { id: 56, country: 'Costa Rica', avatar: 'flag-icon flag-icon-cr', name: '+506' },
    { id: 57, country: 'Côte d Ivoire', avatar: 'flag-icon flag-icon-ci', name: '+225' },
    { id: 58, country: 'Croatia', avatar: 'flag-icon flag-icon-hr', name: '+385' },
    { id: 59, country: 'Cuba', avatar: 'flag-icon flag-icon-cu', name: '+53' },
    { id: 60, country: 'Cyprus', avatar: 'flag-icon flag-icon-cy', name: '+357' },
    { id: 61, country: 'Czech Republic', avatar: 'flag-icon flag-icon-cz', name: '+420' },
    { id: 62, country: 'Denmark', avatar: 'flag-icon flag-icon-dk', name: '+45' },
    { id: 63, country: 'Djibouti', avatar: 'flag-icon flag-icon-dj', name: '+253' },
    { id: 64, country: 'Dominica', avatar: 'flag-icon flag-icon-dm', name: '+1-767' },
    { id: 65, country: 'Dominican Republic', avatar: 'flag-icon flag-icon-do', name: '+1-809,1-829,1-849' },
    { id: 66, country: 'Ecuador', avatar: 'flag-icon flag-icon-ec', name: '+593' },
    { id: 67, country: 'Egypt', avatar: 'flag-icon flag-icon-eg', name: '+20' },
    { id: 68, country: 'El Salvador', avatar: 'flag-icon flag-icon-sv', name: '+503' },
    { id: 69, country: 'Equatorial Guinea', avatar: 'flag-icon flag-icon-gq', name: '+240' },
    { id: 70, country: 'Eritrea', avatar: 'flag-icon flag-icon-er', name: '+291' },
    { id: 71, country: 'Estonia', avatar: 'flag-icon flag-icon-ee', name: '+372' },
    { id: 72, country: 'Ethiopia', avatar: 'flag-icon flag-icon-et', name: '+251' },
    { id: 73, country: 'Falkland Islands (Malvinas)', avatar: 'flag-icon flag-icon-fk', name: '+500' },
    { id: 74, country: 'Faroe Islands', avatar: 'flag-icon flag-icon-fo', name: '+298' },
    { id: 75, country: 'Fiji', avatar: 'flag-icon flag-icon-fj', name: '+679' },
    { id: 76, country: 'Finland', avatar: 'flag-icon flag-icon-fi', name: '+358' },
    { id: 77, country: 'France', avatar: 'flag-icon flag-icon-fr', name: '+33' },
    { id: 78, country: 'French Guiana', avatar: 'flag-icon flag-icon-gf', name: '+594' },
    { id: 79, country: 'French Polynesia', avatar: 'flag-icon flag-icon-pf', name: '+689' },
    { id: 80, country: 'French Southern Territories', avatar: 'flag-icon flag-icon-tf', name: '+262' },
    { id: 81, country: 'Gabon', avatar: 'flag-icon flag-icon-ga', name: '+241' },
    { id: 82, country: 'Gambia', avatar: 'flag-icon flag-icon-gm', name: '+220' },
    { id: 83, country: 'Georgia', avatar: 'flag-icon flag-icon-ge', name: '+995' },
    { id: 84, country: 'Germany', avatar: 'flag-icon flag-icon-de', name: '+49' },
    { id: 85, country: 'Ghana', avatar: 'flag-icon flag-icon-gh', name: '+233' },
    { id: 86, country: 'Gibraltar', avatar: 'flag-icon flag-icon-gi', name: '+350' },
    { id: 87, country: 'Greece', avatar: 'flag-icon flag-icon-gr', name: '+30' },
    { id: 88, country: 'Greenland', avatar: 'flag-icon flag-icon-gl', name: '+299' },
    { id: 89, country: 'Grenada', avatar: 'flag-icon flag-icon-gd', name: '+1-473' },
    { id: 90, country: 'Guadeloupe', avatar: 'flag-icon flag-icon-gp', name: '+590' },
    { id: 91, country: 'Guam', avatar: 'flag-icon flag-icon-gu', name: '+1-671' },
    { id: 92, country: 'Guatemala', avatar: 'flag-icon flag-icon-gt', name: '+502' },
    { id: 93, country: 'Guernsey', avatar: 'flag-icon flag-icon-gg', name: '+44' },
    { id: 94, country: 'Guinea', avatar: 'flag-icon flag-icon-gn', name: '+224' },
    { id: 95, country: 'Guinea-Bissau', avatar: 'flag-icon flag-icon-gw', name: '+245' },
    { id: 96, country: 'Guyana', avatar: 'flag-icon flag-icon-gy', name: '+592' },
    { id: 97, country: 'Haiti', avatar: 'flag-icon flag-icon-ht', name: '+509' },
    { id: 98, country: 'Heard and Mcdonald Islands', avatar: 'flag-icon flag-icon-hm', name: '+672' },
    { id: 99, country: 'Holy See (Vatican City State)', avatar: 'flag-icon flag-icon-va', name: '+379' },
    { id: 100, country: 'Honduras', avatar: 'flag-icon flag-icon-hn', name: '+504' },
    { id: 101, country: 'Hungary', avatar: 'flag-icon flag-icon-hu', name: '+36' },
    { id: 102, country: 'Iceland', avatar: 'flag-icon flag-icon-is', name: '+354' },
    { id: 103, country: 'India', avatar: 'flag-icon flag-icon-in', name: '+91' },
    { id: 104, country: 'Indonesia', avatar: 'flag-icon flag-icon-id', name: '+62' },
    { id: 105, country: 'Iran, Islamic Republic of', avatar: 'flag-icon flag-icon-ir', name: '+98' },
    { id: 106, country: 'Iraq', avatar: 'flag-icon flag-icon-iq', name: '+964' },
    { id: 107, country: 'Ireland', avatar: 'flag-icon flag-icon-ie', name: '+353' },
    { id: 108, country: 'Isle of Man', avatar: 'flag-icon flag-icon-im', name: '+44' },
    { id: 109, country: 'Israel', avatar: 'flag-icon flag-icon-il', name: '+972' },
    { id: 110, country: 'Italy', avatar: 'flag-icon flag-icon-it', name: '+39' },
    { id: 111, country: 'Jamaica', avatar: 'flag-icon flag-icon-jm', name: '+1-876' },
    { id: 112, country: 'Japan', avatar: 'flag-icon flag-icon-jp', name: '+81' },
    { id: 113, country: 'Jersey', avatar: 'flag-icon flag-icon-je', name: '+44' },
    { id: 114, country: 'Jordan', avatar: 'flag-icon flag-icon-jo', name: '+962' },
    { id: 115, country: 'Kazakhstan', avatar: 'flag-icon flag-icon-kz', name: '+7' },
    { id: 116, country: 'Kenya', avatar: 'flag-icon flag-icon-ke', name: '+254' },
    { id: 117, country: 'Kiribati', avatar: 'flag-icon flag-icon-ki', name: '+686' },
    { id: 118, country: 'Korea (North)', avatar: 'flag-icon flag-icon-kp', name: '+850' },
    { id: 119, country: 'Korea (South)', avatar: 'flag-icon flag-icon-kr', name: '+82' },
    { id: 120, country: 'Kuwait', avatar: 'flag-icon flag-icon-kw', name: '+965' },
    { id: 121, country: 'Kyrgyzstan', avatar: 'flag-icon flag-icon-kg', name: '+996' },
    { id: 122, country: 'Lao PDR', avatar: 'flag-icon flag-icon-la', name: '+856' },
    { id: 123, country: 'Latvia', avatar: 'flag-icon flag-icon-lv', name: '+371' },
    { id: 124, country: 'Lebanon', avatar: 'flag-icon flag-icon-lb', name: '+961' },
    { id: 125, country: 'Lesotho', avatar: 'flag-icon flag-icon-ls', name: '+266' },
    { id: 126, country: 'Liberia', avatar: 'flag-icon flag-icon-lr', name: '+231' },
    { id: 127, country: 'Libya', avatar: 'flag-icon flag-icon-ly', name: '+218' },
    { id: 128, country: 'Liechtenstein', avatar: 'flag-icon flag-icon-li', name: '+423' },
    { id: 129, country: 'Lithuania', avatar: 'flag-icon flag-icon-lt', name: '+370' },
    { id: 130, country: 'Luxembourg', avatar: 'flag-icon flag-icon-lu', name: '+352' },
    { id: 131, country: 'Macedonia, Republic of', avatar: 'flag-icon flag-icon-mk', name: '+389' },
    { id: 132, country: 'Madagascar', avatar: 'flag-icon flag-icon-mg', name: '+261' },
    { id: 133, country: 'Malawi', avatar: 'flag-icon flag-icon-mw', name: '+265' },
    { id: 134, country: 'Malaysia', avatar: 'flag-icon flag-icon-my', name: '+60' },
    { id: 135, country: 'Maldives', avatar: 'flag-icon flag-icon-mv', name: '+960' },
    { id: 136, country: 'Mali', avatar: 'flag-icon flag-icon-ml', name: '+223' },
    { id: 137, country: 'Malta', avatar: 'flag-icon flag-icon-mt', name: '+356' },
    { id: 138, country: 'Marshall Islands', avatar: 'flag-icon flag-icon-mh', name: '+692' },
    { id: 139, country: 'Martinique', avatar: 'flag-icon flag-icon-mq', name: '+596' },
    { id: 140, country: 'Mauritania', avatar: 'flag-icon flag-icon-mr', name: '+222' },
    { id: 141, country: 'Mauritius', avatar: 'flag-icon flag-icon-mu', name: '+230' },
    { id: 142, country: 'Mayotte', avatar: 'flag-icon flag-icon-yt', name: '+262' },
    { id: 143, country: 'Mexico', avatar: 'flag-icon flag-icon-mx', name: '+52' },
    { id: 144, country: 'Micronesia, Federated States of', avatar: 'flag-icon flag-icon-fm', name: '+691' },
    { id: 145, country: 'Moldova, Republic of', avatar: 'flag-icon flag-icon-md', name: '+373' },
    { id: 146, country: 'Monaco', avatar: 'flag-icon flag-icon-mc', name: '+377' },
    { id: 147, country: 'Mongolia', avatar: 'flag-icon flag-icon-mn', name: '+976' },
    { id: 148, country: 'Montenegro', avatar: 'flag-icon flag-icon-me', name: '+382' },
    { id: 149, country: 'Montserrat', avatar: 'flag-icon flag-icon-ms', name: '+1-664' },
    { id: 150, country: 'Morocco', avatar: 'flag-icon flag-icon-ma', name: '+212' },
    { id: 151, country: 'Mozambique', avatar: 'flag-icon flag-icon-mz', name: '+258' },
    { id: 152, country: 'Myanmar', avatar: 'flag-icon flag-icon-mm', name: '+95' },
    { id: 153, country: 'Namibia', avatar: 'flag-icon flag-icon-na', name: '+264' },
    { id: 154, country: 'Nauru', avatar: 'flag-icon flag-icon-nr', name: '+674' },
    { id: 155, country: 'Nepal', avatar: 'flag-icon flag-icon-np', name: '+977' },
    { id: 156, country: 'Netherlands', avatar: 'flag-icon flag-icon-nl', name: '+31' },
    { id: 157, country: 'Netherlands Antilles', avatar: 'flag-icon flag-icon-an', name: '+599' },
    { id: 158, country: 'New Caledonia', avatar: 'flag-icon flag-icon-nc', name: '+687' },
    { id: 159, country: 'New Zealand', avatar: 'flag-icon flag-icon-nz', name: '+64' },
    { id: 160, country: 'Nicaragua', avatar: 'flag-icon flag-icon-ni', name: '+505' },
    { id: 161, country: 'Niger', avatar: 'flag-icon flag-icon-ne', name: '+227' },
    { id: 162, country: 'Nigeria', avatar: 'flag-icon flag-icon-ng', name: '+234' },
    { id: 163, country: 'Niue', avatar: 'flag-icon flag-icon-nu', name: '+683' },
    { id: 164, country: 'Norfolk Island', avatar: 'flag-icon flag-icon-nf', name: '+672' },
    { id: 165, country: 'Northern Mariana Islands', avatar: 'flag-icon flag-icon-mp', name: '+1-670' },
    { id: 166, country: 'Norway', avatar: 'flag-icon flag-icon-no', name: '+47' },
    { id: 167, country: 'Oman', avatar: 'flag-icon flag-icon-om', name: '+968' },
    { id: 168, country: 'Pakistan', avatar: 'flag-icon flag-icon-pk', name: '+92' },
    { id: 169, country: 'Palau', avatar: 'flag-icon flag-icon-pw', name: '+680' },
    { id: 170, country: 'Palestinian Territory', avatar: 'flag-icon flag-icon-ps', name: '+970' },
    { id: 171, country: 'Panama', avatar: 'flag-icon flag-icon-pa', name: '+507' },
    { id: 172, country: 'Papua New Guinea', avatar: 'flag-icon flag-icon-pg', name: '+675' },
    { id: 173, country: 'Paraguay', avatar: 'flag-icon flag-icon-py', name: '+595' },
    { id: 174, country: 'Peru', avatar: 'flag-icon flag-icon-pe', name: '+51' },
    { id: 175, country: 'Philippines', avatar: 'flag-icon flag-icon-ph', name: '+63' },
    { id: 176, country: 'Pitcairn', avatar: 'flag-icon flag-icon-pn', name: '+870' },
    { id: 177, country: 'Poland', avatar: 'flag-icon flag-icon-pl', name: '+48' },
    { id: 178, country: 'Portugal', avatar: 'flag-icon flag-icon-pt', name: '+351' },
    { id: 179, country: 'Puerto Rico', avatar: 'flag-icon flag-icon-pr', name: '+1' },
    { id: 180, country: 'Qatar', avatar: 'flag-icon flag-icon-qa', name: '+974' },
    { id: 181, country: 'Réunion', avatar: 'flag-icon flag-icon-re', name: '+262' },
    { id: 182, country: 'Romania', avatar: 'flag-icon flag-icon-ro', name: '+40' },
    { id: 183, country: 'Russian Federation', avatar: 'flag-icon flag-icon-ru', name: '+7' },
    { id: 184, country: 'Rwanda', avatar: 'flag-icon flag-icon-rw', name: '+250' },
    { id: 185, country: 'Saint-Barthélemy', avatar: 'flag-icon flag-icon-bl', name: '+590' },
    { id: 186, country: 'Saint Helena', avatar: 'flag-icon flag-icon-sh', name: '+290' },
    { id: 187, country: 'Saint Kitts and Nevis', avatar: 'flag-icon flag-icon-kn', name: '+1-869' },
    { id: 188, country: 'Saint Lucia', avatar: 'flag-icon flag-icon-lc', name: '+1-758' },
    { id: 189, country: 'Saint-Martin (French)', avatar: 'flag-icon flag-icon-mf', name: '+590' },
    { id: 190, country: 'Saint Pierre and Miquelon', avatar: 'flag-icon flag-icon-pm', name: '+508' },
    { id: 191, country: 'Saint Vincent and Grenadines', avatar: 'flag-icon flag-icon-vc', name: '+1-784' },
    { id: 192, country: 'Samoa', avatar: 'flag-icon flag-icon-ws', name: '+685' },
    { id: 193, country: 'San Marino', avatar: 'flag-icon flag-icon-sm', name: '+378' },
    { id: 194, country: 'Sao Tome and Principe', avatar: 'flag-icon flag-icon-st', name: '+239' },
    { id: 195, country: 'Saudi Arabia', avatar: 'flag-icon flag-icon-sa', name: '+966' },
    { id: 196, country: 'Senegal', avatar: 'flag-icon flag-icon-sn', name: '+221' },
    { id: 197, country: 'Serbia', avatar: 'flag-icon flag-icon-rs', name: '+381' },
    { id: 198, country: 'Seychelles', avatar: 'flag-icon flag-icon-sc', name: '+248' },
    { id: 199, country: 'Sierra Leone', avatar: 'flag-icon flag-icon-sl', name: '+232' },
    { id: 200, country: 'Singapore', avatar: 'flag-icon flag-icon-sg', name: '+65' },
    { id: 201, country: 'Slovakia', avatar: 'flag-icon flag-icon-sk', name: '+421' },
    { id: 202, country: 'Slovenia', avatar: 'flag-icon flag-icon-si', name: '+386' },
    { id: 203, country: 'Solomon Islands', avatar: 'flag-icon flag-icon-sb', name: '+677' },
    { id: 204, country: 'Somalia', avatar: 'flag-icon flag-icon-so', name: '+252' },
    { id: 205, country: 'South Africa', avatar: 'flag-icon flag-icon-za', name: '+27' },
    { id: 206, country: 'South Georgia and the South Sandwich Islands', avatar: 'flag-icon flag-icon-gs', name: '+500' },
    { id: 207, country: 'South Sudan', avatar: 'flag-icon flag-icon-ss', name: '+211' },
    { id: 208, country: 'Spain', avatar: 'flag-icon flag-icon-es', name: '+34' },
    { id: 209, country: 'Sri Lanka', avatar: 'flag-icon flag-icon-lk', name: '+94' },
    { id: 210, country: 'Sudan', avatar: 'flag-icon flag-icon-sd', name: '+249' },
    { id: 211, country: 'Suriname', avatar: 'flag-icon flag-icon-sr', name: '+597' },
    { id: 212, country: 'Svalbard and Jan Mayen Islands', avatar: 'flag-icon flag-icon-sj', name: '+47' },
    { id: 213, country: 'Swaziland', avatar: 'flag-icon flag-icon-sz', name: '+268' },
    { id: 214, country: 'Sweden', avatar: 'flag-icon flag-icon-se', name: '+46' },
    { id: 215, country: 'Switzerland', avatar: 'flag-icon flag-icon-ch', name: '+41' },
    { id: 216, country: 'Syrian Arab Republic (Syria)', avatar: 'flag-icon flag-icon-sy', name: '+963' },
    { id: 217, country: 'Taiwan, Republic of China', avatar: 'flag-icon flag-icon-tw', name: '+886' },
    { id: 218, country: 'Tajikistan', avatar: 'flag-icon flag-icon-tj', name: '+992' },
    { id: 219, country: 'Tanzania, United Republic of', avatar: 'flag-icon flag-icon-tz', name: '+255' },
    { id: 220, country: 'Thailand', avatar: 'flag-icon flag-icon-th', name: '+66' },
    { id: 221, country: 'Timor-Leste', avatar: 'flag-icon flag-icon-tl', name: '+670' },
    { id: 222, country: 'Togo', avatar: 'flag-icon flag-icon-tg', name: '+228' },
    { id: 223, country: 'Tokelau', avatar: 'flag-icon flag-icon-tk', name: '+690' },
    { id: 224, country: 'Tonga', avatar: 'flag-icon flag-icon-to', name: '+676' },
    { id: 225, country: 'Trinidad and Tobago', avatar: 'flag-icon flag-icon-tt', name: '+1-868' },
    { id: 226, country: 'Tunisia', avatar: 'flag-icon flag-icon-tn', name: '+216' },
    { id: 227, country: 'Turkey', avatar: 'flag-icon flag-icon-tr', name: '+90' },
    { id: 228, country: 'Turkmenistan', avatar: 'flag-icon flag-icon-tm', name: '+993' },
    { id: 229, country: 'Turks and Caicos Islands', avatar: 'flag-icon flag-icon-tc', name: '+1-649' },
    { id: 230, country: 'Tuvalu', avatar: 'flag-icon flag-icon-tv', name: '+688' },
    { id: 231, country: 'Uganda', avatar: 'flag-icon flag-icon-ug', name: '+256' },
    { id: 232, country: 'Ukraine', avatar: 'flag-icon flag-icon-ua', name: '+380' },
    { id: 233, country: 'United Arab Emirates', avatar: 'flag-icon flag-icon-ae', name: '+971' },
    { id: 234, country: 'United Kingdom', avatar: 'flag-icon flag-icon-gb', name: '+44' },
    { id: 235, country: 'United States of America', avatar: 'flag-icon flag-icon-us', name: '+1' },
    { id: 236, country: 'US Minor Outlying Islands', avatar: 'flag-icon flag-icon-um', name: '+1' },
    { id: 237, country: 'Uruguay', avatar: 'flag-icon flag-icon-uy', name: '+598' },
    { id: 238, country: 'Uzbekistan', avatar: 'flag-icon flag-icon-uz', name: '+998' },
    { id: 239, country: 'Vanuatu', avatar: 'flag-icon flag-icon-vu', name: '+678' },
    { id: 240, country: 'Venezuela (Bolivarian Republic)', avatar: 'flag-icon flag-icon-ve', name: '+58' },
    { id: 241, country: 'Viet Nam', avatar: 'flag-icon flag-icon-vn', name: '+84' },
    { id: 242, country: 'Virgin Islands, US', avatar: 'flag-icon flag-icon-vi', name: '+1-340' },
    { id: 243, country: 'Wallis and Futuna Islands', avatar: 'flag-icon flag-icon-wf', name: '+681' },
    { id: 244, country: 'Western Sahara', avatar: 'flag-icon flag-icon-eh', name: '+212' },
    { id: 245, country: 'Yemen', avatar: 'flag-icon flag-icon-ye', name: '+967' },
    { id: 246, country: 'Zambia', avatar: 'flag-icon flag-icon-zm', name: '+260' },
    { id: 247, country: 'Zimbabwe', avatar: 'flag-icon flag-icon-zw', name: '+263' }
  ];


  constructor(
    private location: Location
  ) { }

  public addBackdrop(): void {
    const div = Array.from(document.querySelectorAll('.rightSheet, .bottomSheet'));
    div.forEach((d) => {
      const d2 = this.renderer.createElement('div');
      this.renderer.addClass(d2, 'sheet-backdrop');
      this.renderer.appendChild(d, d2);
    });
  }

  public removeBackdrop(): void {
    const sheet = Array.from(document.querySelectorAll('.rightSheet,  .bottomSheet'));
    const backdrop = Array.from(document.querySelectorAll('.sheet-backdrop'));
    backdrop.forEach((bd) => {
      this.renderer.removeChild(sheet, bd);
    });
  }

  goBack(): void {
    this.location.back();
  }
}
