/**
 * Nuphi trade
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2.0.0
 * Contact: shraddha@blackcurrantapps.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Organisation } from './organisation';
import { OrganisationContact } from './organisationContact';


export interface VendorAndSupplier { 
    venSupId?: number;
    companyName?: string;
    companyPhoneNo?: string;
    companyCountryCode?: string;
    companyTaxNo?: string;
    companyWebsite?: string;
    companyBillingAddress?: string;
    companyShippingAddress?: string;
    orgId?: number;
    type?: VendorAndSupplier.TypeEnum;
    tags?: any;
    Organisation?: Organisation;
    OrganisationContacts?: Array<OrganisationContact>;
}
export namespace VendorAndSupplier {
    export type TypeEnum = 'BUYER' | 'SELLER' | 'SERVICE_PROVIDER' | 'DUAL(BUYER+SELLER)' | 'OTHER';
    export const TypeEnum = {
        BUYER: 'BUYER' as TypeEnum,
        SELLER: 'SELLER' as TypeEnum,
        SERVICEPROVIDER: 'SERVICE_PROVIDER' as TypeEnum,
        DUALBUYERSELLER: 'DUAL(BUYER+SELLER)' as TypeEnum,
        OTHER: 'OTHER' as TypeEnum
    };
}
