import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild, Renderer2, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { interval, Subject, Subscription, of } from 'rxjs';
import { filter, switchMap, skip, catchError, tap, distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { SwalHelper } from 'src/app/swal';
import { environment } from 'src/environments/environment';
import {
  Invoice, InvoiceItem, ItemMaster, OrganisationService, Page, VendorAndSupplier, SalesOrder,
  OrgOrdersService, OrgInvoiceService, OrganisationBankAccount, LicensesService
} from 'src/swagger';
import { CommonSupportService } from 'src/app/common-support.service';
import * as moment from 'moment';
import { ComponentCanDeactivate } from 'src/app/form-route-deactivate/component-can-deactivate';
import { OrganisationHelperService } from '../../organisation-helper.service';
import { GlobalProfileService } from 'src/app/global-profile.service';
import { License } from '../../../../swagger/model/license';
import { SelectorDiv, SurveyState } from '../../../enums';
import { BulkItemSyncService } from '../bulk-item-sync.service';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { NumberToWordsPipe } from 'src/app/pipes/number-to-words.pipe';

declare var $: any;
declare var Swal: any;

@Component({
  selector: 'app-invoice-updates',
  templateUrl: './invoice-updates.component.html',
  styleUrls: ['./invoice-updates.component.scss']
})
export class InvoiceUpdatesComponent extends ComponentCanDeactivate implements OnInit, OnDestroy, AfterViewInit {

  constructor(
    public http: HttpClient,
    private orgApi: OrganisationService,
    private orgOrderApi: OrgOrdersService,
    private activatedRoute: ActivatedRoute,
    public commonService: CommonSupportService,
    private renderer: Renderer2,
    private changeDetectorRef: ChangeDetectorRef,
    private orgInvApi: OrgInvoiceService,
    public orgHelper: OrganisationHelperService,
    private router: Router,
    public profileSync: GlobalProfileService,
    public licenseApi: LicensesService,
    private bulkItemSync: BulkItemSyncService
  ) {
    super();
    commonService.renderer = renderer;
    this.basePath = environment.BASE_PATH;
  }

  @ViewChild('InvoiceForm')
  public invoiceFormRef: NgForm;

  public basePath: string;
  private invoiceOCRSubscription: Subscription;

  public itemChange$: Subject<string> = new Subject();
  private itemChangeSubscription: Subscription;
  public itemLoading: boolean;

  public surveyState: SurveyState = SurveyState.SAVED;
  public outputInvoice: any;

  public invoice: Invoice = {};
  public newItem: InvoiceItem = {};

  public shouldShowLoader = false;

  public vendorSuppliers: VendorAndSupplier[] = [];
  public itemMasters$: Subject<ItemMaster[]> = new Subject();
  public ocrResults: Page[] = [];

  public buyerDetails: VendorAndSupplier = {};
  public notifyParty1: VendorAndSupplier = {};
  public notifyParty2: VendorAndSupplier = {};
  public consignee: VendorAndSupplier = {};

  // Third party for customs
  public customsNotifyParty1: VendorAndSupplier = {};
  public customsNotifyParty2: VendorAndSupplier = {};
  public customsConsignee: VendorAndSupplier = {};

  // For OCR
  public copyText = 'Click to copy';
  public selectedImageURL: string;
  public totalPages = [];

  public thisPage: any;
  public bgwidth;
  public bgheight;
  public selectors: SelectorDiv[] = [];

  public rightSheet = false;
  public bottomSheet = false;
  public newContactType: string;

  public isRedirectToPreview: boolean;

  // For fetching Sales Order
  public soChange$: Subject<string> = new Subject();
  public listSo$: Subject<SalesOrder[]> = new Subject();
  private soChangeSubscription: Subscription;
  public alreadyLinkedSOIds = [];
  public soLoading = false;
  public salesOrderId: number;

  /* For feching Vendors */
  public buyerChange$: Subject<string> = new Subject();
  public listBuyerCustomer$: Subject<VendorAndSupplier[]> = new Subject();
  private buyerChangeSubscription: Subscription;
  public buyerLoading = false;

  /* For fetching license details */
  public licenseChange$: Subject<object> = new Subject();
  private licenseChangeSubscription: Subscription;
  public licenseLoading: boolean;
  public license$: Subject<License[]> = new Subject();

  public selectedVendor: number;
  public itemMasterDetail: ItemMaster = {};

  public customItemIndex: number;
  public invoiceVersion = 'Commercial';
  public totalGST = 0;
  public totalQuantity = 0;
  public totalCustomsQuantity = 0;
  public totalNoOfPackages = 0;

  public invoiceFiles: File[] = [];

  public orgBanks: OrganisationBankAccount[] = [];

  public licenseDetail: AdvancedLicense = {};

  public enabledFields = [];

  ngOnInit(): void {
    this.initInvoice();
    const $view = $('.print-wrapper');
    const $content = $view.find('.print');
    const width = '1191px';
    $content.width(width);
    this.fetchVendorSupplier();

    this.activatedRoute.queryParams.subscribe(param => {
      if (param && param.type) {
        this.invoiceVersion = param.type;
      }
    });

    this.activatedRoute.params.subscribe(param => {
      if (param && param.invId) {
        this.fetchInvoice(param.invId);
      }
    });

    this.bulkItemSync.invoiceItems$.subscribe(items => {
      this.invoice.InvoiceItems = this.invoice.InvoiceItems.concat(items);
      this.bottomSheetClose();
      this.calSubTotalAmount();
      this.calCustomsSubTotalAmount();
    });

    $view.on('mousemove', function (event): void {
      const $this = $(this);
      const parentOffset = $this.parent().offset();
      const mouseX = event.pageX - parentOffset.left;
      const mouseY = event.pageY - parentOffset.top;

      const viewRangeX = $this.width();
      const viewRangeY = $this.height();

      const contentRangeX = $content.outerWidth(true);
      const contentRangeY = $content.outerHeight(true);

      const ratioX = contentRangeX / viewRangeX;
      const ratioY = contentRangeY / viewRangeY;

      const offsetX = (mouseX * ratioX) - mouseX;
      const offsetY = (mouseY * ratioY) - mouseY;

      $content.css({
        top: (offsetY * -1) + 'px',
        left: (offsetX * -1) + 'px'
      });

    });

    this.itemChangeSubscription = this.itemChange$.pipe(
      distinctUntilChanged(),
      tap(() => this.itemLoading = true),
      switchMap(query => this.orgApi.listItemMaster(query).pipe(
        catchError(() => of([])), // empty list on error
        tap(() => {
          this.itemLoading = false;
        })
      ))
    ).subscribe(items => {
      this.itemMasters$.next(items);
    });

    this.licenseChangeSubscription = this.licenseChange$.pipe(
      distinctUntilChanged(),
      tap(() => this.licenseLoading = true),
      switchMap((query: any) => this.licenseApi.searchLicensesNo(
        query.itemHSNCode,
        'Export',
        query.licenseNo,
        query.excludeId,
        query.licenseType,
        query.isEPCG,
      ).pipe(
        catchError(() => of([])), // empty list on error
        tap(() => {
          this.licenseLoading = false;
        })
      ))
    ).subscribe(licenses => {
      this.license$.next(licenses);
    });

    this.soChangeSubscription = this.soChange$.pipe(
      debounceTime(200),
      tap(() => this.soLoading = true),
      switchMap(query => this.orgInvApi.searchSosForInvoice(
        query,
        this.invoice.currency ? this.invoice.currency : undefined,
        this.alreadyLinkedSOIds.join(',')
      ).pipe(
        catchError(() => of([])), // empty list on error
        tap(() => {
          this.soLoading = false;
        })
      ))
    ).subscribe(sos => {
      this.listSo$.next(sos);
    });

    this.buyerChangeSubscription = this.buyerChange$.pipe(
      distinctUntilChanged(),
      tap(() => this.buyerLoading = true),
      switchMap(query => this.orgApi.searchVendors(
        query,
        'BUYER'
      ).pipe(
        catchError(() => of([])), // empty list on error
        tap(() => {
          this.buyerLoading = false;
        })
      ))
    ).subscribe(items => {
      this.listBuyerCustomer$.next(items);
    });

  }

  ngAfterViewInit(): void {
    $('.dropdown .dropdown-menu').click(function (e) {
      e.stopPropagation();
      console.log('clicke');
    });
  }

  ngOnDestroy(): void {
    if (this.invoiceOCRSubscription) {
      this.invoiceOCRSubscription.unsubscribe();
    }
    if (this.buyerChangeSubscription) {
      this.buyerChangeSubscription.unsubscribe();
    }
    if (this.soChangeSubscription) {
      this.soChangeSubscription.unsubscribe();
    }
    if (this.itemChangeSubscription) {
      this.itemChangeSubscription.unsubscribe();
    }
    if (this.licenseChangeSubscription) {
      this.licenseChangeSubscription.unsubscribe();
    }
  }

  canDeactivate(): boolean {
    return !this.invoiceFormRef.dirty;
  }

  drop(event: CdkDragDrop<string[]>) {
    const splicedElement = this.invoice.otherData.customColumns.splice(event.previousIndex, 1)[0];
    this.invoice.otherData.customColumns.splice(event.currentIndex, 0, splicedElement);
    this.recalculateEnabledCustomFields();
  }

  // Init default values of new Invoice
  initInvoice(): void {
    this.invoice.InvoiceItems = [];
    this.invoice.notifyPartyId1 = 0;
    this.invoice.notifyPartyId2 = 0;
    this.invoice.buyerId = 0;
    this.invoice.customsConsigneeId = 0;
    this.invoice.customsNotifyPartyId1 = 0;
    this.invoice.customsNotifyPartyId2 = 0;
  }

  // Init new Empty Invoice item
  addItem(): void {
    const item: InvoiceItem = {};
    item.itemQuantity = 1;
    item.itemCustTotalQuantity = 1;
    item.itemPrice = 1;
    item.customsItemPrice = 1;
    item.advanceLicenseDetails = {};
    item.gst = 0;
    item.noOfPackages = 1;
    item.otherData = this.invoice.otherData && this.invoice.otherData.customColumns ? this.orgHelper.copyObject(this.invoice.otherData.customColumns) : null;
    this.invoice.InvoiceItems.push(item);
    this.calSubTotalAmount();
    this.calCustomsSubTotalAmount();
  }

  /** Fn to remove the invoice item
   * @param index Index of invoice item
   */
  removeItem(index: number): void {
    Swal.fire({
      title: 'Remove Item?',
      text: 'Are you sure want to delete this Item?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete',
    }).then((result) => {
      if (result.value) {
        this.invoice.InvoiceItems.splice(index, 1);
        this.changeDetectorRef.detectChanges();
        this.calSubTotalAmount();
        this.calCustomsSubTotalAmount();
        SwalHelper.showToast('success', 'Item deleted!');
      }
    });
  }

  /** Add new Custom Column
    * @param index index of item object
    * @param isChecked Boolean to check custo field is selected
    */
  addColumn(index: number, isChecked: boolean): void {
    this.invoice.otherData.customColumns[index].isEnabled = isChecked;
    this.recalculateEnabledCustomFields();
    this.changeDetectorRef.detectChanges();
  }

  /* Fn to recalculate the enabled custom fields array */
  private recalculateEnabledCustomFields() {
    this.enabledFields = this.invoice.otherData && this.invoice.otherData.customColumns && this.invoice.otherData.customColumns.length > 0 ? this.invoice.otherData.customColumns.filter(col => col.isEnabled) : [];
  }

  /** Remove the custom column
   * @param index Custom field index
   */
  removeColumn(index: number): void {
    Swal.fire({
      title: 'Remove Column?',
      text: 'Are you sure want to delete this Column?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete',
    }).then((result) => {
      if (result.value) {
        this.invoice.otherData.customColumns[this.enabledFields[index]] = false;
        this.recalculateEnabledCustomFields();
        this.changeDetectorRef.detectChanges();
        SwalHelper.showToast('success', 'Column deleted!');
      }
    });
  }

  // Functions related to advance license
  /**
   *
   * @param index index of the invoice item
   * @param isEPCG boolean to check applied for EPCG license
   */

  addLicense(index: number, isEPCG: boolean): void {
    this.licenseDetail = {};
    this.licenseDetail.itemIndex = index;
    this.licenseDetail.type = 'EPCG (Export Promotion Capital Goods)';
    this.licenseDetail.isEPCG = isEPCG;
    const object = {
      itemHSNCode: this.invoice.InvoiceItems[index].itemHSNCode,
      licenseNo: null,
      excludeId: null,
      licenseType: this.licenseDetail.type,
      isEPCG: this.licenseDetail.isEPCG,
    };
    if (this.invoice.InvoiceItems[index].advanceLicenseDetails.licenses) {
      const excludeId = this.invoice.InvoiceItems[index].advanceLicenseDetails.licenses.map(i => i.licenseNo);
      if (excludeId) {
        object.excludeId = excludeId;
      }
    }
    this.licenseChange$.next(object);
    $('#licenseAddModal').modal('show');

  }
  /**
   * @param event License Event
   */
  checkLicense(event: License): void {
    if (event && event.licenseId) {
      const exportQuantity = event.LicenseItems.filter(i => i.itemType === 'Export')[0];
      this.licenseDetail.licenseNo = event.licenseNo;
      this.licenseDetail.licenseId = event.licenseId;
      this.licenseDetail.licenseType = event.licenseType;
      this.licenseDetail.dateOfIssue = event.dateOfIssue;
      this.licenseDetail.fileNo = event.fileNo;
      this.licenseDetail.regionalAuthority = event.regionalAuthority;
      this.licenseDetail.expOblExpiryDate = event.expOblExpiryDate;
      this.licenseDetail.LicenseItems = [];
      this.licenseDetail.exportItem = exportQuantity;

      event.LicenseItems.forEach(item => {
        if (item && item.itemType === 'Import') {
          this.licenseDetail.LicenseItems.push({
            licenseItem: item,
            licenseItemId: item.licenseItemId,
            itemUnit: item.itemUnit,
            itemQuantity: (item.itemQuantityRatio * this.invoice.InvoiceItems[this.licenseDetail.itemIndex].itemCustTotalQuantity) / exportQuantity.itemQuantityRatio,
          });
        }
      });
    } else {
      this.licenseDetail = {};
    }
  }
  // Fn to save the license details
  saveLicenseDetail(): void {
    if (this.licenseDetail.licenseNo) {
      if (!this.invoice.InvoiceItems[this.licenseDetail.itemIndex].advanceLicenseDetails.licenses) {
        this.invoice.InvoiceItems[this.licenseDetail.itemIndex].advanceLicenseDetails.licenses = [];
      }
      this.invoice.InvoiceItems[this.licenseDetail.itemIndex].advanceLicenseDetails.licenses.push(this.orgHelper.copyObject(this.licenseDetail));
    }
    $('#licenseAddModal').modal('hide');
    this.licenseDetail = {};
  }

  /**
   * @param itemIndex Index of invoice item
   * @param licenseIndex Index of license Item inside
   */

  showLicenseDetails(itemIndex: number, licenseIndex: number): void {
    $('#licenseDetailsModal').modal('show');
    this.licenseDetail = this.invoice.InvoiceItems[itemIndex].advanceLicenseDetails.licenses[licenseIndex];
    // this.licenseDetail.totalQty = this.invoice.InvoiceItems[itemIndex].advanceLicenseDetails.licenses[licenseIndex].LicenseItems.map(i => i.);
  }

  // fn to notice the license name change on search fn
  licenseChange(event: string): void {
    const object = {
      itemHSNCode: this.invoice.InvoiceItems[this.licenseDetail.itemIndex].itemHSNCode,
      licenseNo: event,
      excludeId: null,
      licenseType: this.licenseDetail.type,
      isEPCG: this.licenseDetail.isEPCG,
    };
    if (this.invoice.InvoiceItems[this.licenseDetail.itemIndex].advanceLicenseDetails.licenses) {
      const excludeId = this.invoice.InvoiceItems[this.licenseDetail.itemIndex].advanceLicenseDetails.licenses.map(i => i.licenseNo);
      if (excludeId) {
        object.excludeId = excludeId;
      }
    }
    this.licenseChange$.next(object);
  }
  /** Fn to recalculate the License raw item qty
   * @param itemIndex Index of invoice Item
  */
  recalculateLicense(itemIndex: number): void {
    if (this.invoice.InvoiceItems[itemIndex].advanceLicenseDetails.licenses && this.invoice.InvoiceItems[itemIndex].advanceLicenseDetails.licenses.length > 0) {
      this.invoice.InvoiceItems[itemIndex].advanceLicenseDetails.licenses.forEach(license => {
        license.LicenseItems.forEach(item => {
          if (item && item.licenseItem) {
            item.itemQuantity = (item.licenseItem.itemQuantityRatio * this.invoice.InvoiceItems[itemIndex].itemCustTotalQuantity) / license.exportItem.itemQuantityRatio;
          }
        });
      });
    }
  }
  /** Fn to remove the license
   * @param itemIndex Index of invoice item
   * @param licenseIndex Index of license Item inside
   */
  removeLicenseDetails(itemIndex: number, licenseIndex: number): void {
    Swal.fire({
      title: 'Remove License?',
      text: 'Are you sure want to delete this License?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete',
    }).then((result) => {
      if (result.value) {
        this.invoice.InvoiceItems[itemIndex].advanceLicenseDetails.licenses.splice(licenseIndex, 1);
        SwalHelper.showToast('success', 'License deleted!');
      }
    });
  }

  // copyToCustomsUP(index: number): void {
  //   this.invoice.InvoiceItems[index].customsItemPrice = this.invoice.InvoiceItems[index].customsItemPrice && this.invoice.InvoiceItems[index].customsItemPrice != 1 ? this.invoice.InvoiceItems[index].customsItemPrice : this.invoice.InvoiceItems[index].itemPrice;
  // }

  // All the function to do on keyup calculation
  // Fn to calculate the commercial subtotal
  calSubTotalAmount(): void {
    this.invoice.subtotalAmount = this.invoice.InvoiceItems.map(i => i.itemPrice * i.itemQuantity).reduce((a, b) => a + b, 0);
    this.totalQuantity = this.invoice.InvoiceItems.map(i => i.itemQuantity).reduce((a, b) => a + b, 0);
    this.invoice.freightTotalQty = this.invoice.freightTotalQty ? this.invoice.freightTotalQty : this.totalQuantity;
    this.calTotalAmount();
    this.calGSTAmount();
    this.calTotalNetWeight();
    this.calTotalGrossWeight();
    this.calTotalPackages();
    this.calFreightAmount();
  }
  // Fn to calculate the commercial subtotal
  calCustomsSubTotalAmount(): void {
    this.invoice.customsSubtotalAmount = this.invoice.InvoiceItems.map(i => i.customsItemPrice * i.itemCustTotalQuantity).reduce((a, b) => a + b, 0);
    console.log(this.invoice.customsSubtotalAmount)
    this.totalCustomsQuantity = this.invoice.InvoiceItems.map(i => i.itemCustTotalQuantity).reduce((a, b) => a + b, 0);
    this.calTotalCustomsAmount();
    this.calGSTAmount();
    this.calTotalPackages();
    this.calCustomsTotalGrossWeight();
    this.calCustomsTotalNetWeight();
  }

  // Fn to calculate Customs Total GST amount
  calGSTAmount(): void {
    this.totalGST = this.invoice.InvoiceItems.map(i => i.customsItemPrice * i.itemCustTotalQuantity * (i.gst / 100)).reduce((a, b) => a + b, 0) * this.invoice.exchangeRate;
  }
  // Fn to calculate Total Freight amount
  calFreightAmount(): void {
    this.invoice.freight = this.invoice.freightTotalQty * this.invoice.freightUnitPrice;
  }

  // Fn to calculate Total Packages
  calTotalPackages(): void {
    this.totalNoOfPackages = this.invoice.InvoiceItems.map(i => i.noOfPackages).reduce((a, b) => a + b, 0);
  }

  // Fn to calculate Commercial Total net weight
  calTotalNetWeight(): void {
    this.invoice.totalNetWeight = this.invoice.InvoiceItems.map(i => i.netWeight).reduce((a, b) => a + b, 0);
  }

  // Fn to calculate Commerical Total gross weight
  calTotalGrossWeight(): void {
    this.invoice.totalGrossWeight = this.invoice.InvoiceItems.map(i => i.grossWeight).reduce((a, b) => a + b, 0);
  }

  // Fn to calculate Customs Total net weight
  calCustomsTotalNetWeight(): void {
    this.invoice.customsTotalNetWeight = this.invoice.InvoiceItems.map(i => i.custNetWeight).reduce((a, b) => a + b, 0);
  }

  // Fn to calculate Customs Total gross weight
  calCustomsTotalGrossWeight(): void {
    this.invoice.customsTotalGrossWeight = this.invoice.InvoiceItems.map(i => i.custGrossWeight).reduce((a, b) => a + b, 0);
  }

  // Fn to calculate commercial Total qnty
  calTotalQuantity(index: number): void {
    this.invoice.InvoiceItems[index].itemQuantity = this.invoice.InvoiceItems[index].noOfPackages * this.invoice.InvoiceItems[index].itemQuantityPerPack;
    this.calWeight(index);
  }

  /* Fn to calculate Net weight & Gross weight per Item */
  calWeight(index: number): void {
    if (this.invoice.InvoiceItems[index].perUnitWeight) {
      this.invoice.InvoiceItems[index].netWeight = parseFloat((this.invoice.InvoiceItems[index].itemQuantity * this.invoice.InvoiceItems[index].perUnitWeight.netWeight).toFixed(3));
      this.invoice.InvoiceItems[index].grossWeight = parseFloat((this.invoice.InvoiceItems[index].itemQuantity * this.invoice.InvoiceItems[index].perUnitWeight.grossWeight).toFixed(3));
    }
    this.calSubTotalAmount();
  }
  /* Fn to calculate Net weight & Gross weight per customs invoice Item */
  calCustomsWeight(index: number): void {
    if (this.invoice.InvoiceItems[index].perUnitWeight) {
      this.invoice.InvoiceItems[index].custNetWeight = parseFloat((this.invoice.InvoiceItems[index].itemCustTotalQuantity * this.invoice.InvoiceItems[index].perUnitWeight.netWeight).toFixed(3));
      this.invoice.InvoiceItems[index].custGrossWeight = parseFloat((this.invoice.InvoiceItems[index].itemCustTotalQuantity * this.invoice.InvoiceItems[index].perUnitWeight.grossWeight).toFixed(3));
    }
    this.calCustomsSubTotalAmount();
  }

  // Fn to calculate customs Total qnty
  calTotalCustomsQuantity(index: number): void {
    this.invoice.InvoiceItems[index].itemCustTotalQuantity = this.invoice.InvoiceItems[index].noOfPackages * this.invoice.InvoiceItems[index].itemCustQuantityPerPack;
    this.calCustomsWeight(index);
  }

  // Fn to calculate Commercial Total Amount
  calTotalAmount(): void {
    this.invoice.totalAmount = this.invoice.subtotalAmount - this.invoice.discountValue + this.invoice.freight + this.invoice.insuranceValue;
    this.invoice.customsDiscountValue = this.invoice.customsDiscountValue ? this.invoice.customsDiscountValue : this.invoice.discountValue;
    this.calTotalCustomsAmount();
  }

  // Fn to calculate Customs Total Amount
  calTotalCustomsAmount(): void {
    this.invoice.customsTotalAmount = this.invoice.customsSubtotalAmount - this.invoice.customsDiscountValue + this.invoice.freight + this.invoice.insuranceValue;
  }

  /**
   * Fn to check and set the item master details into invoice item
   * If item master not found open the modal to enter details
   * @param event Item master detail/ empty object
   * @param index index of invoice items
   */
  checkItem(event: any, index: number): void {
    if (event) {
      if (event.itemMasterId) {
        const tempOtherData = this.invoice.InvoiceItems[index];
        this.invoice.InvoiceItems[index] = event;
        this.invoice.InvoiceItems[index].itemCustomsDescription = event.itemDescription;
        this.invoice.InvoiceItems[index].itemPackingListDescription = event.itemDescription;
        this.invoice.InvoiceItems[index].itemCustPLDescription = event.itemDescription;
        this.invoice.InvoiceItems[index].customsItemUnit = event.itemUnit;
        this.invoice.InvoiceItems[index].custNetWeight = event.netWeight;
        this.invoice.InvoiceItems[index].custGrossWeight = event.grossWeight;
        this.invoice.InvoiceItems[index].perUnitWeight = {
          netWeight: event.netWeight,
          grossWeight: event.grossWeight
        };
        this.invoice.InvoiceItems[index].gst = 0;
        this.invoice.InvoiceItems[index].itemQuantity = 1;
        this.invoice.InvoiceItems[index].itemCustTotalQuantity = 1;
        this.invoice.InvoiceItems[index].itemPrice = event.itemPrice ? event.itemPrice : 1;
        this.invoice.InvoiceItems[index].customsItemPrice = event.itemPrice ? event.itemPrice : 1;
        this.invoice.InvoiceItems[index].noOfPackages = 1;
        this.invoice.InvoiceItems[index].advanceLicenseDetails = tempOtherData && tempOtherData.advanceLicenseDetails ? tempOtherData.advanceLicenseDetails : {};
        this.invoice.InvoiceItems[index].otherData = tempOtherData && tempOtherData.otherData ? tempOtherData.otherData : this.orgHelper.copyObject(this.invoice.otherData.customColumns);
      } else {
        $('#itemMasterModal').modal('show');
        this.customItemIndex = index;
        this.itemMasterDetail.itemCode = event.itemCode;
        this.itemMasterDetail.itemCurrency = this.invoice.currency;
      }
    } else {
      this.invoice.InvoiceItems[index].itemCode = undefined;
    }
  }

  /* Fn to fetch the list of vendors */
  fetchVendorSupplier(): void {
    this.shouldShowLoader = true;
    this.orgApi.listVendorSupplier().subscribe(vendos => {
      if (vendos && vendos.length > 0) {
        this.shouldShowLoader = false;
        this.vendorSuppliers = vendos;
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /**
   * Fn to fetch the invoice details
   * @param invId invoice id to fetch details
   */
  fetchInvoice(invId): void {
    this.shouldShowLoader = true;
    this.orgInvApi.getInvoice(invId).subscribe(inv => {
      if (inv) {
        this.shouldShowLoader = false;
        this.invoice = inv;
        this.buyerChange$.next(undefined);

        this.recalculateEnabledCustomFields();
        this.bulkItemSync.customFields$.next(this.invoice.otherData.customColumns);

        if (this.invoice.InvoiceItems.length === 0) {
          this.addItem();
        }

        this.postInvoiceDataCleaning();
        this.fetchBankDetails();

        // tslint:disable-next-line: max-line-length
        this.alreadyLinkedSOIds = this.invoice.OrderInvoiceMappings && this.invoice.OrderInvoiceMappings.length > 0 ? this.invoice.OrderInvoiceMappings.map(i => i.salesOrderId) : [];
        this.soChange$.next(undefined);
        this.itemChange$.next(undefined);
        this.calGSTAmount();
      }
      if (this.invoice.ocrStatus !== 'FAILED' && this.invoice.importedDocUrl) {
        this.invoiceOCRSubscription = interval(3000).pipe(skip(3)).subscribe(tick => {
          this.orgApi.ocrResults('Invoice', this.invoice.invId).pipe(
            catchError(err => of(null)),
            filter((result) => !!result),
          ).subscribe(results => {
            if (results && results.length > 0) {
              this.totalPages = results;
              this.invoiceOCRSubscription.unsubscribe();
              this.ocrResults = results;
              this.loadData(0);
            }
          });
        });
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /* Fn to call after invoice fetched from DB and clean the data */
  postInvoiceDataCleaning(): void {
    this.totalQuantity = this.invoice.InvoiceItems.map(i => i.itemQuantity).reduce((a, b) => a + b, 0);
    this.totalCustomsQuantity = this.invoice.InvoiceItems.map(i => i.itemCustTotalQuantity).reduce((a, b) => a + b, 0);
    this.totalNoOfPackages = this.invoice.InvoiceItems.map(i => i.noOfPackages).reduce((a, b) => a + b, 0);

    this.invoice.currency = this.invoice.currency ? this.invoice.currency : 'USD';
    this.invoice.incoTerms = this.invoice.incoTerms ? this.invoice.incoTerms : 'defaultIncoTerms';
    this.invoice.InvoiceItems = this.invoice.InvoiceItems.length === 0 ? [] : this.invoice.InvoiceItems;

    this.invoice.paymentInfo = this.invoice.paymentInfo ? this.invoice.paymentInfo : {};
    this.invoice.customsPaymentInfo = this.invoice.customsPaymentInfo ? this.invoice.customsPaymentInfo : {};

    this.invoice.paymentInfo.isCancelled = this.invoice.paymentInfo.isCancelled ? this.invoice.paymentInfo.isCancelled : false;
    this.invoice.customsPaymentInfo.isCancelled = this.invoice.customsPaymentInfo.isCancelled ? this.invoice.customsPaymentInfo.isCancelled : false;

    this.buyerDetails = this.invoice.buyerId ? this.invoice.Buyer : {};
    this.consignee = this.invoice.consigneeId ? this.invoice.Consignee : {};
    this.notifyParty1 = this.invoice.notifyPartyId1 ? this.invoice.NotifyParty1 : {};
    this.notifyParty2 = this.invoice.notifyPartyId2 ? this.invoice.NotifyParty2 : {};

    this.customsConsignee = this.invoice.customsConsigneeId ? this.invoice.CustomsConsignee : {};
    this.customsNotifyParty1 = this.invoice.customsNotifyPartyId1 ? this.invoice.CustomsNotifyParty1 : {};
    this.customsNotifyParty2 = this.invoice.customsNotifyPartyId2 ? this.invoice.CustomsNotifyParty2 : {};
  }

  /* Fn to clean the date format & FK before saving Data */
  cleanInvoiceData(): void {
    this.invoice.incoTerms = this.invoice.incoTerms === 'defaultIncoTerms' ? null : this.invoice.incoTerms;
    this.invoice.buyerId = this.invoice.buyerId == -1 ? null : this.invoice.buyerId;
    this.invoice.consigneeId = this.invoice.consigneeId == -1 ? null : this.invoice.consigneeId;
    this.invoice.customsConsigneeId = this.invoice.customsConsigneeId == -1 ? null : this.invoice.customsConsigneeId;
    this.invoice.notifyPartyId1 = this.invoice.notifyPartyId1 == -1 ? null : this.invoice.notifyPartyId1;
    this.invoice.customsNotifyPartyId1 = this.invoice.customsNotifyPartyId1 == -1 ? null : this.invoice.customsNotifyPartyId1;
    this.invoice.notifyPartyId2 = this.invoice.notifyPartyId2 == -1 ? null : this.invoice.notifyPartyId2;
    this.invoice.customsNotifyPartyId2 = this.invoice.customsNotifyPartyId2 == -1 ? null : this.invoice.customsNotifyPartyId2;
    this.invoice.dateOfInv = this.invoice.dateOfInv ? moment(this.invoice.dateOfInv).format('YYYY-MM-DD') : null;
    this.invoice.dueDate = this.invoice.dueDate ? moment(this.invoice.dueDate).format('YYYY-MM-DD') : null;
    this.invoice.blDate = this.invoice.blDate ? moment(this.invoice.blDate).format('YYYY-MM-DD') : null;
    this.invoice.orderDate = this.invoice.orderDate ? moment(this.invoice.orderDate).format('YYYY-MM-DD') : null;
    this.invoice.expDeliveryDate = this.invoice.expDeliveryDate ? moment(this.invoice.expDeliveryDate).format('YYYY-MM-DD') : null;
    this.invoice.shippingBillDate = this.invoice.shippingBillDate ? moment(this.invoice.shippingBillDate).format('YYYY-MM-DD') : null;
    this.invoice.lutDate = this.invoice.lutDate ? moment(this.invoice.lutDate).format('YYYY-MM-DD') : null;
  }

  /**
   * Fn to check totals of Customs & commercial documents
   * @param form Angular form
   */
  checkTotals(form): void {
    if (this.invoice.customsTotalAmount !== this.invoice.totalAmount || this.totalQuantity !== this.totalCustomsQuantity) {
      Swal({
        title: `${this.invoice.customsTotalAmount !== this.invoice.totalAmount ? 'Amount' : 'Quantity'} Mismatch`,
        text: `${this.invoice.customsTotalAmount !== this.invoice.totalAmount ? 'Grand Total is different in Commercial Invoice & Customs Invoice' : 'Grand Total is different in Commercial & Customs Packing List'}`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Continue',
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !Swal.isLoading()
      }).then((result) => {
        if (result.value) {
          this.saveInvoice(form);
        }
      }).catch(err => {
        SwalHelper.showErrorSwal(err);
      });
    } else {
      this.saveInvoice(form);
    }
  }

  /**
   * Fn to save the invoice data
   * @param form Angular form
   */
  saveInvoice(form): void {
    if (form.valid) {
      this.cleanInvoiceData();
      this.shouldShowLoader = true;
      if (this.invoice.invId) {
        this.orgInvApi.updateInvoice(
          this.invoice
        ).subscribe(inv => {
          if (inv) {
            this.shouldShowLoader = false;
            this.invoice = inv;
            this.invoice.incoTerms = this.invoice.incoTerms ? this.invoice.incoTerms : 'defaultIncoTerms';
            this.invoiceFormRef.form.markAsPristine();
            if (this.isRedirectToPreview) {
              this.router.navigate([`/organisation/invoices/invoice-details/${this.invoice.invId}`], { queryParams: { type: this.invoiceVersion } });
            } else {
              SwalHelper.showToast('success', 'Invoice Updated!');
            }
          }
        }, err => {
          this.shouldShowLoader = false;
          SwalHelper.showErrorSwal(err);
        });
      }
    }
  }

  /* Fn to fetch the details of all the organisation bank details
  * select the default customs & commercial bank account details with primary bank account
  */
  fetchBankDetails(): void {
    this.orgApi.getOrganisationBankAccount().subscribe(banks => {
      if (banks && banks.length > 0) {
        this.orgBanks = banks;
        let bankDetails;
        if (banks.filter(i => i.isPrimary).length > 0) {
          bankDetails = banks.filter(i => i.isPrimary)[0];
        } else {
          bankDetails = banks[0];
        }
        if (this.invoice.paymentInfo && !this.invoice.paymentInfo.isCancelled) {
          this.selectBankDetails(bankDetails);
        }
        if (this.invoice.customsPaymentInfo && !this.invoice.customsPaymentInfo.isCancelled) {
          this.selectCustomsBankDetails(bankDetails);
        }
      }
    }, err => {
      SwalHelper.showErrorSwal(err);
    });
  }

  /**
   * Fn to set the details of selected bank details for commercial invoice
   * @param bank Organisation bank details object
   */
  selectBankDetails(bank: OrganisationBankAccount): void {
    if (bank) {
      this.invoice.paymentInfo.bankId = bank.bankId;
      this.invoice.paymentInfo.bankName = bank.bankName;
      this.invoice.paymentInfo.accountNo = bank.accountNumber;
      this.invoice.paymentInfo.swiftCode = bank.swiftCode;
      this.invoice.paymentInfo.title = bank.accountTitle;
      this.invoice.paymentInfo.branchAddress = bank.branchAddress;
      this.invoice.paymentInfo.intermediaryBankName = bank.intermediaryBankName;
      this.invoice.paymentInfo.intermediarySwiftCode = bank.intermediarySwiftCode;
      this.invoice.paymentInfo.ifsc = bank.ifscCode;
      this.invoice.paymentInfo.isCancelled = true;
      this.invoice.paymentInfo.otherData = bank.otherData;
    } else {
      this.invoice.paymentInfo = {};
      this.invoice.paymentInfo.isCancelled = true;
    }
  }

  /**
   * Fn to set the details of selected bank details for customs invoice
   * @param bank Organisation bank details object
   */
  selectCustomsBankDetails(bank: OrganisationBankAccount): void {
    if (bank) {
      this.invoice.customsPaymentInfo.bankId = bank.bankId;
      this.invoice.customsPaymentInfo.bankName = bank.bankName;
      this.invoice.customsPaymentInfo.accountNo = bank.accountNumber;
      this.invoice.customsPaymentInfo.swiftCode = bank.swiftCode;
      this.invoice.customsPaymentInfo.title = bank.accountTitle;
      this.invoice.customsPaymentInfo.branchAddress = bank.branchAddress;
      this.invoice.customsPaymentInfo.intermediaryBankName = bank.intermediaryBankName;
      this.invoice.customsPaymentInfo.intermediarySwiftCode = bank.intermediarySwiftCode;
      this.invoice.customsPaymentInfo.ifsc = bank.ifscCode;
      this.invoice.customsPaymentInfo.otherData = bank.otherData;
      this.invoice.customsPaymentInfo.isCancelled = true;
    } else {
      this.invoice.customsPaymentInfo = {};
      this.invoice.customsPaymentInfo.isCancelled = true;
    }
  }

  /* Fn to redirect to preview page */
  redirectToPreview(): void {
    this.isRedirectToPreview = true;
  }

  /**
   * Fn to save the new item details into item master
   * @param form Angular form
   */
  saveItemDetails(form): boolean {
    if (form.valid) {
      this.shouldShowLoader = true;
      this.orgApi.addItemMaster(this.itemMasterDetail
      ).subscribe(item => {
        this.shouldShowLoader = false;
        this.invoice.InvoiceItems[this.customItemIndex].itemMasterId = item.itemMasterId;
        this.invoice.InvoiceItems[this.customItemIndex].itemCode = item.itemCode;
        this.invoice.InvoiceItems[this.customItemIndex].itemHSNCode = item.itemHSNCode;
        this.invoice.InvoiceItems[this.customItemIndex].itemName = item.itemName;
        this.invoice.InvoiceItems[this.customItemIndex].itemDescription = item.itemDescription;
        this.invoice.InvoiceItems[this.customItemIndex].itemCustomsDescription = item.itemDescription;
        this.invoice.InvoiceItems[this.customItemIndex].itemPackingListDescription = item.itemDescription;
        this.invoice.InvoiceItems[this.customItemIndex].itemCustPLDescription = item.itemDescription;
        this.invoice.InvoiceItems[this.customItemIndex].netWeight = item.netWeight;
        this.invoice.InvoiceItems[this.customItemIndex].custNetWeight = item.netWeight;
        this.invoice.InvoiceItems[this.customItemIndex].grossWeight = item.grossWeight;
        this.invoice.InvoiceItems[this.customItemIndex].custGrossWeight = item.grossWeight;
        this.invoice.InvoiceItems[this.customItemIndex].perUnitWeight = {
          netWeight: item.netWeight,
          grossWeight: item.grossWeight
        };
        this.invoice.InvoiceItems[this.customItemIndex].itemUnit = item.itemUnit;
        this.invoice.InvoiceItems[this.customItemIndex].customsItemUnit = item.itemUnit;
        this.invoice.InvoiceItems[this.customItemIndex].gst = 0;
        this.invoice.InvoiceItems[this.customItemIndex].itemQuantity = 1;
        this.invoice.InvoiceItems[this.customItemIndex].itemQuantityPerPack = 1;
        this.invoice.InvoiceItems[this.customItemIndex].itemCustQuantityPerPack = 1;
        this.invoice.InvoiceItems[this.customItemIndex].itemCustTotalQuantity = 1;
        this.invoice.InvoiceItems[this.customItemIndex].itemPrice = item.itemPrice;
        this.invoice.InvoiceItems[this.customItemIndex].customsItemPrice = item.itemPrice;
        this.itemMasterDetail = {};
        this.customItemIndex = undefined;
        $('#itemMasterModal').modal('hide');
        SwalHelper.successTimerSwal('Item Added!');
        this.rightSheetClose();
      }, err => {
        this.shouldShowLoader = false;
        SwalHelper.showErrorSwal(err);
      });
    } else {
      return false;
    }
  }

  /* Fn to link the Sales order and map the order items with invoice items*/
  linkSO(event): void {
    this.shouldShowLoader = true;
    this.orgOrderApi.linkSoInvoice(this.invoice.invId, this.salesOrderId).subscribe(mapping => {
      this.shouldShowLoader = false;
      if (mapping) {
        if (!this.invoice.OrderInvoiceMappings) {
          this.invoice.OrderInvoiceMappings = [];
        }
        const soItems = event.SalesOrderItems;
        soItems.map(i => this.invoice.InvoiceItems.push({
          itemCode: i.itemCode,
          itemHSNCode: i.itemHSNCode,
          // itemSubCode: i.itemSubCode,
          itemName: i.itemName,
          itemDescription: i.itemDescription,
          itemCustomsDescription: i.itemDescription,
          itemPackingListDescription: i.itemDescription,
          itemCustPLDescription: i.itemDescription,
          itemUnit: i.itemUnit,
          customsItemUnit: i.itemUnit,
          itemPrice: i.itemPrice,
          customsItemPrice: i.itemPrice,
          itemQuantity: i.remainingQuantity,
          itemCustTotalQuantity: i.remainingQuantity,
          itemMasterId: i.itemMasterId,
          salesOrderItemId: i.salesOrderItemId,
          salesOrderId: i.salesOrderId,
          gst: 0,
          advanceLicenseDetails: {},
          noOfPackages: 1,
          otherData: this.orgHelper.copyObject(this.enabledFields),
          perUnitWeight: {
            netWeight: i.ItemMaster ? i.ItemMaster.netWeight : 0,
            grossWeight: i.ItemMaster ? i.ItemMaster.grossWeight : 0
          },
          netWeight: parseFloat((i.remainingQuantity * (i.ItemMaster ? i.ItemMaster.netWeight : 0)).toFixed(3)),
          grossWeight: parseFloat((i.remainingQuantity * (i.ItemMaster ? i.ItemMaster.grossWeight : 0)).toFixed(3)),
          custNetWeight: parseFloat((i.remainingQuantity * (i.ItemMaster ? i.ItemMaster.netWeight : 0)).toFixed(3)),
          custGrossWeight: parseFloat((i.remainingQuantity * (i.ItemMaster ? i.ItemMaster.grossWeight : 0)).toFixed(3)),
          SalesOrder: event
        }));
        this.calSubTotalAmount();
        this.calCustomsSubTotalAmount();
        this.invoice.OrderInvoiceMappings.push(mapping);
        this.alreadyLinkedSOIds = this.invoice.OrderInvoiceMappings.map(i => i.salesOrderId);
        this.soChange$.next(undefined);
        this.salesOrderId = undefined;
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal('This order is already linked please select other order');
    });
  }

  /**
   * Fn to unlink the order from invoice
   * @param index Index of the order to unlink
   */
  unlinkSO(index: number): void {
    this.shouldShowLoader = true;
    this.orgOrderApi.unlinkSoInvoice(this.invoice.OrderInvoiceMappings[index].ordInvMapId).subscribe(ok => {
      this.shouldShowLoader = false;
      if (ok) {
        if (this.invoice.OrderInvoiceMappings[index].salesOrderId) {
          this.invoice.InvoiceItems = this.invoice.InvoiceItems.filter(i => i.salesOrderId != this.invoice.OrderInvoiceMappings[index].salesOrderId);
        }
        const ind = this.alreadyLinkedSOIds.findIndex(i => i.salesOrderId == this.invoice.OrderInvoiceMappings[index].salesOrderId);
        this.alreadyLinkedSOIds.splice(ind, 1);
        this.soChange$.next(undefined);
        this.invoice.OrderInvoiceMappings.splice(index, 1);
        this.calSubTotalAmount();
        this.calCustomsSubTotalAmount();
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /* fn to select the Buyer for invoice */
  selectBuyer(): void {
    if (this.invoice.buyerId == -1) {
      this.rightSheetOpen();
      this.newContactType = this.invoice.invoiceType === 'SALES' ? 'Buyer' : 'Seller';
      this.invoice.Buyer = {};
      this.selectedVendor = undefined;
    } else {
      const index = this.vendorSuppliers.findIndex(i => i.venSupId == this.invoice.buyerId);
      if (index >= 0) {
        this.buyerDetails = this.vendorSuppliers[index];
        this.invoice.Buyer = this.vendorSuppliers[index];
      }
    }
  }

  /* Fn to init new buyer */
  addNewBuyer(): void {
    this.rightSheetOpen();
    this.newContactType = 'Buyer';
    this.invoice.Buyer = {};
    this.selectedVendor = undefined;
    this.invoice.buyerId = -1;
  }

  /* fn to select the Consignee for commercial invoice */
  selectConsignee(): void {
    if (this.invoice.consigneeId == -1) {
      this.rightSheetOpen();
      this.newContactType = 'Consignee';
      this.invoice.Consignee = {};
      this.selectedVendor = undefined;
    } else {
      const index = this.vendorSuppliers.findIndex(i => i.venSupId == this.invoice.consigneeId);
      if (index >= 0) {
        this.consignee = this.vendorSuppliers[index];
        this.invoice.Consignee = this.vendorSuppliers[index];
      }
    }
  }

  /* fn to select the Notify party 1 for commercial invoice */
  selectNotify1(): void {
    if (this.invoice.notifyPartyId1 == -1) {
      this.rightSheetOpen();
      this.newContactType = 'Notify Party';
      this.invoice.NotifyParty1 = {};
      this.selectedVendor = undefined;
    } else {
      const index = this.vendorSuppliers.findIndex(i => i.venSupId == this.invoice.notifyPartyId1);
      if (index >= 0) {
        this.notifyParty1 = this.vendorSuppliers[index];
        this.invoice.NotifyParty1 = this.vendorSuppliers[index];
      }
    }
  }

  /* fn to select the Notify party 2 for commercial invoice */
  selectNotify2(): void {
    if (this.invoice.notifyPartyId2 == -1) {
      this.rightSheetOpen();
      this.newContactType = 'Notify Party';
      this.invoice.NotifyParty2 = {};
      this.selectedVendor = undefined;
    } else {
      const index = this.vendorSuppliers.findIndex(i => i.venSupId == this.invoice.notifyPartyId2);
      if (index >= 0) {
        this.notifyParty2 = this.vendorSuppliers[index];
        this.invoice.NotifyParty2 = this.vendorSuppliers[index];
      }
    }
  }

  /* fn to select the consignee for customs invoice */
  selectCustomsConsignee(): void {
    if (this.invoice.customsConsigneeId == -1) {
      this.rightSheetOpen();
      this.newContactType = 'Consignee';
      this.selectedVendor = undefined;
    } else {
      const index = this.vendorSuppliers.findIndex(i => i.venSupId == this.invoice.customsConsigneeId);
      if (index >= 0) {
        this.customsConsignee = this.vendorSuppliers[index];
      }
    }
  }

  /* fn to select the Notify party 1 for customs invoice */
  selectCustomsNotify1(): void {
    if (this.invoice.customsNotifyPartyId1 == -1) {
      this.rightSheetOpen();
      this.newContactType = 'Notify Party';
      this.selectedVendor = undefined;
    } else {
      const index = this.vendorSuppliers.findIndex(i => i.venSupId == this.invoice.customsNotifyPartyId1);
      if (index >= 0) {
        this.customsNotifyParty1 = this.vendorSuppliers[index];
      }
    }
  }

  /* fn to select the Notify party 2 for customs invoice */
  selectCustomsNotify2(): void {
    if (this.invoice.customsNotifyPartyId2 == -1) {
      this.rightSheetOpen();
      this.newContactType = 'Notify Party';
      this.selectedVendor = undefined;
    } else {
      const index = this.vendorSuppliers.findIndex(i => i.venSupId == this.invoice.customsNotifyPartyId2);
      if (index >= 0) {
        this.customsNotifyParty2 = this.vendorSuppliers[index];
      }
    }
  }

  /**
   * Fn to open the right sheet to edit the selected vendor details
   * @param id Id of the selected vendor
   * @param type Type of selected vendor
   */
  editVendor(id, type): void {
    this.selectedVendor = id;
    this.newContactType = type;
    this.rightSheetOpen();
  }

  loadData(index: number): void {
    this.invoice.ocrStatus = 'DONE';
    this.http.get(this.ocrResults[index].metadata).subscribe(data => {
      const dummyObj: any = data;
      this.selectedImageURL = this.ocrResults[index].background;
      this.thisPage = dummyObj.fullTextAnnotation.pages[0];
      this.bgwidth = this.thisPage.width;
      this.bgheight = this.thisPage.height;
      this.selectors = this.orgHelper.calBox(this.thisPage);
      this.changeDetectorRef.detectChanges();
    }, err => {
      SwalHelper.showErrorSwal(err);
    });
  }

  selectText(str): void {
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    const selected =
      document.getSelection().rangeCount > 0
        ? document.getSelection().getRangeAt(0)
        : false;
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    if (selected) {
      document.getSelection().removeAllRanges();
      document.getSelection().addRange(selected);
      this.copyText = 'Copied';
    }
  }

  resetCopytext(): void {
    this.copyText = 'Click to copy';
  }

  // Fn to open the Right sheet for vendor details
  rightSheetOpen(): void {
    this.rightSheet = !this.rightSheet;
    this.renderer.addClass(document.body, 'modal-open');
    this.commonService.addBackdrop();
  }

  /* Fn to open the upload bulk item bottom sheet */
  bottomSheetOpen(): void {
    this.bottomSheet = true;
    this.renderer.addClass(document.body, 'modal-open');
    this.commonService.addBackdrop();
  }

  /* Fn to close the upload bulk item bottom sheet */
  bottomSheetClose(): void {
    this.bottomSheet = false;
    this.renderer.removeClass(document.body, 'modal-open');
    this.commonService.removeBackdrop();
  }

  // Fn to open the Right sheet for vendor details
  rightSheetClose(): void {
    this.invoice.buyerId = this.invoice.buyerId > 0 ? this.invoice.buyerId : null;
    this.invoice.consigneeId = this.invoice.consigneeId > 0 ? this.invoice.consigneeId : null;
    this.invoice.notifyPartyId1 = this.invoice.notifyPartyId1 > 0 ? this.invoice.notifyPartyId1 : null;
    this.invoice.notifyPartyId2 = this.invoice.notifyPartyId2 > 0 ? this.invoice.notifyPartyId2 : null;
    this.invoice.customsConsigneeId = this.invoice.customsConsigneeId > 0 ? this.invoice.customsConsigneeId : null;
    this.invoice.customsNotifyPartyId1 = this.invoice.customsNotifyPartyId1 > 0 ? this.invoice.customsNotifyPartyId1 : null;
    this.invoice.customsNotifyPartyId2 = this.invoice.customsNotifyPartyId2 > 0 ? this.invoice.customsNotifyPartyId2 : null;
    this.rightSheet = false;
    this.renderer.removeClass(document.body, 'modal-open');
    this.commonService.removeBackdrop();
  }

  /* Fn to update the details of new/updated vendor details */
  newVendor(vendor): void {
    if (!this.vendorSuppliers) {
      this.vendorSuppliers = [];
    }
    const index = this.vendorSuppliers.findIndex(i => i.venSupId == vendor.venSupId);
    if (index >= 0) {
      this.vendorSuppliers[index] = vendor;
    } else {
      this.vendorSuppliers.push(vendor);
    }

    this.invoice.consigneeId = this.invoice.consigneeId == -1 ? parseInt(vendor.venSupId, 10) : this.invoice.consigneeId;
    this.invoice.notifyPartyId1 = this.invoice.notifyPartyId1 == -1 ? parseInt(vendor.venSupId, 10) : this.invoice.notifyPartyId1;
    this.invoice.notifyPartyId2 = this.invoice.notifyPartyId2 == -1 ? parseInt(vendor.venSupId, 10) : this.invoice.notifyPartyId2;

    this.invoice.customsConsigneeId = this.invoice.customsConsigneeId == -1 ? parseInt(vendor.venSupId, 10) : this.invoice.customsConsigneeId;
    this.invoice.customsNotifyPartyId1 = this.invoice.customsNotifyPartyId1 == -1 ? parseInt(vendor.venSupId, 10) : this.invoice.customsNotifyPartyId1;
    this.invoice.customsNotifyPartyId2 = this.invoice.customsNotifyPartyId2 == -1 ? parseInt(vendor.venSupId, 10) : this.invoice.customsNotifyPartyId2;

    if (this.invoice.buyerId == -1) {
      this.invoice.buyerId = parseInt(vendor.venSupId, 10);
      this.selectBuyer();
    }
    this.rightSheetClose();
    this.selectConsignee();
    this.selectNotify1();
    this.selectNotify2();

    this.selectCustomsConsignee();
    this.selectCustomsNotify1();
    this.selectCustomsNotify2();
    this.selectedVendor = undefined;
  }

  captureInvoiceFile(event): void {
    if (event.target.files.length + this.invoiceFiles.length > 5) {
      SwalHelper.showErrorSwal('Maximum 5 files allowed');
      return;
    }
    if (event.target.files && event.target.files.length > 0) {
      Object.keys(event.target.files).forEach((key) => {
        this.invoiceFiles.push(event.target.files[key]);
      });
    }
  }

  /* Fn to upload the new attachment */
  uplaodNewDocument(): void {
    this.shouldShowLoader = true;
    this.orgApi.uploadDocumentsAttachment(
      this.invoiceFiles[0],
      this.invoice.invId,
      undefined,
      undefined,
      undefined,
      this.invoiceFiles[1],
      this.invoiceFiles[2],
      this.invoiceFiles[3],
      this.invoiceFiles[4]
    ).subscribe(docs => {
      if (docs) {
        this.shouldShowLoader = false;
        SwalHelper.successTimerSwal('Document uploaded');
        $('#fileUploadModal').modal('hide');
        if (!this.invoice.DocumentAttachments) {
          this.invoice.DocumentAttachments = [];
        }
        this.invoice.DocumentAttachments = docs;
        this.invoiceFiles = []; // Clean the document file array
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /**
   * Fn to remove the attachment
   * @param index index of the attachment to be deleted
   */
  removeAttchment(index: number): void {
    Swal({
      title: 'Delete Attachment',
      text: 'This cannot be undone',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        this.shouldShowLoader = true;
        return new Promise((resolve, reject) => {
          this.orgApi.deleteDocumentsAttachment(
            this.invoice.DocumentAttachments[index].docAttId,
            this.invoice.invId,
            undefined,
          ).subscribe(ok => {
            this.shouldShowLoader = false;
            this.invoice.DocumentAttachments.splice(index, 1);
            resolve(ok);
          }, err => {
            reject(err);
          });
        });
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.value) {
        this.shouldShowLoader = false;
        SwalHelper.successTimerSwal('Attachment deleted!');
      }
    }).catch(err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /**
   * fn to download the attachment
   * @param docAttId document attachment id to be downloaded
   */
  public downloadFile(docAttId: number): void {
    this.orgApi.orgDocumentSignedUrl(docAttId)
      .subscribe(url => {
        window.open(url.url, '_blank');
      });
  }

  /* Fn to close the item master detail modal &b reset the data of item master field */
  closeItemMasterModal(): void {
    this.itemMasterDetail = {};
    $('#itemMasterModal').modal('hide');
    this.invoice.InvoiceItems[this.customItemIndex].itemCode = undefined;
    this.customItemIndex = undefined;
  }

  trackByVendId(index: number, vendor: any): string {
    return vendor.venSupId;
  }

  trackByItem(index, item: InvoiceItem): string {
    return index;
  }
  /**
   * Fn to copy the amount in words
   * @param amount Amount to be copied
   * @param currency currency to be converted into
   */
  copyNumberToWord(amount, currency): void {
    const pipe = new NumberToWordsPipe();
    const fiteredArr = pipe.transform(amount, currency);

    const el = document.createElement('textarea');
    el.value = fiteredArr;
    el.setAttribute('readonly', '');
    document.body.appendChild(el);
    const selected =
      document.getSelection().rangeCount > 0
        ? document.getSelection().getRangeAt(0)
        : false;
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    if (selected) {
      document.getSelection().removeAllRanges();
      document.getSelection().addRange(selected);
      this.copyText = 'Copied';
      SwalHelper.showCenteredSavedToast('Copied');
    }
  }

  /* Fn to copy UOM, Unit Price & Total Qty from Customs Inv */
  copyItemsFromCustomsInv(): void {
    Swal({
      title: 'Copy items from Custom Invoice?',
      text: 'Are you sure you want to copy items  from Customs Invoice',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.value) {
        this.invoice.InvoiceItems.forEach((i, index) => {
          this.invoice.InvoiceItems[index].itemUnit = i.customsItemUnit;
          this.invoice.InvoiceItems[index].itemPrice = i.customsItemPrice;
          this.invoice.InvoiceItems[index].itemQuantity = i.itemCustTotalQuantity;
          this.invoice.InvoiceItems[index].itemQuantityPerPack = i.itemCustQuantityPerPack;
        });
        this.calSubTotalAmount();
      }
    }).catch(err => {
      SwalHelper.showErrorSwal(err);
    });
  }
  /* Fn to copy UOM, Unit Price & Total Qty from Commercial Inv */
  copyItemsFromCommercialInv(): void {
    Swal({
      title: 'Copy items from Commercial Invoice?',
      text: 'Are you sure you want to copy items from Commercial Invoice',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.value) {
        this.invoice.InvoiceItems.forEach((i, index) => {
          this.invoice.InvoiceItems[index].customsItemUnit = i.itemUnit;
          this.invoice.InvoiceItems[index].customsItemPrice = i.itemPrice;
          this.invoice.InvoiceItems[index].itemCustTotalQuantity = i.itemQuantity;
          this.invoice.InvoiceItems[index].itemCustQuantityPerPack = i.itemQuantityPerPack;
        });
        this.calCustomsSubTotalAmount();
      }
    }).catch(err => {
      SwalHelper.showErrorSwal(err);
    });
  }
  /* Fn to copy UOM, net weight, gross weight & Total Qty from Customs Inv */
  copyItemsFromCustomsPL(): void {
    Swal({
      title: 'Copy items from Customs PL?',
      text: 'Are you sure you want to copy items from Customs Packing List',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.value) {
        this.invoice.InvoiceItems.forEach((i, index) => {
          this.invoice.InvoiceItems[index].itemUnit = i.customsItemUnit;
          this.invoice.InvoiceItems[index].itemQuantity = i.itemCustTotalQuantity;
          this.invoice.InvoiceItems[index].netWeight = i.custNetWeight;
          this.invoice.InvoiceItems[index].grossWeight = i.custGrossWeight;
        });
        this.calTotalNetWeight();
        this.calTotalGrossWeight();
      }
    }).catch(err => {
      SwalHelper.showErrorSwal(err);
    });
  }
  /* Fn to copy UOM, net weight, gross weight & Total Qty from Commercial Inv */
  copyItemsFromCommercialPL(): void {
    Swal({
      title: 'Copy items from Commercial PL?',
      text: 'Are you sure you want to copy items from Commercial Packing List',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.value) {
        this.invoice.InvoiceItems.forEach((i, index) => {
          this.invoice.InvoiceItems[index].customsItemUnit = i.itemUnit;
          this.invoice.InvoiceItems[index].itemCustTotalQuantity = i.itemQuantity;
          this.invoice.InvoiceItems[index].custNetWeight = i.netWeight;
          this.invoice.InvoiceItems[index].custGrossWeight = i.grossWeight;
        });
        this.calCustomsTotalNetWeight();
        this.calCustomsTotalGrossWeight();
      }
    }).catch(err => {
      SwalHelper.showErrorSwal(err);
    });
  }
}

export class AdvancedLicense {
  public licenseId?: number;
  public licenseType?: string;
  public expOblExpiryDate?: string;
  public exportItem?: any;
  public itemIndex?: number;
  public LicenseItems?: any;
  public licenseNo?: string;
  public type?: string;
  public isEPCG?: boolean;
  public dateOfIssue?: string;
  public fileNo?: string;
  public regionalAuthority?: string;
}
