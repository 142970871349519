import { Component, OnInit } from '@angular/core';
import { SwalHelper } from 'src/app/swal';
import { OrganisationService } from 'src/swagger';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { GlobalProfileService } from 'src/app/global-profile.service';

@Component({
  selector: 'app-edit-template',
  templateUrl: './edit-template.component.html',
  styleUrls: ['./edit-template.component.scss']
})
export class EditTemplateComponent implements OnInit {

  public shouldShowLoader = false;

  public outputHTML: any;
  public basePath: string;

  public colorValue = '1C242A';
  public fontValue = 'Nunito';

  public templateType: string;
  public templateId: number;
  public templateData: {};

  constructor(
    public http: HttpClient,
    private activatedRoute: ActivatedRoute,
    private orgApi: OrganisationService,
    private profileSync: GlobalProfileService
  ) {
    this.basePath = environment.BASE_PATH;
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(param => {
      if (param.id && param.type) {
        this.templateType = param.type;
        this.templateId = param.id;
      }
    });
    this.profileSync.user$.subscribe(user => {
      if (user && user.Organisation && user.Organisation.templateData) {
        this.templateData = user.Organisation.templateData;
        this.extractData();
      }
      this.fetchTemplate();
    });
  }

  /* Fn to extract the font & color value */
  extractData(): void {
    if (this.templateType === 'PurchaseOrder') {
      this.colorValue = this.templateData[this.templateType][`po${this.templateId}`].colorValue;
      this.fontValue = this.templateData[this.templateType][`po${this.templateId}`].fontValue;
    } else if (this.templateType === 'SalesOrder') {
      this.colorValue = this.templateData[this.templateType][`so${this.templateId}`].colorValue;
      this.fontValue = this.templateData[this.templateType][`so${this.templateId}`].fontValue;
    } else if (this.templateType === 'VendorBill') {
      this.colorValue = this.templateData[this.templateType][`vb${this.templateId}`].colorValue;
      this.fontValue = this.templateData[this.templateType][`vb${this.templateId}`].fontValue;
    } else {
      this.colorValue = this.templateData[this.templateType][this.templateId].colorValue;
      this.fontValue = this.templateData[this.templateType][this.templateId].fontValue;
    }
  }


  /* Fn to fetch the template */
  fetchTemplate(): void {
    const headers = new HttpHeaders().set('Authorization', this.orgApi.configuration.apiKeys.Authorization);
    this.shouldShowLoader = true;
    this.http.post(`${this.basePath}/organisation/templates/${this.templateType}/${this.templateId}`, {
      colorValue: this.colorValue,
      fontValue: this.fontValue
    }, {
      responseType: 'text',
      headers,
      observe: 'body'
    }).subscribe(data => {
      this.shouldShowLoader = false;
      this.outputHTML = data;
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /* Fn to set the font & color value */
  setData(): void {
    if (this.templateType === 'PurchaseOrder') {
      this.templateData[this.templateType][`po${this.templateId}`].colorValue = this.colorValue;
      this.templateData[this.templateType][`po${this.templateId}`].fontValue = this.fontValue;
    } else if (this.templateType === 'SalesOrder') {
      this.templateData[this.templateType][`so${this.templateId}`].colorValue = this.colorValue;
      this.templateData[this.templateType][`so${this.templateId}`].fontValue = this.fontValue;
    } else if (this.templateType === 'VendorBill') {
      this.templateData[this.templateType][`vb${this.templateId}`].colorValue = this.colorValue;
      this.templateData[this.templateType][`vb${this.templateId}`].fontValue = this.fontValue;
    } else {
      this.templateData[this.templateType][this.templateId].colorValue = this.colorValue;
      this.templateData[this.templateType][this.templateId].fontValue = this.fontValue;
    }
  }

  /* Fn to update the template */
  applyTheme(): void {
    this.shouldShowLoader = true;
    this.setData();
    this.orgApi.updateTemplateMaster(
      JSON.stringify(this.templateData)
    ).subscribe(org => {
      this.shouldShowLoader = false;
      if (org) {
        this.profileSync.user.Organisation.templateData = org.templateData;
        this.fetchTemplate();
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /* Fn to reset the filter */
  resetData(): void {
    this.colorValue = '1C242A';
    this.fontValue = 'Nunito';
    this.applyTheme();
  }
}
