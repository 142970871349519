import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { GlobalProfileService } from 'src/app/global-profile.service';
import { SwalHelper } from 'src/app/swal';
import { Invoice, OrganisationService, ReportsService, Shipment, VendorAndSupplier } from 'src/swagger';
import { OrganisationHelperService } from '../../organisation-helper.service';
import { CommonSupportService } from '../../../common-support.service';

@Component({
  selector: 'app-shipment-report',
  templateUrl: './shipment-report.component.html',
  styleUrls: ['./shipment-report.component.scss']
})
export class ShipmentReportComponent implements OnInit {

  public allShipments: Shipment[] = [];
  public shouldShowLoader: boolean;

  public fromDate: string;
  public toDate: string;
  public status: string;
  public currency: string;
  public shipmentType: string;

  public invoiceType: string;

  public allVendors: VendorAndSupplier[] = [];
  public vendorId: number;

  dtOptions: any = {};

  public allInvAmount: Invoice[] = [];

  constructor(
    private reportApi: ReportsService,
    public orgHelper: OrganisationHelperService,
    public profileSync: GlobalProfileService,
    public commonService: CommonSupportService,
    private orgApi: OrganisationService,
  ) { }

  ngOnInit(): void {
    this.dtOptions = {
      dom: 'Bfrtip',
      lengthMenu: [
        [10, 25, 50, -1],
        ['10', '25', '50', 'All']
      ],
      buttons: [
        'pageLength',
        {
          text: 'Excel Download',
          className: 'btn btn-green',
          extend: 'excelHtml5',
          title: 'shipment-report',
        }
      ],
      columnDefs: [
        { targets: 3, type: 'num-fmt' },
      ],
    };
    this.resetFilter();
    this.fetchAllVendors();
  }

  /* Fn to fetch the shipment report */
  fetchReport(): void {
    this.allShipments = [];

    if (this.shipmentType !== 'ALL') {
      this.invoiceType = this.shipmentType === 'SELL' ? 'SALES' : 'PURCHASE';
      this.fetchInvTotal();
    } else {
      this.allInvAmount = [];
    }

    this.shouldShowLoader = true;
    this.reportApi.shipmentReport(
      this.shipmentType === 'ALL' ? undefined : this.shipmentType,
      this.toDate ? moment(this.toDate).format('YYYY-MM-DD') : null,
      this.fromDate ? moment(this.fromDate).format('YYYY-MM-DD') : null,
      this.status === 'All' ? undefined : this.status,
      this.currency === 'All' ? undefined : this.currency,
      undefined,
      this.vendorId  == 0 ? undefined : this.vendorId,
    ).subscribe(invoices => {
      this.shouldShowLoader = false;
      if (invoices && invoices.length > 0) {
        this.allShipments = invoices;
      } else {
        this.allShipments = null;
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /* Fn to reset the filter */
  resetFilter(): void {
    this.toDate = undefined;
    this.fromDate = undefined;
    this.status = 'All';
    this.currency = 'All';
    this.shipmentType = 'SELL';
    this.invoiceType = 'SALES';
    this.vendorId = 0;
    this.fetchReport();
  }

  /* Function to fetch total amount in band*/
  fetchInvTotal(): void {
    this.shouldShowLoader = true;
    this.reportApi.totalInvAmountReport(
      this.invoiceType,
      undefined,
      this.shipmentType === 'ALL' ? undefined : this.shipmentType,
    ).subscribe(pos => {
      this.shouldShowLoader = false;
      if (pos && pos.length > 0) {
        this.allInvAmount = pos;
      } else {
        this.allInvAmount = null;
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /* Fn to fetch all the vendors in filter */
  fetchAllVendors(): void {
    this.shouldShowLoader = true;
    this.orgApi.searchVendors(
      undefined,
      undefined,
      100
    ).subscribe(vendors => {
      this.shouldShowLoader = false;
      if (vendors && vendors.length > 0) {
        this.allVendors = vendors;
      } else {
        this.allVendors = null;
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

}
