import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { GlobalProfileService } from 'src/app/global-profile.service';
import { SwalHelper } from 'src/app/swal';
import { Invoice, OrgInvoiceService, PaymentSchedule } from 'src/swagger';
import { OrganisationHelperService } from '../../organisation-helper.service';
declare var swal: any;

@Component({
  selector: 'app-payment-schedule',
  templateUrl: './payment-schedule.component.html',
  styleUrls: ['./payment-schedule.component.scss']
})
export class PaymentScheduleComponent implements OnInit, OnChanges, OnDestroy {

  public allPayments: PaymentSchedule[] = [];
  public newPaymentSchedule: PaymentSchedule = {};

  public shouldShowLoader: boolean;
  @Input() invDetails: Invoice;

  public remainingAmount = 0;
  private userSubscription: Subscription;

  constructor(
    private orgInvApi: OrgInvoiceService,
    private orgHelper: OrganisationHelperService,
    private profileSync: GlobalProfileService
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    if (this.invDetails.invId) {
      this.userSubscription =  this.profileSync.user$.subscribe(user => {
        if (user && user.isInternal) {
          this.fetchAllSchedules();
        }
      });
    }
  }
  ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }
  // Fetch all the payment schedule
  fetchAllSchedules(): void {
    this.shouldShowLoader = true;
    this.orgInvApi.listPaymentSchedule(
      this.invDetails.invId
    ).subscribe(schedules => {
      this.shouldShowLoader = false;
      if (schedules && schedules.length > 0) {
        this.allPayments = schedules;
      } else {
        this.allPayments = [];
      }
      this.initPaymentSchedule();
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }
  // Delete payment schedule
  deleteSchedule(index: number): void {
    swal({
      title: 'Delete Payment Schedule',
      text: 'This cannot be undone',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        this.shouldShowLoader = true;
        return new Promise((resolve, reject) => {
          this.orgInvApi.deletePaymentSchedule(this.allPayments[index].paymentScheduleId).subscribe(ok => {
            this.shouldShowLoader = false;
            this.allPayments.splice(index, 1);
            this.initPaymentSchedule();
            resolve(ok);
          }, err => {
            reject(err);
          });
        });
      },
      allowOutsideClick: () => !swal.isLoading()
    }).then((result) => {
      if (result.value) {
        this.shouldShowLoader = false;
        SwalHelper.successTimerSwal('Schedule deleted!');
      }
    }).catch(err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }
  // Fn to copy the schedule for edit
  editSchedule(i): void {
    this.newPaymentSchedule = this.orgHelper.copyObject(this.allPayments[i]);
    this.remainingAmount = this.remainingAmount + this.newPaymentSchedule.expAmount;
  }
  // Add or update Schedule
  saveSchedule(): void {
    this.shouldShowLoader = true;
    this.newPaymentSchedule.invId = this.invDetails.invId;
    this.newPaymentSchedule.currency = this.invDetails.currency;
    this.newPaymentSchedule.expDateOfPayment = this.newPaymentSchedule.expDateOfPayment ? moment(this.newPaymentSchedule.expDateOfPayment).format('YYYY-MM-DD') : null;
    if (this.newPaymentSchedule.paymentScheduleId) {
      this.orgInvApi.updatePaymentSchedule(
        this.newPaymentSchedule
      ).subscribe(updatedPayment => {
        this.shouldShowLoader = false;
        const index = this.allPayments.findIndex(i => i.paymentScheduleId === updatedPayment.paymentScheduleId);
        this.allPayments[index] = updatedPayment;
        this.orgHelper.updateSchedule(updatedPayment);
        SwalHelper.successTimerSwal('Schedule Updated');
        this.initPaymentSchedule();
      }, err => {
        this.shouldShowLoader = false;
        SwalHelper.showErrorSwal(err);
      });
    } else {
      this.orgInvApi.createPaymentSchedule(
        this.newPaymentSchedule
      ).subscribe(newPayment => {
        this.shouldShowLoader = false;
        this.allPayments.push(newPayment);
        // this.allPayments.sort((date1, date2) => date2.expDateOfPayment - date1.expDateOfPayment)
        this.orgHelper.updateSchedule(newPayment);
        SwalHelper.successTimerSwal('Schedule Created');
        this.initPaymentSchedule();
      }, err => {
        this.shouldShowLoader = false;
        SwalHelper.showErrorSwal(err);
      });
    }
  }
  // Fn to initialize the new payment schedule
  initPaymentSchedule(): void {
    this.newPaymentSchedule = {};
    this.remainingAmount = this.invDetails.totalAmount - this.allPayments.map(i => i.expAmount).reduce((a, b) => a + b, 0);
    this.newPaymentSchedule.expAmount = this.remainingAmount;
    this.newPaymentSchedule.expDateOfPayment = this.invDetails.dueDate;
  }

}
