import { SwalHelper } from './../../../swal';
import { OrganisationHelperService } from './../../organisation-helper.service';
import { Component, OnInit } from '@angular/core';
import { GlobalProfileService } from 'src/app/global-profile.service';
import { OrganisationService, Organisation } from 'src/swagger';

@Component({
  selector: 'app-mandatory-fields',
  templateUrl: './mandatory-fields.component.html',
  styleUrls: ['./mandatory-fields.component.scss']
})
export class MandatoryFieldsComponent implements OnInit {

  public orgProfile: Organisation = {};
  public shouldShowLoader: boolean;

  constructor(
    public profileSync: GlobalProfileService,
    public orgHelper: OrganisationHelperService,
    private orgApi: OrganisationService,
  ) { }

  ngOnInit(): void {
    this.profileSync.user$.subscribe(user => {
      if (user && user.Organisation) {
        this.orgProfile = this.orgHelper.copyObject(user.Organisation);
      }
    });
  }

   /* Fn to update the organisation profile*/
   updateOrgProfile(): void {
    this.shouldShowLoader = true;
    this.orgApi.updateOrganisationProfile(this.orgProfile)
      .subscribe(orgProfile => {
        this.shouldShowLoader = false;
        this.profileSync.user.Organisation = orgProfile;
        SwalHelper.successTimerSwal('Mandatory Fields Updated');
      }, err => {
        this.shouldShowLoader = false;
        SwalHelper.showErrorSwal(err);
      });
  }
}
