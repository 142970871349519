export const roleTemplate = {
    PO: {
      create: false,
      read: false,
      update: false,
      delete: false,
      share: false,
    },
    SO: {
      create: false,
      read: false,
      update: false,
      delete: false,
      share: false,
    },
    Invoice: {
      create: false,
      read: false,
      update: false,
      delete: false,
      share: false,
    },
    ShippingBill: {
      create: false,
      read: false,
      update: false,
      delete: false,
      share: false,
    },
    BillOfEntry: {
      create: false,
      read: false,
      update: false,
      delete: false,
      share: false,
    },
    BillOfLading: {
      create: false,
      read: false,
      update: false,
      delete: false,
      share: false,
    },
    PackingList: {
      create: false,
      read: false,
      update: false,
      delete: false,
      share: false,
    },
    COO: {
      create: false,
      read: false,
      update: false,
      delete: false,
      share: false,
    },
    QAC: {
      create: false,
      read: false,
      update: false,
      delete: false,
      share: false,
    },
    VoyageInsurance: {
      create: false,
      read: false,
      update: false,
      delete: false,
      share: false,
    },
    Shipment: {
      create: false,
      read: false,
      write: false,
      delete: false,
      share: false,
    },
    eBRC: {
      create: false,
      read: false,
      update: false,
      delete: false,
      share: false,
    },
    ItemMaster: {
      create: false,
      read: false,
      update: false,
      delete: false,
      share: false,
    },
    OrganisationRole: {
      create: false,
      read: false,
      update: false,
      delete: false,
      share: false,
    },
    OrganisationUser: {
      create: false,
      read: false,
      update: false,
      delete: false,
      share: false,
    },
    OrganisationContact: {
      create: false,
      read: false,
      write: false,
      delete: false,
      share: false,
    },
    OtherDocument: {
      create: false,
      read: false,
      write: false,
      delete: false,
      share: false,
    },
    Reports: {
      create: false,
      read: false,
      write: false,
      delete: false,
      share: false,
    },
  };
