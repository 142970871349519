import { Component, OnInit, EventEmitter, Output, Input, OnChanges, OnDestroy } from '@angular/core';
import { OrganisationContact, OrganisationService, VendorAndSupplier, TagMaster } from 'src/swagger';
import { ActivatedRoute } from '@angular/router';
import { SwalHelper } from 'src/app/swal';
import { of, Subject, Subscription } from 'rxjs';
import { catchError, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { CommonSupportService } from 'src/app/common-support.service';
import { NgForm } from '@angular/forms';
declare var $: any;
@Component({
  selector: 'app-organisation-contacts-detail',
  templateUrl: './organisation-contacts-detail.component.html',
  styleUrls: ['./organisation-contacts-detail.component.scss']
})
export class OrganisationContactsDetailComponent implements OnInit, OnChanges, OnDestroy {

  public contactDetails: OrganisationContact = {};
  public shouldShowLoader: boolean;
  public allMasterTags: TagMaster[] = [];

  public vendorCustomerChange$: Subject<string> = new Subject();
  public listvendorCustomer$: Subject<VendorAndSupplier[]> = new Subject();
  private vendorChangeSubscription: Subscription;
  public vendorLoading = false;

  @Output() closeOrgContactToggle = new EventEmitter<OrganisationContact>();
  @Input() orgContactId: number;

  public newTags = [];

  constructor(
    private orgApi: OrganisationService,
    private activatedRoute: ActivatedRoute,
    public commonService: CommonSupportService
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(param => {
      if (param && param.contactId) {
        this.getContactDetails(param.contactId);
      }
    });

    this.vendorChangeSubscription = this.vendorCustomerChange$.pipe(
      distinctUntilChanged(),
      tap(() => this.vendorLoading = true),
      switchMap(query => this.orgApi.searchVendors(
        query
      ).pipe(
        catchError(() => of([])), // empty list on error
        tap(() => {
          this.vendorLoading = false;
        })
      ))
    ).subscribe(items => {
      this.listvendorCustomer$.next(items);
    });
  }

  ngOnChanges(): void {
    if (this.orgContactId) {
      this.getContactDetails(this.orgContactId);
    } else {
      this.initContact();
    }
    this.vendorCustomerChange$.next(undefined);
    this.fetchTags('Contact User');
  }

  ngOnDestroy(): void {
    if (this.vendorChangeSubscription) {
      this.vendorChangeSubscription.unsubscribe();
    }
  }

  /**
   * Fn to fetch all the tags of req tag type
   * @param tagType Type of tag to find
   */
  public fetchTags(tagType: string): void {
    this.orgApi.listTagMaster(tagType).subscribe(tags => {
      if (tags && tags.length > 0) {
        this.allMasterTags = tags;
      } else {
        this.allMasterTags = [];
      }
    }, err => {
      SwalHelper.showErrorSwal(err);
    });
  }

  /* Fn to add the new tag in master */
  public addTagToMaster(tagObject): void {
    this.orgApi.addTagMaster(
      tagObject
    ).subscribe(tag => {
      this.allMasterTags.push(tag);
    }, err => {
      SwalHelper.showErrorSwal(err);
    });
  }

  /* Fn to set the vendor details */
  setVendId(event): void {
    this.contactDetails.venSupId = event.venSupId;
    this.contactDetails.contactType = event.type;
  }

  /* Fn to init the new contact */
  initContact(): void {
    this.contactDetails = {};
    this.contactDetails.tags = [];
  }

  checkTag(event): void {
    this.newTags = event.filter(i => !i.tagMasterId);
  }

  addNewVendor(): void {
    $('#contactAddModal').modal({
      show: true,
      backdrop: false
    });
  }

  newVendor(vendor): void {
    $('#contactAddModal').modal('hide');
    this.contactDetails.venSupId = vendor.venSupId;
    this.contactDetails.companyName = vendor.companyName;
    this.contactDetails.contactType = vendor.type;
  }

  /**
   * Fn to get the details of external contact
   * @param contactId Primary key of external contact
   */
  getContactDetails(contactId: number): void {
    this.shouldShowLoader = true;
    this.orgApi.orgContactDetail(contactId).subscribe(contact => {
      this.shouldShowLoader = false;
      if (contact) {
        this.contactDetails = contact;
        this.vendorCustomerChange$.next(this.contactDetails.companyName);
        if (!this.contactDetails.tags) {
          this.contactDetails.tags = [];
        }
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /**
   * Fn to save the details of external contact
   * @param form Angular form
   */
  saveContactDetails(form: NgForm): boolean {
    if (form.valid) {
      this.shouldShowLoader = true;
      this.createNewTags();
      if (this.contactDetails.orgContactId) {
        this.orgApi.updateOrgContact(this.contactDetails).subscribe(contact => {
          this.shouldShowLoader = false;
          this.closeOrgContactToggle.next(contact);
          SwalHelper.successTimerSwal('Business Contact Updated!');
          this.contactDetails = {};
          this.contactDetails.tags = [];
        }, err => {
          this.shouldShowLoader = false;
          SwalHelper.showErrorSwal(err);
        });
      } else {
        this.orgApi.addOrgContact(this.contactDetails).subscribe(contact => {
          this.shouldShowLoader = false;
          this.closeOrgContactToggle.next(contact);
          SwalHelper.successTimerSwal('Business Contact Added!');
          this.contactDetails = {};
          this.contactDetails.tags = [];
        }, err => {
          this.shouldShowLoader = false;
          SwalHelper.showErrorSwal(err);
        });
      }
    } else {
      return false;
    }
  }

  /* Fn to create new tag */
  createNewTags(): void {
    if (this.newTags && this.newTags.length > 0) {
      this.newTags.forEach(i => {
        const newTag: TagMaster = {};
        newTag.tagName = i.tagName;
        newTag.tagDescription = 'Contact User';
        this.addTagToMaster(newTag);
      });
    }
  }
}
