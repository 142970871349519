import { Component, OnInit, Renderer2, HostListener } from '@angular/core';
import { OrganisationService, Organisation, OrganisationBankAccount } from 'src/swagger';
import { CommonSupportService } from '../../common-support.service';
import { GlobalProfileService } from 'src/app/global-profile.service';
import { SwalHelper } from 'src/app/swal';
import { OrganisationHelperService } from '../organisation-helper.service';

declare var swal: any;

@Component({
  selector: 'app-organisation-profile',
  templateUrl: './organisation-profile.component.html',
  styleUrls: ['./organisation-profile.component.scss']
})
export class OrganisationProfileComponent implements OnInit {

  public orgProfile: Organisation = null;
  public bankAccList: OrganisationBankAccount[] = [];
  public shouldShowLoader = false;
  public rightSheet = false;
  public selectedBank: OrganisationBankAccount;
  public bankSheet = false;

  constructor(
    public orgApi: OrganisationService,
    private renderer: Renderer2,
    private commonService: CommonSupportService,
    public profileSync: GlobalProfileService,
    public orgHelper: OrganisationHelperService
  ) {
    commonService.renderer = renderer;
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent): void {
    this.rightSheet = false;
    this.bankSheet = false;
    this.commonService.removeBackdrop();
    this.renderer.removeClass(document.body, 'modal-open');
  }

  ngOnInit(): void {
    this.profileSync.user$.subscribe(user => {
      if (user && user.Organisation) {
        this.orgProfile = user.Organisation;
      }
    });
    this.fetchBankAccs();
  }

  /* Fn to fetch all the organisation banks */
  fetchBankAccs(): void {
    this.shouldShowLoader = true;
    this.orgApi.getOrganisationBankAccount()
      .subscribe(accs => {
        const index = accs.findIndex(acc => acc.isPrimary === true);
        if (index >= 0) {
          accs.unshift(accs.splice(index, 1)[0]);
        }
        this.bankAccList = accs;
        this.shouldShowLoader = false;
      }, err => {
        this.shouldShowLoader = false;
        SwalHelper.showErrorSwal(err);
      });
  }

  /**
   * FN to copy the data of bank details to be updated & open the rght sheet
   * @param i Index of bank details to be updated
   */
  editBankDetails(i: number): void {
    if (i === -1) {
      this.selectedBank = { currency: 'USD', otherData: {} };
    } else {
      this.selectedBank = this.orgHelper.copyObject(this.bankAccList[i]);
      this.selectedBank.otherData = this.selectedBank.otherData ? this.selectedBank.otherData : {};
    }
    this.bankSheet = true;
    this.renderer.addClass(document.body, 'modal-open');
    this.commonService.addBackdrop();
  }

  /**
   * Fn to save the details of organisation bank details
   * @param form Angular form
   */
  saveBankDetails(form): void {
    if (form.valid) {
      this.shouldShowLoader = true;
      if (this.selectedBank.bankId) {
        this.orgApi.updateOrganisationBankAccount(this.selectedBank)
          .subscribe(ok => {
            this.fetchBankAccs();
            this.rightSheetClose();
            this.shouldShowLoader = false;
            SwalHelper.successTimerSwal('Bank details saved!');
          }, err => {
            this.shouldShowLoader = false;
            SwalHelper.showErrorSwal(err);
          });
      } else {
        this.orgApi.createOrganisationBankAccount(this.selectedBank)
          .subscribe(ok => {
            this.fetchBankAccs();
            this.rightSheetClose();
            this.shouldShowLoader = false;
          }, err => {
            this.shouldShowLoader = false;
            SwalHelper.showErrorSwal(err);
          });
      }
    }
  }

  /**
   * Fn to delete the details of organisation bank
   * @param index Index of bank account
   */
  deleteBankAcc(index: number): void {
    swal({
      title: 'Delete Bank Account?',
      text: 'This cannot be undone',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        this.shouldShowLoader = true;
        return new Promise((resolve, reject) => {
          this.orgApi.deleteOrganisationBankAccount(this.bankAccList[index].bankId)
            .subscribe(ok => {
              this.bankAccList.splice(index, 1);
              this.shouldShowLoader = false;
              resolve(ok);
            }, err => {
              reject(err);
            });
        });
      },
      allowOutsideClick: () => !swal.isLoading()
    }).then((result) => {
      if (result.value) {
        this.shouldShowLoader = false;
        SwalHelper.successTimerSwal('Bank Account deleted!');
      }
    }).catch(err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /* Fn to open the right sheet for organisation profile details */
  rightSheetOpen(): void {
    this.rightSheet = !this.rightSheet;
    this.renderer.addClass(document.body, 'modal-open');
    this.commonService.addBackdrop();
  }

  /* Fn to close the right sheet for organisation profile details */
  rightSheetClose(): void {
    this.rightSheet = false;
    this.bankSheet = false;
    this.renderer.removeClass(document.body, 'modal-open');
    this.commonService.removeBackdrop();
  }

  /**
   * Fn to update the details of organisation
   * @param user Updated Organisation details
   */
  receivedUpdatedUser(user: any): void {
    this.rightSheetClose();
    this.orgProfile = user.Organisation;
  }

}
