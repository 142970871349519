import { Component, OnInit, Renderer2, HostListener } from '@angular/core';
import { ServiceProviderService, ServiceProvider, User } from 'src/swagger';
import { SwalHelper } from 'src/app/swal';
import { GlobalProfileService } from 'src/app/global-profile.service';
import { CommonSupportService } from '../../common-support.service';


@Component({
  selector: 'app-service-provider-profile',
  templateUrl: './service-provider-profile.component.html',
  styleUrls: ['./service-provider-profile.component.scss']
})
export class ServiceProviderProfileComponent implements OnInit {

  public shouldShowLoader: boolean;
  public servProv: ServiceProvider;
  public user: User = {};
  public categories: Array<string> = [];
  public cities: Array<string> = [];
  public businessProfileSheet = false;
  public personalDetailSheet = false;
  public isProfileComplete = false;

  constructor(
    private renderer: Renderer2,
    private commonService: CommonSupportService,
    private servProvApi: ServiceProviderService,
    public profileSync: GlobalProfileService
  ) {
    commonService.renderer = renderer;
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent): void {
    this.businessProfileSheet = false;
    this.personalDetailSheet = false;
    this.renderer.removeClass(document.body, 'modal-open');
  }

  ngOnInit(): void {
    this.shouldShowLoader = true;
    this.profileSync.user$.subscribe(user => {
      this.shouldShowLoader = false;
      if (user && user.ServiceProvider) {
        this.user = user;
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });

    if (this.user.ServiceProvider.about && this.user.ServiceProvider.city && this.user.ServiceProvider.phoneNumber) {
      this.isProfileComplete = true;
    } else {
      this.isProfileComplete = false;
    }
  }

  Ratings(n: number): Array<number> {
    return Array(n);
  }

  updatedProfile(event): void {
    this.user = event;
    this.rightSheet(undefined, 'close');
  }

  /* Fn to open the appropriate right sheet to update the information */
  rightSheet(type?: string, action?: string): void {
    if (action === 'open') {
      this.renderer.addClass(document.body, 'modal-open');
      this.commonService.addBackdrop();
      if (type === 'business') {
        this.businessProfileSheet = true;
      } else {
        this.personalDetailSheet = true;
      }
    } else {
      this.renderer.removeClass(document.body, 'modal-open');
      this.commonService.removeBackdrop();
      this.personalDetailSheet = false;
      this.businessProfileSheet = false;
    }
  }

}
