import { Component, OnInit } from '@angular/core';
import { OpenService, SignupObjServiceProvider } from 'src/swagger';
import { SwalHelper } from '../../swal';
import { Router } from '@angular/router';
import { CommonSupportService } from 'src/app/common-support.service';
// import { ReCaptchaV3Service } from 'ng-recaptcha';

declare var Swal: any;

@Component({
  selector: 'app-signup-service-provider',
  templateUrl: './signup-service-provider.component.html',
  styleUrls: ['./signup-service-provider.component.scss']
})
export class SignupServiceProviderComponent implements OnInit {
  public newServProv: SignupObjServiceProvider = {};
  public isLoading: boolean;
  public confPassword: string;
  public companyName: string;

  // public isCaptchaResolved = true;

  public categoryList = [
    'CHA',
    'Freight Forwarder',
    'DGFT Consultant',
    'Export Consultant',
    'International Agent to find Buyers',
    'FX Advisor',
    'Loan Syndication',
    'Chartered Accountant',
    'Quality Ceritification',
    'Laboratory',
    'General Insurance',
    'Credit Insurance',
    'Insurance Claim Advisor'
  ];
  public categories: Array<string> = [];

  constructor(
    private openService: OpenService,
    private router: Router,
    public commonService: CommonSupportService,
    // private recaptchaV3Service: ReCaptchaV3Service
  ) { }

  ngOnInit(): void {
    this.newServProv.countryCode = '+91';
  }

  createServiceProvider(form): boolean {
    // this.recaptchaV3Service.execute('')
    // .subscribe((token: string) => {
    //   this.newServProv.recaptcha = token;
    //   this.isCaptchaResolved = true;
    //   if (form.valid && this.confPassword === this.newServProv.password) {
    //     this.isLoading = true;
    //     this.openService.signupServiceProvider(this.companyName, this.newServProv)
    //       .subscribe(ok => {
    //         this.isLoading = false;
    //         Swal.fire({
    //           title: 'Congratulations!!!',
    //           type: 'success',
    //           text: `Successfully Signed Up!!! Please verify your email by clicking the link sent to you on ${this.newServProv.email}`,
    //           timer: 2000,
    //           showConfirmButton: true,
    //         });
    //         this.router.navigate(['/login']);
    //         form.reset();
    //       }, err => {
    //         this.isLoading = false;
    //         SwalHelper.showErrorSwal(err);
    //       });
    //   } else {
    //     return false;
    //   }
    // })
    // this.newServProv.recaptcha = "";
    // this.isCaptchaResolved = true;
    if (form.valid && this.confPassword === this.newServProv.password) {
      this.isLoading = true;
      this.openService.signupServiceProvider(this.companyName, this.newServProv)
        .subscribe(ok => {
          this.isLoading = false;
          Swal.fire({
            title: 'Congratulations!!!',
            type: 'success',
            text: `Successfully Signed Up!!! Please verify your email by clicking the link sent to you on ${this.newServProv.email}`,
            timer: 2000,
            showConfirmButton: true,
          });
          this.router.navigate(['/login']);
          form.reset();
        }, err => {
          this.isLoading = false;
          SwalHelper.showErrorSwal(err);
        });
    } else {
      return false;
    }

    return false;

  }

  // resolved(captchaResponse: string): void {
  //   this.newServProv.recaptcha = captchaResponse;
  //   this.isCaptchaResolved = true;
  // }

  // errored(): void {
  //   console.warn(`reCAPTCHA error encountered`);
  // }
}
