/**
 * Nuphi trade
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2.0.0
 * Contact: shraddha@blackcurrantapps.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Organisation } from './organisation';


export interface OrganisationContact { 
    orgContactId?: number;
    companyName?: string;
    contactName?: string;
    contactEmail?: string;
    contactPhoneNo?: string;
    contactCountryCode?: string;
    contactAddress?: string;
    contactDesignation?: string;
    comment?: string;
    orgId?: number;
    venSupId?: number;
    contactType?: OrganisationContact.ContactTypeEnum;
    permissions?: any;
    isNotifyEnabled?: boolean;
    tags?: any;
    Organisation?: Organisation;
}
export namespace OrganisationContact {
    export type ContactTypeEnum = 'CHA' | 'FF' | 'BUYER' | 'CONSULTANT' | 'SELLER' | 'BANKER' | 'LENDER' | 'OTHERS' | 'DUAL(BUYER+SELLER)';
    export const ContactTypeEnum = {
        CHA: 'CHA' as ContactTypeEnum,
        FF: 'FF' as ContactTypeEnum,
        BUYER: 'BUYER' as ContactTypeEnum,
        CONSULTANT: 'CONSULTANT' as ContactTypeEnum,
        SELLER: 'SELLER' as ContactTypeEnum,
        BANKER: 'BANKER' as ContactTypeEnum,
        LENDER: 'LENDER' as ContactTypeEnum,
        OTHERS: 'OTHERS' as ContactTypeEnum,
        DUALBUYERSELLER: 'DUAL(BUYER+SELLER)' as ContactTypeEnum
    };
}
