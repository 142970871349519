import { Component, OnInit } from '@angular/core';
import { SwalHelper } from 'src/app/swal';
import { OrgShipmentsService, ShipmentTracking } from 'src/swagger';
declare var swal: any;
declare var $: any;

@Component({
  selector: 'app-shipment-tracking',
  templateUrl: './shipment-tracking.component.html',
  styleUrls: ['./shipment-tracking.component.scss']
})
export class ShipmentTrackingComponent implements OnInit {

  public trackings: ShipmentTracking[] = [];
  public shouldShowLoader = false;

  public tracking: ShipmentTracking = {};

  constructor(
    private shipmentApi: OrgShipmentsService
  ) { }

  ngOnInit(): void {
    this.fetchTrackings();
  }

  /* Fn to fetch all the shipment tracking list */
  fetchTrackings(): void {
    this.shouldShowLoader = true;
    this.shipmentApi.listShipmentTracking().subscribe(trackings => {
      this.shouldShowLoader = false;
      if (trackings && trackings.length > 0) {
        this.trackings = trackings;
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /* Fn to update the tracking details */
  newTracking(): void {
    $('#trackingModal').modal('hide');
    this.shouldShowLoader = true;
    if (this.tracking.shipmentTrackingId) {
      this.shipmentApi.updateShipmentTracking(
        this.tracking
      ).subscribe(track => {
        if (track) {
          this.shouldShowLoader = false;
          const index = this.trackings.findIndex(i => i.shipmentTrackingId == track.shipmentTrackingId);
          this.trackings[index] = track;
          SwalHelper.successTimerSwal('Tracking updated!');
        }
      }, err => {
        this.shouldShowLoader = false;
        SwalHelper.showErrorSwal(err);
      });
    } else {
      this.shipmentApi.createShipmentTracking(
        this.tracking
      ).subscribe(track => {
        if (track) {
          this.shouldShowLoader = false;
          this.trackings.unshift(track);
          setTimeout(() => {
            this.fetchTrackings();
          }, 15000);
          SwalHelper.successTimerSwal('Tracking added!');
        }
      }, err => {
        this.shouldShowLoader = false;
        SwalHelper.showErrorSwal(err);
      });
    }
  }

  /* Fn to init new tracking */
  initTracking(): void {
    this.tracking = {};
    this.tracking.isTrackingEnabled = true;
    $('#trackingModal').modal('show');

  }

  /**
   * FN to update the shipment tracking details
   * @param index Index of shipment tracking to be updated
   */
  editShipmentTracking(index: number): void {
    this.tracking = this.trackings[index];
    $('#trackingModal').modal('show');
  }

  /**
   * Fn to delete the shipment tracking
   * @param index Index of shipment tracking to be deleted
   */
  deleteShipmentTracking(index: number): void {
    swal({
      title: 'Delete Tracking',
      text: 'This cannot be undone',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        this.shouldShowLoader = true;
        return new Promise((resolve, reject) => {
          this.shipmentApi.deleteShipmentTracking(this.trackings[index].shipmentTrackingId).subscribe(ok => {
            this.shouldShowLoader = false;
            this.trackings.splice(index, 1);
            resolve(ok);
          }, err => {
            reject(err);
          });
        });
      },
      allowOutsideClick: () => !swal.isLoading()
    }).then((result) => {
      if (result.value) {
        this.shouldShowLoader = false;
        SwalHelper.successTimerSwal('Tracking deleted!');
      }
    }).catch(err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }
  openImage(index: number): void {
    window.open(this.trackings[index].screenShotUrl, '_blank');
  }
}
