import { User, OrgShipmentsService, OrgInvoiceService, OrgOrdersService, ShipmentDocsService, ReportsService, LicensesService } from 'src/swagger';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { OpenService, OrganisationService, Configuration, AuthService, OrgContactService , ServiceProviderService } from '../swagger';
import * as jwtDecode from 'jwt-decode';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class GlobalProfileService {
  public user: User = null;
  public user$ = new BehaviorSubject<User>(null);
  public isUserLoggedIn = false;
  public isUserLoggedIn$ = new BehaviorSubject<boolean>(false);

  constructor(
    private openApi: OpenService,
    private authApi: AuthService,
    private organisationApi: OrganisationService,
    private orgContactApi: OrgContactService,
    private orgShipmentApi: OrgShipmentsService,
    private orgInvoiceApi: OrgInvoiceService,
    private servProvApi: ServiceProviderService,
    private orgOrderApi: OrgOrdersService,
    private shipmentDocsApi: ShipmentDocsService,
    private reportApi: ReportsService,
    private licenseApi: LicensesService,
    public router: Router,
  ) {
    const token = this.getSavedToken();
    this.setApiConfig(token);
    if (token) {
      this.updateLoggedInStatus(true);
      this.loadSavedUser();
    }
  }

  public updateUserProfile(user: User): void {
    this.user = user;
    this.user$.next(user);
    this.saveUser(user);
  }

  public setToken(token: string): void {
    this.updateLoggedInStatus(true);
    this.saveToken(token);
    this.setApiConfig(token);
  }

  public clearToken(): void {
    this.updateUserProfile(null);
    this.updateLoggedInStatus(false);
    this.setToken(null);
    localStorage.clear();
    this.setApiConfig('');
  }

  private updateLoggedInStatus(status: boolean): void {
    this.isUserLoggedIn = status;
    this.isUserLoggedIn$.next(status);
  }

  private saveToken(token: string): void {
    localStorage.setItem('TOKEN', token);
  }

  public getSavedToken(): string {
    const base64token = localStorage.getItem('TOKEN');
    if (!base64token) { return ''; }
    const token = jwtDecode(base64token);
    if (token.exp * 1000 <= new Date().getTime()) {
      return '';
    }
    return base64token;
  }

  private saveUser(user: User): void {
    localStorage.setItem('USER', JSON.stringify(user));
  }

  private loadSavedUser(): User {
    const strUser = localStorage.getItem('USER');
    if (!strUser) {
      return null;
    }
    else {
      const user = JSON.parse(strUser);
      this.user = user;
      this.user$.next(user);
      return user;
    }
  }

  private setApiConfig(token: string): void {
    const config: Configuration = new Configuration();
    config.apiKeys = {};
    config.apiKeys.Authorization = token;
    this.openApi.configuration = config;
    this.authApi.configuration = config;
    this.organisationApi.configuration = config;
    this.orgShipmentApi.configuration = config;
    this.orgContactApi.configuration = config;
    this.orgInvoiceApi.configuration = config;
    this.servProvApi.configuration = config;
    this.orgOrderApi.configuration = config;
    this.shipmentDocsApi.configuration = config;
    this.reportApi.configuration = config;
    this.licenseApi.configuration = config;
    // Add other api services here
  }
}
