import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CreateProfileComponent } from './create-profile/create-profile.component';
import { OnboardingComponent } from './onboarding.component';
import { CreateShipmentComponent } from './create-shipment/create-shipment.component';
import { ShipmentOverviewComponent } from './shipment-overview/shipment-overview.component';

const routes: Routes = [
  {
    path: '',
    component: OnboardingComponent,
    children: [
      {
        path: 'profile',
        component: CreateProfileComponent
      },
      {
        path: 'shipment-overview',
        component: ShipmentOverviewComponent
      },
      {
        path: 'create-shipment',
        component: CreateShipmentComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OnboardingRoutingModule { }
