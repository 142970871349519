import { Component, OnInit, HostListener, Renderer2, AfterViewInit, OnDestroy } from '@angular/core';
import { OrganisationContact, OrganisationService, VendorAndSupplier } from 'src/swagger';
import { SwalHelper } from 'src/app/swal';
import { CommonSupportService } from 'src/app/common-support.service';
import { Subject } from 'rxjs';
import { GlobalProfileService } from '../../global-profile.service';
declare var swal: any;

@Component({
  selector: 'app-organisation-contacts',
  templateUrl: './organisation-contacts.component.html',
  styleUrls: ['./organisation-contacts.component.scss']
})
export class OrganisationContactsComponent implements OnInit, AfterViewInit, OnDestroy {

  public allContacts: OrganisationContact[] = [];
  public allVendors: VendorAndSupplier[] = [];
  public contactDetails: OrganisationContact = {};

  public shouldShowLoader: boolean;

  public rightSheet = false;
  public vendorRightSheet = false;

  public selectedOrgContact: number;
  public selectedVendor: number;
  public userType: string;
  public listUserType: string;

  public dtTrigger = new Subject();

  constructor(
    private orgApi: OrganisationService,
    private renderer: Renderer2,
    public commonService: CommonSupportService,
    public profileSync: GlobalProfileService,
  ) {
    commonService.renderer = renderer;
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent): void {
    this.rightSheet = false;
    this.vendorRightSheet = false;
    this.renderer.removeClass(document.body, 'modal-open');
    this.commonService.removeBackdrop();
  }

  ngOnInit(): void {
    this.userType = 'Vendor';
    this.fetchAllVendors();
    this.listUserType = 'VENDOR';
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  /* Fn to open the right sheet for external contact form */
  rightSheetOpen(): void {
    this.rightSheet = !this.rightSheet;
    this.renderer.addClass(document.body, 'modal-open');
    this.commonService.addBackdrop();
  }

  /* Fn to close the right sheet for external contact form */
  rightSheetClose(): void {
    this.rightSheet = false;
    this.renderer.removeClass(document.body, 'modal-open');
    this.commonService.removeBackdrop();
    this.selectedVendor = undefined;
    this.selectedOrgContact = undefined;
  }

  /* Fn to open the right sheet for vendor form */
  vendorRightSheetOpen(): void {
    this.vendorRightSheet = !this.vendorRightSheet;
    this.renderer.addClass(document.body, 'modal-open');
    this.commonService.addBackdrop();
  }

  /* Fn to close the right sheet for vendor form */
  vendorRightSheetClose(): void {
    this.vendorRightSheet = false;
    this.renderer.removeClass(document.body, 'modal-open');
    this.commonService.removeBackdrop();
    this.selectedVendor = undefined;
    this.selectedOrgContact = undefined;
  }

  /* Get details of Contact & open contact right sheet for edit */
  editDetails(index: number): void {
    this.selectedOrgContact = this.allContacts[index].orgContactId;
    this.selectedVendor = undefined;
    this.rightSheetOpen();
  }

  /* Get details of Vendor & open vendor right sheet for edit */
  editVendorDetails(index: number): void {
    this.selectedVendor = this.allVendors[index].venSupId;
    this.vendorRightSheetOpen();
  }

  /* Fn to init new vendor & open right sheet */
  addNewVendor(): void {
    this.selectedVendor = undefined;
    this.vendorRightSheetOpen();
  }

  /* Fn to init new contact & open right sheet */
  addNewContact(): void {
    this.selectedOrgContact = undefined;
    this.rightSheetOpen();
  }

  /* Fn to fetch all Contacts */
  fetchAllContacts(): void {
    this.shouldShowLoader = true;
    this.orgApi.listOrgContact(
    ).subscribe(contacts => {
      this.shouldShowLoader = false;
      if (contacts && contacts.length > 0) {
        this.allContacts = contacts;
      } else {
        this.allContacts = null;
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /* fn to fetch all vendors */
  fetchAllVendors(): void {
    this.shouldShowLoader = true;
    this.orgApi.listVendorSupplier().subscribe(vendors => {
      this.shouldShowLoader = false;
      if (vendors && vendors.length > 0) {
        this.allVendors = vendors;
      } else {
        this.allVendors = null;
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /* Fn to receive updated contact data */
  receivedContactData(contact): void {
    this.fetchAllContacts();
    this.rightSheetClose();
  }

  /* Fn to receive updated vendor data */
  vendorData(vendor): void {
    this.fetchAllVendors();
    this.vendorRightSheetClose();
  }

  /**
   * Fn to delete businessContact
   * @param index Index of external contact to be deleted
   */
  deleteContact(index: number): void {
    swal({
      title: 'Are you sure?',
      text: 'Deletion cannot be undone!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        this.shouldShowLoader = true;
        return new Promise((resolve, reject) => {
          this.orgApi.deleteOrgContact(this.allContacts[index].orgContactId).subscribe(ok => {
            this.shouldShowLoader = false;
            this.allContacts.splice(index, 1);
            resolve(ok);
          }, err => {
            reject(err);
          });
        });
      },
      allowOutsideClick: () => !swal.isLoading()
    }).then((result) => {
      if (result.value) {
        this.shouldShowLoader = false;
        SwalHelper.successTimerSwal('Contact deleted!');
      }
    }).catch(err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /**
   * Fn to delete vendor
   * @param index Index of vendor to be deleted
   */
  deleteVendor(index: number): void {
    swal({
      title: 'Are you sure',
      text: 'Deletion cannot be undone!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        this.shouldShowLoader = true;
        return new Promise((resolve, reject) => {
          this.orgApi.deleteVendorSupplier(this.allVendors[index].venSupId).subscribe(ok => {
            this.shouldShowLoader = false;
            this.allVendors.splice(index, 1);
            resolve(ok);
          }, err => {
            reject(err);
          });
        });
      },
      allowOutsideClick: () => !swal.isLoading()
    }).then((result) => {
      if (result.value) {
        this.shouldShowLoader = false;
        SwalHelper.successTimerSwal('Company details deleted!');
      }
    }).catch(err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }
}
