import { GlobalProfileService } from './../global-profile.service';
import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlSegment, Route } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, filter, take, timeout, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OrganisationGuardService {
  constructor(
    private profileSync: GlobalProfileService,
    private router: Router
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.shouldPass();
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.shouldPass();
  }

  canLoad(route: Route, segments: UrlSegment[]): boolean | Observable<boolean> | Promise<boolean> {
    return this.shouldPass();
  }

  shouldPass(): Observable<boolean> {
    return this.profileSync.user$.pipe(
      filter((user) => {
        return (!!user);
      }),
      take(1),
      timeout(3000),
      map((user) => {
        if (user && user.orgId) {
          return true;
        } else {
          this.router.navigate(['/login']);
          return false;
        }
      }),
      catchError((err, obs) => {
        this.router.navigate(['/login']);
        return of(false);
      })
    );
  }
}
