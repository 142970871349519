import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LoaderComponent } from './loader/loader.component';
import { CurrencyHelperPipe } from '../pipes/currency-helper.pipe';
import { CurrencyConverterPipe } from '../pipes/currency-converter.pipe';
import { SafePipe } from '../pipes/safe.pipe';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { NumberToWordsPipe } from '../pipes/number-to-words.pipe';
@NgModule({
  declarations: [
    LoaderComponent,
    CurrencyHelperPipe,
    CurrencyConverterPipe,
    NumberToWordsPipe,
    SafePipe
  ],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
  ],
  exports: [
    LoaderComponent,
    CurrencyHelperPipe,
    CurrencyConverterPipe,
    NumberToWordsPipe,
    SafePipe
  ],
})
export class HelperModuleModule { }
