import { SwalHelper } from './../../swal';
import { GlobalProfileService } from './../../global-profile.service';
import { OpenService } from './../../../swagger/api/open.service';
import { take } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-exchange-access-secret',
  templateUrl: './exchange-access-secret.component.html',
  styleUrls: ['./exchange-access-secret.component.scss']
})
export class ExchangeAccessSecretComponent implements OnInit {

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private exchangeApi: OpenService,
    private profileSync: GlobalProfileService
  ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.pipe(take(1)).subscribe(params => {
      if (params.secret) {
        this.exchangeApi.exchangeAccessSecret(params.secret, params.shipmentUid).subscribe(user => {
          this.profileSync.setToken(user.token);
          delete user.token;
          this.profileSync.updateUserProfile(user);
          if (params.shipmentUid) {
            this.router.navigate(['shipment', 'uid', params.shipmentUid]);
          } else {
            this.router.navigate(['shipment']);
          }
        }, err => {
          SwalHelper.showErrorSwal(err);
        });
      } else {
        this.router.navigate(['/login']);
      }

    });
  }

}
