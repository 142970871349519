import { Component, OnInit, HostListener, Renderer2, OnDestroy } from '@angular/core';
import { User, OrganisationService } from 'src/swagger';
import { SwalHelper } from 'src/app/swal';
import { CommonSupportService } from 'src/app/common-support.service';
import { GlobalProfileService } from 'src/app/global-profile.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { NgForm } from '@angular/forms';
declare var swal: any;

@Component({
  selector: 'app-organisation-users',
  templateUrl: './organisation-users.component.html',
  styleUrls: ['./organisation-users.component.scss']
})
export class OrganisationUsersComponent implements OnInit, OnDestroy {

  public allOrgUsers: User[] = [];
  public userDetails: User = {};

  public shouldShowLoader: boolean;

  public isEditUser: boolean;
  public selectedUser: string;

  public rightSheet = false;
  public isAddMode = false;
  private userSubscription: Subscription;

  dtOptions: DataTables.Settings = {};

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent): void {
    this.rightSheet = false;
    this.renderer.removeClass(document.body, 'modal-open');
  }

  constructor(
    private orgApi: OrganisationService,
    private renderer: Renderer2,
    public profileSync: GlobalProfileService,
    private commonService: CommonSupportService
  ) {
    commonService.renderer = renderer;
  }

  ngOnInit(): void {
    this.userSubscription = this.profileSync.user$.pipe(take(1)).subscribe(user => {
      if (user && user.OrganisationRole && user.OrganisationRole.permissions && user.OrganisationRole.permissions.OrganisationUser.read) {
        this.fetchAllUsers();
      }
    });
    this.dtOptions = {
      columnDefs: [
        { orderable: false, targets: -1, width: '80px' }
      ]
    };
  }

  ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  /* Fn to fetch all the users  */
  fetchAllUsers(): void {
    this.shouldShowLoader = true;
    this.orgApi.allOrgUsers().subscribe(users => {
      this.shouldShowLoader = false;
      if (users && users.length > 0) {
        this.allOrgUsers = users;
      } else {
        this.allOrgUsers = null;
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /**
   * Fn to fet the details of requested email id
   * @param email Email id of user
   */
  fetchUserDetails(email: string): void {
    this.shouldShowLoader = true;
    this.isEditUser = true;
    this.orgApi.getUserProfile(email).subscribe(user => {
      this.shouldShowLoader = false;
      if (user) {
        this.userDetails = user;
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /* Fn to open the right sheet for user details for */
  rightSheetOpen(): void {
    this.rightSheet = !this.rightSheet;
    this.renderer.addClass(document.body, 'modal-open');
    this.commonService.addBackdrop();
    this.isAddMode = true;
  }

  /* Fn to close the right sheet for user details for */
  rightSheetClose(): void {
    this.rightSheet = false;
    this.renderer.removeClass(document.body, 'modal-open');
    this.commonService.removeBackdrop();
    this.isAddMode = false;
  }

  /**
   * Copy the details of user to be updated & open the right sheet
   * @param index Index of user to be updated
   */
  editDetails(index: number): void {
    this.selectedUser = this.allOrgUsers[index].email;
    this.rightSheetOpen();
  }

  /* Fn to init new user & open right sheet */
  addNewUser(): void {
    this.selectedUser = null;
    this.rightSheetOpen();
  }
  /*
   * Fn to save the user details
   * @param form Angular form
   */
  saveUserDetails(form: NgForm): boolean {
    if (form.valid) {
      this.shouldShowLoader = true;
      if (this.isEditUser) {
        this.orgApi.updateUserProfile(this.userDetails).subscribe(user => {
          this.shouldShowLoader = false;
          SwalHelper.successTimerSwal('User Updated!');
        }, err => {
          this.shouldShowLoader = false;
          SwalHelper.showErrorSwal(err);
        });
      } else {
        this.orgApi.addUserProfile(this.userDetails).subscribe(user => {
          this.shouldShowLoader = false;
          SwalHelper.successTimerSwal('User Added!');
        }, err => {
          this.shouldShowLoader = false;
          SwalHelper.showErrorSwal(err);
        });
      }
    } else {
      return false;
    }
  }

  /**
   * Fn to delete the user details
   * @param index index of user to be deleted
   *
   */
  deleteUser(index: number): void {
    swal({
      title: 'Delete User',
      text: 'This cannot be undone',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        this.shouldShowLoader = true;
        return new Promise((resolve, reject) => {
          this.orgApi.deleteUserProfile(this.allOrgUsers[index].email).subscribe(ok => {
            this.shouldShowLoader = false;
            this.allOrgUsers.splice(index, 1);
            resolve(ok);
          }, err => {
            reject(err);
          });
        });
      },
      allowOutsideClick: () => !swal.isLoading()
    }).then((result) => {
      if (result.value) {
        this.shouldShowLoader = false;
        SwalHelper.successTimerSwal('User deleted!');
      }
    }).catch(err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /**
   * fn to save the updated user details in to array
   * @param user Updated user details
   */
  receivedData(user): void {
    const index = this.allOrgUsers.findIndex(i => i.email == user.email);
    if (index >= 0) {
      this.allOrgUsers[index] = user;
    } else {
      this.allOrgUsers.push(user);
    }
    this.rightSheetClose();
  }

  /* Fn to check if document has access or not */
  hasDocPermission(object): boolean {
    return Object.keys(object).every((k) => object[k] == true) ? true : false;
  }
}
