import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, HostListener, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonSupportService } from 'src/app/common-support.service';
import { GlobalProfileService } from 'src/app/global-profile.service';
import { OrganisationHelperService } from 'src/app/organisation/organisation-helper.service';
import { SwalHelper } from 'src/app/swal';
import { environment } from 'src/environments/environment';
import { saveAs } from 'file-saver';
import { BillOfLading, CustomExportObject, OrganisationService, ShipmentDocsService, ShipmentDocument, StatusHistory } from 'src/swagger';
import { OrgShipmentsService } from 'src/swagger/api/orgShipments.service';
import { Subscription, of, Subject } from 'rxjs';
import { debounceTime, tap, switchMap, catchError } from 'rxjs/operators';
import { Shipment } from 'src/swagger/model/shipment';

declare var $: any;
declare var swal: any;

@Component({
  selector: 'app-bill-of-lading-details',
  templateUrl: './bill-of-lading-details.component.html',
  styleUrls: ['./bill-of-lading-details.component.scss']
})
export class BillOfLadingDetailsComponent implements OnInit, OnDestroy {

  public bol: BillOfLading = {};
  public shouldShowLoader = false;
  public rightSheet = false;

  public outputbol: any;
  public basePath: string;

  public emails = [];

  public statusHistory: StatusHistory = {};
  public statusEnum = BillOfLading.StatusEnum;
  public statusPercentage: string;

  public shipmentDocumentLink: ShipmentDocument = {}; // For Linking Purpose

  public shipmentChange$: Subject<string> = new Subject();
  public listShipment$: Subject<Shipment[]> = new Subject();
  public alreadyLinkedShipIds = [];
  public shipmentLoading = false;
  private shipmentSubscription: Subscription;

  constructor(
    public http: HttpClient,
    private orgApi: OrganisationService,
    private activatedRoute: ActivatedRoute,
    public profileSync: GlobalProfileService,
    private orgServiceHelper: OrganisationHelperService,
    private renderer: Renderer2,
    public commonService: CommonSupportService,
    private shipmentDocApi: ShipmentDocsService,
    public orgShipmentApi: OrgShipmentsService
  ) {
    this.basePath = environment.BASE_PATH;
    commonService.renderer = renderer;
  }
  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent): void {
    this.rightSheet = false;
    this.renderer.removeClass(document.body, 'modal-open');
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(param => {
      if (param && param.billOfLadingId) {
        this.fetchBOL(param.billOfLadingId);
      }
    });
    this.shipmentSubscription = this.shipmentChange$.pipe(
      debounceTime(200),
      tap(() => this.shipmentLoading = true),
      switchMap(query => this.shipmentDocApi.searchShipmentDocsShipments(
        'BILL_OF_LADING',
        query,
        undefined,
        this.alreadyLinkedShipIds.join(',')
      ).pipe(
        catchError(() => of([])), // empty list on error
        tap(() => {
          this.shipmentLoading = false;
        })
      ))
    ).subscribe(sos => {
      this.listShipment$.next(sos);
    });
    if (localStorage.getItem('IS_LINK_SHIPMENT') && this.profileSync.user && this.profileSync.user.OrganisationRole && this.profileSync.user.OrganisationRole.permissions && this.profileSync.user.OrganisationRole.permissions.Shipment.update) {
      this.openDocLinkModal();
      localStorage.removeItem('IS_LINK_SHIPMENT');
    }
  }

  ngOnDestroy(): void {
    if (this.shipmentSubscription) {
      this.shipmentSubscription.unsubscribe();
    }
  }

  rightSheetOpen(): void {
    this.rightSheet = !this.rightSheet;
    this.renderer.addClass(document.body, 'modal-open');
    this.commonService.addBackdrop();
  }

  rightSheetClose(): void {
    this.rightSheet = false;
    this.renderer.removeClass(document.body, 'modal-open');
    this.commonService.removeBackdrop();
  }

  fetchBOL(bolId): void {
    this.shouldShowLoader = true;
    this.shipmentDocApi.bolStatusHistory(bolId).subscribe(bol => {
      if (bol) {
        this.shouldShowLoader = false;
        this.bol = bol;
        this.calcPercentage();
        this.fetchTemplate();
        if (this.bol.ShipmentDocuments && this.bol.ShipmentDocuments.length > 0) {
          this.alreadyLinkedShipIds = this.bol.ShipmentDocuments.map(i => i.Shipment.shipmentId);
        }
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  calcPercentage(): void {
    if (this.bol.status === 'SHIPPED_ON_BOARD') {
      this.statusPercentage = '20%';
    } else if (this.bol.status === 'IN_TRANSIT') {
      this.statusPercentage = this.bol.mode === 'Sea' ? '40%' : '33%';
    } else if (this.bol.status === 'TELEX_RELEASED') {
      this.statusPercentage = this.bol.mode === 'Sea' ? '60' : '66%';
    } else if (this.bol.status === 'COURIER_RELEASED') {
      this.statusPercentage = '80%';
    } else if (this.bol.status === 'DELIVERED') {
      this.statusPercentage = '100%';
    } else if (this.bol.status === 'DISPUTED') {
      this.statusPercentage = '100%';
    } else {
      this.statusPercentage = '0%';
    }
  }

  // Fetch server side rendered invoice template
  fetchTemplate(): void {
    const headers = new HttpHeaders().set('Authorization', this.orgApi.configuration.apiKeys.Authorization);
    this.shouldShowLoader = true;
    this.http.post(`${this.basePath}/organisation/templates/BillOfLading/bol1`, { billOfLadingId: this.bol.billOfLadingId }, {
      responseType: 'text',
      headers,
      observe: 'body'
    }).subscribe(data => {
      this.shouldShowLoader = false;
      this.outputbol = data;
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }
  // Export the invoice in pdf form
  exportbol(): void {
    this.shouldShowLoader = true;
    const cusObj: CustomExportObject = {};
    cusObj.billOfLadingId = this.bol.billOfLadingId;
    cusObj.type = 'BillOfLading';
    this.orgApi
      .exportDocument(cusObj).subscribe(res => {
        this.shouldShowLoader = false;
        saveAs(res, ('BillOfLading.pdf'));
      }, err => {
        this.shouldShowLoader = false;
        SwalHelper.showErrorSwal(err);
      });
  }

  prinBOL(): void {
    const printContents = document.getElementById('printBol').innerHTML;
    const win = window.open('', 'Title', 'toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes');
    win.document.body.innerHTML = printContents;
    win.print();
  }

  public downloadFile(docAttId: number): void {
    this.orgApi.orgDocumentSignedUrl(docAttId)
      .subscribe(url => {
        window.open(url.url, '_blank');
      });
  }

  saveStatusComment(): void {
    this.shouldShowLoader = true;
    this.statusHistory.billOfLadingId = this.bol.billOfLadingId;
    if (this.statusHistory.statusHistoryId) {
      this.orgApi.updateStatusHistory(this.statusHistory).subscribe(history => {
        if (history) {
          this.shouldShowLoader = false;
          SwalHelper.showSavedToast('Comment Updated');
          const index = this.bol.StatusHistories.findIndex(i => i.statusHistoryId === this.statusHistory.statusHistoryId);
          this.bol.StatusHistories[index] = history;
          this.statusHistory = {};
        }
      }, err => {
        this.shouldShowLoader = false;
        SwalHelper.showErrorSwal(err);
      });
    } else {
      this.orgApi.createStatusHistory(this.statusHistory).subscribe(history => {
        if (history) {
          this.shouldShowLoader = false;
          this.bol.StatusHistories.unshift(history);
          SwalHelper.showSavedToast('Comment Added');
          this.statusHistory = {};
        }
      }, err => {
        this.shouldShowLoader = false;
        SwalHelper.showErrorSwal(err);
      });
    }
  }

  editComment(index: number): void {
    this.statusHistory = this.orgServiceHelper.copyObject(this.bol.StatusHistories[index]);
  }

  deleteStatusComment(index: number): void {
    swal({
      title: 'Delete Status Comment',
      text: 'This cannot be undone',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        this.shouldShowLoader = true;
        return new Promise((resolve, reject) => {
          this.orgApi.deleteStatusHistory(this.bol.StatusHistories[index].statusHistoryId).subscribe(ok => {
            this.shouldShowLoader = false;
            this.bol.StatusHistories.splice(index, 1);
            resolve(ok);
          }, err => {
            reject(err);
          });
        });
      },
      allowOutsideClick: () => !swal.isLoading()
    }).then((result) => {
      if (result.value) {
        this.shouldShowLoader = false;
        SwalHelper.successTimerSwal('Comment deleted!');
      }
    }).catch(err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  updateStatus(status: BillOfLading.StatusEnum): void {
    this.shouldShowLoader = true;
    this.shipmentDocApi.updateBlStatus(
      this.bol.billOfLadingId,
      status
    ).subscribe(ok => {
      this.shouldShowLoader = false;
      if (ok) {
        this.bol.status = status;
        SwalHelper.successTimerSwal(`BOL marked as ${status.toLocaleLowerCase()}`);
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  openDocLinkModal(): void {
    this.shipmentDocumentLink.docType = 'BILL_OF_LADING';
    $('#fileAddModal').modal('show');
  }

  docAlreadyLinked(): void {
    this.shipmentDocumentLink.billOfLadingId = this.bol.billOfLadingId;
    this.orgShipmentApi.documentAlreadyLinked(
      this.shipmentDocumentLink
    ).subscribe(ok => {
      if (ok) {
        swal({
          title: 'Alredy Linked',
          text: ok.message,
          type: 'warning',
          showCancelButton: true,
          cancelButtonText: 'No',
          confirmButtonText: 'Yes! Link',
        }).then((result) => {
          if (result.value) {
            this.linkDocument();
          } else {
            $('#fileAddModal').modal('hide');
            this.shipmentDocumentLink = {};
            this.shipmentDocumentLink.docType = 'BILL_OF_LADING';
          }
        });
      } else {
        this.linkDocument();
      }
    }, err => {
      SwalHelper.showErrorSwal(err);
    });
  }

  // Link the documents
  linkDocument(): void {
    this.shouldShowLoader = true;
    this.shipmentDocumentLink.billOfLadingId = this.bol.billOfLadingId;
    this.orgShipmentApi.linkShipmentDocument(
      this.shipmentDocumentLink
    ).subscribe(doc => {
      this.shouldShowLoader = false;
      SwalHelper.successTimerSwal('Shipment linked');
      if (doc) {
        if (doc.BillOfLading) {
          this.bol.ShipmentDocuments.push({
            shipmentDocumentId: doc.shipmentDocumentId,
            Shipment: doc.Shipment
          });
          this.alreadyLinkedShipIds.push(this.shipmentDocumentLink.shipmentId);
        }
        this.shipmentDocumentLink = {};
        this.shipmentDocumentLink.docType = 'BILL_OF_LADING';
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /**
   * Fn to Unlink the shipment from order
   * @param index Index of shipment document
   */
  unlinkDocument(index: number): void {
    swal({
      title: `Unlink Shipment`,
      text: 'This cannot be undone',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Unlink',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        this.shouldShowLoader = true;
        return new Promise((resolve, reject) => {
          this.orgShipmentApi.unlinkShipmentDocument(
            this.bol.ShipmentDocuments[index].Shipment.shipmentId,
            this.bol.ShipmentDocuments[index].shipmentDocumentId
          ).subscribe(doc => {
            this.shouldShowLoader = false;
            this.bol.ShipmentDocuments.splice(index, 1);
            $('#fileViewModal').modal('hide');
            resolve(doc);
          }, err => {
            this.shouldShowLoader = false;
            reject(err);
          });
        });
      },
      allowOutsideClick: () => !swal.isLoading()
    }).then((result) => {
      if (result.value) {
        SwalHelper.successTimerSwal('Shipment Unlinked');
      }
    }).catch(err => {
      SwalHelper.showErrorSwal(err);
    });
  }
}
