import { OrganisationHelperService } from 'src/app/organisation/organisation-helper.service';
import { SwalHelper } from './../../swal';
import { CommonSupportService } from 'src/app/common-support.service';
import { Organisation, OrganisationService } from 'src/swagger';
import { Component, OnInit, Renderer2, HostListener } from '@angular/core';
import { GlobalProfileService } from 'src/app/global-profile.service';

@Component({
  selector: 'app-master',
  templateUrl: './master.component.html',
  styleUrls: ['./master.component.scss']
})
export class MasterComponent implements OnInit {

  public rightSheet = false;
  public checklistName: string;

  public shouldShowLoader: boolean;
  public orgProfile: Organisation = null;

  constructor(
    private orgApi: OrganisationService,
    public commonService: CommonSupportService,
    private renderer: Renderer2,
    public profileSync: GlobalProfileService,
    public orgHelper: OrganisationHelperService,
  ) {
    commonService.renderer = renderer;
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent): void {
    this.rightSheet = false;
    this.renderer.removeClass(document.body, 'modal-open');
  }

  ngOnInit(): void {
    this.profileSync.user$.subscribe(user => {
      if (user && user.Organisation) {
        this.orgProfile = this.orgHelper.copyObject(user.Organisation);
      }
    });
  }

  /* Fn to open the right sheet for role details form */
  rightSheetOpen(): void {
    this.rightSheet = !this.rightSheet;
    this.renderer.addClass(document.body, 'modal-open');
    this.commonService.addBackdrop();
  }

  /* Fn to open the right sheet for role details form */
  rightSheetClose(): void {
    this.rightSheet = false;
    this.renderer.removeClass(document.body, 'modal-open');
    this.commonService.removeBackdrop();
  }

  /* Fn to add checklist to it's master list */
  addChecklistItem(): void {
    if (!this.profileSync.user.Organisation.checklistMaster) {
      this.profileSync.user.Organisation.checklistMaster = [];
      this.orgProfile.checklistMaster = [];
    }
    this.profileSync.user.Organisation.checklistMaster.push(this.checklistName);
    this.orgProfile.checklistMaster.push(this.checklistName);
    this.checklistName = undefined;
    this.updateOrgProfile();
  }

  /**
   *
   * @param index index of checklist to be deleted
   */
  deleteChecklistItem(index: number): void {
    this.profileSync.user.Organisation.checklistMaster.splice(index, 1);
    this.orgProfile.checklistMaster.splice(index, 1);
    this.updateOrgProfile();
  }


  /* Fn to update the organisation profile*/
  updateOrgProfile(): void {
    this.shouldShowLoader = true;
    this.orgApi.updateOrganisationProfile(this.orgProfile)
      .subscribe(orgProfile => {
        this.shouldShowLoader = false;
        this.profileSync.user.Organisation = orgProfile;
      }, err => {
        this.shouldShowLoader = false;
        SwalHelper.showErrorSwal(err);
      });
  }
}
