import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { CommonSupportService } from 'src/app/common-support.service';
import { SwalHelper } from 'src/app/swal';
import { License, LicensesService } from 'src/swagger';
import { Router } from '@angular/router';
import { GlobalProfileService } from '../../../../global-profile.service';

@Component({
  selector: 'app-license-update',
  templateUrl: './license-update.component.html',
  styleUrls: ['./license-update.component.scss']
})
export class LicenseUpdateComponent implements OnInit, OnChanges {

  public licenseDetail: License = {};
  public shouldShowLoader: boolean;

  @Output() closeLicenseToggle = new EventEmitter<License>();
  @Input() licenseId: number;

  public type = [
    'AA (Advance Authorisation)',
    'DFIA (Duty Free Import Authorisation)',
    'DFRC (Duty Free Replenishment Certificate)',
    'DEPB (Duty Entitlement Passbook Scheme)',
    'EPCG (Export Promotion Capital Goods)',
  ];

  constructor(
    public commonService: CommonSupportService,
    public licenseApi: LicensesService,
    private router: Router,
    private profileSync: GlobalProfileService
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    if (this.licenseId) {
      this.getlicenseDetail(this.licenseId);
    } else {
      this.initLicense();
    }
  }

  /* Fn to initialize the empty license */
  initLicense(): void {
    this.licenseDetail = {};
  }

  /* Fn to fetch the license details */
  getlicenseDetail(licenseId): void {
    this.shouldShowLoader = true;
    this.licenseApi.getLicense(licenseId).subscribe(license => {
      this.shouldShowLoader = false;
      if (license) {
        this.licenseDetail = license;
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /**
   * Fn to save the license details
   * @param form Angular form
   */
  savelicenseDetail(form): boolean {
    if (form.valid) {
      this.shouldShowLoader = true;
      this.licenseDetail.validityImports = this.licenseDetail.validityImports ? moment(this.licenseDetail.validityImports).format('YYYY-MM-DD') : null;
      this.licenseDetail.dateOfIssue = this.licenseDetail.dateOfIssue ? moment(this.licenseDetail.dateOfIssue).format('YYYY-MM-DD') : null;
      this.licenseDetail.custNotifyDate = this.licenseDetail.custNotifyDate ? moment(this.licenseDetail.custNotifyDate).format('YYYY-MM-DD') : null;
      this.licenseDetail.expOblExpiryDate = this.licenseDetail.expOblExpiryDate ? moment(this.licenseDetail.expOblExpiryDate).format('YYYY-MM-DD') : null;
      this.licenseDetail.expOblExtExpiryDate = this.licenseDetail.expOblExtExpiryDate ? moment(this.licenseDetail.expOblExtExpiryDate).format('YYYY-MM-DD') : null;

      if (this.licenseDetail.licenseId) {
        this.licenseApi.updateLicense(this.licenseDetail).subscribe(license => {
          this.shouldShowLoader = false;
          this.closeLicenseToggle.next(license);
          SwalHelper.successTimerSwal('License Details Updated!');
          this.licenseDetail = {};
        }, err => {
          this.shouldShowLoader = false;
          SwalHelper.showErrorSwal(err);
        });
      } else {
        this.licenseApi.createLicense(this.licenseDetail).subscribe(license => {
          this.shouldShowLoader = false;
          this.closeLicenseToggle.next(license);
          SwalHelper.successTimerSwal('License Details Added!');
          this.router.navigate([`/organisation/dgft/license-details/${license.licenseId}`]);
          this.licenseDetail = {};
          if (this.profileSync.user && this.profileSync.user.Organisation && this.profileSync.user.Organisation.Licenses && this.profileSync.user.Organisation.Licenses.length === 0) {
            this.profileSync.user.Organisation.Licenses.push(license);
          }
        }, err => {
          this.shouldShowLoader = false;
          SwalHelper.showErrorSwal(err);
        });
      }
    } else {
      return false;
    }
  }

}
