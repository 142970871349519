import { take } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { OpenService } from 'src/swagger';
import { SwalHelper } from 'src/app/swal';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-magic-link',
  templateUrl: './magic-link.component.html',
  styleUrls: ['./magic-link.component.scss']
})
export class MagicLinkComponent implements OnInit {

  public shouldShowLoader: boolean;

  public shipmentUid: string;
  public sharedBy: string;
  public companyName: string;
  public sharedTo: string;
  public shipmentNo: string;

  constructor(
    private openApi: OpenService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParamMap.pipe(take(1)).subscribe(params => {
      if (params.has('shipmentUid')) {
        this.shipmentUid = params.get('shipmentUid');
      }
      if (params.has('sharedBy')) {
        this.sharedBy = params.get('sharedBy');
      }
      if (params.has('companyName')) {
        this.companyName = params.get('companyName');
      }
      if (params.has('sharedTo')) {
        this.sharedTo = params.get('sharedTo');
      }
      if (params.has('shipmentNo')) {
        this.shipmentNo = params.get('shipmentNo');
      }
    });
  }

  /* Fn to send the magic link */
  sendMagicLink(): void {
    this.shouldShowLoader = true;
    if (this.sharedTo) {
      this.openApi.sendAccessSecret(this.sharedTo, this.shipmentUid).subscribe(ok => {
        if (ok) {
          this.shouldShowLoader = false;
          SwalHelper.successTimerSwal('Magic Link sent to your Email Id');
        }
      }, err => {
        this.shouldShowLoader = false;
        SwalHelper.showErrorSwal(err);
      });
    } else {
      SwalHelper.showErrorToast('Please enter or confirm email');
    }

  }

}
