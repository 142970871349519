/**
 * Nuphi trade
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2.0.0
 * Contact: shraddha@blackcurrantapps.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CustomExportObject } from '../model/customExportObject';
import { DocumentAttachment } from '../model/documentAttachment';
import { ExcelUploadErrors } from '../model/excelUploadErrors';
import { ExcelUploadResult } from '../model/excelUploadResult';
import { Invoice } from '../model/invoice';
import { InvoiceItem } from '../model/invoiceItem';
import { ItemMaster } from '../model/itemMaster';
import { OrgNotification } from '../model/orgNotification';
import { Organisation } from '../model/organisation';
import { OrganisationBankAccount } from '../model/organisationBankAccount';
import { OrganisationContact } from '../model/organisationContact';
import { OrganisationCustomField } from '../model/organisationCustomField';
import { OrganisationRole } from '../model/organisationRole';
import { Page } from '../model/page';
import { PurchaseOrder } from '../model/purchaseOrder';
import { Reminder } from '../model/reminder';
import { ReminderEmailList } from '../model/reminderEmailList';
import { SalesOrder } from '../model/salesOrder';
import { SendgridDomain } from '../model/sendgridDomain';
import { ServiceProvider } from '../model/serviceProvider';
import { Shipment } from '../model/shipment';
import { SignedUrl } from '../model/signedUrl';
import { StatusHistory } from '../model/statusHistory';
import { TagMaster } from '../model/tagMaster';
import { User } from '../model/user';
import { VendorAndSupplier } from '../model/vendorAndSupplier';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable({
  providedIn: 'root'
})
export class OrganisationService {

    protected basePath = 'http://localhost:3000';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * add item specific details
     * Add the details of organisation item master
     * @param ItemMaster 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addItemMaster(ItemMaster: ItemMaster, observe?: 'body', reportProgress?: boolean): Observable<ItemMaster>;
    public addItemMaster(ItemMaster: ItemMaster, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ItemMaster>>;
    public addItemMaster(ItemMaster: ItemMaster, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ItemMaster>>;
    public addItemMaster(ItemMaster: ItemMaster, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (ItemMaster === null || ItemMaster === undefined) {
            throw new Error('Required parameter ItemMaster was null or undefined when calling addItemMaster.');
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ItemMaster>(`${this.basePath}/organisation/item_master`,
            ItemMaster,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * add contact specific details
     * Add the details of organisation contact
     * @param OrganisationContact 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addOrgContact(OrganisationContact: OrganisationContact, observe?: 'body', reportProgress?: boolean): Observable<OrganisationContact>;
    public addOrgContact(OrganisationContact: OrganisationContact, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrganisationContact>>;
    public addOrgContact(OrganisationContact: OrganisationContact, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrganisationContact>>;
    public addOrgContact(OrganisationContact: OrganisationContact, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (OrganisationContact === null || OrganisationContact === undefined) {
            throw new Error('Required parameter OrganisationContact was null or undefined when calling addOrgContact.');
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<OrganisationContact>(`${this.basePath}/organisation/org_contact`,
            OrganisationContact,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * add role specific details
     * Add the details of role
     * @param OrganisationRole 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addRole(OrganisationRole: OrganisationRole, observe?: 'body', reportProgress?: boolean): Observable<OrganisationRole>;
    public addRole(OrganisationRole: OrganisationRole, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrganisationRole>>;
    public addRole(OrganisationRole: OrganisationRole, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrganisationRole>>;
    public addRole(OrganisationRole: OrganisationRole, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (OrganisationRole === null || OrganisationRole === undefined) {
            throw new Error('Required parameter OrganisationRole was null or undefined when calling addRole.');
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<OrganisationRole>(`${this.basePath}/organisation/role`,
            OrganisationRole,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * add tag specific details
     * Add the details of organisation tag master
     * @param TagMaster 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addTagMaster(TagMaster: TagMaster, observe?: 'body', reportProgress?: boolean): Observable<TagMaster>;
    public addTagMaster(TagMaster: TagMaster, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TagMaster>>;
    public addTagMaster(TagMaster: TagMaster, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TagMaster>>;
    public addTagMaster(TagMaster: TagMaster, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (TagMaster === null || TagMaster === undefined) {
            throw new Error('Required parameter TagMaster was null or undefined when calling addTagMaster.');
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<TagMaster>(`${this.basePath}/organisation/tag_master`,
            TagMaster,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * add user specific details
     * Add the details of organisation user
     * @param User 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addUserProfile(User: User, observe?: 'body', reportProgress?: boolean): Observable<User>;
    public addUserProfile(User: User, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<User>>;
    public addUserProfile(User: User, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<User>>;
    public addUserProfile(User: User, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (User === null || User === undefined) {
            throw new Error('Required parameter User was null or undefined when calling addUserProfile.');
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<User>(`${this.basePath}/organisation/user_profile`,
            User,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * add vendor specific details
     * Add the details of organisation vendor supplier
     * @param VendorAndSupplier 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addVendorSupplier(VendorAndSupplier: VendorAndSupplier, observe?: 'body', reportProgress?: boolean): Observable<VendorAndSupplier>;
    public addVendorSupplier(VendorAndSupplier: VendorAndSupplier, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VendorAndSupplier>>;
    public addVendorSupplier(VendorAndSupplier: VendorAndSupplier, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VendorAndSupplier>>;
    public addVendorSupplier(VendorAndSupplier: VendorAndSupplier, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (VendorAndSupplier === null || VendorAndSupplier === undefined) {
            throw new Error('Required parameter VendorAndSupplier was null or undefined when calling addVendorSupplier.');
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<VendorAndSupplier>(`${this.basePath}/organisation/vendor_supplier`,
            VendorAndSupplier,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get all roles
     * Get the all role based of an organisation
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public allOrgRoles(observe?: 'body', reportProgress?: boolean): Observable<Array<OrganisationRole>>;
    public allOrgRoles(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<OrganisationRole>>>;
    public allOrgRoles(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<OrganisationRole>>>;
    public allOrgRoles(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<OrganisationRole>>(`${this.basePath}/organisation/list_roles`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all users
     * Get all the users of an organisation
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public allOrgUsers(observe?: 'body', reportProgress?: boolean): Observable<Array<User>>;
    public allOrgUsers(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<User>>>;
    public allOrgUsers(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<User>>>;
    public allOrgUsers(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<User>>(`${this.basePath}/organisation/list_users`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * gets bank acc detail for an org for a particular bankId
     * gets bank acc detail for an org
     * @param bankId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public bankDetails(bankId: number, observe?: 'body', reportProgress?: boolean): Observable<OrganisationBankAccount>;
    public bankDetails(bankId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrganisationBankAccount>>;
    public bankDetails(bankId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrganisationBankAccount>>;
    public bankDetails(bankId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bankId === null || bankId === undefined) {
            throw new Error('Required parameter bankId was null or undefined when calling bankDetails.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (bankId !== undefined && bankId !== null) {
            queryParameters = queryParameters.set('bankId', <any>bankId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<OrganisationBankAccount>(`${this.basePath}/organisation/bank_details`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Fetch the details of my domain
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public checkDomain(observe?: 'body', reportProgress?: boolean): Observable<SendgridDomain>;
    public checkDomain(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SendgridDomain>>;
    public checkDomain(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SendgridDomain>>;
    public checkDomain(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<SendgridDomain>(`${this.basePath}/organisation/check_domain`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Clean all the notifications
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public cleanAllNotifications(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public cleanAllNotifications(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public cleanAllNotifications(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public cleanAllNotifications(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/organisation/clean_all_notifications`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete the notification
     * 
     * @param notificationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public cleanNotification(notificationId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public cleanNotification(notificationId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public cleanNotification(notificationId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public cleanNotification(notificationId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (notificationId === null || notificationId === undefined) {
            throw new Error('Required parameter notificationId was null or undefined when calling cleanNotification.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (notificationId !== undefined && notificationId !== null) {
            queryParameters = queryParameters.set('notificationId', <any>notificationId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/organisation/clean_notification`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Mark the notification as completed
     * 
     * @param notificationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public completeNotification(notificationId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public completeNotification(notificationId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public completeNotification(notificationId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public completeNotification(notificationId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (notificationId === null || notificationId === undefined) {
            throw new Error('Required parameter notificationId was null or undefined when calling completeNotification.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (notificationId !== undefined && notificationId !== null) {
            queryParameters = queryParameters.set('notificationId', <any>notificationId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.put<any>(`${this.basePath}/organisation/complete_notification`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Mark the reminder as completed
     * 
     * @param reminderId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public completeReminder(reminderId?: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public completeReminder(reminderId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public completeReminder(reminderId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public completeReminder(reminderId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (reminderId !== undefined && reminderId !== null) {
            queryParameters = queryParameters.set('reminderId', <any>reminderId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.put<any>(`${this.basePath}/organisation/complete_reminder`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * create custom field
     * 
     * @param OrganisationCustomField 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createCustomField(OrganisationCustomField: OrganisationCustomField, observe?: 'body', reportProgress?: boolean): Observable<OrganisationCustomField>;
    public createCustomField(OrganisationCustomField: OrganisationCustomField, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrganisationCustomField>>;
    public createCustomField(OrganisationCustomField: OrganisationCustomField, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrganisationCustomField>>;
    public createCustomField(OrganisationCustomField: OrganisationCustomField, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (OrganisationCustomField === null || OrganisationCustomField === undefined) {
            throw new Error('Required parameter OrganisationCustomField was null or undefined when calling createCustomField.');
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<OrganisationCustomField>(`${this.basePath}/organisation/custom_field`,
            OrganisationCustomField,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * create the details of my domain
     * 
     * @param domainName 
     * @param subDomainName 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createDomain(domainName: string, subDomainName?: string, observe?: 'body', reportProgress?: boolean): Observable<SendgridDomain>;
    public createDomain(domainName: string, subDomainName?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SendgridDomain>>;
    public createDomain(domainName: string, subDomainName?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SendgridDomain>>;
    public createDomain(domainName: string, subDomainName?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (domainName === null || domainName === undefined) {
            throw new Error('Required parameter domainName was null or undefined when calling createDomain.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (domainName !== undefined && domainName !== null) {
            queryParameters = queryParameters.set('domainName', <any>domainName);
        }
        if (subDomainName !== undefined && subDomainName !== null) {
            queryParameters = queryParameters.set('subDomainName', <any>subDomainName);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<SendgridDomain>(`${this.basePath}/organisation/create_domain`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * create an org bank acc entry
     * 
     * @param OrganisationBankAccount 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createOrganisationBankAccount(OrganisationBankAccount: OrganisationBankAccount, observe?: 'body', reportProgress?: boolean): Observable<OrganisationBankAccount>;
    public createOrganisationBankAccount(OrganisationBankAccount: OrganisationBankAccount, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrganisationBankAccount>>;
    public createOrganisationBankAccount(OrganisationBankAccount: OrganisationBankAccount, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrganisationBankAccount>>;
    public createOrganisationBankAccount(OrganisationBankAccount: OrganisationBankAccount, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (OrganisationBankAccount === null || OrganisationBankAccount === undefined) {
            throw new Error('Required parameter OrganisationBankAccount was null or undefined when calling createOrganisationBankAccount.');
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<OrganisationBankAccount>(`${this.basePath}/organisation/bank_account`,
            OrganisationBankAccount,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * create new reminder
     * Create a new reminder for an organisation
     * @param Reminder 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createReminder(Reminder: Reminder, observe?: 'body', reportProgress?: boolean): Observable<Reminder>;
    public createReminder(Reminder: Reminder, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Reminder>>;
    public createReminder(Reminder: Reminder, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Reminder>>;
    public createReminder(Reminder: Reminder, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (Reminder === null || Reminder === undefined) {
            throw new Error('Required parameter Reminder was null or undefined when calling createReminder.');
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Reminder>(`${this.basePath}/organisation/reminder`,
            Reminder,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * add status history comment
     * Add the details of organisation status history comment
     * @param StatusHistory 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createStatusHistory(StatusHistory: StatusHistory, observe?: 'body', reportProgress?: boolean): Observable<StatusHistory>;
    public createStatusHistory(StatusHistory: StatusHistory, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<StatusHistory>>;
    public createStatusHistory(StatusHistory: StatusHistory, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<StatusHistory>>;
    public createStatusHistory(StatusHistory: StatusHistory, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (StatusHistory === null || StatusHistory === undefined) {
            throw new Error('Required parameter StatusHistory was null or undefined when calling createStatusHistory.');
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<StatusHistory>(`${this.basePath}/organisation/status_history`,
            StatusHistory,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get dashboard export cards
     * Get various export cards for an organisation
     * @param currency 
     * @param limit 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dashboardExportCards(currency: string, limit?: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public dashboardExportCards(currency: string, limit?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public dashboardExportCards(currency: string, limit?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public dashboardExportCards(currency: string, limit?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (currency === null || currency === undefined) {
            throw new Error('Required parameter currency was null or undefined when calling dashboardExportCards.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (currency !== undefined && currency !== null) {
            queryParameters = queryParameters.set('currency', <any>currency);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<any>(`${this.basePath}/organisation/dashboard_export_cards`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get dashboard import cards
     * Get various import cards for an organisation
     * @param currency 
     * @param limit 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dashboardImportCards(currency: string, limit?: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public dashboardImportCards(currency: string, limit?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public dashboardImportCards(currency: string, limit?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public dashboardImportCards(currency: string, limit?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (currency === null || currency === undefined) {
            throw new Error('Required parameter currency was null or undefined when calling dashboardImportCards.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (currency !== undefined && currency !== null) {
            queryParameters = queryParameters.set('currency', <any>currency);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<any>(`${this.basePath}/organisation/dashboard_import_cards`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete the custom field
     * 
     * @param orgCusFieId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteCustomField(orgCusFieId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteCustomField(orgCusFieId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteCustomField(orgCusFieId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteCustomField(orgCusFieId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (orgCusFieId === null || orgCusFieId === undefined) {
            throw new Error('Required parameter orgCusFieId was null or undefined when calling deleteCustomField.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (orgCusFieId !== undefined && orgCusFieId !== null) {
            queryParameters = queryParameters.set('orgCusFieId', <any>orgCusFieId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/organisation/custom_field`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete an attachment from the documents either inv, sb, boe, bol
     * 
     * @param docAttId 
     * @param invId 
     * @param shippingBillId 
     * @param billOfEntryId 
     * @param billOfLadingId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteDocumentsAttachment(docAttId: number, invId?: number, shippingBillId?: number, billOfEntryId?: number, billOfLadingId?: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteDocumentsAttachment(docAttId: number, invId?: number, shippingBillId?: number, billOfEntryId?: number, billOfLadingId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteDocumentsAttachment(docAttId: number, invId?: number, shippingBillId?: number, billOfEntryId?: number, billOfLadingId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteDocumentsAttachment(docAttId: number, invId?: number, shippingBillId?: number, billOfEntryId?: number, billOfLadingId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (docAttId === null || docAttId === undefined) {
            throw new Error('Required parameter docAttId was null or undefined when calling deleteDocumentsAttachment.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (docAttId !== undefined && docAttId !== null) {
            queryParameters = queryParameters.set('docAttId', <any>docAttId);
        }
        if (invId !== undefined && invId !== null) {
            queryParameters = queryParameters.set('invId', <any>invId);
        }
        if (shippingBillId !== undefined && shippingBillId !== null) {
            queryParameters = queryParameters.set('shippingBillId', <any>shippingBillId);
        }
        if (billOfEntryId !== undefined && billOfEntryId !== null) {
            queryParameters = queryParameters.set('billOfEntryId', <any>billOfEntryId);
        }
        if (billOfLadingId !== undefined && billOfLadingId !== null) {
            queryParameters = queryParameters.set('billOfLadingId', <any>billOfLadingId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/organisation/delete_documents_attachment`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete item specific details
     * Delete the details of organisation item.
     * @param itemMasterId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteItemMaster(itemMasterId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteItemMaster(itemMasterId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteItemMaster(itemMasterId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteItemMaster(itemMasterId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (itemMasterId === null || itemMasterId === undefined) {
            throw new Error('Required parameter itemMasterId was null or undefined when calling deleteItemMaster.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (itemMasterId !== undefined && itemMasterId !== null) {
            queryParameters = queryParameters.set('itemMasterId', <any>itemMasterId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/organisation/item_master`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete contact specific details
     * Delete the details of organisation contact.This will be the soft delete
     * @param orgContactId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteOrgContact(orgContactId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteOrgContact(orgContactId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteOrgContact(orgContactId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteOrgContact(orgContactId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (orgContactId === null || orgContactId === undefined) {
            throw new Error('Required parameter orgContactId was null or undefined when calling deleteOrgContact.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (orgContactId !== undefined && orgContactId !== null) {
            queryParameters = queryParameters.set('orgContactId', <any>orgContactId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/organisation/org_contact`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * delete an org bank acc entry
     * 
     * @param bankId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteOrganisationBankAccount(bankId: number, observe?: 'body', reportProgress?: boolean): Observable<OrganisationBankAccount>;
    public deleteOrganisationBankAccount(bankId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrganisationBankAccount>>;
    public deleteOrganisationBankAccount(bankId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrganisationBankAccount>>;
    public deleteOrganisationBankAccount(bankId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bankId === null || bankId === undefined) {
            throw new Error('Required parameter bankId was null or undefined when calling deleteOrganisationBankAccount.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (bankId !== undefined && bankId !== null) {
            queryParameters = queryParameters.set('bankId', <any>bankId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<OrganisationBankAccount>(`${this.basePath}/organisation/bank_account`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * delete shipment qualtiy asssurance certificate
     * 
     * @param shipmentId 
     * @param qaTestingCertId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteQualityAssuranceCertificate(shipmentId: number, qaTestingCertId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteQualityAssuranceCertificate(shipmentId: number, qaTestingCertId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteQualityAssuranceCertificate(shipmentId: number, qaTestingCertId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteQualityAssuranceCertificate(shipmentId: number, qaTestingCertId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (shipmentId === null || shipmentId === undefined) {
            throw new Error('Required parameter shipmentId was null or undefined when calling deleteQualityAssuranceCertificate.');
        }

        if (qaTestingCertId === null || qaTestingCertId === undefined) {
            throw new Error('Required parameter qaTestingCertId was null or undefined when calling deleteQualityAssuranceCertificate.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (shipmentId !== undefined && shipmentId !== null) {
            queryParameters = queryParameters.set('shipmentId', <any>shipmentId);
        }
        if (qaTestingCertId !== undefined && qaTestingCertId !== null) {
            queryParameters = queryParameters.set('qaTestingCertId', <any>qaTestingCertId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/organisation/qa_certificate`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get reminder detail
     * Delete a requested reminder for an organisation
     * @param reminderId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteReminder(reminderId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteReminder(reminderId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteReminder(reminderId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteReminder(reminderId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (reminderId === null || reminderId === undefined) {
            throw new Error('Required parameter reminderId was null or undefined when calling deleteReminder.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (reminderId !== undefined && reminderId !== null) {
            queryParameters = queryParameters.set('reminderId', <any>reminderId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/organisation/reminder`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete role specific details
     * Delete the details of role
     * @param orgRoleId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteRole(orgRoleId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteRole(orgRoleId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteRole(orgRoleId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteRole(orgRoleId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (orgRoleId === null || orgRoleId === undefined) {
            throw new Error('Required parameter orgRoleId was null or undefined when calling deleteRole.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (orgRoleId !== undefined && orgRoleId !== null) {
            queryParameters = queryParameters.set('orgRoleId', <any>orgRoleId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/organisation/role`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * remove stamp of the organisation
     * stamp remove from organisation
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteStamp(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteStamp(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteStamp(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteStamp(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/organisation/delete_stamp`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete status history
     * Delete the details of organisation status history comment
     * @param statusHistoryId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteStatusHistory(statusHistoryId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteStatusHistory(statusHistoryId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteStatusHistory(statusHistoryId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteStatusHistory(statusHistoryId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (statusHistoryId === null || statusHistoryId === undefined) {
            throw new Error('Required parameter statusHistoryId was null or undefined when calling deleteStatusHistory.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (statusHistoryId !== undefined && statusHistoryId !== null) {
            queryParameters = queryParameters.set('statusHistoryId', <any>statusHistoryId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/organisation/status_history`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete role specific details
     * Delete the details of organisation user.It will be the soft delete.
     * @param email 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteUserProfile(email: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteUserProfile(email: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteUserProfile(email: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteUserProfile(email: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (email === null || email === undefined) {
            throw new Error('Required parameter email was null or undefined when calling deleteUserProfile.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (email !== undefined && email !== null) {
            queryParameters = queryParameters.set('email', <any>email);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/organisation/user_profile`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete vendor specific details
     * Delete the details of organisation vendor supplier.This will be the soft delete
     * @param venSupId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteVendorSupplier(venSupId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteVendorSupplier(venSupId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteVendorSupplier(venSupId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteVendorSupplier(venSupId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (venSupId === null || venSupId === undefined) {
            throw new Error('Required parameter venSupId was null or undefined when calling deleteVendorSupplier.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (venSupId !== undefined && venSupId !== null) {
            queryParameters = queryParameters.set('venSupId', <any>venSupId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/organisation/vendor_supplier`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * deletes voyage inusrance details linked to shipment
     * 
     * @param insuranceId 
     * @param invoiceId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteVoyageInsurance(insuranceId: number, invoiceId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteVoyageInsurance(insuranceId: number, invoiceId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteVoyageInsurance(insuranceId: number, invoiceId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteVoyageInsurance(insuranceId: number, invoiceId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (insuranceId === null || insuranceId === undefined) {
            throw new Error('Required parameter insuranceId was null or undefined when calling deleteVoyageInsurance.');
        }

        if (invoiceId === null || invoiceId === undefined) {
            throw new Error('Required parameter invoiceId was null or undefined when calling deleteVoyageInsurance.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (insuranceId !== undefined && insuranceId !== null) {
            queryParameters = queryParameters.set('insuranceId', <any>insuranceId);
        }
        if (invoiceId !== undefined && invoiceId !== null) {
            queryParameters = queryParameters.set('invoiceId', <any>invoiceId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/organisation/list_voyage_insurance`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Send the requested invoice in pdf format
     * 
     * @param invId 
     * @param emailArray 
     * @param type 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public emailInvoice(invId: number, emailArray: Array<string>, type: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public emailInvoice(invId: number, emailArray: Array<string>, type: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public emailInvoice(invId: number, emailArray: Array<string>, type: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public emailInvoice(invId: number, emailArray: Array<string>, type: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (invId === null || invId === undefined) {
            throw new Error('Required parameter invId was null or undefined when calling emailInvoice.');
        }

        if (emailArray === null || emailArray === undefined) {
            throw new Error('Required parameter emailArray was null or undefined when calling emailInvoice.');
        }

        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling emailInvoice.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (invId !== undefined && invId !== null) {
            queryParameters = queryParameters.set('invId', <any>invId);
        }
        if (emailArray) {
            queryParameters = queryParameters.set('emailArray', emailArray.join(COLLECTION_FORMATS['csv']));
        }
        if (type !== undefined && type !== null) {
            queryParameters = queryParameters.set('type', <any>type);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<any>(`${this.basePath}/organisation/email_invoice`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Send the requested purchase order in pdf format
     * 
     * @param purchaseOrderId 
     * @param emailArray 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public emailPurchaseOrder(purchaseOrderId: number, emailArray: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public emailPurchaseOrder(purchaseOrderId: number, emailArray: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public emailPurchaseOrder(purchaseOrderId: number, emailArray: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public emailPurchaseOrder(purchaseOrderId: number, emailArray: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (purchaseOrderId === null || purchaseOrderId === undefined) {
            throw new Error('Required parameter purchaseOrderId was null or undefined when calling emailPurchaseOrder.');
        }

        if (emailArray === null || emailArray === undefined) {
            throw new Error('Required parameter emailArray was null or undefined when calling emailPurchaseOrder.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (purchaseOrderId !== undefined && purchaseOrderId !== null) {
            queryParameters = queryParameters.set('purchaseOrderId', <any>purchaseOrderId);
        }
        if (emailArray) {
            queryParameters = queryParameters.set('emailArray', emailArray.join(COLLECTION_FORMATS['csv']));
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<any>(`${this.basePath}/organisation/email_purchase_order`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Send the requested sales order in pdf format
     * 
     * @param salesOrderId 
     * @param emailArray 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public emailSalesOrder(salesOrderId: number, emailArray: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public emailSalesOrder(salesOrderId: number, emailArray: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public emailSalesOrder(salesOrderId: number, emailArray: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public emailSalesOrder(salesOrderId: number, emailArray: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (salesOrderId === null || salesOrderId === undefined) {
            throw new Error('Required parameter salesOrderId was null or undefined when calling emailSalesOrder.');
        }

        if (emailArray === null || emailArray === undefined) {
            throw new Error('Required parameter emailArray was null or undefined when calling emailSalesOrder.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (salesOrderId !== undefined && salesOrderId !== null) {
            queryParameters = queryParameters.set('salesOrderId', <any>salesOrderId);
        }
        if (emailArray) {
            queryParameters = queryParameters.set('emailArray', emailArray.join(COLLECTION_FORMATS['csv']));
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<any>(`${this.basePath}/organisation/email_sales_order`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Export document as a file
     * 
     * @param CustomExportObject 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exportDocument(CustomExportObject: CustomExportObject, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public exportDocument(CustomExportObject: CustomExportObject, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public exportDocument(CustomExportObject: CustomExportObject, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public exportDocument(CustomExportObject: CustomExportObject, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (CustomExportObject === null || CustomExportObject === undefined) {
            throw new Error('Required parameter CustomExportObject was null or undefined when calling exportDocument.');
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/pdf'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post(`${this.basePath}/organisation/export_document`,
            CustomExportObject,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Export purchase order as a file
     * 
     * @param purchaseOrderId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exportPo(purchaseOrderId: number, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public exportPo(purchaseOrderId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public exportPo(purchaseOrderId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public exportPo(purchaseOrderId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (purchaseOrderId === null || purchaseOrderId === undefined) {
            throw new Error('Required parameter purchaseOrderId was null or undefined when calling exportPo.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (purchaseOrderId !== undefined && purchaseOrderId !== null) {
            queryParameters = queryParameters.set('purchaseOrderId', <any>purchaseOrderId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/pdf'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get(`${this.basePath}/organisation/export_po`,
            {
                params: queryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Export sales order as a file
     * 
     * @param salesOrderId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exportSo(salesOrderId: number, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public exportSo(salesOrderId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public exportSo(salesOrderId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public exportSo(salesOrderId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (salesOrderId === null || salesOrderId === undefined) {
            throw new Error('Required parameter salesOrderId was null or undefined when calling exportSo.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (salesOrderId !== undefined && salesOrderId !== null) {
            queryParameters = queryParameters.set('salesOrderId', <any>salesOrderId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/pdf'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get(`${this.basePath}/organisation/export_so`,
            {
                params: queryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * fetch items of a particular invoice
     * list items of an invoice
     * @param invId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public fetchItems(invId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<InvoiceItem>>;
    public fetchItems(invId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<InvoiceItem>>>;
    public fetchItems(invId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<InvoiceItem>>>;
    public fetchItems(invId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (invId === null || invId === undefined) {
            throw new Error('Required parameter invId was null or undefined when calling fetchItems.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (invId !== undefined && invId !== null) {
            queryParameters = queryParameters.set('invId', <any>invId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<InvoiceItem>>(`${this.basePath}/organisation/fetch_items`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * fetch all the custom fields
     * 
     * @param documentType 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCustomField(documentType: string, observe?: 'body', reportProgress?: boolean): Observable<Array<OrganisationCustomField>>;
    public getCustomField(documentType: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<OrganisationCustomField>>>;
    public getCustomField(documentType: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<OrganisationCustomField>>>;
    public getCustomField(documentType: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (documentType === null || documentType === undefined) {
            throw new Error('Required parameter documentType was null or undefined when calling getCustomField.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (documentType !== undefined && documentType !== null) {
            queryParameters = queryParameters.set('documentType', <any>documentType);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<OrganisationCustomField>>(`${this.basePath}/organisation/custom_field`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get organisation detail
     * fetch organisation profile details
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrgProfile(observe?: 'body', reportProgress?: boolean): Observable<Organisation>;
    public getOrgProfile(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Organisation>>;
    public getOrgProfile(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Organisation>>;
    public getOrgProfile(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Organisation>(`${this.basePath}/organisation/org_profile`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * fetch org bank acc&#39;s
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrganisationBankAccount(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public getOrganisationBankAccount(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public getOrganisationBankAccount(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public getOrganisationBankAccount(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<any>(`${this.basePath}/organisation/list_bank_acc`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get role specific details
     * Get the details of role based on it&#39;s role id
     * @param orgRoleId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRole(orgRoleId: number, observe?: 'body', reportProgress?: boolean): Observable<OrganisationRole>;
    public getRole(orgRoleId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrganisationRole>>;
    public getRole(orgRoleId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrganisationRole>>;
    public getRole(orgRoleId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (orgRoleId === null || orgRoleId === undefined) {
            throw new Error('Required parameter orgRoleId was null or undefined when calling getRole.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (orgRoleId !== undefined && orgRoleId !== null) {
            queryParameters = queryParameters.set('orgRoleId', <any>orgRoleId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<OrganisationRole>(`${this.basePath}/organisation/role`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get role specific details
     * Get the details of organisation user based on it&#39;s email id
     * @param email 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUserProfile(email: string, observe?: 'body', reportProgress?: boolean): Observable<User>;
    public getUserProfile(email: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<User>>;
    public getUserProfile(email: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<User>>;
    public getUserProfile(email: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (email === null || email === undefined) {
            throw new Error('Required parameter email was null or undefined when calling getUserProfile.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (email !== undefined && email !== null) {
            queryParameters = queryParameters.set('email', <any>email);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<User>(`${this.basePath}/organisation/user_profile`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get item specific details
     * Get the details of organisation item master based on it&#39;s item master id
     * @param itemMasterId 
     * @param itemCode 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itemMasterDetail(itemMasterId?: number, itemCode?: string, observe?: 'body', reportProgress?: boolean): Observable<ItemMaster>;
    public itemMasterDetail(itemMasterId?: number, itemCode?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ItemMaster>>;
    public itemMasterDetail(itemMasterId?: number, itemCode?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ItemMaster>>;
    public itemMasterDetail(itemMasterId?: number, itemCode?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (itemMasterId !== undefined && itemMasterId !== null) {
            queryParameters = queryParameters.set('itemMasterId', <any>itemMasterId);
        }
        if (itemCode !== undefined && itemCode !== null) {
            queryParameters = queryParameters.set('itemCode', <any>itemCode);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<ItemMaster>(`${this.basePath}/organisation/item_master`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all item master
     * Get all the item masters of an organisation
     * @param itemCode 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listItemMaster(itemCode?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ItemMaster>>;
    public listItemMaster(itemCode?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ItemMaster>>>;
    public listItemMaster(itemCode?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ItemMaster>>>;
    public listItemMaster(itemCode?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (itemCode !== undefined && itemCode !== null) {
            queryParameters = queryParameters.set('itemCode', <any>itemCode);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<ItemMaster>>(`${this.basePath}/organisation/list_item_master`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * List of all the notifications
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listNotifications(observe?: 'body', reportProgress?: boolean): Observable<Array<OrgNotification>>;
    public listNotifications(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<OrgNotification>>>;
    public listNotifications(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<OrgNotification>>>;
    public listNotifications(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<OrgNotification>>(`${this.basePath}/organisation/list_notifications`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all contact master
     * Get all the contacts of an organisation
     * @param contactType 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listOrgContact(contactType?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<OrganisationContact>>;
    public listOrgContact(contactType?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<OrganisationContact>>>;
    public listOrgContact(contactType?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<OrganisationContact>>>;
    public listOrgContact(contactType?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (contactType !== undefined && contactType !== null) {
            queryParameters = queryParameters.set('contactType', <any>contactType);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<OrganisationContact>>(`${this.basePath}/organisation/list_org_contact`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * List of all the reminders
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listReminders(observe?: 'body', reportProgress?: boolean): Observable<Array<Reminder>>;
    public listReminders(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Reminder>>>;
    public listReminders(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Reminder>>>;
    public listReminders(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<Reminder>>(`${this.basePath}/organisation/list_reminders`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get list of all service provider
     * 
     * @param query 
     * @param rating 
     * @param category 
     * @param isNuphiCertified 
     * @param location 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listServiceProvider(query?: string, rating?: number, category?: string, isNuphiCertified?: boolean, location?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ServiceProvider>>;
    public listServiceProvider(query?: string, rating?: number, category?: string, isNuphiCertified?: boolean, location?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ServiceProvider>>>;
    public listServiceProvider(query?: string, rating?: number, category?: string, isNuphiCertified?: boolean, location?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ServiceProvider>>>;
    public listServiceProvider(query?: string, rating?: number, category?: string, isNuphiCertified?: boolean, location?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (query !== undefined && query !== null) {
            queryParameters = queryParameters.set('query', <any>query);
        }
        if (rating !== undefined && rating !== null) {
            queryParameters = queryParameters.set('rating', <any>rating);
        }
        if (category !== undefined && category !== null) {
            queryParameters = queryParameters.set('category', <any>category);
        }
        if (isNuphiCertified !== undefined && isNuphiCertified !== null) {
            queryParameters = queryParameters.set('isNuphiCertified', <any>isNuphiCertified);
        }
        if (location !== undefined && location !== null) {
            queryParameters = queryParameters.set('location', <any>location);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<ServiceProvider>>(`${this.basePath}/organisation/service_provider_list`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all tag master
     * Get all the tag masters of an organisation
     * @param searchQuery 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listTagMaster(searchQuery?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<TagMaster>>;
    public listTagMaster(searchQuery?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TagMaster>>>;
    public listTagMaster(searchQuery?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TagMaster>>>;
    public listTagMaster(searchQuery?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (searchQuery !== undefined && searchQuery !== null) {
            queryParameters = queryParameters.set('searchQuery', <any>searchQuery);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<TagMaster>>(`${this.basePath}/organisation/list_tag_master`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all vendor suppliers
     * Get all the vendor suppliers of an organisation
     * @param type 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listVendorSupplier(type?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<VendorAndSupplier>>;
    public listVendorSupplier(type?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<VendorAndSupplier>>>;
    public listVendorSupplier(type?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<VendorAndSupplier>>>;
    public listVendorSupplier(type?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (type !== undefined && type !== null) {
            queryParameters = queryParameters.set('type', <any>type);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<VendorAndSupplier>>(`${this.basePath}/organisation/list_vendor_supplier`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Fetch the details of my workspace
     * 
     * @param shipmentStatus 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public myWorkspace(shipmentStatus: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Shipment>>;
    public myWorkspace(shipmentStatus: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Shipment>>>;
    public myWorkspace(shipmentStatus: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Shipment>>>;
    public myWorkspace(shipmentStatus: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (shipmentStatus === null || shipmentStatus === undefined) {
            throw new Error('Required parameter shipmentStatus was null or undefined when calling myWorkspace.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (shipmentStatus !== undefined && shipmentStatus !== null) {
            queryParameters = queryParameters.set('shipmentStatus', <any>shipmentStatus);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<Shipment>>(`${this.basePath}/organisation/my_workspace`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get OCR results
     * gives signed urls to all ocr pages
     * @param model 
     * @param pk 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public ocrResults(model: string, pk: number, observe?: 'body', reportProgress?: boolean): Observable<Array<Page>>;
    public ocrResults(model: string, pk: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Page>>>;
    public ocrResults(model: string, pk: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Page>>>;
    public ocrResults(model: string, pk: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (model === null || model === undefined) {
            throw new Error('Required parameter model was null or undefined when calling ocrResults.');
        }

        if (pk === null || pk === undefined) {
            throw new Error('Required parameter pk was null or undefined when calling ocrResults.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (model !== undefined && model !== null) {
            queryParameters = queryParameters.set('model', <any>model);
        }
        if (pk !== undefined && pk !== null) {
            queryParameters = queryParameters.set('pk', <any>pk);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<Page>>(`${this.basePath}/organisation/ocr_results`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get contact specific details
     * Get the details of organisation contact on it&#39;s contact  id
     * @param orgContactId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public orgContactDetail(orgContactId: number, observe?: 'body', reportProgress?: boolean): Observable<OrganisationContact>;
    public orgContactDetail(orgContactId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrganisationContact>>;
    public orgContactDetail(orgContactId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrganisationContact>>;
    public orgContactDetail(orgContactId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (orgContactId === null || orgContactId === undefined) {
            throw new Error('Required parameter orgContactId was null or undefined when calling orgContactDetail.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (orgContactId !== undefined && orgContactId !== null) {
            queryParameters = queryParameters.set('orgContactId', <any>orgContactId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<OrganisationContact>(`${this.basePath}/organisation/org_contact`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get a signed url to download attachment file
     * 
     * @param docAttId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public orgDocumentSignedUrl(docAttId: number, observe?: 'body', reportProgress?: boolean): Observable<SignedUrl>;
    public orgDocumentSignedUrl(docAttId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SignedUrl>>;
    public orgDocumentSignedUrl(docAttId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SignedUrl>>;
    public orgDocumentSignedUrl(docAttId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (docAttId === null || docAttId === undefined) {
            throw new Error('Required parameter docAttId was null or undefined when calling orgDocumentSignedUrl.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (docAttId !== undefined && docAttId !== null) {
            queryParameters = queryParameters.set('docAttId', <any>docAttId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<SignedUrl>(`${this.basePath}/organisation/org_document_signed_url`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all recent_transaction
     * Get all the recent_transaction of an organisation
     * @param currency 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public recentTransaction(currency: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Invoice>>;
    public recentTransaction(currency: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Invoice>>>;
    public recentTransaction(currency: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Invoice>>>;
    public recentTransaction(currency: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (currency === null || currency === undefined) {
            throw new Error('Required parameter currency was null or undefined when calling recentTransaction.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (currency !== undefined && currency !== null) {
            queryParameters = queryParameters.set('currency', <any>currency);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<Invoice>>(`${this.basePath}/organisation/recent_transaction`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * List of all the reminder email list
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public reminderEmailList(observe?: 'body', reportProgress?: boolean): Observable<Array<ReminderEmailList>>;
    public reminderEmailList(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ReminderEmailList>>>;
    public reminderEmailList(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ReminderEmailList>>>;
    public reminderEmailList(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<ReminderEmailList>>(`${this.basePath}/organisation/reminder_email_list`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Search invoices
     * 
     * @param type 
     * @param query 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchInvoicesForClone(type?: string, query?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Invoice>>;
    public searchInvoicesForClone(type?: string, query?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Invoice>>>;
    public searchInvoicesForClone(type?: string, query?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Invoice>>>;
    public searchInvoicesForClone(type?: string, query?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (type !== undefined && type !== null) {
            queryParameters = queryParameters.set('type', <any>type);
        }
        if (query !== undefined && query !== null) {
            queryParameters = queryParameters.set('query', <any>query);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<Invoice>>(`${this.basePath}/organisation/search_invoices`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Search Purchase orders for clone feature
     * 
     * @param query 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchPosForClone(query?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<PurchaseOrder>>;
    public searchPosForClone(query?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PurchaseOrder>>>;
    public searchPosForClone(query?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PurchaseOrder>>>;
    public searchPosForClone(query?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (query !== undefined && query !== null) {
            queryParameters = queryParameters.set('query', <any>query);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<PurchaseOrder>>(`${this.basePath}/organisation/search_pos`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Search Sales order for clone feature
     * 
     * @param query 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchSosForClone(query?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<SalesOrder>>;
    public searchSosForClone(query?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SalesOrder>>>;
    public searchSosForClone(query?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SalesOrder>>>;
    public searchSosForClone(query?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (query !== undefined && query !== null) {
            queryParameters = queryParameters.set('query', <any>query);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<SalesOrder>>(`${this.basePath}/organisation/search_sos`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Search vendors
     * 
     * @param query 
     * @param type 
     * @param limit 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchVendors(query?: string, type?: string, limit?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<VendorAndSupplier>>;
    public searchVendors(query?: string, type?: string, limit?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<VendorAndSupplier>>>;
    public searchVendors(query?: string, type?: string, limit?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<VendorAndSupplier>>>;
    public searchVendors(query?: string, type?: string, limit?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (query !== undefined && query !== null) {
            queryParameters = queryParameters.set('query', <any>query);
        }
        if (type !== undefined && type !== null) {
            queryParameters = queryParameters.set('type', <any>type);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<VendorAndSupplier>>(`${this.basePath}/organisation/search_vendors`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update the BOL status manually
     * 
     * @param billOfLadingId 
     * @param status 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateBolStatus(billOfLadingId: number, status: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateBolStatus(billOfLadingId: number, status: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateBolStatus(billOfLadingId: number, status: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateBolStatus(billOfLadingId: number, status: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (billOfLadingId === null || billOfLadingId === undefined) {
            throw new Error('Required parameter billOfLadingId was null or undefined when calling updateBolStatus.');
        }

        if (status === null || status === undefined) {
            throw new Error('Required parameter status was null or undefined when calling updateBolStatus.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (billOfLadingId !== undefined && billOfLadingId !== null) {
            queryParameters = queryParameters.set('billOfLadingId', <any>billOfLadingId);
        }
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', <any>status);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.put<any>(`${this.basePath}/organisation/update_bol_status`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * update custom field
     * 
     * @param OrganisationCustomField 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateCustomField(OrganisationCustomField: OrganisationCustomField, observe?: 'body', reportProgress?: boolean): Observable<OrganisationCustomField>;
    public updateCustomField(OrganisationCustomField: OrganisationCustomField, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrganisationCustomField>>;
    public updateCustomField(OrganisationCustomField: OrganisationCustomField, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrganisationCustomField>>;
    public updateCustomField(OrganisationCustomField: OrganisationCustomField, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (OrganisationCustomField === null || OrganisationCustomField === undefined) {
            throw new Error('Required parameter OrganisationCustomField was null or undefined when calling updateCustomField.');
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<OrganisationCustomField>(`${this.basePath}/organisation/custom_field`,
            OrganisationCustomField,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update item specific details
     * Update the details of organisation item master
     * @param ItemMaster 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateItemMaster(ItemMaster: ItemMaster, observe?: 'body', reportProgress?: boolean): Observable<ItemMaster>;
    public updateItemMaster(ItemMaster: ItemMaster, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ItemMaster>>;
    public updateItemMaster(ItemMaster: ItemMaster, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ItemMaster>>;
    public updateItemMaster(ItemMaster: ItemMaster, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (ItemMaster === null || ItemMaster === undefined) {
            throw new Error('Required parameter ItemMaster was null or undefined when calling updateItemMaster.');
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ItemMaster>(`${this.basePath}/organisation/item_master`,
            ItemMaster,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update contact specific details
     * Update the details of organisation contact
     * @param OrganisationContact 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateOrgContact(OrganisationContact: OrganisationContact, observe?: 'body', reportProgress?: boolean): Observable<OrganisationContact>;
    public updateOrgContact(OrganisationContact: OrganisationContact, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrganisationContact>>;
    public updateOrgContact(OrganisationContact: OrganisationContact, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrganisationContact>>;
    public updateOrgContact(OrganisationContact: OrganisationContact, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (OrganisationContact === null || OrganisationContact === undefined) {
            throw new Error('Required parameter OrganisationContact was null or undefined when calling updateOrgContact.');
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<OrganisationContact>(`${this.basePath}/organisation/org_contact`,
            OrganisationContact,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * update an org bank acc entry
     * 
     * @param OrganisationBankAccount 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateOrganisationBankAccount(OrganisationBankAccount: OrganisationBankAccount, observe?: 'body', reportProgress?: boolean): Observable<OrganisationBankAccount>;
    public updateOrganisationBankAccount(OrganisationBankAccount: OrganisationBankAccount, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrganisationBankAccount>>;
    public updateOrganisationBankAccount(OrganisationBankAccount: OrganisationBankAccount, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrganisationBankAccount>>;
    public updateOrganisationBankAccount(OrganisationBankAccount: OrganisationBankAccount, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (OrganisationBankAccount === null || OrganisationBankAccount === undefined) {
            throw new Error('Required parameter OrganisationBankAccount was null or undefined when calling updateOrganisationBankAccount.');
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<OrganisationBankAccount>(`${this.basePath}/organisation/bank_account`,
            OrganisationBankAccount,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *  update details of organisation
     * update organisation profile details
     * @param Organisation 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateOrganisationProfile(Organisation: Organisation, observe?: 'body', reportProgress?: boolean): Observable<Organisation>;
    public updateOrganisationProfile(Organisation: Organisation, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Organisation>>;
    public updateOrganisationProfile(Organisation: Organisation, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Organisation>>;
    public updateOrganisationProfile(Organisation: Organisation, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (Organisation === null || Organisation === undefined) {
            throw new Error('Required parameter Organisation was null or undefined when calling updateOrganisationProfile.');
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Organisation>(`${this.basePath}/organisation/org_profile`,
            Organisation,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * update reminder
     * Update a requested reminder for an organisation
     * @param Reminder 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateReminder(Reminder: Reminder, observe?: 'body', reportProgress?: boolean): Observable<Reminder>;
    public updateReminder(Reminder: Reminder, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Reminder>>;
    public updateReminder(Reminder: Reminder, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Reminder>>;
    public updateReminder(Reminder: Reminder, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (Reminder === null || Reminder === undefined) {
            throw new Error('Required parameter Reminder was null or undefined when calling updateReminder.');
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Reminder>(`${this.basePath}/organisation/reminder`,
            Reminder,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update role specific details
     * Update the details of role
     * @param OrganisationRole 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateRole(OrganisationRole: OrganisationRole, observe?: 'body', reportProgress?: boolean): Observable<OrganisationRole>;
    public updateRole(OrganisationRole: OrganisationRole, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrganisationRole>>;
    public updateRole(OrganisationRole: OrganisationRole, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrganisationRole>>;
    public updateRole(OrganisationRole: OrganisationRole, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (OrganisationRole === null || OrganisationRole === undefined) {
            throw new Error('Required parameter OrganisationRole was null or undefined when calling updateRole.');
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<OrganisationRole>(`${this.basePath}/organisation/role`,
            OrganisationRole,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update status history specific details
     * Update the details of organisation status history comment
     * @param StatusHistory 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateStatusHistory(StatusHistory: StatusHistory, observe?: 'body', reportProgress?: boolean): Observable<StatusHistory>;
    public updateStatusHistory(StatusHistory: StatusHistory, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<StatusHistory>>;
    public updateStatusHistory(StatusHistory: StatusHistory, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<StatusHistory>>;
    public updateStatusHistory(StatusHistory: StatusHistory, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (StatusHistory === null || StatusHistory === undefined) {
            throw new Error('Required parameter StatusHistory was null or undefined when calling updateStatusHistory.');
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<StatusHistory>(`${this.basePath}/organisation/status_history`,
            StatusHistory,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update the master template data
     * 
     * @param templateData 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateTemplateMaster(templateData: string, observe?: 'body', reportProgress?: boolean): Observable<Organisation>;
    public updateTemplateMaster(templateData: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Organisation>>;
    public updateTemplateMaster(templateData: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Organisation>>;
    public updateTemplateMaster(templateData: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (templateData === null || templateData === undefined) {
            throw new Error('Required parameter templateData was null or undefined when calling updateTemplateMaster.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (templateData !== undefined && templateData !== null) {
            queryParameters = queryParameters.set('templateData', <any>templateData);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.put<Organisation>(`${this.basePath}/organisation/update_template_master`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update user specific details
     * Update the details of organisation User
     * @param User 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateUserProfile(User: User, observe?: 'body', reportProgress?: boolean): Observable<User>;
    public updateUserProfile(User: User, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<User>>;
    public updateUserProfile(User: User, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<User>>;
    public updateUserProfile(User: User, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (User === null || User === undefined) {
            throw new Error('Required parameter User was null or undefined when calling updateUserProfile.');
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<User>(`${this.basePath}/organisation/user_profile`,
            User,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update vendor specific details
     * Update the details of organisation vendor supplier
     * @param VendorAndSupplier 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateVendorSupplier(VendorAndSupplier: VendorAndSupplier, observe?: 'body', reportProgress?: boolean): Observable<VendorAndSupplier>;
    public updateVendorSupplier(VendorAndSupplier: VendorAndSupplier, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VendorAndSupplier>>;
    public updateVendorSupplier(VendorAndSupplier: VendorAndSupplier, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VendorAndSupplier>>;
    public updateVendorSupplier(VendorAndSupplier: VendorAndSupplier, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (VendorAndSupplier === null || VendorAndSupplier === undefined) {
            throw new Error('Required parameter VendorAndSupplier was null or undefined when calling updateVendorSupplier.');
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<VendorAndSupplier>(`${this.basePath}/organisation/vendor_supplier`,
            VendorAndSupplier,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * upload an attachment to the any of the document inv, sb, boe, bol
     * 
     * @param file1 
     * @param invId 
     * @param shippingBillId 
     * @param billOfEntryId 
     * @param billOfLadingId 
     * @param file2 
     * @param file3 
     * @param file4 
     * @param file5 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public uploadDocumentsAttachment(file1: Blob, invId?: number, shippingBillId?: number, billOfEntryId?: number, billOfLadingId?: number, file2?: Blob, file3?: Blob, file4?: Blob, file5?: Blob, observe?: 'body', reportProgress?: boolean): Observable<Array<DocumentAttachment>>;
    public uploadDocumentsAttachment(file1: Blob, invId?: number, shippingBillId?: number, billOfEntryId?: number, billOfLadingId?: number, file2?: Blob, file3?: Blob, file4?: Blob, file5?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DocumentAttachment>>>;
    public uploadDocumentsAttachment(file1: Blob, invId?: number, shippingBillId?: number, billOfEntryId?: number, billOfLadingId?: number, file2?: Blob, file3?: Blob, file4?: Blob, file5?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DocumentAttachment>>>;
    public uploadDocumentsAttachment(file1: Blob, invId?: number, shippingBillId?: number, billOfEntryId?: number, billOfLadingId?: number, file2?: Blob, file3?: Blob, file4?: Blob, file5?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (file1 === null || file1 === undefined) {
            throw new Error('Required parameter file1 was null or undefined when calling uploadDocumentsAttachment.');
        }









        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (invId !== undefined && invId !== null) {
            queryParameters = queryParameters.set('invId', <any>invId);
        }
        if (shippingBillId !== undefined && shippingBillId !== null) {
            queryParameters = queryParameters.set('shippingBillId', <any>shippingBillId);
        }
        if (billOfEntryId !== undefined && billOfEntryId !== null) {
            queryParameters = queryParameters.set('billOfEntryId', <any>billOfEntryId);
        }
        if (billOfLadingId !== undefined && billOfLadingId !== null) {
            queryParameters = queryParameters.set('billOfLadingId', <any>billOfLadingId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file1 !== undefined) {
            formParams.append('file1', <any>file1);
        }
        if (file2 !== undefined) {
            formParams.append('file2', <any>file2);
        }
        if (file3 !== undefined) {
            formParams.append('file3', <any>file3);
        }
        if (file4 !== undefined) {
            formParams.append('file4', <any>file4);
        }
        if (file5 !== undefined) {
            formParams.append('file5', <any>file5);
        }

        return this.httpClient.post<Array<DocumentAttachment>>(`${this.basePath}/organisation/upload_documents_attachment`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Uplod the excel file of invoice item master
     * 
     * @param file 
     * @param includeFirstRow 
     * @param dryRun Dry run the database transactions to see the results
     * @param columnMappings 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public uploadItemMasterExcel(file: Blob, includeFirstRow: boolean, dryRun: boolean, columnMappings: string, observe?: 'body', reportProgress?: boolean): Observable<ExcelUploadResult>;
    public uploadItemMasterExcel(file: Blob, includeFirstRow: boolean, dryRun: boolean, columnMappings: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ExcelUploadResult>>;
    public uploadItemMasterExcel(file: Blob, includeFirstRow: boolean, dryRun: boolean, columnMappings: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ExcelUploadResult>>;
    public uploadItemMasterExcel(file: Blob, includeFirstRow: boolean, dryRun: boolean, columnMappings: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (file === null || file === undefined) {
            throw new Error('Required parameter file was null or undefined when calling uploadItemMasterExcel.');
        }

        if (includeFirstRow === null || includeFirstRow === undefined) {
            throw new Error('Required parameter includeFirstRow was null or undefined when calling uploadItemMasterExcel.');
        }

        if (dryRun === null || dryRun === undefined) {
            throw new Error('Required parameter dryRun was null or undefined when calling uploadItemMasterExcel.');
        }

        if (columnMappings === null || columnMappings === undefined) {
            throw new Error('Required parameter columnMappings was null or undefined when calling uploadItemMasterExcel.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (includeFirstRow !== undefined && includeFirstRow !== null) {
            queryParameters = queryParameters.set('includeFirstRow', <any>includeFirstRow);
        }
        if (dryRun !== undefined && dryRun !== null) {
            queryParameters = queryParameters.set('dryRun', <any>dryRun);
        }
        if (columnMappings !== undefined && columnMappings !== null) {
            queryParameters = queryParameters.set('columnMappings', <any>columnMappings);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams.append('file', <any>file);
        }

        return this.httpClient.put<ExcelUploadResult>(`${this.basePath}/organisation/upload_item_master_excel`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * upload logo of the organisation
     * logo upload for organisation
     * @param Logo 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public uploadLogo(Logo: Blob, observe?: 'body', reportProgress?: boolean): Observable<SignedUrl>;
    public uploadLogo(Logo: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SignedUrl>>;
    public uploadLogo(Logo: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SignedUrl>>;
    public uploadLogo(Logo: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (Logo === null || Logo === undefined) {
            throw new Error('Required parameter Logo was null or undefined when calling uploadLogo.');
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (Logo !== undefined) {
            formParams.append('Logo', <any>Logo);
        }

        return this.httpClient.post<SignedUrl>(`${this.basePath}/organisation/upload_logo`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * upload reminder attachment of the organisation
     * reminder attachment upload for organisation
     * @param reminderId 
     * @param Logo 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public uploadReminderAttachment(reminderId: number, Logo: Blob, observe?: 'body', reportProgress?: boolean): Observable<SignedUrl>;
    public uploadReminderAttachment(reminderId: number, Logo: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SignedUrl>>;
    public uploadReminderAttachment(reminderId: number, Logo: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SignedUrl>>;
    public uploadReminderAttachment(reminderId: number, Logo: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (reminderId === null || reminderId === undefined) {
            throw new Error('Required parameter reminderId was null or undefined when calling uploadReminderAttachment.');
        }

        if (Logo === null || Logo === undefined) {
            throw new Error('Required parameter Logo was null or undefined when calling uploadReminderAttachment.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (reminderId !== undefined && reminderId !== null) {
            queryParameters = queryParameters.set('reminderId', <any>reminderId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (Logo !== undefined) {
            formParams.append('Logo', <any>Logo);
        }

        return this.httpClient.post<SignedUrl>(`${this.basePath}/organisation/upload_reminder_attachment`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * upload stamp of the organisation
     * stamp upload for organisation
     * @param Logo 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public uploadStamp(Logo: Blob, observe?: 'body', reportProgress?: boolean): Observable<SignedUrl>;
    public uploadStamp(Logo: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SignedUrl>>;
    public uploadStamp(Logo: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SignedUrl>>;
    public uploadStamp(Logo: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (Logo === null || Logo === undefined) {
            throw new Error('Required parameter Logo was null or undefined when calling uploadStamp.');
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (Logo !== undefined) {
            formParams.append('Logo', <any>Logo);
        }

        return this.httpClient.post<SignedUrl>(`${this.basePath}/organisation/upload_stamp`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update the user prefernces
     * 
     * @param User 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userPreference(User: User, observe?: 'body', reportProgress?: boolean): Observable<User>;
    public userPreference(User: User, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<User>>;
    public userPreference(User: User, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<User>>;
    public userPreference(User: User, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (User === null || User === undefined) {
            throw new Error('Required parameter User was null or undefined when calling userPreference.');
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<User>(`${this.basePath}/organisation/user_preference`,
            User,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * validate the details of my domain
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public validateDomain(observe?: 'body', reportProgress?: boolean): Observable<SendgridDomain>;
    public validateDomain(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SendgridDomain>>;
    public validateDomain(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SendgridDomain>>;
    public validateDomain(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<SendgridDomain>(`${this.basePath}/organisation/validate_domain`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get vendor specific details
     * Get the details of organisation vendor supplier on it&#39;s id
     * @param venSupId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public vendorSupplierDetail(venSupId: number, observe?: 'body', reportProgress?: boolean): Observable<VendorAndSupplier>;
    public vendorSupplierDetail(venSupId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VendorAndSupplier>>;
    public vendorSupplierDetail(venSupId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VendorAndSupplier>>;
    public vendorSupplierDetail(venSupId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (venSupId === null || venSupId === undefined) {
            throw new Error('Required parameter venSupId was null or undefined when calling vendorSupplierDetail.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (venSupId !== undefined && venSupId !== null) {
            queryParameters = queryParameters.set('venSupId', <any>venSupId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<VendorAndSupplier>(`${this.basePath}/organisation/vendor_supplier`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
