import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, filter, take, timeout, switchMap } from 'rxjs/operators';
import { GlobalProfileService } from './../global-profile.service';


/**
 * Only lets through if user is atleast authenticated (External or internal)
 * Otherwise redirects to magic link page with a redirectTo url parameter telling what to do after auth
 */

@Injectable({
  providedIn: 'root'
})
export class ShipmentAuthenticatedGuard implements CanActivate {

  constructor(
    private profileSync: GlobalProfileService,
    private router: Router
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.profileSync.isUserLoggedIn$.pipe(
      filter(isLoggedIn => isLoggedIn),
      take(1),
      switchMap((isLoggedIn) => {
        if (this.profileSync.user.orgId) {
          // this is org login
          this.router.navigate(['/organisation/shipment/uid/', next.queryParams.shipmentUid]);
          return of(false);
        } else if (this.profileSync.user.servProvId) {
          // this is service provide
          this.router.navigate(['/service-provider/shipment/uid/', next.queryParams.shipmentUid]);
          return of(false);
        } else {
          // external user
          return of(isLoggedIn);
        }
      }),
      timeout(1000),
      catchError((err, obs) => {
        this.reRouteToAccesLinkLogin(next.queryParams);
        return of(false);
      })
    );
  }

  private reRouteToAccesLinkLogin(queryParams: object): void {
    this.router.navigate(['/access-link'], {
      queryParams
    });
  }
}
