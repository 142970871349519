import { Component, OnInit, OnDestroy } from '@angular/core';
import { BillOfLading, ShipmentDocsService } from 'src/swagger';
import { Router } from '@angular/router';
import { SwalHelper } from 'src/app/swal';
import * as moment from 'moment';
import { GlobalProfileService } from 'src/app/global-profile.service';
import { Subscription } from 'rxjs';

declare var swal: any;
declare var $: any;

@Component({
  selector: 'app-bill-of-lading',
  templateUrl: './bill-of-lading.component.html',
  styleUrls: ['./bill-of-lading.component.scss']
})
export class BillOfLadingComponent implements OnInit, OnDestroy {

  public bol: BillOfLading = {};
  public bolCreationtype;
  public allBol: BillOfLading[] = [];
  public rightSheet = false;
  public selectedBol = null;
  public selectedBolShipmentId = null;
  public shouldShowLoader: boolean;
  public bolFile: File;
  public bolFileName;
  public startDate: any;
  public endDate: any;
  public status: string;

  dtOptions: DataTables.Settings = {};
  private userSubscription: Subscription;

  constructor(
    public router: Router,
    private shipmentDocApi: ShipmentDocsService,
    public profileSync: GlobalProfileService
  ) { }

  ngOnInit(): void {
    this.initBOL();
    this.dtOptions = {
      columnDefs: [
        { targets: 0, width: '40px' },
        { targets: -1, width: '80px' },
        { orderable: false, targets: -1 }
      ]
    };
    this.userSubscription = this.profileSync.user$.subscribe(user => {
      if (user && user.OrganisationRole && user.OrganisationRole.permissions && user.OrganisationRole.permissions.BillOfLading.read) {
        this.getBolList();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  initBOL(): void {
    this.bolCreationtype = 'new';
    this.bol.mode = 'Sea';
    this.bol.blDate = moment().format('YYYY-MM-DD');
  }

  getBolList(): void {
    this.shouldShowLoader = true;
    this.allBol = [];
    this.shipmentDocApi.listBillOfLadings(
      this.startDate ? moment(this.startDate).format('YYYY-MM-DD') : null,
      this.endDate ? moment(this.endDate).format('YYYY-MM-DD') : null,
      this.status ? this.status : undefined,
    )
      .subscribe(billOfLadings => {
        this.shouldShowLoader = false;
        if (billOfLadings && billOfLadings.length > 0) {
          this.allBol = billOfLadings;
        }
        else {
          this.allBol = null;
        }
      }, err => {
        this.shouldShowLoader = false;
        SwalHelper.showErrorSwal(err);
      });
  }

  deleteBol(i): void {
    swal({
      title: 'Delete Bill of Lading',
      text: 'This cannot be undone',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return new Promise((resolve, reject) => {
          this.shipmentDocApi.deleteBillOfLading(this.allBol[i].billOfLadingId).subscribe(ok => {
            this.shouldShowLoader = false;
            this.allBol.splice(i, 1);
            resolve(ok);
          }, err => {
            reject(err);
          });
        });
      },
      allowOutsideClick: () => !swal.isLoading()
    }).then((result) => {
      if (result.value) {
        this.shouldShowLoader = false;
        SwalHelper.successTimerSwal('Bill of Lading deleted!');
      }
    }).catch(err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  fileChange(event): void {
    this.bolFileName = event.target.files[0].name;
    this.bolFile = event.target.files[0];
  }

  newBol(): void {
    $('#bolModal').modal('hide');
    this.shouldShowLoader = true;
    if (this.bolCreationtype === 'new') {
      this.bol.blDate = moment(this.bol.blDate).format('YYYY-MM-DD');
      this.shipmentDocApi.createBillOfLading(
        this.bol
      ).subscribe(bol => {
        if (bol) {
          this.shouldShowLoader = false;
          this.bol = bol;
          this.router.navigate([`organisation/shipment/shipment-documents/bill-of-lading/edit-bill-of-lading/${this.bol.billOfLadingId}`]);
        }
      }, err => {
        this.shouldShowLoader = false;
        SwalHelper.showErrorSwal(err);
      });
    } else {
      this.shipmentDocApi.importBol(
        this.bolFile
      ).subscribe(bol => {
        if (bol) {
          this.shouldShowLoader = false;
          this.bol = bol;
          this.router.navigate([`organisation/shipment/shipment-documents/bill-of-lading/edit-bill-of-lading/${this.bol.billOfLadingId}`]);
        }
      }, err => {
        this.shouldShowLoader = false;
        SwalHelper.showErrorSwal(err);
      });
    }
  }

  resetFilter(): void {
    this.startDate = undefined;
    this.endDate = undefined;
    this.status = undefined;
    this.getBolList();
  }

  /**
  * Fn to open the shipment link modal with bill of lading details
  * @param index Index of bill of lading to shipment link
  */
  openLinkShipment(index: number): void {
    localStorage.setItem('IS_LINK_SHIPMENT', 'true');
    this.router.navigate([`/organisation/shipment/shipment-documents/bill-of-lading/bill-of-lading-details/${this.allBol[index].billOfLadingId}`]);
  }
}
