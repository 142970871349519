import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-shipment-demo',
  templateUrl: './shipment-demo.component.html',
  styleUrls: ['./shipment-demo.component.scss']
})
export class ShipmentDemoComponent implements OnInit {

  constructor() { }

  @Output() isExitDemo = new EventEmitter<boolean>();

  ngOnInit(): void {
  }

  closeDemo(): void {
    this.isExitDemo.next(true);
  }

}
