/**
 * Nuphi trade
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2.0.0
 * Contact: shraddha@blackcurrantapps.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { License } from '../model/license';
import { LicenseItem } from '../model/licenseItem';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable({
  providedIn: 'root'
})
export class LicensesService {

    protected basePath = 'http://localhost:3000';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Close license
     * check obligation of license of an organisation
     * @param licenseId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public checkLicenseObligation(licenseId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public checkLicenseObligation(licenseId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public checkLicenseObligation(licenseId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public checkLicenseObligation(licenseId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (licenseId === null || licenseId === undefined) {
            throw new Error('Required parameter licenseId was null or undefined when calling checkLicenseObligation.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (licenseId !== undefined && licenseId !== null) {
            queryParameters = queryParameters.set('licenseId', <any>licenseId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.put<any>(`${this.basePath}/organisation/licenses/check_license_obligation`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Close license
     * Close the license of an organisation
     * @param licenseId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public closeLicense(licenseId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public closeLicense(licenseId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public closeLicense(licenseId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public closeLicense(licenseId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (licenseId === null || licenseId === undefined) {
            throw new Error('Required parameter licenseId was null or undefined when calling closeLicense.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (licenseId !== undefined && licenseId !== null) {
            queryParameters = queryParameters.set('licenseId', <any>licenseId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.put<any>(`${this.basePath}/organisation/licenses/close_license`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * add invoice specific details
     * Add the details of organisation invoice
     * @param License 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createLicense(License: License, observe?: 'body', reportProgress?: boolean): Observable<License>;
    public createLicense(License: License, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<License>>;
    public createLicense(License: License, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<License>>;
    public createLicense(License: License, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (License === null || License === undefined) {
            throw new Error('Required parameter License was null or undefined when calling createLicense.');
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<License>(`${this.basePath}/organisation/licenses/license`,
            License,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * add invoice specific details
     * Add the details of organisation invoice
     * @param LicenseItem 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createLicenseItem(LicenseItem: LicenseItem, observe?: 'body', reportProgress?: boolean): Observable<LicenseItem>;
    public createLicenseItem(LicenseItem: LicenseItem, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LicenseItem>>;
    public createLicenseItem(LicenseItem: LicenseItem, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LicenseItem>>;
    public createLicenseItem(LicenseItem: LicenseItem, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (LicenseItem === null || LicenseItem === undefined) {
            throw new Error('Required parameter LicenseItem was null or undefined when calling createLicenseItem.');
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<LicenseItem>(`${this.basePath}/organisation/licenses/license_item`,
            LicenseItem,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete invoice specific details
     * Delete the details of organisation invoice.This will be the soft delete
     * @param licenseId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteLicense(licenseId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteLicense(licenseId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteLicense(licenseId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteLicense(licenseId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (licenseId === null || licenseId === undefined) {
            throw new Error('Required parameter licenseId was null or undefined when calling deleteLicense.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (licenseId !== undefined && licenseId !== null) {
            queryParameters = queryParameters.set('licenseId', <any>licenseId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/organisation/licenses/license`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete invoice specific details
     * Delete the details of organisation invoice.This will be the soft delete
     * @param licenseItemId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteLicenseItem(licenseItemId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteLicenseItem(licenseItemId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteLicenseItem(licenseItemId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteLicenseItem(licenseItemId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (licenseItemId === null || licenseItemId === undefined) {
            throw new Error('Required parameter licenseItemId was null or undefined when calling deleteLicenseItem.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (licenseItemId !== undefined && licenseItemId !== null) {
            queryParameters = queryParameters.set('licenseItemId', <any>licenseItemId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/organisation/licenses/license_item`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * add invoice specific details
     * get the details of organisation invoice
     * @param licenseId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getLicense(licenseId: number, observe?: 'body', reportProgress?: boolean): Observable<License>;
    public getLicense(licenseId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<License>>;
    public getLicense(licenseId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<License>>;
    public getLicense(licenseId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (licenseId === null || licenseId === undefined) {
            throw new Error('Required parameter licenseId was null or undefined when calling getLicense.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (licenseId !== undefined && licenseId !== null) {
            queryParameters = queryParameters.set('licenseId', <any>licenseId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<License>(`${this.basePath}/organisation/licenses/license`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * add invoice specific details
     * get the details of organisation invoice
     * @param licenseItemId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getLicenseItem(licenseItemId: number, observe?: 'body', reportProgress?: boolean): Observable<LicenseItem>;
    public getLicenseItem(licenseItemId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LicenseItem>>;
    public getLicenseItem(licenseItemId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LicenseItem>>;
    public getLicenseItem(licenseItemId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (licenseItemId === null || licenseItemId === undefined) {
            throw new Error('Required parameter licenseItemId was null or undefined when calling getLicenseItem.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (licenseItemId !== undefined && licenseItemId !== null) {
            queryParameters = queryParameters.set('licenseItemId', <any>licenseItemId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<LicenseItem>(`${this.basePath}/organisation/licenses/license_item`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete license_export_item details
     * Delete the license_export_item
     * @param licenseExportItemId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public licenseExportItem(licenseExportItemId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public licenseExportItem(licenseExportItemId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public licenseExportItem(licenseExportItemId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public licenseExportItem(licenseExportItemId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (licenseExportItemId === null || licenseExportItemId === undefined) {
            throw new Error('Required parameter licenseExportItemId was null or undefined when calling licenseExportItem.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (licenseExportItemId !== undefined && licenseExportItemId !== null) {
            queryParameters = queryParameters.set('licenseExportItemId', <any>licenseExportItemId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/organisation/licenses/license_export_item`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete license_import_item details
     * Delete the license_import_item
     * @param licenseImportItemId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public licenseImportItem(licenseImportItemId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public licenseImportItem(licenseImportItemId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public licenseImportItem(licenseImportItemId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public licenseImportItem(licenseImportItemId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (licenseImportItemId === null || licenseImportItemId === undefined) {
            throw new Error('Required parameter licenseImportItemId was null or undefined when calling licenseImportItem.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (licenseImportItemId !== undefined && licenseImportItemId !== null) {
            queryParameters = queryParameters.set('licenseImportItemId', <any>licenseImportItemId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/organisation/licenses/license_import_item`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all invoice
     * Get all the Invoice of an organisation
     * @param licenseStatus 
     * @param dateOfIssueTo 
     * @param dateOfIssueFrom 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listLicenses(licenseStatus?: string, dateOfIssueTo?: string, dateOfIssueFrom?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<License>>;
    public listLicenses(licenseStatus?: string, dateOfIssueTo?: string, dateOfIssueFrom?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<License>>>;
    public listLicenses(licenseStatus?: string, dateOfIssueTo?: string, dateOfIssueFrom?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<License>>>;
    public listLicenses(licenseStatus?: string, dateOfIssueTo?: string, dateOfIssueFrom?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (licenseStatus !== undefined && licenseStatus !== null) {
            queryParameters = queryParameters.set('licenseStatus', <any>licenseStatus);
        }
        if (dateOfIssueTo !== undefined && dateOfIssueTo !== null) {
            queryParameters = queryParameters.set('dateOfIssueTo', <any>dateOfIssueTo);
        }
        if (dateOfIssueFrom !== undefined && dateOfIssueFrom !== null) {
            queryParameters = queryParameters.set('dateOfIssueFrom', <any>dateOfIssueFrom);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<License>>(`${this.basePath}/organisation/licenses/list_licenses`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all invoice
     * Get all the license no of an organisation
     * @param searchQuery 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listLicensesNo(searchQuery?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<License>>;
    public listLicensesNo(searchQuery?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<License>>>;
    public listLicensesNo(searchQuery?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<License>>>;
    public listLicensesNo(searchQuery?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (searchQuery !== undefined && searchQuery !== null) {
            queryParameters = queryParameters.set('searchQuery', <any>searchQuery);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<License>>(`${this.basePath}/organisation/licenses/list_licenses_no`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all invoice
     * Get all the license no of an organisation
     * @param itemHSNCode 
     * @param itemType 
     * @param searchQuery 
     * @param excludeId 
     * @param licenseType 
     * @param isEPCG 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchLicensesNo(itemHSNCode: string, itemType: string, searchQuery?: string, excludeId?: string, licenseType?: string, isEPCG?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Array<License>>;
    public searchLicensesNo(itemHSNCode: string, itemType: string, searchQuery?: string, excludeId?: string, licenseType?: string, isEPCG?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<License>>>;
    public searchLicensesNo(itemHSNCode: string, itemType: string, searchQuery?: string, excludeId?: string, licenseType?: string, isEPCG?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<License>>>;
    public searchLicensesNo(itemHSNCode: string, itemType: string, searchQuery?: string, excludeId?: string, licenseType?: string, isEPCG?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (itemHSNCode === null || itemHSNCode === undefined) {
            throw new Error('Required parameter itemHSNCode was null or undefined when calling searchLicensesNo.');
        }

        if (itemType === null || itemType === undefined) {
            throw new Error('Required parameter itemType was null or undefined when calling searchLicensesNo.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (itemHSNCode !== undefined && itemHSNCode !== null) {
            queryParameters = queryParameters.set('itemHSNCode', <any>itemHSNCode);
        }
        if (itemType !== undefined && itemType !== null) {
            queryParameters = queryParameters.set('itemType', <any>itemType);
        }
        if (searchQuery !== undefined && searchQuery !== null) {
            queryParameters = queryParameters.set('searchQuery', <any>searchQuery);
        }
        if (excludeId !== undefined && excludeId !== null) {
            queryParameters = queryParameters.set('excludeId', <any>excludeId);
        }
        if (licenseType !== undefined && licenseType !== null) {
            queryParameters = queryParameters.set('licenseType', <any>licenseType);
        }
        if (isEPCG !== undefined && isEPCG !== null) {
            queryParameters = queryParameters.set('isEPCG', <any>isEPCG);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<License>>(`${this.basePath}/organisation/licenses/search_licenses_no`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update invoice specific details
     * Update the details of organisation invoice
     * @param License 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateLicense(License: License, observe?: 'body', reportProgress?: boolean): Observable<License>;
    public updateLicense(License: License, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<License>>;
    public updateLicense(License: License, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<License>>;
    public updateLicense(License: License, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (License === null || License === undefined) {
            throw new Error('Required parameter License was null or undefined when calling updateLicense.');
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<License>(`${this.basePath}/organisation/licenses/license`,
            License,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update invoice specific details
     * Update the details of organisation invoice
     * @param LicenseItem 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateLicenseItem(LicenseItem: LicenseItem, observe?: 'body', reportProgress?: boolean): Observable<LicenseItem>;
    public updateLicenseItem(LicenseItem: LicenseItem, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LicenseItem>>;
    public updateLicenseItem(LicenseItem: LicenseItem, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LicenseItem>>;
    public updateLicenseItem(LicenseItem: LicenseItem, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (LicenseItem === null || LicenseItem === undefined) {
            throw new Error('Required parameter LicenseItem was null or undefined when calling updateLicenseItem.');
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<LicenseItem>(`${this.basePath}/organisation/licenses/license_item`,
            LicenseItem,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update sion details of license items
     * Update the details of SION ratio of license items
     * @param License 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateSionRatio(License: License, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateSionRatio(License: License, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateSionRatio(License: License, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateSionRatio(License: License, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (License === null || License === undefined) {
            throw new Error('Required parameter License was null or undefined when calling updateSionRatio.');
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/organisation/licenses/update_sion_ratio`,
            License,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
