import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/swagger';
import { Router } from '@angular/router';
import { SwalHelper } from '../../swal';
import { GlobalProfileService } from '../../global-profile.service';

@Component({
  selector: 'app-change-password-setting',
  templateUrl: './change-password-setting.component.html',
  styleUrls: ['./change-password-setting.component.scss']
})
export class ChangePasswordSettingComponent implements OnInit {

  public oldPassword: string;
  public newPassword: string;
  public confirmPassword: string;

  constructor(
    private authApi: AuthService,
    private router: Router,
    public profileSync: GlobalProfileService,
  ) { }

  ngOnInit(): void {
  }

  changeOldPassword(form): boolean {
    if (form.valid) {
      this.authApi.changePasswordSetting(this.oldPassword, this.newPassword)
        .subscribe(ok => {
          SwalHelper.successTimerSwal('Password changed successfully!');
          this.profileSync.clearToken();
          this.router.navigate(['/login']);
        }, err => {
          SwalHelper.showErrorSwal(err);
        });
    } else {
      return false;
    }
  }

}
