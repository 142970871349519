import { Component, HostListener, OnInit, Renderer2 } from '@angular/core';
import * as moment from 'moment';
import { CommonSupportService } from 'src/app/common-support.service';
import { SwalHelper } from 'src/app/swal';
import { License, LicensesService } from 'src/swagger';

@Component({
  selector: 'app-licenses',
  templateUrl: './licenses.component.html',
  styleUrls: ['./licenses.component.scss']
})
export class LicensesComponent implements OnInit {

  public shouldShowLoader: boolean;

  public rightSheet = false;
  public selectedLicense: number;
  public allLicenses: License[] = [];

  public dateOfIssueTo: any;
  public dateOfIssueFrom: any;
  public licenseStatus: string;

  constructor(
    private renderer: Renderer2,
    public commonService: CommonSupportService,
    public licenseApi: LicensesService
  ) {
    commonService.renderer = renderer;
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent): void {
    this.rightSheet = false;
    this.renderer.removeClass(document.body, 'modal-open');
    this.commonService.removeBackdrop();
  }

  ngOnInit(): void {
    this.fetchAllLicenses();
  }

  // Fn to fetch all Contacts
  fetchAllLicenses(): void {
    this.allLicenses = [];
    this.shouldShowLoader = true;
    this.licenseApi.listLicenses(
      this.licenseStatus ? this.licenseStatus : null,
      this.dateOfIssueTo ? moment(this.dateOfIssueTo).format('YYYY-MM-DD') : null,
      this.dateOfIssueFrom ? moment(this.dateOfIssueFrom).format('YYYY-MM-DD') : null,
    ).subscribe(contacts => {
      this.shouldShowLoader = false;
      if (contacts && contacts.length > 0) {
        this.allLicenses = contacts;
      } else {
        this.allLicenses = null;
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /* Fn to reset the filter option */
  resetFilter(): void {
    this.dateOfIssueFrom = undefined;
    this.dateOfIssueTo = undefined;
    this.licenseStatus = undefined;
    this.fetchAllLicenses();
  }

  /* Fn to open the license right sheet */
  rightSheetOpen(): void {
    this.rightSheet = !this.rightSheet;
    this.renderer.addClass(document.body, 'modal-open');
    this.commonService.addBackdrop();
  }

  /* Fn to close the license right sheet */
  rightSheetClose(): void {
    this.rightSheet = false;
    this.renderer.removeClass(document.body, 'modal-open');
    this.commonService.removeBackdrop();
    this.selectedLicense = undefined;
  }

  /* Fn to add the new license & open right sheet */
  addNewLicense(): void {
    this.selectedLicense = undefined;
    this.rightSheetOpen();
  }

  /* Fn to edit the new license & open right sheet */
  editDetails(index: number): void {
    this.selectedLicense = this.allLicenses[index].licenseId;
    this.rightSheetOpen();
  }

   // Fn to receive updated contact data
   receivedContactData(license): void {
    this.fetchAllLicenses();
    this.rightSheetClose();
  }
}
