import { Component, OnInit, Renderer2, HostListener, OnDestroy } from '@angular/core';
import { CommonSupportService } from 'src/app/common-support.service';
import { Invoice, OrganisationService, OrgInvoiceService } from 'src/swagger';
import { SwalHelper } from 'src/app/swal';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { GlobalProfileService } from 'src/app/global-profile.service';
import { OrganisationHelperService } from '../organisation-helper.service';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { Subscription } from 'rxjs';
declare var $: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {

  public rightSheet = false;

  public invoiceFileName;
  public dashboardCurrency = 'USD';
  public chartLimit = 6;
  public activeTab = 'export';

  public invoiceFile: File;
  public shouldShowLoader: boolean;

  public invoice: Invoice = {};
  public stats: Stats = {};

  public invoiceCreationtype: string;
  public shouldShowProfileFill = false;

  public filterVisibility = false;

  public recentTransactions: Invoice[] = [];

  dtOptions: DataTables.Settings = {};
  private userSubscription: Subscription;

  constructor(
    private commonService: CommonSupportService,
    private renderer: Renderer2,
    private orgApi: OrganisationService,
    public router: Router,
    private orgInvApi: OrgInvoiceService,
    public profileSync: GlobalProfileService,
    public orgHelper: OrganisationHelperService
  ) {
    commonService.renderer = renderer;
  }

  /* --------------export invoices---------------------- */
  public invoicesChartOptions: any = {
    responsive: true,
    scales: {
      xAxes: [
        {
          barPercentage: 0.4,
        }
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            userCallback(value, index, values) {
              value = value.toString();
              value = value.split(/(?=(?:...)*$)/);
              value = value.join(',');
              return value;
            },
          }
        }
      ]
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 30,
        bottom: 0
      }
    },
    legend: false,
    tooltips: {
      callbacks: {
        label(tooltipItem, data) {
          tooltipItem.yLabel = tooltipItem.yLabel.toFixed(2);
          const res = tooltipItem.yLabel.toString().split('.');
          if (res.length === 1 || res[1].length < 3) {
            tooltipItem.yLabel = parseFloat(tooltipItem.yLabel).toFixed(2);
          }
          return tooltipItem.yLabel.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        },
      },
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    }
  };
  public invoicesChartLabels: any[] = [];
  public invoicesChartType = 'bar';
  public invoicesChartLegend = true;
  public invoicesChartPlugins = [pluginDataLabels];

  public invoicesChartData: any[] = [
    { data: [], label: 'Exports per Month' }
  ];

  public invoicesChartColors: Array<any> = [
    {
      backgroundColor: 'rgba(77, 99, 243, 0.1)',
      borderColor: 'rgba(77, 99, 243, 0.8)',
      borderWidth: '1'
    },
  ];
  /* --------------vendor bills---------------------- */
  public vendorBillChartOptions: any = {
    responsive: true,
    scales: {
      xAxes: [
        {
          barPercentage: 0.4,
        }
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            userCallback(value, index, values) {
              value = value.toString();
              value = value.split(/(?=(?:...)*$)/);
              value = value.join(',');
              return value;
            },
          }
        }
      ]
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 30,
        bottom: 0
      }
    },
    legend: false,
    tooltips: {
      callbacks: {
        label(tooltipItem, data) {
          tooltipItem.yLabel = tooltipItem.yLabel.toFixed(2);
          const res = tooltipItem.yLabel.toString().split('.');
          if (res.length === 1 || res[1].length < 3) {
            tooltipItem.yLabel = parseFloat(tooltipItem.yLabel).toFixed(2);
          }
          return tooltipItem.yLabel.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        },
      },
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    }
  };
  public vendorBillChartLabels: any[] = [];
  public vendorBillChartType = 'bar';
  public vendorBillChartLegend = true;
  public vendorBillChartPlugins = [pluginDataLabels];

  public vendorBillChartData: any[] = [
    { data: [], label: 'Vendor Bill per Month' }
  ];

  public vendorBillChartColors: Array<any> = [
    {
      backgroundColor: 'rgba(238,206,0, 0.1)',
      borderColor: 'rgba(238,206,0, 0.8)',
      borderWidth: '1'
    },
  ];
  /* --------------export orders---------------------- */
  public expOrderChartOptions: any = {
    responsive: true,
    scales: {
      xAxes: [
        {
          barPercentage: 0.4,
        }
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            userCallback(value, index, values) {
              value = value.toString();
              value = value.split(/(?=(?:...)*$)/);
              value = value.join(',');
              return value;
            },
          }
        }
      ]
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 30,
        bottom: 0
      }
    },
    legend: false,
    tooltips: {
      callbacks: {
        label(tooltipItem, data) {
          tooltipItem.yLabel = tooltipItem.yLabel.toFixed(2);
          const res = tooltipItem.yLabel.toString().split('.');
          if (res.length === 1 || res[1].length < 3) {
            tooltipItem.yLabel = parseFloat(tooltipItem.yLabel).toFixed(2);
          }
          return tooltipItem.yLabel.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        },
      },
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    }
  };
  public expOrderChartLabels: any[] = [];
  public expOrderChartType = 'bar';
  public expOrderChartLegend = true;
  public expOrderChartPlugins = [pluginDataLabels];

  public expOrderChartData: any[] = [
    { data: [], label: 'Exports per Month' }
  ];

  public expOrderChartColors: Array<any> = [
    {
      backgroundColor: 'rgba(77,99,243, 0.1)',
      borderColor: 'rgba(77,99,243, 0.8)',
      borderWidth: '1'
    },
  ];
  /* --------------import orders---------------------- */
  public impOrderChartOptions: any = {
    responsive: true,
    scales: {
      xAxes: [
        {
          barPercentage: 0.4,
        }
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            userCallback(value, index, values) {
              value = value.toString();
              value = value.split(/(?=(?:...)*$)/);
              value = value.join(',');
              return value;
            },
          }
        }
      ]
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 30,
        bottom: 0
      }
    },
    legend: false,
    tooltips: {
      callbacks: {
        label(tooltipItem, data) {
          tooltipItem.yLabel = tooltipItem.yLabel.toFixed(2);
          const res = tooltipItem.yLabel.toString().split('.');
          if (res.length === 1 || res[1].length < 3) {
            tooltipItem.yLabel = parseFloat(tooltipItem.yLabel).toFixed(2);
          }
          return tooltipItem.yLabel.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        },
      },
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    }
  };
  public impOrderChartLabels: any[] = [];
  public impOrderChartType = 'bar';
  public impOrderChartLegend = true;
  public impOrderChartPlugins = [pluginDataLabels];

  public impOrderChartData: any[] = [
    { data: [], label: 'Imports per Month' }
  ];

  public impOrderChartColors: Array<any> = [
    {
      backgroundColor: 'rgba(238,206,0, 0.1)',
      borderColor: 'rgba(238,206,0, 0.8)',
      borderWidth: '1'
    },
  ];

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent): void {
    this.rightSheet = false;
    this.renderer.removeClass(document.body, 'modal-open');
  }


  ngOnInit(): void {
    this.invoiceCreationtype = 'new';
    $('#introModal').on('hide.bs.modal', function(e) {
      $('#introVideo').get(0).pause();
    });
    $('#introModal').on('shown.bs.modal', function(e) {
      $('#introVideo').get(0).play();
    });
    this.initInv();
    this.userSubscription = this.profileSync.user$.subscribe(user => {
      if (user && user.isInternal) {
        // tslint:disable-next-line: max-line-length
        if (user.Organisation && (!user.Organisation.registeredAddress || !user.Organisation.city || !user.Organisation.state || !user.Organisation.country)) {
          this.shouldShowProfileFill = true;
        }
      }
    });

    this.dtOptions = {
      columnDefs: [
        { targets: [5, 6], type: 'num-fmt' },
      ],
    };
    this.filterVisibility = false;
  }

  toggleFilter(): void {
    this.filterVisibility = false;
  }

  ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }
  // Function to get Export dashboard statistics
  getExportCards(): void {
    this.stats = {};
    this.activeTab = 'Export';
    this.filterVisibility = true;
    this.shouldShowLoader = true;
    this.orgApi.dashboardExportCards(
      this.dashboardCurrency,
      this.chartLimit
    ).subscribe(stats => {
      this.shouldShowLoader = false;
      this.stats = stats;
      this.invoicesChartData[0].data = [];
      this.invoicesChartLabels = [];

      this.expOrderChartData[0].data = [];
      this.expOrderChartLabels = [];

      const past6MonthInvoice = stats.sixMonthInvoice;

      past6MonthInvoice.reverse().forEach(element => {
        this.invoicesChartLabels.push(element.Month);
        this.invoicesChartData[0].data.push(element.totalAmount.toFixed(2));
      });

      stats.sixMonthSO.reverse().forEach(element => {
        this.expOrderChartLabels.push(element.Month);
        this.expOrderChartData[0].data.push(element.soTotalAmount.toFixed(2));
      });

    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }
  /* Fn to get the import dashboard statistics */
  getImportCards(): void {
    this.stats = {};
    this.activeTab = 'Import';
    this.shouldShowLoader = true;
    this.filterVisibility = true;
    this.orgApi.dashboardImportCards(
      this.dashboardCurrency,
      this.chartLimit
    ).subscribe(stats => {
      this.shouldShowLoader = false;
      this.stats = stats;

      this.vendorBillChartData[0].data = [];
      this.vendorBillChartLabels = [];

      this.impOrderChartData[0].data = [];
      this.impOrderChartLabels = [];

      const sixMonthVB = [];
      stats.sixMonthVB.reverse().forEach(element => {
        this.vendorBillChartLabels.push(element.Month);
        sixMonthVB.push(element.totalAmount.toFixed(2));
      });

      this.vendorBillChartData[0].data = sixMonthVB;

      const sixMonthPO = [];
      stats.sixMonthPO.reverse().forEach(element => {
        this.impOrderChartLabels.push(element.Month);
        sixMonthPO.push(element.poTotalAmount.toFixed(2));
      });

      this.impOrderChartData[0].data = sixMonthPO;

    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }
  /* Fn to get the recent transaction */
  getRecentTransaction(): void {
    this.recentTransactions = [];
    this.activeTab = 'Recent';

    this.shouldShowLoader = true;
    this.orgApi.recentTransaction(
      this.dashboardCurrency
    ).subscribe(invoices => {
      this.shouldShowLoader = false;
      if (invoices.length > 0) {
        this.recentTransactions = invoices;
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  // Open Shipment rightsheet
  rightSheetOpen(): void {
    this.rightSheet = !this.rightSheet;
    this.renderer.addClass(document.body, 'modal-open');
    this.commonService.addBackdrop();
  }
  // Close shipment rightsheet
  rightSheetClose(): void {
    this.rightSheet = false;
    this.renderer.removeClass(document.body, 'modal-open');
    this.commonService.removeBackdrop();
  }
  // Store file on import invoice file upload
  fileChange(event): void {
    this.invoiceFileName = event.target.files[0].name;
    this.invoiceFile = event.target.files[0];
  }
  // Function to Add/Import new Invoice
  newInvoice(): void {
    $('#invoiceModal').modal('hide');
    this.shouldShowLoader = true;
    this.invoice.invoiceType = 'SALES';
    this.invoice.templateName = '1';
    if (this.invoiceCreationtype === 'new') {
      this.invoice.dateOfInv = moment(this.invoice.dateOfInv).format('YYYY-MM-DD');
      this.orgInvApi.createInvoice(
        this.invoice
      ).subscribe(inv => {
        if (inv) {
          this.shouldShowLoader = false;
          this.invoice = inv;
          this.router.navigate([`/organisation/invoices/edit-invoice/${this.invoice.invId}`]);
        }
      }, err => {
        this.shouldShowLoader = false;
        SwalHelper.showErrorSwal(err);
      });
    } else {
      this.orgInvApi.importInvoice(
        this.invoiceFile,
        this.invoice.modeOfShipment,
        this.invoice.currency,
        'SALES',
        this.invoice.invNo
      ).subscribe(inv => {
        if (inv) {
          this.shouldShowLoader = false;
          this.invoice = inv;
          this.router.navigate([`/organisation/invoices/edit-invoice/${this.invoice.invId}`]);
        }
      }, err => {
        this.shouldShowLoader = false;
        SwalHelper.showErrorSwal(err);
      });
    }
  }
/* Fn to initialize the new invoice */
  initInv(): void {
    this.invoiceCreationtype = 'new';
    this.invoice.modeOfShipment = 'Sea';
    this.invoice.currency = this.invoice.currency ? this.invoice.currency : 'USD';
    this.invoice.templateName = '1';
    this.invoice.invoiceType = 'SALES';
    this.invoice.dateOfInv = moment().format('YYYY-MM-DD');
  }
  /* Function to detect the currency change & fetch statistics accordingly */
  detectCurrencyChange(): void {
    if (this.activeTab === 'Export') {
      this.getExportCards();
    } else if (this.activeTab === 'Import') {
      this.getImportCards();
    } else {
      this.getRecentTransaction();
    }
  }

  /* Fn to get the invoice chart data based on month dropdwon */
  changeInvoiceChartLimit(limit: number): void {
    this.invoicesChartData[0].data = [];
    this.invoicesChartLabels = [];
    const pastMonthInvoice = [...this.stats.sixMonthInvoice];

    pastMonthInvoice.reverse().slice(0, limit).reverse().forEach(element => {
      this.invoicesChartLabels.push(element.Month);
      this.invoicesChartData[0].data.push(element.totalAmount.toFixed(2));
    });
  }

  /* Fn to get the vendor bill chart data based on month dropdown */
  changeVBChartLimit(limit: number): void {
    this.vendorBillChartData[0].data = [];
    this.vendorBillChartLabels = [];

    const pastMonthVB = [...this.stats.sixMonthVB];

    pastMonthVB.reverse().slice(0, limit).reverse().forEach(element => {
      this.vendorBillChartLabels.push(element.Month);
      this.vendorBillChartData[0].data.push(element.totalAmount.toFixed(2));
    });
  }

  /* Fn to get the Export order chart data based on month dropdown */
  changeExpOrderChartLimit(limit: number): void {
    this.expOrderChartData[0].data = [];
    this.expOrderChartLabels = [];
    const pastMonthSO = [...this.stats.sixMonthSO];

    pastMonthSO.reverse().slice(0, limit).reverse().forEach(element => {
      this.expOrderChartLabels.push(element.Month);
      this.expOrderChartData[0].data.push(element.soTotalAmount.toFixed(2));
    });
  }

  /* Fn to get the Import order chart data based on month dropdown */
  changeImpOrderChartLimit(limit: number): void {
    this.impOrderChartData[0].data = [];
    this.impOrderChartLabels = [];
    const pastMonthPO = [...this.stats.sixMonthPO];

    pastMonthPO.reverse().slice(0, limit).reverse().forEach(element => {
      this.impOrderChartLabels.push(element.Month);
      this.impOrderChartData[0].data.push(element.poTotalAmount.toFixed(2));
    });
  }
}

export class Stats {
  exports?: number;
  imports?: number;
  overduePurchaseAmount?: Array<any>;
  overdueSaleAmount?: Array<any>;
  payables?: Array<any>;
  receivables?: Array<any>;
  poTotalValue?: Array<any>;
  soTotalValue?: Array<any>;
  shipmentValue?: Array<any>;
  totalPo?: number;
  totalSo?: number;
  totalShipments?: number;
  totalVendorAdded?: number;
  totalUser?: number;
  overdueAmount?: number;
  totalInvoice?: number;
  totalSO?: number;
  totalPO?: number;
  sixMonthInvoice?: any;
  sixMonthVB?: any;
  sixMonthSO?: any;
  sixMonthPO?: any;
  totalReceivableArray?: any;
  overdueAmountArray?: any;
  totalReceivable?: number;
  currencyShipments?: number;
  currencySO?: number;
  currencyInvoice?: number;
  currencyPO?: number;
  totalInvoiceAmount?: number;
}
