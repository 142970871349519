/**
 * Nuphi trade
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2.0.0
 * Contact: shraddha@blackcurrantapps.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { BillOfEntry } from './billOfEntry';
import { BillOfLading } from './billOfLading';
import { DocumentAttachment } from './documentAttachment';
import { InvoiceItem } from './invoiceItem';
import { InvoicePayment } from './invoicePayment';
import { LicenseExportItem } from './licenseExportItem';
import { LicenseImportItem } from './licenseImportItem';
import { OrderInvoiceMapping } from './orderInvoiceMapping';
import { Organisation } from './organisation';
import { ShipmentDocument } from './shipmentDocument';
import { ShippingBill } from './shippingBill';
import { StatusHistory } from './statusHistory';
import { VendorAndSupplier } from './vendorAndSupplier';


export interface Invoice { 
    invId?: number;
    invUid?: string;
    orgId?: number;
    buyerId?: number;
    consigneeId?: number;
    customsConsigneeId?: number;
    companyName?: string;
    IECcode?: string;
    address?: string;
    companyLogo?: string;
    notifyPartyId1?: number;
    notifyPartyId2?: number;
    customsNotifyPartyId1?: number;
    customsNotifyPartyId2?: number;
    invNo?: string;
    invName?: string;
    invoiceType?: Invoice.InvoiceTypeEnum;
    invoiceStatus?: Invoice.InvoiceStatusEnum;
    dateOfInv?: string;
    dueDate?: string;
    expDeliveryDate?: string;
    subtotalAmount?: number;
    totalAmount?: number;
    balanceAmount?: number;
    advanceAmount?: number;
    advanceAmountLabel?: string;
    advanceAmountDate?: string;
    customsSubtotalAmount?: number;
    customsTotalAmount?: number;
    amountInWords?: string;
    orderDate?: string;
    orderNo?: string;
    currency?: string;
    discountValue?: number;
    customsDiscountValue?: number;
    discountPercentage?: number;
    blNo?: string;
    blDate?: string;
    shippingBillNumber?: string;
    shippingBillDate?: string;
    beNumber?: string;
    billOfEntryDate?: string;
    insuranceValue?: number;
    fobValue?: number;
    freight?: number;
    freightUnitPrice?: number;
    freightTotalQty?: number;
    cifValue?: number;
    totalGrossWeight?: number;
    totalNetWeight?: number;
    customsTotalGrossWeight?: number;
    customsTotalNetWeight?: number;
    vesselNo?: string;
    containerNo?: string;
    flightNo?: string;
    vesselName?: string;
    paymentTerms?: string;
    incoTerms?: string;
    incoTermsPlace?: string;
    modeOfShipment?: string;
    seaContainerType?: string;
    finalDestination?: string;
    creditPeriod?: number;
    assignmentClause?: string;
    rexNumber?: string;
    rexClause?: string;
    specialTerms?: string;
    meisClause?: string;
    originCountry?: string;
    portOfLoading?: string;
    destinationPort?: string;
    destinationCountry?: string;
    voyageNo?: string;
    comment?: string;
    customsDeliveyInstruction?: string;
    customsPLDeliveyInstruction?: string;
    comPLDeliveyInstruction?: string;
    comDelLabel?: string;
    customsDelLabel?: string;
    customsPLDelLabel?: string;
    comPLDelLabel?: string;
    paymentInfo?: any;
    customsPaymentInfo?: any;
    qrCode?: string;
    sealNo?: string;
    otherData?: any;
    importedDocUrl?: string;
    ocrS3FolderUrl?: string;
    numPages?: number;
    ocrStatus?: string;
    templateName?: string;
    customInvHeading?: string;
    customInvSubHeading?: string;
    customInvPLHeading?: string;
    customInvPLSubHeading?: string;
    lutNo?: string;
    lutDate?: string;
    exchangeRate?: number;
    exchangeRateUSD?: number;
    amountInWordsINR?: string;
    noOfPackagesDes?: string;
    quantityDescription?: string;
    quantityPerPackDes?: string;
    customsQntyDes?: string;
    customsTotalQntyDes?: string;
    customsUnitPriceDes?: string;
    customsPLNetWeightDes?: string;
    customsPLGrossWeightDes?: string;
    customsNoOfPackagesDes?: string;
    packingListHeading?: string;
    packingListSubHeading?: string;
    comInvSubHeading?: string;
    comPLNetWeightDes?: string;
    comPLGrossWeightDes?: string;
    licenses?: string;
    unitPriceDes?: string;
    isOrderNoVisible?: boolean;
    isNoOfPackagesVisible?: boolean;
    isQtyPerPackageVisible?: boolean;
    advanceLicenseDetails?: any;
    isQtyPerPackComPlVisible?: boolean;
    isQtyPerPackCusPlVisible?: boolean;
    isQtyComPlVisible?: boolean;
    isQtyCusPlVisible?: boolean;
    isGSTValueVisible?: boolean;
    isTotalINRVisible?: boolean;
    isAmountINRVisible?: boolean;
    isGSTVisible?: boolean;
    isItemCodeVisible?: boolean;
    Buyer?: VendorAndSupplier;
    Consignee?: VendorAndSupplier;
    NotifyParty1?: VendorAndSupplier;
    NotifyParty2?: VendorAndSupplier;
    CustomsConsignee?: VendorAndSupplier;
    CustomsNotifyParty1?: VendorAndSupplier;
    CustomsNotifyParty2?: VendorAndSupplier;
    BillOfEntry?: BillOfEntry;
    BillOfLading?: BillOfLading;
    ShippingBill?: ShippingBill;
    Organisation?: Organisation;
    InvoicePayments?: Array<InvoicePayment>;
    InvoiceItems?: Array<InvoiceItem>;
    OrderInvoiceMappings?: Array<OrderInvoiceMapping>;
    StatusHistories?: Array<StatusHistory>;
    DocumentAttachments?: Array<DocumentAttachment>;
    ShipmentDocuments?: Array<ShipmentDocument>;
    LicenseExportItems?: Array<LicenseExportItem>;
    LicenseImportItems?: Array<LicenseImportItem>;
}
export namespace Invoice {
    export type InvoiceTypeEnum = 'SALES' | 'PURCHASE';
    export const InvoiceTypeEnum = {
        SALES: 'SALES' as InvoiceTypeEnum,
        PURCHASE: 'PURCHASE' as InvoiceTypeEnum
    };
    export type InvoiceStatusEnum = 'DRAFT' | 'ACCEPTED' | 'PARTIALLY_PAID' | 'OVERDUE' | 'FULLY_PAID' | 'DISPUTED' | 'ARCHIVED';
    export const InvoiceStatusEnum = {
        DRAFT: 'DRAFT' as InvoiceStatusEnum,
        ACCEPTED: 'ACCEPTED' as InvoiceStatusEnum,
        PARTIALLYPAID: 'PARTIALLY_PAID' as InvoiceStatusEnum,
        OVERDUE: 'OVERDUE' as InvoiceStatusEnum,
        FULLYPAID: 'FULLY_PAID' as InvoiceStatusEnum,
        DISPUTED: 'DISPUTED' as InvoiceStatusEnum,
        ARCHIVED: 'ARCHIVED' as InvoiceStatusEnum
    };
}
