import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalProfileService } from 'src/app/global-profile.service';
import { SwalHelper } from 'src/app/swal';
import { environment } from 'src/environments/environment';
import { OrganisationService } from 'src/swagger';
declare var $: any;
@Component({
  selector: 'app-invoice-templates',
  templateUrl: './invoice-templates.component.html',
  styleUrls: ['./invoice-templates.component.scss']
})
export class InvoiceTemplatesComponent implements OnInit {

  public shouldShowLoader = false;

  public outputHTML: any;
  public basePath: string;

  public templateId: number;
  public templateName: string;
  public templateData: {};

  public colorValue = '1C242A';
  public fontValue = 'Nunito';

  constructor(
    public http: HttpClient,
    private orgApi: OrganisationService,
    private profileSync: GlobalProfileService,
    private router: Router
  ) {
    this.basePath = environment.BASE_PATH;
  }

  ngOnInit(): void {
    this.profileSync.user$.subscribe(user => {
      if (user && user.Organisation && user.Organisation.templateData) {
        this.templateData = user.Organisation.templateData;
      }
    });
  }

  /* Fn to extract the color & font value */
  extractData(id, name): void {
    this.templateId = id;
    this.templateName = name;
    // tslint:disable-next-line: no-string-literal
    this.colorValue = this.templateData['Invoice'][this.templateId].colorValue;
    // tslint:disable-next-line: no-string-literal
    this.fontValue = this.templateData['Invoice'][this.templateId].fontValue;
    this.fetchTemplate();
  }

  /* Fn to fetch the template */
  fetchTemplate(): void {
    const headers = new HttpHeaders().set('Authorization', this.orgApi.configuration.apiKeys.Authorization);

    this.shouldShowLoader = true;
    this.http.post(`${this.basePath}/organisation/templates/Invoice/${this.templateId}`, {
      colorValue: this.colorValue,
      fontValue: this.fontValue
    }, {
      responseType: 'text',
      headers,
      observe: 'body'
    }).subscribe(data => {
      $('#template1Modal').modal('show');
      this.shouldShowLoader = false;
      this.outputHTML = data;
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /* Fn to navigate the template for edit */
  editTemplate(): void {
    $('#template1Modal').modal('hide');
    this.router.navigate([`organisation/master/template-master/edit-template/Invoice/${this.templateId}`]);
  }

}
