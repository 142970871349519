import { AfterViewInit, Component, OnInit, EventEmitter, Output } from '@angular/core';
import { BulkItemSyncService } from '../bulk-item-sync.service';
import { Subject } from 'rxjs';
import * as stringSimilarity from 'string-similarity';
import * as XLSX from 'xlsx';
import { InvoiceItem } from '../../../../swagger/model/invoiceItem';
import _ from 'lodash/array';
import { OrganisationService } from '../../../../swagger/api/organisation.service';
import { ExcelUploadResult } from '../../../../swagger/model/excelUploadResult';
declare var Swal: any;

@Component({
  selector: 'app-item-bulk-upload',
  templateUrl: './item-bulk-upload.component.html',
  styleUrls: ['./item-bulk-upload.component.scss']
})
export class ItemBulkUploadComponent implements OnInit, AfterViewInit {

  @Output() closeUploadToggle = new EventEmitter<string>();

  public excelItemsHeaders = [
    'itemCode',
    'itemName',
    'itemHSNCode',
    'itemDescription',
    'itemPrice',
    'itemUnit',
    'itemQuantity',
    'gst',
    'netWeight',
    'grossWeight',
    'noOfPackages',
    'itemQuantityPerPack',
    'IGNORE'
  ];

  public SELECTED_COLUMNS = [];

  private fileSubject = new Subject<File>();
  public file: File;
  public file$ = this.fileSubject.asObservable();
  public fileName = '';

  public sheetData = [];
  public isLoadingSheetData = false;

  public shouldShowLoader = false;

  public invoiceItems: InvoiceItem[] = [];
  public previewResult: ExcelUploadResult = {};

  public customFields = [];

  constructor(
    private itemBulkSync: BulkItemSyncService,
    private orgApi: OrganisationService
  ) {
    this.file$.subscribe((file) => {
      this.file = file;
      this.fileName = file.name;
      const reader: FileReader = new FileReader();

      reader.onload = (e: any) => {
        this.isLoadingSheetData = true;
        /* read workbook */
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
        /* save data */
        this.sheetData = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], {
          raw: false,
          header: 1,
          blankrows: false,
          dateNF: 'yyyy-mm-dd'
        });

        this.findBestMatchColumns();
        this.setResult();
      };

      this.isLoadingSheetData = false;
      reader.readAsBinaryString(file);
    });
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.itemBulkSync.customFields$.subscribe(fields => {
      this.customFields = fields;
      if (fields && fields.length > 0) {
        this.excelItemsHeaders = this.excelItemsHeaders.concat(fields.map(i => i['key']));
      }
    });
  }

  public setFile(event: any): void {
    this.shouldShowLoader = true;
    this.fileSubject.next(event.srcElement.files[0]);
  }

  private findBestMatchColumns(): void {
    this.SELECTED_COLUMNS = this.sheetData[0].map((colName: any) => {
      if (stringSimilarity.findBestMatch(colName, this.excelItemsHeaders).bestMatch.rating >= 0.4) {
        return stringSimilarity.findBestMatch(colName, this.excelItemsHeaders).bestMatch.target;
      } else {
        return 'IGNORE';
      }
    });
  }

  setResult(): void {
    this.isLoadingSheetData = false;
    this.shouldShowLoader = true;
    this.invoiceItems = [];
    const {
      formattedData
    } = this.applyMapping(this.sheetData, this.SELECTED_COLUMNS);

    formattedData.shift();
    this.orgApi.uploadItemMasterExcel(
      this.file,
      false,
      true,
      JSON.stringify(this.SELECTED_COLUMNS))
      .subscribe((result) => {
        this.shouldShowLoader = false;
        this.previewResult = result;
        formattedData.map(data => {
          this.invoiceItems.push({
            itemCode: data.itemCode,
            itemHSNCode: data.itemHSNCode,
            itemName: data.itemName,
            itemUnit: data.itemUnit,
            customsItemUnit: data.itemUnit,
            itemPrice: data.itemPrice ? parseFloat(data.itemPrice.toString().replace(',', '')) : 1,
            customsItemPrice: data.itemPrice ? parseFloat(data.itemPrice.toString().replace(',', '')) : 1,
            noOfPackages: data.noOfPackages ? parseFloat(data.noOfPackages.toString().replace(',', '')) : 1,
            itemQuantityPerPack: data.itemQuantityPerPack ? parseFloat(data.itemQuantityPerPack.toString().replace(',', '')) : 1,
            itemCustQuantityPerPack: data.itemQuantityPerPack ? parseFloat(data.itemQuantityPerPack.toString().replace(',', '')) : 1,
            gst: data.gst ? parseFloat(data.gst.toString().replace(',', '')) : 1,
            itemDescription: data.itemDescription,
            itemCustomsDescription: data.itemDescription,
            itemPackingListDescription: data.itemDescription,
            itemCustPLDescription: data.itemDescription,
            itemQuantity: data.itemQuantity ? parseFloat(data.itemQuantity.toString().replace(',', '')) : 0,
            itemCustTotalQuantity: data.itemQuantity ? parseFloat(data.itemQuantity.toString().replace(',', '')) : 0,
            netWeight: data.netWeight ? parseFloat(data.netWeight.toString().replace(',', '')) : 0,
            custNetWeight: data.netWeight ? parseFloat(data.netWeight.toString().replace(',', '')) : 0,
            grossWeight: data.grossWeight ? parseFloat(data.grossWeight.toString().replace(',', '')) : 0,
            custGrossWeight: data.grossWeight ? parseFloat(data.grossWeight.toString().replace(',', '')) : 0,
            advanceLicenseDetails: {},
            itemMasterId: this.previewResult.skippedItemMasters && this.previewResult.skippedItemMasters.filter(i => i.itemCode === data.itemCode).length > 0 ? this.previewResult.skippedItemMasters.filter(i => i.itemCode === data.itemCode)[0].itemMasterId : null,
            otherData: this.customFields.map(i => {
              return {
                key: i.key,
                pattern: i.pattern,
                required: i.required,
                type: i.type,
                value: data[i.key],
              };
            })
          });
        });
      }, (err) => {
        Swal.fire({
          title: 'Whoops',
          html: err.error ? err.error.reduce((msg, er) => msg + '<br' + er) : err.message,
          type: 'error',
        });
      });
  }

  upload(): void {
    this.shouldShowLoader = true;
    this.orgApi.uploadItemMasterExcel(
      this.file,
      false,
      false,
      JSON.stringify(this.SELECTED_COLUMNS))
      .subscribe((result) => {
        this.invoiceItems.forEach(item => {
          if (!item.itemMasterId && result.newItemMasters.filter(i => i.itemCode === item.itemCode).length > 0) {
            return item.itemMasterId = result.newItemMasters.filter(i => i.itemCode === item.itemCode)[0].itemMasterId;
          }
        });
        this.itemBulkSync.invoiceItems$.next(this.invoiceItems);
        this.shouldShowLoader = false;
      }, (err) => {
        this.shouldShowLoader = false;
        Swal.fire({
          title: 'Whoops',
          html: err.error ? err.error.reduce((msg, er) => msg + '<br' + er) : err.message,
          type: 'error',
        });
      });
  }

  bottomSheetClose(): void {
    this.closeUploadToggle.next('Close shipment');
  }

  /**
 *
 * @param {Array<Array>} data AoA data from uploaded excel sheet
 * @param {Array} mapping order of columns ['invNo','InvDate'] can contain 'IGNORE'
 * @returns {formattedData, warnings} data of type [{columnName: value}]
 */
  public applyMapping(data, mapping): any {
    const mappingWarnings = [];
    const columnNametoIndex = {};
    const indexToColumnName = [];

    const columns = mapping;
    for (let i = 0; i < columns.length; i += 1) {
      // Removing duplicates column assignments if any
      if (columnNametoIndex.hasOwnProperty(columns[i])) {
        columns[i] = 'IGNORE';
        indexToColumnName.push('IGNORE');
      } else {
        indexToColumnName.push(columns[i]);
      }
      if (columns[i] === 'IGNORE') {
        mappingWarnings.push(`Ignoring excel column #${i}`);
      } else {
        // Prefix and column index
        columnNametoIndex[columns[i]] = i;
      }
    }

    const formattedData = data.map((rawRow) => {
      if (rawRow.length > 0) {
        const row = _.zipObject(indexToColumnName, rawRow);
        delete row.IGNORE;
        return row;
      }
    });

    return {
      formattedData,
      mappingWarnings,
    };
  }
}
