import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { OpenService } from 'src/swagger';
import { SwalHelper } from 'src/app/swal';
declare var Swal: any;

@Component({
  selector: 'app-verify-user',
  templateUrl: './verify-user.component.html',
  styleUrls: ['./verify-user.component.scss']
})
export class VerifyUserComponent implements OnInit {

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private openApi: OpenService
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      if (params.token) {
        this.verifyUser(params.token);
      }
    });
  }

  /**
   * Fn to verify user
   * @param token Token to be verified
   */
  verifyUser(token): void {
    Swal.fire({
      title: 'Verification in progress',
      type: 'info',
      timer: 3000,
      html: '<div class="spinner-border text-primary" role="status"><span class="sr-only">Loading...</span></div>',
      showConfirmButton: false,
    });
    this.openApi.verifyUser(token).subscribe(ok => {
      if (ok) {
        Swal.fire({
          title: 'Welcome! \n Login & start digitising & simplifying your business',
          type: 'success',
          showConfirmButton: true,
        }).then(result => {
          if (result.value) {
            this.router.navigate(['/login']);
          }
        });
      }
    }, err => {
        SwalHelper.showErrorSwal(err);
    });
  }
}
