/**
 * Nuphi trade
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2.0.0
 * Contact: shraddha@blackcurrantapps.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { BillOfEntry } from '../model/billOfEntry';
import { BillOfLading } from '../model/billOfLading';
import { ExcelUploadErrors } from '../model/excelUploadErrors';
import { ExcelUploadResult } from '../model/excelUploadResult';
import { Invoice } from '../model/invoice';
import { Shipment } from '../model/shipment';
import { ShippingBill } from '../model/shippingBill';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable({
  providedIn: 'root'
})
export class ShipmentDocsService {

    protected basePath = 'http://localhost:3000';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * get bol specific details with status history
     * get the details of organisation BOL with the status history
     * @param billOfLadingId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public bolStatusHistory(billOfLadingId: number, observe?: 'body', reportProgress?: boolean): Observable<BillOfLading>;
    public bolStatusHistory(billOfLadingId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BillOfLading>>;
    public bolStatusHistory(billOfLadingId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BillOfLading>>;
    public bolStatusHistory(billOfLadingId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (billOfLadingId === null || billOfLadingId === undefined) {
            throw new Error('Required parameter billOfLadingId was null or undefined when calling bolStatusHistory.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (billOfLadingId !== undefined && billOfLadingId !== null) {
            queryParameters = queryParameters.set('billOfLadingId', <any>billOfLadingId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<BillOfLading>(`${this.basePath}/organisation/shipmentDocs/bol_status_history`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * create billOfEntry
     * 
     * @param BillOfEntry 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createBillOfEntry(BillOfEntry: BillOfEntry, observe?: 'body', reportProgress?: boolean): Observable<BillOfEntry>;
    public createBillOfEntry(BillOfEntry: BillOfEntry, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BillOfEntry>>;
    public createBillOfEntry(BillOfEntry: BillOfEntry, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BillOfEntry>>;
    public createBillOfEntry(BillOfEntry: BillOfEntry, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (BillOfEntry === null || BillOfEntry === undefined) {
            throw new Error('Required parameter BillOfEntry was null or undefined when calling createBillOfEntry.');
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<BillOfEntry>(`${this.basePath}/organisation/shipmentDocs/bill_of_entry`,
            BillOfEntry,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * create billOfLading
     * 
     * @param BillOfLading 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createBillOfLading(BillOfLading: BillOfLading, observe?: 'body', reportProgress?: boolean): Observable<BillOfLading>;
    public createBillOfLading(BillOfLading: BillOfLading, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BillOfLading>>;
    public createBillOfLading(BillOfLading: BillOfLading, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BillOfLading>>;
    public createBillOfLading(BillOfLading: BillOfLading, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (BillOfLading === null || BillOfLading === undefined) {
            throw new Error('Required parameter BillOfLading was null or undefined when calling createBillOfLading.');
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<BillOfLading>(`${this.basePath}/organisation/shipmentDocs/bill_of_lading`,
            BillOfLading,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * create shipping bill
     * 
     * @param ShippingBill 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createShippingBill(ShippingBill: ShippingBill, observe?: 'body', reportProgress?: boolean): Observable<ShippingBill>;
    public createShippingBill(ShippingBill: ShippingBill, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ShippingBill>>;
    public createShippingBill(ShippingBill: ShippingBill, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ShippingBill>>;
    public createShippingBill(ShippingBill: ShippingBill, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (ShippingBill === null || ShippingBill === undefined) {
            throw new Error('Required parameter ShippingBill was null or undefined when calling createShippingBill.');
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ShippingBill>(`${this.basePath}/organisation/shipmentDocs/shipping_bill`,
            ShippingBill,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * delete billOfEntry
     * 
     * @param billOfEntryId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteBillOfEntry(billOfEntryId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteBillOfEntry(billOfEntryId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteBillOfEntry(billOfEntryId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteBillOfEntry(billOfEntryId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (billOfEntryId === null || billOfEntryId === undefined) {
            throw new Error('Required parameter billOfEntryId was null or undefined when calling deleteBillOfEntry.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (billOfEntryId !== undefined && billOfEntryId !== null) {
            queryParameters = queryParameters.set('billOfEntryId', <any>billOfEntryId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/organisation/shipmentDocs/bill_of_entry`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * delete billOfLading
     * 
     * @param billOfLadingId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteBillOfLading(billOfLadingId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteBillOfLading(billOfLadingId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteBillOfLading(billOfLadingId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteBillOfLading(billOfLadingId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (billOfLadingId === null || billOfLadingId === undefined) {
            throw new Error('Required parameter billOfLadingId was null or undefined when calling deleteBillOfLading.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (billOfLadingId !== undefined && billOfLadingId !== null) {
            queryParameters = queryParameters.set('billOfLadingId', <any>billOfLadingId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/organisation/shipmentDocs/bill_of_lading`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * update shipping bill
     * 
     * @param shippingBillId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteShippingBill(shippingBillId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteShippingBill(shippingBillId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteShippingBill(shippingBillId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteShippingBill(shippingBillId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (shippingBillId === null || shippingBillId === undefined) {
            throw new Error('Required parameter shippingBillId was null or undefined when calling deleteShippingBill.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (shippingBillId !== undefined && shippingBillId !== null) {
            queryParameters = queryParameters.set('shippingBillId', <any>shippingBillId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/organisation/shipmentDocs/shipping_bill`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * fetch shipment bill of entry
     * 
     * @param billOfEntryId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBillOfEntry(billOfEntryId: number, observe?: 'body', reportProgress?: boolean): Observable<BillOfEntry>;
    public getBillOfEntry(billOfEntryId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BillOfEntry>>;
    public getBillOfEntry(billOfEntryId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BillOfEntry>>;
    public getBillOfEntry(billOfEntryId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (billOfEntryId === null || billOfEntryId === undefined) {
            throw new Error('Required parameter billOfEntryId was null or undefined when calling getBillOfEntry.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (billOfEntryId !== undefined && billOfEntryId !== null) {
            queryParameters = queryParameters.set('billOfEntryId', <any>billOfEntryId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<BillOfEntry>(`${this.basePath}/organisation/shipmentDocs/bill_of_entry`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * fetch shipment bill of lading
     * 
     * @param billOfLadingId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBillOfLading(billOfLadingId: number, observe?: 'body', reportProgress?: boolean): Observable<BillOfLading>;
    public getBillOfLading(billOfLadingId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BillOfLading>>;
    public getBillOfLading(billOfLadingId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BillOfLading>>;
    public getBillOfLading(billOfLadingId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (billOfLadingId === null || billOfLadingId === undefined) {
            throw new Error('Required parameter billOfLadingId was null or undefined when calling getBillOfLading.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (billOfLadingId !== undefined && billOfLadingId !== null) {
            queryParameters = queryParameters.set('billOfLadingId', <any>billOfLadingId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<BillOfLading>(`${this.basePath}/organisation/shipmentDocs/bill_of_lading`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * fetch shipping bill
     * 
     * @param shippingBillId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getShippingBill(shippingBillId: number, observe?: 'body', reportProgress?: boolean): Observable<ShippingBill>;
    public getShippingBill(shippingBillId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ShippingBill>>;
    public getShippingBill(shippingBillId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ShippingBill>>;
    public getShippingBill(shippingBillId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (shippingBillId === null || shippingBillId === undefined) {
            throw new Error('Required parameter shippingBillId was null or undefined when calling getShippingBill.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (shippingBillId !== undefined && shippingBillId !== null) {
            queryParameters = queryParameters.set('shippingBillId', <any>shippingBillId);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<ShippingBill>(`${this.basePath}/organisation/shipmentDocs/shipping_bill`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Import Bill of Entry for OCR
     * 
     * @param attachment1 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public importBoe(attachment1: Blob, observe?: 'body', reportProgress?: boolean): Observable<BillOfEntry>;
    public importBoe(attachment1: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BillOfEntry>>;
    public importBoe(attachment1: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BillOfEntry>>;
    public importBoe(attachment1: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (attachment1 === null || attachment1 === undefined) {
            throw new Error('Required parameter attachment1 was null or undefined when calling importBoe.');
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (attachment1 !== undefined) {
            formParams.append('attachment1', <any>attachment1);
        }

        return this.httpClient.post<BillOfEntry>(`${this.basePath}/organisation/shipmentDocs/import_boe`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Import Bill of Lading for OCR
     * 
     * @param attachment1 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public importBol(attachment1: Blob, observe?: 'body', reportProgress?: boolean): Observable<BillOfLading>;
    public importBol(attachment1: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BillOfLading>>;
    public importBol(attachment1: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BillOfLading>>;
    public importBol(attachment1: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (attachment1 === null || attachment1 === undefined) {
            throw new Error('Required parameter attachment1 was null or undefined when calling importBol.');
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (attachment1 !== undefined) {
            formParams.append('attachment1', <any>attachment1);
        }

        return this.httpClient.post<BillOfLading>(`${this.basePath}/organisation/shipmentDocs/import_bol`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Import Shipping Bill for OCR
     * 
     * @param attachment1 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public importShippingBill(attachment1: Blob, observe?: 'body', reportProgress?: boolean): Observable<ShippingBill>;
    public importShippingBill(attachment1: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ShippingBill>>;
    public importShippingBill(attachment1: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ShippingBill>>;
    public importShippingBill(attachment1: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (attachment1 === null || attachment1 === undefined) {
            throw new Error('Required parameter attachment1 was null or undefined when calling importShippingBill.');
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (attachment1 !== undefined) {
            formParams.append('attachment1', <any>attachment1);
        }

        return this.httpClient.post<ShippingBill>(`${this.basePath}/organisation/shipmentDocs/import_shippingBill`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * list all bill of entry&#39;s
     * 
     * @param startDate 
     * @param endDate 
     * @param status 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listBillOfEntrys(startDate?: string, endDate?: string, status?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<BillOfEntry>>;
    public listBillOfEntrys(startDate?: string, endDate?: string, status?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<BillOfEntry>>>;
    public listBillOfEntrys(startDate?: string, endDate?: string, status?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<BillOfEntry>>>;
    public listBillOfEntrys(startDate?: string, endDate?: string, status?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (startDate !== undefined && startDate !== null) {
            queryParameters = queryParameters.set('startDate', <any>startDate);
        }
        if (endDate !== undefined && endDate !== null) {
            queryParameters = queryParameters.set('endDate', <any>endDate);
        }
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', <any>status);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<BillOfEntry>>(`${this.basePath}/organisation/shipmentDocs/bill_of_entry_list`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * list of bill of lading&#39;s
     * 
     * @param startDate 
     * @param endDate 
     * @param status 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listBillOfLadings(startDate?: string, endDate?: string, status?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<BillOfLading>>;
    public listBillOfLadings(startDate?: string, endDate?: string, status?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<BillOfLading>>>;
    public listBillOfLadings(startDate?: string, endDate?: string, status?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<BillOfLading>>>;
    public listBillOfLadings(startDate?: string, endDate?: string, status?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (startDate !== undefined && startDate !== null) {
            queryParameters = queryParameters.set('startDate', <any>startDate);
        }
        if (endDate !== undefined && endDate !== null) {
            queryParameters = queryParameters.set('endDate', <any>endDate);
        }
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', <any>status);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<BillOfLading>>(`${this.basePath}/organisation/shipmentDocs/bill_of_lading_list`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get all shipping bills
     * Get the all shipping bills of an organisation
     * @param startDate 
     * @param endDate 
     * @param status 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listShippingBill(startDate?: string, endDate?: string, status?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ShippingBill>>;
    public listShippingBill(startDate?: string, endDate?: string, status?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ShippingBill>>>;
    public listShippingBill(startDate?: string, endDate?: string, status?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ShippingBill>>>;
    public listShippingBill(startDate?: string, endDate?: string, status?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (startDate !== undefined && startDate !== null) {
            queryParameters = queryParameters.set('startDate', <any>startDate);
        }
        if (endDate !== undefined && endDate !== null) {
            queryParameters = queryParameters.set('endDate', <any>endDate);
        }
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', <any>status);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<ShippingBill>>(`${this.basePath}/organisation/shipmentDocs/list_shipping_bill`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Search invoices
     * 
     * @param type 
     * @param query 
     * @param exclude_ids comman seperated invoice nos
     * @param limit 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchInvoicesShipmentDocs(type?: string, query?: string, exclude_ids?: string, limit?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<Invoice>>;
    public searchInvoicesShipmentDocs(type?: string, query?: string, exclude_ids?: string, limit?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Invoice>>>;
    public searchInvoicesShipmentDocs(type?: string, query?: string, exclude_ids?: string, limit?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Invoice>>>;
    public searchInvoicesShipmentDocs(type?: string, query?: string, exclude_ids?: string, limit?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (type !== undefined && type !== null) {
            queryParameters = queryParameters.set('type', <any>type);
        }
        if (query !== undefined && query !== null) {
            queryParameters = queryParameters.set('query', <any>query);
        }
        if (exclude_ids !== undefined && exclude_ids !== null) {
            queryParameters = queryParameters.set('exclude_ids', <any>exclude_ids);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<Invoice>>(`${this.basePath}/organisation/shipmentDocs/search_invoices`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Search vendors
     * 
     * @param docType 
     * @param query 
     * @param type 
     * @param exclude_ids comman seperated invoice nos
     * @param limit 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchShipmentDocsShipments(docType: string, query?: string, type?: string, exclude_ids?: string, limit?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<Shipment>>;
    public searchShipmentDocsShipments(docType: string, query?: string, type?: string, exclude_ids?: string, limit?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Shipment>>>;
    public searchShipmentDocsShipments(docType: string, query?: string, type?: string, exclude_ids?: string, limit?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Shipment>>>;
    public searchShipmentDocsShipments(docType: string, query?: string, type?: string, exclude_ids?: string, limit?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (docType === null || docType === undefined) {
            throw new Error('Required parameter docType was null or undefined when calling searchShipmentDocsShipments.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (docType !== undefined && docType !== null) {
            queryParameters = queryParameters.set('docType', <any>docType);
        }
        if (query !== undefined && query !== null) {
            queryParameters = queryParameters.set('query', <any>query);
        }
        if (type !== undefined && type !== null) {
            queryParameters = queryParameters.set('type', <any>type);
        }
        if (exclude_ids !== undefined && exclude_ids !== null) {
            queryParameters = queryParameters.set('exclude_ids', <any>exclude_ids);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<Shipment>>(`${this.basePath}/organisation/shipmentDocs/search_shipments`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * update billOfEntry
     * 
     * @param BillOfEntry 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateBillOfEntry(BillOfEntry: BillOfEntry, observe?: 'body', reportProgress?: boolean): Observable<BillOfEntry>;
    public updateBillOfEntry(BillOfEntry: BillOfEntry, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BillOfEntry>>;
    public updateBillOfEntry(BillOfEntry: BillOfEntry, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BillOfEntry>>;
    public updateBillOfEntry(BillOfEntry: BillOfEntry, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (BillOfEntry === null || BillOfEntry === undefined) {
            throw new Error('Required parameter BillOfEntry was null or undefined when calling updateBillOfEntry.');
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<BillOfEntry>(`${this.basePath}/organisation/shipmentDocs/bill_of_entry`,
            BillOfEntry,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * update billOfLading
     * 
     * @param BillOfLading 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateBillOfLading(BillOfLading: BillOfLading, observe?: 'body', reportProgress?: boolean): Observable<BillOfLading>;
    public updateBillOfLading(BillOfLading: BillOfLading, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BillOfLading>>;
    public updateBillOfLading(BillOfLading: BillOfLading, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BillOfLading>>;
    public updateBillOfLading(BillOfLading: BillOfLading, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (BillOfLading === null || BillOfLading === undefined) {
            throw new Error('Required parameter BillOfLading was null or undefined when calling updateBillOfLading.');
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<BillOfLading>(`${this.basePath}/organisation/shipmentDocs/bill_of_lading`,
            BillOfLading,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update the staus of the Invoice Manually
     * 
     * @param billOfLadingId 
     * @param status 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateBlStatus(billOfLadingId: number, status: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateBlStatus(billOfLadingId: number, status: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateBlStatus(billOfLadingId: number, status: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateBlStatus(billOfLadingId: number, status: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (billOfLadingId === null || billOfLadingId === undefined) {
            throw new Error('Required parameter billOfLadingId was null or undefined when calling updateBlStatus.');
        }

        if (status === null || status === undefined) {
            throw new Error('Required parameter status was null or undefined when calling updateBlStatus.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (billOfLadingId !== undefined && billOfLadingId !== null) {
            queryParameters = queryParameters.set('billOfLadingId', <any>billOfLadingId);
        }
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', <any>status);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.put<any>(`${this.basePath}/organisation/shipmentDocs/update_bl_status`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * update shipping bill
     * 
     * @param ShippingBill 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateShippingBill(ShippingBill: ShippingBill, observe?: 'body', reportProgress?: boolean): Observable<ShippingBill>;
    public updateShippingBill(ShippingBill: ShippingBill, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ShippingBill>>;
    public updateShippingBill(ShippingBill: ShippingBill, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ShippingBill>>;
    public updateShippingBill(ShippingBill: ShippingBill, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (ShippingBill === null || ShippingBill === undefined) {
            throw new Error('Required parameter ShippingBill was null or undefined when calling updateShippingBill.');
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ShippingBill>(`${this.basePath}/organisation/shipmentDocs/shipping_bill`,
            ShippingBill,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Uplod the excel file of bill of ladings
     * 
     * @param file 
     * @param includeFirstRow 
     * @param dryRun Dry run the database transactions to see the results
     * @param columnMappings 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public uploadBlExcel(file: Blob, includeFirstRow: boolean, dryRun: boolean, columnMappings: string, observe?: 'body', reportProgress?: boolean): Observable<ExcelUploadResult>;
    public uploadBlExcel(file: Blob, includeFirstRow: boolean, dryRun: boolean, columnMappings: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ExcelUploadResult>>;
    public uploadBlExcel(file: Blob, includeFirstRow: boolean, dryRun: boolean, columnMappings: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ExcelUploadResult>>;
    public uploadBlExcel(file: Blob, includeFirstRow: boolean, dryRun: boolean, columnMappings: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (file === null || file === undefined) {
            throw new Error('Required parameter file was null or undefined when calling uploadBlExcel.');
        }

        if (includeFirstRow === null || includeFirstRow === undefined) {
            throw new Error('Required parameter includeFirstRow was null or undefined when calling uploadBlExcel.');
        }

        if (dryRun === null || dryRun === undefined) {
            throw new Error('Required parameter dryRun was null or undefined when calling uploadBlExcel.');
        }

        if (columnMappings === null || columnMappings === undefined) {
            throw new Error('Required parameter columnMappings was null or undefined when calling uploadBlExcel.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (includeFirstRow !== undefined && includeFirstRow !== null) {
            queryParameters = queryParameters.set('includeFirstRow', <any>includeFirstRow);
        }
        if (dryRun !== undefined && dryRun !== null) {
            queryParameters = queryParameters.set('dryRun', <any>dryRun);
        }
        if (columnMappings !== undefined && columnMappings !== null) {
            queryParameters = queryParameters.set('columnMappings', <any>columnMappings);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams.append('file', <any>file);
        }

        return this.httpClient.put<ExcelUploadResult>(`${this.basePath}/organisation/shipmentDocs/upload_bl_excel`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Uplod the excel file of bill of entries
     * 
     * @param file 
     * @param includeFirstRow 
     * @param dryRun Dry run the database transactions to see the results
     * @param columnMappings 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public uploadBoeExcel(file: Blob, includeFirstRow: boolean, dryRun: boolean, columnMappings: string, observe?: 'body', reportProgress?: boolean): Observable<ExcelUploadResult>;
    public uploadBoeExcel(file: Blob, includeFirstRow: boolean, dryRun: boolean, columnMappings: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ExcelUploadResult>>;
    public uploadBoeExcel(file: Blob, includeFirstRow: boolean, dryRun: boolean, columnMappings: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ExcelUploadResult>>;
    public uploadBoeExcel(file: Blob, includeFirstRow: boolean, dryRun: boolean, columnMappings: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (file === null || file === undefined) {
            throw new Error('Required parameter file was null or undefined when calling uploadBoeExcel.');
        }

        if (includeFirstRow === null || includeFirstRow === undefined) {
            throw new Error('Required parameter includeFirstRow was null or undefined when calling uploadBoeExcel.');
        }

        if (dryRun === null || dryRun === undefined) {
            throw new Error('Required parameter dryRun was null or undefined when calling uploadBoeExcel.');
        }

        if (columnMappings === null || columnMappings === undefined) {
            throw new Error('Required parameter columnMappings was null or undefined when calling uploadBoeExcel.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (includeFirstRow !== undefined && includeFirstRow !== null) {
            queryParameters = queryParameters.set('includeFirstRow', <any>includeFirstRow);
        }
        if (dryRun !== undefined && dryRun !== null) {
            queryParameters = queryParameters.set('dryRun', <any>dryRun);
        }
        if (columnMappings !== undefined && columnMappings !== null) {
            queryParameters = queryParameters.set('columnMappings', <any>columnMappings);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams.append('file', <any>file);
        }

        return this.httpClient.put<ExcelUploadResult>(`${this.basePath}/organisation/shipmentDocs/upload_boe_excel`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Uplod the excel file of shipping bills
     * 
     * @param file 
     * @param includeFirstRow 
     * @param dryRun Dry run the database transactions to see the results
     * @param columnMappings 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public uploadShippingBillExcel(file: Blob, includeFirstRow: boolean, dryRun: boolean, columnMappings: string, observe?: 'body', reportProgress?: boolean): Observable<ExcelUploadResult>;
    public uploadShippingBillExcel(file: Blob, includeFirstRow: boolean, dryRun: boolean, columnMappings: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ExcelUploadResult>>;
    public uploadShippingBillExcel(file: Blob, includeFirstRow: boolean, dryRun: boolean, columnMappings: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ExcelUploadResult>>;
    public uploadShippingBillExcel(file: Blob, includeFirstRow: boolean, dryRun: boolean, columnMappings: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (file === null || file === undefined) {
            throw new Error('Required parameter file was null or undefined when calling uploadShippingBillExcel.');
        }

        if (includeFirstRow === null || includeFirstRow === undefined) {
            throw new Error('Required parameter includeFirstRow was null or undefined when calling uploadShippingBillExcel.');
        }

        if (dryRun === null || dryRun === undefined) {
            throw new Error('Required parameter dryRun was null or undefined when calling uploadShippingBillExcel.');
        }

        if (columnMappings === null || columnMappings === undefined) {
            throw new Error('Required parameter columnMappings was null or undefined when calling uploadShippingBillExcel.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (includeFirstRow !== undefined && includeFirstRow !== null) {
            queryParameters = queryParameters.set('includeFirstRow', <any>includeFirstRow);
        }
        if (dryRun !== undefined && dryRun !== null) {
            queryParameters = queryParameters.set('dryRun', <any>dryRun);
        }
        if (columnMappings !== undefined && columnMappings !== null) {
            queryParameters = queryParameters.set('columnMappings', <any>columnMappings);
        }

        let headers = this.defaultHeaders;

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams.append('file', <any>file);
        }

        return this.httpClient.put<ExcelUploadResult>(`${this.basePath}/organisation/shipmentDocs/upload_shipping_bill_excel`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
