import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { AuthService } from './api/auth.service';
import { LicensesService } from './api/licenses.service';
import { OpenService } from './api/open.service';
import { OrgContactService } from './api/orgContact.service';
import { OrgInvoiceService } from './api/orgInvoice.service';
import { OrgOrdersService } from './api/orgOrders.service';
import { OrgShipmentsService } from './api/orgShipments.service';
import { OrganisationService } from './api/organisation.service';
import { ReportsService } from './api/reports.service';
import { ServiceProviderService } from './api/serviceProvider.service';
import { ShipmentDocsService } from './api/shipmentDocs.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    AuthService,
    LicensesService,
    OpenService,
    OrgContactService,
    OrgInvoiceService,
    OrgOrdersService,
    OrgShipmentsService,
    OrganisationService,
    ReportsService,
    ServiceProviderService,
    ShipmentDocsService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
