import { Component, OnDestroy, OnInit } from '@angular/core';
import { SwalHelper } from 'src/app/swal';
import { SalesOrder, OrganisationService, OrgOrdersService } from 'src/swagger';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { GlobalProfileService } from 'src/app/global-profile.service';
import { OrganisationHelperService } from '../organisation-helper.service';
import { of, Subject, Subscription } from 'rxjs';
import { catchError, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
declare var swal: any;
declare var $: any;

@Component({
  selector: 'app-sales-order',
  templateUrl: './sales-order.component.html',
  styleUrls: ['./sales-order.component.scss']
})
export class SalesOrderComponent implements OnInit, OnDestroy {

  public allSalesOrders: SalesOrder[] = [];
  public allSOAmount: SalesOrder[] = [];
  public shouldShowLoader: boolean;

  public soEndDate: any;
  public soStartDate: any;
  public soStatus: string;

  public soCreationtype: string;
  public soFileName;

  public soFile: File;
  public salesOrderDetails: SalesOrder = {};

  dtOptions: DataTables.Settings = {};
  private userSubscription: Subscription;

  public cloneOrderId: number;
  public clonedOrderNo: string;

  public orderChange$: Subject<string> = new Subject();
  public listOrder$: Subject<SalesOrder[]> = new Subject();
  private orderChangeSubscription: Subscription;
  public orderLoading = false;

  public reminderOrder: SalesOrder = {};


  constructor(
    private orgOrderApi: OrgOrdersService,
    public router: Router,
    public orgApi: OrganisationService,
    public profileSync: GlobalProfileService,
    public orgHelper: OrganisationHelperService
  ) { }

  ngOnInit(): void {
    this.initSo();
    this.soStatus = 'All';
    this.dtOptions = {
      columnDefs: [
        { targets: [4, 5], width: '100px', type: 'date' },
        { targets: 7, type: 'num-fmt' },
        { orderable: false, targets: -1 },
        { width: '70px', targets: [-1, 0] }
      ]
    };
    this.userSubscription = this.profileSync.user$.subscribe(user => {
      if (user && user.OrganisationRole && user.OrganisationRole.permissions && user.OrganisationRole.permissions.SO.read) {
        this.fetchSOTotals();
        this.fetchAllSalesOrder();
      }
    });
    this.orderChangeSubscription = this.orderChange$.pipe(
      distinctUntilChanged(),
      tap(() => this.orderLoading = true),
      switchMap(query => this.orgApi.searchSosForClone(
        query,
      ).pipe(
        catchError(() => of([])), // empty list on error
        tap(() => {
          this.orderLoading = false;
        })
      ))
    ).subscribe(items => {
      this.listOrder$.next(items);
    });
  }

  ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
    if (this.orderChangeSubscription) {
      this.orderChangeSubscription.unsubscribe();
    }
  }

  /* Fn to init the Sales Order*/
  initSo(): void {
    this.soCreationtype = 'cloneSalesOrder';
    this.salesOrderDetails.currency = this.salesOrderDetails.currency ? this.salesOrderDetails.currency : 'USD';
    this.salesOrderDetails.templateName = 'so1';
  }

  /* Fn to fetch all the sales orders */
  fetchAllSalesOrder(): void {
    this.allSalesOrders = [];
    this.shouldShowLoader = true;
    this.orgOrderApi.listSo(
      this.soStartDate ? moment(this.soStartDate).format('YYYY-MM-DD') : null,
      this.soEndDate ? moment(this.soEndDate).format('YYYY-MM-DD') : null,
      this.soStatus === 'All' ? undefined : this.soStatus
    ).subscribe(sos => {
      this.shouldShowLoader = false;
      if (sos && sos.length > 0) {
        this.allSalesOrders = sos;
      } else {
        this.allSalesOrders = null;
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /* Fn to fetch the total order amount for total band*/
  fetchSOTotals(): void {
    this.shouldShowLoader = true;
    this.orgOrderApi.totalSoAmount(
    ).subscribe(pos => {
      this.shouldShowLoader = false;
      if (pos && pos.length > 0) {
        this.allSOAmount = pos;
      } else {
        this.allSOAmount = null;
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /* Fn to reset the filter */
  resetFilter(): void {
    this.soEndDate = undefined;
    this.soStartDate = undefined;
    this.soStatus = 'All';
    this.fetchAllSalesOrder();
  }

  /**
   * Fn to delete the order
   * @param index Index of the order to be deleted
   */
  deleteSO(index: number): void {
    swal({
      title: 'Delete Sales order',
      text: 'This cannot be undone',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        this.shouldShowLoader = true;
        return new Promise((resolve, reject) => {
          this.orgOrderApi.deleteSoDetail(this.allSalesOrders[index].salesOrderId).subscribe(ok => {
            this.shouldShowLoader = false;
            this.allSalesOrders.splice(index, 1);
            resolve(ok);
          }, err => {
            reject(err);
          });
        });
      },
      allowOutsideClick: () => !swal.isLoading()
    }).then((result) => {
      if (result.value) {
        this.shouldShowLoader = false;
        SwalHelper.successTimerSwal('Sales order deleted!');
      }
    }).catch(err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  fileChange(event): void {
    this.soFileName = event.target.files[0].name;
    this.soFile = event.target.files[0];
  }

  /* Fn to  Add the new Order*/
  newOrder(): void {
    this.shouldShowLoader = true;
    if (this.soCreationtype === 'new') {
      this.salesOrderDetails.templateName = 'so1';
      this.salesOrderDetails.orgOrderDate = moment(this.salesOrderDetails.orgOrderDate).format('YYYY-MM-DD');
      this.orgOrderApi.createSo(
        this.salesOrderDetails
      ).subscribe(inv => {
        if (inv) {
          $('#salesOrderModal').modal('hide');
          this.shouldShowLoader = false;
          this.salesOrderDetails = inv;
          this.router.navigate([`/organisation/sales-orders/update-sales-order/${this.salesOrderDetails.salesOrderId}`]);
        }
      }, err => {
        this.shouldShowLoader = false;
        SwalHelper.showErrorSwal(err);
      });
    } else if (this.soCreationtype === 'existing') {
      this.orgOrderApi.importSo(
        this.soFile,
        this.salesOrderDetails.currency
      ).subscribe(inv => {
        if (inv) {
          $('#salesOrderModal').modal('hide');
          this.shouldShowLoader = false;
          this.salesOrderDetails = inv;
          this.router.navigate([`/organisation/sales-orders/update-sales-order/${this.salesOrderDetails.salesOrderId}`]);
        }
      }, err => {
        this.shouldShowLoader = false;
        SwalHelper.showErrorSwal(err);
      });
    } else {
      $('#salesOrderModal').modal('hide');
      this.shouldShowLoader = false;
      this.cloneOrder();
    }
  }

  /**
   * Fn to archive the order
   * @param index Index of the order to be archived
   */
  archiveOrder(index: number): void {
    swal({
      title: 'Archive Order',
      text: 'Unlink all the Linked documents first.',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Archive',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        this.shouldShowLoader = true;
        return new Promise((resolve, reject) => {
          this.orgOrderApi.archiveSo(
            this.allSalesOrders[index].salesOrderId,
          ).subscribe(inv => {
            this.shouldShowLoader = false;
            this.allSalesOrders[index].status = 'ARCHIVED';
            this.allSalesOrders[index].orgOrderNo = this.allSalesOrders[index].orgOrderNo.concat(' (ARCHIVED)');
            resolve(inv);
          }, err => {
            reject(err);
          });
        });
      },
      allowOutsideClick: () => !swal.isLoading()
    }).then((result) => {
      if (result.value) {
        this.shouldShowLoader = false;
        SwalHelper.successTimerSwal('Order Archived!');
      }
    }).catch(err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /**
   * Fn to get the details of order to be cloned
   * @param index Index of the order to be cloned
   */
  cloneSO(index: number): void {
    $('#cloneOrderModal').modal('show');
    this.cloneOrderId = this.allSalesOrders[index].salesOrderId;
  }

  /* Fn to clone the order*/
  cloneOrder(): void {
    swal({
      title: 'Clone Export Order',
      text: 'This cannot be undone',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Clone',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        this.shouldShowLoader = true;
        return new Promise((resolve, reject) => {
          this.orgOrderApi.cloneSo(
            this.cloneOrderId,
            this.clonedOrderNo,
          ).subscribe(so => {
            this.shouldShowLoader = false;
            this.allSalesOrders.push(so);
            $('#cloneOrderModal').modal('hide');
            this.cloneOrderId = null;
            this.clonedOrderNo = null;
            this.router.navigate([`/organisation/sales-orders/update-sales-order/${so.salesOrderId}`]);
            resolve(so);
          }, err => {
            reject(err);
          });
        });
      },
      allowOutsideClick: () => !swal.isLoading()
    }).then((result) => {
      if (result.value) {
        this.shouldShowLoader = false;
        SwalHelper.successTimerSwal('Order Cloned!');
      }
    }).catch(err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /**
   * Fn to open the reminder modal with order details
   * @param index Index of order to create reminder
   */
  openReminder(index: number): void {
    $('#reminderModal').modal('show');
    this.reminderOrder = this.allSalesOrders[index];
  }

  /**
   * Fn to open the shipment link modal with order details
   * @param index Index of order to shipment link
   */
  openLinkShipment(index: number): void {
    localStorage.setItem('IS_LINK_SHIPMENT', 'true');
    this.router.navigate([`/organisation/sales-orders/sales-order-detail/${this.allSalesOrders[index].salesOrderId}`]);
  }
}
