import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { OrganisationService, OrganisationCustomField } from 'src/swagger';
import { SwalHelper } from 'src/app/swal';
import { Subject } from 'rxjs';
import { NgForm } from '@angular/forms';
import { ComponentCanDeactivate } from 'src/app/form-route-deactivate/component-can-deactivate';
declare var swal: any;
declare var $: any;

@Component({
  selector: 'app-order-custom-fields',
  templateUrl: './order-custom-fields.component.html',
  styleUrls: ['./order-custom-fields.component.scss']
})
export class OrderCustomFieldsComponent extends ComponentCanDeactivate implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('customFieldform')
  public customFieldformRef: NgForm;

  public orderFields: OrganisationCustomField[] = [];
  public newField: OrganisationCustomField = {};
  public shouldShowLoader: boolean;
  public isMultiple: boolean;

  public dtTrigger = new Subject();

  constructor(
    private orgApi: OrganisationService
  ) {
    super();
  }

  ngOnInit(): void {
    this.fetchAllCustomFields();
    this.newField.documentType = 'Sales Order';
    this.initCustomField();
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  /* Fn to init the new field */
  initCustomField(): void {
    this.newField.fieldType = 'text';
    this.newField.fieldRegex = '.{1,100}';
  }

  canDeactivate(): boolean {
    return !this.customFieldformRef.dirty;
  }

  /* Fn to fetch all the custom fields */
  fetchAllCustomFields(): void {
    this.shouldShowLoader = true;
    this.orgApi.getCustomField(
      'Sales Order'
    ).subscribe(fields => {
      this.shouldShowLoader = false;
      if (fields && fields.length > 0) {
        this.orderFields = fields;
      } else {
        this.orderFields = null;
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /**
   * Fn to delete the custom field
   * @param index Index of custom field
   */
  deleteField(index: number): void {
    swal({
      title: 'Delete Additional field?',
      text: 'This cannot be undone',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        this.shouldShowLoader = true;
        return new Promise((resolve, reject) => {
          this.orgApi.deleteCustomField(this.orderFields[index].orgCusFieId).subscribe(ok => {
            this.shouldShowLoader = false;
            this.fetchAllCustomFields();
            resolve(ok);
          }, err => {
            reject(err);
          });
        });
      },
      allowOutsideClick: () => !swal.isLoading()
    }).then((result) => {
      if (result.value) {
        this.shouldShowLoader = false;
        SwalHelper.successTimerSwal('Additional Field deleted!');
      }
    }).catch(err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /**
   * Fn to save the custom field details
   * @param form Angular form
   */
  updateField(form: NgForm): void {
    if (form.valid) {
      if (this.isMultiple) {
        this.newField.documentType = 'Sales Order Items';
      }
      if (!this.orderFields) {
        this.orderFields = [];
      }
      if (this.newField.orgCusFieId) {
        this.shouldShowLoader = true;
        this.orgApi.updateCustomField(this.newField).subscribe(field => {
          $('#orderModal').modal('hide');
          this.shouldShowLoader = false;
          if (field) {
            SwalHelper.successTimerSwal('Additional Field updated!');
            this.fetchAllCustomFields();
            this.newField = {};
            this.newField.documentType = 'Sales Order';
            this.initCustomField();
          }
          this.customFieldformRef.form.markAsPristine();
        }, err => {
          this.shouldShowLoader = false;
          SwalHelper.showErrorSwal(err);
        });
      } else {
        this.shouldShowLoader = true;
        this.orgApi.createCustomField(this.newField).subscribe(field => {
          $('#orderModal').modal('hide');
          this.shouldShowLoader = false;
          SwalHelper.successTimerSwal('Additional Field created!');
          this.fetchAllCustomFields();
          this.newField = {};
          this.newField.documentType = 'Sales Order';
          this.initCustomField();
          this.customFieldformRef.form.markAsPristine();
        }, err => {
          this.shouldShowLoader = false;
          SwalHelper.showErrorSwal(err);
        });
      }
    }
  }

  /* Fn to setting the pattern based on field type */
  selectPattern(): void {
    if (this.newField.fieldType === 'text') {
      this.newField.fieldRegex = '.{1,100}';
    } else if (this.newField.fieldType === 'textarea') {
      this.newField.fieldRegex = '.{1,500}';
    } else if (this.newField.fieldType === 'number') {
      this.newField.fieldRegex = '[0-9.]{0,15}';
    } else if (this.newField.fieldType === 'email') {
      this.newField.fieldRegex = '[a-z0-9!#$%&‘*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?';
    } else if (this.newField.fieldType === 'tel') {
      this.newField.fieldRegex = '[0-9+)(\s-]{1,20}';
    }
  }

}
