import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SwalHelper } from 'src/app/swal';
import { environment } from 'src/environments/environment';
import { saveAs } from 'file-saver';
import { CustomExportObject, OrganisationService, ShippingBill, ShipmentDocument, ShipmentDocsService } from 'src/swagger';
import { CommonSupportService } from '../../../../common-support.service';
import { GlobalProfileService } from 'src/app/global-profile.service';
import { OrgShipmentsService } from 'src/swagger/api/orgShipments.service';
import { Subscription, of, Subject } from 'rxjs';
import { debounceTime, tap, switchMap, catchError } from 'rxjs/operators';
import { Shipment } from 'src/swagger/model/shipment';
declare var swal: any;
declare var $: any;

@Component({
  selector: 'app-shipping-bill-details',
  templateUrl: './shipping-bill-details.component.html',
  styleUrls: ['./shipping-bill-details.component.scss']
})
export class ShippingBillDetailsComponent implements OnInit, OnDestroy {

  public shippingBill: ShippingBill = {};
  public shouldShowLoader = false;

  public outputSB: any;
  public basePath: string;
  public shipDocs: ShipmentDocument[] = [];

  public shipmentDocumentLink: ShipmentDocument = {}; // For Linking Purpose

  public shipmentChange$: Subject<string> = new Subject();
  public listShipment$: Subject<Shipment[]> = new Subject();
  public alreadyLinkedShipIds = [];
  public shipmentLoading = false;
  private shipmentSubscription: Subscription;

  constructor(
    public http: HttpClient,
    private activatedRoute: ActivatedRoute,
    private orgApi: OrganisationService,
    public commonService: CommonSupportService,
    private shipmentDocApi: ShipmentDocsService,
    public profileSync: GlobalProfileService,
    public orgShipmentApi: OrgShipmentsService
  ) {
    this.basePath = environment.BASE_PATH;
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(param => {
      if (param && param.shippingBillId) {
        this.fetchShippingBill(param.shippingBillId);
      }
    });
    this.shipmentSubscription = this.shipmentChange$.pipe(
      debounceTime(200),
      tap(() => this.shipmentLoading = true),
      switchMap(query => this.shipmentDocApi.searchShipmentDocsShipments(
        'SHIPPING_BILL',
        query,
        'SELL',
        this.alreadyLinkedShipIds.join(',')
      ).pipe(
        catchError(() => of([])), // empty list on error
        tap(() => {
          this.shipmentLoading = false;
        })
      ))
    ).subscribe(sos => {
      this.listShipment$.next(sos);
    });
    if (localStorage.getItem('IS_LINK_SHIPMENT') && this.profileSync.user && this.profileSync.user.OrganisationRole && this.profileSync.user.OrganisationRole.permissions && this.profileSync.user.OrganisationRole.permissions.Shipment.update) {
      this.openDocLinkModal();
      localStorage.removeItem('IS_LINK_SHIPMENT');
    }
  }

  ngOnDestroy(): void {
    if (this.shipmentSubscription) {
      this.shipmentSubscription.unsubscribe();
    }
  }

  /* Fn to fetch the Shipping bill Details */
  fetchShippingBill(bolId): void {
    this.shouldShowLoader = true;
    this.shipmentDocApi.getShippingBill(bolId).subscribe(shippingBill => {
      if (shippingBill) {
        this.shouldShowLoader = false;
        this.shippingBill = shippingBill;
        this.fetchTemplate();
        if (this.shippingBill.ShipmentDocuments && this.shippingBill.ShipmentDocuments.length > 0) {
          this.alreadyLinkedShipIds = this.shippingBill.ShipmentDocuments.map(i => i.Shipment.shipmentId);
        }
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  // Fetch server side rendered shipping bill template
  fetchTemplate(): void {
    const headers = new HttpHeaders().set('Authorization', this.orgApi.configuration.apiKeys.Authorization);
    this.shouldShowLoader = true;
    this.http.post(`${this.basePath}/organisation/templates/ShippingBill/sb1`, { shippingBillId: this.shippingBill.shippingBillId }, {
      responseType: 'text',
      headers,
      observe: 'body'
    }).subscribe(data => {
      this.shouldShowLoader = false;
      this.outputSB = data;
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  // Export the Shipping Bill in pdf form
  exportSB(): void {
    this.shouldShowLoader = true;
    const cusObj: CustomExportObject = {};
    cusObj.shippingBillId = this.shippingBill.shippingBillId;
    cusObj.type = 'ShippingBill';
    this.orgApi
      .exportDocument(cusObj).subscribe(res => {
        this.shouldShowLoader = false;
        saveAs(res, ('ShippingBill.pdf'));
      }, err => {
        this.shouldShowLoader = false;
        SwalHelper.showErrorSwal(err);
      });
  }

  public downloadFile(docAttId: number): void {
    this.orgApi.orgDocumentSignedUrl(docAttId)
      .subscribe(url => {
        window.open(url.url, '_blank');
      });
  }

  /* Fn to print shipping bill */
  printSB(): void {
    const printContents = document.getElementById('printBol').innerHTML;
    const win = window.open('', 'Title', 'toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes');
    win.document.body.innerHTML = printContents;
    win.print();
  }

  openDocLinkModal(): void {
    this.shipmentDocumentLink.docType = 'SHIPPING_BILL';
    $('#fileAddModal').modal('show');
  }

  docAlreadyLinked(): void {
    this.shipmentDocumentLink.shippingBillId = this.shippingBill.shippingBillId;
    this.orgShipmentApi.documentAlreadyLinked(
      this.shipmentDocumentLink
    ).subscribe(ok => {
      if (ok) {
        swal({
          title: 'Alredy Linked',
          text: ok.message,
          type: 'warning',
          showCancelButton: true,
          cancelButtonText: 'No',
          confirmButtonText: 'Yes! Link',
        }).then((result) => {
          if (result.value) {
            this.linkDocument();
          } else {
            $('#fileAddModal').modal('hide');
            this.shipmentDocumentLink = {};
            this.shipmentDocumentLink.docType = 'SHIPPING_BILL';
          }
        });
      } else {
        this.linkDocument();
      }
    }, err => {
      SwalHelper.showErrorSwal(err);
    });
  }

  /* Fn to link the shipping bill with shipment */
  linkDocument(): void {
    this.shouldShowLoader = true;
    this.shipmentDocumentLink.shippingBillId = this.shippingBill.shippingBillId;
    this.orgShipmentApi.linkShipmentDocument(
      this.shipmentDocumentLink
    ).subscribe(doc => {
      this.shouldShowLoader = false;
      SwalHelper.successTimerSwal('Shipment linked');
      if (doc) {
        if (doc.ShippingBill) {
          this.shippingBill.ShipmentDocuments.push({
            shipmentDocumentId: doc.shipmentDocumentId,
            Shipment: doc.Shipment
          });
          this.alreadyLinkedShipIds.push(this.shipmentDocumentLink.shipmentId);
        }
        this.shipmentDocumentLink = {};
        this.shipmentDocumentLink.docType = 'SHIPPING_BILL';
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /**
   * Fn to Unlink the shipment from order
   * @param index Index of shipment document
   */
  unlinkDocument(index: number): void {
    swal({
      title: `Unlink Shipment`,
      text: 'This cannot be undone',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Unlink',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        this.shouldShowLoader = true;
        return new Promise((resolve, reject) => {
          this.orgShipmentApi.unlinkShipmentDocument(
            this.shippingBill.ShipmentDocuments[index].Shipment.shipmentId,
            this.shippingBill.ShipmentDocuments[index].shipmentDocumentId
          ).subscribe(doc => {
            this.shouldShowLoader = false;
            this.shippingBill.ShipmentDocuments.splice(index, 1);
            $('#fileViewModal').modal('hide');
            resolve(doc);
          }, err => {
            this.shouldShowLoader = false;
            reject(err);
          });
        });
      },
      allowOutsideClick: () => !swal.isLoading()
    }).then((result) => {
      if (result.value) {
        SwalHelper.successTimerSwal('Shipment Unlinked');
      }
    }).catch(err => {
      SwalHelper.showErrorSwal(err);
    });
  }
}
