import { Component, OnDestroy, OnInit } from '@angular/core';
import { Invoice, OrganisationService, OrgInvoiceService } from 'src/swagger';
import { SwalHelper } from 'src/app/swal';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { OrganisationHelperService } from '../organisation-helper.service';
import { GlobalProfileService } from '../../global-profile.service';
import { of, Subject, Subscription } from 'rxjs';
import { catchError, distinctUntilChanged, switchMap, tap, take } from 'rxjs/operators';

declare var swal: any;
declare var $: any;

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent implements OnInit, OnDestroy {

  public allInvoices: Invoice[] = [];
  public allInvAmount: Invoice[] = [];
  public invoice: Invoice = {};
  public shouldShowLoader: boolean;

  public invoiceType: any;
  public endDate: any;
  public dateOfInv: any;
  public invoiceStatus: string;

  public invoiceCreationtype: string;
  public invoiceFileName;

  public invoiceFile: File;
  public cloneInvId: number;
  public clonedInvNo: string;
  public cloneInvType = 'SALES';

  dtOptions: DataTables.Settings = {};
  private userSubscription: Subscription;

  public invChange$: Subject<string> = new Subject();
  public listInvoice$: Subject<Invoice[]> = new Subject();
  private invChangeSubscription: Subscription;
  public invLoading = false;

  public reminderInv: Invoice = {};

  constructor(
    private orgInvApi: OrgInvoiceService,
    public router: Router,
    public orgApi: OrganisationService,
    public orgHelper: OrganisationHelperService,
    public profileSync: GlobalProfileService,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.initInv();
    this.dtOptions = {
      columnDefs: [
        { targets: [5, 6], type: 'date' },
        { targets: 4, type: 'num-fmt' },
        { orderable: false, targets: -1 },
        {targets: [0, -1], width: '50px'},
      ]
    };
    this.activatedRoute.queryParams.subscribe(param => {
      if (param && param.status) {
        this.invoiceStatus = param.status;
      } else {
        this.invoiceStatus = 'All';
      }
    });
    this.userSubscription = this.profileSync.user$.pipe(take(1)).subscribe(user => {
      if (user && user.OrganisationRole && user.OrganisationRole.permissions && user.OrganisationRole.permissions.Invoice.read) {
        this.fetchAllInvoices();
        this.fetchInvTotal();
      }
    });

    this.invChangeSubscription = this.invChange$.pipe(
      distinctUntilChanged(),
      tap(() => this.invLoading = true),
      switchMap(query => this.orgApi.searchInvoicesForClone(
        'SALES',
        query,
      ).pipe(
        catchError(() => of([])), // empty list on error
        tap(() => {
          this.invLoading = false;
        })
      ))
    ).subscribe(items => {
      this.listInvoice$.next(items);
    });
  }

  ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
    if (this.invChangeSubscription) {
      this.invChangeSubscription.unsubscribe();
    }
  }

  initInv(): void {
    this.invoiceCreationtype = 'cloneInvoice';
    this.invoice.modeOfShipment = 'Sea';
    this.invoice.currency = this.invoice.currency ? this.invoice.currency : 'USD';
    this.invoice.templateName = '1';
    this.invoice.invoiceType = 'SALES';
    this.invoice.dateOfInv = moment().format('YYYY-MM-DD');
  }

  fetchAllInvoices(): void {
    this.allInvoices = [];
    this.shouldShowLoader = true;
    this.orgInvApi.listInvoice(
      'SALES',
      this.endDate ? moment(this.endDate).format('YYYY-MM-DD') : null,
      this.dateOfInv ? moment(this.dateOfInv).format('YYYY-MM-DD') : null,
      this.invoiceStatus === 'All' ? undefined : this.invoiceStatus
    ).subscribe(invoices => {
      this.shouldShowLoader = false;
      if (invoices && invoices.length > 0) {
        this.allInvoices = invoices;
      } else {
        this.allInvoices = null;
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  fetchInvTotal(): void {
    this.shouldShowLoader = true;
    this.orgInvApi.totalInvAmount(
      'SALES'
    ).subscribe(pos => {
      this.shouldShowLoader = false;
      if (pos && pos.length > 0) {
        this.allInvAmount = pos;
      } else {
        this.allInvAmount = null;
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  resetFilter(): void {
    this.endDate = undefined;
    this.dateOfInv = undefined;
    this.invoiceStatus = 'All';
  }
  deleteInvoice(index: number): void {
    swal({
      title: 'Delete Invoice',
      text: 'This cannot be undone',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        this.shouldShowLoader = true;
        return new Promise((resolve, reject) => {
          this.orgInvApi.deleteInvoice(this.allInvoices[index].invId).subscribe(ok => {
            this.shouldShowLoader = false;
            this.allInvoices.splice(index, 1);
            resolve(ok);
          }, err => {
            reject(err);
          });
        });
      },
      allowOutsideClick: () => !swal.isLoading()
    }).then((result) => {
      if (result.value) {
        this.shouldShowLoader = false;
        SwalHelper.successTimerSwal('Invoice deleted!');
      }
    }).catch(err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  fileChange(event): void {
    this.invoiceFileName = event.target.files[0].name;
    this.invoiceFile = event.target.files[0];
  }

  newInvoice(): void {
    $('#invoiceModal').modal('hide');
    this.shouldShowLoader = true;
    this.invoice.invoiceType = 'SALES';
    this.invoice.templateName = '1';
    if (this.invoiceCreationtype === 'new') {
      this.invoice.dateOfInv = moment(this.invoice.dateOfInv).format('YYYY-MM-DD');
      this.orgInvApi.createInvoice(
        this.invoice
      ).subscribe(inv => {
        if (inv) {
          this.shouldShowLoader = false;
          this.invoice = inv;
          this.router.navigate([`/organisation/invoices/edit-invoice/${this.invoice.invId}`]);
        }
      }, err => {
        this.shouldShowLoader = false;
        SwalHelper.showErrorSwal(err);
      });
    } else if (this.invoiceCreationtype === 'existing') {
      this.orgInvApi.importInvoice(
        this.invoiceFile,
        this.invoice.modeOfShipment,
        this.invoice.currency,
        'SALES',
        this.invoice.invNo
      ).subscribe(inv => {
        if (inv) {
          this.shouldShowLoader = false;
          this.invoice = inv;
          this.router.navigate([`/organisation/invoices/edit-invoice/${this.invoice.invId}`]);
        }
      }, err => {
        this.shouldShowLoader = false;
        SwalHelper.showErrorSwal(err);
      });
    } else {
      this.shouldShowLoader = false;
      this.cloneInvoice();
    }
  }

  cloneInv(index: number): void {
    $('#cloneInvoiceModal').modal('show');
    this.cloneInvId = this.allInvoices[index].invId;
  }

  /* Fn to clone the Invoice */
  cloneInvoice(): void {
    swal({
      title: 'Clone/Copy Invoice',
      text: 'This cannot be undone',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Clone',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        this.shouldShowLoader = true;
        return new Promise((resolve, reject) => {
          this.orgInvApi.cloneInvoice(
            this.cloneInvId,
            this.clonedInvNo,
            this.cloneInvType,
          ).subscribe(inv => {
            this.shouldShowLoader = false;
            this.allInvoices.push(inv);
            $('#cloneInvoiceModal').modal('hide');
            this.cloneInvId = null;
            this.clonedInvNo = null;
            this.router.navigate([`/organisation/invoices/edit-invoice/${inv.invId}`]);
            resolve(inv);
          }, err => {
            reject(err);
          });
        });
      },
      allowOutsideClick: () => !swal.isLoading()
    }).then((result) => {
      if (result.value) {
        this.shouldShowLoader = false;
        SwalHelper.successTimerSwal('Invoice Cloned!');
      }
    }).catch(err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /**
   * Fn to archive the Invoice
   * @param index Index of Invoice to be archived
   */
  archiveInvoice(index: number): void {
    swal({
      title: 'Archive Invoice',
      text: 'Unlink all the Linked documents first.',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Archive',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        this.shouldShowLoader = true;
        return new Promise((resolve, reject) => {
          this.orgInvApi.archiveInvoice(
            this.allInvoices[index].invId,
          ).subscribe(inv => {
            this.shouldShowLoader = false;
            this.allInvoices[index].invoiceStatus = 'ARCHIVED';
            resolve(inv);
          }, err => {
            reject(err);
          });
        });
      },
      allowOutsideClick: () => !swal.isLoading()
    }).then((result) => {
      if (result.value) {
        this.shouldShowLoader = false;
        SwalHelper.successTimerSwal('Invoice Archived!');
      }
    }).catch(err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /**
   * Fn to Open the reminder modal
   * @param index Index of Invoice for reminder
   */
  openReminder(index: number): void {
    $('#reminderModal').modal('show');
    this.reminderInv = {};
    this.reminderInv = this.allInvoices[index];
    this.reminderInv.invoiceType = 'SALES';
  }
  /**
   * Fn to open the shipment link modal with invoice details
   * @param index Index of invoice to shipment link
   */
   openLinkShipment(index: number): void {
    localStorage.setItem('IS_LINK_SHIPMENT', 'true');
    this.router.navigate([`/organisation/invoices/invoice-details/${this.allInvoices[index].invId}`]);
  }
}
