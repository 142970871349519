import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild, Renderer2, ChangeDetectorRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { interval, Subject, Subscription, of } from 'rxjs';
import { filter, switchMap, skip, catchError, tap, distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { SwalHelper } from 'src/app/swal';
import { environment } from 'src/environments/environment';
import {
  Invoice, InvoiceItem, ItemMaster, OrganisationService, Page, VendorAndSupplier, OrgOrdersService, OrgInvoiceService, PurchaseOrder, LicensesService, License, LicenseItem
} from 'src/swagger';
import { CommonSupportService } from 'src/app/common-support.service';
import * as moment from 'moment';
import { ComponentCanDeactivate } from 'src/app/form-route-deactivate/component-can-deactivate';
import { OrganisationHelperService } from '../../organisation-helper.service';
import { GlobalProfileService } from 'src/app/global-profile.service';
import { SelectorDiv, SurveyState } from 'src/app/enums';
import { BulkItemSyncService } from '../../invoice/bulk-item-sync.service';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { NumberToWordsPipe } from '../../../pipes/number-to-words.pipe';

declare var $: any;
declare var Swal: any;

@Component({
  selector: 'app-vendor-bill-updates',
  templateUrl: './vendor-bill-updates.component.html',
  styleUrls: ['./vendor-bill-updates.component.scss']
})
export class VendorBillUpdatesComponent extends ComponentCanDeactivate implements OnInit, OnDestroy {

  @ViewChild('InvoiceForm')
  public invoiceFormRef: NgForm;

  public basePath: string;
  private invoiceOCRSubscription: Subscription;

  public itemChange$: Subject<string> = new Subject();
  private itemChangeSubscription: Subscription;
  public itemLoading: boolean;

  public licenseChange$: Subject<object> = new Subject();
  private licenseChangeSubscription: Subscription;
  public licenseLoading: boolean;
  public license$: Subject<License[]> = new Subject();

  public surveyState: SurveyState = SurveyState.SAVED;
  public outputInvoice: any;

  public invoice: Invoice = {};
  public newItem: InvoiceItem = {};

  public shouldShowLoader = false;

  public vendorSuppliers: VendorAndSupplier[] = [];
  public itemMasters$: Subject<ItemMaster[]> = new Subject();
  public ocrResults: Page[] = [];

  public buyerDetails: VendorAndSupplier = {};
  public notifyParty1: VendorAndSupplier = {};
  public notifyParty2: VendorAndSupplier = {};
  public consignee: VendorAndSupplier = {};

  public copyText = 'Click to copy';
  public selectedImageURL: string;
  public totalPages = [];

  public thisPage: any;
  public bgwidth;
  public bgheight;
  public selectors: SelectorDiv[] = [];

  public rightSheet = false;
  public bottomSheet = false;
  public newContactType: string;

  public isRedirectToPreview: boolean;

  public poChange$: Subject<string> = new Subject();
  public listPo$: Subject<PurchaseOrder[]> = new Subject();
  private poChangeSubscription: Subscription;
  public alreadyLinkedPOIds = [];
  public poLoading = false;
  public purchaseOrderId: number;

  public selectedVendor: number;
  public itemMasterDetail: ItemMaster = {};

  public customItemIndex: number;

  public invoiceFiles: File[] = [];
  public licenseDetail: AdvancedLicense = {};

  public enabledFields = [];

  constructor(
    public http: HttpClient,
    private orgApi: OrganisationService,
    private orgOrderApi: OrgOrdersService,
    private activatedRoute: ActivatedRoute,
    public commonService: CommonSupportService,
    private renderer: Renderer2,
    private changeDetectorRef: ChangeDetectorRef,
    private orgInvApi: OrgInvoiceService,
    public orgHelper: OrganisationHelperService,
    private router: Router,
    public profileSync: GlobalProfileService,
    public licenseApi: LicensesService,
    private bulkItemSync: BulkItemSyncService
  ) {
    super();
    commonService.renderer = renderer;
    this.basePath = environment.BASE_PATH;
  }

  ngOnInit(): void {
    this.invoice.InvoiceItems = [];
    this.invoice.notifyPartyId1 = 0;
    this.invoice.notifyPartyId2 = 0;
    this.invoice.buyerId = 0;

    const $view = $('.print-wrapper');
    const $content = $view.find('.print');
    const width = '1191px';
    $content.width(width);
    this.fetchVendorSupplier();

    this.activatedRoute.params.subscribe(param => {
      if (param && param.invId) {
        this.fetchInvoice(param.invId);
      }
    });

    this.bulkItemSync.invoiceItems$.subscribe(items => {
      this.invoice.InvoiceItems = this.invoice.InvoiceItems.concat(items);
      this.bottomSheetClose();
      this.calTotalAmount();
    });

    $view.on('mousemove', function (event): void {
      const $this = $(this);
      const parentOffset = $this.parent().offset();
      const mouseX = event.pageX - parentOffset.left;
      const mouseY = event.pageY - parentOffset.top;

      const viewRangeX = $this.width();
      const viewRangeY = $this.height();

      const contentRangeX = $content.outerWidth(true);
      const contentRangeY = $content.outerHeight(true);

      const ratioX = contentRangeX / viewRangeX;
      const ratioY = contentRangeY / viewRangeY;

      const offsetX = (mouseX * ratioX) - mouseX;
      const offsetY = (mouseY * ratioY) - mouseY;

      $content.css({
        top: (offsetY * -1) + 'px',
        left: (offsetX * -1) + 'px'
      });

    });

    this.itemChangeSubscription = this.itemChange$.pipe(
      distinctUntilChanged(),
      tap(() => this.itemLoading = true),
      switchMap(query => this.orgApi.listItemMaster(query).pipe(
        catchError(() => of([])), // empty list on error
        tap(() => {
          this.itemLoading = false;
        })
      ))
    ).subscribe(items => {
      this.itemMasters$.next(items);
    });


    this.licenseChangeSubscription = this.licenseChange$.pipe(
      distinctUntilChanged(),
      tap(() => this.licenseLoading = true),
      switchMap((query: any) => this.licenseApi.searchLicensesNo(
        query.itemHSNCode,
        'Import',
        query.licenseNo,
      ).pipe(
        catchError(() => of([])), // empty list on error
        tap(() => {
          this.licenseLoading = false;
        })
      ))
    ).subscribe(licenses => {
      this.license$.next(licenses);
    });

    this.poChangeSubscription = this.poChange$.pipe(
      debounceTime(200),
      tap(() => this.poLoading = true),
      switchMap(query => this.orgInvApi.searchPosForInvoice(
        query,
        this.invoice.currency ? this.invoice.currency : undefined,
        this.alreadyLinkedPOIds.join(',')
      ).pipe(
        catchError(() => of([])), // empty list on error
        tap(() => {
          this.poLoading = false;
        })
      ))
    ).subscribe(pos => {
      this.listPo$.next(pos);
    });


    $('.dropdown-menu').click(function (e) {
      e.stopPropagation();
    });

  }

  ngOnDestroy(): void {
    if (this.invoiceOCRSubscription) {
      this.invoiceOCRSubscription.unsubscribe();
    }
    if (this.poChangeSubscription) {
      this.poChangeSubscription.unsubscribe();
    }
    if (this.itemChangeSubscription) {
      this.itemChangeSubscription.unsubscribe();
    }
    if (this.licenseChangeSubscription) {
      this.licenseChangeSubscription.unsubscribe();
    }
  }

  canDeactivate(): boolean {
    return !this.invoiceFormRef.dirty;
  }

  drop(event: CdkDragDrop<string[]>) {
    const splicedElement = this.invoice.otherData.customColumns.splice(event.previousIndex, 1)[0];
    this.invoice.otherData.customColumns.splice(event.currentIndex, 0, splicedElement);
    this.recalculateEnabledCustomFields();
  }

  /* Init new Empty vendor bill item */
  addItem(): void {
    const item: InvoiceItem = {};
    item.itemQuantity = 1;
    item.itemPrice = 1;
    item.advanceLicenseDetails = {};
    item.otherData = this.invoice.otherData && this.invoice.otherData.customColumns ? this.orgHelper.copyObject(this.invoice.otherData.customColumns) : null;
    this.invoice.InvoiceItems.push(item);
    this.calAmount();
  }

  /** Fn to remove the vendor bill item
   * @param index Index of vendor bill item
   */
  removeItem(index: number): void {
    Swal.fire({
      title: 'Remove Item?',
      text: 'Are you sure want to delete this Item?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete',
    }).then((result) => {
      if (result.value) {
        this.invoice.InvoiceItems.splice(index, 1);
        this.calAmount();
        SwalHelper.showToast('success', 'Item deleted!');
      }
    });
  }

  /** Fn to add new Custom Column
    * @param index Index of custom field
    * @param isChecked Boolean to check custo field is selected
    */
  addColumn(index, isChecked: boolean): void {
    this.invoice.otherData.customColumns[index].isEnabled = isChecked;
    this.recalculateEnabledCustomFields();
    this.changeDetectorRef.detectChanges();
  }

  /* Fn to recalculate the enabled custom fields array */
  private recalculateEnabledCustomFields(): void {
    this.enabledFields = this.invoice.otherData && this.invoice.otherData.customColumns && this.invoice.otherData.customColumns.length > 0 ? this.invoice.otherData.customColumns.filter(col => col.isEnabled) : [];
  }

  /** Fn to Remove the custom column
   * @param index Custom field index
   */
  removeColumn(index: number): void {
    Swal.fire({
      title: 'Remove Column?',
      text: 'Are you sure want to delete this Item?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete',
    }).then((result) => {
      if (result.value) {
        this.invoice.otherData.customColumns[this.enabledFields[index]] = false;
        this.recalculateEnabledCustomFields();
        this.changeDetectorRef.detectChanges();
        SwalHelper.showToast('success', 'Item deleted!');
      }
    });
  }

  // Functions related to advance license
  /**
   * @param index index of the invoice item
   */
  addLicense(index: number): void {
    this.license$.next(null);
    $('#licenseAddModal').modal('show');
    this.licenseDetail.itemIndex = index;
  }

  /** Fn to remove the license
   * @param itemIndex Index of invoice item
   */
  removeLicenseDetails(itemIndex: number): void {
    Swal.fire({
      title: 'Remove License?',
      text: 'Are you sure want to delete this License?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete',
    }).then((result) => {
      if (result.value) {
        this.invoice.InvoiceItems[itemIndex].advanceLicenseDetails = {};
        SwalHelper.showToast('success', 'License deleted!');
      }
    });
  }

  /**
   * @param event License Event
   */
  checkLicense(event): void {
    if (event && event.licenseId) {
      this.licenseDetail.licenseNo = event.licenseNo;
      this.licenseDetail.licenseId = event.licenseId;
      this.licenseDetail.licenseType = event.licenseType;
      this.licenseDetail.expOblExpiryDate = event.expOblExpiryDate;
      this.licenseDetail.itemQuantity = this.invoice.InvoiceItems[this.licenseDetail.itemIndex].itemQuantity;
      this.licenseDetail.LicenseItem = event.LicenseItems[0];
    }
  }

  /* Fn to save the license details */
  saveLicenseDetail(): void {
    if (this.licenseDetail.licenseNo) {
      this.invoice.InvoiceItems[this.licenseDetail.itemIndex].advanceLicenseDetails = this.orgHelper.copyObject(this.licenseDetail);
    }
    $('#licenseAddModal').modal('hide');
    this.licenseDetail = {};
  }

  licenseChange(event): void {
    const object = {
      itemHSNCode: this.invoice.InvoiceItems[this.licenseDetail.itemIndex].itemHSNCode,
      licenseNo: event
    };
    this.licenseChange$.next(object);
  }

  checkLicenseItem(event, index: number): void {
    if (event && event.licenseItemId) {
      this.invoice.InvoiceItems[index].advanceLicenseDetails.licenseItem = event;
      this.invoice.InvoiceItems[index].advanceLicenseDetails.licenseItemId = event.licenseItemId;
    }
  }

  calAmount(): void {
    this.invoice.subtotalAmount = this.invoice.InvoiceItems.map(i => i.itemPrice * i.itemQuantity).reduce((a, b) => a + b, 0);
    this.calTotalAmount();
  }

  calTotalAmount(): void {
    this.invoice.totalAmount = this.invoice.subtotalAmount - this.invoice.discountValue;
  }

  checkItem(event, index: number): void {
    if (event) {
      if (event.itemMasterId) {
        const tempOtherData = this.invoice.InvoiceItems[index];
        this.invoice.InvoiceItems[index] = event;
        this.invoice.InvoiceItems[index].itemQuantity = 1;
        this.invoice.InvoiceItems[index].itemPrice = event.itemPrice ? event.itemPrice : 1;
        this.invoice.InvoiceItems[index].noOfPackages = 1;
        this.invoice.InvoiceItems[index].advanceLicenseDetails = tempOtherData && tempOtherData.advanceLicenseDetails ? tempOtherData.advanceLicenseDetails : {};
        this.invoice.InvoiceItems[index].otherData = tempOtherData && tempOtherData.otherData ? tempOtherData.otherData : this.orgHelper.copyObject(this.invoice.otherData.customColumns);
      } else {
        $('#itemMasterModal').modal('show');
        this.customItemIndex = index;
        this.itemMasterDetail.itemCode = event.itemCode;
        this.itemMasterDetail.itemCurrency = this.invoice.currency;
      }
    } else {
      this.invoice.InvoiceItems[index].itemCode = undefined;
    }
  }

  /* Fn to fetch the list of vendors */
  fetchVendorSupplier(): void {
    this.shouldShowLoader = true;
    this.orgApi.listVendorSupplier(
      undefined
    ).subscribe(vendos => {
      if (vendos && vendos.length > 0) {
        this.shouldShowLoader = false;
        this.vendorSuppliers = vendos;
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /**
   * Fn to fetch the vendor bill details
   * @param invId vendor bill id to fetch details
   */
  fetchInvoice(invId: number): void {
    this.shouldShowLoader = true;
    this.orgInvApi.getInvoice(invId).subscribe(inv => {
      if (inv) {
        this.shouldShowLoader = false;
        this.invoice = inv;

        this.recalculateEnabledCustomFields();
        this.bulkItemSync.customFields$.next(this.invoice.otherData.customColumns);

        if (this.invoice.InvoiceItems.length === 0) {
          this.addItem();
        }

        this.invoice.currency = this.invoice.currency ? this.invoice.currency : 'USD';
        this.invoice.incoTerms = this.invoice.incoTerms ? this.invoice.incoTerms : 'defaultIncoTerms';
        this.invoice.InvoiceItems = this.invoice.InvoiceItems.length === 0 ? [] : this.invoice.InvoiceItems;
        this.invoice.paymentInfo = this.invoice.paymentInfo ? this.invoice.paymentInfo : {};
        this.buyerDetails = this.invoice.buyerId ? this.invoice.Buyer : {};
        this.consignee = this.invoice.consigneeId ? this.invoice.Consignee : {};
        this.notifyParty1 = this.invoice.notifyPartyId1 ? this.invoice.NotifyParty1 : {};
        this.notifyParty2 = this.invoice.notifyPartyId2 ? this.invoice.NotifyParty2 : {};
        // tslint:disable-next-line: max-line-length
        this.alreadyLinkedPOIds = this.invoice.OrderInvoiceMappings && this.invoice.OrderInvoiceMappings.length > 0 ? this.invoice.OrderInvoiceMappings.map(i => i.purchaseOrderId) : [];
        this.poChange$.next(undefined);
        this.itemChange$.next(undefined);
      }
      if (this.invoice.ocrStatus !== 'FAILED' && this.invoice.importedDocUrl) {
        this.invoiceOCRSubscription = interval(3000).pipe(skip(3)).subscribe(tick => {
          this.orgApi.ocrResults('Invoice', this.invoice.invId).pipe(
            catchError(err => of(null)),
            filter((result) => !!result),
          ).subscribe(results => {
            if (results && results.length > 0) {
              this.totalPages = results;
              this.invoiceOCRSubscription.unsubscribe();
              this.ocrResults = results;
              this.loadData(0);
            }
          });
        });
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /* Fn to clean the date format & FK before saving Data */
  cleanInvoiceData(): void {
    this.invoice.consigneeId = this.invoice.consigneeId == -1 ? null : this.invoice.consigneeId;
    this.invoice.incoTerms = this.invoice.incoTerms === 'defaultIncoTerms' ? null : this.invoice.incoTerms;
    this.invoice.buyerId = this.invoice.buyerId == -1 ? null : this.invoice.buyerId;
    this.invoice.notifyPartyId1 = this.invoice.notifyPartyId1 == -1 ? null : this.invoice.notifyPartyId1;
    this.invoice.notifyPartyId2 = this.invoice.notifyPartyId2 == -1 ? null : this.invoice.notifyPartyId2;
    this.invoice.dateOfInv = this.invoice.dateOfInv ? moment(this.invoice.dateOfInv).format('YYYY-MM-DD') : null;
    this.invoice.dueDate = this.invoice.dueDate ? moment(this.invoice.dueDate).format('YYYY-MM-DD') : null;
    this.invoice.blDate = this.invoice.blDate ? moment(this.invoice.blDate).format('YYYY-MM-DD') : null;
    this.invoice.orderDate = this.invoice.orderDate ? moment(this.invoice.orderDate).format('YYYY-MM-DD') : null;
    this.invoice.expDeliveryDate = this.invoice.expDeliveryDate ? moment(this.invoice.expDeliveryDate).format('YYYY-MM-DD') : null;
    this.invoice.shippingBillDate = this.invoice.shippingBillDate ? moment(this.invoice.shippingBillDate).format('YYYY-MM-DD') : null;
  }

  /**
   * Fn to save the details of vendor bill
   * @param form Angular form
   */
  saveInvoice(form): void {
    if (form.valid) {
      this.shouldShowLoader = true;
      this.cleanInvoiceData();
      if (this.invoice.invId) {
        this.orgInvApi.updateInvoice(
          this.invoice
        ).subscribe(inv => {
          if (inv) {
            this.shouldShowLoader = false;
            this.invoice = inv;
            this.invoice.incoTerms = this.invoice.incoTerms ? this.invoice.incoTerms : 'defaultIncoTerms';
            this.invoiceFormRef.form.markAsPristine();
            if (this.isRedirectToPreview) {
              this.router.navigate([`/organisation/vendor-bills/vendor-bill-details/${this.invoice.invId}`]);
            } else {
              SwalHelper.showToast('success', 'Vendor Bill Updated!');
            }
          }
        }, err => {
          this.shouldShowLoader = false;
          SwalHelper.showErrorSwal(err);
        });
      }
    }
  }

  redirectToPreview(): void {
    this.isRedirectToPreview = true;
  }

  /**
   * Fn to save the new item details into item master
   * @param form Angular form
   */
  saveItemDetails(form): boolean {
    if (form.valid) {
      this.shouldShowLoader = true;
      this.orgApi.addItemMaster(this.itemMasterDetail
      ).subscribe(item => {
        this.shouldShowLoader = false;
        this.invoice.InvoiceItems[this.customItemIndex].itemMasterId = item.itemMasterId;
        this.invoice.InvoiceItems[this.customItemIndex].itemDescription = item.itemDescription;
        this.invoice.InvoiceItems[this.customItemIndex].itemHSNCode = item.itemHSNCode;
        this.invoice.InvoiceItems[this.customItemIndex].itemCode = item.itemCode;
        this.invoice.InvoiceItems[this.customItemIndex].itemUnit = item.itemUnit;
        this.invoice.InvoiceItems[this.customItemIndex].itemName = item.itemName;
        this.invoice.InvoiceItems[this.customItemIndex].itemQuantity = 1;
        this.invoice.InvoiceItems[this.customItemIndex].itemPrice = item.itemPrice;
        this.itemMasterDetail = {};
        this.customItemIndex = undefined;
        $('#itemMasterModal').modal('hide');
        SwalHelper.successTimerSwal('Item Added!');
        this.rightSheetClose();
      }, err => {
        this.shouldShowLoader = false;
        SwalHelper.showErrorSwal(err);
      });
    } else {
      return false;
    }
  }

  /* Fn to link the purchase order and map the order items with invoice items*/
  linkPO(event): void {
    this.shouldShowLoader = true;
    this.orgOrderApi.linkPoInvoice(this.invoice.invId, this.purchaseOrderId).subscribe(mapping => {
      this.shouldShowLoader = false;
      if (mapping) {
        if (!this.invoice.OrderInvoiceMappings) {
          this.invoice.OrderInvoiceMappings = [];
        }
        const poItems = event.PurchaseOrderItems;
        poItems.map(i => this.invoice.InvoiceItems.push({
          itemCode: i.itemCode,
          itemHSNCode: i.itemHSNCode,
          // itemSubCode: i.itemSubCode,
          itemName: i.itemName,
          itemDescription: i.itemDescription,
          itemUnit: i.itemUnit,
          itemPrice: i.itemPrice,
          itemQuantity: i.remainingQuantity,
          itemMasterId: i.itemMasterId,
          purchaseOrderItemId: i.purchaseOrderItemId,
          purchaseOrderId: i.purchaseOrderId,
          otherData: this.orgHelper.copyObject(this.invoice.otherData.customColumns),
          PurchaseOrder: event,
          advanceLicenseDetails: {}
        }));
        this.calAmount();
        this.invoice.OrderInvoiceMappings.push(mapping);
        this.alreadyLinkedPOIds = this.invoice.OrderInvoiceMappings.map(i => i.purchaseOrderId);
        this.poChange$.next(undefined);
        this.purchaseOrderId = undefined;
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal('This order is already linked please select other order');
    });
  }

  /**
   * Fn to unlink the order from invoice
   * @param index Index of the order to unlink
   */
  unlinkSO(index: number): void {
    this.shouldShowLoader = true;
    this.orgOrderApi.unlinkSoInvoice(this.invoice.OrderInvoiceMappings[index].ordInvMapId).subscribe(ok => {
      this.shouldShowLoader = false;
      if (ok) {
        if (this.invoice.OrderInvoiceMappings[index].purchaseOrderId) {
          this.invoice.InvoiceItems = this.invoice.InvoiceItems.filter(i => i.purchaseOrderId != this.invoice.OrderInvoiceMappings[index].purchaseOrderId);
        }
        const ind = this.alreadyLinkedPOIds.findIndex(i => i.purchaseOrderId == this.invoice.OrderInvoiceMappings[index].purchaseOrderId);
        this.alreadyLinkedPOIds.splice(ind, 1);
        this.invoice.OrderInvoiceMappings.splice(index, 1);
        this.poChange$.next(undefined);
        this.calAmount();
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /* Fn to select the Buyer */
  selectBuyer(): void {
    if (this.invoice.buyerId == -1) {
      this.rightSheetOpen();
      this.newContactType = this.invoice.invoiceType === 'SALES' ? 'Buyer' : 'Seller';
      this.invoice.Buyer = {};
      this.selectedVendor = undefined;
    } else {
      const index = this.vendorSuppliers.findIndex(i => i.venSupId == this.invoice.buyerId);
      if (index >= 0) {
        this.buyerDetails = this.vendorSuppliers[index];
        this.invoice.Buyer = this.vendorSuppliers[index];
      }
    }
  }

  /* Fn to select the consignee */
  selectConsignee(): void {
    if (this.invoice.consigneeId == -1) {
      this.rightSheetOpen();
      this.newContactType = 'Consignee';
      this.invoice.Consignee = {};
      this.selectedVendor = undefined;
    } else {
      const index = this.vendorSuppliers.findIndex(i => i.venSupId == this.invoice.consigneeId);
      if (index >= 0) {
        this.consignee = this.vendorSuppliers[index];
        this.invoice.Consignee = this.vendorSuppliers[index];
      }
    }
  }

  /* Fn to select the notify party 1 */
  selectNotify1(): void {
    if (this.invoice.notifyPartyId1 == -1) {
      this.rightSheetOpen();
      this.newContactType = 'Notify Party';
      this.invoice.NotifyParty1 = {};
      this.selectedVendor = undefined;
    } else {
      const index = this.vendorSuppliers.findIndex(i => i.venSupId == this.invoice.notifyPartyId1);
      if (index >= 0) {
        this.notifyParty1 = this.vendorSuppliers[index];
        this.invoice.NotifyParty1 = this.vendorSuppliers[index];
      }
    }
  }

  /* Fn to select the notify party 2 */
  selectNotify2(): void {
    if (this.invoice.notifyPartyId2 == -1) {
      this.rightSheetOpen();
      this.newContactType = 'Notify Party';
      this.invoice.NotifyParty2 = {};
      this.selectedVendor = undefined;
    } else {
      const index = this.vendorSuppliers.findIndex(i => i.venSupId == this.invoice.notifyPartyId2);
      if (index >= 0) {
        this.notifyParty2 = this.vendorSuppliers[index];
        this.invoice.NotifyParty2 = this.vendorSuppliers[index];
      }
    }
  }

  /**
   * Fn to open the right sheet to edit the selected vendor details
   * @param id Id of the selected vendor
   * @param type Type of selected vendor
   */
  editVendor(id: number, type: string): void {
    this.selectedVendor = id;
    this.newContactType = type;
    this.rightSheetOpen();
  }

  loadData(index: number): void {
    this.invoice.ocrStatus = 'DONE';
    this.http.get(this.ocrResults[index].metadata).subscribe(data => {
      const dummyObj: any = data;
      this.selectedImageURL = this.ocrResults[index].background;
      this.thisPage = dummyObj.fullTextAnnotation.pages[0];
      this.bgwidth = this.thisPage.width;
      this.bgheight = this.thisPage.height;
      this.selectors = this.orgHelper.calBox(this.thisPage);
      this.changeDetectorRef.detectChanges();
    }, err => {
      SwalHelper.showErrorSwal(err);
    });
  }

  selectText(str): void {
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    const selected =
      document.getSelection().rangeCount > 0
        ? document.getSelection().getRangeAt(0)
        : false;
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    if (selected) {
      document.getSelection().removeAllRanges();
      document.getSelection().addRange(selected);
      this.copyText = 'Copied';
    }
  }

  copyNumberToWord(amount): void {
    const pipe = new NumberToWordsPipe();
    const fiteredArr = pipe.transform(amount, 'US');

    const el = document.createElement('textarea');
    el.value = fiteredArr;
    el.setAttribute('readonly', '');
    document.body.appendChild(el);
    const selected =
      document.getSelection().rangeCount > 0
        ? document.getSelection().getRangeAt(0)
        : false;
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    if (selected) {
      document.getSelection().removeAllRanges();
      document.getSelection().addRange(selected);
      SwalHelper.showCenteredSavedToast('Copied');
    }
  }

  resetCopytext(): void {
    this.copyText = 'Click to copy';
  }

  /* Fn to open the right sheet for vendor details*/
  rightSheetOpen(): void {
    this.rightSheet = !this.rightSheet;
    this.renderer.addClass(document.body, 'modal-open');
    this.commonService.addBackdrop();
  }

  /* Fn to close the right sheet */
  rightSheetClose(): void {
    this.invoice.buyerId = this.invoice.buyerId > 0 ? this.invoice.buyerId : null;
    this.invoice.consigneeId = this.invoice.consigneeId > 0 ? this.invoice.consigneeId : null;
    this.invoice.notifyPartyId1 = this.invoice.notifyPartyId1 > 0 ? this.invoice.notifyPartyId1 : null;
    this.invoice.notifyPartyId2 = this.invoice.notifyPartyId2 > 0 ? this.invoice.notifyPartyId2 : null;
    this.rightSheet = false;
    this.renderer.removeClass(document.body, 'modal-open');
    this.commonService.removeBackdrop();
  }

  /* Fn to open the upload bulk item bottom sheet */
  bottomSheetOpen(): void {
    this.bottomSheet = true;
    this.renderer.addClass(document.body, 'modal-open');
    this.commonService.addBackdrop();
  }

  /* Fn to close the upload bulk item bottom sheet */
  bottomSheetClose(): void {
    this.bottomSheet = false;
    this.renderer.removeClass(document.body, 'modal-open');
    this.commonService.removeBackdrop();
  }

  /* Fn to update the details of new/updated vendor details */
  newVendor(vendor): void {
    if (!this.vendorSuppliers) {
      this.vendorSuppliers = [];
    }
    const index = this.vendorSuppliers.findIndex(i => i.venSupId == vendor.venSupId);
    if (index >= 0) {
      this.vendorSuppliers[index] = vendor;
    } else {
      this.vendorSuppliers.push(vendor);
    }

    this.invoice.consigneeId = this.invoice.consigneeId == -1 ? parseInt(vendor.venSupId, 10) : this.invoice.consigneeId;
    this.invoice.buyerId = this.invoice.buyerId == -1 ? parseInt(vendor.venSupId, 10) : this.invoice.buyerId;
    this.invoice.notifyPartyId1 = this.invoice.notifyPartyId1 == -1 ? parseInt(vendor.venSupId, 10) : this.invoice.notifyPartyId1;
    this.invoice.notifyPartyId2 = this.invoice.notifyPartyId2 == -1 ? parseInt(vendor.venSupId, 10) : this.invoice.notifyPartyId2;
    this.rightSheetClose();
    this.selectBuyer();
    this.selectConsignee();
    this.selectNotify1();
    this.selectNotify2();
    this.selectedVendor = undefined;
  }

  captureInvoiceFile(event): void {
    if (event.target.files.length + this.invoiceFiles.length > 5) {
      SwalHelper.showErrorSwal('Maximum 5 files allowed');
      return;
    }
    if (event.target.files && event.target.files.length > 0) {
      Object.keys(event.target.files).forEach((key) => {
        this.invoiceFiles.push(event.target.files[key]);
      });
    }
  }

  /* Fn to upload the new attachment */
  uplaodNewDocument(): void {
    this.shouldShowLoader = true;
    this.orgApi.uploadDocumentsAttachment(
      this.invoiceFiles[0],
      this.invoice.invId,
      undefined,
      undefined,
      undefined,
      this.invoiceFiles[1],
      this.invoiceFiles[2],
      this.invoiceFiles[3],
      this.invoiceFiles[4]
    ).subscribe(docs => {
      if (docs) {
        this.shouldShowLoader = false;
        SwalHelper.successTimerSwal('Document uploaded');
        $('#fileUploadModal').modal('hide');
        if (!this.invoice.DocumentAttachments) {
          this.invoice.DocumentAttachments = [];
        }
        this.invoice.DocumentAttachments = docs;
        this.invoiceFiles = []; // Clean the document file array
      }
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /**
   * Fn to remove the attachment
   * @param index index of the attachment to be deleted
   */
  removeAttchment(index: number): void {
    Swal({
      title: 'Delete Attachment',
      text: 'This cannot be undone',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        this.shouldShowLoader = true;
        return new Promise((resolve, reject) => {
          this.orgApi.deleteDocumentsAttachment(
            this.invoice.DocumentAttachments[index].docAttId,
            this.invoice.invId,
          ).subscribe(ok => {
            this.shouldShowLoader = false;
            this.invoice.DocumentAttachments.splice(index, 1);
            resolve(ok);
          }, err => {
            reject(err);
          });
        });
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.value) {
        this.shouldShowLoader = false;
        SwalHelper.successTimerSwal('Attachment deleted!');
      }
    }).catch(err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /**
   * fn to download the attachment
   * @param docAttId document attachment id to be downloaded
   */
  public downloadFile(docAttId: number): void {
    this.orgApi.orgDocumentSignedUrl(docAttId)
      .subscribe(url => {
        window.open(url.url, '_blank');
      });
  }

  /* Fn to close the item master detail modal &b reset the data of item master field */
  closeItemMasterModal(): void {
    this.itemMasterDetail = {};
    $('#itemMasterModal').modal('hide');
    this.invoice.InvoiceItems[this.customItemIndex].itemCode = undefined;
    this.customItemIndex = undefined;
  }

  trackByVendId(index: number, vendor: any): string {
    return vendor.venSupId;
  }
  trackByItem(index, item: InvoiceItem): string {
    return index;
  }

  showLicenseDetails(itemIndex, licenseIndex): void {
    $('#licenseDetailsModal').modal('show');
    this.licenseDetail = this.invoice.InvoiceItems[itemIndex].advanceLicenseDetails;
  }

}
export class AdvancedLicense {
  public licenseId?: number;
  public licenseType?: string;
  public expOblExpiryDate?: string;
  public exportItem?: any;
  public itemIndex?: number;
  public itemQuantity?: number;
  public licenseNo?: string;
  public LicenseItem?: LicenseItem;
}
