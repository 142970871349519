import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ShipmentTracking } from '../../../../swagger/model/shipmentTracking';
import { OrgShipmentsService } from '../../../../swagger/api/orgShipments.service';
import { SwalHelper } from '../../../swal';
import { Shipment } from '../../../../swagger/model/shipment';
import { interval, of, Subscription } from 'rxjs';
import { skip, catchError, filter, switchMap, take } from 'rxjs/operators';
declare var $: any;

@Component({
  selector: 'app-shipment-tracking',
  templateUrl: './shipment-tracking.component.html',
  styleUrls: ['./shipment-tracking.component.scss']
})
export class ShipmentTrackingComponent implements OnInit {

  public shouldShowLoader = false;
  private trackingSubscription: Subscription;

  public tracking: ShipmentTracking = {};
  @Input() shipmentDetails: Shipment = {};
  @Output() closeTracking = new EventEmitter<ShipmentTracking>();

  constructor(
    private shipmentApi: OrgShipmentsService,
  ) { }

  ngOnInit(): void {
    if (this.shipmentDetails && this.shipmentDetails.shipmentId && this.shipmentDetails.ShipmentTracking) {
      this.tracking = this.shipmentDetails.ShipmentTracking;
      if (this.tracking.shipmentTracking && this.tracking.shipmentTracking.status === 'Processing') {
        this.pollTrackingStatus();
      }
    } else {
      this.tracking = {};
      this.tracking.shipmentId = this.shipmentDetails.shipmentId;
    }
  }

  /* Fn to fetch the tracking information if tracking is status is 'Processing' */
  pollTrackingStatus(): void {
    this.trackingSubscription = interval(3000).pipe(
      skip(3),
      switchMap(() => {
        return this.shipmentApi.getShipmentTracking(this.shipmentDetails.shipmentId, this.tracking.shipmentTrackingId).pipe(
          catchError(err => of(null)),
          filter((result) => !!result),
        );
      }),
      take(1)
    ).subscribe(track => {
      if (track) {
        this.tracking = track;
        this.trackingSubscription.unsubscribe();
      }
    });
  }

  /* Fn to add or update the tracking information */
  newTracking(): void {
    $('#trackingModal').modal('hide');
    this.shouldShowLoader = true;
    if (this.tracking.shipmentTrackingId) {
      this.shipmentApi.updateShipmentTracking(
        this.tracking
      ).subscribe(track => {
        if (track) {
          this.shouldShowLoader = false;
          this.shipmentDetails.ShipmentTracking = track;
          SwalHelper.successTimerSwal('Tracking updated!');
          this.closeTracking.next(track);
          this.tracking = track;
          this.pollTrackingStatus();
        }
      }, err => {
        this.shouldShowLoader = false;
        SwalHelper.showErrorSwal(err);
      });
    } else {
      this.tracking.shipmentId = this.shipmentDetails.shipmentId;
      this.shipmentApi.createShipmentTracking(
        this.tracking
      ).subscribe(track => {
        if (track) {
          this.shouldShowLoader = false;
          this.shipmentDetails.ShipmentTracking = track;
          this.tracking = track;
          this.closeTracking.next(track);
          SwalHelper.successTimerSwal('Tracking added!');
          this.pollTrackingStatus();
        }
      }, err => {
        this.shouldShowLoader = false;
        SwalHelper.showErrorSwal(err);
      });
    }
  }

  /* Fn to get the latest tracking information */
  getLatestTracking(): void {
    this.shouldShowLoader = true;
    this.shipmentApi.getShipmentTracking(
      this.shipmentDetails.shipmentId,
      this.tracking.shipmentTrackingId
    ).subscribe(track => {
      this.shouldShowLoader = false;
      this.tracking = track;
      this.pollTrackingStatus();
    }, err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }
}
