import { Component, OnInit } from '@angular/core';
import { AuthService, Shipment } from 'src/swagger';
import { SwalHelper } from 'src/app/swal';
import * as moment from 'moment';

@Component({
  selector: 'app-shipment-list',
  templateUrl: './shipment-list.component.html',
  styleUrls: ['./shipment-list.component.scss']
})
export class ShipmentListComponent implements OnInit {

  public shipments: Shipment[] = [];
  public shouldShowLoader: boolean;
  public startDate: string;
  public endDate: string;
  public status: string;
  dtOptions: DataTables.Settings = {};

  constructor(
    private auth: AuthService
  ) { }

  ngOnInit(): void {
    this.shouldShowLoader = true;
    this.dtOptions = {
      columnDefs: [
        { targets: 0, width: '40px' },
        { targets: -1, width: '80px' },
        { orderable: false, targets: -1 }
      ]
    };
    this.getExternalShipmentShared();
  }

  getExternalShipmentShared(): void {
    this.shipments = [];
    this.auth.serviceProviderSharedShipments(
      this.startDate ? moment(this.startDate).format('YYYY-MM-DD') : null,
      this.endDate ? moment(this.endDate).format('YYYY-MM-DD') : null,
      this.status ? this.status : undefined)
      .subscribe(ships => {
        this.shouldShowLoader = false;
        if (ships && ships.length > 0) {
          this.shipments = ships;
        } else {
          this.shipments = null;
        }
      }, err => {
        this.shouldShowLoader = false;
        SwalHelper.showErrorSwal(err);
      });
  }

  resetFilter(): void {
    this.startDate = undefined;
    this.endDate = undefined;
    this.status = undefined;
    this.getExternalShipmentShared();
  }
}


