import { Component, OnDestroy, OnInit } from '@angular/core';
import { ShipmentDocsService, ShippingBill } from 'src/swagger';
import { Router } from '@angular/router';
import { SwalHelper } from 'src/app/swal';
declare var swal: any;
declare var $: any;
import * as moment from 'moment';
import { GlobalProfileService } from 'src/app/global-profile.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-shipping-bill',
  templateUrl: './shipping-bill.component.html',
  styleUrls: ['./shipping-bill.component.scss']
})
export class ShippingBillComponent implements OnInit, OnDestroy {

  public rightSheet = false;
  public sb: ShippingBill = {};
  public sbCreationtype;
  public sbFile: File;
  public sbFileName;
  dtOptions: DataTables.Settings = {};
  public shouldShowLoader: boolean;

  public shippingBills: ShippingBill[] = [];

  public startDate: any;
  public endDate: any;
  private userSubscription: Subscription;

  constructor(
    public router: Router,
    private shipmentDocApi: ShipmentDocsService,
    public profileSync: GlobalProfileService
  ) { }

  ngOnInit(): void {
    this.dtOptions = {
      columnDefs: [
        { targets: 0, width: '40px' },
        { targets: -1, width: '80px' },
        { orderable: false, targets: -1 }
      ]
    };
    this.initSB();
    this.shouldShowLoader = true;
    this.userSubscription = this.profileSync.user$.subscribe(user => {
      if (user && user.OrganisationRole && user.OrganisationRole.permissions && user.OrganisationRole.permissions.ShippingBill.read) {
        this.getSbList();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  /* Fn to initialize ShippingBill */
  initSB(): void {
    this.sbCreationtype = 'new';
    this.sb.mode = 'Sea';
    this.sb.shippingBillDate = moment().format('YYYY-MM-DD');
  }

  /* Fn to fetch the shipping bills list */
  getSbList(): void {
    this.shouldShowLoader = true;
    this.shippingBills = [];
    this.shipmentDocApi.listShippingBill(
      this.startDate ? moment(this.startDate).format('YYYY-MM-DD') : null,
      this.endDate ? moment(this.endDate).format('YYYY-MM-DD') : null,
    ).subscribe(sb => {
        this.shouldShowLoader = false;
        if (sb && sb.length > 0) {
          this.shippingBills = sb;
        }
        else {
          this.shippingBills = null;
        }
      }, err => {
        this.shouldShowLoader = false;
        SwalHelper.showErrorSwal(err);
      });
  }

  fileChange(event): void {
    this.sbFileName = event.target.files[0].name;
    this.sbFile = event.target.files[0];
  }

  /* Fn to create a new shipping bill*/
  newSb(): void {
    $('#sbModal').modal('hide');
    this.shouldShowLoader = true;
    if (this.sbCreationtype === 'new') {
      this.sb.shippingBillDate = moment(this.sb.shippingBillDate).format('YYYY-MM-DD');
      this.shipmentDocApi.createShippingBill(
        this.sb
      ).subscribe(sb => {
        if (sb) {
          this.shouldShowLoader = false;
          this.sb = sb;
          this.router.navigate([`organisation/shipment/shipment-documents/shipping-bill/edit-shipping-bill/${this.sb.shippingBillId}`]);
        }
      }, err => {
        this.shouldShowLoader = false;
        SwalHelper.showErrorSwal(err);
      });
    } else {
      this.shipmentDocApi.importShippingBill(
        this.sbFile
      ).subscribe(sb => {
        if (sb) {
          this.shouldShowLoader = false;
          this.sb = sb;
          this.router.navigate([`organisation/shipment/shipment-documents/shipping-bill/edit-shipping-bill/${this.sb.shippingBillId}`]);
        }
      }, err => {
        this.shouldShowLoader = false;
        SwalHelper.showErrorSwal(err);
      });
    }
  }

  /**
   * Fn to delete the shipping -bill
   * @param index Index of shipping-bill to be deleted
   */
  deleteShippingBill(index: number): void {
    swal({
      title: 'Delete Shipping Bill',
      text: 'This cannot be undone',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        this.shouldShowLoader = true;
        return new Promise((resolve, reject) => {
          this.shipmentDocApi.deleteShippingBill(
            this.shippingBills[index].shippingBillId,
          ).subscribe(ok => {
            this.shouldShowLoader = false;
            this.shippingBills.splice(index, 1);
            resolve(ok);
          }, err => {
            reject(err);
          });
        });
      },
      allowOutsideClick: () => !swal.isLoading()
    }).then((result) => {
      if (result.value) {
        this.shouldShowLoader = false;
        SwalHelper.successTimerSwal('Shipping Bill deleted!');
      }
    }).catch(err => {
      this.shouldShowLoader = false;
      SwalHelper.showErrorSwal(err);
    });
  }

  /* Fn to reset the filter */
  resetFilter(): void {
    this.startDate = undefined;
    this.endDate = undefined;
    this.getSbList();
  }

  /**
  * Fn to open the shipment link modal with shipping bill details
  * @param index Index of shipping bill to shipment link
  */
  openLinkShipment(index: number): void {
    localStorage.setItem('IS_LINK_SHIPMENT', 'true');
    this.router.navigate([`/organisation/shipment/shipment-documents/shipping-bill/shipping-bill-details/${this.shippingBills[index].shippingBillId}`]);
  }
}
